import {
    Box, Checkbox, Chip, DialogActions, DialogTitle,
    IconButton, List, ListItem, ListItemButton, ListItemIcon,
    ListItemText, Popper, PopperPlacementType, TextField, Tooltip, Typography
} from '@mui/material';
import React, { useEffect } from 'react';
import { useEffectOnce } from 'src/hooks/useEffectOnce';
import { debounce } from 'src/Utility/api';
import * as api from 'src/Utility/api';
import CancelIcon from '@mui/icons-material/Cancel';
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
import { AppContext } from 'src/Components/Dashboard/@State/context/AppContext';
import { ExchangeOrderType } from 'src/Components/Dashboard/@State/types';
import ReportSelectionTable from './ReportSelectionTable/ReportSelectionTable';

function ReportSelectionMapDollarAmounts(props: any) {
    const [open, setOpen] = React.useState(false);
    const [placement, setPlacement] = React.useState<PopperPlacementType>('bottom-start');
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [orderReportSelectionOrder1, setOrderReportSelectionOrder1] = React.useState<any[]>([]);
    const [orderReportSelectionOrder2, setOrderReportSelectionOrder2] = React.useState<any[]>([]);
    const [searchTextOrder1, setSearchTextOrder1] = React.useState<string>("");
    const [searchTextOrder2, setSearchTextOrder2] = React.useState<string>("");
    const [columnSelectionFilteredOrder1, setColumnSelectionFilteredOrder1] = React.useState<any[]>([]);
    const [columnSelectionFilteredOrder2, setColumnSelectionFilteredOrder2] = React.useState<any[]>([]);
    const { fetchReportDisplayName } = React.useContext(AppContext) as ExchangeOrderType;

    useEffect(() => {
        if (searchTextOrder1 !== "") {
            const filteredColumnSelection = orderReportSelectionOrder1.filter((ele) => {
                return ele.ReportName.toLowerCase().includes(searchTextOrder1.toLowerCase());
            });
            setColumnSelectionFilteredOrder1(filteredColumnSelection);
        } else {
            setColumnSelectionFilteredOrder1([]);
        }
    }, [searchTextOrder1]);

    useEffect(() => {
        if (searchTextOrder2 !== "") {
            const filteredColumnSelection = orderReportSelectionOrder2.filter((ele) => {
                return ele.ReportName.toLowerCase().includes(searchTextOrder2.toLowerCase());
            });
            setColumnSelectionFilteredOrder2(filteredColumnSelection);
        } else {
            setColumnSelectionFilteredOrder2([]);
        }
    }, [searchTextOrder2]);

    useEffect(() => {
        if (props.estimateOrderReports.length == 0) {
            getOrderEstimateReports();
        }
    }, [props.selectedOrderId]);

    useEffect(() => {
        if (props.estimateOrderReportsBothSides.length > 0) {
            loadOrderReportInformation();
        }
    }, [props.estimateOrderReports]);

    const handleClickOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
        setOpen(true);
    };

    const clearSelection = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
        setOpen(false);
    };

    const setColumnPreferenceHandler = (columnPerferenceObj: any, isEnable: boolean) => {
        let _columnPreference = [...orderReportSelectionOrder1];
        _columnPreference[_columnPreference.indexOf(columnPerferenceObj)].Enable = isEnable;
        setOrderReportSelectionOrder1(_columnPreference);
        props.setOrderReportSelectionOrder1(_columnPreference);
    }

    const setColumnPreferenceHandlerOrder2 = (columnPerferenceObj: any, isEnable: boolean) => {
        let _columnPreference = [...orderReportSelectionOrder2];
        _columnPreference[_columnPreference.indexOf(columnPerferenceObj)].Enable = isEnable;
        setOrderReportSelectionOrder2(_columnPreference);
        props.setOrderReportSelectionOrder2(_columnPreference);
    }

    const getSelectedColumns = () => {
        if (orderReportSelectionOrder1.length > 0) {
            const selectedColumns = orderReportSelectionOrder1.filter((ele: any) => { return ele.Enable });
            if (selectedColumns.length > 0) {
                const columnsNames = selectedColumns.map((ele: any) => { return ele.ColumnName });
                return columnsNames.toString();
            } else {
                return "All";
            }
        } else {
            return "All";
        }
    }

    const loadOrderReportInformation = () => {
        const order1Reports = props.estimateOrderReportsBothSides.filter((ele: any) => { return ele.OrderId === props.tradeOrderFromTo.OrderId.toString() });
        loadOrderReportInformationOneSide(order1Reports.map((ele: any) => { return ele.ReportName }), setOrderReportSelectionOrder1, props.setOrderReportSelectionOrder1);

        if (isOppositeSideOrder()) {
            const order2Reports = props.estimateOrderReportsBothSides.filter((ele: any) => { return ele.OrderId === props.oppositeTradeOrderFromTo?.OrderId.toString() });
            loadOrderReportInformationOneSide(order2Reports.map((ele: any) => { return ele.ReportName }), setOrderReportSelectionOrder2, props.setOrderReportSelectionOrder2);
        }
    }

    const loadOrderReportInformationOneSide = (orderReports: any, setOrderReportSelectionHandler: any, parentSetOrderReportSelection: any) => {
        let _orderReports: any[] = [];

        if (orderReports.length == 0) { // This needs to be loaded only if estimates data isn't there //
            props.reportGroups?.forEach((element: any) => {
                if (element.Report_YN === "Y" && element.Active_YN === "Y") {
                    if (props.orderReports.includes("All") || props.orderReports.includes(element.DA_ColName)) {
                        const _currentList = _orderReports.map((ele: any) => { return ele.ReportName });
                        if (!_currentList.includes(element.DA_ColName)) {
                            _orderReports.push({
                                ReportName: element.DA_ColName,
                                Enable: true
                            });
                        }
                    }
                }
            });
        }
        else {
            orderReports.forEach((reportName: string) => {
                const _currentList = _orderReports.map((ele: any) => { return ele.ReportName });
                if (!_currentList.includes(reportName)) {
                    _orderReports.push({
                        ReportName: reportName,
                        Enable: true
                    });
                }
            });
        }
        setOrderReportSelectionHandler(_orderReports);
        parentSetOrderReportSelection(_orderReports);
    }

    const getOrderEstimateReports = async () => {
        const _orderId = props.oppositeTradeOrderFromTo.OrderBy == "" ?
            props.selectedOrderId : (props.tradeOrderFromTo.OrderId + "," + props.oppositeTradeOrderFromTo.OrderId);
        await api.API_GET('/Report/GetEstimatesReports?OrderIds=' + _orderId + "")
            .then((response) => {
                if (response.data.length > 0) {
                    const reports = response.data.map((ele: any) => { return ele.ReportName });
                    props.setEstimateOrderReportsBothSides(response.data);
                    props.setEstimateOrderReports(reports);
                }
            })
            .catch((ex: any) => {
                loadOrderReportInformation();
                console.log(ex)
            });
    }

    const isOppositeSideOrder = () => {
        return props?.oppositeTradeOrderFromTo?.OrderId != "";
    }

    return (
        <>
            <Tooltip title="Order Datasets">
                <IconButton id="orderds"
                    color="success"
                    aria-label="Order Datasets"
                    style={{ backgroundColor: "rgb(239 238 238)", marginLeft: "5px", width: "fit-content" }}
                    onClick={handleClickOpen}>
                    <AssessmentOutlinedIcon fontSize="small" />
                </IconButton>
            </Tooltip>
            <Popper open={open} placement={placement} anchorEl={anchorEl} style={{ zIndex: '10000' }}>
                <Box sx={{ border: '2px solid #bfbfbf', p: 1, bgcolor: 'background.paper', display: 'flex' }}>
                    {/* <div>
                        {!isOppositeSideOrder() && <Typography variant="h6" sx={{ color: "#505050", paddingRight: "10px", fontSize: "1rem", marginBottom: "0px" }} >Order #{props.tradeOrderFromTo.OrderId}</Typography>}
                        {isOppositeSideOrder() && <Chip sx={{ backgroundColor: '#b5ebbb', width: '100%' }} label={"Order #" + props.tradeOrderFromTo.OrderId + " (" + props.tradeOrderFromTo.OrderBy + ")"} variant="outlined" />}
                        <TextField
                            label="Search Datasets"
                            id="columnselection-search-text-box1"
                            value={searchTextOrder1}
                            sx={{ width: '100%' }}
                            size="small"
                            onChange={(ele) => { debounce(setSearchTextOrder1(ele.target.value)) }}
                        />
                        <Box sx={{ border: '1px solid #bfbfbf', p: 1, bgcolor: 'background.paper' }} style={{ height: '27vh', width: '100%' }} className="ag-theme-material">
                            <List sx={{ width: '100%', maxWidth: 360, maxHeight: '25vh', overflow: 'scroll', bgcolor: 'background.paper' }}>
                                {orderReportSelectionOrder1.map((column: any) => {
                                    const labelId = `checkbox-list-label-${column.ReportName}`;
                                    if (columnSelectionFilteredOrder1.length === 0
                                        || columnSelectionFilteredOrder1
                                            .map((ele: any) => { return ele.ReportName.toLowerCase() })
                                            .includes(column.ReportName.toLowerCase())) {
                                        return (
                                            <ListItem
                                                key={column.ReportName}
                                                secondaryAction={
                                                    <IconButton edge="end" aria-label="comments">
                                                    </IconButton>
                                                }
                                                disablePadding>
                                                <ListItemButton role={undefined} dense onClick={() => { setColumnPreferenceHandler(column, !column.Enable) }}>
                                                    <ListItemIcon>
                                                        <Checkbox
                                                            style={{ padding: '0px' }}
                                                            edge="start"
                                                            checked={column.Enable}
                                                            tabIndex={-1}
                                                            disableRipple
                                                            inputProps={{ 'aria-labelledby': labelId }}
                                                        />
                                                    </ListItemIcon>
                                                    <ListItemText id={labelId} primary={fetchReportDisplayName(column.ReportName)} />
                                                </ListItemButton>
                                            </ListItem>
                                        );
                                    }

                                })}
                            </List>
                        </Box>
                    </div>
                    {isOppositeSideOrder() && <div>
                        <Chip sx={{ backgroundColor: '#94c1fc', width: '100%' }} label={"Order #" + props.oppositeTradeOrderFromTo.OrderId + " (" + props.tradeOrderFromTo.OrderTo + ")"} variant="outlined" />
                        <TextField
                            label="Search Datasets"
                            id="columnselection-search-text-box2"
                            value={searchTextOrder2}
                            sx={{ width: '100%' }}
                            size="small"
                            onChange={(ele) => { debounce(setSearchTextOrder2(ele.target.value)) }}
                        />
                        <Box sx={{ border: '1px solid #bfbfbf', p: 1, bgcolor: 'background.paper' }} style={{ height: '27vh', width: '100%' }} className="ag-theme-material">
                            <List sx={{ width: '100%', maxWidth: 360, maxHeight: '25vh', overflow: 'scroll', bgcolor: 'background.paper' }}>
                                {orderReportSelectionOrder2.map((column: any) => {
                                    const labelId = `checkbox-list-label-${column.ReportName}`;
                                    if (columnSelectionFilteredOrder2.length === 0
                                        || columnSelectionFilteredOrder2
                                            .map((ele: any) => { return ele.ReportName.toLowerCase() })
                                            .includes(column.ReportName.toLowerCase())) {
                                        return (
                                            <ListItem
                                                key={column.ReportName}
                                                secondaryAction={
                                                    <IconButton edge="end" aria-label="comments">
                                                    </IconButton>
                                                }
                                                disablePadding>
                                                <ListItemButton role={undefined} dense onClick={() => { setColumnPreferenceHandlerOrder2(column, !column.Enable) }}>
                                                    <ListItemIcon>
                                                        <Checkbox
                                                            style={{ padding: '0px' }}
                                                            edge="start"
                                                            checked={column.Enable}
                                                            tabIndex={-1}
                                                            disableRipple
                                                            inputProps={{ 'aria-labelledby': labelId }}
                                                        />
                                                    </ListItemIcon>
                                                    <ListItemText id={labelId} primary={fetchReportDisplayName(column.ReportName)} />
                                                </ListItemButton>
                                            </ListItem>
                                        );
                                    }

                                })}
                            </List>
                        </Box>

                    </div>
                    } */}
                    <div style={{ height: '35vh', width: '100%' }}>
                        <ReportSelectionTable
                            order1Info={props.tradeOrderFromTo}
                            order2Info={props.oppositeTradeOrderFromTo}
                            isOppositeSideOrder={isOppositeSideOrder()}
                            orderReportSelectionOrder1={orderReportSelectionOrder1}
                            orderReportSelectionOrder2={orderReportSelectionOrder2}
                            setColumnPreferenceHandler={setColumnPreferenceHandler}
                            setColumnPreferenceHandlerOrder2={setColumnPreferenceHandlerOrder2}>
                        </ReportSelectionTable>
                    </div>
                    <DialogActions sx={{ padding: "2px", alignItems: 'baseline' }}>
                        <IconButton id="cancel" color="error" aria-label="cancel" onClick={clearSelection}>
                            <CancelIcon />
                        </IconButton>
                    </DialogActions>

                </Box>
            </Popper>
        </>
    )
}

export default ReportSelectionMapDollarAmounts;