import * as React from 'react';
import { CartType, IColumnSelectionInfo } from '../types';
import * as api from 'src/Utility/api';

export const CartContext = React.createContext<CartType | null>(null);

interface BaseLayoutProps {
    children?: React.ReactNode;
}

const CartProvider: React.FC<BaseLayoutProps> = ({ children }) => {
    const [columnSelectionList, setColSelectionList] = React.useState<IColumnSelectionInfo[]>([]);
    const [reportGroups, SetReportGroups] = React.useState<any[]>([]);
    const [operatorList, setOperatorList] = React.useState<string[]>([]);

    const saveColumnSelection = (columnSelection: any, nodeData: any) => {
        const _dataColumnSelection = [...columnSelectionList];
        const selectionInfo = {
            SchemaName: nodeData["SchemaName"],
            TableName: nodeData["TableName"],
            columnSelectionInfo: columnSelection
        };
        const matchData = _dataColumnSelection
            .filter((ele) => {
                return ele.SchemaName === nodeData["SchemaName"]
                    && ele.TableName === nodeData["TableName"]
            });
        if (matchData.length === 0) {
            _dataColumnSelection.push(selectionInfo);
        } else {
            const matchIndex = _dataColumnSelection.indexOf(matchData[0]);
            _dataColumnSelection[matchIndex] = selectionInfo;
        }

        setColSelectionList(_dataColumnSelection);
    }

    const loadInitialOperatorData = (_loggedInUserMailId : any) => {
        const fetchOperators = async () => {
        await api.API_GET('/Table/GetTablesData?schema=settings&table=OPERATOR')
            .then((response) => {
                let _operators: string[] = [];
                response.data.TableData.forEach((data: any) => _operators.push(data.ShortName));
                setOperatorList(_operators);
            });
        };

        async function fetchUserSecurityPermissionData() {
            await api.API_GET('/Table/GetUserSecurityPermissions?loggedInUser=' + _loggedInUserMailId)
            .then((response) => {
                if (response?.data?.TableData?.length > 0) {
                    let _operators: string[] = [];
                    let includesAll: boolean = false;
                    if (response.data.TableData != null) {
                        response.data.TableData
                            .forEach((permission: any) => {
                                _operators = _operators.concat(permission.Operators?.split(','));
                                if (_operators.includes('All') || _operators.includes('ALL')) {
                                    includesAll = true;
                                }
                            });
                        if (includesAll) {
                            fetchOperators();
                        }
                        else if (_operators.length > 0) {
                            setOperatorList(Array.from(new Set(_operators)));
                        }
                    }
                }
            });
        };

        fetchUserSecurityPermissionData();
    };

    const loadInitialPrerequisiteData = () => {
        async function fetchReportGroups() {
            await api.API_GET('/Table/GetTablesData?schema=settings&table=REPORT_GROUP')
                .then((response) => {
                    if(response?.data){
                    SetReportGroups(response.data.TableData);
                    }
                });
        };
        fetchReportGroups();
    };

    return <CartContext.Provider
        value={{
            columnSelectionList,
            reportGroups,
            operatorList,
            saveColumnSelection,
            loadInitialPrerequisiteData,
            loadInitialOperatorData
        }}>{children}
    </CartContext.Provider>;
};

export default CartProvider;