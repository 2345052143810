import { useCallback, useMemo, useRef, useState } from "react";
import { AgGridReact } from 'ag-grid-react';

import 'ag-grid-enterprise';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-material.css';
import { useEffectOnce } from "src/hooks/useEffectOnce";
import * as api from 'src/Utility/api';
import Skeleton from '@mui/material/Skeleton';
import SystemUpdateAltIcon from '@mui/icons-material/SystemUpdateAlt';
import RefreshIcon from '@mui/icons-material/Refresh';
import { debounce } from 'src/Utility/api';
import { Box, Grid, IconButton, LinearProgress, Paper, TextField, Tooltip } from "@mui/material";
import "./ReportGrid.css";

interface IReportGridProps {
    sequenceIndex: number,
    reportTitle: string,
    dataSource: string,
    isFullScreen: boolean
}

function ReportGrid(props: IReportGridProps) {
    const gridRef: any = useRef();
    const [isDataLoaded, setIsDataLoaded] = useState<boolean>(false);

    useEffectOnce(() => {
        loadReportData();
    });

    const loadReportData = () => {
        if (props.dataSource.length > 0 && props.dataSource.substring(0, 6).toLocaleLowerCase() === 'select') {
            loadDataSelectStatement();
        } else {
            loadDataFromApi();
        }
    }

    const loadDataSelectStatement = () => {
        async function getSelectStatementData() {
            await api.API_GET('/Table/GetSelectQueryResult?schema='
                + "settings" + '&table='
                + "DATA_QUALITY_DASHBOARD" + '&selectQuery='
                + props.dataSource + '').then((response: any) => {
                    setIsDataLoaded(true);
                    setTimeout(() => {
                        if (response?.data?.Columns.length > 0) {
                            let columnDefinitions: any[] = [];
                            response.data.Columns.forEach((column: any) => {
                                let colHeader = column.ColumnName.toUpperCase();
                                let columnDefnition = {
                                    field: column.ColumnName,
                                    headerName: colHeader,
                                    cellClass: ['ag-cell-custom'],
                                };
                                columnDefinitions.push(columnDefnition);
                            });

                            gridRef.current.api.setColumnDefs(columnDefinitions);
                            gridRef.current.api.setRowData(response?.data?.TableData);
                        } else {
                            gridRef.current.api.setColumnDefs([]);
                            gridRef.current.api.setRowData([]);
                        }
                    }, 500);
                });
        }

        getSelectStatementData();
    }

    const loadDataFromApi = () => {
        async function getApiData() {
            await api.API_GET_EXTERNAL(props.dataSource)
                .then((response: any) => {
                    setIsDataLoaded(true);
                    setTimeout(() => {
                        let columnDefinitions: any[] = [];
                        if (response?.data?.length > 0) {
                            const columns = Object.keys(response.data[0]);
                            columns.forEach((column: any) => {
                                let colHeader = column.toUpperCase();
                                let columnDefnition = {
                                    field: column,
                                    headerName: colHeader,
                                    cellClass: ['ag-cell-custom'],
                                };
                                columnDefinitions.push(columnDefnition);

                                gridRef?.current?.api?.setColumnDefs(columnDefinitions);
                                gridRef?.current?.api?.setRowData(response?.data);
                            });
                        } else {
                            gridRef?.current?.api?.setColumnDefs([]);
                            gridRef?.current?.api?.setRowData([]);
                        }
                    }, 500);
                });
        }

        getApiData();
    }

    const onBtExport = () => {
        const params = {
            columnGroups: true,
            allColumns: true,
            fileName: props.reportTitle,
        };
        gridRef.current.api.exportDataAsCsv(params);
    }

    const refreshDataHandler = () => {
        loadReportData();
    }

    const onFilterTextBoxChanged = useCallback((event: any) => {
        gridRef.current.api.setQuickFilter(event.target.value);
    }, []);

    const onColumnSearchTextBoxChanged = useCallback((event: any, id: string) => {
        const allColumns = gridRef.current.columnApi.getAllColumns();
        const mathcingColumns = allColumns.filter((ele: any) => { return ele.colId.toLowerCase().match(event.target.value.toLowerCase()) });
        if (mathcingColumns.length > 0) {
            if (event.target.value !== "" && document.getElementById(id)?.getElementsByClassName("ag-body-horizontal-scroll-viewport")) {
                document.getElementById(id)?.getElementsByClassName("ag-body-horizontal-scroll-viewport")[0]
                    .scrollTo((mathcingColumns[0].left), 0);
            } else if (document.getElementById(id)?.getElementsByClassName("ag-body-horizontal-scroll-viewport")) {
                document.getElementById(id)?.getElementsByClassName("ag-body-horizontal-scroll-viewport")[0]
                    .scrollTo(0, 0);
            }
        }
    }, []);

    const defaultColDef = useMemo(() => {
        return {
            sortable: true,
            filter: true,
            headerCheckboxSelectionFilteredOnly: true
        };
    }, []);

    const gridOptions = {
        // PROPERTIES
        pagination: false,
        defaultExportParams: {
            columnGroups: true,
            fileName: 'name_of_file'
        },
        getRowHeight: () => { return 25 }
    }

    return (
        <div id={"AgGrid-" + props.sequenceIndex} className="ag-theme-material" style={{ height: props.isFullScreen ? "85vh" : "25vh" }}>
            {isDataLoaded ?
                (
                    <>
                        <Paper sx={{ p: 1, display: 'flex', flexDirection: 'row', marginBottom: '10px' }}>
                            <Grid item xs={2}>
                                <TextField
                                    label="Search field"
                                    id="filter-text-box"
                                    defaultValue=""
                                    sx={{ width: '100%' }}
                                    size="small"
                                    onInput={debounce((eve: any) => { onFilterTextBoxChanged(eve) })}
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <TextField
                                    label="Column Search field"
                                    id="columnsearch-text-box"
                                    defaultValue=""
                                    sx={{ width: '100%' }}
                                    size="small"
                                    onInput={debounce((eve: any) => { onColumnSearchTextBoxChanged(eve, "AgGrid-" + props.sequenceIndex) })}
                                />
                            </Grid>
                            <Grid item xs={8} sx={{ textAlign: 'right' }}>
                                <Tooltip title="Refresh">
                                    <IconButton
                                        id="refreshtable"
                                        color="success"
                                        aria-label="refresh table"
                                        component="label"
                                        style={{ backgroundColor: "rgb(239 238 238)", marginRight: '5px' }}
                                        onClick={refreshDataHandler}>
                                        <RefreshIcon />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title="Export to excel">
                                    <IconButton
                                        id="exporttoexcel"
                                        color="success"
                                        aria-label="Export to excel"
                                        component="label"
                                        size="small"
                                        style={{ backgroundColor: "rgb(239 238 238)", marginRight: '5px' }}
                                        onClick={onBtExport}>
                                        <SystemUpdateAltIcon />
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                        </Paper>
                        <AgGridReact
                            ref={gridRef}
                            defaultColDef={defaultColDef}
                            gridOptions={gridOptions}>
                        </AgGridReact>
                    </>
                ) :
                (<Box sx={{ pt: 0.5 }}>
                    <LinearProgress />
                    <Skeleton variant="rectangular" width={"100%"} height={80} />
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                </Box>)}
        </div>
    )
}

export default ReportGrid;