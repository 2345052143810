import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined';
import { Button, ButtonGroup, IconButton, Tooltip, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { convertUTCtoCDT } from 'src/Utility/common';

export default function AddCommentRenderer(props: any) {
    const [latestComment, setLatestComment] = useState<any>(null);
    const [waitingReply, setWaitingReply] = useState<boolean>(false);

    useEffect(() => {
        if (props.orderComments && props.orderComments.length > 0) {
            let _comments = props.orderComments
                .filter((comment: any) => {
                    return (comment.Api === props.data?.Api || comment.Api === props.data?.API)
                        && comment.ReportName === props.data.DaColName
                });
            if (_comments.length > 0) {
                setLatestComment(_comments[0]);
                setWaitingReply(props.operator !== _comments[0].CommentByOperator);
            }
        }
    }, [props.orderComments]);

    function truncate(input: string) {
        if (input && input.length > 4) {
            return input.substring(0, 5) + '...';
        }
        return input;
    };

    return (
        <>
        {
            !props.node.footer && 
            <div style={{ display: 'flex' }}>
            <Tooltip title={latestComment !== null ? latestComment.Comment + " : ( " + convertUTCtoCDT(latestComment.LastModifiedDate+"Z") + " )" : ""}>
                <ButtonGroup onClick={() => { props.setSelectedEstimate(props.data); props.setCommentOpen(true); }} style={{ padding: "0px", backgroundColor: waitingReply ? "#ffd1d1" : "transparent" }} variant="text" aria-label="text button group">
                    <Button id="btn1">
                        <IconButton id="history" color="primary" size="small" style={{ padding: "0px" }} aria-label="history">
                            <ChatOutlinedIcon />
                        </IconButton>
                    </Button>
                    {latestComment !== null && <Button id="setestimatesbtn1" onClick={() => { props.setSelectedEstimate(props.data) }}>{latestComment.CommentByOperator}</Button>}
                    {latestComment === null && <Button id="button1">...</Button>}
                    {latestComment !== null && <Button id="conerttocdtbtn1" style={{ marginLeft: "5px" }}>{latestComment !== null ? convertUTCtoCDT(latestComment.LastModifiedDate+"Z") : " "}</Button>}
                    <Button id="acrbtn2" style={{ marginLeft: "5px" }}>{latestComment && truncate(latestComment?.Comment)}</Button>
                </ButtonGroup>
            </Tooltip>
            </div>
        }
        </>
     
    )
}