import React, { useCallback, useEffect, useMemo, useRef } from "react";

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-balham.css';
import 'ag-grid-enterprise';

import { Alert, AlertColor, Avatar, Button, Chip, Collapse, IconButton, Paper, Stack, TextField, Tooltip, Typography } from "@mui/material";
import { SideBarDef } from "ag-grid-community";

import * as api from 'src/Utility/api';
import { ExchangeOrderType, ITableGridColumnDefinition, MessageTags, TabOrder } from "../../@State/types";
import AutoModeIcon from '@mui/icons-material/AutoMode';
import './Valuation.css';
import MyOrdersFilter from "./MyOrders/MyOrdersFilter";

import RestartAltOutlinedIcon from '@mui/icons-material/RestartAltOutlined';
import SystemUpdateAltIcon from '@mui/icons-material/SystemUpdateAlt';
import SaveIcon from '@mui/icons-material/Save';
import ExcelUpload from "../ExcelUpload/ExcelUpload";
import QualityRatingRenderer from "../CellRenderers/QualityRatingRenderer";
import ConfirmDialog from "src/Common/ConfirmDialog";
import ViewColumnOutlinedIcon from '@mui/icons-material/ViewColumnOutlined';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ColumnSelection from "../ColumnSelection/ColumnSelection";
import AddCommentRenderer from "../CellRenderers/AddCommentRenderer";
import OrderComment from "../OrderSummary/OrderComment/OrderComment";
import DateTimeRenderer from "../CellRenderers/DateTimeRenderer";
import DatePickerEditor from "../CellEditors/DatePickerEditor";
import { useEffectOnce } from "src/hooks/useEffectOnce";
import { AppContext } from '../../@State/context/AppContext';
import MapViewRenderer from "../Cart/MapViewRenderer";
import ExcludeIncludeOrderRenderer from "../CellRenderers/ExcludeIncludeOrderRenderer";
import ApproveDropDownCellEditor from "../CellEditors/ApproveDropdownRenderer";
import * as eventMessaging from 'src/Utility/Messaging/Messaging';
import ReportNameRenderer from "../CellRenderers/ReportNameRenderer";
import DollarValueRenderer from "../CellRenderers/DollarValueRenderer";
import { columnHeaderTemplate, isJsonString } from "src/Utility/common";
import circleImage from 'src/Utility/Images/loading.gif';
import ChecklistIcon from '@mui/icons-material/Checklist';

function Valuation(props: any) {
    const initialMessage = {
        open: false,
        msg: '',
        severity: 'info'
    };

    const { pickListDefinitions, screenUpdaterValuation, dataAuditData,
        reportConfigurations, setScreenUpdaterValuation, operatorList, loadInitialOperatorData } = React.useContext(AppContext) as ExchangeOrderType;
    const [message, setMessage] = React.useState(initialMessage);
    const [operator, setOperator] = React.useState<string>('');
    const [columns, setColumns] = React.useState<any[]>([]);
    const [valuationData, setValuationData] = React.useState<any[]>([]);
    const [type, setType] = React.useState<any>("Valuation");
    const [selectedOrder, setselectedOrder] = React.useState<any[]>([]);
    const [firstSelectedOrderData, setFirstSelectedOrderData] = React.useState<any[]>([]);
    const [apis, setApis] = React.useState<string>("");
    const [reports, setReports] = React.useState<string[]>([]);
    const [cartOrders, SetCartOrders] = React.useState<any[]>([]);
    const [isLoadedFromCart, SetisLoadedFromCart] = React.useState(false);
    const [pivotMode, setPivotMode] = React.useState<boolean>(false);
    const [openConfirmDialog, setOpenConfirmDialog] = React.useState(false);
    const [regenValuation, setRegenValuation] = React.useState(false);
    const [tablePreferenceList, setTablePreferenceList] = React.useState<any[]>([]);
    const [selectedPreference, setselectedPreference] = React.useState<any>();
    const [columnPreference, setColumnPreference] = React.useState<any[]>([]);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [columnsPerfOpen, setcolumnsPerfOpen] = React.useState(false);
    const canBeOpen = columnsPerfOpen && Boolean(anchorEl);
    const id = canBeOpen ? 'transition-popper' : undefined;
    const [isEditted, setIsEditted] = React.useState(false);
    const [columnDefinitions, setcolumnDefinitions] = React.useState<any[]>([]);
    const [orderComments, setOrderComments] = React.useState<any[] | null>(null);
    const [openSaveConfirmDialog, setOpenSaveConfirmDialog] = React.useState(false);
    const [isUserInValuation, setIsUserInValuation] = React.useState(false);
    const [commentOpen, setCommentOpen] = React.useState<boolean>(false);
    const [valuationTempData, setValuationTempData] = React.useState<any[]>([]);
    const [valuationDataAudit, setValuationDataAudit] = React.useState<any[] | null>(null);
    const [isExecutedOrder, setIsExecutedOrder] = React.useState(false);
    const [filterValuationType, setFilterValuationType] = React.useState<string>('All');
    const [showGif,setShowGif] = React.useState(false);
    const [transactionFees,setTransactionFees] = React.useState<number>(0);
    const filterTypeList = ['All', 'Y', 'N'];
    const [missingColumns, setMissingColumns] = React.useState<any[]>([]);
    const [isTradeOrder, setIsTradeOrder] = React.useState(false);

    const hiddenColumns: any[] = ["ValuationId"
        , "ReportYn"
        , "OrderValuationId"
        , "ActiveYn"
        , "Report_Display_order"
        , "Comment2"
        , "ApprovedDate2"
        , "ApprovalStatus2"
        , "Approver3"
        , "Comment3"
        , "ApprovedDate3"
        , "ApprovalStatus3"];

    const displayColumns: any[] = ["Operator", "WellName",
        , "API"
        , "Dataset"
        , "DateOfAcquisition"
        , "CostFromAFE"
        , "OasisCostFromAFE"
        , "Depreciation"
        , "CurrentValue"
        , "DaColName"
        , "L1"
        , "L2"
        , "L3"
        , "L4"
        , 'L5', 'L6'
        , "OrderOwnerProposedValue"
        ,"DataSetFrequency"
        , "OrderId"
        , "DataOwnerProposedValue"
        , "IsIncludedInOrder"
        , "QualityRating"
        , "OrderOwnerApproval", "DataOwnerApproval", "Comment"];

    const privateFieldTemplate = `<div class="ag-cell-label-container" role="presentation">
        <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>
        <div ref="eLabel" class="ag-header-cell-label" role="presentation">
            <div> 
                <span ref="eText" class="ag-header-cell-text" role="columnheader"></span><p style= "color: red; margin:0px">(proprietary)</p>
            </div>
            <span ref="eSortOrder" class="ag-header-icon ag-sort-order ag-hidden"></span>
            <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon ag-hidden"></span>
            <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon ag-hidden"></span>
            <span ref="eSortMixed" class="ag-header-icon ag-sort-mixed-icon ag-hidden"></span>
            <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon ag-hidden"></span>
            <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>
        </div>
    </div>`;


    const [confirmDialogObject, setConfirmDialogObject] = React.useState<any>({
        title: "",
        body: "",
        okHandler: () => undefined
    });
    const [selectedValuation, setSelectedValuation] = React.useState<any>(null);

    const valuationGridRef: any = useRef();

    const sideBar: SideBarDef = useMemo(() => {
        return {
            toolPanels: [
                {
                    id: 'columns',
                    labelDefault: 'Columns',
                    labelKey: 'columns',
                    iconKey: 'columns',
                    toolPanel: 'agColumnsToolPanel',
                    minWidth: 225,
                    maxWidth: 225,
                    width: 225
                },
                {
                    id: 'filters',
                    labelDefault: 'Filters',
                    labelKey: 'filters',
                    iconKey: 'filter',
                    toolPanel: 'agFiltersToolPanel',
                    minWidth: 270,
                    maxWidth: 270,
                    width: 270,
                },
            ],
            position: 'right',
            defaultToolPanel: 'columns'
        };
    }, []);

    const defaultColDef = useMemo(() => {
        return {
            resizable: true,
            wrapHeaderText: true,
            autoHeaderHeight: true,
            editable: false,
            headerCheckboxSelectionFilteredOnly: true
        };
    }, []);


    const gridOptions = {
        // PROPERTIES
        defaultColDef: defaultColDef,
        pagination: false,
        enableRowGroup: true,
        groupSelectsChildren: true,
        defaultExportParams: {
            columnGroups: true,
            fileName: 'name_of_file'
        },
        autoGroupColumnDef: {
            hide: true,
            cellRendererParams: {
                suppressCount: true,
            },
        },
        suppressAggFuncInHeader: true,
        groupingShowCounts: false,
        getRowHeight: () => { return 22 }
    }

    useEffect(() => {
        if (props.selectedCartOrder?.length > 0) {
            setIsExecutedOrder(props.selectedCartOrder[0].OrderStatus === 'Executed');
            SetisLoadedFromCart(true);
            props.setShowProgress(true);
            SetCartOrders(props.selectedCartOrder);
            let order = props.selectedCartOrder[0];
            setOperator(order.RequestedTo);
            setFirstSelectedOrderData(order);
            regenerateValuation(props.selectedCartOrder);
        }
    }, [props.IsValuation]);

    useEffect(() => {
        if (props.IsSummary && props.selectedReportClicked != '' && props.selectedReportLevel != '') {
            let reportData: any[] = [];
            switch (props.selectedReportLevel) {
                case "L1":
                    reportData = valuationTempData.filter((data: any) => { return data.L1 === props.selectedReportClicked });
                    break;
                case "L2":
                    reportData = valuationTempData.filter((data: any) => { return data.L2 === props.selectedReportClicked });
                    break;
                case "L3":
                    reportData = valuationTempData.filter((data: any) => { return data.L3 === props.selectedReportClicked });
                    break;
                case "L4":
                    reportData = valuationTempData.filter((data: any) => { return data.L4 === props.selectedReportClicked });
                    break;
                case "L5":
                    reportData = valuationTempData.filter((data: any) => { return data.L5 === props.selectedReportClicked });
                    break;
                case "L6":
                    reportData = valuationTempData.filter((data: any) => { return data.L6 === props.selectedReportClicked });
                    break;
            }

            setValuationData(reportData);
        } else {
            setValuationData(valuationTempData);
        }

    }, [props.selectedReportClicked]);

    useEffect(() => {
        const tradeType = cartOrders?.map((element: any) => { return element.TradeType });
        let isTradeOrder = tradeType.includes('Trade');
        setIsTradeOrder(isTradeOrder);

    }, [cartOrders]);

    useEffect(() => {
        if (operatorList?.length == 0) {
            loadInitialOperatorData(props.loggedInUserMailId);
            //loadInitialPrerequisiteData();
        }
        if (typeof (operatorList) != "undefined" && operatorList.length >= 0) {
            setOperator(operatorList[0]);
            setcolumnsPerfOpen(false);
            loadColumnPreferences();
        }
    }, [operatorList]);

    useEffect(() => {
        valuationGridRef.current?.api?.onFilterChanged();
    }, [filterValuationType]);

    useEffect(() => {
        loadValuationDataHandler();
    }, [operator]);

    const loadValuationDataHandler = () => {
        if (operator !== '' && operator != undefined && props.selectedCartOrder?.length == 0) {
            loadValuationData(operator, apis, reports, '');
        }
    }

    useEffect(() => {
        loadValuationTable();
    }, [valuationData, pivotMode]);

    useEffect(() => {
        if (props.tabIndex != TabOrder.Valuation) {
            if (isUserInValuation) {
                const edittedData = valuationData.filter((data) => { return data.isModified && (!data.isNew) });
                if (edittedData.length > 0) {
                    let confirmDialogObject = {
                        title: "Valuation",
                        body: "You have made some changes. Do you want to Save and Continue?, else click CANCEL",
                        okHandler: saveEditedDataHandler
                    };

                    setConfirmDialogObject(confirmDialogObject);
                    setOpenSaveConfirmDialog(true);
                    setIsUserInValuation(false);
                }
            }
        } else {
            setIsUserInValuation(true);
        }
    }, [props.tabIndex]);


    useEffect(() => {
        if (selectedPreference != null) {
            setColumnPreference(selectedPreference.PreferenceJson);
        }
        else {
            setInitialColumnPreferenceState();
        }
    }, [selectedPreference]);

    useEffect(() => {

    }, [apis]);

    useEffect(() => {
        if (selectedOrder.length > 0) {
            loadComments();
        }
    }, [selectedOrder]);

    useEffect(() => {
        if (orderComments && orderComments.length > 0) {
            loadValuationTable();
        }
    }, [orderComments]);

    useEffectOnce(() => {
        if (valuationGridRef !== undefined && valuationGridRef?.current !== undefined) {
            setTimeout(() => {
                valuationGridRef?.current?.api?.closeToolPanel();
            }, 200);
        }
    });

    useEffect(() => {
        if (screenUpdaterValuation && screenUpdaterValuation?.length > 0 && isEditted == false) {
            if (isJsonString(screenUpdaterValuation[0])) {
                let valuationChangeObject = JSON.parse(screenUpdaterValuation[0]);
                if (valuationChangeObject) {
                    updateNodeHandler(valuationChangeObject);
                }
            }
        }
    }, [screenUpdaterValuation]);

    useEffect(()=>{
        applyColumnPreference(columnPreference);
    },[columnPreference])

    const updateNodeHandler = (valuationObj: any) => {
        if (valuationObj !== null && valuationObj !== undefined) {
            showGifHandler();
            let nodeIndex = valuationData.findIndex(x => x.ValuationId === valuationObj.ValuationId);
            if (nodeIndex >= 0) {
                let rowNode = valuationGridRef?.current?.api?.getRowNode(nodeIndex);
                rowNode?.setData(valuationObj);
                valuationGridRef?.current?.api?.redrawRows();

                loadComments();
                setScreenUpdaterValuation([]);
            }
        }
    }

    const showGifHandler = () =>{
        setShowGif(true);
        setTimeout(() => {
            setShowGif(false);
        }, 9000);
    }

    const loadValuationTable = () => {
        if (valuationData !== undefined && valuationData.length >= 0) {
            let columnDefinitions = constructValuationColumnDefinitions();
            setcolumnDefinitions(columnDefinitions);
            if (valuationGridRef !== undefined && valuationGridRef.current !== undefined) {
                if (valuationGridRef.current.api !== 'undefined') {
                    valuationGridRef.current?.api?.setColumnDefs(columnDefinitions);
                    valuationGridRef.current?.api?.setRowData(valuationData);
                } else {
                    valuationGridRef.current.props.gridOptions.columnDefs = columnDefinitions;
                    valuationGridRef.current.props.gridOptions.rowData = valuationData;
                }

                onValidate();
                generatePinnedBottomData();
            } else {
                valuationGridRef.current?.api?.setColumnDefs([]);
                valuationGridRef.current?.api?.setRowData([]);
            }
        }
        else {
            valuationGridRef.current?.api?.setColumnDefs([]);
            valuationGridRef.current?.api?.setRowData([]);
        }
    }

    const loadComments = () => {
        async function getOrderComments() {
            let getEstimateCommentsUrl = '/OrderManagement/getOrderComments?OrderId='
                + selectedOrder[0];
            await api.API_GET(getEstimateCommentsUrl).then((response) => {
                if (response.data.length > 0) {
                    const _data = response.data;
                    if (orderComments == null || (orderComments && _data.length > orderComments?.length)) {
                        setOrderComments(_data);
                        loadValuationTable();
                    }
                } else {
                    setOrderComments([]);
                }
            }).catch(() => {
                setOrderComments([]);
            });
        }
        getOrderComments();
    }


    const regenerateValuation = async (orderData: any[]) => {

        const selectedReports: any[] = orderData.map((element: any) => { return element.SelectedReports });
        const _orderData: any[] = orderData.map((element: any) => {
            if (element.OrderId == 'N/A') {
                return element.Data
            }
            else {
                if (typeof (element.Data) === 'string') {
                    return JSON.parse(element.Data);
                }
                else {
                    return element.Data;
                }
            }
        });
        const apis: string[] = _orderData.map((element: any) => { return element.map((b: any) => { return b.API }); });
        const orderIds: any[] = orderData.map((element: any) => { return element.OrderId });
        setReports(selectedReports);
        setselectedOrder(orderIds);
        const uniqueValues = getUniqueApi(apis);
        setApis(uniqueValues.toString());

        const requestedOpertator = orderData.length > 0 ? orderData[0].RequestedTo : operator;

        let data: any = {}
        if (orderIds[0] == 'N/A') {
            data = {
                operator: requestedOpertator,
                user: props.loggedInUser,
                API: "'" + uniqueValues.join("','") + "'",
                environment: api.env
            };
        }
        else {
            data = {
                operator: requestedOpertator,
                user: props.loggedInUser,
                OrderId: orderIds.join(","),
                environment: api.env
            };
        }

        SetisLoadedFromCart(false);
        loadValuationData(requestedOpertator, uniqueValues.toString(), selectedReports, orderIds.toString());
    }

    const CalculateCurrentValue = (rowData: any) => {
        let currentValue = 0;
        if (rowData.DateOfAcquisition != null && rowData.CostFromAFE != null && rowData.Depreciation != null) {
            //Current Value = Max(0, AFE Cost – ( AFE Cost * Depreciation% ) *  ( (Today's Date - Acquisition Date) / 365 )   )
            let dateFrom = new Date();
            let dateTo = new Date(rowData.DateOfAcquisition);
            const MS_PER_DAY: number = 1000 * 60 * 60 * 24;
            const daysBetweenDates: number = Math.ceil((dateFrom.getTime() - dateTo.getTime()) / MS_PER_DAY);

            if (daysBetweenDates > 0) {
                const dateDiff = daysBetweenDates / 365;
                const cost = rowData.CostFromAFE * (rowData.Depreciation / 100);
                const value = (rowData.CostFromAFE - (cost * dateDiff));
                currentValue = Math.max(0, value);
            }
        }

        let _tableData = valuationData;
        let index = _tableData.findIndex(i => i.ValuationId === rowData.ValuationId);
        rowData.CurrentValue = currentValue;
        _tableData[index]["isModified"] = true;
        _tableData[index]["CurrentValue"] = currentValue;
        if (_tableData[index]["modifiedAttrs"] === undefined) {
            _tableData[index]["modifiedAttrs"] = "CurrentValue";
        } else {
            let modAttrs = _tableData[index]["modifiedAttrs"];
            modAttrs = modAttrs + ',' + "CurrentValue";
            _tableData[index]["modifiedAttrs"] = modAttrs;
        }
    }

    const postSortRows = (params: any) => {
        let rowNodes = params.nodes;
        let nextInsertPos = 0;
        for (let i = 0; i < rowNodes?.length; i++) {
            const isIncluded = rowNodes[i]?.data?.IsIncludedInOrder;
            if (isIncluded === 'Y') {
                rowNodes.splice(nextInsertPos, 0, rowNodes?.splice(i, 1)[0]);
                nextInsertPos++;
            }
        }
    }


    const fetchDisplayName = (columnName: string) => {
        let _displayName = columnName;
        let matchingPreferance = [];
        matchingPreferance = columnPreference.filter((element) => { return element.ColumnName == columnName });

        if (matchingPreferance.length > 0) {
            _displayName = matchingPreferance[0].DisplayName;
        }

        return _displayName;
    }

    const constructValuationColumnDefinitions = () => {
        let columnDefinitions: ITableGridColumnDefinition[] = [];
        const nonEditable = ["Api", "WellName", "L1", "L2", "L3", "L4", "L5", "L6", "Operator", "Dataset", "ReportYn", "OrderId", "ReportDisplayOrder", "OrderOwnerProposedValue"];
        const rowGroupsColumns = ["L1", "L2", "L3", "L4", "L5", "L6"];
        const pivotColumns = ["API", "DateOfAcquisition"];
        const aggrSumColumns = ["CostFromAFE", "CurrentValue", "OrderOwnerProposedValue", "DataOwnerProposedValue", "OasisCostFromAFE"];
        const aggrAvgColumns = ["QualityRating"];
        const dollarColumns = ["CostFromAFE", "CurrentValue", "OrderOwnerProposedValue", "DataOwnerProposedValue","OasisCostFromAFE"];
        const fixDecimalTwoColumns = ["Depreciation"];

        displayColumns.forEach((column) => {
            if (column.toLowerCase().includes('rating')) {
                let columnDefinition: ITableGridColumnDefinition = {
                    field: column,
                    //type: 'editableColumn',
                    headerName: fetchDisplayName(column),
                    headerTooltip: fetchDisplayName(column),
                    headerComponentParams: columnHeaderTemplate(fetchDisplayName(column)),
                    sortable: true,
                    filter: 'agNumberColumnFilter',
                    editable: true,
                    cellStyle: { textAlign: 'left' },
                    aggFunc: aggrAvgColumns.includes(column) ? "avg" : "",
                    enablePivot: pivotMode ? true : false,
                    enableValue: true,
                    enableRowGroup: true,
                    cellRendererSelector: (params : any) => {
                        if (params.node.rowPinned) {
                            return undefined;
                        } else {
                                // rows that are not pinned 
                                return {
                                    component: QualityRatingRenderer,
                                    params: {
                                        isReadonly: valuationGridRef?.current?.props?.pivotMode ? true : false,
                                        onRatingChange: (value: any, rowIndex: any, rowData: any) => {
                                            let _tableData = valuationData;
                                            let index = _tableData.indexOf(rowData);
                                            rowData[column] = value;
                                            _tableData[index] = rowData;
                                            _tableData[index]["isModified"] = true;

                                            if (_tableData[index]["modifiedAttrs"] === undefined) {
                                                _tableData[index]["modifiedAttrs"] = column;
                                            } else {
                                                let modAttrs = _tableData[index]["modifiedAttrs"];
                                                modAttrs = modAttrs + ',' + column;
                                                _tableData[index]["modifiedAttrs"] = modAttrs;
                                            }
                                        }
                                    }
                            };
                        }
                    },
                    hide: isColumnHidden(column),
                    cellClass: ['ag-cell-custom'],
                    suppressToolPanel: false
                };

                columnDefinitions.push(columnDefinition);
            } else if (column.toLowerCase().includes('dateofacquisition')) {
                columnDefinitions.push({
                    field: column,
                    //type: 'editableColumn',
                    width: 200,
                    suppressSizeToFit: true,
                    singleClickEdit: false,
                    headerName: fetchDisplayName(column),
                    headerTooltip: fetchDisplayName(column),
                    headerComponentParams: columnHeaderTemplate(fetchDisplayName(column)),
                    cellEditorPopup: true,
                    editable: true,
                    enableRowGroup: true,
                    cellEditor: DatePickerEditor,
                    cellEditorParams: {
                        column: column,
                        onDateValueSelected: (value: any, rowIndex: any, rowData: any) => {
                            let _tableData = valuationData;
                            let index = _tableData.findIndex(i => i.ValuationId === rowData.ValuationId);
                            rowData[column] = value;
                            CalculateCurrentValue(rowData);
                            _tableData[index] = rowData;
                            _tableData[index]["isModified"] = true;
                            updateTotalRowNode();
                        }
                    },
                    cellRendererSelector: (params : any) => {
                        if (params.node.rowPinned || pivotMode) {
                            return undefined;
                        } else {
                                // rows that are not pinned 
                                return {
                                    component: DateTimeRenderer,
                                    params: {
                                        showTime: false
                                    }
                            };
                        }
                    },
                    cellClass: ['ag-cell-custom'],
                    suppressToolPanel: false,
                    hide: isColumnHidden(column),
                    filter: (column.DataType !== "Int32" && column.DataType !== "Int64") ? 'agMultiColumnFilter' : 'agNumberColumnFilter'
                });
            }
            else if (column.toLowerCase().includes('currentvalue')) {
                let columnDefinition: ITableGridColumnDefinition = {
                    field: column,
                    //type: 'editableColumn',
                    headerName: fetchDisplayName(column),
                    headerTooltip: fetchDisplayName(column),
                    isPropreitary : true,
                    headerComponentParams: {
                        menuIcon: 'fa-bars',
                        template: privateFieldTemplate
                    },
                    cellClass: ['ag-cell-custom'],
                    cellStyle : (params : any) => {
                        if (params.data.IsIncludedInOrder === 'Y' && (params.value === null || params.value === 0)) {
                            return {backgroundColor: '#ffc0cb'};
                        }
                        return null;
                    },
                    sortable: true,
                    pivot: pivotMode && pivotColumns.includes(column) ? true : false,
                    rowGroup: pivotMode && rowGroupsColumns.includes(column) ? true : false,
                    enableRowGroup: true,
                    enableValue: true,
                    aggFunc: pivotMode ? "sum" : aggrSumColumns.includes(column) ? "currentValueSum" : (aggrAvgColumns.includes(column) ? "avg" : ""),
                    enablePivot: pivotMode ? true : false,
                    filter: true,
                    editable: false,
                    hide: isColumnHidden(column),
                    valueFormatter: applyValueFormatting(column)
                };

                columnDefinitions.push(columnDefinition);
            } else if (column.toLowerCase().includes('oasiscostfromafe')) {
                let columnDefinition: ITableGridColumnDefinition = {
                    field: column,
                    //type: 'editableColumn',
                    headerName: fetchDisplayName(column),
                    headerTooltip: fetchDisplayName(column),
                    headerComponentParams: columnHeaderTemplate(fetchDisplayName(column)),
                    cellClass: ['ag-cell-custom'],
                    sortable: true,
                    pivot: pivotMode && pivotColumns.includes(column) ? true : false,
                    rowGroup: pivotMode && rowGroupsColumns.includes(column) ? true : false,
                    enableRowGroup: true,
                    enableValue: true,
                    aggFunc: pivotMode ? "sum" : aggrSumColumns.includes(column) ? "oasiscostSum" : (aggrAvgColumns.includes(column) ? "avg" : ""),
                    enablePivot: pivotMode ? true : false,
                    filter: true,
                    editable: false,
                    hide: isColumnHidden(column),
                    valueFormatter: applyValueFormatting(column)
                };

                columnDefinitions.push(columnDefinition);
            } else if (column.toLowerCase().includes('costfromafe')) {
                let columnDefinition: ITableGridColumnDefinition = {
                    field: column,
                    //type: 'editableColumn',
                    headerName: fetchDisplayName(column),
                    headerTooltip: fetchDisplayName(column),
                    isPropreitary : true,
                    headerComponentParams: {
                        menuIcon: 'fa-bars',
                        template: privateFieldTemplate
                    },
                    cellClass: ['ag-cell-custom'],
                    sortable: true,
                    pivot: pivotMode && pivotColumns.includes(column) ? true : false,
                    rowGroup: pivotMode && rowGroupsColumns.includes(column) ? true : false,
                    enableRowGroup: true,
                    enableValue: true,
                    aggFunc: pivotMode ? "sum" : aggrSumColumns.includes(column) ? "costSum" : (aggrAvgColumns.includes(column) ? "avg" : ""),
                    enablePivot: pivotMode ? true : false,
                    filter: true,
                    editable: !nonEditable.includes(column),
                    hide: isColumnHidden(column),
                    valueFormatter: applyValueFormatting(column)
                };

                columnDefinitions.push(columnDefinition);
            }
            else if (column.toLowerCase().includes('orderownerproposedvalue')) {
                if (selectedOrder.length > 0) {
                    let columnDefinition: ITableGridColumnDefinition = {
                        field: column,
                        //type: 'editableColumn',
                        headerName: fetchDisplayName('OrderOwnerProposedValue'),
                        headerTooltip: fetchDisplayName('OrderOwnerProposedValue'),
                        headerComponentParams: columnHeaderTemplate(fetchDisplayName('OrderOwnerProposedValue')),
                        cellClass: ['ag-cell-custom'],
                        sortable: true,
                        pivot: pivotMode && pivotColumns.includes(column) ? true : false,
                        rowGroup: pivotMode && rowGroupsColumns.includes(column) ? true : false,
                        enableRowGroup: true,
                        enableValue: true,
                        aggFunc: pivotMode ? "sum" : aggrSumColumns.includes(column) ? "proposedEstimatesSum" : (aggrAvgColumns.includes(column) ? "avg" : ""),
                        enablePivot: pivotMode ? true : false,
                        filter: true,
                        editable: !nonEditable.includes(column),
                        hide: isColumnHidden(column),
                        valueFormatter: applyValueFormatting(column)
                    };

                    columnDefinitions.push(columnDefinition);
                }
            }
            else if (column.toLowerCase().includes('isincludedinorder')) {
                if (selectedOrder.length > 0) {
                    let columnDefinition: ITableGridColumnDefinition = {
                        field: 'IsIncludedInOrder',
                        headerName: fetchDisplayName('IsIncludedInOrder'),
                        headerTooltip: fetchDisplayName('IsIncludedInOrder'),
                        headerComponentParams: columnHeaderTemplate(fetchDisplayName('IsIncludedInOrder')),
                        sortable: true,
                        width: 120,
                        filter: (column.DataType !== "Int32" && column.DataType !== "Int64") ? 'agMultiColumnFilter' : 'agNumberColumnFilter',
                        editable: false,
                        pivot: true,
                        enablePivot: pivotMode ? true : false,
                        enableRowGroup: true,
                        enableValue: false,
                        rowGroup: false,
                        hide: false,
                        aggFunc: "",

                        cellRendererSelector: (params : any) => {
                            if (params.node.rowPinned || pivotMode) {
                                return undefined;
                            } else {
                                    // rows that are not pinned 
                                    return {
                                        component: ExcludeIncludeOrderRenderer,
                                        params: {
                                            IsDisabled: true,
                                            onExcludeInclude: (value: any, rowIndex: any, rowData: any) => {
                                            }
                                        }
                                };
                            }
                        },
                        cellClass: ['ag-cell-custom'],
                    };
                    columnDefinitions.push(columnDefinition);
                }
            }
            else if (column.toLowerCase().includes('dataownerproposedvalue')) {
                if (selectedOrder.length > 0) {
                    let columnDefinition: ITableGridColumnDefinition = {
                        field: column,
                        //type: 'editableColumn',
                        headerName: fetchDisplayName('DataOwnerProposedValue'),
                        headerTooltip: fetchDisplayName('DataOwnerProposedValue'),
                        headerComponentParams: columnHeaderTemplate(fetchDisplayName('DataOwnerProposedValue')),
                        cellClass: ['ag-cell-custom'],
                        sortable: true,
                        pivot: pivotMode && pivotColumns.includes(column) ? true : false,
                        rowGroup: pivotMode && rowGroupsColumns.includes(column) ? true : false,
                        enableRowGroup: true,
                        enableValue: true,
                        aggFunc: pivotMode ? "sum" : aggrSumColumns.includes(column) ? "proposedValueSum" : (aggrAvgColumns.includes(column) ? "avg" : ""),
                        enablePivot: pivotMode ? true : false,
                        filter: true,
                        editable: !nonEditable.includes(column),
                        cellStyle : (params : any) => {
                            if (params.data.IsIncludedInOrder === 'Y' && (params.value === null || params.value === 0)) {
                                return {backgroundColor: '#ffc0cb'};
                            }
                            return null;
                        },
                        cellRendererSelector: (params : any) => {
                            if (params.node.rowPinned || pivotMode) {
                                return undefined;
                            } else {
                                    // rows that are not pinned 
                                    return {
                                        component: DollarValueRenderer,
                                        params: {
                                            ScaleValues: (applySame: boolean, scalePercent: number, applyRound: boolean, value: number, rowData: any, scaleColumn : any) => {
                                                let _tableData = valuationData;
                                                let index = _tableData.findIndex(i => i.ValuationId === rowData.ValuationId);
                                                let currentVal = rowData[scaleColumn];
                                                let newValue = applyRound ? Math.round(currentVal + (currentVal * (scalePercent / 100))) : currentVal + (currentVal * (scalePercent / 100));
                                                if (applySame){
                                                    let applyToAll = false;
                                                    valuationGridRef.current!.api.forEachNodeAfterFilterAndSort((params: any) => {
                                                    let valuationId = params.data?.ValuationId;
                                                    if(valuationId === rowData.ValuationId){
                                                        applyToAll = true;
                                                    }
                
                                                    if(applyToAll) {
                                                        let element = _tableData.find(i => i.ValuationId === valuationId);
                                                        let currentVal = element[scaleColumn];
                                                        if (element["IsIncludedInOrder"].toLowerCase() == "y" && currentVal != null) {
                                                            let newValue = applyRound ? Math.round(currentVal + (currentVal * (scalePercent / 100))) : currentVal + (currentVal * (scalePercent / 100));
                                                            element[column] = newValue;
                                                            element["isModified"] = true;
                
                                                            if (element["modifiedAttrs"] === undefined) {
                                                                element["modifiedAttrs"] = column;
                                                            } else {
                                                                let modAttrs = element["modifiedAttrs"];
                                                                modAttrs = modAttrs + ',' + column;
                                                                element["modifiedAttrs"] = modAttrs;
                                                            }
                                                        }
                                                    }
                                                });
                                                } else {
                                                    if (index > -1) {
                                                        _tableData[index][column] = newValue;
                                                        _tableData[index]["isModified"] = true;
                
                                                        if (_tableData[index]["modifiedAttrs"] === undefined) {
                                                            _tableData[index]["modifiedAttrs"] = column;
                                                        } else {
                                                            let modAttrs = _tableData[index]["modifiedAttrs"];
                                                            modAttrs = modAttrs + ',' + column;
                                                            _tableData[index]["modifiedAttrs"] = modAttrs;
                                                        }
                                                    }
                                                } 
                                                valuationGridRef.current?.api?.setRowData(_tableData);
                                                updateTotalRowNode();
                                                onValidate();
                                            }
                                        }
                                };
                            }
                        },
                        hide: isColumnHidden(column),
                        valueFormatter: applyValueFormatting(column)
                };

                columnDefinitions.push(columnDefinition);
                }
            }
            else if (column.toLowerCase().includes('orderid')) {
                if (selectedOrder.length > 0) {
                    let columnDefinition: ITableGridColumnDefinition = {
                        field: column,
                        //type: 'editableColumn',
                        headerName: fetchDisplayName(column),
                        headerTooltip: fetchDisplayName(column),
                        headerComponentParams: columnHeaderTemplate(fetchDisplayName(column)),
                        cellClass: ['ag-cell-custom'],
                        sortable: true,
                        cellStyle: { textAlign: 'left' },
                        pivot: pivotMode && pivotColumns.includes(column) ? true : false,
                        rowGroup: pivotMode && rowGroupsColumns.includes(column) ? true : false,
                        enableRowGroup: false,
                        aggFunc: aggrSumColumns.includes(column) ? "costSum" : (aggrAvgColumns.includes(column) ? "avg" : ""),
                        enablePivot: pivotMode ? true : false,
                        filter: true,
                        editable: !nonEditable.includes(column),
                        hide: isColumnHidden(column),
                        valueFormatter: applyValueFormatting(column)
                    };

                    columnDefinitions.push(columnDefinition);
                }
            }
            else if (column.toLowerCase().includes('dataownerapproval')) {
                if (selectedOrder.length > 0) {

                    const pickListColumnDefinition = handlePickListConstruction(column, "Approval");
                    if (pickListColumnDefinition === undefined || pickListColumnDefinition === false) {
                    } else {
                        pickListColumnDefinition.headerName = column;
                        pickListColumnDefinition.headerTooltip = column;
                        columnDefinitions.push(pickListColumnDefinition);
                    }
                }
            }

            else if (column.toLowerCase().includes('orderownerapproval')) {
                if (selectedOrder.length > 0) {
                    let columnDefinition: ITableGridColumnDefinition = {
                        field: 'OrderOwnerApproval',
                        //type: 'editableColumn',
                        headerName: fetchDisplayName('OrderOwnerApproval'),
                        headerTooltip: fetchDisplayName('OrderOwnerApproval'),
                        headerComponentParams: columnHeaderTemplate(fetchDisplayName('OrderOwnerApproval')),
                        cellClass: ['ag-cell-custom'],
                        sortable: true,
                        cellStyle: { textAlign: 'left' },
                        pivot: pivotMode && pivotColumns.includes('OrderOwnerApproval') ? true : false,
                        rowGroup: pivotMode && rowGroupsColumns.includes('OrderOwnerApproval') ? true : false,
                        enablePivot: pivotMode ? true : false,
                        enableRowGroup: false,
                        filter: true,
                        editable: false,
                        hide: isColumnHidden('OrderOwnerApproval'),
                    };
                    columnDefinitions.push(columnDefinition);
                }
            }
            else if (column.toLowerCase()=== 'dataset') {
                let columnDefinition: ITableGridColumnDefinition = {
                    field: 'ReportName',
                    //type: 'editableColumn',
                    headerName: fetchDisplayName(column),
                    headerTooltip: fetchDisplayName(column),
                    headerComponentParams: columnHeaderTemplate(fetchDisplayName(column)),
                    cellClass: ['ag-cell-custom'],
                    sortable: true,
                    cellStyle: { textAlign: 'left' },
                    cellRendererSelector: (params : any) => {
                        if (params.node.rowPinned || pivotMode) {
                            return undefined;
                        } else {
                                // rows that are not pinned 
                                return {
                                    component: ReportNameRenderer,
                                    params: {
                                        ShowInfo: true,
                                        DataAuditData: valuationDataAudit,
                                        ReportConfig: reportConfigurations
                                    }
                            };
                        }
                    },
                    pivot: pivotMode && pivotColumns.includes(column) ? true : false,
                    rowGroup: pivotMode && rowGroupsColumns.includes(column) ? true : false,
                    aggFunc: aggrSumColumns.includes(column) ? "sum" : (aggrAvgColumns.includes(column) ? "avg" : ""),
                    enableRowGroup: true,
                    enablePivot: pivotMode ? true : false,
                    filter: true,
                    editable: !nonEditable.includes(column),
                    hide: isColumnHidden(column),
                    valueFormatter: applyValueFormatting(column)
                };
                columnDefinitions.push(columnDefinition);
            } else if (column.toLowerCase() === 'datasetfrequency') {
                if (selectedOrder.length > 0) {
                    let columnDefinition: ITableGridColumnDefinition = {
                        field: 'DataSetFrequency',
                        //type: 'editableColumn',
                        headerName: fetchDisplayName('DataSetFrequency'),
                        headerTooltip: fetchDisplayName('DataSetFrequency'),
                        headerComponentParams: columnHeaderTemplate(fetchDisplayName('DataSetFrequency')),
                        cellClass: ['ag-cell-custom'],
                        cellStyle: { textAlign: 'left' },
                        sortable: true,
                        pivot: pivotMode && pivotColumns.includes('DataSetFrequency') ? true : false,
                        rowGroup: pivotMode && rowGroupsColumns.includes('DataSetFrequency') ? true : false,
                        enablePivot: pivotMode ? true : false,
                        filter: true,
                        editable: false,
                        hide: isColumnHidden('DataSetFrequency'),
                    };
                    columnDefinitions.push(columnDefinition);
                }
            } 
            else if (column.toLowerCase().includes('depreciation')) {
                let columnDefinition: ITableGridColumnDefinition = {
                    field: column,
                    //type: 'editableColumn',
                    headerName: fetchDisplayName(column),
                    headerTooltip: fetchDisplayName(column),
                    isPropreitary : true,
                    headerComponentParams: {
                        menuIcon: 'fa-bars',
                        template: privateFieldTemplate
                    },
                    cellClass: ['ag-cell-custom'],
                    sortable: true,
                    pivot: pivotMode && pivotColumns.includes(column) ? true : false,
                    rowGroup: pivotMode && rowGroupsColumns.includes(column) ? true : false,
                    enableRowGroup: true,
                    aggFunc: aggrSumColumns.includes(column) ? "sum" : (aggrAvgColumns.includes(column) ? "avg" : ""),
                    enablePivot: pivotMode ? true : false,
                    filter: true,
                    editable: !nonEditable.includes(column),
                    hide: isColumnHidden(column),
                    valueFormatter: applyValueFormatting(column)
                };
                columnDefinitions.push(columnDefinition);
            }
            else if (column.toLowerCase().includes('l6')
                || column.toLowerCase().includes('l5')
                || column.toLowerCase().includes('l4')
                || column.toLowerCase().includes('l3')
                || column.toLowerCase().includes('l2')
                || column.toLowerCase().includes('l1')) {
                let columnDefinition: ITableGridColumnDefinition = {
                    field: column,
                    //type: 'editableColumn',
                    headerName: fetchDisplayName(column),
                    headerTooltip: fetchDisplayName(column),
                    cellClass: ['ag-cell-custom'],
                    cellRenderer: ReportNameRenderer,
                    cellRendererParams: {
                        ShowInfo: false,
                        DataAuditData: valuationDataAudit,
                        ReportConfig: reportConfigurations
                    },
                    sortable: true,
                    cellStyle: { textAlign: 'left' },
                    enableRowGroup: true,
                    pivot: pivotMode && pivotColumns.includes(column) ? true : false,
                    rowGroup: pivotMode && rowGroupsColumns.includes(column) ? true : false,
                    aggFunc: aggrSumColumns.includes(column) ? "sum" : (aggrAvgColumns.includes(column) ? "avg" : ""),
                    enablePivot: pivotMode ? true : false,
                    filter: true,
                    editable: !nonEditable.includes(column),
                    hide: isColumnHidden(column),
                    valueFormatter: applyValueFormatting(column)
                };
                columnDefinitions.push(columnDefinition);
            } else if (column.toLowerCase().includes('comment')) {
                if (selectedOrder.length > 0) {
                    columnDefinitions.unshift({
                        field: "Comment",
                        //type: 'editableColumn',
                        width: 120,
                        headerName: fetchDisplayName("Comment"),
                        headerTooltip: fetchDisplayName("Comment"),
                        headerComponentParams: columnHeaderTemplate(fetchDisplayName('Comment')),
                        tooltipField: "Comment",
                        cellClass: ['ag-cell-custom'],
                        sortable: true,
                        pivot: false,
                        rowGroup: false,
                        hide: false,
                        enablePivot: false,
                        aggFunc: "",
                        filter: 'agTextColumnFilter',
                        cellRendererSelector: (params : any) => {
                            if (params.node.rowPinned || pivotMode) {
                                return undefined;
                            } else {
                                    // rows that are not pinned 
                                    return {
                                        component: AddCommentRenderer,
                                        params: {
                                            setSelectedEstimate: (selectedValuation: any) => {
                                                setSelectedValuation(selectedValuation)
                                            },
                                            orderComments: orderComments,
                                            setCommentOpen: setCommentOpen
                                        }
                                };
                            }
                        }
                    });
                }
            }
            else {
                let columnDefinition: ITableGridColumnDefinition = {
                    field: column,
                    //type: 'editableColumn',
                    headerName: fetchDisplayName(column),
                    headerTooltip: fetchDisplayName(column),
                    headerComponentParams: columnHeaderTemplate(fetchDisplayName(column)),
                    cellClass: ['ag-cell-custom'],
                    sortable: true,
                    cellStyle: { textAlign: 'left' },
                    pivot: pivotMode && pivotColumns.includes(column) ? true : false,
                    rowGroup: pivotMode && rowGroupsColumns.includes(column) ? true : false,
                    enableRowGroup: true,
                    aggFunc: aggrSumColumns.includes(column) ? "sum" : (aggrAvgColumns.includes(column) ? "avg" : ""),
                    enablePivot: pivotMode ? true : false,
                    filter: true,
                    editable: !nonEditable.includes(column),
                    hide: isColumnHidden(column),
                    valueFormatter: applyValueFormatting(column)
                };

                columnDefinitions.push(columnDefinition);
            }
        });

        columnDefinitions = rearrangeColumnsBasedOnPreference(columnDefinitions, []);
        let hiddenItem = columnDefinitions.filter((elem: any) => { return !elem.hide });
        hiddenItem[0].headerCheckboxSelection = true;
        hiddenItem[0].checkboxSelection = true;

        return columnDefinitions;
    }

    const generatePinnedBottomData = () => {
        let result : any = {};
        if(valuationGridRef){
            let colDefs = valuationGridRef.current?.api?.getColumnDefs();
            let firstColumn = colDefs?.find((ele:any) => {return !ele.hide});
            valuationGridRef.current?.columnApi?.getAllGridColumns().forEach((item: any) => {
                if(firstColumn && (item.colId == firstColumn.field || item.colId === firstColumn.headerName)){
                    result[item.colId] = "Total";
                } else {
                    result[item.colId] = null;
                }
            });
    
            calculatePinnedBottomData(result);
            if(pivotMode){
                valuationGridRef.current?.api?.setPinnedTopRowData([]);
                //valuationGridRef.current?.api?.setPinnedTopRowData([params?.node?.aggData]);
            }
            else {
                valuationGridRef.current?.api?.setPinnedTopRowData([result]);
            }
        }
    }

    const calculatePinnedBottomData = (result: any) => {
        let costfromafe = 0;
        let oasiscostfromafe = 0;
        let currentValueSum = 0;
        let proposedvalueSum = 0;
        let dataOwnervalueSum = 0;
        let transactionFee = 0;

        if (selectedOrder.length > 0) {
                valuationData.forEach((node: any) => {
                    currentValueSum += node?.IsIncludedInOrder === 'Y' ? node?.CurrentValue : 0;
                    costfromafe += !(node?.IsIncludedInOrder === 'Y') ? 0 : isNaN(node?.CostFromAFE) ? 0 : Number(node?.CostFromAFE);
                    oasiscostfromafe += !(node?.IsIncludedInOrder === 'Y') ? 0 : isNaN(node?.OasisCostFromAFE) ? 0 : Number(node?.OasisCostFromAFE);
                    proposedvalueSum += !(node?.IsIncludedInOrder === 'Y') ? 0 : isNaN(node?.OrderOwnerProposedValue) ? 0 : Number(node?.OrderOwnerProposedValue);
                    dataOwnervalueSum += !(node?.IsIncludedInOrder === 'Y') ? 0 : isNaN(node?.DataOwnerProposedValue) ? 0 : Number(node?.DataOwnerProposedValue);
                    let value =  Math.round(0.2 * oasiscostfromafe)
                    transactionFee = value > 5000 ? 5000 : value;
                    setTransactionFees(transactionFee);
            });
        } else {
            valuationData.forEach((node: any) => {
                currentValueSum += node?.CurrentValue;
                costfromafe += isNaN(node?.CostFromAFE) ? 0 : Number(node?.CostFromAFE);
                oasiscostfromafe += isNaN(node?.OasisCostFromAFE) ? 0 : Number(node?.OasisCostFromAFE);

                proposedvalueSum += isNaN(node?.OrderOwnerProposedValue) ? 0 : Number(node?.OrderOwnerProposedValue);
                dataOwnervalueSum += isNaN(node?.DataOwnerProposedValue) ? 0 : Number(node?.DataOwnerProposedValue);
            });
        }

        result['CostFromAFE'] = costfromafe;
        result['OasisCostFromAFE'] = oasiscostfromafe;

        result['CurrentValue'] = currentValueSum;
       
        if (selectedOrder.length > 0) {
            result['OrderOwnerProposedValue'] = proposedvalueSum;
            result['DataOwnerProposedValue'] = dataOwnervalueSum;
        }
    }

    const getRowStyle = (params: any) => {
        let rowStyle: Record<string, string> = {};

        if (params?.node?.data?.isModified) {
            rowStyle.fontStyle = 'italic';
            rowStyle.fontWeight = 'bold';
        }

        if (params.node.rowPinned) {
            rowStyle.pointerEvents = 'none';
            rowStyle.fontWeight = 'bold';
            rowStyle.backgroundColor = '#87CEEB';
          }

        if (selectedOrder.length > 0) {
            let bgColor = params.node?.data?.IsIncludedInOrder == 'Y' ? '#fff' : 'lightgrey';
            rowStyle.backgroundColor = bgColor;
            if (isExecutedOrder) {
                rowStyle.pointerEvents = 'none';
            }
        }
        else {
            rowStyle.backgroundColor = '#fff';
        }

        return rowStyle;
    }

    const handlePickListConstruction = (columnName: string, pickListColumnName: string) => {
        if (pickListDefinitions !== undefined && pickListDefinitions.length > 0) {
            const pickListMatch = pickListDefinitions
                .filter((ele: any) => {
                    return ele.ColumnName.toLowerCase()
                        === pickListColumnName.toLowerCase() &&
                        ele.SchemaName === 'orders' &&
                        ele.TableName === 'Estimate'
                });
            if (pickListMatch.length > 0) {
                if (pickListMatch[0].ListValues[0] === '[') {
                    const tradeType = cartOrders.map((element: any) => { return element.TradeType });

                    const pickListColumnDefinition = {
                        field: columnName,
                        //type: 'editableColumn',
                        headerName: fetchDisplayName(columnName),
                        headerTooltip: fetchDisplayName(columnName),
                        headerComponentParams: columnHeaderTemplate(fetchDisplayName(columnName)),
                        sortable: true,
                        filter: true,
                        cellEditorPopup: true,
                        singleClickEdit: false,
                        suppressSizeToFit: true,
                        enableRowGroup: true,
                        editable: true,
                        cellStyle : (params : any) => {
                            if (pickListColumnName === 'Approval' && params.data.IsIncludedInOrder === 'Y' && (params.value === null || params.value === '')) {
                                return {backgroundColor: '#ffc0cb', textAlign: 'left'};
                            }
                            return { textAlign: 'left' };
                        },
                        cellRendererSelector: (params : any) => {
                            if (params.node.rowPinned || pivotMode) {
                                return undefined;
                            } else {
                                    // rows that are not pinned 
                                    return {
                                        component: ApproveDropDownCellEditor,
                                        params: {
                                            IsRentalOrPurchaseOrder : tradeType.includes('Rental') || tradeType.includes('Purchase'),
                                            DropDownOptionsJSON: pickListMatch[0].ListValues,
                                            onDropDownValueSelected: (value: any, rowData: any, applySameBelow: boolean, CopyValues : boolean) => {
                                                const rowIndex = valuationData.findIndex((element) => element.ValuationId === rowData.ValuationId);
                                                let _tableData = valuationData;
                
                                                if (applySameBelow) {
                
                                                    let applyToAll = false;
                                                    valuationGridRef.current!.api.forEachNodeAfterFilterAndSort((params: any) => {
                                                        let valuationId = params.data?.ValuationId;
                                                        if (valuationId === rowData.ValuationId) {
                                                            applyToAll = true;
                                                        }
                
                                                        if (applyToAll) {
                                                            let element = _tableData.find(i => i.ValuationId === valuationId);
                                                            if (element["IsIncludedInOrder"].toLowerCase() == "y") {
                                                                element[columnName] = value;
                                                                element["isModified"] = true;
                    
                                                                if (element["modifiedAttrs"] === undefined) {
                                                                    element["modifiedAttrs"] = columnName;
                                                                } else {
                                                                    let modAttrs = element["modifiedAttrs"];
                                                                    modAttrs = modAttrs + ',' + columnName;
                                                                    element["modifiedAttrs"] = modAttrs;
                                                                }


                                                                if(columnName.toLowerCase().includes('dataownerapproval') &&  value.toUpperCase() == 'ACCEPT'){
                                                                    element["OrderOwnerApproval"] = value;
                                                                    element["isModified"] = true;
        
                                                                    if (element["modifiedAttrs"] === undefined) {
                                                                        element["modifiedAttrs"] = "OrderOwnerApproval";
                                                                    } else {
                                                                        let modAttrs = element["modifiedAttrs"];
                                                                        modAttrs = modAttrs + ',' + "OrderOwnerApproval";
                                                                        element["modifiedAttrs"] = modAttrs;
                                                                    }
                                                                } 

                                                                if(CopyValues){
                                                                    element["DataOwnerProposedValue"] = element["OrderOwnerProposedValue"];
                                                                    element["isModified"] = true;
        
                                                                    if (element["modifiedAttrs"] === undefined) {
                                                                        element["modifiedAttrs"] = "DataOwnerProposedValue";
                                                                    } else {
                                                                        let modAttrs = element["modifiedAttrs"];
                                                                        modAttrs = modAttrs + ',' + "DataOwnerProposedValue";
                                                                        element["modifiedAttrs"] = modAttrs;
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    });
                
                                                    valuationGridRef.current?.api?.setRowData(_tableData);
                                                } else {
                                                    _tableData[rowIndex][columnName] = value;
                                                    _tableData[rowIndex]["isModified"] = true;
                
                                                    if (_tableData[rowIndex]["modifiedAttrs"] === undefined) {
                                                        _tableData[rowIndex]["modifiedAttrs"] = columnName;
                                                    } else {
                                                        let modAttrs = _tableData[rowIndex]["modifiedAttrs"];
                                                        modAttrs = modAttrs + ',' + columnName;
                                                        _tableData[rowIndex]["modifiedAttrs"] = modAttrs;
                                                    }

                                                    if(columnName.toLowerCase().includes('dataownerapproval') &&  value.toUpperCase() == 'ACCEPT'){
                                                        _tableData[rowIndex]["OrderOwnerApproval"] = value;
                                                        _tableData[rowIndex]["isModified"] = true;
    
                                                        if (_tableData[rowIndex]["modifiedAttrs"] === undefined) {
                                                            _tableData[rowIndex]["modifiedAttrs"] = "OrderOwnerApproval";
                                                        } else {
                                                            let modAttrs = _tableData[rowIndex]["modifiedAttrs"];
                                                            modAttrs = modAttrs + ',' + "OrderOwnerApproval";
                                                            _tableData[rowIndex]["modifiedAttrs"] = modAttrs;
                                                        }
                                                    } 

                                                    if(CopyValues){
                                                        _tableData[rowIndex]["DataOwnerProposedValue"] = _tableData[rowIndex]["OrderOwnerProposedValue"];
                                                        _tableData[rowIndex]["isModified"] = true;
    
                                                        if (_tableData[rowIndex]["modifiedAttrs"] === undefined) {
                                                            _tableData[rowIndex]["modifiedAttrs"] = "DataOwnerProposedValue";
                                                        } else {
                                                            let modAttrs = _tableData[rowIndex]["modifiedAttrs"];
                                                            modAttrs = modAttrs + ',' + "DataOwnerProposedValue";
                                                            _tableData[rowIndex]["modifiedAttrs"] = modAttrs;
                                                        }
                                                    }
                                                    valuationGridRef.current?.api?.setRowData(_tableData);
                                                }

                                                onValidate();

                                            }
                                        }
                                };
                            }
                        },
                        suppressToolPanel: false,
                        cellClass: ['ag-cell-custom']
                    };

                    return pickListColumnDefinition;
                }
            }
        }
        else {
            return false;
        }
    }

    const onValidate = () => {
        if(cartOrders.length > 0) {
            let columns : any[] = [];
            valuationGridRef?.current!.api?.forEachNodeAfterFilterAndSort((params: any) => {
                if(params.data.IsIncludedInOrder === 'Y') {
                    if(params.data.CurrentValue === 0 || params.data.CurrentValue === null){
                        columns.push('CurrentValue');
                    }
                    if(params.data.DataOwnerProposedValue === 0 || params.data.DataOwnerProposedValue === null){
                        columns.push('DataOwnerProposedValue');
                    }
                    if(params.data.DataOwnerApproval === '' || params.data.DataOwnerApproval === null){
                        columns.push('DataOwnerApproval');
                    }
                }
            });
    
            if(columns.length > 0){
                let unique = columns.filter((item, i, ar) => ar.indexOf(item) === i);
                setMissingColumns(unique);
            } else {
                setMissingColumns([]);
            }
        }
    }


    const aggFuncs = {
        'currentValueSum': (params: any) => {
            let data = params.rowNode?.childrenAfterGroup;
            let sum = 0;
            data.forEach((value: any) => (sum += (value?.data?.IsIncludedInOrder === 'Y') ? value?.data?.CurrentValue : 0));
            return sum;
        },
        'costSum': (params: any) => {
            let data = params.rowNode?.childrenAfterGroup;
            let sum = 0;
            data.forEach((value: any) => (sum += !(value?.data?.IsIncludedInOrder === 'Y') ? 0 : isNaN(value?.data?.CostFromAFE) ? 0 : Number(value?.data?.CostFromAFE)));
            return sum;
        },
        'oasiscostSum': (params: any) => {
            let data = params.rowNode?.childrenAfterGroup;
            let sum = 0;
            data.forEach((value: any) => (sum += !(value?.data?.IsIncludedInOrder === 'Y') ? 0 : isNaN(value?.data?.OasisCostFromAFE) ? 0 : Number(value?.data?.OasisCostFromAFE)));
            return sum;
        },
        'proposedEstimatesSum': (params: any) => {
            let data = params.rowNode?.childrenAfterGroup;
            let sum = 0;
            data.forEach((value: any) => (sum += !(value?.data?.IsIncludedInOrder === 'Y') ? 0 : isNaN(value?.data?.OrderOwnerProposedValue) ? 0 : Number(value?.data?.OrderOwnerProposedValue)));
            return sum;
        },
        'proposedValueSum': (params: any) => {
            let data = params.rowNode?.childrenAfterGroup;
            let sum = 0;
            data.forEach((value: any) => (sum += !(value?.data?.IsIncludedInOrder === 'Y') ? 0 : isNaN(value?.data?.DataOwnerProposedValue) ? 0 : Number(value?.data?.DataOwnerProposedValue)));
            return sum;
        }
    };

    function updateTotalRowNode() {
        generatePinnedBottomData();
        let totalRowNode: any;
        let costfromafe = 0;
        let oasiscostfromafe = 0;
        let currentValueSum = 0;
        let proposedvalueSum = 0;
        let orderOwnervalueSum = 0;

        valuationGridRef?.current?.api?.rowModel?.rowsToDisplay?.forEach((node: any) => {
            if (node.footer  || node.rowPinned) {
                totalRowNode = node;
            } else {
                currentValueSum += node?.data?.CurrentValue;
                costfromafe += isNaN(node?.data?.CostFromAFE) ? 0 : Number(node?.data?.CostFromAFE)
                oasiscostfromafe += isNaN(node?.data?.OasisCostFromAFE) ? 0 : Number(node?.data?.OasisCostFromAFE)

                proposedvalueSum += !(node?.data?.IsIncludedInOrder === 'Y') ? 0 : isNaN(node?.data?.DataOwnerProposedValue) ? 0 : Number(node?.data?.DataOwnerProposedValue);
                orderOwnervalueSum += !(node?.data?.IsIncludedInOrder === 'Y') ? 0 : isNaN(node?.data?.OrderOwnerProposedValue) ? 0 : Number(node?.data?.OrderOwnerProposedValue);

            }
        });

        totalRowNode?.setDataValue('CostFromAFE', costfromafe);
        totalRowNode?.setDataValue('OasisCostFromAFE', oasiscostfromafe);

        totalRowNode?.setDataValue('CurrentValue', currentValueSum);
        if (selectedOrder.length > 0) {
            totalRowNode?.setDataValue('DataOwnerProposedValue', proposedvalueSum);
            totalRowNode?.setDataValue('OrderOwnerProposedValue', orderOwnervalueSum);
        }
    }

    const applyValueFormatting = (column: any) => {
        const dollarColumns: any[] = ["CostFromAFE", "CurrentValue", "DataOwnerProposedValue", "OrderOwnerProposedValue","OasisCostFromAFE"];
        const fixDecimalTwoColumns: any[] = [];
        const percentageSuffixColumns: any[] = ["Depreciation"];

        if (dollarColumns.includes(column)) {
            return (params: any) => currencyFormatter(params.value, '$');
        } else if (fixDecimalTwoColumns.includes(column)) {
            return (params: any) => parseFloat(params.value)?.toFixed(2);
        } else if (percentageSuffixColumns.includes(column)) {
            return (params: any) => percentageFormatter(params.value);
        }
    }

    const formatHeaderName = (value: string) => {
        return value;
    }

    function currencyFormatter(currency: number, sign: string) {
        if (currency !== null && currency !== undefined && !isNaN(currency)  && currency.toString() !== '') {
            let sansDec = parseFloat(currency.toString())?.toFixed(2);
            let formatted = sansDec?.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
            return sign + `${formatted}`;
        }
    }

    function percentageFormatter(currency: number) {
        if (currency !== null && currency !== undefined && !isNaN(currency)  && currency.toString() !== '') {
            let formatted = parseFloat(currency.toString())?.toFixed(2);
            return `${formatted}` + '%';
        }
    }

    const loadValuationData = (operator: any, apis: any, reports: any, orderId: any) => {
        async function getValuationData() {
            let getValuationDataUrl = '/OrderManagement/getOrderValuation?_operator=' + operator;
            if (operator !== "" && apis !== "") {
                getValuationDataUrl = getValuationDataUrl + "&apis=" + apis.toString() + '&reports=' + reports + '';
            }
            if (orderId != "") {
                getValuationDataUrl = getValuationDataUrl + "&orderId=" + orderId;
            }


            await api.API_GET(getValuationDataUrl).then((response) => {
                if (response.data.length > 0) {
                    const _data = response.data
                        .filter((element: any) => { return element.Report_YN === "Y" })
                        .sort((x: any, y: any) => { return ((y.IsIncludedInOrder === 'Y' ? 1 : 0) - (x.IsIncludedInOrder === 'Y' ? 1 : 0) || x.Report_Display_order) });

                    if (orderId != "") {
                        api.LOG_MESSAGE(["Page : Valuation ", "Action : Valuation Data Loaded for OrderId : " + orderId], { 'UserName': props.loggedInUser });
                    }
                    else {
                        api.LOG_MESSAGE(["Page : Valuation ", "Action : Valuation Data Loaded for Operator : " + operator], { 'UserName': props.loggedInUser });
                    }

                    const unique = _data.filter((item: any, i: any, arr: any) => {
                        return arr.indexOf(arr.find((t: any) => t.API === item.API)) === i;
                    });
                    const auditInfo = dataAuditData.filter(o => unique.some((a: any) => o.API.toLowerCase().match(a.API.toLowerCase())));

                    setValuationDataAudit(auditInfo);

                    setColumns(Object.keys(response.data[0]));
                    setValuationData(_data);
                    setValuationTempData(_data);
                    if (props.IsSummary) {
                        props.setSelectedEstValData(_data);
                        props.setIsDataModified();
                        setFilterValuationType('Y');
                    }

                } else {
                    setValuationData([]);
                }
            }).finally(() => {
                props.setShowProgress(false);
                SetisLoadedFromCart(false);
            });
        }

        if (operator !== '' && operator !== undefined) {
            getValuationData();
        }
    }

    const loadColumnPreferences = () => {
        setTablePreferenceList([]);

        async function loadColumnPreferencesData() {
            let getDataUrl = '/Common/GetColumnPreferencesByTable?schema=VALUATION&table=VALUATION';
            await api.API_GET(getDataUrl).then((response) => {
                if (response?.data?.length > 0) {
                    let globalPreference = response?.data.filter((ele: any) => { return ele.PreferenceName.toLowerCase().includes('global') });
                    let defaultPreference = response?.data.filter((ele: any) => { return ele.UserMailId == props.loggedInUserMailId && ele.IsDefault });
                    let preferences = response?.data.filter((ele: any) => {
                        return ((ele.UserMailId === props.loggedInUserMailId) ||
                            (ele.UserMailId !== props.loggedInUserMailId && ele.IsPublic))
                    });
                    setTablePreferenceList(preferences);

                    if (defaultPreference.length > 0) {
                        setselectedPreference(defaultPreference[0]);
                    }
                    else if (globalPreference.length > 0) {
                        setselectedPreference(globalPreference[0]);
                    }
                    else if (preferences.length > 0) {
                        //setselectedPreference(preferences[0]);
                    }
                }
            }).catch(error => {
                console.log("error in post call", error)
            }).finally(() => {
            });
        };

        loadColumnPreferencesData();
    }

    const autoSizeColumns = (timeoutPeriod: number) => {
        setTimeout(() => {
            valuationGridRef?.current?.columnApi?.autoSizeAllColumns(false);
        }, timeoutPeriod);
    }

    const onFirstDataRendered = useCallback((timeoutPeriod: number) => {
        autoSizeColumns(timeoutPeriod);
    }, []);

    const orderPickHandler = (orderData: any) => {
        const reports: any[] = orderData.map((element: any) => { return element.SelectedReports });

        const orderStatus = orderData.map((element: any) => { return element.OrderStatus });
        setIsExecutedOrder(orderStatus.includes('Executed'));

        const _orderData: any[] = orderData.map((element: any) => {
            if (element.OrderId == 'N/A') {
                return element.Data
            }
            else {
                return JSON.parse(element.Data)
            }
        });
        const apis: string[] = _orderData.map((element: any) => { return element.map((b: any) => { return b.API }); });
        const orderIds: any[] = orderData.map((element: any) => { return element.OrderId });

        setReports(reports);
        const uniqueValues = getUniqueApi(apis);
        setApis(uniqueValues.toString());
        setselectedOrder(orderIds);
        regenerateValuation(orderData);
        if (orderData.length > 0) {
            setFirstSelectedOrderData(orderData[0]);
        }
    }

    const resetValuationHandler = () => {
        let orderId = selectedOrder.length > 0 ? selectedOrder.toString() : ""
        loadValuationData(operator, apis, reports, orderId);
        autoSizeColumns(1000);
    }

    const getUniqueApi = (apiData: string[]) => {
        const uniqueData: string[] = [];
        apiData.map((element: any) => {
            element.forEach((a: any) => {
                if (!uniqueData.includes(a)) {
                    uniqueData.push(a);
                }
            });
        });

        return uniqueData;
    }


    const onBtExport = () => {
        const params = {
            columnGroups: true,
            allColumns: true,
            fileName: "Valuation",
        };
        valuationGridRef.current.api.exportDataAsCsv(params);
        api.LOG_MESSAGE(["Page : Valuation ", "Action : Data Exported to Excel"], { 'UserName': props.loggedInUser });

    }

    const onRegenerateValuation = async () => {
        props.setShowProgress(true);
        regenerateValuation([]);
    }

    const getModifiedAttributes = (editedObject: any) => {
        const actualKeys = Object.keys(editedObject);
        const modifiedKeys = editedObject["modifiedAttrs"];
        let _objectClone: any = {};
        for (let key of actualKeys) {
            if ((modifiedKeys.includes(key) && key != "DataOwnerProposedValue" && key != "DataOwnerApproval") || key === "ValuationId") {
                _objectClone[key] = editedObject[key];
            }
        }
        return _objectClone;
    }

    const getModifiedProposedValueAttributes = (editedObject: any) => {
        const actualKeys = ['DataOwnerProposedValue', 'OrderValuationId', 'DataOwnerApproval'];
        const modifiedKeys = editedObject["modifiedAttrs"];
        let _objectClone: any = {};
        for (let key of actualKeys) {
            if (modifiedKeys.includes(key) || key === "OrderValuationId") {
                if (key === "DataOwnerProposedValue") {
                    _objectClone["ProposedValue"] = editedObject[key];
                }
                else if (key === "DataOwnerApproval") {
                    _objectClone["ApprovalStatus"] = editedObject[key];
                }
                else {
                    _objectClone[key] = editedObject[key];
                }
            }
        }
        return _objectClone;
    }

    const getModifiedEstimateAttributes = (editedObject: any) => {
        const actualKeys = ['OrderOwnerApproval', 'EstimateId'];
        const modifiedKeys = editedObject["modifiedAttrs"];
        let _objectClone: any = {};
        for (let key of actualKeys) {
            if (modifiedKeys.includes(key) || key === "EstimateId") {
                if (key === "OrderOwnerApproval") {
                    _objectClone["ApprovalStatus"] = editedObject[key];
                }
                else {
                    _objectClone[key] = editedObject[key];
                }
            }
        }
        return _objectClone;
    }

    const onCellValueChanged = (params: any) => {
        if (!params.node.footer || !params.node.rowPinned) {
            setIsEditted(true);

            let _tableData = valuationData;
            if (_tableData.length > 0 && !params.node?.footer) {

                if (params.column.colId == "CostFromAFE" || params.column.colId == "Depreciation") {
                    let value = params.column.colId == "CostFromAfe" ? Number(params.node.data.CostFromAfe) : Number(params.node.data.Depreciation);
                    let isNotNumber = isNaN(+value);
                    if (isNotNumber) {
                        params.node.setDataValue(params.column.colId, 0);
                    }
                }
                  if (params.node.id !== "rowGroupFooter_ROOT_NODE_ID") {
                    params.node.data.isModified = true;
                    _tableData[params.node.id]["isModified"] = true;
                    _tableData[params.node.id][params.column.colId] = params.node.data[params.column.colId];
                    if (_tableData[params.node.id]["modifiedAttrs"] === undefined) {
                        _tableData[params.node.id]["modifiedAttrs"] = params.column.colId;
                    } else {
                        let modAttrs = _tableData[params.node.id]["modifiedAttrs"];
                        modAttrs = modAttrs + ',' + params.column.colId;
                        _tableData[params.node.id]["modifiedAttrs"] = modAttrs;
                    }

                    params.node.setData({ ...params.node.data, isModified: true });
                }

                if (params.column.colId == "CostFromAFE" || params.column.colId == "Depreciation"
                    || params.column.colId == "DateOfAcquisition") {
                    CalculateCurrentValue(params.node.data);
                    params.node.setDataValue('CurrentValue', params.node.data.CurrentValue);
                }

                if (params.column.colId == "CostFromAFE" || params.column.colId == "Depreciation"
                    || params.column.colId == "DateOfAcquisition" || params.column.colId == "DataOwnerProposedValue") {
                    updateTotalRowNode();
                }
            }

            onValidate();
        }
    }

    const onCellValueEnded = (params: any) => {
        if (params.column.colId == "DateOfAcquisition") {
            let _tableData = valuationData;
            let rowNode = valuationGridRef.current.api.getRowNode(params.rowIndex);
            CalculateCurrentValue(rowNode.data);
            rowNode.setDataValue('DateOfAcquisition', rowNode.data.DateOfAcquisition);
            rowNode.setDataValue('CurrentValue', rowNode.data.CurrentValue);
            rowNode.data.isModified = true;
            //rowNode.setData({ ...rowNode.data, isModified: true });
            updateTotalRowNode();

            if (_tableData[params.rowIndex]["modifiedAttrs"] === undefined) {
                _tableData[params.rowIndex]["modifiedAttrs"] = "DateOfAcquisition";
            } else {
                let modAttrs = _tableData[params.rowIndex]["modifiedAttrs"];
                modAttrs = modAttrs + ',' + "DateOfAcquisition";
                _tableData[params.rowIndex]["modifiedAttrs"] = modAttrs;
            }
        }

        onValidate();
    }

    const onSave = () => {
        props.setShowProgress(true);
        onSubmitHandler(false);
    }

    const onSubmitHandler = (changeMode: boolean) => {
        //let isApprovalStatusModified : boolean = false;
        const edittedData = valuationData.filter((data) => { return data.isModified });
        const newData: any = [];
        const editedRows: any = [];
        const editedOrderValuationRows: any = [];
        const editedOrderEstimateRows: any = [];
        edittedData.forEach((element) => {
            if (element.modifiedAttrs !== undefined) {
                let updatedData = getModifiedAttributes(element);
                updatedData.LastModifiedBy = props.loggedInUser;
                updatedData.LastModifiedDate = new Date().toISOString();
                editedRows.push(updatedData);

                let orderValuationData = getModifiedProposedValueAttributes(element);
                if (orderValuationData) {
                    //isApprovalStatusModified = element["modifiedAttrs"].includes("DataOwnerApproval");
                    orderValuationData.LastModifiedBy = props.loggedInUser;
                    orderValuationData.LastModifiedDate = new Date().toISOString();
                    editedOrderValuationRows.push(orderValuationData);
                }

                let orderEstimateData = getModifiedEstimateAttributes(element);
                if (orderEstimateData) {
                    orderEstimateData.LastModifiedBy = props.loggedInUser;
                    orderEstimateData.LastModifiedDate = new Date().toISOString();
                    editedOrderEstimateRows.push(orderEstimateData);
                }
            }
        }
        );

        SaveData();

        async function SaveData() {
            await api.API_POST('/Table/SaveTableData', {
                schema: 'orders',
                table: 'VALUATION',
                insert: newData,
                update: editedRows,
                ids: ""
            }).then((response) => {
                if (response.data.length > 0) {
                    formMessage('Data Saved successfully', 'success');
                    api.LOG_MESSAGE(["Page : Valuation", "Action : Saved Valuation data "], { 'UserName': props.loggedInUser });

                    let rowNode: any = null;
                    edittedData.forEach((element) => {
                        let valuation = valuationData.findIndex(item => item.ValuationId === element.ValuationId);
                        if (valuation > -1) {
                            rowNode = valuationGridRef.current.api.getRowNode(valuation);
                            rowNode.data.isModified = false;

                            valuationData[valuation].isModified = false;
                            delete valuationData[valuation].modifiedAttrs;
                            rowNode.setData({ ...valuationData[valuation], isModified: false });
                        }
                    });

                    if (rowNode !== null) {
                        eventMessaging.ValuationUpdatedBroadcast(rowNode?.data, operator);
                        if(rowNode?.data?.OrderId !== undefined){
                        eventMessaging.GetOrderAndBroadcastOrderUpdate(rowNode.data.OrderId,operator);
                        }
                    }

                    if (props.IsSummary) {
                        // Update in TempData
                        props.setIsDataModified();
                        props.setSelectedEstValData(valuationTempData);
                    }

                    if (changeMode) {
                        setOpenConfirmDialog(false);
                        setOpenSaveConfirmDialog(false);
                    }

                    setIsEditted(false);
                    props.setShowProgress(false);
                }
            }).catch(() => {
                setIsEditted(false);
            });

            await api.API_POST('/Table/SaveTableData', {
                schema: 'orders',
                table: 'ORDER_VALUATION',
                insert: newData,
                update: editedOrderValuationRows,
                ids: ""
            }).then((response) => {
                if (response.data.length > 0) {
                    formMessage('Data Saved successfully', 'success');
                    // if (selectedOrder.length > 0) {
                    //     CheckAndIntiateWorkflow();
                    // }

                    edittedData.forEach((element) => {
                        let valuation = valuationData.findIndex(item => item.OrderValuationId === element.OrderValuationId);
                        if (valuation > -1) {
                            valuationData[valuation].isModified = false;
                            delete valuationData[valuation].modifiedAttrs;
                        }
                    });

                    //alert("Data saved successfully.")
                    if (changeMode) {
                        setOpenConfirmDialog(false);
                    }
                    props.setShowProgress(false);
                }
            }).catch(() => {
            });

            await api.API_POST('/Table/SaveTableData', {
                schema: 'orders',
                table: 'ESTIMATES',
                insert: newData,
                update: editedOrderEstimateRows,
                ids: ""
            }).then((response) => {
                if (response.data.length > 0) {
                    formMessage('Data Saved successfully', 'success');
                    if (selectedOrder.length > 0) {
                        CheckAndIntiateWorkflow();
                    }
                    props.setShowProgress(false);
                }
            }).catch(() => {
            });
        };

        async function CheckAndIntiateWorkflow() {

            const hasUnApprovedEstimates = valuationData.filter((i : any) => {return i.IsIncludedInOrder === 'Y' && i.OrderOwnerApproval?.toLowerCase() !== 'accept'});
            const hasUnApprovedValuation = valuationData.filter((i: any) => { return i.IsIncludedInOrder === 'Y' && i.DataOwnerApproval?.toLowerCase() !== 'accept' });

            if (hasUnApprovedValuation.length == 0 && hasUnApprovedEstimates.length == 0) {
                let reInitiateUrl = '/OrderManagement/CheckEstimateValuation?orderId='
                    + selectedOrder[0];
                await api.API_GET(reInitiateUrl).then((response) => {
                    if (response.data) {
                        formMessage('Internal workflow Re-Initiated', 'success');
                        api.LOG_MESSAGE(["Page : Valuation", "Action : Workflow ReInitiated for OrderId : + " + selectedOrder[0]], { 'UserName': props.loggedInUser });
                        props.setShowProgress(false);
                    }
                }).catch(() => {

                });
            }
        };
    }

    const saveEditedDataHandler = () => {
        props.setShowProgress(true);
        onSubmitHandler(true)
    }

    const onCancelHandler = () => {
        valuationGridRef.current.api.undoCellEditing();
        setPivotMode(true);
        setOpenConfirmDialog(false);
    }

    const onNavigateTabHandler = () => {
        valuationGridRef.current.api.undoCellEditing();
        setOpenSaveConfirmDialog(false);
    }

    const closeMessage = () => {
        setMessage(initialMessage);
    }

    const formMessage = (message: string, severity: string) => {
        setMessage({
            open: true,
            msg: message,
            severity: severity
        });
    }

    const onPivotModeChanged = (params: any) => {
        if (!pivotMode) {
            const edittedData = valuationData.filter((data) => { return data.isModified && (!data.isNew) });
            if (edittedData.length > 0) {
                let confirmDialogObject = {
                    title: "Valuation",
                    body: "You have made some changes. Do you want to Save and Continue?, else click CANCEL",
                    okHandler: saveEditedDataHandler
                };

                setConfirmDialogObject(confirmDialogObject);
                setOpenConfirmDialog(true);
            }
            else {
                setPivotMode((pivotMode) => !pivotMode);
            }
        }
        else {
            setPivotMode((pivotMode) => !pivotMode);
        }
    }

    const handleClickColumnsOpen = (event: React.MouseEvent<HTMLElement>) => {
        setInitialColumnPreferenceState();
        setAnchorEl(event.currentTarget);
        setcolumnsPerfOpen(!columnsPerfOpen);
    };

    const setInitialColumnPreferenceState = () => {
        let columnPreference: any[] = [];
        if (selectedPreference == null) {
            displayColumns.forEach((element: any, index: any) => {
                columnPreference.push({
                    ColumnName: element,
                    Enable: true,
                    Index: index,
                    AvailableOrDisplayed: "D" // A for Available columns , D for Displayed columns
                });
            });

            if (columnPreference.length > 0) {
                let preference: any = {
                    PreferenceJson: columnPreference,
                    PreferenceId: 0,
                    PreferenceName: '',
                    SchemaName: 'VALUATION',
                    TableName: 'VALUATION',
                    UserMailId: props.loggedInUserMailId,
                    IsPublic: true,
                    IsDefault: false,
                    CreatedBy: props.loggedInUserMailId
                };

                setselectedPreference(preference);
            }

        }
    }

    const applyDisplayColumnNames = (_columnPreference: any[], colDefs: any) => {
        colDefs.forEach((colDef: any) => {
            let colPref = _columnPreference.filter((ele) => { return ele.ColumnName == colDef.field });
            if (colPref.length > 0) {
                colDef.headerName = colPref[0].DisplayName;
                colDef.headerComponentParams = columnHeaderTemplate(colPref[0].DisplayName, colDef.isPropreitary);
            }
        });

        return colDefs;
    }

    const applyColumnPreferenceHandler = async (selectedPreferene: any) => {
        setselectedPreference(selectedPreferene);
        applyColumnPreference(selectedPreferene.PreferenceJson);
    }

    const setColumnPerefenceHanler = (columnPerferenceObjs: any[]) => {
        let _columnPreference = [...columnPreference];
        columnPerferenceObjs.forEach((columnPerferenceObj: any) => {
            const _preferenceMatch = _columnPreference.filter((ele: any) => { return ele.ColumnName === columnPerferenceObj.ColumnName });
            if (_preferenceMatch.length > 0) {
                _columnPreference[_columnPreference.indexOf(_preferenceMatch[0])] = columnPerferenceObj;
            }
        });

        setColumnPreference(_columnPreference);
    }

    const isColumnHidden = (columnName: string) => {
        if (columnPreference != null) {
            let colMatch = columnPreference.filter((element: any) => {
                return element.ColumnName === columnName;
            });
            if (colMatch.length > 0) {
                return !colMatch[0].Enable;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }

    const applyColumnPreference = (_columnPreference: any[]) => {
        let updatedColumnDefs = rearrangeColumnsBasedOnPreference([...columnDefinitions], _columnPreference);
        _columnPreference.forEach((colPreference: any) => {
            let columnDefMatch = updatedColumnDefs.filter((element: any) => { return element.field.toLowerCase().match(colPreference.ColumnName.toLowerCase()) || element.headerName.toLowerCase().match(colPreference.ColumnName.toLowerCase()) });
            if (columnDefMatch.length > 0) {
                if (colPreference.Enable === false) {
                    updatedColumnDefs[updatedColumnDefs.indexOf(columnDefMatch[0])].hide = true;
                } else {
                    updatedColumnDefs[updatedColumnDefs.indexOf(columnDefMatch[0])].hide = false;
                }
            }
        });

        updatedColumnDefs.forEach((elem: any) => {
            elem.headerCheckboxSelection = false;
            elem.checkboxSelection = false;
        });

        let visibleItem = updatedColumnDefs.filter((elem: any) => { return !elem.hide });
        if (visibleItem && visibleItem.length > 0){
            visibleItem[0].headerCheckboxSelection = true;
            visibleItem[0].checkboxSelection = true;
        }
  
        valuationGridRef.current?.columnApi?.columnModel?.setColumnDefs(applyDisplayColumnNames(_columnPreference,updatedColumnDefs));
        valuationGridRef.current?.api?.setRowData(valuationData);
    }

    const rearrangeColumnsBasedOnPreference = (columnDefinitions: any[], _columnPreference: any[]) => {
        let _reArrangedColumnDefinitions: any[] = [];
        let colPrefs = _columnPreference.length > 0 ? _columnPreference : [...columnPreference];

        if (colPrefs != null && colPrefs.length > 0) {
            colPrefs.forEach((preference: any) => {
                const columnDefinitionObj = columnDefinitions.filter((ele: any) => { return ele.field.toLowerCase() === preference.ColumnName.toLowerCase() || ele.headerName.toLowerCase() === preference.ColumnName.toLowerCase() });
                if (columnDefinitionObj.length > 0) {
                    _reArrangedColumnDefinitions.push(columnDefinitionObj[0]);
                }
            });

            const columnsNotInPreference = columnDefinitions.filter(o => !colPrefs.some(({ ColumnName }) => o.field.toLowerCase() === ColumnName.toLowerCase() || o.headerName.toLowerCase() === ColumnName.toLowerCase()));
            columnsNotInPreference.forEach((column: any) => {
                _reArrangedColumnDefinitions.push(column);
            });
        }
        return _reArrangedColumnDefinitions.length > 0 ? _reArrangedColumnDefinitions : columnDefinitions;
    }

    const cellClicked = (params: any) => {
        params.node.setSelected(true)
    }

    const onRowSelectedHandler = () => {
        const selectedRow = valuationGridRef.current?.api?.getSelectedRows();
        if (selectedRow.length > 0) {
            setSelectedValuation(selectedRow[0]);
        }
    }

    const handleCollapseExplorer = () => {
        setCommentOpen(!commentOpen);
    }

    const IsIncludedTypeFilterChanged = useCallback((newValue: string) => {
        setFilterValuationType(newValue);
    }, []);

    const isExternalFilterPresent = useCallback((): boolean => {
        return filterValuationType !== 'All';
    }, [filterValuationType]);

    const doesExternalFilterPass = useCallback((node: any): boolean => {
        if (node.data) {
            let isValid: boolean = false;
            switch (filterValuationType) {
                case 'Y':
                    isValid = node.data.IsIncludedInOrder === "Y";
                    break;
                case 'N':
                    isValid = node.data.IsIncludedInOrder === "N";
                    break;
                default:
                    isValid = filterValuationType === 'All';
                    break;
            }

            return isValid;
        }
        return false;
    }, [filterValuationType]);


    return (
        <>

            <Paper className="valuationHeader" elevation={0} sx={{ height: 'auto !important', padding: '10px', marginBottom: '5px' }}>
                {props.IsSummary === true && <Typography variant="button" sx={{ fontSize: '1rem', color: 'rgba(0, 0, 0, 0.6) !important', paddingRight: '5%' }}>VALUATION</Typography>}
                {props.IsSummary === false && <TextField
                    id="Operator" name="Operator" select label="Operator"
                    size="small"
                    SelectProps={{
                        native: true,
                    }}
                    value={operator}
                    onChange={e => {
                        setOperator(e.target.value);
                        autoSizeColumns(1000);
                        setReports([]);
                        setApis("");
                        setselectedOrder([]);
                        setOrderComments([]);
                        setSelectedValuation(null);
                    }}>
                    {operatorList?.map((operator: string) => (
                        <option key={operator} value={operator}>
                            {operator}
                        </option>
                    ))}
                </TextField>}

                {props.IsSummary === false &&
                    <MyOrdersFilter
                        operator={operator}
                        type={type}
                        loggedInUser={props.loggedInUser}
                        loggedInUserMailId={props.loggedInUserMailId}
                        selectedOrder={selectedOrder}
                        orderPickHandler={orderPickHandler}
                    ></MyOrdersFilter>}

                <Button id="reset"
                    variant="outlined"
                    color="success"
                    sx={{ marginLeft: '10px', fontSize: '11px' }}
                    startIcon={<RestartAltOutlinedIcon />}
                    onClick={resetValuationHandler}>RESET</Button>

                <Tooltip title="Save">
                    <IconButton
                        id="save"
                        color="success"
                        aria-label="Save"
                        component="label"
                        style={{ backgroundColor: "rgb(239 238 238)", marginLeft: '10px', padding: '2px' }}
                        onClick={onSave}>
                        <SaveIcon />
                    </IconButton>
                </Tooltip>

                <Tooltip title="Validate">
                    <IconButton
                        id="Validate"
                        color="success"
                        aria-label="Save"
                        component="label"
                        style={{ backgroundColor: "rgb(239 238 238)", marginLeft: '10px', padding: '2px' }}
                        onClick={onValidate}>
                        <ChecklistIcon />
                    </IconButton>
                </Tooltip>

                <Tooltip title="Regenerate Valuation">
                    <IconButton
                        id="RegenerateValuation"
                        color="success"
                        aria-label="Regenerate Valuation"
                        component="label"
                        style={{ backgroundColor: "rgb(239 238 238)", marginLeft: '10px', padding: '2px' }}
                        onClick={onRegenerateValuation}>
                        <AutoModeIcon />
                    </IconButton>
                </Tooltip>

                <Tooltip title="Export to excel">
                    <IconButton
                        id="exporttoexcel"
                        color="success"
                        aria-label="Export to excel"
                        component="label"
                        style={{ backgroundColor: "rgb(239 238 238)", marginLeft: '10px', padding: '2px' }}
                        onClick={onBtExport}>
                        <SystemUpdateAltIcon />
                    </IconButton>
                </Tooltip>



                <ExcelUpload
                    selectedTableInfo={{
                        schema: "orders",
                        name: "VALUATION",
                        pkColumnName: "ValuationId"
                    }}
                    columns={columns}
                    loggedInUser={props.loggedInUser}
                    loggedInUserOperator={operator}
                    environment={api.env}
                    tableData={valuationData}
                    setTableData={setValuationData}
                    setTableDataMonitor={setValuationData}
                    setShowProgress={props.setShowProgress}
                    setReplaceDeleteIds={() => { }}
                    refreshdatahandler={() => { }}
                    setSaveAndUpdateInfo={() => { }}
                    setOpenSuccessMessage={() => { }}
                    setErrorList={() => { }}
                    setOpenErrorMessage={() => { }}></ExcelUpload>

                <Tooltip title="columns">
                    <IconButton
                        id="columns"
                        color="success"
                        aria-label="columns"
                        component="label"
                        style={{ backgroundColor: "rgb(239 238 238)", marginRight: '5px', padding: '2px' }}
                        onClick={handleClickColumnsOpen}>
                        <ViewColumnOutlinedIcon />
                    </IconButton>
                </Tooltip>

               

                {selectedOrder.length == 1 &&
                    <Tooltip id="mapview" title="Map View">
                        <MapViewRenderer
                            data={firstSelectedOrderData}
                            setTabIndex={props.setTabIndex}
                            selectedOperator={operator}
                            isGreenButton={true}
                            IsSummary={props.IsSummary}
                            groupedOrders={props.groupedOrders}
                            summaryCloseHandler={props.summaryCloseHandler}
                        />
                    </Tooltip>
                }

            

                {missingColumns && missingColumns.length > 0 && 
                        <Collapse in={missingColumns.length > 0} sx = {{display:"inline-block", mt:-11}}>
                        <Alert severity="error"
                            sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                            <strong>Missing Values</strong>  {missingColumns.map((_errorInfo, index) => {
                            return <><strong> {index + 1}.</strong> {_errorInfo} </>
                             })}
                        </Alert>
                    </Collapse>
                }

                {showGif &&
                    <img style={{ height: "31px",position:"fixed" }} src={circleImage} />
                }

                {selectedOrder.length == 1 &&
                    <IconButton id="valuationhandlecollapse" style={{ float: 'right', backgroundColor: 'rgb(239, 238, 238)',fontSize:'14px' }} onClick={() => { handleCollapseExplorer(); }}>
                        {commentOpen == false && <ChevronLeftIcon />}
                        {commentOpen == true && <ChevronRightIcon />}
                    </IconButton>}

                    {selectedOrder.length == 1 &&
                    <Stack sx={{ float: 'right', display : 'flex' }} direction="row" spacing={1}>
                            <Typography variant="subtitle1" component="div">
                                Filter Datasets Included:
                            </Typography>
                            {filterTypeList.map((item) => (
                                <Chip
                                    key={item}
                                    color="primary"
                                    size="small"
                                    //avatar={<Avatar>{item.charAt(0)}</Avatar>}
                                    label={item}
                                    variant={item === filterValuationType ? "filled" : "outlined"}
                                    onClick={(event) => {
                                        IsIncludedTypeFilterChanged(item);
                                    }} />
                            ))}
                    </Stack> 
                }
                {selectedOrder.length == 1 && isTradeOrder && 
                    <Stack sx={{ float: 'right', display : 'flex', mr:10 }} direction="row" spacing={1}>
                            <Typography variant="subtitle1" component="div">
                                Transaction Fees:
                            </Typography>
                            <Typography variant="subtitle1" component="div">
                                {transactionFees}USD
                            </Typography>
                          
                    </Stack> 
                }
                <div style={{ height: '20px' }}>
                    <Collapse in={message.open} >
                        <Alert onClose={closeMessage} severity={message.severity as AlertColor}
                            sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                            {message.msg}
                        </Alert>
                    </Collapse>
                </div>
            </Paper>

            <div style={{ display: 'flex' }}>
                <div style={{ width: commentOpen ? "80%" : "100%" }}>
                    <Paper id='orderValuationGridContent' className="ag-theme-balham" elevation={0} sx={{ height: props.IsSummary ? '65vh' : '77vh', padding: '10px' }}>
                        <AgGridReact
                            //domLayout={'autoHeight'}
                            getRowStyle={getRowStyle}
                            ref={valuationGridRef}
                            gridOptions={gridOptions}
                            pagination={props.enablePagination !== undefined ? props.enablePagination : true}
                            pivotMode={pivotMode}
                            aggFuncs={aggFuncs}
                            suppressAggFuncInHeader={true}
                            suppressExpandablePivotGroups={true}
                            onColumnGroupOpened={() => { onFirstDataRendered(1000) }}
                            sideBar={sideBar}
                            groupDefaultExpanded={-1}
                            rowSelection={'single'}
                            rowGroupPanelShow={'always'}
                            enableRangeSelection={true}
                            onSelectionChanged={onRowSelectedHandler}
                            onCellClicked={cellClicked}
                            onCellEditingStopped={onCellValueEnded}
                            onColumnPivotModeChanged={onPivotModeChanged}
                            groupIncludeFooter={false}
                            groupIncludeTotalFooter={pivotMode}
                            isExternalFilterPresent={isExternalFilterPresent}
                            doesExternalFilterPass={doesExternalFilterPass}
                            postSortRows={postSortRows}
                            onCellValueChanged={onCellValueChanged}
                            onFirstDataRendered={() => { onFirstDataRendered(1000) }}
                            overlayNoRowsTemplate={'<span style="padding: 10px; border: 2px solid #444; background: lightgoldenrodyellow;">Loading...</span>'}>
                        </AgGridReact>
                    </Paper>
                </div>
                {commentOpen &&
                    <div style={{ width: '20%' }}>
                        <Paper id='orderComment' className="ag-theme-balham" elevation={0} sx={{ height: 'auto', padding: '10px' }}>
                            <OrderComment
                                orderInfo={{ OrderId: selectedOrder[0] }}
                                operator={operator}
                                loggedInUserName={props.loggedInUser}
                                loggedInUserMailId={props.loggedInUserMailId}
                                messageTag={MessageTags.Valuation}
                                completeObject={selectedValuation}
                                selectedEstimate={selectedValuation !== null ? {
                                    Api: selectedValuation?.API,
                                    ReportName: selectedValuation?.DaColName,
                                    OrderId: selectedOrder[0],
                                    CreatedBy: props.loggedInUserMailId,
                                    DataOwnerOperator: selectedValuation.DataOwnerOperator,
                                    OrderOwnerOperator: selectedValuation.OrderOwnerOperator
                                } : null} ></OrderComment>
                        </Paper>
                    </div>}
            </div>

            <ConfirmDialog
                open={openConfirmDialog}
                setOpenConfirmDialogHandler={onCancelHandler}
                title={confirmDialogObject.title}
                body={confirmDialogObject.body}
                okHandler={confirmDialogObject.okHandler}
            ></ConfirmDialog>

            <ConfirmDialog
                open={openSaveConfirmDialog}
                setOpenConfirmDialogHandler={onNavigateTabHandler}
                title={confirmDialogObject.title}
                body={confirmDialogObject.body}
                okHandler={confirmDialogObject.okHandler}
            ></ConfirmDialog>

            <ColumnSelection
                tablePreferenceList={tablePreferenceList}
                selectedPreference={selectedPreference}
                setselectedPreference={setselectedPreference}
                loggedInUserMailId={props.loggedInUserMailId}
                loggedInUser={props.loggedInUser}
                hiddenColumns={hiddenColumns}
                columnsPerfOpen={columnsPerfOpen}
                anchorEl={anchorEl}
                applyColumnPreferenceHandler={applyColumnPreferenceHandler}
                setcolumnsPerfOpen={setcolumnsPerfOpen}
                displayColumns={displayColumns}
            ></ColumnSelection>
        </>
    )
}

export default Valuation;