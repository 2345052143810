import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

interface IConfirmDialogProps{
  open:boolean,
  title:string,
  body:string,
  setOpenConfirmDialogHandler:(active:boolean) => void,
  okHandler: () => void
}

export default function ConfirmDialog(props:IConfirmDialogProps) {
  const handleClose = () => {
    props.setOpenConfirmDialogHandler(false);
  };

  const okSelectionHandler = () =>{
    props.okHandler();
    props.setOpenConfirmDialogHandler(false);
  }

  return (
    <div>
      <Dialog
        open={props.open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {props.title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
           {props.body}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button id="confirm" onClick={okSelectionHandler}>
            Ok
          </Button>
          <Button id="cancel" onClick={handleClose} autoFocus>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
