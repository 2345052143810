import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';
import MergeIcon from '@mui/icons-material/Merge';
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import ReplyIcon from '@mui/icons-material/Reply';
import { Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControlLabel, Grid, IconButton, TextField, Typography } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import * as React from "react";
import { useEffectOnce } from 'src/hooks/useEffectOnce';
import * as api from 'src/Utility/api';
import { isNumeric } from 'src/Utility/common';
import circleImage from 'src/Utility/Images/loading.gif';
import 'src/Common/Styles/heartbeat.css';

function DialogViewRenderer(props: any) {

    type CounterDetails = {
        OrderId?: string;
        CounterOrderId?: string;
        OrderStatus?: string;
        StartDate?: string;
        EndDate?: string;
        Amount?: string;
        CommentsShared?: string;
        CommentsPrivate?: string;
        OrderEvent?: string;
        LastModifiedBy?: string;
        LastModifiedDate?: string;
    };
    const dialogTradeTypes = ['Rental', 'Purchase'];
    const [open, setOpen] = React.useState(false);
    const [requestedOrder, setRequestedOrder] = React.useState<any>(props.data);
    const [counterOrder, setCounterOrder] = React.useState<any>({});
    const [hasCounterOrder, setHasCounterOrder] = React.useState<boolean>(false);
    const [createCounter, setCreateCounter] = React.useState<boolean>(false);
    const [disableCreateCounter, setDisableCreateCounter] = React.useState<boolean>(false);
    const [disableOrderFields, setDisableOrderFields] = React.useState<boolean>(false);
    const [disableCounterFields, setDisableCounterFields] = React.useState<boolean>(false);
    const [disableCounterStatus, setDisableCounterStatus] = React.useState<boolean>(false);
    const [isExpand, setIsExpand] = React.useState<boolean>(true);
    const [rowIndex] = React.useState(props.rowIndex);

    const [modifiedAttributes, setModifiedAttributes] = React.useState<any>(
        {
            'StartDate': false,
            'EndDate': false,
            'Amount': false,
            'CommentsShared': false,
            'CommentsPrivate': false,
            'OrderStatus': false
        }
    );
    const [hasCounterUpdate, setHasCounterUpdate] = React.useState<boolean>(false);
    const [hasCounterStatusUpdate, setHasCounterStatusUpdate] = React.useState<boolean>(false);
    const [hasOrderUpdate, setHasOrderUpdate] = React.useState<boolean>(false);
    const [hasOrderStatusUpdate, setHasOrderStatusUpdate] = React.useState<boolean>(false);

    const [dataOwner, setDataOwner] = React.useState<boolean>(false);
    const [dataRequestor, setDataRequestor] = React.useState<boolean>(false);
    const [showEventUpdateGif, setShowEventUpdateGif] = React.useState<boolean>(false);
    const [orderStatusList, setOrderStatusList] = React.useState<any[]>([
        {
            value: 'Countered',
            label: 'Countered',
            disable: false
        },
        {
            value: 'Accepted',
            label: 'Accepted',
            disable: true
        },
        {
            value: 'Placed',
            label: 'Placed',
            disable: false
        },
        {
            value: 'Closed',
            label: 'Closed',
            disable: false
        },
        {
            value: 'Approved',
            label: 'Approved',
            disable: true
        },
        {
            value: 'Rejected',
            label: 'Rejected',
            disable: true
        },

        {
            value: 'Executed',
            label: 'Executed',
            disable: true
        }]);
    const [counterStatusList, setCounterStatusList] = React.useState<any[]>([
        {
            value: 'Countered',
            label: 'Countered',
            disable: false
        },
        {
            value: 'Approved',
            label: 'Approved',
            disable: false
        },
        {
            value: 'Rejected',
            label: 'Rejected',
            disable: false
        }

    ]);
    const openCounterDialog = (event: React.MouseEvent<HTMLButtonElement>) => {
        setOpen(true);
        setIsExpand(props.node.expanded);
    };

    const replyExchangeOrder = (event: React.MouseEvent<HTMLButtonElement>) => {
        if (props.gridType === "pending" && (props.data.TradeGroupId === 'N/A' || props.data?.detailData?.every((item: any) => item.OrderEvent === "ORDER_CLOSED"))) {
            props.initiateExchange(props.data);
        } else if (props.gridType === "grouped") {
            if (props.data.RequestedTo === props.operator) {
                setDataOwner(true);
                setDataRequestor(false);
                setDisableOrderFields(true);
                setDisableCreateCounter(true);
            } else {
                setDataOwner(false);
                setDataRequestor(true);
                setDisableOrderFields(true);
            }
            setOpen(true);
        }
    };

    const toggleExapnd = () => {
        if (props.node.data.TradeGroupId !== 'N/A') {
            props.node.setExpanded(!isExpand);
            setIsExpand(!isExpand);
        }
    }

    useEffectOnce(() => {
        if (props.loadCounter && dialogTradeTypes.includes(props.data.TradeType) && props.data.OrderId !== 'N/A')
            loadCounterOrders();
    });

    React.useEffect(() => {
        if (props?.node?.data?.isEventUpdated == true) {
            setShowEventUpdateGif(true);
            setTimeout(() => {
                setShowEventUpdateGif(false);       
                props.onEventUpdateGifComplete(rowIndex,{...props?.node?.data,isEventUpdated:false});
            }, 7000);
        }
        if(props?.node?.data && props.node.data.TradeGroupId !== 'N/A' && isNumeric(props.node.data.TradeGroupId)){
            props.node.setExpanded(true);
        }
    }, [props?.node?.data]);

    const handleCounterInputChange = (e: any) => {
        setCounterOrder(
            { ...counterOrder, [e.target.name]: e.target.value }
        );
        setModifiedAttributes(
            { ...modifiedAttributes, [e.target.name]: true }
        );
        setHasCounterUpdate(true);
    };

    const handleOrderInputChange = (e: any) => {
        setRequestedOrder(
            { ...requestedOrder, [e.target.name]: e.target.value }
        );
        setModifiedAttributes(
            { ...modifiedAttributes, [e.target.name]: true }
        );
        setHasOrderUpdate(true);
    };

    const handleCounterStatusChange = (e: any) => {
        let _counterOrder = { ...counterOrder };
        let _modifiedAttributes = { ...modifiedAttributes }
        if (e.target.value === "Approved") {
            if (hasCounterOrder) {
                if (requestedOrder.StartDate !== _counterOrder.StartDate) {
                    _counterOrder.StartDate = requestedOrder.StartDate;
                    _modifiedAttributes.StartDate = true;
                }
                if (requestedOrder.EndDate !== counterOrder.EndDate) {
                    _counterOrder.EndDate = requestedOrder.EndDate;
                    _modifiedAttributes.EndDate = true;
                }
                if (requestedOrder.Amount !== counterOrder.Amount) {
                    _counterOrder.Amount = requestedOrder.Amount;
                    _modifiedAttributes.Amount = true;
                }
                setCounterOrder(_counterOrder);
            }
        }
        _counterOrder.OrderStatus = e.target.value;
        setCounterOrder(_counterOrder);
        _modifiedAttributes.OrderStatus = true;
        setModifiedAttributes(_modifiedAttributes);
        setHasCounterStatusUpdate(true);
    }

    const handleOrderStatusChange = (e: any) => {
        let _requestedOrder = { ...requestedOrder };
        let _modifiedAttributes = { ...modifiedAttributes }
        if (e.target.value === "Accepted") {
            if (counterOrder.StartDate !== _requestedOrder.StartDate) {
                _requestedOrder.StartDate = counterOrder.StartDate;
                _modifiedAttributes.StartDate = true;
            }
            if (counterOrder.EndDate !== _requestedOrder.EndDate) {
                _requestedOrder.EndDate = counterOrder.EndDate;
                _modifiedAttributes.EndDate = true;

            }
            if (counterOrder.Amount !== _requestedOrder.Amount) {
                _requestedOrder.Amount = counterOrder.Amount;
                _modifiedAttributes.Amount = true;
            }

            setModifiedAttributes(_modifiedAttributes);
            setRequestedOrder(_requestedOrder);
        }
        _requestedOrder.OrderStatus = e.target.value;
        setRequestedOrder(_requestedOrder);
        _modifiedAttributes.OrderStatus = true;
        setModifiedAttributes(_modifiedAttributes);
        setHasOrderStatusUpdate(true);
    }

    const handleCounterChecked = (e: any) => {
        setCounterStatusList(current =>
            current.map(item => {
                if (item.value === 'Countered') {
                    return { ...item, disable: !e.target.checked };
                }
                return item;
            }),
        );
        setCreateCounter(e.target.checked);
        let _initalCounter = { ...requestedOrder };
        _initalCounter.CommentsShared = "Counter order";
        _initalCounter.CommentsPrivate = "Counter order";
        _initalCounter.OrderStatus = "Countered";
        setCounterOrder(_initalCounter);
    }

    const submit = (event: React.MouseEvent<HTMLButtonElement>) => {
        setOpen(false);
        let newCounterOrders: any[] = [];
        let updatedCounterOrders: any[] = [];
        let updatedOrders: any[] = [];
        let currentDate = new Date().toISOString();
        let initiateWorkflow: boolean = false;
        let _updatedOrder: CounterDetails = {
            OrderId: requestedOrder.OrderId,
            LastModifiedBy: props.loggedInUser,
            LastModifiedDate: currentDate
        }
        let _updatedCounterOrder: CounterDetails = {
            OrderId: requestedOrder.OrderId,
            CounterOrderId: counterOrder.CounterOrderId,
            LastModifiedBy: props.loggedInUser,
            LastModifiedDate: currentDate
        }
        let _orderEvent: string = "";
        if (props.data.TradeType === "Trade") {
            if (dataOwner) {
                switch (counterOrder.OrderStatus) {
                    case 'Approved':
                        _orderEvent = 'ORDER_APPROVED';
                        _updatedOrder.OrderStatus = counterOrder.OrderStatus;
                        //initiateWorkflow = true;
                        updatedOrders.push(_updatedOrder);
                        break;
                    case 'Rejected':
                        _orderEvent = 'ORDER_REJECTED';
                        _updatedOrder.OrderStatus = counterOrder.OrderStatus;
                        updatedOrders.push(_updatedOrder);
                        break;
                    default:
                        break;
                }
            }
        } else {
            if (dataRequestor) {
                switch (requestedOrder.OrderStatus) {
                    case 'Accepted':
                        _orderEvent = 'COUNTER_ACCEPTED';
                        getModifiedOrderRequest(_updatedOrder);
                        break;
                    /*case 'Rejected':
                        _orderEvent = 'COUNTER_REJECTED';
                        break; */
                    case 'Countered':
                        _orderEvent = 'COUNTER_RESPONSE';
                        getModifiedOrderRequest(_updatedOrder);
                        break;
                    case 'Placed':
                        _orderEvent = 'COUNTER_RESPONSE';
                        break;
                    case 'Closed':
                        _orderEvent = 'ORDER_WITHDRAWN';
                        getModifiedOrderRequest(_updatedOrder);
                        break;
                    default:
                        break;
                }
                _updatedOrder.OrderEvent = _orderEvent;
                updatedOrders.push(_updatedOrder);
                if (hasCounterOrder) {
                    _updatedCounterOrder.OrderStatus = requestedOrder.OrderStatus;
                    _updatedCounterOrder.OrderEvent = _orderEvent;
                    updatedCounterOrders.push(_updatedCounterOrder);
                }
            } else if (dataOwner) {
                let _counterOrder = { ...counterOrder };
                if (createCounter) {
                    _counterOrder.Data = requestedOrder.Data;
                    _counterOrder.OrderEvent = "COUNTER_CREATED";
                    _counterOrder.OrderStatus = "Countered";
                    newCounterOrders.push(_counterOrder);
                    _updatedOrder.OrderEvent = _counterOrder.OrderEvent;
                    updatedOrders.push(_updatedOrder);
                } else {
                    switch (counterOrder.OrderStatus) {
                        case 'Approved':
                            _orderEvent = 'ORDER_APPROVED';
                            _updatedOrder.OrderStatus = counterOrder.OrderStatus;
                            //initiateWorkflow = true;
                            break;
                        case 'Rejected':
                            _orderEvent = 'ORDER_REJECTED';
                            _updatedOrder.OrderStatus = counterOrder.OrderStatus;
                            break;
                        case 'Countered':
                            _orderEvent = 'COUNTER_AMEND';
                            break;
                        case 'Placed':
                            _orderEvent = 'COUNTER_AMEND';
                            _updatedOrder.OrderStatus = counterOrder.OrderStatus;
                            if (_counterOrder.OrderStatus !== 'Countered') {
                                _updatedCounterOrder.OrderStatus = 'Countered';
                            }
                            break;
                        default:
                            break;
                    }
                    if (hasCounterOrder) {
                        getModifiedCounterRequest(_updatedCounterOrder);
                        _updatedCounterOrder.OrderEvent = _orderEvent;
                        updatedCounterOrders.push(_updatedCounterOrder);
                    }

                    _updatedOrder.OrderEvent = _orderEvent;
                    updatedOrders.push(_updatedOrder);

                }
            }
        }

        if (newCounterOrders.length > 0) {
            createCounterOrder();
        }
        if (updatedCounterOrders.length > 0) {
            updateCounterOrder();
        }
        if (updatedOrders.length > 0) {
            updateOrder(updatedOrders, initiateWorkflow);
        }

        async function createCounterOrder() {
            await api.API_POST('/OrderManagement/SaveOrder', {
                CounterOrders: newCounterOrders,
                GenerateWorkflow: true
            }).then((response) => {
                props.loadPendingRequests('countercreate')
            }).catch(() => {
                props.formMessage('Counter order create failed', 'error');
            });
        }

        async function updateCounterOrder() {
            await api.API_POST('/OrderManagement/UpdateOrder', {
                CounterOrders: updatedCounterOrders
            }).then((response) => {
                props.loadPendingRequests('counterupdate')
            }).catch(() => {
                props.formMessage('Counter order update failed', 'error');
            });
        }
    };

    const updateOrder = async function (updatedOrders: any[], intitiateWorkflow: boolean) {
        await api.API_POST('/OrderManagement/UpdateOrder', {
            orders: updatedOrders
        }).then((response) => {
            if (intitiateWorkflow) {
                triggerWorkflow(updatedOrders);
            } else {
                props.loadPendingRequests('update');
            }
        }).catch(() => {
            props.formMessage('Order update failed', 'error');
        });
    }

    const triggerWorkflow = async (updatedOrders: any[]) => {
        props.setShowProgress(true);
        let orderId = updatedOrders[0].OrderId;
        let initiateWorkflowUrl = '/OrderManagement/InitiateWorkflow'
        await api.API_POST(initiateWorkflowUrl, {
            orderId: orderId,
            operator: props.data.RequestedTo,
            auditIds: getAudidIds(props.data.Data),
            selectedReports: props.data.SelectedReports,
            workflowType: "E",
            userEmailId: props.loggedInUserMailId,
            userName: props.loggedInUser,
            // database: api.env === 'OASIS' ? 'oasis' : 'envoy_dev'
        }).then((response) => {
            if (response?.data?.length > 0) {
                props.formMessage('Order workflow initiated successfully', 'success');
            } else {
                props.formMessage('Order workflow initiate failed', 'error');
            }
            props.loadPendingRequests();
        }).catch(() => {
            console.log('Error while rerieving order approval data', 'error');
            props.setShowProgress(false);
        });
    }

    const getAudidIds = (jsonString: string): string => {
        const jsonData = JSON.parse(jsonString);
        let auditIds: string[] = [];
        for (let prop in jsonData) {
            auditIds.push(jsonData[prop]['DataAuditId']);
        }
        return auditIds.join(",");;
    }

    const close = (event: React.MouseEvent<HTMLButtonElement>) => {
        setOpen(false);
        loadCounterOrders();
    };

    const getModifiedCounterRequest = (_updatedCounterOrder: CounterDetails) => {
        if (modifiedAttributes.StartDate) {
            _updatedCounterOrder.StartDate = counterOrder.StartDate;
        } else {
            delete _updatedCounterOrder.StartDate;
        }
        if (modifiedAttributes.EndDate) {
            _updatedCounterOrder.EndDate = counterOrder.EndDate;
        } else {
            delete _updatedCounterOrder.EndDate;
        }
        if (modifiedAttributes.Amount) {
            _updatedCounterOrder.Amount = counterOrder.Amount;
        } else {
            delete _updatedCounterOrder.Amount;
        }
        if (modifiedAttributes.OrderStatus) {
            _updatedCounterOrder.OrderStatus = counterOrder.OrderStatus;
        } else {
            delete _updatedCounterOrder.OrderStatus;
        }
        if (modifiedAttributes.CommentsShared) {
            _updatedCounterOrder.CommentsShared = counterOrder.CommentsShared;
        } else {
            delete _updatedCounterOrder.CommentsShared;
        }
        if (modifiedAttributes.CommentsPrivate) {
            _updatedCounterOrder.CommentsPrivate = counterOrder.CommentsPrivate;
        } else {
            delete _updatedCounterOrder.CommentsPrivate;
        }
        return _updatedCounterOrder;
    }

    const getModifiedOrderRequest = (_updatedOrder: CounterDetails) => {
        if (modifiedAttributes.StartDate) {
            _updatedOrder.StartDate = requestedOrder.StartDate;
        } else {
            delete _updatedOrder.StartDate;
        }
        if (modifiedAttributes.EndDate) {
            _updatedOrder.EndDate = requestedOrder.EndDate;
        } else {
            delete _updatedOrder.EndDate;
        }
        if (modifiedAttributes.Amount) {
            _updatedOrder.Amount = requestedOrder.Amount;
        } else {
            delete _updatedOrder.Amount;
        }
        if (modifiedAttributes.OrderStatus) {
            _updatedOrder.OrderStatus = requestedOrder.OrderStatus;
        } else {
            delete _updatedOrder.OrderStatus;
        }
        if (modifiedAttributes.CommentsShared) {
            _updatedOrder.CommentsShared = requestedOrder.CommentsShared;
        } else {
            delete _updatedOrder.CommentsShared;
        }
        if (modifiedAttributes.CommentsPrivate) {
            _updatedOrder.CommentsPrivate = requestedOrder.CommentsPrivate;
        } else {
            delete _updatedOrder.CommentsPrivate;
        }
        return _updatedOrder;
    }

    const loadCounterOrders = async () => {
        if (props.operator === requestedOrder.RequestedBy) {
            setDataOwner(false);
            setDataRequestor(true);
            setDisableOrderFields(requestedOrder.OrderStatus === 'Approved' || requestedOrder.OrderStatus === 'Accepted'
                || requestedOrder.OrderStatus === 'Rejected' || requestedOrder.OrderStatus === 'Closed');
            setDisableCounterFields(true);
            setDisableCounterStatus(true);
        } else if (props.operator === requestedOrder.RequestedTo) {
            setDataOwner(true);
            setDataRequestor(false);
            setDisableOrderFields(true);
            setDisableCounterFields(requestedOrder.OrderStatus === 'Approved' || requestedOrder.OrderStatus === 'Accepted' || requestedOrder.OrderStatus === 'Rejected');
            setDisableCounterStatus(requestedOrder.OrderStatus === 'Closed');
        }

        let orderDataUrl = '/OrderManagement/getOrderTableData?schema=orders&table=COUNTER_ORDER&OrderId=' + props.data.OrderId;
        await api.API_GET(orderDataUrl).then((response) => {
            if (response?.data?.CounterOrders) {
                let counterOrders: any[] = response?.data?.CounterOrders;
                if (counterOrders.length > 0) {
                    setCounterOrder(counterOrders[0]);
                    setHasCounterOrder(true);
                    setCreateCounter(false);
                    setDisableCreateCounter(true);
                    setOrderStatusList(current =>
                        current.map(item => {
                            if (item.value === 'Accepted') {
                                return { ...item, disable: false };
                            }
                            return item;
                        }),
                    );
                } else {
                    setOrderStatusList(current =>
                        current.map(item => {
                            if (item.value !== 'Placed' && item.value !== 'Closed') {
                                return { ...item, disable: true };
                            }
                            return item;
                        }),
                    );
                    setCounterStatusList(current =>
                        current.map(item => {
                            if (item.value === 'Countered') {
                                return { ...item, disable: true };
                            }
                            return item;
                        }),
                    );
                    setDisableCreateCounter(requestedOrder.OrderStatus === 'Closed');
                }
            }
        }).catch((error) => console.log('Error while retrieving counter requestedOrder details', error))
    }

    const isResponseOrderMissing = () =>{
        return (props.data.TradeType === "Trade" 
        && props.data.TradeGroupId === 'N/A'  
        && props.gridType === "pending") ? true : false;
    }



    return (
        <div style={{ display: 'flex' }}>

            {props.value}
            {dialogTradeTypes.includes(requestedOrder.TradeType) &&
                <Tooltip arrow placement="right-start"
                    title={
                        <React.Fragment>
                            <b>{'Order status: '}{requestedOrder.OrderStatus}</b>
                            {hasCounterOrder &&
                                <><br /><b>{'Counter status: '}{counterOrder.OrderStatus}</b></>}
                        </React.Fragment>
                    }>
                    <>
                        <IconButton id="opencounterdialog" color="primary" size="small" onClick={openCounterDialog}>
                            {dataRequestor &&
                                <NewReleasesIcon fontSize="small" sx={{ color: 'black' }} />
                            }
                            {dataOwner &&
                                <ReplyIcon fontSize="small" sx={{ color: 'black' }} />
                            }
                        </IconButton >
                        {showEventUpdateGif && <img style={{ height: "30px" }} src={circleImage} />}
                    </>
                </Tooltip>
            }
            {requestedOrder.TradeType === "Trade" &&
                <Tooltip arrow placement="right-start"
                    title={
                        <React.Fragment>
                            {isResponseOrderMissing() && <b>Click here to create response order</b>}
                            {!isResponseOrderMissing() && <b>{'Order status: '}{requestedOrder.OrderStatus}</b>}
                        </React.Fragment>
                    }>
                    <div className={isResponseOrderMissing() ? "heart":""}>
                        <IconButton id="replyExchangeOrder" color="primary" size="small" onClick={replyExchangeOrder}>
                            <MergeIcon fontSize="small"  sx={{ color: isResponseOrderMissing() ? 'red':'black' }} />
                        </IconButton >
                        {showEventUpdateGif && <img style={{ height: "30px" }} src={circleImage} />}
                    </div>

                </Tooltip>
            }
            {requestedOrder.TradeType === "Trade" && requestedOrder.TradeGroupId !== "N/A"  && requestedOrder.detailData && requestedOrder.detailData?.length > 0 ?
                <>
                    {isExpand && <span className="ag-group-expanded" onClick={toggleExapnd} ><span
                        className="ag-icon ag-icon-expanded" style={{ fontWeight: 'bold' }} unselectable="on"></span></span>}
                    {!isExpand && <span className="ag-group-contracted" onClick={toggleExapnd} ><span
                        className="ag-icon ag-icon-contracted" style={{ fontWeight: 'bold' }} unselectable="on"></span></span>}
                </>
                : props.node.setExpanded(!isExpand)}
            <Dialog maxWidth="lg" open={open} onClose={() => { setOpen(false); }}>
                <DialogActions>
                    <Button id="submit" type="submit" variant="outlined" color="success" startIcon={<DoneOutlinedIcon />}
                        onClick={submit}>Submit</Button>
                    <Button id="close" variant="outlined" color="error" startIcon={<CancelOutlinedIcon />}
                        onClick={close}>Close</Button>
                </DialogActions>
                <DialogTitle>{requestedOrder.TradeType} order : {props.value}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Current User : {props.loggedInUser}<br />
                        Data Owner : {props.data.RequestedTo}
                    </DialogContentText><br />

                    <Grid container rowSpacing={1} columnSpacing={2}>
                        <Grid item xs={12}>
                            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                                Order details:
                            </Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                                id="requestedBy" name="RequestedBy" disabled label="From" size="small"
                                value={requestedOrder.RequestedBy} />
                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                                id="requestedTo" name="RequestedTo" disabled label="To" size="small"
                                value={requestedOrder.RequestedTo} />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                id="requestedReports" fullWidth name="RequestedReports" disabled label="Datasets"
                                multiline
                                value={requestedOrder.SelectedReports} rows={2} maxRows={4} />
                        </Grid>
                        <Grid item xs={3}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DateTimePicker
                                    renderInput={(props) => <TextField {...props} />}
                                    label="StartDate"
                                    value={requestedOrder.StartDate}
                                    disabled={disableOrderFields}
                                    onChange={(newValue) => {
                                        setRequestedOrder(
                                            { ...requestedOrder, StartDate: newValue });
                                        setModifiedAttributes(
                                            { ...modifiedAttributes, StartDate: true }
                                        );
                                        setHasOrderUpdate(true);
                                    }} />
                            </LocalizationProvider>
                        </Grid>
                        <Grid item xs={3}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DateTimePicker
                                    renderInput={(props) => <TextField {...props} />}
                                    label="EndDate"
                                    value={requestedOrder.EndDate}
                                    disabled={disableOrderFields}
                                    onChange={(newValue) => {
                                        setRequestedOrder(
                                            { ...requestedOrder, EndDate: newValue });
                                        setModifiedAttributes(
                                            { ...modifiedAttributes, EndDate: true }
                                        );
                                        setHasOrderUpdate(true);
                                    }} />
                            </LocalizationProvider>
                        </Grid>
                        <Grid item xs={3}>
                            <TextField required
                                id="amount" name="Amount" label="Amount" size="small"
                                value={requestedOrder.Amount}
                                disabled={disableOrderFields}
                                type="number"
                                onChange={handleOrderInputChange} />
                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                                id="orderStatus" name="OrderStatus" select label="Status"
                                size="small" disabled={disableOrderFields}
                                SelectProps={{
                                    native: true,
                                }}
                                value={requestedOrder.OrderStatus}
                                onChange={handleOrderStatusChange}>
                                {orderStatusList.map((option) => (
                                    <option disabled={option.disable} key={option.value} value={option.value}>
                                        {option.label}
                                    </option>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item xs={6}>
                            <TextField fullWidth id="CommentsShared" name="CommentsShared" label="Comments (Shared)"
                                value={requestedOrder.CommentsShared} multiline disabled={disableOrderFields}
                                onChange={handleOrderInputChange} rows={4} maxRows={4}>
                            </TextField>
                        </Grid>
                        {dataRequestor &&
                            <Grid item xs={6}>
                                <TextField fullWidth id="CommentsPrivate" name="CommentsPrivate" label="Comments (Private)"
                                    value={requestedOrder.CommentsPrivate} multiline disabled={disableOrderFields}
                                    onChange={handleOrderInputChange} rows={4} maxRows={4}>
                                </TextField>
                            </Grid>
                        }
                    </Grid>
                    <br />
                    {(hasCounterOrder || createCounter) &&
                        <Grid container rowSpacing={1} columnSpacing={2}>
                            <Grid item xs={12}>
                                <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                                    Counter details:
                                </Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DateTimePicker
                                        renderInput={(props) => <TextField {...props} />}
                                        label="StartDate"
                                        disabled={disableCounterFields}
                                        value={counterOrder.StartDate}
                                        onChange={(newValue) => {
                                            setCounterOrder(
                                                { ...counterOrder, StartDate: newValue });
                                            setModifiedAttributes(
                                                { ...modifiedAttributes, StartDate: true }
                                            );
                                            setHasCounterUpdate(true);
                                        }} />
                                </LocalizationProvider>
                            </Grid>
                            <Grid item xs={3}>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DateTimePicker
                                        renderInput={(props) => <TextField {...props} />}
                                        label="EndDate"
                                        value={counterOrder.EndDate}
                                        disabled={disableCounterFields}
                                        onChange={(newValue) => {
                                            setCounterOrder(
                                                { ...counterOrder, EndDate: newValue });
                                            setModifiedAttributes(
                                                { ...modifiedAttributes, EndDate: true }
                                            );
                                            setHasCounterUpdate(true);
                                        }} />
                                </LocalizationProvider>
                            </Grid>
                            <Grid item xs={3}>
                                <TextField required
                                    id="amount" name="Amount" label="Amount" size="small"
                                    value={counterOrder.Amount}
                                    disabled={disableCounterFields}
                                    type="number"
                                    onChange={handleCounterInputChange} />
                            </Grid>
                            <Grid item xs={3}>
                                <TextField
                                    id="counterStatus" name="OrderStatus" select label="Status"
                                    size="small" disabled={disableCounterStatus}
                                    SelectProps={{
                                        native: true,
                                    }}
                                    value={counterOrder.OrderStatus}
                                    onChange={handleCounterStatusChange}>
                                    {counterStatusList.map((option) => (
                                        <option disabled={option.disable} key={option.value} value={option.value}>
                                            {option.label}
                                        </option>
                                    ))}
                                </TextField>
                            </Grid>
                            <Grid item xs={6}>
                                <TextField fullWidth id="CommentsShared" name="CommentsShared" label="Comments (Shared)" multiline
                                    value={counterOrder.CommentsShared} rows={4} maxRows={4}
                                    disabled={disableCounterFields}
                                    onChange={handleCounterInputChange}>
                                </TextField>
                            </Grid>
                            {dataOwner &&
                                <Grid item xs={6}>
                                    <TextField fullWidth id="CommentsPrivate" name="CommentsPrivate" label="Comments (Private)" multiline
                                        value={counterOrder.CommentsPrivate} rows={4} maxRows={4}
                                        disabled={disableCounterFields}
                                        onChange={handleCounterInputChange}>
                                    </TextField>
                                </Grid>
                            }
                        </Grid>}
                    {dataOwner &&
                        <Grid container rowSpacing={1} columnSpacing={2}>
                            <Grid item xs={3}>
                                <FormControlLabel
                                    label="Create counter"
                                    disabled={disableCreateCounter}
                                    control={<Checkbox checked={createCounter} onChange={handleCounterChecked} />} />
                            </Grid>
                            {(!hasCounterOrder && !createCounter && requestedOrder.OrderStatus !== 'Closed') &&
                                <Grid item xs={3}>
                                    <TextField
                                        id="counterStatus" name="OrderStatus" select label="Status"
                                        size="small" disabled={disableCounterStatus}
                                        SelectProps={{
                                            native: true,
                                        }}
                                        value={counterOrder.OrderStatus}
                                        onChange={handleCounterStatusChange}>
                                        {counterStatusList.map((option) => (
                                            <option disabled={option.disable} key={option.value} value={option.value}>
                                                {option.label}
                                            </option>
                                        ))}
                                    </TextField>
                                </Grid>
                            }
                        </Grid>
                    }
                </DialogContent>

            </Dialog>
        </div>

    )

}
export default DialogViewRenderer;