import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import UploadFileOutlinedIcon from "@mui/icons-material/UploadFileOutlined";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip";
import * as React from "react";
import { read, utils } from "xlsx";
// For merge
import * as api from "src/Utility/api";
import { IExplorerTableInfo } from "../../@State/types";

interface IExcelUploadProps {
  // Added for merge
  selectedTableInfo: IExplorerTableInfo;
  refreshdatahandler(): any;
  setSaveAndUpdateInfo(response: any): any;
  setOpenSuccessMessage(successMessage: any): any;
  setErrorList(errorlist: any): any;
  setOpenErrorMessage(msg: any): any;
  //
  columns: any[];
  loggedInUser: string;
  loggedInUserOperator: any;
  environment: any;
  tableData: any[];
  setTableData: React.Dispatch<React.SetStateAction<any[]>>;
  setShowProgress: (show: boolean) => void;
  setTableDataMonitor: React.Dispatch<React.SetStateAction<any[]>>;
  setReplaceDeleteIds: React.Dispatch<React.SetStateAction<any[]>>;
}

export default function ExcelUpload(props: IExcelUploadProps) {
  const inputExcelFileRef: any = React.useRef();
  const mergeInputExcelFileRef: any = React.useRef();
  const fullMergeInputExcelFileRef: any = React.useRef();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleFileUpload = async (e: any) => {
    props.setShowProgress(true);
    const file = await e.target.files[0].arrayBuffer();
    const wb = read(file);
    const excelUploadeData = utils.sheet_to_json<any>(
      wb.Sheets[wb.SheetNames[0]]
    );
    const uploadedDataList: any[] = [];
    const _clonnedNewRowObject = getClonedNewRowObject();

    excelUploadeData.forEach((data) => {
      const newObj = { ..._clonnedNewRowObject };
      props.columns.forEach((column) => {
        let dataObjectKey = Object.keys(data).find(
          (key) => key.toLowerCase() === column.ColumnName.toLowerCase()
        );
        if (dataObjectKey && data[dataObjectKey] !== undefined) {
          newObj[column.ColumnName] = data[dataObjectKey];
        }
      });
      uploadedDataList.push(newObj);
    });

    if (props.selectedTableInfo.pkColumnName !== undefined) {
      props.setReplaceDeleteIds(
        props.tableData.map((ele) => {
          return ele[props.selectedTableInfo.pkColumnName!];
        })
      );
    }
    props.setTableData(uploadedDataList);
    props.setTableDataMonitor(uploadedDataList);
    props.setShowProgress(false);
  };

  // Changes for Upload and Merge

  const formResponseMessage = (response: any) => {
    props.refreshdatahandler();

    props.setSaveAndUpdateInfo({
      insertRows: response.data['inserted_rows'],
      updatedRows: response.data['updated_rows'],
      insertErrors: response.data['insert_errors'],
      updateErros: response.data['update_errors'],
    });

    if (
      response.data['inserted_rows'] > 0 ||
      response.data['updated_rows'] > 0
    ) {
      props.setOpenSuccessMessage({
        success: true,
        message: "Data saved successfully",
        isSaveData: true,
      });
    }
    if (
      +response.data['insert_errors'] > 0 ||
      +response.data['update_errors'] > 0
    ) {
      const _errorList = [];
      if (+response.data['insert_errors'] > 0) {
        _errorList.push(response.data['insert_errors']);
      }
      if (+response.data['update_errors'] > 0) {
        _errorList.push(response.data['update_errors']);
      }
      props.setErrorList(_errorList);
      props.setOpenErrorMessage(true);
    }

    props.setShowProgress(false);
  };

  // Merge - No deletion
  const handleMergeFileUpload = async (e: any) => {
    props.setShowProgress(true);
    const file = await e.target.files[0].arrayBuffer();
    // Calling the Upload Merge Azure Function
    await api
      .API_AF_POST_FILEUPLOAD(
        "/UpdateMergeFn?",
        {
          user: props.loggedInUser,
          operator: props.loggedInUserOperator,
          environment: props.environment,
          schema: props.selectedTableInfo.schema,
          table: props.selectedTableInfo.name,
          primaryKey: props.selectedTableInfo.pkColumnName,
          modified: file,
          fullMerge: "",
        }
      )
      .then((response) => {
        formResponseMessage(response);
        api.LOG_MESSAGE(["Page : " + props.selectedTableInfo.schema + " - " + props.selectedTableInfo.name,"Action : Upload and Merge Data successfull"], {'UserName' : props.loggedInUser});

      })
      .catch(() => {
        props.refreshdatahandler();
        const _errorList = [];
        _errorList.push("Error occured during Upload and Merge API call");
        props.setErrorList(_errorList);
        props.setOpenErrorMessage(true);
      });
  };

  // Full Merge
  const handleFullMergeFileUpload = async (e: any) => {
    props.setShowProgress(true);
    const file = await e.target.files[0].arrayBuffer();
    // Calling the Upload Merge Azure Function
    await api
      .API_AF_POST_FILEUPLOAD(
        "/UpdateMergeFn?",
        {
          user: props.loggedInUser,
          operator: props.loggedInUserOperator,
          environment: props.environment,
          schema: props.selectedTableInfo.schema,
          table: props.selectedTableInfo.name,
          primaryKey: props.selectedTableInfo.pkColumnName,
          modified: file,
          fullMerge: 1,
        }
      )
      .then((response) => {
        formResponseMessage(response);
        api.LOG_MESSAGE(["Page : " + props.selectedTableInfo.schema + " - " + props.selectedTableInfo.name,"Action : Upload and Full Merge Data successfull"], {'UserName' : props.loggedInUser});
      })
      .catch(() => {
        props.refreshdatahandler();
        const _errorList = [];
        _errorList.push("Error occured during Upload and Full Merge API call");
        props.setErrorList(_errorList);
        props.setOpenErrorMessage(true);
      });
  };

  const getClonedNewRowObject = () => {
    let _objectClone: any = {};
    const _columns = [...props.columns];
    _columns.forEach((colInfo: any) => {
      _objectClone[colInfo.ColumnName] = null;
    });

    for (let key in _objectClone) {
      if (_objectClone.hasOwnProperty(key)) {
        if (key.toLowerCase().includes("date")) {
          _objectClone[key] = new Date().toISOString();
        } else if (key.toLowerCase() == "active_yn") {
          _objectClone[key] = "Y";
        }
      }
    }

    _objectClone["isNew"] = true;
    _objectClone["LastModifiedBy"] = props.loggedInUser;
    _objectClone["CreatedBy"] = props.loggedInUser;

    return _objectClone;
  };

  return (
    <React.Fragment>
      <input
        ref={inputExcelFileRef}
        type="file"
        accept=".xlsx, .xls, .csv"
        style={{ display: "none" }}
        onChange={(e) => {
          handleFileUpload(e);
        }}
        id="contained-button-file"
      />
      <input
        ref={mergeInputExcelFileRef}
        type="file"
        accept=".xlsx, .xls, .csv"
        style={{ display: "none" }}
        onChange={(e) => {
          handleMergeFileUpload(e);
        }}
        id="contained-button-file"
      />
      <input
        ref={fullMergeInputExcelFileRef}
        type="file"
        accept=".xlsx, .xls, .csv"
        style={{ display: "none" }}
        onChange={(e) => {
          handleFullMergeFileUpload(e);
        }}
        id="contained-button-file"
      />
      <div style={{ display: "inline" }}>
        <Tooltip title="Excel file upload">
          <IconButton id="excelfileupload"
            color="success"
            onClick={handleClick}
            sx={{
              ml: 2,
              marginLeft: "10px",
              backgroundColor: "rgb(239 238 238)",
              marginRight: "10px",
              padding:'2px'
            }}
            aria-controls={open ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
          >
            <UploadFileOutlinedIcon />
          </IconButton>
        </Tooltip>
      </div>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem onClick={() => inputExcelFileRef.current.click()}>
          <ListItemIcon>
            <FileUploadOutlinedIcon fontSize="small" />
          </ListItemIcon>
          Upload and Replace
        </MenuItem>
        <MenuItem onClick={() => mergeInputExcelFileRef.current.click()}>
          <ListItemIcon>
            <FileUploadOutlinedIcon fontSize="small" />
          </ListItemIcon>
          Upload and Merge
        </MenuItem>
        <MenuItem onClick={() => fullMergeInputExcelFileRef.current.click()}>
          <ListItemIcon>
            <FileUploadOutlinedIcon fontSize="small" />
          </ListItemIcon>
          Upload and Full Merge
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
}
