import { TextField } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import React from 'react';
import { useState, useEffect } from 'react';

function DateTimeEditor(props: any) {
    const [value, setDateTimeValue] = useState(() => { return props.value != null ? new Date(props.value) : new Date()});
  const [column, setColumnName] = useState(props.column);
  const [rowIndex] = useState(props.rowIndex);

  const valueChangeHandler = (_value: any) => {
    props.onDateTimeValueSelected(_value, rowIndex, props.data);
    setDateTimeValue(_value);
  }

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DateTimePicker
          renderInput={(props) => <TextField size="small" {...props} />}
          label={column}
          value={value}
          PopperProps={{
            style: { zIndex: 1002 }
          }}
          onChange={valueChangeHandler} />
      </LocalizationProvider>
    </>
  )
}

export default DateTimeEditor;