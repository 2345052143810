import * as React from 'react';
import { ExchangeOrderType, TradeOrderFromTo, IExchangeOrder, IUpdateOrder } from '../types';
import * as api from 'src/Utility/api';
import { useEffectOnce } from 'src/hooks/useEffectOnce';

export const AppContext = React.createContext<ExchangeOrderType | null>(null);

interface BaseLayoutProps {
    children?: React.ReactNode;
}

const AppProvider: React.FC<BaseLayoutProps> = ({ children }) => {
    const [pickListDefinitions, setPickListDefinitions] = React.useState<any[]>();
    const [exchangeOrder, setExchangeOrder] = React.useState<IExchangeOrder>({});
    const [modifiedOrder, setModifiedOrder] = React.useState<IUpdateOrder>({});
    const [IsAdminUser, setAdminUser] = React.useState(false);
    const [reportGroups, SetReportGroups] = React.useState<any[]>([]);
    const [CommonAppConfig, SetCommonAppConfig] = React.useState<any[]>([]);
    const [orderWells, setOrderWells] = React.useState<string[]>([]);
    const [isMapViewDollar, setIsMapViewDollar] = React.useState<boolean>(false);
    const [oppositeTradeWells, setOppositeTradeWells] = React.useState<string[]>([]);
    const [orderReports, setOrderReports] = React.useState<string[]>([]);
    const [ordersWithEstimates, setOrdersWithEstimates] = React.useState<any[]>([]);
    const [helpVideoFiles, setHelpVideoFiles] = React.useState<any[]>();
    const [orders, SetOrders] = React.useState<any[]>([]);
    const [operatorList, setOperatorList] = React.useState<string[]>([]);
    const [tradeOrderFromTo,setTradeOrderFromTo] = React.useState<TradeOrderFromTo>({
        OrderId:"",
        OrderBy:"",
        OrderTo:""
    });
    const [oppositeTradeOrderFromTo,setOppositeTradeOrderFromTo] = React.useState<TradeOrderFromTo>({
        OrderId:"",
        OrderBy:"",
        OrderTo:""
    });

    const [screenUpdaterTransaction, setScreenUpdaterTransaction] = React.useState<any[]>();
    const [screenUpdaterEstimates, setScreenUpdaterEstimates] = React.useState<any[]>();
    const [screenUpdaterValuation, setScreenUpdaterValuation] = React.useState<any[]>();
    const [reportConfigurations, setReportConfigurations] = React.useState<any[]>();
    const [reportGroupInfo, setReportGroupInfo] = React.useState<any[]>();
    const [dataAuditDataImmutable, setDataAuditDataImmutable] = React.useState<any[]>([]);
    const [dataAuditData, setDataAuditData] = React.useState<any[]>([]);
    const [countyKmlFiles, SetCountyKmlFiles] = React.useState<any[]>();
    const [columnLabels, SetColumnLabels] = React.useState<any[]>([]);
    const [betaDataAuditInfo, setBetaDataAuditInfo] = React.useState(false);
    const [isInfoColumnsOperator, setIsInfoColumnsOperator] = React.useState<string>("");

    const saveExchangeOrder = (exchangeOrder: IExchangeOrder) => {
        setExchangeOrder(exchangeOrder);
    };

    const saveModifiedOrder = (modifiedOrder: IUpdateOrder) => {
        setModifiedOrder(modifiedOrder);
    };

    useEffectOnce(()=>{
        if(reportGroups?.length === 0 || columnLabels?.length === 0 ){
            loadInitialPrerequisiteData();
        }
    });

    React.useEffect(() => {
      if (
        (reportGroups?.length !== 0 || dataAuditData?.length !== 0) &&
        isInfoColumnsOperator !== "" &&
        betaDataAuditInfo === true
      ) {
        fetchReportGroupConfigurationHandler(isInfoColumnsOperator);
        fetchReportGroupsHandler(isInfoColumnsOperator);
        fetchDataAuditHandler(isInfoColumnsOperator);
      }
    }, [betaDataAuditInfo, isInfoColumnsOperator]);

    const fetchReportDisplayName = (reportNames:string) => {       
        let reportDisplayNames:string = reportNames;

        if(reportGroups.length > 0 
            && reportNames!== undefined 
            && reportNames.split(',').length > 0){
            const _reportNames = reportNames.split(',');
            let reportDisplayNamesList:string[] = [];
            _reportNames.forEach((reportName:string)=>{
                const matchingReport = reportGroups.filter((element)=>{return element.DA_ColName.toLowerCase() === reportName.toLowerCase()});
                if(matchingReport.length > 0){
                    reportDisplayNamesList.push(matchingReport[0].Report_Display_name);
                }
            });

            if(reportDisplayNamesList.length > 0){
                reportDisplayNames = reportDisplayNamesList.toString();
            }
        }

        return reportDisplayNames;
    }

    const fetchColumnDisplayName = (columnName: string, tableName: string, schemaName : string) => {  
        let displayName:string = '';
        if(columnLabels.length > 0 
            && columnName!== undefined){
          
                const matchingColumn = columnLabels.filter((element)=>{ return element.TableName.toLowerCase() === tableName.toLowerCase() &&    
                    element.SchemaName.toLowerCase() === schemaName.toLowerCase() && element.ColumnName.toLowerCase() === columnName.toLowerCase()   });
                if(matchingColumn.length > 0){
                    displayName = matchingColumn[0].ColumnLabel;
                }
        }

        return displayName;
    }

    const fetchCommonAppConfigHandler = (isReload:boolean = false) =>{
        async function fetchCommonAppConfig() {
            await api.API_GET('/Table/GetTablesData?schema=settings&table=COMMON_KEYVALUE_CONFIG')
                .then((response) => {
                    if(response?.data){
                    SetCommonAppConfig(response.data.TableData);
                    }
                });
        };

        if(CommonAppConfig.length === 0 || isReload){
            fetchCommonAppConfig();
        }
    }

    const fetchOperatorOrders = (operator :any) =>{
        async function fetchOrders(operator : any) {
            let orderDataUrl = '/OrderManagement/getOrderTableData?schema=orders&table=ORDER_MANAGEMENT&operatorName=' + operator;
            await api.API_GET(orderDataUrl).then((response) => {
                if (response?.data?.Orders && response?.data?.Orders.length > 0) {
                    SetOrders(response?.data?.Orders);
                }
            }).catch((error) => {
                console.log('Error while retrieving my order details', error);
            });
        };
        fetchOrders(operator);
    }

    const fetchOrdersWithEstimatesHandler = () =>{
        async function fetchOrdersWithEstimates() {
            await api.API_GET('/OrderManagement/GetOrdersWithEstimates')
                .then((response) => {
                    setOrdersWithEstimates(response.data);
                });
        };

        fetchOrdersWithEstimates();
    }

    const loadInitialOperatorData = (_loggedInUserMailId: any) => {
        const fetchOperators = async () => {
            await api.API_GET('/Table/GetTablesData?schema=settings&table=OPERATOR')
                .then((response) => {
                    let _operators: string[] = [];
                    response.data.TableData.forEach((data: any) => _operators.push(data.ShortName));
                    setOperatorList(_operators);
                });
        };

        async function fetchUserSecurityPermissionData() {
            await api.API_GET('/Table/GetUserSecurityPermissions?loggedInUser=' + _loggedInUserMailId)
                .then((response) => {
                    if (response.data.TableData.length > 0) {
                        let _operators: string[] = [];
                        let includesAll: boolean = false;
                        if (response.data.TableData != null) {
                            response.data.TableData
                                .forEach((permission: any) => {
                                    _operators = _operators.concat(permission.Operators?.split(','));
                                    if (_operators.includes('All') || _operators.includes('ALL')) {
                                        includesAll = true;
                                    }
                                });
                            if (includesAll) {
                                fetchOperators();
                            }
                            else if (_operators.length > 0) {
                                setOperatorList(Array.from(new Set(_operators)));
                            }
                        }
                    }
                });
        };

        fetchUserSecurityPermissionData();
    };

    const fetchReportGroupsHandler = (isInfoColummnOperator:string = "") =>{
        async function fetchReportGroups() {
            let url = "/Table/GetTablesData?schema=settings&table=REPORT_GROUP";

            if(isInfoColummnOperator !== ""){
                url += "&infoColumnsOperator="+isInfoColummnOperator;
            }

            await api.API_GET(url)
                .then((response) => {
                    if(response?.data){
                    SetReportGroups(response?.data?.TableData);
                    }
                });
        };

        fetchReportGroups();
    }

    const loadInitialPrerequisiteData = () => {
        async function fetchPicklistDefinitions() {
            await api.API_GET('/Table/GetTablesData?schema=settings&table=PICKLIST_DEFINITIONS')
                .then((response) => {
                    if(response?.data){
                    setPickListDefinitions(response?.data?.TableData);
                    }
                });
        };

       //Report Groups
       fetchReportGroupsHandler();

        async function fetchHelpVideoFiles() {
            await api.API_GET('/Common/GetFilesInBlobContainer?containerName=helpvideos')
                .then((response) => {
                    if(response?.data){
                    setHelpVideoFiles(response.data);
                    }
                });
        };

        async function fetchColumnLabels() {
            await api.API_GET('/Table/GetTablesData?schema=settings&table=COLUMN_LABELS')
                .then((response) => {
                    if(response?.data){
                        SetColumnLabels(response?.data?.TableData);
                    }
                });
        };

        fetchPicklistDefinitions();
        
        fetchCommonAppConfigHandler();
        fetchHelpVideoFiles();  
        fetchOrdersWithEstimatesHandler();  
        fetchColumnLabels();  
    };

    const fetchDataAuditHandler = (isInfoColummnOperator:string = "") =>{
        const fetchDataAudit = async () => {
            let url = "/Table/GetTablesData?schema=DataAudit&table=DATA_AUDIT";

            if(isInfoColummnOperator !== ""){
                url += "&infoColumnsOperator="+isInfoColummnOperator;
            }
            await api.API_GET(url)
                .then((response) => {
                    setDataAuditData(response.data.TableData);
                    setDataAuditDataImmutable(response.data.TableData);
                });
        };

        fetchDataAudit();
    }

    const fetchReportGroupConfigurationHandler = (
      isInfoColummnOperator: string = ""
    ) => {
      async function fetchReportGroupsConfig() {
        let url = "/Report/GetReportConfiguration";

        if(isInfoColummnOperator !== ""){
            url += "?infoColumnsOperator="+isInfoColummnOperator;
        }

        await api.API_GET(url).then((response) => {
          setReportConfigurations(response.data);
        });
      }

      fetchReportGroupsConfig();
    };

    const loadInitialMapData = () => {
        
        async function fetchReportGroupInformation() {
            await api.API_GET('/Report/GetReportInformation')
                .then((response) => {
                    setReportGroupInfo(response.data);
                });
        };
       
        // Data  Audit handler
        fetchDataAuditHandler();

        async function fetchCountyKMLFiles() {
            await api.API_GET('/Common/GetFilesInBlobContainer?containerName=kmlfilesprod')
                .then((response) => {
                    SetCountyKmlFiles(response.data);
                });
        };

        
        fetchCountyKMLFiles();
        fetchReportGroupConfigurationHandler();
        fetchReportGroupInformation();
    };

    return <AppContext.Provider value={{
        exchangeOrder,
        saveExchangeOrder,
        modifiedOrder,
        saveModifiedOrder,
        reportGroups,
        loadInitialPrerequisiteData,
        orderWells,
        oppositeTradeWells,
        pickListDefinitions,
        tradeOrderFromTo,
        oppositeTradeOrderFromTo,
        isMapViewDollar,
        orderReports,
        screenUpdaterTransaction,
        screenUpdaterEstimates,
        screenUpdaterValuation,
        ordersWithEstimates,
        betaDataAuditInfo,
        isInfoColumnsOperator,
        setTradeOrderFromTo,
        setOppositeTradeOrderFromTo,
        setOrderWells,
        setOppositeTradeWells,
        setIsMapViewDollar,
        setOrderReports,
        setScreenUpdaterTransaction,
        setScreenUpdaterEstimates,
        setScreenUpdaterValuation,
        setBetaDataAuditInfo,
        setIsInfoColumnsOperator,
        fetchReportDisplayName,
        fetchCommonAppConfigHandler,
        fetchOperatorOrders,
        fetchOrdersWithEstimatesHandler,
        fetchReportGroupsHandler,
        fetchDataAuditHandler,
        CommonAppConfig,
        helpVideoFiles,
        orders,
        loadInitialMapData,
        reportConfigurations,
        reportGroupInfo,
        dataAuditData,
        dataAuditDataImmutable,
        setDataAuditData,
        countyKmlFiles,
        IsAdminUser,
        setAdminUser,
        fetchColumnDisplayName,
        operatorList,
        loadInitialOperatorData
    }}>
        {children}
    </AppContext.Provider>;
};

export default AppProvider;