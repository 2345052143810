import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { Alert, Box, Button, ButtonGroup, Collapse, DialogActions, DialogTitle, IconButton, Popper, PopperPlacementType, Tooltip, Typography } from '@mui/material';
import { AgGridReact } from 'ag-grid-react';
import React, { useCallback, useContext, useEffect } from 'react';
import { AppContext } from '../../@State/context/AppContext';
import { ExchangeOrderType, ITableGridColumnDefinition } from '../../@State/types';
import '../Cart/Cart.css';
import MapViewRenderer from '../Cart/MapViewRenderer';
import { useEffectOnce } from 'src/hooks/useEffectOnce';
import { Rnd } from 'react-rnd';

function CartDataRenderer(props: any) {
  const [open, setOpen] = React.useState(false);
  const [selectedData, setSelectedData] = React.useState<any[]>([]);
  const [placement] = React.useState<PopperPlacementType>('bottom-start');
  const innerGridCartRef: any = React.useRef();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [noOfWells, setNoOfWells] = React.useState<number>(0);
  const [isExecutedOrder, setIsExecutedOrder] = React.useState(false);

  const {
    orderWells,
    setOrderWells } = useContext(AppContext) as ExchangeOrderType;

  const [hiddenColumns] = React.useState<string[]>(["Approver1"
    , "Comment1"
    , "ApprovedDate1"
    , "ApprovalStatus1"
    , "Approver2"
    , "Comment2"
    , "ApprovedDate2"
    , "ApprovalStatus2"
    , "Approver3"
    , "Comment3"
    , "ApprovedDate3"
    , "ApprovalStatus3"]);

  const defaultColDef = React.useMemo(() => {
    return {
      minWidth: 100,
      resizable: true,
      cellStyle: { textAlign: 'left' }
    };
  }, []);

  const [gridOptions] = React.useState<any>({
    rowData: [],
    columnDefs: [],
    defaultColDef: defaultColDef,
    pagination: false
  });

  const handleClickOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
    setTimeout(() => {
      loadCartInformation();
    }, 200);
  };

  useEffect(() => {
    loadNoOfWells();
  },[]);

  useEffect(() => {
    if (typeof (props.setIsEdittedHandler) !== "undefined") {
      if (open) {
        props.setIsEdittedHandler(true);
      } else {
        props.setIsEdittedHandler(false);
      }
    }
  }, [open])

  const handleClickClose = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setOpen(false);
  };

  const loadCartInformation = () => {
    let data: any[] = [];
    let columns: any[] = ['DataAuditId', 'API', 'WellName', 'County', 'Operator'];

    if (props.IsSummary || props.data) {
      if (typeof (props.data.Data) === 'string') {
        setIsExecutedOrder(props.data?.OrderStatus === 'Executed');
        data = JSON.parse(props.data.Data);
        if (data.length > 0) {
          columns = columns.concat(props.data.SelectedReports.split(","));
        }
      }
      else if (props.data.Data.length > 0) {
        data = props.data.Data;
        columns = columns.concat(props.data.SelectedReports.split(","));
      }
    } else if (props.cartData.length > 0) {
      setIsExecutedOrder(props.cartData[props.rowIndex].OrderStatus === 'Executed');
      if (typeof (props.cartData[props.rowIndex].Data) === 'string') {
        data = JSON.parse(props.cartData[props.rowIndex].Data);
        if (data.length > 0) {
          columns = columns.concat(props.cartData[props.rowIndex].SelectedReports.split(","));
        }
      }
      else if (props.cartData[props.rowIndex].Data.length > 0) {
        if (props.cartData[props.rowIndex].Data.length > 0) {
          data = props.cartData[props.rowIndex].Data;
          columns = columns.concat(props.cartData[props.rowIndex].SelectedReports.split(","));
        }
      }
    }

    setSelectedData(data);
    let columnDefinition: ITableGridColumnDefinition[] = [];
    columns.forEach((row) => {
      if (!hiddenColumns.includes(row)) {
        columnDefinition.push({
          field: row,
          //type: 'editableColumn',
          width: 120,
          headerName: row,
          headerTooltip: row,
          tooltipField: row,
          cellClass: ['ag-cell-custom'],
          sortable: true,
          filter: 'agTextColumnFilter'
        });
      }
    });
    if (typeof (innerGridCartRef?.current?.api) !== 'undefined') {
      columnDefinition[0].headerCheckboxSelection = true;
      columnDefinition[0].checkboxSelection = true;
      innerGridCartRef.current.api.setColumnDefs(columnDefinition);
      innerGridCartRef.current.api.setRowData(data);
    } else {
      innerGridCartRef.current.api.setColumnDefs([]);
      innerGridCartRef.current.api.setRowData([]);
    }
  }

  const loadNoOfWells = () => {
    let data: any[] = [];
    if (props.cartData.length > 0) {
      let index = props.data != undefined ? props.cartData.indexOf(props.data) : props.rowIndex;
      if (typeof (props.cartData[index]?.Data) === 'string' && props.cartData[index]?.Data != "") {
        data = JSON.parse(props.cartData[index].Data);
        setNoOfWells(data?.length);
      } else {
        let matchObject = props.cartData.find((element:any)=>{ return element.OrderId === props.data.OrderId});
        if (matchObject !== undefined && matchObject !== null) {
          if (typeof (matchObject?.Data) === 'string' && matchObject?.Data != "") {
            data = JSON.parse(matchObject.Data);
          }else{
            data = matchObject.Data;
          }
          
          setNoOfWells(data?.length);
        }
      }
    }
    else {
      if (typeof (props.data.Data) === 'string' && props.data.Data != "") {
        data = JSON.parse(props.data.Data);
        setNoOfWells(data?.length);
      } else if (props.data.Data.length > 0) {
        if (props.data.Data.length > 0) {
          data = props.data.Data;
          setNoOfWells(data?.length);
        }
      }
    }

  }

  const removeWells = () => {
    let _selectedData = [...selectedData];
    let selectedRows: any[] = innerGridCartRef.current.api.getSelectedRows();
    let deletedIds: string[] = selectedRows.map((data) => { return data.DataAuditId });
    if (deletedIds.length > 0) {
      _selectedData = _selectedData.filter((data) => { return !deletedIds.includes(data.DataAuditId) });
      innerGridCartRef.current.api.setRowData(_selectedData);
      setSelectedData(_selectedData);
      setNoOfWells(_selectedData?.length);
      props.onRemoveWells(_selectedData, props.rowIndex, props.data);
    }
  }

  const addWells = () => {

    if(noOfWells < props.maxWellsCount) {
      let _selectedData = [...selectedData];
      props.onAddWells(_selectedData, props.rowIndex, props.data);
  
      if (_selectedData.length > 0) {
        _selectedData[0].orderId = props.data.OrderId;
        _selectedData[0].addWells = true;
      }
  
      setOrderWells(_selectedData);
        closeOrderSummaryHandler();
        setOpen(false);
    }
  }

  const closeOrderSummaryHandler = () => {
    if (props.isOrderSummary === true) { // Close if its from order summary screen //
      props.closeOrderSummaryHandler();
    }
  }

  const autoSizeColumns = (timeoutPeriod: number) => {
    setTimeout(() => {
      innerGridCartRef?.current?.columnApi?.autoSizeAllColumns(false);
    }, timeoutPeriod);
  }

  const onFirstDataRendered = useCallback((timeoutPeriod: number) => {
    autoSizeColumns(timeoutPeriod);
  }, []);

  return (
    <div style={{ cursor: 'pointer' }} >
      <p style={{ margin: "0px", float: 'right' }}>{props.hideText ? "" : noOfWells + " Wells"}
        <IconButton id="cartdata"
          sx={{ padding: '0px' }}
          color="primary" size="small"
          onClick={handleClickOpen}
          aria-label="data">
          <MoreVertOutlinedIcon />
        </IconButton>
        {props.isFromCart &&
          <MapViewRenderer
            data={props.data}
            setTabIndex={props.setTabIndex}
            groupedOrders={props.groupedOrders}
            orderPickHandler={props.orderPickHandler}
            selectedOperator={props.selectedOperator}>
          </MapViewRenderer>}
      </p>
      <Popper open={open} placement={placement} anchorEl={anchorEl} style={{ zIndex: '10000' }}>
        <Rnd minWidth={'110vh'}>
          <Box sx={{ border: '2px solid #bfbfbf', p: 1, bgcolor: 'background.paper' }}>
            <DialogTitle>Selected Wells:</DialogTitle>
            {noOfWells >= props.maxWellsCount &&
                    <Collapse in={noOfWells >= props.maxWellsCount} sx={{ display: "inline-block", mt: -11 }}>
                        <Alert severity="error"
                            sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                            <strong>  You cannot add more than {props.maxWellsCount} wells to this Order </strong> 
                        </Alert>
                    </Collapse>
                }
            <ButtonGroup sx={{ display: (props.isReadOnly || isExecutedOrder) ? "none" : props.cartData.length > 0 && props.cartData[props.rowIndex]?.RequestedBy === props.selectedOperator ? "flex" : props.data.RequestedBy === props.selectedOperator ? "flex" : "none" }} size="small" aria-label="button group">
                <Button id="addmorewell" variant="outlined" disabled = {noOfWells == props.maxWellsCount} onClick={addWells} startIcon={<AddCircleOutlineIcon />}>
                  Add more wells
                </Button>
              <Button id="removeexistingwell" variant="outlined" onClick={removeWells} startIcon={<RemoveCircleOutlineIcon />}>
                Remove existing wells
              </Button>
            </ButtonGroup>
            <div style={{ height: '25vh', width: '100%',minWidth:'100vh' }} className="ag-theme-balham">
              <AgGridReact
                ref={innerGridCartRef}
                rowSelection={'multiple'}
                columnHoverHighlight={true}
                onFirstDataRendered={() => { onFirstDataRendered(10) }}
                gridOptions={gridOptions}>
              </AgGridReact>
            </div>
            <DialogActions>
              <Button id="cdrokbtn1"
                variant="outlined"
                color="success"
                startIcon={<DoneOutlinedIcon />}
                onClick={handleClickClose}>
                Ok</Button>
              {/* <Button
              variant="outlined"
              color="error"
              startIcon={<CancelOutlinedIcon />}
              onClick={handleClickClose}>
              Close</Button> */}
            </DialogActions>
          </Box>
        </Rnd>
      </Popper>
    </div>
  )
}

export default CartDataRenderer;