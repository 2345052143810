import { Alert, AlertColor, Box, Button, Checkbox, Fade, Grid, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Popper, Snackbar, TextField, Tooltip, Typography } from "@mui/material";
import ArrowCircleUpOutlinedIcon from '@mui/icons-material/ArrowCircleUpOutlined';
import ArrowCircleDownOutlinedIcon from '@mui/icons-material/ArrowCircleDownOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import RotateLeftOutlinedIcon from '@mui/icons-material/RotateLeftOutlined';
import { useEffect, useState } from "react";
import IOSSwitchToggle from "src/Common/IOSSwitchToggle";
import DisplaySettingsIcon from '@mui/icons-material/DisplaySettings';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import LayersOutlinedIcon from '@mui/icons-material/LayersOutlined';
import LayersClearIcon from '@mui/icons-material/LayersClear';
import React from "react";
import * as api from 'src/Utility/api';
import { Rnd } from 'react-rnd';
import { ExchangeOrderType } from "../../@State/types";
import { AppContext } from '../../@State/context/AppContext';
import ColumnNameEditor from "./ColumnNameEditor/ColumnNameEditor";
import { findDuplicates } from "src/Utility/common";

interface IColumnSelectionProps {
    tablePreferenceList: any[],
    selectedPreference: any,
    loggedInUserMailId: string,
    loggedInUser: string,
    setselectedPreference: (value: React.SetStateAction<any>) => void,
    hiddenColumns: string[],
    columnsPerfOpen: boolean,
    anchorEl: HTMLElement | null,
    applyColumnPreferenceHandler: (selectedPreference: any) => void,
    setcolumnsPerfOpen: (value: React.SetStateAction<boolean>) => void,
    displayColumns?: string[]
}

function ColumnSelection(props: IColumnSelectionProps) {
    const [enableRightMove, setEnableRightMove] = useState(false);
    const [enableLefttMove, setEnableLefttMove] = useState(false);
    const [checked, setChecked] = useState<string[]>([]);
    const [open, setOpen] = useState<boolean>(false);
    const [openInfo, setOpenInfo] = useState<boolean>(false);
    const [selectedColumnObjects, setSelectedColumnObjects] = useState<any[]>([]);
    const canBeOpen = props.columnsPerfOpen && Boolean(props.anchorEl);
    const id = canBeOpen ? 'transition-popper' : undefined;
    const [preferenceName, setPreferenceName] = React.useState<string>('');
    const [preferenceId, setPreferenceId] = React.useState<number>(0);
    const [selectedPreferenceName, setSelectedPreferenceName] = React.useState<string>('');
    const [isPublic, setIsPublic] = React.useState<boolean>(false);
    const [isModifyDisabled, setIsModifyDisabled] = React.useState<boolean>(false);
    const [tablePreferenceList, setTablePreferenceList] = React.useState<any[]>([]);
    const [columnPreference, setColumnPreference] = React.useState<any[]>([]);
    const [defaultColumns, setDefaultColumns] = React.useState<any[]>([]);
    const [selectedPreference, setselectedPreference] = React.useState<any>();
    const [messageInfo, setMessageInfo] = React.useState<any>({ Open: false, Message: "", severity: "" });
    const [isAddNewPreference, setIsAddNewPreference] = React.useState<boolean>(false);
    const { fetchColumnDisplayName } = React.useContext(AppContext) as ExchangeOrderType;
    useEffect(() => {
        if (props.selectedPreference != null) {
            setselectedPreference(props.selectedPreference);
            setSelectedPreferenceName(props.selectedPreference.PreferenceName);

        }
        else {
            if (props.tablePreferenceList.length > 0) {
                let preference = props.tablePreferenceList[0];
                setselectedPreference(preference);
                setSelectedPreferenceName(preference.PreferenceName);

            }
        }
    }, [props.selectedPreference]);

    useEffect(() => {
        if (selectedPreference != null) {
            setPreferenceName(selectedPreference.PreferenceName);
            setPreferenceId(selectedPreference.PreferenceId);
            //setSelectedPreferenceName(selectedPreference.PreferenceName);
            let isModifyDisabled = selectedPreference.UserMailId !== props.loggedInUserMailId;
            setIsModifyDisabled(isModifyDisabled);
            setIsPublic(selectedPreference.IsPublic);

            if (selectedPreference.PreferenceJson != null && selectedPreference.PreferenceJson.length > 0) {
                let columns = [...selectedPreference.PreferenceJson];
                columns.forEach((ele: any) => {
                    //let displayName = fetchColumnDisplayName(ele.ColumnName, selectedPreference.SchemaName, selectedPreference.TableName);
                    ele["DisplayName"] = ele.DisplayName != null ? ele.DisplayName : ele.ColumnName;
                });

                // Check for display columns which are not there in preference then add that to Available
                const columnsNotInPreference = props.displayColumns?.filter(o => !columns.some(({ ColumnName }) => o.toLowerCase() === ColumnName.toLowerCase()));
                columnsNotInPreference?.forEach((column: any) => {
                    //let displayName = fetchColumnDisplayName(column, selectedPreference.SchemaName, selectedPreference.TableName);
                    columns.push({
                        ColumnName: column,
                        DisplayName: column,
                        Enable: true,
                        AvailableOrDisplayed: "D"
                    });
                });

                setDefaultColumns(columns);

                setColumnPreference(columns);
                setEnableRightMove(columns.filter((ele: any) => { return ele.AvailableOrDisplayed === "A" }).length > 0);
                setEnableLefttMove(columns.filter((ele: any) => { return ele.AvailableOrDisplayed === "D" }).length > 0);
            }
        }
    }, [selectedPreference]);

    useEffect(() => {
        setTablePreferenceList(props.tablePreferenceList);
    }, [props.tablePreferenceList])

    const applyColumnPreferenceHandler = async (isSave: boolean) => {
        if (selectedPreference.PreferenceId > 0) {
            if (selectedPreference.UserMailId == props.loggedInUserMailId) {
                await api.API_POST('/Common/ApplyColumnPreferences', {
                    "preferenceId": selectedPreference.PreferenceId,
                    "userMailId": props.loggedInUserMailId,
                    "schemaName": selectedPreference.SchemaName,
                    "tableName": selectedPreference.TableName,
                    "isDefault": true,
                    "createdBy": props.loggedInUser
                }).then((response) => {
                    if (response?.data) {
                        api.LOG_MESSAGE(["Page : " + selectedPreference.SchemaName + " - " + selectedPreference.TableName, "Action : Preference applied : + " + selectedPreference.PreferenceName], { 'UserName': props.loggedInUser });
                    }
                }).catch(() => {
                });
            }

            let preference = { ...selectedPreference }
            preference.PreferenceJson = columnPreference;
            props.applyColumnPreferenceHandler(preference);

            if (!isSave) {
                props.setcolumnsPerfOpen(false);
                setOpen(false);
            }
        } else {
            setMessageInfo({ Open: true, Message: "Select Preference to apply", severity :"error" });

        }
    }

    const handleToggle = (value: string) => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    const columnSelectHandler = (columnPerferenceObj: any, isEnable: boolean) => {
        let _selectedColumnObjects = [...selectedColumnObjects];
        const _columnPerferenceObj = { ...columnPerferenceObj }
        const _columnPerferenceObjChecked = { ...columnPerferenceObj }

        const matchObjects = _selectedColumnObjects.filter((ele: any) => { return ele.ColumnName === columnPerferenceObj.ColumnName });
        const matchIndex = matchObjects.length > 0 ? _selectedColumnObjects.indexOf(matchObjects[0]) : -1;
        if (matchIndex == -1) {
            _columnPerferenceObj.Enable = isEnable;
            _columnPerferenceObj.AvailableOrDisplayed = isEnable ? "D" : "A";
            _selectedColumnObjects.push(_columnPerferenceObj);
            setSelectedColumnObjects(_selectedColumnObjects);
        } else {
            _selectedColumnObjects.splice(matchIndex, 1);
            setSelectedColumnObjects(_selectedColumnObjects);
        }

        handleToggle(columnPerferenceObj.ColumnName);
        setColumnPerefenceHanler([_columnPerferenceObjChecked]);
    }

    const setColumnPerefenceHanler = (columnPerferenceObjs: any[]) => {
        let _columnPreference = [...columnPreference];
        columnPerferenceObjs.forEach((columnPerferenceObj: any) => {
            const _preferenceMatch = _columnPreference.filter((ele: any) => { return ele.ColumnName === columnPerferenceObj.ColumnName });
            if (_preferenceMatch.length > 0) {
                _columnPreference[_columnPreference.indexOf(_preferenceMatch[0])] = columnPerferenceObj;
            }
        });
        setColumnPreference(_columnPreference);
        setEnableRightMove(_columnPreference.filter((ele: any) => { return ele.AvailableOrDisplayed === "A" }).length > 0);
        setEnableLefttMove(_columnPreference.filter((ele: any) => { return ele.AvailableOrDisplayed === "D" }).length > 0);
    }

    const setPreference = (key: any) => {
        const index = key.options.selectedIndex;
        const preferences = [...tablePreferenceList];
        let selectedPreference = { ...preferences[index] };
        setselectedPreference(selectedPreference);
        setSelectedPreferenceName(selectedPreference.PreferenceName);
        let isModifyDisabled = selectedPreference.UserMailId !== props.loggedInUserMailId;
        setIsModifyDisabled(isModifyDisabled);
        setIsAddNewPreference(false);
    }

    const CloseMessage = () => {
        setMessageInfo({ Open: false, Message: "", severity: 'info' });
    }

    const moveAllHandler = (columnPerferenceObj: any[], isEnable: boolean) => {
        let _columnPerferenceObj = [...columnPerferenceObj];
        _columnPerferenceObj.forEach((ele: any) => {
            ele.Enable = isEnable;
            ele.AvailableOrDisplayed = isEnable ? "D" : "A";
        });

        setSelectedColumnObjects(_columnPerferenceObj);
        setColumnPerefenceHanler(_columnPerferenceObj);
    }

    const addNewPreferenceHandler = () => {
        setIsAddNewPreference(true);
        let preferences: any[] = [];
        let columnPreference = [...defaultColumns];

        columnPreference.forEach((element: any) => {
            //let displayName = fetchColumnDisplayName(element.ColumnName, selectedPreference.SchemaName, selectedPreference.TableName);
            preferences.push({
                ColumnName: element.ColumnName,
                DisplayName: element.DisplayName,
                Enable: true,
                AvailableOrDisplayed: "D"
            });
        });

        preferences = selectedPreference.PreferenceId > 0 ? [...selectedPreference.PreferenceJson] : columnPreference;
        setIsModifyDisabled(false);
        let preference: any = {
            PreferenceJson: preferences,
            PreferenceId: 0,
            PreferenceName: '',
            SchemaName: selectedPreference.SchemaName,
            TableName: selectedPreference.TableName,
            UserMailId: props.loggedInUserMailId,
            IsPublic: true,
            IsDefault: false,
            CreatedBy: props.loggedInUserMailId
        };

        setselectedPreference(preference);
        setChecked([]);
        setSelectedColumnObjects([]);
    }

    const savePreferenceHandler = () => {
        if (preferenceName?.trim().length > 0) {
            saveColumnPreferenceHandler(selectedPreference);
        }
        else {
            setMessageInfo({ Open: true, Message: "Preference Name is required", severity:"error" });
        }
    }

    const deletePreferenceHandler = async () => {

        let delPrefUrl = '/Common/DeleteColumnPreference?preferenceId='
            + preferenceId
            + '&userName=' + props.loggedInUser;

        await api.API_GET(delPrefUrl).then((response) => {
            if (response.data) {
                setMessageInfo({ Open: true, Message: "Preference Deleted Successfully", severity :"success" });
                api.LOG_MESSAGE(["Page : " + selectedPreference.SchemaName + " - " + selectedPreference.TableName, "Action : Preference Deleted : + " + selectedPreference.PreferenceName], { 'UserName': props.loggedInUser });

                const preferences = tablePreferenceList.filter((ele: any) => { return ele.PreferenceId !== preferenceId });
                if (preferences.length > 0) {
                    setTablePreferenceList(preferences);
                    setselectedPreference(preferences[0]);
                    setSelectedPreferenceName(preferences[0].PreferenceName);
                }
                else {
                    addNewPreferenceHandler();
                }
            }
        }).catch(error => {
        }).finally(() => {
        });
    }

    const saveColumnPreferenceHandler = async (columnPreferenceObj: any) => {
        let preference = [...columnPreference];
        let _displayNames = preference.map((element) => { return element.DisplayName });
        // preference.forEach((ele: any) => {
        //     delete ele.DisplayName;
        // });
        if (findDuplicates(_displayNames).length > 0) {
            setMessageInfo({ Open: true, Message: "Cannot save duplicate column names !", severity :"error" });
        } else {

            await api.API_POST('/Common/SaveColumnPreferences', {
                "preferenceId": selectedPreference != null ? selectedPreference.PreferenceId : 0,
                "preferenceName": preferenceName,
                "schemaName": selectedPreference.SchemaName,
                "tableName": selectedPreference.TableName,
                "preferenceJson": preference,
                "userMailId": props.loggedInUserMailId,
                "isPublic": isPublic,
                "isDefault": false,
                "createdBy": props.loggedInUser
            }).then((response) => {
                if (response?.data) {
                    let preferenceList = [...tablePreferenceList];
                    const preference = preferenceList.find(i => i.PreferenceId == response.data.PreferenceId)
                    if (preference) {
                        const index = preferenceList.indexOf(preference);
                        preferenceList.splice(index, 1, response.data);
                    }
                    else {
                        preferenceList?.push(response.data);
                    }

                    api.LOG_MESSAGE(["Page : " + selectedPreference.SchemaName + " - " + selectedPreference.TableName, "Action : Preference Saved Successfully : + " + selectedPreference.PreferenceName], { 'UserName': props.loggedInUser });

                    setTablePreferenceList(preferenceList);
                    setselectedPreference(response?.data);
                    setSelectedPreferenceName(response?.data.PreferenceName);
                    applyColumnPreferenceHandler(true);
                }

                setMessageInfo({ Open: true, Message: "Preference Saved Successfully", severity :"success" });
                setIsAddNewPreference(false);

            }).catch(() => {

            });
        }
    }

    const enableIsPublic = (checked: boolean) => {
        setIsPublic(checked);
    }

    function array_move(direction: string) {
        if (selectedColumnObjects.length === 1) {
            let _columnPerferenceObjs = [...columnPreference];
            let currentObj = _columnPerferenceObjs.filter((element: any) => { return element.ColumnName === selectedColumnObjects[0].ColumnName });
            let currentIndex = _columnPerferenceObjs.indexOf(currentObj[0]);
            let new_index = direction === "UP" ? (currentIndex - 1) : (currentIndex + 1);
            if (new_index !== -1 && new_index < _columnPerferenceObjs.length) {
                if (new_index >= _columnPerferenceObjs.length) {
                    let k = new_index - _columnPerferenceObjs.length + 1;
                    while (k--) {
                        _columnPerferenceObjs.push(undefined);
                    }
                }
                _columnPerferenceObjs.splice(new_index, 0, _columnPerferenceObjs.splice(currentIndex, 1)[0]);
                setColumnPreference(_columnPerferenceObjs);
            }
        } else {
            setOpenInfo(true);
        }
    };

    const setColumnPreferenceDisplayNameHandler = (columnName: string, displayColumnname: string) => {
        const matchObjects = columnPreference.filter((ele: any) => { return ele.ColumnName === columnName });
        if (matchObjects.length > 0) {
            const matchIndex = columnPreference.indexOf(matchObjects[0]);
            matchObjects[0].DisplayName = displayColumnname;
            let _columnPreferenceCopy = [...columnPreference];
            _columnPreferenceCopy[matchIndex] = matchObjects[0];
            setColumnPreference(_columnPreferenceCopy);
        }
    }

    return (
        <Popper id={id} sx={{ zIndex: 10000 }} open={props.columnsPerfOpen} placement='bottom-end' anchorEl={props.anchorEl} transition>
            {({ TransitionProps }) => (
                <Rnd minWidth={'80vh'} style={{ position: 'relative' }}>
                    <Fade {...TransitionProps} timeout={350}>
                        <Box sx={{ border: '1px solid #bfbfbf', p: 1, bgcolor: 'background.paper' }}><>
                            <Grid container sx={{ padding: "10px" }}>
                                <Grid item xs={4} style={{ display: 'flex' }}>
                                    <TextField
                                        sx={{
                                            width: '100%',
                                            maxWidth: 300,
                                            minWidth: 260
                                        }}
                                        onChange={(value) => { setPreferenceName(value.target.value) }}
                                        disabled={isModifyDisabled}
                                        id="preferenceName" name="preferenceName" label="PreferenceName" size="small"
                                        value={preferenceName} />
                                    <div style={{ marginLeft: "20px" }}>
                                        {!isModifyDisabled &&
                                            <IOSSwitchToggle label={"Public"} value={isPublic} handleCallBack={enableIsPublic}></IOSSwitchToggle>}
                                    </div>

                                </Grid>

                                <Grid item xs={8} sx={{ textAlign: 'right' }} justifyContent="right">

                                    <Tooltip title="Add Preference">
                                        <IconButton
                                            id="addpref"
                                            color="success"
                                            aria-label="Add Row"
                                            component="label"
                                            style={{ backgroundColor: "rgb(239 238 238)", marginRight: '5px' }}
                                            onClick={() => { addNewPreferenceHandler(); }}>
                                            <AddCircleIcon />
                                        </IconButton>
                                    </Tooltip>

                                    <Tooltip title="Delete">
                                        <IconButton
                                            id="delete"
                                            color="warning"
                                            aria-label="Delete"
                                            disabled={isModifyDisabled || isAddNewPreference}
                                            component="label"
                                            style={{ backgroundColor: "rgb(239 238 238)", marginRight: '5px' }}
                                            onClick={() => { deletePreferenceHandler(); }}>
                                            <DeleteIcon />
                                        </IconButton>
                                    </Tooltip>

                                    <Tooltip title="Save" style={{ marginRight: "10px" }}>
                                        <IconButton
                                            id="save"
                                            color="success"
                                            aria-label="Save"
                                            disabled={isModifyDisabled}
                                            component="label"
                                            style={{ backgroundColor: "rgb(239 238 238)", marginRight: '5px' }}
                                            onClick={() => { savePreferenceHandler(); }}>
                                            <SaveIcon />
                                        </IconButton>
                                    </Tooltip>

                                    <Tooltip title="Apply">
                                        <IconButton
                                            id="apply"
                                            color="success"
                                            aria-label="Apply"
                                            disabled={isAddNewPreference}
                                            style={{ backgroundColor: "rgb(239 238 238)", width: "fit-content", marginRight: '5px' }}
                                            onClick={() => { applyColumnPreferenceHandler(false); }}>
                                            <DisplaySettingsIcon fontSize="small" />
                                        </IconButton>
                                    </Tooltip>

                                    <Tooltip title="Cancel">
                                        <IconButton
                                            id="cancel"
                                            color="success"
                                            aria-label="Apply"
                                            style={{ backgroundColor: "rgb(239 238 238)", width: "fit-content" }}
                                            onClick={() => {
                                                props.setcolumnsPerfOpen(false);
                                                setChecked([]);
                                                setSelectedColumnObjects([]);
                                            }}>
                                            <CancelIcon fontSize="small" />
                                        </IconButton>
                                    </Tooltip>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2} sx={{ textAlign: 'center' }} justifyContent="center" alignItems="center">
                                <Grid item>
                                    <Typography variant="button" sx={{ color: '#505050' }}>
                                        Columns Available
                                    </Typography>
                                    <List
                                        sx={{
                                            width: '100%',
                                            maxWidth: 360,
                                            minWidth: 260,
                                            maxHeight: '50vh',
                                            minHeight: '50vh',
                                            overflow: 'scroll',
                                            bgcolor: 'background.paper'
                                        }}>
                                        {columnPreference.map((column: any) => {
                                            if (!props.hiddenColumns.includes(column.ColumnName) && column.AvailableOrDisplayed === "A") {
                                                const labelId = `checkbox-list-label-${column.ColumnName}`;
                                                return (
                                                    <ListItem
                                                        key={column.ColumnName}
                                                        sx={{ padding: '0px' }}
                                                        secondaryAction={
                                                            <IconButton id="comments" edge="end" aria-label="comments">
                                                            </IconButton>
                                                        }
                                                        disablePadding
                                                    >
                                                        <ListItemButton
                                                            role={undefined}
                                                            dense
                                                            onClick={() => { columnSelectHandler(column, !column.Enable); }}>
                                                            <ListItemIcon>
                                                                <Checkbox
                                                                    style={{ padding: '0px' }}
                                                                    edge="start"
                                                                    tabIndex={-1}
                                                                    checked={checked.indexOf(column.ColumnName) !== -1}
                                                                    disableRipple
                                                                    inputProps={{ 'aria-labelledby': labelId }}
                                                                />
                                                            </ListItemIcon>
                                                            {/* <ListItemText id={labelId} primary={column.DisplayName} /> */}
                                                            <ListItemText
                                                                id={labelId}
                                                                primary={
                                                                    <ColumnNameEditor
                                                                        DisplayName={column.DisplayName}
                                                                        ColumnName={column.ColumnName}
                                                                        setColumnPreferenceDisplayNameHandler={setColumnPreferenceDisplayNameHandler}>
                                                                    </ColumnNameEditor>
                                                                } disableTypography={true} />
                                                        </ListItemButton>
                                                    </ListItem>
                                                );
                                            }
                                        })}
                                    </List>

                                </Grid>
                                <Grid item>
                                    <Grid container direction="column" alignItems="center">
                                        <Button id="columnselectionbtn1"
                                            sx={{ my: 0.5 }}
                                            variant="outlined"
                                            size="small"
                                            onClick={() => {
                                                moveAllHandler(columnPreference, true);
                                                setChecked([]);
                                                setSelectedColumnObjects([]);
                                            }}
                                            disabled={!enableRightMove}
                                            aria-label="move all right"
                                        >
                                            ≫
                                        </Button>
                                        <Button id="columnselectionbtn2"
                                            sx={{ my: 0.5 }}
                                            variant="outlined"
                                            size="small"
                                            onClick={() => {
                                                setColumnPerefenceHanler(selectedColumnObjects);
                                                setChecked([]);
                                                setSelectedColumnObjects([]);
                                            }}
                                            disabled={!enableRightMove}
                                            aria-label="move selected right"
                                        >
                                            &gt;
                                        </Button>
                                        <Button id="columnselectionbtn3"
                                            sx={{ my: 0.5 }}
                                            variant="outlined"
                                            size="small"
                                            onClick={() => {
                                                setColumnPerefenceHanler(selectedColumnObjects);
                                                setChecked([]);
                                                setSelectedColumnObjects([]);
                                            }}
                                            disabled={!enableLefttMove}
                                            aria-label="move selected left"
                                        >
                                            &lt;
                                        </Button>
                                        <Button id="columnselectionbtn3"
                                            sx={{ my: 0.5 }}
                                            variant="outlined"
                                            size="small"
                                            onClick={() => {
                                                moveAllHandler(columnPreference, false);
                                                setChecked([]);
                                                setSelectedColumnObjects([]);
                                            }}
                                            disabled={!enableLefttMove}
                                            aria-label="move all left"
                                        >
                                            ≪
                                        </Button>
                                    </Grid>
                                </Grid>
                                <Grid item sx={{ textAlign: 'center' }}>
                                    <Typography variant="button" sx={{ color: '#505050' }}>
                                        Columns Displayed
                                    </Typography>
                                    <List
                                        sx={{
                                            width: '100%',
                                            maxWidth: 360,
                                            minWidth: 260,
                                            maxHeight: '50vh',
                                            minHeight: '50vh',
                                            overflow: 'scroll',
                                            bgcolor: 'background.paper'
                                        }}>
                                        {columnPreference.map((column: any) => {
                                            if (!props.hiddenColumns.includes(column.ColumnName) && column.AvailableOrDisplayed === "D") {
                                                const labelId = `checkbox-list-label-${column.ColumnName}`;
                                                return (
                                                    <ListItem
                                                        sx={{ padding: '0px' }}
                                                        key={column.ColumnName}
                                                        secondaryAction={
                                                            <IconButton id="columnselection-comments" edge="end" aria-label="comments">
                                                            </IconButton>
                                                        }
                                                        disablePadding
                                                    >
                                                        <ListItemButton
                                                            role={undefined}
                                                            dense
                                                            onClick={() => { columnSelectHandler(column, !column.Enable); }}>
                                                            <ListItemIcon>
                                                                <Checkbox
                                                                    style={{ padding: '0px' }}
                                                                    edge="start"
                                                                    tabIndex={-1}
                                                                    checked={checked.indexOf(column.ColumnName) !== -1}
                                                                    disableRipple
                                                                    inputProps={{ 'aria-labelledby': labelId }}
                                                                />
                                                            </ListItemIcon>
                                                            {/* <ListItemText id={labelId} primary={column.DisplayName} /> */}
                                                            <ListItemText
                                                                id={labelId}
                                                                primary={
                                                                    <ColumnNameEditor
                                                                        DisplayName={column.DisplayName}
                                                                        ColumnName={column.ColumnName}
                                                                        setColumnPreferenceDisplayNameHandler={setColumnPreferenceDisplayNameHandler}>
                                                                    </ColumnNameEditor>
                                                                } disableTypography={true} />
                                                        </ListItemButton>
                                                    </ListItem>
                                                );
                                            }
                                        })}
                                    </List>
                                </Grid>
                                <Grid item>
                                    <Grid container direction="column" alignItems="center">
                                        <IconButton id="moveright"
                                            size="large"
                                            onClick={() => { array_move("UP") }}
                                            //disabled={leftChecked.length === 0}
                                            aria-label="move selected right"
                                        >
                                            <ArrowCircleUpOutlinedIcon></ArrowCircleUpOutlinedIcon>
                                        </IconButton>
                                        <IconButton id="moveleft"
                                            size="medium"
                                            onClick={() => { array_move("DOWN") }}
                                            //disabled={rightChecked.length === 0}
                                            aria-label="move selected left"
                                        >
                                            <ArrowCircleDownOutlinedIcon></ArrowCircleDownOutlinedIcon>
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid container>

                                <Grid container xs={4} sx={{ padding: "10px" }} justifyContent="left">
                                    <TextField
                                        id="preference" name="preference" select label="Preferences"
                                        size="small"
                                        sx={{
                                            width: '100%',
                                            maxWidth: 300,
                                            minWidth: 260
                                        }}
                                        SelectProps={{
                                            native: true,
                                        }}
                                        value={selectedPreferenceName}
                                        onChange={e => setPreference(e.target)}>
                                        {tablePreferenceList?.map((preference: any) => (
                                            <option key={preference.PreferenceId} value={preference.PreferenceName}>
                                                {preference.PreferenceName}
                                            </option>
                                        ))}
                                    </TextField>
                                </Grid>

                                <Grid container xs={8} sx={{ padding: "10px" }} justifyContent="right">

                                    <Snackbar
                                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                        open={messageInfo.Open}
                                        autoHideDuration={2000}
                                        onClose={() => { CloseMessage() }}>
                                        <Alert severity={messageInfo.severity as AlertColor}>{messageInfo.Message}</Alert>
                                    </Snackbar>

                                    <Snackbar
                                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                        open={openInfo}
                                        autoHideDuration={2000}
                                        onClose={() => { setOpenInfo(false) }}>
                                        <Alert severity="info">Please select one column to rearrange</Alert>
                                    </Snackbar>
                                </Grid>
                            </Grid></>
                        </Box>
                    </Fade>
                </Rnd>
            )}
        </Popper>
    )
}

export default ColumnSelection;