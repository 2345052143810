import React from "react";
import Button from '@mui/material/Button';
import { IColumnList } from "../@State/types";
import { start } from "repl";

interface columnListProps {
  col: IColumnList,
  addTable: any[],
  addNewRow: () => void,
  deleteRow: (index: number) => void,
  dataTypeList: string[],
  foreignKeyList: string[],
  handleChangeOptions: (index: number, e: any) => void
}
const ColumnList = (columnListProps: columnListProps) => {
  return (
    <tr key={columnListProps.col.index}>
      <td>
        <input required type="text" name="ColumnName" value={columnListProps.col.ColumnName} data-id={columnListProps.col.index} id={columnListProps.col.ColumnName} className="form-control " />
      </td>
      <td>
        <select required onChange={e => columnListProps.handleChangeOptions(columnListProps.col.index, e)} name="DataType" id={columnListProps.col.DataType} data-id={columnListProps.col.index} className="form-control">
          <option value="">Please select </option>
          {
            columnListProps.dataTypeList.map((datatype) => <option value={datatype}>{datatype}</option>)
          }

        </select>
      </td>
      <td>
        <input type="text" name="Length" value={columnListProps.col.Length} data-id={columnListProps.col.index} id={columnListProps.col.Length} className="form-control " />
      </td>
      <td>
        <select placeholder="please select" required name="ColumnUnique" id={columnListProps.col.ColumnUnique} data-id={columnListProps.col.index} className="form-control" >
          <option value="">Please select </option>
          <option value="Identity">Identity</option>
          <option value="Yes">Yes</option>
          <option value="No">No</option>
        </select>
      </td>
      <td>
        {/* <input type="text"   name="foreignKey" id={col.ForeignKey} data-id={col.index} className="form-control"/> */}
        <select onChange={e => columnListProps.handleChangeOptions(columnListProps.col.index, e)} name="ForeignKey" id={columnListProps.col.ForeignKey} data-id={columnListProps.col.index} className="form-control">
          <option value="">Please select </option>
          {
            columnListProps.foreignKeyList.map((fks) => <option value={fks}>{fks}</option>)
          }
        </select>
      </td>
      <td>
        <select name="EncryptedYn" id={columnListProps.col.EncryptedYn} data-id={columnListProps.col.index} className="form-control" >
          <option value="">Please select </option>
          <option value="Yes">Yes</option>
          <option value="No">No</option>
        </select>
      </td>
      <td>
        <input type="text" name="Label" value={columnListProps.col.Label} data-id={columnListProps.col.index} id={columnListProps.col.Label} className="form-control " />
      </td>
      <td>
        <input type="text" name="Tooltip" value={columnListProps.col.Tooltip} data-id={columnListProps.col.index} id={columnListProps.col.Tooltip} className="form-control " />
      </td>
      <td>
        <input type="text" name="ColumnDescpn" value={columnListProps.col.ColumnDescpn} data-id={columnListProps.col.index} id={columnListProps.col.ColumnDescpn} className="form-control " />
      </td>
      {
        columnListProps.addTable.length > 1 &&
        <td style={{ marginLeft: '15px' }}>
          <Button id="deleterow" color="error" className="btn btn-danger" onClick={() => columnListProps.deleteRow(columnListProps.col.index)} style={{ marginLeft: '15px', fontSize: '20px' }} variant="contained" size="small">
            -
          </Button>
        </td>
      }
      <td style={{ marginLeft: '15px' }}>
        <Button id="addrow" onClick={columnListProps.addNewRow} style={{ marginLeft: '15px', fontSize: '20px' }} variant="contained" size="small">
          +
        </Button>
      </td>
    </tr >
  )
}
export default ColumnList