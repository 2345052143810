import CloseIcon from "@mui/icons-material/Close";
import { Alert, AlertTitle, Collapse, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import React from "react";
import { useEffectOnce } from "src/hooks/useEffectOnce";
import * as api from "src/Utility/api";
import { Itable } from "../@State/types";

function HistoryTable(props: any) {
  const [schemas, setSchemas] = React.useState<string[]>([]);
  const [tables, setTables] = React.useState<string[]>([]);
  const [tableMap, setTableMap] = React.useState<Map<string, string[]>>(
    new Map([])
  );
  const [tableModel, setTableModel] = React.useState<Itable>({
    columnList: [],
    schemaName: "",
    tableName: "",
  });
  const [openSuccessMessage, setOpenSuccessMessage] = React.useState(false);
  const [openErrorMessage, setOpenErrorMessage] = React.useState(false);
  const [message, setMessage] = React.useState();

  useEffectOnce(() => {
    loadTables();
  });

  const loadTables = () => {
    async function fetchExistingTables() {
      let getTablesUrl = "/Table/GetTables?loggedInUser=" + props.loggedInUserMailId;
      await api.API_GET(getTablesUrl).then((res) => {
        let _tableMap: Map<string, string[]> = new Map([]);
        if (res?.data.length > 0) {
          const tableData = res.data;
          tableData.forEach((element: any) => {
            const { schema, name } = element;
            if (_tableMap.has(schema)) {
              _tableMap.get(schema)!.push(name);
            } else {
              let _tables: string[] = [];
              _tables.push(name);
              _tableMap.set(schema, _tables);
            }
          });
          setTableMap(_tableMap);
          setSchemas(Array.from(_tableMap.keys()));
        }
      });
    }
    fetchExistingTables();
  };

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    if (name === "schemaName" && tableMap.get(value) !== undefined) {
      setTables(tableMap.get(value)!);
    }
    setTableModel({ ...tableModel, [name]: value });
  };

  const resetColumns = () => {
    setTableModel({
      columnList: [],
      schemaName: "",
      tableName: "",
    });
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    async function createHistoryTable() {
      let data = {
        schemaName: tableModel.schemaName,
        tableName: tableModel.tableName,
      };
      props.setShowProgress(true);
      await api
        .API_POST("/Table/CreateHistoryTable", data)
        .then((res) => {
          props.setShowProgress(false);
          e.target.reset();
          resetColumns();
          setMessage(res.data);
          setOpenSuccessMessage(true);
        })
        .catch((error) => {
          props.setShowProgress(false);
          setMessage(error.response.data);
          setOpenErrorMessage(true);
        });
    }
    createHistoryTable();
  };

  return (
    <div className="content">
      <Collapse in={openSuccessMessage}>
        <Alert severity="success">
          <CloseIcon
            fontSize="inherit"
            style={{ float: "right", cursor: "pointer" }}
            onClick={() => {
              setOpenSuccessMessage(false);
            }}
          />
          <AlertTitle style={{ width: "100%" }}>Success</AlertTitle>
          {message}
        </Alert>
      </Collapse>
      <Collapse in={openErrorMessage}>
        <Alert severity="error">
          <CloseIcon
            fontSize="inherit"
            style={{ float: "right", cursor: "pointer" }}
            onClick={() => {
              setOpenErrorMessage(false);
            }}
          />
          <AlertTitle style={{ width: "100%" }}>Error</AlertTitle>
          {message}
        </Alert>
      </Collapse>
      <form onSubmit={handleSubmit} onChange={handleInputChange}>
        <div className="row" style={{ marginTop: 20 }}>
          <div className="col-sm-12">
            <div className="card">
              <Box sx={{
                display: "flex", flexWrap: "wrap", "& > :not(style)": {
                  m: 1, width: "100%", height: 50, marginBottom: 2,
                },
              }}>
                <Paper
                  sx={{
                    p: 1,
                    display: "flex",
                    flexDirection: "row",
                    marginBottom: "15px",
                  }}>
                  <Typography
                    variant="h6" gutterBottom component="div"
                    sx={{ color: "#505050", marginLeft: "20px" }}>
                    Create History Table
                  </Typography>
                </Paper>
              </Box>
              <div className="card-body">
                <div className="row">
                  <div className="col-sm-6">
                    <div className="form-group ">
                      <label style={{ marginLeft: "20px" }} className="required">
                        Schema Name
                      </label>
                      <div>
                        <select required style={{ marginLeft: "20px" }} name="schemaName" className="form-control"
                          onChange={handleInputChange}>
                          <option value="">Please select </option>
                          {schemas.map((schema) => (
                            <option key={schema} value={schema}>
                              {schema}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group ">
                      <label style={{ marginLeft: "20px" }} className="required">
                        Table Name
                      </label>
                      <div>
                        <select required style={{ marginLeft: "20px" }} name="tableName" className="form-control"
                          onChange={handleInputChange}>
                          <option value="">Please select </option>
                          {tables.map((name) => (
                            <option key={name} value={name}>
                              {name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-footer text-center">
                <Button id="submit" type="submit" variant="contained" size="small"
                  style={{ marginLeft: "20px", marginTop: "15px" }}>
                  Submit
                </Button>
              </div>
            </div>
          </div>
        </div>
      </form >
    </div >
  )
}
export default HistoryTable;
