import * as React from 'react';

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-enterprise';

import { AppContext } from 'src/Components/Dashboard/@State/context/AppContext';
import { ExchangeOrderType } from 'src/Components/Dashboard/@State/types';
import { useMemo, useState } from 'react';

import './ReportSelectionTable.css';

interface IReportSelection {
    reportName: string,
    reportDBName: string,
    order1Checked: boolean,
    order2Checked?: boolean
}

export default function ReportSelectionTable(props: any) {
    const [reportSelectionData, setReportSelectionData] = React.useState<any[]>([]);
    const { fetchReportDisplayName } = React.useContext(AppContext) as ExchangeOrderType;
    const [columnDefs, setColumnDefs] = useState<any[]>([]);
    const [rowData, setRowData] = useState();

    const reportsGridRef: any = React.useRef();

    React.useEffect(() => {
        constructColumnDefinition();
    }, [reportSelectionData]);

    const constructColumnDefinition = () => {
        let _colDef:any[] = [];
        _colDef.push({
            field: 'reportName',
            headerName: 'Datasets (selected)',
            width:150
        },
        {
            headerName: "Order #" + props.order1Info.OrderId + " (" + props.order1Info.OrderBy + ")",
            headerClass: props.isOppositeSideOrder ? "headerOrderFrom":"",
            field: "order1Checked",
            cellStyle: { textAlign: 'center' },
            cellRenderer: (params: any) => {
                const shouldDisplay = fetchMatchingReportObj(props.orderReportSelectionOrder1, params.data.reportDBName);
                return <input
                    type='checkbox'
                    checked={params.data.order1Checked}
                    disabled={(shouldDisplay && shouldDisplay.length > 0) ? false : true}
                    onClick={() => { handleCheckUncheckDataset(props.orderReportSelectionOrder1, props.setColumnPreferenceHandler, params.data.reportDBName) }} />;
            },
            width:120
        });

        if(props.isOppositeSideOrder){
            _colDef.push({
                headerName: "Order #" + props.order2Info.OrderId + " (" + props.order1Info.OrderTo + ")",
                headerClass:"headerOrderTo",
                field: "order2Checked",
                cellStyle: { textAlign: 'center' },
                cellRenderer: (params: any) => {
                    const shouldDisplay = fetchMatchingReportObj(props.orderReportSelectionOrder2, params.data.reportDBName);
                    return <input
                        type='checkbox'
                        checked={params.data.order2Checked}
                        disabled={(shouldDisplay && shouldDisplay.length > 0) ? false : true}
                        onClick={() => { handleCheckUncheckDataset(props.orderReportSelectionOrder2, props.setColumnPreferenceHandlerOrder2, params.data.reportDBName) }} />;
                },
                width:120
            });
        }

        setColumnDefs(_colDef);
    }

    const defaultColDef = useMemo(() => {
        return {
            resizable: true,
            wrapHeaderText: true,
            autoHeaderHeight: true,
            editable: false,
            headerCheckboxSelectionFilteredOnly: true,
            enableCellChangeFlash: true
        };
    }, []);

    const gridOptions = {
        // PROPERTIES
        defaultColDef: defaultColDef,
        pagination: false,
        defaultExportParams: {
            columnGroups: true,
            fileName: 'name_of_file'
        }
    }


    React.useEffect(() => {
        if (!props.isOppositeSideOrder
            && props.orderReportSelectionOrder1.length > 0) {
            let _reports: IReportSelection[] = [];
            const reports1 = props.orderReportSelectionOrder1.map((ele: any) => { return ele.ReportName });
            reports1.forEach((rep: any) => {
                if (_reports.indexOf(rep) == -1) {
                    _reports.push({
                        reportName: fetchReportDisplayName(rep),
                        reportDBName: rep,
                        order1Checked: getIsEnable(props.orderReportSelectionOrder1, rep)
                    });
                }
            });
            setReportSelectionData(_reports);
        } else if (props.isOppositeSideOrder
            && props.orderReportSelectionOrder1.length > 0
            && props.orderReportSelectionOrder2.length > 0) {
            let reportsCombined: IReportSelection[] = [];
            const reports1 = props.orderReportSelectionOrder1.map((ele: any) => { return ele.ReportName });
            const reports2 = props.orderReportSelectionOrder2.map((ele: any) => { return ele.ReportName });
            reports1.forEach((rep: any) => {
                if (reportsCombined.indexOf(rep) == -1) {
                    reportsCombined.push({
                        reportName: fetchReportDisplayName(rep),
                        reportDBName: rep,
                        order1Checked: getIsEnable(props.orderReportSelectionOrder1, rep),
                        order2Checked: getIsEnable(props.orderReportSelectionOrder2, rep)
                    });
                }
            });
            reports2.forEach((rep: any) => {
                if (reportsCombined.indexOf(rep) == -1) {
                    reportsCombined.push({
                        reportName: fetchReportDisplayName(rep),
                        reportDBName: rep,
                        order1Checked: getIsEnable(props.orderReportSelectionOrder1, rep),
                        order2Checked: getIsEnable(props.orderReportSelectionOrder2, rep)
                    });
                }
            });

            setReportSelectionData(reportsCombined);
        }
    }, [props.orderReportSelectionOrder1, props.orderReportSelectionOrder2]);

    const getIsEnable = (orderReportSelection: any[], reportName: any) => {
        let isEnable = false;
        const _orderReportSelection = [...orderReportSelection];
        const matchObj = _orderReportSelection.filter((ele: any) => {
            return ele.ReportName == reportName;
        });
        if (matchObj.length > 0) {
            isEnable = matchObj[0].Enable;
        }
        return isEnable;
    }

    const fetchMatchingReportObj = (orderReportSelection: any[], reportName: any) => {
        const _orderReportSelection = [...orderReportSelection];
        const matchObj = _orderReportSelection.filter((ele: any, index: number) => {
            return ele.ReportName == reportName;
        });
        return matchObj;
    }

    const handleCheckUncheckDataset = (orderReportSelection: any[], setColPrefHandler: any, reportName: any) => {
        const matchObj = fetchMatchingReportObj(orderReportSelection,reportName);
        if (matchObj.length > 0) {
            setColPrefHandler(matchObj[0], !matchObj[0].Enable);
        }
    }

    return (
        <div style={{ height: '100%', width: '400px' }} className="ag-theme-balham">
            {columnDefs.length > 0 && <AgGridReact
                rowSelection={'multiple'}
                columnDefs={columnDefs}
                ref={reportsGridRef}
                gridOptions={gridOptions}
                rowData={reportSelectionData}
                overlayNoRowsTemplate={'<span style="padding: 10px; border: 2px solid #444; background: lightgoldenrodyellow;">No reports found.</span>'}>
            </AgGridReact>}
        </div>
    );
}