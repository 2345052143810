import React from "react";
import { AppContext } from "../../@State/context/AppContext";
import { ExchangeOrderType } from "../../@State/types";
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import { Box, Button, ButtonGroup, DialogActions, DialogTitle, IconButton, Popper, PopperPlacementType } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import NodeInfo from "../TableView/NodeInfo";
import * as api from 'src/Utility/api';
import UnfoldLessOutlinedIcon from '@mui/icons-material/UnfoldLessOutlined';
import UnfoldMoreOutlinedIcon from '@mui/icons-material/UnfoldMoreOutlined';
import CancelIcon from '@mui/icons-material/Cancel';
import { Rnd } from "react-rnd";

function ReportNameRenderer(props: any) {
  const { fetchReportDisplayName } = React.useContext(AppContext) as ExchangeOrderType;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [placement] = React.useState<PopperPlacementType>('top-start');
  const [open, setOpen] = React.useState(false);
  const [currentMapNodeData, setCurrentMapNodeData] = React.useState<any>(null);
  const [expandAll,setExpandAll] = React.useState<boolean>(true);
  const [expandNodeInfo,setExpandNodeInfo] = React.useState<any>(null);
  const [qualityRatings, setQualityRatings] = React.useState<any[]>([]);


  const handleClickOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
    const auditData = props.DataAuditData.find((element: any) => element.API.includes(props.data.API) && element.WellName === props.data.WellName );
    if(props.data) {
        let expandInfo = { 'L1' : props.data.L1, 'L2' : props.data.L2,'L3' : props.data.L3,'L4' : props.data.L4,'L5' : props.data.L5,'L6' : props.data.L6  };
        setExpandNodeInfo(expandInfo);
    }

    setCurrentMapNodeData(auditData);
    getQualityRating(auditData)
  };

  const getQualityRating = async (auditData : any) => {
    if (auditData !== null) {
        await api.API_GET('/Report/GetReportRatingByAPI?api=' + auditData.API)
            .then((response) => {
                if (response.data.ReportRatings.length > 0) {
                    setQualityRatings(response.data.ReportRatings);
                }
            })
            .catch((ex: any) => {
                console.log(ex)
            });
    }
}

const clickAwayHandler = () => {
  setExpandNodeInfo({});
  setOpen(false);
};

  return (
    <>
      {props.value && (fetchReportDisplayName(props.value))}
      <p style={{ margin: "0px", float: 'right', display: props.ShowInfo ? 'flex' : 'none' }}  >
                    <IconButton id="data" color="primary" size="small"
                        onClick={handleClickOpen} aria-label="data">
                        <InfoIcon fontSize="small" />
                    </IconButton>
                </p>
        <Popper open={open} placement={placement} anchorEl={anchorEl} style={{ zIndex: '10000' }}>
          <Rnd>
            <Box sx={{ border: '2px solid #bfbfbf', p: 1, bgcolor: 'background.paper',  minWidth : '400px' }}>
                              <div style={{ width: '100%' }}>
                                    <DialogActions  style={{ margin: 2, float: 'right' }}>
                                          <IconButton id="done" sx={{float:'right'}} color="success" aria-label="done" onClick={() => { setExpandNodeInfo(null); setExpandAll(!expandAll); }}>
                                            {expandAll === false && <UnfoldMoreOutlinedIcon />}
                                            {expandAll === true && <UnfoldLessOutlinedIcon />}
                                        </IconButton>
                                        <IconButton id="cancel" color="error" aria-label="cancel" onClick={clickAwayHandler}>
                                            <CancelIcon />
                                        </IconButton>
                                    </DialogActions>
                              </div>
                              <Box sx={{ border: '1px solid #bfbfbf', p: 1, bgcolor: 'background.paper' }} style={{ width: '100%',maxHeight:'600px',overflow:'scroll' }} className="ag-theme-material">

                                  <NodeInfo
                                      qualityRatings={qualityRatings}
                                      reportGroups={props.ReportConfig}
                                      currentMapNodeData={currentMapNodeData}
                                      defaultExpandedInfo = {expandNodeInfo}
                                      expandAll={expandAll}></NodeInfo>
                              </Box>
            
            </Box>
          </Rnd>
      </Popper>
    </>
  )
}

export default ReportNameRenderer;