import { AgGridReact } from 'ag-grid-react';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';

import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-material.css';
import 'ag-grid-enterprise';
import { LicenseManager, SideBarDef } from "ag-grid-enterprise";

import AddCircleIcon from '@mui/icons-material/AddCircle';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import HistoryToggleOffIcon from '@mui/icons-material/HistoryToggleOff';
import SaveIcon from '@mui/icons-material/Save';
import SystemUpdateAltIcon from '@mui/icons-material/SystemUpdateAlt';
import ViewColumnOutlinedIcon from '@mui/icons-material/ViewColumnOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import {
  Alert, AlertTitle, Avatar,
  Box, Chip, Collapse, Grid, IconButton, Paper,
  Skeleton, Snackbar, Tooltip, Typography
} from '@mui/material';
import { Any } from '@react-spring/types';
import ConfirmDialog from 'src/Common/ConfirmDialog';
import { useEffectOnce } from 'src/hooks/useEffectOnce';
import * as api from 'src/Utility/api';
import { AppContext } from '../../@State/context/AppContext';
import ExplorerProvider from '../../@State/context/ExplorerContext';
import { TableContext } from "../../@State/context/TableContext";
import { ExchangeOrderType, ICartInfo, IColumnLabelData, IDropDownSelectList, IExplorerTableInfo, ISecurityPermissions, TableType } from "../../@State/types";
import DateTimePickerEditor from '../CellEditors/DateTimePickerEditor';
import DropDownAgGridRenderer from '../CellEditors/DropDownAgGridEditor';
import DropDownCellRenderer from '../CellEditors/DropDownCellEditor';
import ApproveDisapproveRenderer from '../CellRenderers/ApproveDisapproveRenderer';
import DateTimeRenderer from '../CellRenderers/DateTimeRenderer';
import DropDownAgGridCustomColumnRenderer from '../CellRenderers/DropDownAgGridCustomColumnRenderer';
import EnableDisableRenderer from '../CellRenderers/EnableDisableRenderer';
import FileUploadRenderer from '../CellRenderers/FileUploadRenderer';
import HistoryViewRenderer from '../CellRenderers/HistoryViewRenderer';
import OperatorSelectorRenderer from '../CellRenderers/OperatorSelectorRenderer';
import QualityRatingRenderer from '../CellRenderers/QualityRatingRenderer';
import TableDataRenderer from '../CellRenderers/TableDataRenderer';
import ColumnSelection from '../ColumnSelection/ColumnSelection';
import CountySelection from '../CountySelection/CountySelection';
import ExcelUploadMenu from '../ExcelUpload/ExcelUpload';
import Explorer from '../Explorer/Explorer';
import { constructColumnGrouping } from './columnGrouping';
import { checkDataAuditOperators } from './DataValidations';
import './TableView.css';
import { columnHeaderTemplate } from 'src/Utility/common';
import { CSVLink } from "react-csv";

interface ITableViewProps {
  selectedTableInfo: IExplorerTableInfo;
  loggedInUser: string;
  loggedInUserMailId: string;
  userSecurityPermission: any;
  tabIndex: number;
  cartInformation?: ICartInfo[];
  IsRefreshClicked: any;
  setShowProgress: (show: boolean) => void;
  setCartInformation: React.Dispatch<React.SetStateAction<ICartInfo[]>>;
  setIsRefreshClicked: (show: boolean) => void;
}

interface ISaveAndUpdateInfo {
  insertErrors?: string,
  insertRows: number,
  updateErros?: string,
  updatedRows: number
}

function TableView(props: ITableViewProps) {
  LicenseManager.setLicenseKey("CompanyName=Zeus Solutions Inc,LicensedApplication=Oasis Data Exchange,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=0,AssetReference=AG-038725,SupportServicesEnd=29_February_2024_[v2]_MTcwOTE2NDgwMDAwMA==1651fd0655b06ef24f0110ac943c1e32");
  const gridRef: any = useRef();
  const currentTableRef: any = useRef("");
  const currentSchemaRef: any = useRef("");
  const {
    tableInfo,
    pickListDefinitions,
    fileUploadColumns,
    approvalConfig,
    approvalStatus,
    operators,
    reportGroups,
    dropDownSelectListCollection,
    reportConfigurations,
    loadInitialPrerequisiteData,
    saveDropDownSelectList,
    saveTableInfo,
    clearTableInfo } = React.useContext(TableContext) as TableType;

  const {
    exchangeOrder,
    modifiedOrder,
    fetchReportDisplayName } = React.useContext(AppContext) as ExchangeOrderType;

  const [columns, setColumns] = React.useState<any[]>([]);
  const [columnLabelMap, setColumnLabelMap] = React.useState<Map<string, IColumnLabelData>>(
    new Map([])
  );
  const [tableData, setTableData] = React.useState<any[]>([]);
  const [tableDataMonitor, setTableDataMonitor] = React.useState<any[]>([]);
  const [openSuccessMessage, setOpenSuccessMessage] = React.useState({
    success: false,
    message: "Data saved successfully",
    isSaveData: true
  });
  const [openErrorMessage, setOpenErrorMessage] = React.useState(false);
  const [historyView, setHistoryView] = React.useState(false);
  const [openInformationMessage, setOpenInformationMessage] = React.useState(false);
  const [informationMessage, setInformationMessage] = React.useState("");
  const [openConfirmDialog, setOpenConfirmDialog] = React.useState(false);
  const [errorList, setErrorList] = React.useState<string[]>([]);
  const [saveAndUpdateInfo, setSaveAndUpdateInfo] = React.useState<ISaveAndUpdateInfo>({
    insertRows: 0,
    updatedRows: 0,
    insertErrors: "",
    updateErros: ""
  });
  const [securityPermissions, setSecurityPermissions] = React.useState<ISecurityPermissions>({
    isAddAllowed: false,
    isEditAllowed: false,
    isDeleteAllowed: false,
    isViewAll: true,
    isRowLevelSecurity: false,
    isLoaded: false,
    operators: ""
  });

  const [displayColumns, setDisplayColumns] = React.useState<string[]>([]);
  const [tablePreferenceList, setTablePreferenceList] = React.useState<any[]>([]);
  const [selectedPreference, setselectedPreference] = React.useState<any>();
  const [columnPreference, setColumnPreference] = React.useState<any[]>([]);
  const [hiddenColumns, setHiddenColumns] = React.useState<string[]>(["Approver1"
    , "Comment1"
    , "ApprovedDate1"
    , "ApprovalStatus1"
    , "Approver2"
    , "Comment2"
    , "ApprovedDate2"
    , "ApprovalStatus2"
    , "Approver3"
    , "Comment3"
    , "ApprovedDate3"
    , "ApprovalStatus3"]);

  const [columnsPerfOpen, setcolumnsPerfOpen] = React.useState(false);
  const [columnDefinitions, setcolumnDefinitions] = React.useState<any[]>([]);

  const handleClickPerfOpen = (event: React.MouseEvent<HTMLElement>) => {
    //setInitialColumnPreferenceStateByColumns(columns);
    setAnchorEl(event.currentTarget);
    setcolumnsPerfOpen(!columnsPerfOpen);
  };

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const canBeOpen = columnsPerfOpen && Boolean(anchorEl);
  const id = canBeOpen ? 'transition-popper' : undefined;

  const [mapViewRelatedColumns, setMapViewRelatedColumns] = React.useState<string[]>(["Latitude", "Longitude"]);
  const [enableMapView, setEnableMapView] = React.useState(false);

  let mapLocations: any[] = [];
  let countClick = 0;

  const [locs, setLocs] = React.useState<any>([]);
  const [map, setMap] = React.useState<any>(null);
  const [replaceDeleteIds, setReplaceDeleteIds] = React.useState<any[]>([]);
  const [filtersApplied, setFiltersApplied] = React.useState<any[]>([]);
  const [filtersAppliedReports, setFiltersAppliedReports] = React.useState<any[]>([]);

  const [open, setOpen] = React.useState(true);
  const [explorerWidth, setExplorerWidth] = React.useState<number>(0);
  const [tableViewWidth, setTableViewWidth] = React.useState<number>(12);
  const [selectedTable, setSelectedTable] = React.useState<IExplorerTableInfo>({
    name: "",
    schema: ""
  });
  const [isUserGlobalAdmin, setIsUserGlobalAdmin] = React.useState(false);

  const [isLogTable, setIsLogTable] = React.useState(false);

  let selectedPref: any[] = [];
  const handleCollapseExplorer = () => {
    setOpen(false);
    setExplorerWidth(0);
    setTableViewWidth(12);
  }

  const handleExpandExplorer = () => {
    setOpen(true);
    setExplorerWidth(2);
    setTableViewWidth(10);
  }

  const sideBar: SideBarDef = useMemo(() => {
    return {
      toolPanels: [
        {
          id: 'filters',
          labelDefault: 'Filters',
          labelKey: 'filters',
          iconKey: 'filter',
          toolPanel: 'agFiltersToolPanel',
          minWidth: 270,
          maxWidth: 270,
          width: 270,
        },
      ],
      position: 'right'
    };
  }, []);

  React.useEffect(() => {
    if (map) {
      mapFitBounds();
    }
  }, [map]);

  function mapFitBounds() {
    if (!map) return;

    const bounds = new google.maps.LatLngBounds();
    locs.forEach((loc: any) => {
      bounds.extend(new google.maps.LatLng(loc.lat, loc.lng));
    });

    if (locs.length > 0) {
      map.fitBounds(bounds);
    }
  }

  useEffectOnce(() => {
    loadInitialPrerequisiteData();
  });

  useEffect(() => {
    selectedPref = [];
    setcolumnsPerfOpen(false);
    //setselectedPreference(null);
    if (securityPermissions.isLoaded === true
      && (selectedTable.schema != currentSchemaRef.current
        || selectedTable.name != currentTableRef.current)) {
      loadColumnPreferences();
      currentSchemaRef.current = selectedTable.schema;
      currentTableRef.current = selectedTable.name;

    }
  }, [selectedTable, approvalConfig, securityPermissions]);

  useEffect(() => {
    if (selectedPreference != null) {
      setColumnPreference(selectedPreference.PreferenceJson);
    }
    //  else {
    //      setInitialColumnPreferenceState();
    //  }

  }, [selectedPreference]);

  useEffect(() => {
    handleGridCoulmnConstruction(columns, tableData, selectedPreference?.PreferenceJson);
    setSecurityPermissions({ ...securityPermissions, isLoaded: false });
    setCheckBoxSelectable();
    hideAllFilter();
  }, [tableData]);

  useEffect(() => {
    setUserSecurityPermissions();
  }, [props.userSecurityPermission, selectedTable]);

  useEffect(() => {
    loadInitialPrerequisiteData();
    clearTableInfo();
    loadTableData(true, false, selectedPref);
  }, [props.IsRefreshClicked]);

  const setUserSecurityPermissions = () => {
    if (props.userSecurityPermission != null
      && selectedTable.schema != ''
      && selectedTable.name != '') {
      const isGlobalAdmin = props.userSecurityPermission
        .filter((permission: any) => {
          return (permission.SchemaName === null
            && permission.TableName === null
            && permission.UserMailId.toLowerCase() === props.loggedInUserMailId.toLowerCase())
        });
      const currentTableSecurityPermissions = props.userSecurityPermission
        .filter((permission: any) => {
          return (permission.SchemaName === selectedTable.schema
            && permission.TableName === selectedTable.name
            && permission.UserMailId === props.loggedInUserMailId)
        });

      if (isGlobalAdmin.length > 0) {
        setSecurityPermissions({
          isAddAllowed: true,
          isEditAllowed: true,
          isDeleteAllowed: true,
          isRowLevelSecurity: false,
          isViewAll: true,
          isLoaded: true,
          operators: isGlobalAdmin.Operators
        });

        setIsUserGlobalAdmin(true);
      }
      else if (currentTableSecurityPermissions.length > 0) {
        currentTableSecurityPermissions.forEach((permission: any) => {
          setSecurityPermissions({
            isAddAllowed: permission.Add_YN === 'Y' ? true : false,
            isEditAllowed: permission.Update_YN === 'Y' ? true : false,
            isDeleteAllowed: permission.Delete_YN === 'Y' ? true : false,
            isRowLevelSecurity: permission.RowLevel_Security_YN === 'Y' ? true : false,
            isViewAll: permission.View_All_YN === 'Y' ? true : false,
            isLoaded: true,
            operators: permission.Operators
          });
        });
      } else {
        setSecurityPermissions({
          isAddAllowed: false,
          isEditAllowed: false,
          isDeleteAllowed: false,
          isRowLevelSecurity: false,
          isViewAll: true,
          isLoaded: true,
          operators: ""
        });
      }
    }
  }

  const loadTableData = (skipCache?: boolean, loadHistory?: boolean, colPreference?: any) => {
    async function fetchTableData() {
      if (selectedTable.name !== "") {
        const _tableInfo = tableInfo.filter((element) => {
          return element.schema === selectedTable.schema &&
            element.tableName === selectedTable.name
        });

        if (_tableInfo.length === 0 || skipCache === true) {
          props.setShowProgress(true);
          gridRef.current.api.showLoadingOverlay();

          const operatorList = sessionStorage.getItem("Operators");

          let getTableDataUrl = '/Table/GetTablesData?schema='
            + selectedTable.schema
            + '&table=' + selectedTable.name;

          if (operatorList && (selectedTable.name != 'SECURITY_GROUP' && selectedTable.name != 'COMMON_KEYVALUE_CONFIG')) {
            getTableDataUrl += '&filterValues=' + operatorList;
          }

          if (loadHistory === true) {
            getTableDataUrl += '&historyView=true';
          }
          if (!securityPermissions.isViewAll) {
            getTableDataUrl += '&loggedInUserName=' + props.loggedInUser;
          }        

          if (selectedTable.name === "UserLogs") {
            getTableDataUrl = '/Table/GetLogsData';
          }

          await api.API_GET(getTableDataUrl).then((response) => {
            if (response?.data?.Columns.length > 0) {
              if (selectedTable.schema == currentSchemaRef.current
                && selectedTable.name == currentTableRef.current) {
                let columns: any[] = [];
                columns = response.data.Columns;

                addApprovalColumnsHandler(approvalConfig);

                setColumns(columns);
                setDisplayColumns(columns.map((ele) => { return ele.ColumnName; }));
                setTableData(response?.data?.TableData);
                selectedPref = colPreference?.length > 0 ? colPreference : setInitialColumnPreferenceStateByColumns(columns);
                handleGridCoulmnConstruction(columns, response?.data?.TableData, selectedPref);

                setTableDataMonitor(response?.data?.TableData);

                if (response?.data?.TableData?.length >= 0) {
                  saveTableInfo({
                    schema: selectedTable.schema,
                    tableName: selectedTable.name,
                    data: response?.data
                  });
                }
              } else {
                gridRef.current?.api?.hideOverlay();
              }
            } else {
              gridRef.current?.api?.setColumnDefs([]);
              gridRef.current?.api?.setRowData([]);
            }
            props.setShowProgress(false);
          }).catch(error => {
            props.setShowProgress(false);
            gridRef.current?.api?.setColumnDefs([]);
            gridRef.current?.api?.setRowData([]);
            console.log("error in post call", error)
          }).finally(() => {
            props.setShowProgress(false);
          });
        } else {
          let columns: any[] = [];
          columns = _tableInfo[0].data.Columns;

          addApprovalColumnsHandler(approvalConfig);

          setColumns(columns);
          setDisplayColumns(columns.map((ele) => { return ele.ColumnName; }));
          setTableData(_tableInfo[0].data?.TableData);
          selectedPref = colPreference.length > 0 ? colPreference : setInitialColumnPreferenceStateByColumns(columns);
          handleGridCoulmnConstruction(columns, _tableInfo[0].data?.TableData, selectedPref);
          setTableDataMonitor(_tableInfo[0].data?.TableData);
        }
      }
    };

    fetchTableData();
    loadColumnLabels();
  }

  const loadColumnPreferences = () => {
    async function loadColumnPreferencesData() {

      if (selectedTable.name !== "" && selectedTable.schema !== "") {
        let preference: any = [];

        let getDataUrl = '/Common/GetColumnPreferencesByTable?schema='
          + selectedTable.schema
          + '&table=' + selectedTable.name;

        await api.API_GET(getDataUrl).then((response) => {
          if (response?.data?.length > 0) {
            let globalPreference = response?.data.filter((ele: any) => { return ele.PreferenceName.includes('global') });
            let defaultPreference = response?.data.filter((ele: any) => { return ele.UserMailId == props.loggedInUserMailId && ele.IsDefault });
            let preferences = response?.data.filter((ele: any) => {
              return ((ele.UserMailId === props.loggedInUserMailId) ||
                (ele.UserMailId !== props.loggedInUserMailId && ele.IsPublic))
            });
            setTablePreferenceList(preferences);

            if (defaultPreference.length > 0) {
              setselectedPreference(defaultPreference[0]);
              preference = defaultPreference[0].PreferenceJson;
            }
            else if (globalPreference.length > 0) {
              setselectedPreference(globalPreference[0]);
              preference = globalPreference[0].PreferenceJson;
            }
          }
          else {
            setTablePreferenceList([]);
            setselectedPreference(null);
          }

          selectedPref = preference;
          loadTableData(false, false, preference);
        }).catch(error => {
          console.log("error in post call", error)
          loadTableData(false, false, []);
        }).finally(() => {
        });
      }
    };

    loadColumnPreferencesData();
  }

  const loadColumnLabels = () => {
    async function getColumnLabels() {
      let getTableDataUrl = '/Table/GetTablesData?schema=dbo&table=COLUMN_LABELS';
      let _columnLabelMap: Map<string, IColumnLabelData> = new Map([]);
      await api.API_GET(getTableDataUrl).then((response) => {
        if (response?.data?.Columns.length > 0) {
          let data: any[] = [];
          data = response.data.TableData;
          data.forEach((col, idx) => {
            let _tableName = historyView ? selectedTable.name + '_H' : selectedTable.name;
            if (col.SchemaName === selectedTable.schema
              && col.TableName === _tableName) {
              _columnLabelMap.set(col.ColumnName, {
                columnName: col.ColumnName,
                columnLabel: col.ColumnLabel,
                columnDescription: col.ColumnDescription
              });
            }
          });
        }
        setColumnLabelMap(_columnLabelMap);
      }).finally(() => { });
    }
    getColumnLabels();
  }

  const getColumnLabel = (columnName: string) => {
    return columnLabelMap.get(columnName);
  }

  const fetchDisplayName = (columnName: string) => {
    let _displayName = columnName;
    let matchingPreferance = [];
    matchingPreferance = columnPreference.filter((element) => { return element.ColumnName == columnName });

    if (matchingPreferance.length > 0) {
      _displayName = matchingPreferance[0].DisplayName;
    }

    return _displayName;
  }


  const handleGridCoulmnConstruction = (columns: any[], tableData: any, _colPreference: any[]) => {
    let columnDefinition: any[] = [];
    columns.forEach((column, index) => {
      let columnLabelData = getColumnLabel(column.ColumnName);
      let colHeader = column.ColumnName;
      let colTooltip = '';
      if (columnLabelData !== undefined) {
        //colHeader = columnLabelData.columnLabel.toUpperCase();
        //colTooltip = columnLabelData.columnDescription.toUpperCase();
      }
      const pickListColumnDefinition = handlePickListConstruction(column.ColumnName, _colPreference);
      if (pickListColumnDefinition === undefined || pickListColumnDefinition === false) {
        if (!hiddenColumns.includes(column.ColumnName)) {
          if (column.ColumnName === "ApprovalStatus1" || column.ColumnName === "ApprovalStatus2" || column.ColumnName === "ApprovalStatus3") {
            columnDefinition.push({
              field: column.ColumnName,
              type: 'editableColumn',
              headerName: fetchDisplayName(colHeader),
              headerComponentParams: columnHeaderTemplate(fetchDisplayName(colHeader)),
              width: (colHeader.length * 17),
              headerTooltip: colTooltip,
              sortable: true,
              filter: (column.DataType !== "Int32" && column.DataType !== "Int64") ? 'agMultiColumnFilter' : 'agNumberColumnFilter',
              //editable: true,
              cellRenderer: ApproveDisapproveRenderer,
              cellRendererParams: {
                schemaName: selectedTable.schema,
                tableName: selectedTable.name,
                approvalLevel: column.ColumnName.replace(/^\D+/g, ''),
                approvalConfig: approvalConfig,
                approvalStatus: approvalStatus,
                tablePrimaryKey: selectedTable.pkColumnName,
                loggedInUser: props.loggedInUser,
                openSuccess: setOpenSuccessMessage,
                reloadData: loadTableData
              },
              cellClass: ['ag-cell-custom'],
              hide: isColumnHidden(column.ColumnName, _colPreference),
              suppressToolPanel: false
            });
          }
          else if (column.ColumnName.toLowerCase().replace("_", "") === "activeyn" || column.ColumnName.toLowerCase().includes('_yn')) {
            columnDefinition.push({
              field: column.ColumnName,
              headerName: fetchDisplayName(colHeader),
              headerComponentParams: columnHeaderTemplate(fetchDisplayName(colHeader)),
              width: (colHeader.length * 17),
              headerTooltip: colTooltip,
              sortable: true,
              filter: (column.DataType !== "Int32" && column.DataType !== "Int64") ? 'agMultiColumnFilter' : 'agNumberColumnFilter',
              //editable: true,
              cellRenderer: EnableDisableRenderer,
              cellRendererParams: {
                setTableDataMonitor: setTableDataMonitor,
                tableDataMonitor: tableDataMonitor
              },
              cellClass: ['ag-cell-custom'],
              hide: isColumnHidden(column.ColumnName, _colPreference),
              suppressToolPanel: false
            });
          }
          else if (column.ColumnName.toLowerCase().includes('rating')) {
            columnDefinition.push({
              field: column.ColumnName,
              type: 'editableColumn',
              headerName: fetchDisplayName(colHeader),
              headerComponentParams: columnHeaderTemplate(fetchDisplayName(colHeader)),
              width: (colHeader.length * 17),
              headerTooltip: colTooltip,
              sortable: true,
              filter: 'agNumberColumnFilter',
              //editable: true,
              cellRenderer: QualityRatingRenderer,
              cellRendererParams: {
                isReadonly: false,
                onRatingChange: (value: Any, rowIndex: any, rowData: any) => {
                  let _tableData = [...tableDataMonitor];
                  rowData[column.ColumnName] = value;
                  _tableData[rowIndex] = rowData;
                  _tableData = applyModifiedAttr(_tableData, rowIndex, column.ColumnName);
                  setTableData(_tableData);
                  setTableDataMonitor(_tableData);
                }
              },
              cellClass: ['ag-cell-custom'],
              hide: isColumnHidden(column.ColumnName, _colPreference),
              suppressToolPanel: false
            });
          }
          else if (column.DataType === "DateTime") {
            columnDefinition.push({
              field: column.ColumnName,
              type: 'editableColumn',
              headerName: fetchDisplayName(colHeader),
              headerComponentParams: columnHeaderTemplate(fetchDisplayName(colHeader)),
              width: (colHeader.length * 17),
              headerTooltip: colTooltip,
              sortable: true,
              filter: (column.DataType !== "Int32" && column.DataType !== "Int64") ? 'agMultiColumnFilter' : 'agNumberColumnFilter',
              //editable: true,
              cellEditorPopup: true,
              cellEditor: DateTimePickerEditor,
              cellEditorParams: {
                onDateTimeValueSelected: (value: Any, rowIndex: any, rowData: any) => {
                  let _tableData = [...tableDataMonitor];
                  rowData[column.ColumnName] = value;
                  _tableData[rowIndex] = rowData;
                  _tableData = applyModifiedAttr(_tableData, rowIndex, column.ColumnName);
                  setTableData(_tableData);
                  setTableDataMonitor(_tableData);
                }
              },
              cellRenderer: DateTimeRenderer,
              cellRendererParams: {
                showTime: true
              },
              cellClass: ['ag-cell-custom'],
              hide: isColumnHidden(column.ColumnName, _colPreference),
              suppressToolPanel: false
            });
          }
          else if ((selectedTable.schema.toLowerCase() === "orders"
            && (selectedTable.name.toLowerCase() === "order_management"
              || selectedTable.name.toLowerCase() === "counter_order"))
            && column.ColumnName.toLowerCase() === "data") {
            columnDefinition.push({
              field: column.ColumnName,
              type: 'editableColumn',
              headerName: fetchDisplayName(colHeader),
              headerComponentParams: columnHeaderTemplate(fetchDisplayName(colHeader)),
              width: (colHeader.length * 17),
              headerTooltip: colTooltip,
              cellRenderer: TableDataRenderer,
              cellClass: ['ag-cell-custom'],
              hide: isColumnHidden(column.ColumnName, _colPreference),
              suppressToolPanel: false
            });
          }
          else if (column.ColumnName.toLowerCase() === "operators") {
            columnDefinition.push({
              field: column.ColumnName,
              type: 'editableColumn',
              headerName: fetchDisplayName(colHeader),
              headerComponentParams: columnHeaderTemplate(fetchDisplayName(colHeader)),
              width: (colHeader.length * 17),
              headerTooltip: colTooltip,
              cellRenderer: OperatorSelectorRenderer,
              cellRendererParams: {
                operatorData: operators,
                onColumnSelected: (value: any, rowIndex: any, rowData: any) => {
                  let _tableData = [...tableDataMonitor];
                  rowData[column.ColumnName] = value;
                  _tableData[rowIndex] = rowData;
                  _tableData = applyModifiedAttr(_tableData, rowIndex, column.ColumnName);
                  setTableData(_tableData);
                  setTableDataMonitor(_tableData);
                }
              },
              cellClass: ['ag-cell-custom'],
              hide: isColumnHidden(column.ColumnName, _colPreference),
              suppressToolPanel: false
            });
          }
          else {
            if (column.ColumnName && selectedTable.pkColumnName
              && column.ColumnName === selectedTable.pkColumnName && !historyView) {
              columnDefinition.push({
                field: column.ColumnName,
                type: 'editable',
                headerName: fetchDisplayName(colHeader),
                headerComponentParams: columnHeaderTemplate(fetchDisplayName(colHeader)),
                headerTooltip: colTooltip,
                width: (colHeader.length * 20),
                sortable: false,
                cellRenderer: HistoryViewRenderer,
                cellRendererParams: {
                  IsSummary: false,
                  schemaName: selectedTable.schema,
                  tableName: selectedTable.name,
                  tablePrimaryKey: selectedTable.pkColumnName,
                  loggedInUser: props.loggedInUser,
                  setShowProgress: props.setShowProgress
                },
                hide: isColumnHidden(column.ColumnName, _colPreference),
                suppressToolPanel: false
              });
            } else {
              columnDefinition.push({
                field: column.ColumnName,
                type: 'editableColumn',
                headerName: fetchDisplayName(colHeader),
                headerComponentParams: columnHeaderTemplate(fetchDisplayName(colHeader)),
                headerTooltip: colTooltip,
                sortable: true,
                filter: 'agMultiColumnFilter',
                cellClass: ['ag-cell-custom'],
                hide: isColumnHidden(column.ColumnName, _colPreference),
                suppressToolPanel: false,
                sort: (selectedTable.name.toLowerCase() === "order_management"
                  && column.ColumnName === "TradeGroupId" && !historyView) ? 'asc' : 'none',
              });
            }
          }
        } else {
          columnDefinition.push({
            field: column.ColumnName,
            type: 'editableColumn',
            headerName: fetchDisplayName(colHeader),
            headerComponentParams: columnHeaderTemplate(fetchDisplayName(colHeader)),
            headerTooltip: colTooltip,
            sortable: true,
            filter: 'agMultiColumnFilter',
            hide: true,
            suppressToolPanel: true
          })
        }
      } else {
        pickListColumnDefinition.headerName = colHeader;
        pickListColumnDefinition.headerTooltip = colTooltip;
        columnDefinition.push(pickListColumnDefinition);
      }
    });


    if (columnDefinition.length > 0) {
      columnDefinition[0].headerCheckboxSelection = true;
      columnDefinition[0].checkboxSelection = true;
      columnDefinition[0].showDisabledCheckboxes = true;
    }

    columnDefinition = rearrangeColumnsBasedOnPreference(columnDefinition, _colPreference);
    if (selectedTable.name === "DATA_AUDIT") {
      columnDefinition = constructColumnGrouping(columnDefinition, reportConfigurations);
    }

    setcolumnDefinitions(columnDefinition);
    gridRef?.current?.api?.setColumnDefs(columnDefinition);
    gridRef?.current?.api?.setRowData(tableData);
  }

  const onFilterTextBoxChanged = useCallback((event: any) => {
    gridRef.current.api.setQuickFilter(event.target.value);
    setMapLocs();
  }, []);

  const onColumnSearchTextBoxChanged = useCallback((event: any) => {
    const allColumns = gridRef.current.columnApi.getAllColumns();
    countClick = event.keyCode == 13 ? countClick + 1 : 0;
    //gridRef.current.api.ensureColumnVisible(allColumns[allColumns.length - 1].colId);
    const mathcingColumns = allColumns.filter((ele: any) => { return ele.colId.toLowerCase().match(event.target.value.toLowerCase()) });
    if (mathcingColumns.length > 0) {
      if (countClick >= mathcingColumns.length) {
        countClick = 0;
      }

      if (event.target.value !== "" && document.getElementsByClassName("ag-body-horizontal-scroll-viewport")) {
        document.getElementsByClassName("ag-body-horizontal-scroll-viewport")[0]
          .scrollTo((mathcingColumns[countClick].left), 0);
      } else if (document.getElementsByClassName("ag-body-horizontal-scroll-viewport")) {
        document.getElementsByClassName("ag-body-horizontal-scroll-viewport")[0]
          .scrollTo(0, 0);
      }
    }
  }, []);

  const handlePickListConstruction = (columnName: string, _colPreference: any[]) => {
    if (pickListDefinitions !== undefined && pickListDefinitions.length > 0) {
      const pickListMatch = pickListDefinitions
        .filter((ele) => {
          return ele.ColumnName.toLowerCase()
            === columnName.toLowerCase() &&
            ele.SchemaName === selectedTable.schema &&
            ele.TableName === selectedTable.name
        });
      if (pickListMatch?.length > 0) {
        if (pickListMatch[0]?.ListValues?.length > 0 && pickListMatch[0]?.ListValues[0] === '[') {
          const pickListColumnDefinition = {
            field: columnName,
            type: 'editableColumn',
            headerName: fetchDisplayName(columnName),
            headerTooltip: fetchDisplayName(columnName),
            headerComponentParams: columnHeaderTemplate(fetchDisplayName(columnName)),
            width: (columnName.length * 17),
            sortable: true,
            filter: true,
            cellStyle: { textAlign: 'left' },
            //editable: isColumnEdittable(columnName),
            cellEditor: DropDownCellRenderer,
            cellEditorParams: {
              DropDownOptionsJSON: pickListMatch[0].ListValues,
              onDropDownValueSelected: (option: Any, rowIndex: any) => {
                let _tableData = [...tableData];
                _tableData[rowIndex][columnName] = option;
                _tableData[rowIndex]["isModified"] = true;
                _tableData[rowIndex]["modifiedAttrs"] = columnName;
                setTableData(_tableData);
                setTableDataMonitor(_tableData);
              }
            },
            hide: isColumnHidden(columnName, _colPreference),
            suppressToolPanel: false,
            cellClass: ['ag-cell-custom']
          };

          return pickListColumnDefinition;
        } else {
          handleFetchTableHasADropDownData(selectedTable.schema,
            selectedTable.name,
            pickListMatch[0].ListValues,
            columnName);

          const pickListColumnDefinition = {
            field: columnName,
            type: 'editableColumn',
            headerName: fetchDisplayName(columnName),
            headerTooltip: fetchDisplayName(columnName),
            headerComponentParams: columnHeaderTemplate(fetchDisplayName(columnName)),
            width: (columnName.length * 17),
            sortable: true,
            filter: true,
            cellStyle: { textAlign: 'left' },
            //editable: isColumnEdittable(columnName),
            cellEditorPopup: true,
            cellRenderer: DropDownAgGridCustomColumnRenderer,
            cellRendererParams: {
              schemaName: selectedTable.schema,
              tableName: selectedTable.name,
              dropDownSelectQuery: pickListMatch[0].ListValues
            },
            cellEditor: DropDownAgGridRenderer,
            cellEditorParams: {
              schemaName: selectedTable.schema,
              tableName: selectedTable.name,
              dropDownSelectQuery: pickListMatch[0].ListValues,
              onDropDownValueSelected: (optionData: any, rowIndex: any, rowData: any) => {
                const selectedColumnValue = optionData[columnName];
                let _tableData = [...tableDataMonitor];
                rowData[columnName] = selectedColumnValue;
                _tableData[rowIndex] = rowData;
                _tableData = applyModifiedAttr(_tableData, rowIndex, columnName);
                setTableData(_tableData);
                setTableDataMonitor(_tableData);
              }
            },
            cellClass: ['ag-cell-custom'],
            hide: isColumnHidden(columnName, _colPreference)
          };

          return pickListColumnDefinition;
        }
      }
    }
    if (fileUploadColumns !== undefined && fileUploadColumns.length > 0) {
      const fileUploadColumnMatch = fileUploadColumns
        .filter((ele) => {
          return ele.ColumnName.toLowerCase()
            === columnName.toLowerCase() &&
            ele.SchemaName === selectedTable.schema &&
            ele.TableName === selectedTable.name
        });
      if (fileUploadColumnMatch.length > 0) {

        const fileUploadColumnDefinition = {
          field: columnName,
          type: 'editableColumn',
          headerName: fetchDisplayName(columnName),
          headerTooltip: fetchDisplayName(columnName),
          headerComponentParams: columnHeaderTemplate(fetchDisplayName(columnName)),
          width: (columnName.length * 27),
          sortable: true,
          filter: true,
          cellRenderer: FileUploadRenderer,
          cellRendererParams: {
            schemaName: selectedTable.schema,
            tableName: selectedTable.name,
            columnName: columnName,
            pkColumnName: selectedTable.pkColumnName,
            setShowProgress: props.setShowProgress,
            openSuccessMessage: setOpenSuccessMessage,
            loadData: loadTableData
          },
          cellClass: ['ag-cell-custom'],
          hide: isColumnHidden(columnName, _colPreference),
          suppressToolPanel: false
        };

        return fileUploadColumnDefinition;
      }
    }
    else {
      return false;
    }
  }

  /* #Cell Editting security */

  const isCellEditable = (params: any) => {
    if (historyView) {
      return false;
    }
    if (params.data.isModified) {
      return true;
    }
    else if (params.data.isNew) {
      return true;
    }
    else if (selectedTable.name === "DATA_AUDIT"
      && securityPermissions?.operators !== undefined
      && params.data?.Operator !== undefined) {
      const isOperatorRight = securityPermissions.operators?.split(",").indexOf(params.data["Operator"]);
      const isAllOperator = securityPermissions.operators?.toLowerCase() === "all";
      if (isOperatorRight >= 0 || isAllOperator) {
        return true;
      } else {
        return false;
      }
    }
    else if (securityPermissions.isAddAllowed
      && securityPermissions.isEditAllowed
      && securityPermissions.isDeleteAllowed
      && securityPermissions.isRowLevelSecurity === false) {
      return true;
    }
    else if (securityPermissions.isRowLevelSecurity
      && securityPermissions.isEditAllowed === true
      && params.data.CreatedBy === props.loggedInUser) {
      return true;
    }
    else {
      return false;
    }
  };

  const setCheckBoxSelectable = () => {
    const updateSelectable = (rowNode: any) => {
      rowNode.setRowSelectable(true);
    };

    gridRef.current.api?.deselectAll();
    gridRef.current.api?.forEachNodeAfterFilterAndSort(updateSelectable);
  };

  const columnTypes = useMemo(() => {
    return {
      editableColumn: {
        editable: (params: any) => {
          return isCellEditable(params);
        },
        cellStyle: (params: any) => {
          if (!isCellEditable(params)) {
            if (params.column.colId.toLowerCase().replace("_", "") === "activeyn" || params.column.colId.toLowerCase().includes('_yn')) {
              return { pointerEvents: 'none', fontWeight: 'none', backgroundColor: 'none' };
            } else {
              return { pointerEvents: 'none', fontWeight: 'none', backgroundColor: 'none' };
            }
          }
          else if (params.column.colId.toUpperCase() === selectedTable.pkColumnName?.toUpperCase()) {
            return { pointerEvents: 'none', fontWeight: 'none', backgroundColor: 'none' };
          }
          else if (params.column.colId.toUpperCase() === "LASTMODIFIEDBY") {
            return { pointerEvents: 'none', fontWeight: 'none', backgroundColor: 'none' };
          }
          else if (params.column.colId.toUpperCase() === "LASTMODIFIEDDATE") {
            return { pointerEvents: 'none', fontWeight: 'none', backgroundColor: 'none' };
          }
          else if (params.column.colId.toUpperCase() === "CREATEDBY") {
            return { pointerEvents: 'none', fontWeight: 'none', backgroundColor: 'none' };
          }
          else if (params.column.colId.toUpperCase() === "CREATEDDATE") {
            return { pointerEvents: 'none', fontWeight: 'none', backgroundColor: 'none' };
          }
          if (historyView && params.data.AffectedColumns !== null && params.data.AffectedColumns !== undefined
            && params.data.AffectedColumns.includes(params.column.colId)) {
            return { pointerEvents: 'none', fontWeight: 'bold', backgroundColor: 'lightgrey' };
          }
        },
      },
    };
  }, [securityPermissions, selectedTable, historyView]);

  /* #endregion */


  const defaultColDef = useMemo(() => {
    return {
      resizable: true,
      filter: true,
      headerCheckboxSelectionFilteredOnly: true
    };
  }, []);

  const gridOptions = {
    // PROPERTIES
    defaultColDef: defaultColDef,
    //rowSelection: 'multiple',
    defaultExportParams: {
      columnGroups: true,
      fileName: 'name_of_file'
    },

    // EVENTS
    onRowClicked: () => console.log('A row was clicked'),
    onColumnResized: () => console.log('A column was resized'),
    onGridReady: () => console.log('The grid is now ready'),
    onCellValueChanged: () => console.log('cell value changed'),
    onRowValueChanged: () => console.log('row value changed'),
    getRowHeight: () => { return 30 },
    isRowSelectable: (params: any) => {
      return isCellEditable(params);
    }
  }

  const getModifiedAttributes = (editedObject: any) => {
    const actualKeys = Object.keys(editedObject);
    const modifiedKeys = editedObject["modifiedAttrs"];
    let _objectClone: any = {};
    for (let key of actualKeys) {
      if (modifiedKeys.includes(key) || key === selectedTable.pkColumnName) {
        _objectClone[key] = editedObject[key];
      }
    }
    return _objectClone;
  }

  const onCellValueChanged = (params: any) => {
    let _tableData = [...tableDataMonitor];
    if (tableDataMonitor.length > 0) {
      _tableData[params.node.id] = params.node.data;
      _tableData[params.node.id]["isModified"] = true;
      if (_tableData[params.node.id]["modifiedAttrs"] === undefined) {
        _tableData[params.node.id]["modifiedAttrs"] = params.column.colId;
      } else {
        let modAttrs = _tableData[params.node.id]["modifiedAttrs"];
        modAttrs = modAttrs + ',' + params.column.colId;
        _tableData[params.node.id]["modifiedAttrs"] = modAttrs;
      }
    }

    params.node.setData({ ...params.node.data, isModified: true });
    setTableDataMonitor(_tableData);
  }

  const applyModifiedAttr = (_tableData: any[], rowIndex: number, columnName: any) => {
    _tableData[rowIndex]["isModified"] = true;
    if (_tableData[rowIndex]["modifiedAttrs"] === undefined) {

      _tableData[rowIndex]["modifiedAttrs"] = columnName;
    } else {
      let modAttrs = _tableData[rowIndex]["modifiedAttrs"];
      modAttrs = modAttrs + ',' + columnName;
      _tableData[rowIndex]["modifiedAttrs"] = modAttrs;
    }

    return _tableData;
  }

  const onSubmitHandler = () => {
    const edittedData = tableDataMonitor.filter((data) => { return data.isModified && (!data.isNew) });
    const newData = tableDataMonitor.filter((data) => { return data.isNew });
    const editedRows: any = [];
    edittedData.forEach((element) => {

      if (element.modifiedAttrs !== undefined) {
        let updatedData = getModifiedAttributes(element);
        updatedData.LastModifiedBy = props.loggedInUser;
        updatedData.LastModifiedDate = new Date().toISOString().slice(0, 19);
        editedRows.push(updatedData);
      }
    }
    );

    newData.forEach((element) => {
      delete element.isModified;
      delete element.isNew;
      element.LastModifiedBy = props.loggedInUser;
      element.LastModifiedDate = new Date().toISOString().slice(0, 19);
    }
    );

    async function SaveData() {
      await api.API_POST('/Table/SaveTableData', {
        schema: selectedTable.schema,
        table: selectedTable.name,
        insert: newData,
        update: editedRows,
        ids: replaceDeleteIds.length > 0 ? replaceDeleteIds.toString() : ""
      }).then((response) => {
        api.LOG_MESSAGE(["Page : Data Maintenance", "Table : " + selectedTable.schema + "." + selectedTable.name, "Action : Data Saved successfully"], { 'UserName': props.loggedInUser });

        setReplaceDeleteIds([]);
        if (response.data.length > 0) {
          setSaveAndUpdateInfo({
            insertRows: response.data[0].inserted_rows,
            updatedRows: response.data[0].updated_rows,
            insertErrors: response.data[0].insert_errors,
            updateErros: response.data[0].update_errors
          });

          if (response.data[0].inserted_rows > 0 || response.data[0].updated_rows > 0) {
            setOpenSuccessMessage({
              success: true,
              message: "Data saved successfully",
              isSaveData: true
            });
            loadTableData(true, false, selectedPreference?.preferenceJson);
            if (selectedTable.name === 'PICKLIST_DEFINITIONS') {
              loadInitialPrerequisiteData();
            }
          }
          if (response.data[0].insert_errors.length > 0 || response.data[0].update_errors.length > 0) {
            const _errorList = [];
            if (response.data[0].insert_errors.length > 0) { _errorList.push(response.data[0].insert_errors) }
            if (response.data[0].update_errors.length > 0) { _errorList.push(response.data[0].update_errors) }
            setErrorList(_errorList);
            setOpenErrorMessage(true);
          }
        }
      }).catch(() => {

      });
    };

    if (checkValidations(newData, editedRows)) {
      if (edittedData.length > 0 || newData.length > 0) {
        setErrorList([]);
        SaveData();
      } else {
        setInformationMessage("There are no changes to save.");
        setOpenInformationMessage(true);
      }
    }
  }

  const checkValidations = (newRecords: any[], edittedRecords: any[]) => {
    let result = true;

    if (!checkDataAuditOperators(newRecords, edittedRecords, securityPermissions)) {
      result = false;

      setErrorList(["Please enter a valid operator name."]);
      setOpenErrorMessage(true);
    }

    return result;
  }

  const onDeleteHandler = () => {
    let deleteIds: any[] = [];
    let selectedRows = gridRef.current.api.getSelectedRows();
    if (selectedRows.length > 0) {
      selectedRows.forEach((element: any) => {
        let primaryKeyId = element["" + selectedTable.pkColumnName];
        if (typeof (primaryKeyId) != "undefined" && primaryKeyId != null) {
          deleteIds.push(primaryKeyId);
        }
      });

      if (deleteIds.length > 0) {
        deleteData(deleteIds.toString());
      }
    }
  }

  const onAddtoCart = (nodeData?: any) => {
    let selectedRows = gridRef.current.api.getSelectedRows();
    let _cartInformation = props.cartInformation ? [...props.cartInformation] : [];
    let matchingObject = props.cartInformation?.filter((info) => {
      return info.schemaName === selectedTable.schema
        && info.tableName === selectedTable.name;
    });
    if (matchingObject && matchingObject.length > 0) {
      let matchingObjectIndex = props.cartInformation?.indexOf(matchingObject[0]);
      let _currentObject = { ...matchingObject[0] };
      let _currentData: any[] = [];
      if (exchangeOrder && exchangeOrder.requestedTo !== undefined) {
        _currentData = [..._currentObject.dataRows];
      }
      let _currentDataMap = _currentObject.dataMap
      let _currentDataIds: any[] = [];
      if (selectedTable.pkColumnName !== undefined) {
        _currentDataIds = _currentData.map((ele) => {
          return ele[selectedTable.pkColumnName ? selectedTable.pkColumnName : ""];
        });
      }
      selectedRows.forEach((rowInfo: any) => {
        const rowId = rowInfo[selectedTable.pkColumnName ? selectedTable.pkColumnName : ""];
        if (!_currentDataIds.includes(rowId)) {
          _currentData.push(rowInfo);
          if (_currentDataMap.get(rowInfo['Operator'])) {
            _currentDataMap.get(rowInfo['Operator'])?.push(rowInfo)
          } else {
            let _rowData: any[] = [rowInfo];
            _currentDataMap.set(rowInfo['Operator'], _rowData);
          }
        }
      });
      _currentObject.dataMap = _currentDataMap;
      _currentObject.dataRows = _currentData;
      if (exchangeOrder && exchangeOrder.requestedTo !== undefined) {
        _currentObject.isExchange = true;
      }
      if (modifiedOrder && modifiedOrder.requestedTo !== undefined) {
        _currentObject.isModified = true;
      }
      if (_currentObject.operatorFilter.filter((element) => { return element.operator === selectedRows[0].Operator }).length > 0) {
        _currentObject.operatorFilter
          .filter((element) => { return element.operator === selectedRows[0].Operator })[0].filterApplied = filtersAppliedReports;
      } else {
        _currentObject.operatorFilter.push({ operator: selectedRows[0].Operator, filterApplied: filtersAppliedReports });
      }

      if (matchingObjectIndex !== undefined) {

        _cartInformation.splice(matchingObjectIndex, 1, _currentObject);
        //_cartInformation[matchingObjectIndex] = _currentObject;
        props.setCartInformation(_cartInformation);
      }
    } else {
      if (selectedRows.length > 0) {
        _cartInformation.push({
          schemaName: selectedTable.schema,
          tableName: selectedTable.name,
          dataRows: selectedRows,
          dataMap: groupBy(selectedRows, (x: any) => x.Operator),
          isExchange: exchangeOrder && exchangeOrder.requestedTo !== undefined,
          isModified: modifiedOrder && modifiedOrder.requestedTo !== undefined,
          operatorFilter: [{ operator: selectedRows[0].Operator, filterApplied: filtersAppliedReports }]
        });
        props.setCartInformation(_cartInformation);
      }
    }

    gridRef.current.api.deselectAll();
  }


  function groupBy<K, V>(array: V[], grouper: (item: V) => K) {
    return array.reduce((store, item) => {
      let key = grouper(item)
      if (!store.has(key)) {
        store.set(key, [item])
      } else {
        store.get(key)?.push(item)
      }
      return store;
    }, new Map<K, V[]>())
  }

  async function deleteData(deleteIds: any) {
    await api.API_POST('/Table/SaveTableData', {
      schema: selectedTable.schema,
      table: selectedTable.name,
      insert: [],
      update: [],
      ids: deleteIds
    }).then((response) => {
      if (response.data.length > 0) {
        if (response.data[0].deleted_rows > 0) {
          setInformationMessage("Data deleted successfully.");
          api.LOG_MESSAGE(["Page : Data Maintenance", "Table : " + selectedTable.schema + "." + selectedTable.name, "Action : Data deleted successfully"], { 'UserName': props.loggedInUser });
          setOpenInformationMessage(true);
          loadTableData(true, false, selectedPreference?.preferenceJson);
          if (selectedTable.name === 'PICKLIST_DEFINITIONS') {
            loadInitialPrerequisiteData();
          }
        }

        if (response.data[0].delete_errors.length > 0) {
          const _errorList = [];
          if (response.data[0].delete_errors.length > 0) { _errorList.push(response.data[0].delete_errors) }
          setErrorList(_errorList);
          setOpenErrorMessage(true);
        }
      }
    }).catch(() => {
      setOpenSuccessMessage({
        success: false,
        message: "",
        isSaveData: true
      });
    });
  };

  const addActualColumnRowForExport = () => {
    let _tableData = [...tableDataMonitor];
    let _objectClone: any = {};
    const _columns = [...columns];
    _columns.forEach((colInfo: any) => {
      _objectClone[colInfo.ColumnName] = colInfo.ColumnName;
    });

    _tableData.unshift(_objectClone);
    setTableData(_tableData);
    setTableDataMonitor(_tableData);
  }

  const removeColumnRowForExport = () =>{
    let _tableData = [...tableDataMonitor];
    _tableData.shift();
    setTableData(_tableData);
    setTableDataMonitor(_tableData);
  }

  const addNewRowHandler = () => {
    let _tableData = [...tableDataMonitor];
    let selectedRows = gridRef.current.api.getSelectedRows();
    if (selectedRows.length > 0) {
      let clonedItems: any[] = [];
      const pkcolName = selectedTable.pkColumnName ? selectedTable.pkColumnName : "";
      selectedRows.forEach((element: any) => {
        let _objectCln: any = {};
        const _columns = [...columns];
        _columns.forEach((colInfo: any) => {
          if (colInfo.ColumnName.toLowerCase().includes("date")) {
            _objectCln[colInfo.ColumnName] = new Date().toISOString().slice(0, 19);
          }
          else {
            _objectCln[colInfo.ColumnName] = element[colInfo.ColumnName];
          }
        });

        _objectCln[pkcolName] = null;
        _objectCln["isNew"] = true;
        _objectCln["LastModifiedBy"] = props.loggedInUser;
        _objectCln["CreatedBy"] = props.loggedInUser;
        clonedItems.push(_objectCln);
      });

      _tableData = clonedItems.concat(_tableData);
    }
    else {
      let _objectClone: any = {};
      const _columns = [...columns];
      _columns.forEach((colInfo: any) => {
        _objectClone[colInfo.ColumnName] = null;
      });

      for (let key in _objectClone) {
        if (_objectClone.hasOwnProperty(key)) {
          if (key.includes("Date")) {
            _objectClone[key] = new Date().toISOString().slice(0, 19);
          }
          else if (key.toLowerCase() == "active_yn" || key.toLowerCase() == "activeyn") {
            _objectClone[key] = "Y";
          }
        }
      }

      _objectClone["isNew"] = true;
      _objectClone["LastModifiedBy"] = props.loggedInUser;
      _objectClone["CreatedBy"] = props.loggedInUser;
      _tableData.unshift(_objectClone);
    }

    setTableData(_tableData);
    setTableDataMonitor(_tableData);
  }

  const refreshDataHandler = () => {
    loadInitialPrerequisiteData();
    props.setIsRefreshClicked(true);
    clearTableInfo();
    loadTableData(true, false, selectedPreference?.preferenceJson);
  }

  const loadHistoryTable = () => {
    setHistoryView(true);
    loadTableData(true, true);
  }

  const closeMessageHandler = () => {
    setOpenSuccessMessage({
      success: false,
      message: "",
      isSaveData: true
    });
  }

  const openConfirmDeleteDialogue = () => {
    let selectedRows = gridRef.current.api.getSelectedRows();
    if (selectedRows.length > 0) {
      setOpenConfirmDialog(true);
    } else {
      setInformationMessage("No rows selected to delete.");
      setOpenInformationMessage(true);
    }
  }

  const isColumnEdittable = (columnName: any) => {
    if (columnName.toUpperCase() === selectedTable.pkColumnName?.toUpperCase()) {
      return false;
    }
    else if (columnName.toUpperCase() === "LASTMODIFIEDBY") {
      return false;
    }
    else if (columnName.toUpperCase() === "LASTMODIFIEDDATE") {
      return false;
    }

    return true;
  }

  const getRowStyle = (params: any) => {
    let rowStyle = {} //{ 'pointer-events': 'none', opacity: '0.7' }
    if (params.node.data.isModified) {
      return { fontStyle: 'italic', fontWeight: 'bold' };
    }
    else if (params.node.data.isNew) {

    }
    else if (securityPermissions.isAddAllowed
      && securityPermissions.isEditAllowed
      && securityPermissions.isDeleteAllowed
      && securityPermissions.isRowLevelSecurity === false) {

    }
    else if (securityPermissions.isRowLevelSecurity
      && securityPermissions.isEditAllowed === true
      && params.node.data.CreatedBy === props.loggedInUser) {

    } else {
      return rowStyle;
    }
  }


  const handleFetchTableHasADropDownData = async (
    schemaName: string,
    tableName: string,
    dropDownSelectQuery: string,
    columnName: string) => {
    const dropDownDataExists = dropDownSelectListCollection?.filter((dropDownSelectList: IDropDownSelectList) => {
      return dropDownSelectList.colId === columnName
        && dropDownSelectList.tableName === tableName
        && dropDownSelectList.schema === schemaName;
    });

    if (typeof (dropDownDataExists) === "undefined" || dropDownDataExists?.length === 0) {
      await api.API_GET('/Table/GetSelectQueryResult?schema='
        + schemaName + '&table='
        + tableName + '&selectQuery='
        + dropDownSelectQuery + '').then((response: any) => {
          saveDropDownSelectList({
            tableName: tableName,
            schema: schemaName,
            colId: columnName,
            data: response.data
          });

        });
    }
  }

  const addApprovalColumnsHandler = (approvalConfig?: any[]) => {
    const levels = approvalConfig?.filter((config) => {
      return config.SchemaName === selectedTable.schema
        && config.TableName === selectedTable.name
    });

    let _hiddenColumns = ["Approver1"
      , "Comment1"
      , "ApprovedDate1"
      , "ApprovalStatus1"
      , "Approver2"
      , "Comment2"
      , "ApprovedDate2"
      , "ApprovalStatus2"
      , "Approver3"
      , "Comment3"
      , "ApprovedDate3"
      , "ApprovalStatus3"];
    levels?.forEach((level, index) => {
      _hiddenColumns.splice(_hiddenColumns.indexOf("ApprovalStatus" + (index + 1)), 1);
    });
    setHiddenColumns(_hiddenColumns);
  }

  const onBtExport = () => {
    addActualColumnRowForExport();
    setTimeout(() => {
      const params = {
        columnGroups: true,
        allColumns: true,
        fileName: selectedTable.name,
      };
      gridRef.current.api.exportDataAsCsv(params);
      removeColumnRowForExport();
      api.LOG_MESSAGE(["Page : Data Maintenance", "Table : " + selectedTable.name, "Action : Data exported successfully"], { 'UserName': props.loggedInUser });
    }, 2000);
  }

  const setInitialColumnPreferenceStateByColumns = (cols: any[]) => {
    let columnPreference: any[] = [];
    cols.forEach((element: any) => {
      columnPreference.push({
        ColumnName: element.ColumnName,
        Enable: true,
        AvailableOrDisplayed: "D" // A for Available columns , D for Displayed columns
      });
    });

    if (columnPreference.length > 0) {
      let preference: any = {
        PreferenceJson: columnPreference,
        PreferenceId: 0,
        PreferenceName: null,
        SchemaName: selectedTable.schema,
        TableName: selectedTable.name,
        UserMailId: props.loggedInUserMailId,
        IsPublic: true,
        IsDefault: false,
        CreatedBy: props.loggedInUserMailId
      };

      //if (selectedPreference == null || selectedPreference == undefined) {
      setselectedPreference(preference);
      //}
    }

    return columnPreference;
  }

  const applyDisplayColumnNames = (_columnPreference: any[], colDefs: any) => {
    colDefs.forEach((colDef: any) => {
        let colPref = _columnPreference.filter((ele) => { return ele.ColumnName == colDef.field });
        if (colPref.length > 0) {
            colDef.headerName = colPref[0].DisplayName
        }
    });

    return colDefs;
}

  const applyColumnPreferenceHandler = async (selectedPreferene: any) => {
    setselectedPreference(selectedPreferene);
    applyColumnPreference(selectedPreferene.PreferenceJson);
  }

  const applyColumnPreference = (_columnPreference: any[]) => {
    let updatedColumnDefs = rearrangeColumnsBasedOnPreference([...columnDefinitions], _columnPreference);
    _columnPreference.forEach((colPreference: any) => {
      let columnDefMatch = updatedColumnDefs.filter((element: any) => { return element.field === colPreference.ColumnName });
      if (columnDefMatch.length > 0) {
        if (colPreference.Enable === false) {
          updatedColumnDefs[updatedColumnDefs.indexOf(columnDefMatch[0])].hide = true;
        } else {
          updatedColumnDefs[updatedColumnDefs.indexOf(columnDefMatch[0])].hide = false;
        }
      }
    });

    gridRef.current?.columnApi?.columnModel?.setColumnDefs(applyDisplayColumnNames(_columnPreference,updatedColumnDefs));
  }

  const rearrangeColumnsBasedOnPreference = (columnDefinitions: any[], _columnPreference: any[]) => {
    let _reArrangedColumnDefinitions: any[] = [];
    //let colPrefs = _columnPreference.length > 0 ? _columnPreference : [...columnPreference];
    let colPrefs = _columnPreference;

    if (colPrefs != null && colPrefs.length > 0) {
      colPrefs.forEach((preference: any) => {
        const columnDefinitionObj = columnDefinitions.filter((ele: any) => { return ele.field === preference.ColumnName });
        if (columnDefinitionObj.length > 0) {
          _reArrangedColumnDefinitions.push(columnDefinitionObj[0]);
        }
      });

      const columnsNotInPreference = columnDefinitions.filter(o => !colPrefs.some(({ ColumnName }) => o.field.toLowerCase().match(ColumnName.toLowerCase())));
      columnsNotInPreference.forEach((column: any) => {
        _reArrangedColumnDefinitions.push(column);
      });
    }
    return _reArrangedColumnDefinitions.length > 0 ? _reArrangedColumnDefinitions : columnDefinitions;
  }

  const isColumnHidden = (columnName: string, columnPreference: any[]) => {
    if (columnPreference != null) {
      let colMatch = columnPreference.filter((element: any) => {
        return element.ColumnName === columnName;
      });
      if (colMatch.length > 0) {
        return !colMatch[0].Enable;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  const setMapLocs = () => {
    let locations: any[] = [];

    gridRef.current?.api?.getModel().rowsToDisplay.forEach((ele: any) => {
      if (ele.data.Longitude !== null && ele.data.Longitude !== undefined
        && ele.data.Latitude !== null && ele.data.Latitude !== undefined) {
        locations.push({ lat: parseFloat(ele.data.Longitude), lng: parseFloat(ele.data.Latitude), nodeData: ele.data });
      }
    });
    setLocs(locations);

  }

  const printFilterModel = () => {
    let filterModel = gridRef.current.api.getFilterModel();
    setFiltersApplied(Object.keys(filterModel));
    setReportFiltersApplied(filterModel);
  }

  const setReportFiltersApplied = (filterModel: any[]) => {
    //Check if its a report filter by checking for boolean values

    let filterReports: string[] = [];
    filterReports = Object.keys(filterModel).filter((element: any) => {
      const filterValues = filterModel[element]?.filterModels ? filterModel[element].filterModels[filterModel[element].filterModels?.length - 1]?.values : [];
      return (filterValues?.includes('1') || filterValues?.includes('0'));
    });
    setFiltersAppliedReports(filterReports);
  }

  function clearFilter(filterName: any) {
    if (filterName) {
      let FilterComponent = gridRef.current.api.getFilterInstance(filterName);
      FilterComponent.setModel(null);
      gridRef.current.api.onFilterChanged();
    }
  }

  const scollToFilter = (filterName: any) => {
    Array.prototype.slice.call(document.getElementsByClassName("ag-filter-toolpanel-group-title"))
      .filter((element) => { return element.innerHTML === filterName.toUpperCase() })[0].scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
  }

  const hideAllFilter = () => {
    const filtersToolPanel = gridRef.current?.api?.getToolPanelInstance('filters');
    if (filtersToolPanel) {
      filtersToolPanel.collapseFilterGroups();
      filtersToolPanel.expandFilterGroups(['generalinformation', 'geology', 'engineering', 'finance', 'software']);
    }
  }
  return (
    <div style={{ display: 'flex' }}>
      <Grid style={{ display: open ? "" : "none", paddingTop: "0px", marginRight: "1%" }} item xs={explorerWidth}>
        <Paper sx={{ p: 3, display: 'flex', flexDirection: 'column', height: '90vh', padding: '10px' }}>
          <Box>
            <IconButton id="tableviewcollapse" style={{ float: 'right', backgroundColor: 'rgb(239, 238, 238)' }} onClick={() => { handleCollapseExplorer(); }}>
              <ChevronLeftIcon />
            </IconButton>
          </Box>
          <ExplorerProvider>
            <Explorer loggedInUser={props.loggedInUser}
              loggedInUserMailId={props.loggedInUserMailId}
              setSelectedTableHandler={setSelectedTable}
              IsUserGlobalAdmin={isUserGlobalAdmin}
              userSecurityPermission={props.userSecurityPermission} />
          </ExplorerProvider>
        </Paper>
      </Grid>

      <Grid item xs={tableViewWidth} style={{ paddingTop: "0px" }}>
        <Box>
          {!open &&
            <IconButton id="tableviewexpand"
              style={{
                margin: '4px',
                backgroundColor: 'rgb(239, 238, 238)',
                position: 'fixed',
                zIndex: 100,
                marginLeft: '10px'
              }}
              onClick={() => { handleExpandExplorer(); }}>
              <ChevronRightIcon />
            </IconButton>}
        </Box>

        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={openSuccessMessage.success}
          autoHideDuration={3000}
          onClose={closeMessageHandler}>
          <p></p>
        </Snackbar>
        <Paper sx={{ p: 1, display: filtersApplied.length > 0 ? 'flex' : 'none', flexDirection: 'row', marginBottom: '10px' }}>
          <Typography variant="h6" sx={{ color: "#505050", paddingRight: "10px" }} gutterBottom>
            Filter :
          </Typography>
          {
            filtersApplied.map((filter: any, index: number) => {
              return <Chip key={index} label={filter} sx={{ marginRight: "5px" }} variant="outlined" onClick={() => { scollToFilter(filter) }} onDelete={() => { clearFilter(filter) }} />
            })
          }
        </Paper>
        <div style={{ display: 'flex' }}>
          <Paper sx={{ p: 3, display: 'flex', flexDirection: 'column', padding: '10px', width: '100%' }}>
            <div>
              <Grid item sx={{ textAlign: 'right' }}>

                {!enableMapView && <Tooltip id="viewTH" title="View table history">
                  <IconButton id="TVbrowsepicture"
                    color="success"
                    aria-label="browse picture"
                    component="label"
                    style={{ backgroundColor: "rgb(239 238 238)", marginRight: '5px' }}
                    onClick={loadHistoryTable}>
                    <HistoryToggleOffIcon />
                  </IconButton>
                </Tooltip>}

                {(securityPermissions.isAddAllowed ||
                  securityPermissions.isEditAllowed ||
                  securityPermissions.isDeleteAllowed ||
                  selectedTable.schema === 'security') &&
                  !historyView &&
                  <Tooltip id="save" title="Save" style={{ marginRight: "10px" }}>
                    <IconButton id="tvsave"
                      color="success"
                      aria-label="Save"
                      component="label"
                      style={{ backgroundColor: "rgb(239 238 238)", marginRight: '5px' }}
                      onClick={onSubmitHandler}>
                      <SaveIcon />
                    </IconButton>
                  </Tooltip>}

                {securityPermissions.isAddAllowed && !historyView && <Tooltip id="addrow" title="Add Row">
                  <IconButton id="tvaddrow"
                    color="success"
                    aria-label="Add Row"
                    component="label"
                    style={{ backgroundColor: "rgb(239 238 238)", marginRight: '5px' }}
                    onClick={addNewRowHandler}>
                    <AddCircleIcon />
                  </IconButton>
                </Tooltip>}

                {securityPermissions.isDeleteAllowed && !historyView && <Tooltip id="Delete" title="Delete">
                  <IconButton id="tv-delete"
                    color="warning"
                    aria-label="Delete"
                    component="label"
                    style={{ backgroundColor: "rgb(239 238 238)", marginRight: '5px' }}
                    onClick={openConfirmDeleteDialogue}>
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>}

                {securityPermissions.isDeleteAllowed && !historyView &&
                  <ExcelUploadMenu
                    selectedTableInfo={selectedTable}
                    columns={columns}
                    loggedInUser={props.loggedInUser}
                    loggedInUserOperator={props.userSecurityPermission[0].Operators}
                    environment={api.env}
                    tableData={tableData}
                    setTableData={setTableData}
                    setShowProgress={props.setShowProgress}
                    setTableDataMonitor={setTableDataMonitor}
                    setReplaceDeleteIds={setReplaceDeleteIds}
                    refreshdatahandler={refreshDataHandler}
                    setSaveAndUpdateInfo={setSaveAndUpdateInfo}
                    setOpenSuccessMessage={setOpenSuccessMessage}
                    setErrorList={setErrorList}
                    setOpenErrorMessage={setOpenErrorMessage}></ExcelUploadMenu>
                }
                {!enableMapView && <Tooltip id="export" title="Export to excel">              
                  <IconButton id="exportexcel"
                    color="success"
                    aria-label="Export to excel"
                    component="label"
                    style={{ backgroundColor: "rgb(239 238 238)", marginRight: '5px' }}
                    onClick={onBtExport}>
                    <SystemUpdateAltIcon />
                  </IconButton>

                </Tooltip>}
                {/* {!enableMapView && <Tooltip title="Export Full DB Table view">              
                  <IconButton
                    color="success"
                    aria-label="Export to excel"
                    component="label"
                    style={{ backgroundColor: "rgb(239 238 238)", marginRight: '5px' }}
                    onClick={()=>{}}>
                    <CSVLink
                      style={{ color: "#2e7d32",height:'1.5rem' }}
                      filename={selectedTable.name + ".csv"}
                      data={tableData}>
                      <FileDownloadOutlinedIcon /></CSVLink>
                  </IconButton>

                </Tooltip>} */}
                {!enableMapView && <Tooltip id="success" title="columns">
                  <IconButton id="success"
                    color="success"
                    aria-label="columns"
                    component="label"
                    style={{ backgroundColor: "rgb(239 238 238)", marginRight: '5px' }}
                    onClick={handleClickPerfOpen}>
                    <ViewColumnOutlinedIcon />
                  </IconButton>
                </Tooltip>}

                {selectedTable.name === "DATA_AUDIT" && <Tooltip id="addtocart" title="Add to cart">
                  <IconButton id="addcart"
                    color="success"
                    aria-label="Add to cart"
                    component="label"
                    style={{ backgroundColor: "rgb(239 238 238)", marginRight: '5px' }}
                    onClick={onAddtoCart}>
                    <AddShoppingCartIcon />
                  </IconButton>
                </Tooltip>}

                {enableMapView && <Tooltip title="Apply County">
                  <CountySelection mapObj={map} />
                </Tooltip>}

                <ColumnSelection
                  tablePreferenceList={tablePreferenceList}
                  selectedPreference={selectedPreference}
                  setselectedPreference={setselectedPreference}
                  loggedInUserMailId={props.loggedInUserMailId}
                  loggedInUser={props.loggedInUser}
                  hiddenColumns={hiddenColumns}
                  columnsPerfOpen={columnsPerfOpen}
                  anchorEl={anchorEl}
                  applyColumnPreferenceHandler={applyColumnPreferenceHandler}
                  setcolumnsPerfOpen={setcolumnsPerfOpen}
                  displayColumns={displayColumns}
                ></ColumnSelection>
              </Grid>
            </div>
            <div
              className={"ag-theme-balham ag-theme-material table"}
              style={{ height: '80vh', width: '100%' }}>
              <Collapse in={openSuccessMessage.success}>
                {openSuccessMessage.isSaveData &&
                  <Alert severity="success">
                    <CloseIcon fontSize="inherit"
                      style={{ float: 'right', cursor: 'pointer' }}
                      onClick={() => {
                        setOpenSuccessMessage({
                          success: false,
                          message: "",
                          isSaveData: true
                        })
                      }} />
                    <AlertTitle style={{ width: "100%" }}>Success</AlertTitle>
                    Data saved successfully —
                    Added <strong>{saveAndUpdateInfo.insertRows}</strong> Rows,
                    Modified <strong>{saveAndUpdateInfo.updatedRows}</strong> Rows.
                  </Alert>}
                {!openSuccessMessage.isSaveData &&
                  <Alert severity="success">
                    <CloseIcon fontSize="inherit"
                      style={{ float: 'right', cursor: 'pointer' }}
                      onClick={() => {
                        setOpenSuccessMessage({
                          success: false,
                          message: "",
                          isSaveData: true
                        })
                      }} />
                    <AlertTitle style={{ width: "100%" }}>Success</AlertTitle>
                    {openSuccessMessage.message}
                  </Alert>
                }
              </Collapse>
              <Collapse in={openErrorMessage}>
                <Alert severity="error">
                  <CloseIcon fontSize="inherit"
                    style={{ float: 'right', cursor: 'pointer' }}
                    onClick={() => { setOpenErrorMessage(false) }} />
                  <AlertTitle>Error Message</AlertTitle>
                  {errorList.map((_errorInfo, index) => {
                    return <><strong>{index + 1}.</strong> {_errorInfo}</>
                  })}
                </Alert>
              </Collapse>
              <Collapse in={openInformationMessage}>
                <Alert severity="info">
                  <CloseIcon fontSize="inherit"
                    style={{ float: 'right', cursor: 'pointer' }}
                    onClick={() => { setOpenInformationMessage(false) }} />
                  <AlertTitle>Info</AlertTitle>
                  {informationMessage}
                </Alert>
              </Collapse>

              {columns.length === 0 &&
                <>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Box sx={{ margin: 1 }}>
                      <Skeleton variant="circular">
                        <Avatar />
                      </Skeleton>
                    </Box>
                    <Box sx={{ width: '100%' }}>
                      <Skeleton width="100%">
                        <Typography>.</Typography>
                      </Skeleton>
                    </Box>
                  </Box>
                  <Skeleton variant="rectangular" width="100%">
                    <div style={{ paddingTop: '57%' }} />
                  </Skeleton>
                </>
              }

              <AgGridReact
                ref={gridRef}
                rowSelection={'multiple'}
                gridOptions={gridOptions}
                columnHoverHighlight={true}
                columnTypes={columnTypes}
                getRowStyle={getRowStyle}
                animateRows={true}
                sideBar={sideBar}
                pagination={true}
                onFilterChanged={() => { setMapLocs(); printFilterModel(); }}
                onCellValueChanged={onCellValueChanged}
                overlayLoadingTemplate={'<span class="ag-overlay-loading-center">Please wait while your rows are loading</span>'}
              ></AgGridReact>
            </div>

          </Paper>
        </div>
        <ConfirmDialog
          open={openConfirmDialog}
          setOpenConfirmDialogHandler={setOpenConfirmDialog}
          title="Are you sure to delete ?"
          body="Please click OK if you confirm to delete , else click CANCEL"
          okHandler={onDeleteHandler}></ConfirmDialog>
      </Grid>
    </div>

  );
}

export default TableView;