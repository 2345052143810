import React, { useCallback, useEffect, useMemo, useRef } from "react";

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-balham.css';
import 'ag-grid-enterprise';

import { Alert, AlertColor, Collapse, Button, IconButton, Paper, TextField, Tooltip, Typography, Stack, Chip} from "@mui/material";
import { SideBarDef } from "ag-grid-community";

import * as api from 'src/Utility/api';
import { ExchangeOrderType, ITableGridColumnDefinition, MessageTags, TabOrder } from "../../@State/types";
import './Estimates.css';
import MyOrdersFilter from "../Valuation/MyOrders/MyOrdersFilter";
import RestartAltOutlinedIcon from '@mui/icons-material/RestartAltOutlined';
import SaveIcon from '@mui/icons-material/Save';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExcelUpload from "../ExcelUpload/ExcelUpload";
import QualityRatingRenderer from "../CellRenderers/QualityRatingRenderer";
import ConfirmDialog from "src/Common/ConfirmDialog";
import ViewColumnOutlinedIcon from '@mui/icons-material/ViewColumnOutlined';
import ColumnSelection from "../ColumnSelection/ColumnSelection";
import OrderComment from "../OrderSummary/OrderComment/OrderComment";
import AddCommentRenderer from "../CellRenderers/AddCommentRenderer";
import DateTimeRenderer from "../CellRenderers/DateTimeRenderer";
import DatePickerEditor from "../CellEditors/DatePickerEditor";
import ExcludeIncludeOrderRenderer from "../CellRenderers/ExcludeIncludeOrderRenderer";
import { useEffectOnce } from "src/hooks/useEffectOnce";
import { AppContext } from '../../@State/context/AppContext';
import MapViewRenderer from "../Cart/MapViewRenderer";
import ApproveDropDownCellEditor from "../CellEditors/ApproveDropdownRenderer";
import * as eventMessaging from 'src/Utility/Messaging/Messaging';
import ReportNameRenderer from "../CellRenderers/ReportNameRenderer";
import DollarValueRenderer from "../CellRenderers/DollarValueRenderer";
import { columnHeaderTemplate, isJsonString } from "src/Utility/common";
import circleImage from 'src/Utility/Images/loading.gif';
import ChecklistIcon from '@mui/icons-material/Checklist';

function Estimates(props: any) {
    const initialMessage = {
        open: false,
        msg: '',
        severity: 'info'
    };
    const [message, setMessage] = React.useState(initialMessage);

    const [operator, setOperator] = React.useState<string>('');
    const [columns, setColumns] = React.useState<any[]>([]);
    const [estimateData, setEstimateData] = React.useState<any[]>([]);
    const [estimateTempData, setEstimateTempData] = React.useState<any[]>([]);
    const [selectedOrder, setselectedOrder] = React.useState<any[]>([]);
    const [firstSelectedOrderData, setFirstSelectedOrderData] = React.useState<any[]>([]);
    const [apis, setApis] = React.useState<string>("");
    const [reports, setReports] = React.useState<string[]>([]);
    const [cartOrders, SetCartOrders] = React.useState<any[]>([]);
    const [isLoadedFromCart, SetisLoadedFromCart] = React.useState(false);
    const [pivotMode, setPivotMode] = React.useState<boolean>(false);
    const [openConfirmDialog, setOpenConfirmDialog] = React.useState(false);
    const [openSaveConfirmDialog, setOpenSaveConfirmDialog] = React.useState(false);
    const [isUserInEstimate, setIsUserInEstimate] = React.useState(false);
    const [filterEstimateType, setFilterEstimateType] = React.useState<string>('All');
    const filterTypeList = ['All', 'Y', 'N'];
    const [showGif, setShowGif] = React.useState(false);
    const [tablePreferenceList, setTablePreferenceList] = React.useState<any[]>([]);
    const [selectedPreference, setselectedPreference] = React.useState<any>();
    const [columnPreference, setColumnPreference] = React.useState<any[]>([]);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const { pickListDefinitions,
        dataAuditData,
        reportConfigurations,
        CommonAppConfig,
        screenUpdaterEstimates,
        setScreenUpdaterEstimates,
        fetchOrdersWithEstimatesHandler, operatorList, loadInitialOperatorData } = React.useContext(AppContext) as ExchangeOrderType;
    const [columnsPerfOpen, setcolumnsPerfOpen] = React.useState(false);
    const [isEditted, setIsEditted] = React.useState(false);
    const canBeOpen = columnsPerfOpen && Boolean(anchorEl);
    const id = canBeOpen ? 'transition-popper' : undefined;
    const [columnDefinitions, setcolumnDefinitions] = React.useState<any[]>([]);
    const [orderComments, setOrderComments] = React.useState<any[] | null>(null);
    const [estimateDataAudit, setEstimateDataAudit] = React.useState<any[] | null>(null);
    const [refreshInterval, setRefreshInterval] = React.useState<number>(0);
    const [hiddenColumns, setHiddenColumns] = React.useState<string[]>(["ReportDisplayOrder"
        , "ReportYn"
        , "EstimateId"
        , "Info1"
        , "Info2"
        , "Info3"
        , "EffStartDate"
        , "EffEndDate"
        , "CreatedBy"
        , "Comment3"
        , "CreatedDate"
        , "LastModifiedBy"
        , "LastModifiedDate"
        , "LastModifiedBy"
        , "LastModifiedBy"
        , "LastModifiedBy"
        , "LastModifiedBy"]);
    const [commentOpen, setCommentOpen] = React.useState<boolean>(false);

    const displayColumns: any[] = ['WellName', 'DaColName', 'L1', 'L2', 'L3', 'L4', 'L5', 'L6', 'OrderOwnerOperator', 'DataOwnerOperator', 'API', 'DataSet','OasisCostFromAFE',
        'DateOfAcquisition', 'CostFromAFE', 'Depreciation', 'CurrentValue', 'QualityRating', 'OrderId', 'IsIncludedInOrder', 'OrderOwnerProposedValue',
        'DataOwnerProposedValue', 'DataSetFrequency', 'OrderOwnerApproval', 'DataOwnerApproval', 'Comment'];

    const [confirmDialogObject, setConfirmDialogObject] = React.useState<any>({
        title: "",
        body: "",
        okHandler: () => undefined
    });
    const [missingColumns, setMissingColumns] = React.useState<any[]>([]);
    const [dataSetRestriction, setDataSetsRestriction] = React.useState<number>(500);
    const [selectedEstimate, setselectedEstimate] = React.useState<any>(null);
    const [isExecutedOrder, setIsExecutedOrder] = React.useState(false);
    const privateFieldTemplate = `<div class="ag-cell-label-container" role="presentation">
                <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>
                <div ref="eLabel" class="ag-header-cell-label" role="presentation">
                    <div> 
                        <span ref="eText" class="ag-header-cell-text" role="columnheader"></span><p style= "color: red; margin:0px">(proprietary)</p>
                    </div>
                    <span ref="eSortOrder" class="ag-header-icon ag-sort-order ag-hidden"></span>
                    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon ag-hidden"></span>
                    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon ag-hidden"></span>
                    <span ref="eSortMixed" class="ag-header-icon ag-sort-mixed-icon ag-hidden"></span>
                    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon ag-hidden"></span>
                    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>
                </div>
            </div>`;

    const EstimateGridRef: any = useRef();

    const sideBar: SideBarDef = useMemo(() => {
        return {
            toolPanels: [
                {
                    id: 'columns',
                    labelDefault: 'Columns',
                    labelKey: 'columns',
                    iconKey: 'columns',
                    toolPanel: 'agColumnsToolPanel',
                    minWidth: 225,
                    maxWidth: 225,
                    width: 225
                },
                {
                    id: 'filters',
                    labelDefault: 'Filters',
                    labelKey: 'filters',
                    iconKey: 'filter',
                    toolPanel: 'agFiltersToolPanel',
                    minWidth: 270,
                    maxWidth: 270,
                    width: 270,
                },
            ],
            position: 'right',
            defaultToolPanel: 'columns'
        };
    }, []);

    const defaultColDef = useMemo(() => {
        return {
            resizable: true,
            wrapHeaderText: true,
            autoHeaderHeight: true,
            editable: false,
            headerCheckboxSelectionFilteredOnly: true,
            enableCellChangeFlash: true
        };
    }, []);

    const gridOptions = {
        // PROPERTIES
        defaultColDef: defaultColDef,
        pagination: false,
        enableRowGroup: true,
        groupSelectsChildren: true,
        suppressCsvExport: true,
        suppressExcelExport: true,
        suppressCutToClipboard: true,
        suppressCopyRowsToClipboard: true,
        defaultExportParams: {
            columnGroups: true,
            fileName: 'name_of_file'
        },
        autoGroupColumnDef: {
            hide: true,
            cellRendererParams: {
                suppressCount: true,
            },
        },
        enableRangeSelection: true,
        suppressAggFuncInHeader: true,
        groupingShowCounts: false,
        getRowHeight: () => { return 24 }
    }

    useEffect(() => {
        if (operatorList?.length == 0) {
            loadInitialOperatorData(props.loggedInUserMailId);
        }
        if (typeof (operatorList) != "undefined" && operatorList.length > 0) {
            setOperator(operatorList[0]);
            setcolumnsPerfOpen(false);
            loadColumnPreferences();
        }
    }, [operatorList]);

    useEffect(() => {
        if (props.IsSummary && props.selectedReportClicked != '' && props.selectedReportLevel != '') {
            let reportData: any[] = [];
            switch (props.selectedReportLevel) {
                case "L1":
                    reportData = estimateTempData.filter((data: any) => { return data.L1 === props.selectedReportClicked });
                    break;
                case "L2":
                    reportData = estimateTempData.filter((data: any) => { return data.L2 === props.selectedReportClicked });
                    break;
                case "L3":
                    reportData = estimateTempData.filter((data: any) => { return data.L3 === props.selectedReportClicked });
                    break;
                case "L4":
                    reportData = estimateTempData.filter((data: any) => { return data.L4 === props.selectedReportClicked });
                    break;
                case "L5":
                    reportData = estimateTempData.filter((data: any) => { return data.L5 === props.selectedReportClicked });
                    break;
                case "L6":
                    reportData = estimateTempData.filter((data: any) => { return data.L6 === props.selectedReportClicked });
                    break;
            }

            setEstimateData(reportData);
        } else {
            setEstimateData(estimateTempData);
        }

    }, [props.selectedReportClicked]);

    useEffect(() => {
        if (props.tabIndex != TabOrder.Estimate) {
            if (isUserInEstimate) {
                const edittedData = estimateData.filter((data) => { return data.isModified });
                if (edittedData.length > 0) {
                    let confirmDialogObject = {
                        title: "Estimates",
                        body: "You have made some changes. Do you want to Save and Continue?, else click CANCEL",
                        okHandler: saveEditedDataHandler
                    };

                    setConfirmDialogObject(confirmDialogObject);
                    setOpenSaveConfirmDialog(true);
                    setIsUserInEstimate(false);
                }
            }
        } else {
            setIsUserInEstimate(true);
        }
    }, [props.tabIndex]);

    useEffect(() => {
        if (props.selectedCartOrder?.length > 0) {
            setIsExecutedOrder(props.selectedCartOrder[0].OrderStatus === 'Executed');
            SetisLoadedFromCart(true);
            props.setShowProgress(true);
            SetCartOrders(props.selectedCartOrder);
            let order = props.selectedCartOrder[0];
            setFirstSelectedOrderData(order);
            regenerateEstimates(props.selectedCartOrder);
        }
    }, [props.IsEstimate]);

    useEffect(() => {
        EstimateGridRef.current?.api?.onFilterChanged();
    }, [filterEstimateType]);

    useEffect(() => {
        loadEstimateDataHandler();
    }, [operator]);

    const loadEstimateDataHandler = () => {
        if (operator !== '' && operator !== undefined && !isLoadedFromCart) {
            let order = cartOrders.length > 0 ? cartOrders[0].OrderId.toString() : ""
            let dataOwner = cartOrders.length > 0 ? cartOrders[0].RequestedTo.toString() : ""
            loadEstimateData(operator, apis, reports, dataOwner, order);
        }
    }

    useEffect(() => {
        if (selectedPreference != null) {
            setColumnPreference(selectedPreference.PreferenceJson);
        }
        else {
            setInitialColumnPreferenceState();
        }
    }, [selectedPreference]);

    useEffect(() => {
        loadEstimateTable();
    }, [estimateData, pivotMode]);

    useEffect(() => {
        if (selectedOrder.length > 0) {
            loadComments();
        }
    }, [selectedOrder]);

    useEffect(() => {
        if (orderComments && orderComments.length > 0) {
            loadEstimateTable();
        }
    }, [orderComments]);

    useEffect(() => {
        if (CommonAppConfig.length > 0) {
            const commentRefreshConfig = CommonAppConfig
                .filter((element) => {
                    return element.ConfigShortDesc === "Comment_Refresh"
                });
            if (commentRefreshConfig.length > 0) {
                const refreshInterval = parseInt(commentRefreshConfig[0].ConfigValue);
                setRefreshInterval(refreshInterval);
            }

            let noOfdataset = CommonAppConfig.find((temp : any) => {
                return  temp.ConfigShortDesc === "NoOfDataSets" && temp.Operators.includes(operator);
            });

            if(noOfdataset){
                setDataSetsRestriction(noOfdataset.ConfigValue);
            }
        }
    }, [CommonAppConfig]);


    useEffectOnce(() => {
        if (EstimateGridRef !== undefined && EstimateGridRef?.current !== undefined) {
            setTimeout(() => {
                EstimateGridRef?.current?.api?.closeToolPanel();
            }, 200);
        }
    });

    useEffect(() => {
        if (screenUpdaterEstimates && screenUpdaterEstimates?.length > 0 && isEditted == false) {
            if (isJsonString(screenUpdaterEstimates[0])) {
                let estimateChangeObject = JSON.parse(screenUpdaterEstimates[0]);
                if (estimateChangeObject) {
                    updateNodeHandler(estimateChangeObject);
                }
            }
        }
    }, [screenUpdaterEstimates]);

    useEffect(() => {
        applyColumnPreference(columnPreference);
    }, [columnPreference])

    const updateNodeHandler = (estimateObj: any) => {
        if (estimateObj !== null && estimateObj !== undefined) {
            showGifHandler();
            let nodeIndex = estimateData.findIndex(x => x.EstimateId === estimateObj.EstimateId);
            if (nodeIndex >= 0) {
                let rowNode = EstimateGridRef?.current?.api?.getRowNode(nodeIndex);
                rowNode?.setData(estimateObj);
                EstimateGridRef?.current?.api?.redrawRows();

                loadComments();
                setScreenUpdaterEstimates([]);
            }
        }
    }

    const showGifHandler = () => {
        setShowGif(true);
        setTimeout(() => {
            setShowGif(false);
        }, 9000);
    }

    const loadEstimateTable = () => {
        if (estimateData !== undefined && estimateData.length >= 0) {
            let columnDefinitions = constructEstimateColumnDefinitions();
            setcolumnDefinitions(columnDefinitions);
            if (EstimateGridRef !== undefined && EstimateGridRef.current !== undefined) {
                if (EstimateGridRef.current.api !== 'undefined') {
                    EstimateGridRef.current?.api?.setColumnDefs(columnDefinitions);
                    EstimateGridRef.current?.api?.setRowData(estimateData);
                } else {
                    EstimateGridRef.current.props.gridOptions.columnDefs = columnDefinitions;
                    EstimateGridRef.current.props.gridOptions.rowData = estimateData;
                }

                onValidate();
                generatePinnedBottomData();
            } else {
                EstimateGridRef.current?.api?.setColumnDefs([]);
                EstimateGridRef.current?.api?.setRowData([]);
            }
        }
        else {
            EstimateGridRef.current?.api?.setColumnDefs([]);
            EstimateGridRef.current?.api?.setRowData([]);
        }
    }

    const loadComments = () => {
        async function getOrderComments() {
            let getEstimateCommentsUrl = '/OrderManagement/getOrderComments?OrderId='
                + selectedOrder[0];
            await api.API_GET(getEstimateCommentsUrl).then((response) => {
                if (response.data.length > 0) {
                    const _data = response.data;
                    if (orderComments == null) {
                        setOrderComments(_data);
                        loadEstimateTable();
                    }
                    else if (orderComments && _data.length > orderComments?.length) {
                        setOrderComments(_data);
                        loadEstimateTable();
                    }
                } else {
                    setOrderComments([]);
                }
            }).catch(() => {
                setOrderComments([]);
            });
        }

        getOrderComments();
    }

    const regenerateEstimates = async (orderArray: any[]) => {
        const selectedReports: any[] = orderArray.map((element: any) => { return element.SelectedReports });
        const _orderData: any[] = orderArray.map((element: any) => {
            if (element.OrderId == 'N/A') {
                return element.Data
            }
            else {
                if (typeof (element.Data) === 'string') {
                    return JSON.parse(element.Data);
                }
                else {
                    return element.Data;
                }
            }
        });

        const apis: string[] = _orderData?.map((element: any) => { return element.map((b: any) => { return b.API }); });
        const orderIds: any[] = orderArray?.map((element: any) => { return element.OrderId });
        setReports(selectedReports);
        setselectedOrder(orderIds);
        const uniqueValues = getUniqueApi(apis);
        setApis(uniqueValues.toString());
        loadEstimateData(orderArray[0].RequestedBy, uniqueValues.toString(), selectedReports, orderArray[0].RequestedTo, orderIds.toString());
        if (!props.IsSummary) {
            fetchOrdersWithEstimatesHandler();
        }
    }

    const fetchDisplayName = (columnName: string) => {
        let _displayName = columnName;
        let matchingPreferance = [];
        matchingPreferance = columnPreference.filter((element) => { return element.ColumnName == columnName });

        if (matchingPreferance.length > 0) {
            _displayName = matchingPreferance[0].DisplayName;
        }

        return _displayName;
    }


    const constructEstimateColumnDefinitions = () => {
        let columnDefinitions: ITableGridColumnDefinition[] = [];
        const nonEditable = ["API", "WellName", "DataOwnerOperator", "L1", "L2", "L3", "L4", "L5", "L6", "OrderOwnerOperator", "DataSet", "ReportYn", "ReportDisplayOrder", "DataOwnerProposedValue", "OrderId"];
        const rowGroupsColumns = ["L1", "L2", "L3", "L4", "L5", "L6"];
        const pivotColumns = ["API", "DataOwnerOperator"];
        const aggrSumColumns = ["CostFromAFE", "CurrentValue", "OrderOwnerProposedValue", "DataOwnerProposedValue", "OasisCostFromAFE"];
        const aggrAvgColumns = ["QualityRating"];
        const dollarColumns = ["CostFromAFE", "CurrentValue", "OrderOwnerProposedValue", "DataOwnerProposedValue","OasisCostFromAFE"];
        const fixDecimalTwoColumns = ["Depreciation"];

        displayColumns.forEach((column) => {
            if (column.toLowerCase().includes('rating')) {
                let columnDefinition: ITableGridColumnDefinition = {
                    field: column,
                    //type: 'editableColumn',
                    headerName: fetchDisplayName(column),
                    headerTooltip: fetchDisplayName(column),
                    headerComponentParams: columnHeaderTemplate(fetchDisplayName(column)),
                    sortable: true,
                    cellStyle: { textAlign: 'left' },
                    filter: 'agNumberColumnFilter',
                    editable: true,
                    enableRowGroup: true,
                    aggFunc: aggrAvgColumns.includes(column) ? "avg" : "",
                    enablePivot: pivotMode ? true : false,
                    enableValue: true,
                    cellRendererSelector: (params: any) => {
                        if (params.node.rowPinned) {
                            return undefined;
                        } else {
                            // rows that are not pinned 
                            return {
                                component: QualityRatingRenderer,
                                params: {
                                    isReadonly: EstimateGridRef?.current?.props?.pivotMode ? true : false,
                                    onRatingChange: (value: any, rowIndex: any, rowData: any) => {
                                        let _tableData = estimateData;
                                        let index = _tableData.indexOf(rowData);
                                        rowData[column] = value;
                                        _tableData[index] = rowData;
                                        _tableData[index]["isModified"] = true;

                                        if (_tableData[index]["modifiedAttrs"] === undefined) {
                                            _tableData[index]["modifiedAttrs"] = column;
                                        } else {
                                            let modAttrs = _tableData[index]["modifiedAttrs"];
                                            modAttrs = modAttrs + ',' + column;
                                            _tableData[index]["modifiedAttrs"] = modAttrs;
                                        }
                                    }
                                }
                            };
                        }
                    },
                    hide: isColumnHidden(column),
                    cellClass: ['ag-cell-custom'],
                    suppressToolPanel: false
                };

                columnDefinitions.push(columnDefinition);
            } else if (column.toLowerCase().includes('dateofacquisition')) {
                columnDefinitions.push({
                    field: column,
                    //type: 'editableColumn',
                    width: 200,
                    suppressSizeToFit: true,
                    singleClickEdit: false,
                    headerName: fetchDisplayName(column),
                    headerTooltip: fetchDisplayName(column),
                    headerComponentParams: columnHeaderTemplate(fetchDisplayName(column)),
                    cellEditorPopup: true,
                    enableRowGroup: true,
                    editable: true,
                    cellEditor: DatePickerEditor,
                    cellEditorParams: {
                        column: column,
                        onDateValueSelected: (value: any, rowIndex: any, rowData: any) => {
                            let _tableData = estimateData;
                            let index = _tableData.findIndex(i => i.EstimateId === rowData.EstimateId);
                            rowData[column] = value;
                            CalculateCurrentValue(rowData);
                            _tableData[index] = rowData;
                            _tableData[index]["isModified"] = true;
                            updateTotalRowNode();
                        }
                    },
                    cellRendererSelector: (params: any) => {
                        if (params.node.rowPinned || pivotMode) {
                            return undefined;
                        } else {
                            // rows that are not pinned 
                            return {
                                component: DateTimeRenderer,
                                params: {
                                    showTime: false
                                }
                            };
                        }
                    },
                    cellClass: ['ag-cell-custom'],
                    suppressToolPanel: false,
                    hide: isColumnHidden(column),
                    filter: (column.DataType !== "Int32" && column.DataType !== "Int64") ? 'agMultiColumnFilter' : 'agNumberColumnFilter'
                });
            } else if (column.toLowerCase().includes('currentvalue')) {
                let columnDefinition: ITableGridColumnDefinition = {
                    field: column,
                    //type: 'editableColumn',
                    headerName: fetchDisplayName(column),
                    headerTooltip: fetchDisplayName(column),
                    isPropreitary : true,
                    headerComponentParams: {
                        menuIcon: 'fa-bars',
                        template: privateFieldTemplate
                    },
                    cellClass: ['ag-cell-custom'],
                    sortable: true,
                    cellStyle: (params: any) => {
                        if (params.data.IsIncludedInOrder === 'Y' && (params.value === null || params.value === 0)) {
                            return { backgroundColor: '#ffc0cb' };
                        }
                        return null;
                    },
                    pivot: pivotMode && pivotColumns.includes(column) ? true : false,
                    rowGroup: pivotMode && rowGroupsColumns.includes(column) ? true : false,
                    aggFunc: pivotMode ? "sum" : aggrSumColumns.includes(column) ? "currentValueSum" : (aggrAvgColumns.includes(column) ? "avg" : ""),
                    enablePivot: pivotMode ? true : false,
                    enableRowGroup: true,
                    enableValue: true,
                    filter: true,
                    editable: false,
                    hide: isColumnHidden(column),
                    valueFormatter: applyValueFormatting(column)
                };
                columnDefinitions.push(columnDefinition);
            } else if (column.toLowerCase().includes('oasiscostfromafe')) {
                let columnDefinition: ITableGridColumnDefinition = {
                    field: column,
                    //type: 'editableColumn',
                    headerName: fetchDisplayName(column),
                    headerTooltip: fetchDisplayName(column),
                    headerComponentParams: columnHeaderTemplate(fetchDisplayName(column)),
                    cellClass: ['ag-cell-custom'],
                    sortable: true,
                    pivot: pivotMode && pivotColumns.includes(column) ? true : false,
                    rowGroup: pivotMode && rowGroupsColumns.includes(column) ? true : false,
                    aggFunc: pivotMode ? "sum" : aggrSumColumns.includes(column) ? "oasisCostSum" : (aggrAvgColumns.includes(column) ? "avg" : "sum"),
                    enablePivot: pivotMode ? true : false,
                    enableRowGroup: true,
                    enableValue: true,
                    filter: true,
                    editable: false,
                    hide: isColumnHidden(column),
                    valueFormatter: applyValueFormatting(column)
                };

                columnDefinitions.push(columnDefinition);
            }
            else if (column.toLowerCase().includes('costfromafe')) {
                let columnDefinition: ITableGridColumnDefinition = {
                    field: column,
                    //type: 'editableColumn',
                    headerName: fetchDisplayName(column),
                    headerTooltip: fetchDisplayName(column),
                    isPropreitary : true,
                    headerComponentParams: {
                        menuIcon: 'fa-bars',
                        template: privateFieldTemplate
                    },
                    cellClass: ['ag-cell-custom'],
                    sortable: true,
                    pivot: pivotMode && pivotColumns.includes(column) ? true : false,
                    rowGroup: pivotMode && rowGroupsColumns.includes(column) ? true : false,
                    aggFunc: pivotMode ? "sum" : aggrSumColumns.includes(column) ? "costSum" : (aggrAvgColumns.includes(column) ? "avg" : "sum"),
                    enablePivot: pivotMode ? true : false,
                    enableRowGroup: true,
                    enableValue: true,
                    filter: true,
                    editable: !nonEditable.includes(column),
                    hide: isColumnHidden(column),
                    valueFormatter: applyValueFormatting(column)
                };

                columnDefinitions.push(columnDefinition);
            } else if (column.toLowerCase().includes('isincludedinorder')) {
                if (selectedOrder.length > 0) {
                    let columnDefinition: ITableGridColumnDefinition = {
                        field: 'IsIncludedInOrder',
                        headerName: fetchDisplayName('IsIncludedInOrder'),
                        headerTooltip: fetchDisplayName('IsIncludedInOrder'),
                        headerComponentParams: columnHeaderTemplate(fetchDisplayName('IsIncludedInOrder')),
                        sortable: true,
                        width: 120,
                        filter: (column.DataType !== "Int32" && column.DataType !== "Int64") ? 'agMultiColumnFilter' : 'agNumberColumnFilter',
                        //editable: true,
                        pivot: true,
                        enablePivot: pivotMode ? true : false,
                        enableRowGroup: true,
                        enableValue: false,
                        rowGroup: false,
                        hide: false,
                        aggFunc: "",
                        cellRendererSelector: (params: any) => {
                            if (params.node.rowPinned || pivotMode) {
                                return undefined;
                            } else {
                                // rows that are not pinned 
                                return {
                                    component: ExcludeIncludeOrderRenderer,
                                    params: {
                                        IsDisabled: false,
                                        onExcludeInclude: (value: any, rowData: any, applySame: boolean) => {
                                            let _tableData = estimateData;
                                            let index = _tableData.findIndex(i => i.EstimateId === rowData.EstimateId);
                                            if (applySame) {
                                                let applyToAll = false;
                                                EstimateGridRef.current!.api.forEachNodeAfterFilterAndSort((params: any) => {
                                                    let estimateId = params.data?.EstimateId;
                                                    if (estimateId === rowData.EstimateId) {
                                                        applyToAll = true;
                                                    }

                                                    if (applyToAll) {
                                                        let element = _tableData.find(i => i.EstimateId === estimateId);
                                                        element[column] = value;
                                                        element["isModified"] = true;

                                                        if (element["modifiedAttrs"] === undefined) {
                                                            element["modifiedAttrs"] = column;
                                                        } else {
                                                            let modAttrs = element["modifiedAttrs"];
                                                            modAttrs = modAttrs + ',' + column;
                                                            element["modifiedAttrs"] = modAttrs;
                                                        }
                                                    }
                                                });

                                                EstimateGridRef.current?.api?.setRowData(_tableData);
                                            } else {
                                                _tableData[index][column] = value;
                                                _tableData[index]["isModified"] = true;

                                                if (_tableData[index]["modifiedAttrs"] === undefined) {
                                                    _tableData[index]["modifiedAttrs"] = column;
                                                } else {
                                                    let modAttrs = _tableData[index]["modifiedAttrs"];
                                                    modAttrs = modAttrs + ',' + column;
                                                    _tableData[index]["modifiedAttrs"] = modAttrs;
                                                }
                                            }

                                            updateTotalRowNode();
                                        }
                                    }
                                };
                            }
                        },
                        cellClass: ['ag-cell-custom'],
                    };
                    columnDefinitions.push(columnDefinition);
                }
            } else if (column.toLowerCase().includes('dataownerproposedvalue')) {
                if (selectedOrder.length > 0 && cartOrders[0].OrderStatus != "Drafted" && cartOrders[0].OrderStatus != "Pending") {

                    let columnDefinition: ITableGridColumnDefinition = {
                        field: 'DataOwnerProposedValue',
                        //type: 'editableColumn',
                        headerName: fetchDisplayName('DataOwnerProposedValue'),
                        headerTooltip: fetchDisplayName('DataOwnerProposedValue'),
                        headerComponentParams: columnHeaderTemplate(fetchDisplayName('DataOwnerProposedValue')),
                        cellClass: ['ag-cell-custom'],
                        sortable: true,
                        pivot: pivotMode && pivotColumns.includes('DataOwnerProposedValue') ? true : false,
                        rowGroup: pivotMode && rowGroupsColumns.includes('DataOwnerProposedValue') ? true : false,
                        aggFunc: pivotMode ? "sum" : aggrSumColumns.includes('DataOwnerProposedValue') ? "proposedValuationSum" : (aggrAvgColumns.includes('DataOwnerProposedValue') ? "avg" : ""),
                        enablePivot: pivotMode ? true : false,
                        enableRowGroup: true,
                        enableValue: true,
                        filter: true,
                        editable: false,
                        hide: isColumnHidden('DataOwnerProposedValue'),
                        valueFormatter: applyValueFormatting('DataOwnerProposedValue')
                    };
                    columnDefinitions.push(columnDefinition);
                }
            } else if (column.toLowerCase().includes('orderownerproposedvalue')) {
                if (selectedOrder.length > 0) {
                    let columnDefinition1: ITableGridColumnDefinition = {
                        field: 'OrderOwnerProposedValue',
                        //type: 'editableColumn',
                        headerName: fetchDisplayName('OrderOwnerProposedValue'),
                        headerTooltip: fetchDisplayName('OrderOwnerProposedValue'),
                        headerComponentParams: columnHeaderTemplate(fetchDisplayName('OrderOwnerProposedValue')),
                        cellClass: ['ag-cell-custom'],
                        sortable: true,
                        enableRowGroup: true,
                        pivot: pivotMode && pivotColumns.includes('OrderOwnerProposedValue') ? true : false,
                        rowGroup: pivotMode && rowGroupsColumns.includes('OrderOwnerProposedValue') ? true : false,
                        aggFunc: pivotMode ? "sum" : aggrSumColumns.includes('OrderOwnerProposedValue') ? "orderownerValueSum" : (aggrAvgColumns.includes('OrderOwnerProposedValue') ? "avg" : ""),
                        enablePivot: pivotMode ? true : false,
                        enableValue: true,
                        filter: true,
                        editable: true,
                        cellStyle: (params: any) => {
                            if (params.data.IsIncludedInOrder === 'Y' && (params.value === null || params.value === 0)) {
                                return { backgroundColor: '#ffc0cb' };
                            }
                            return null;
                        },
                        cellRendererSelector: (params: any) => {
                            if (params.node.rowPinned || pivotMode) {
                                return undefined;
                            } else {
                                // rows that are not pinned 
                                return {
                                    component: DollarValueRenderer,
                                    params: {
                                        ScaleValues: (applySame: boolean, scalePercent: number, applyRound: boolean, value: number, rowData: any,scaleColumn : any) => {
                                            let _tableData = estimateData;
                                            let index = _tableData.findIndex(i => i.EstimateId === rowData.EstimateId);
                                            let currentVal = rowData[scaleColumn];
                                            let newValue = applyRound ? Math.round(currentVal + (currentVal * (scalePercent / 100))) : currentVal + (currentVal * (scalePercent / 100));
                                            if (applySame) {
                                                let applyToAll = false;
                                                EstimateGridRef.current!.api.forEachNodeAfterFilterAndSort((params: any) => {
                                                    let estimateId = params.data?.EstimateId;
                                                    if (estimateId === rowData.EstimateId) {
                                                        applyToAll = true;
                                                    }

                                                    if (applyToAll) {
                                                        let element = _tableData.find(i => i.EstimateId === estimateId);
                                                        if(element) {
                                                            let currentVal = element[scaleColumn];
                                                            if (element["IsIncludedInOrder"].toLowerCase() == "y" && currentVal != null) {
                                                                let newValue = applyRound ? Math.round(currentVal + (currentVal * (scalePercent / 100))) : currentVal + (currentVal * (scalePercent / 100));
    
                                                                element[column] = newValue;
                                                                element["isModified"] = true;
    
                                                                if (element["modifiedAttrs"] === undefined) {
                                                                    element["modifiedAttrs"] = column;
                                                                } else {
                                                                    let modAttrs = element["modifiedAttrs"];
                                                                    modAttrs = modAttrs + ',' + column;
                                                                    element["modifiedAttrs"] = modAttrs;
                                                                }
                                                            }
                                                        }
                                                    }
                                                });
                                            } else {
                                                if (index > -1) {
                                                    _tableData[index][column] = newValue;
                                                    _tableData[index]["isModified"] = true;

                                                    if (_tableData[index]["modifiedAttrs"] === undefined) {
                                                        _tableData[index]["modifiedAttrs"] = column;
                                                    } else {
                                                        let modAttrs = _tableData[index]["modifiedAttrs"];
                                                        modAttrs = modAttrs + ',' + column;
                                                        _tableData[index]["modifiedAttrs"] = modAttrs;
                                                    }
                                                }
                                            }
                                            EstimateGridRef.current?.api?.setRowData(_tableData);
                                            updateTotalRowNode();
                                            onValidate();
                                        }
                                    }
                                };
                            }
                        },
                        hide: isColumnHidden('OrderOwnerProposedValue'),
                        valueFormatter: applyValueFormatting('OrderOwnerProposedValue')
                    };
                    columnDefinitions.push(columnDefinition1);
                }
            } else if (column.toLowerCase() === 'datasetfrequency') {
                if (selectedOrder.length > 0) {

                    const pickListColumnDefinition = handlePickListConstruction(column, column, true);
                    if (pickListColumnDefinition === undefined || pickListColumnDefinition === false) {
                    } else {
                        pickListColumnDefinition.headerName = column;
                        pickListColumnDefinition.headerTooltip = column;
                        columnDefinitions.push(pickListColumnDefinition);
                    }
                }
            } else if (column.toLowerCase().includes('orderownerapproval')) {
                if (selectedOrder.length > 0 && cartOrders[0].OrderStatus != "Drafted" && cartOrders[0].OrderStatus != "Pending") {

                    const pickListColumnDefinition = handlePickListConstruction(column, "Approval", true);
                    if (pickListColumnDefinition === undefined || pickListColumnDefinition === false) {
                    } else {
                        pickListColumnDefinition.headerName = column;
                        pickListColumnDefinition.headerTooltip = column;
                        columnDefinitions.push(pickListColumnDefinition);
                    }
                }
            } else if (column.toLowerCase().includes('dataownerapproval')) {
                if (selectedOrder.length > 0 && cartOrders[0].OrderStatus != "Drafted" && cartOrders[0].OrderStatus != "Pending") {
                    let columnDefinition: ITableGridColumnDefinition = {
                        field: 'DataOwnerApproval',
                        //type: 'editableColumn',
                        headerName: fetchDisplayName('DataOwnerApproval'),
                        headerTooltip: fetchDisplayName('DataOwnerApproval'),
                        headerComponentParams: columnHeaderTemplate(fetchDisplayName('DataOwnerApproval')),
                        cellClass: ['ag-cell-custom'],
                        cellStyle: { textAlign: 'left' },
                        sortable: true,
                        pivot: pivotMode && pivotColumns.includes('DataOwnerApproval') ? true : false,
                        rowGroup: pivotMode && rowGroupsColumns.includes('DataOwnerApproval') ? true : false,
                        enablePivot: pivotMode ? true : false,
                        filter: true,
                        editable: false,
                        hide: isColumnHidden('DataOwnerApproval'),
                    };
                    columnDefinitions.push(columnDefinition);
                }
            } else if (column.toLowerCase().includes('orderid')) {
                if (selectedOrder.length > 0) {
                    let columnDefinition: ITableGridColumnDefinition = {
                        field: column,
                        //type: 'editableColumn',
                        cellStyle: { textAlign: 'left' },
                        headerName: fetchDisplayName(column),
                        headerTooltip: fetchDisplayName(column),
                        headerComponentParams: columnHeaderTemplate(fetchDisplayName(column)),
                        cellClass: ['ag-cell-custom'],
                        sortable: true,
                        enableRowGroup: false,
                        pivot: pivotMode && pivotColumns.includes(column) ? true : false,
                        rowGroup: pivotMode && rowGroupsColumns.includes(column) ? true : false,
                        aggFunc: aggrSumColumns.includes(column) ? "sum" : (aggrAvgColumns.includes(column) ? "avg" : ""),
                        enablePivot: pivotMode ? true : false,
                        filter: true,
                        editable: !nonEditable.includes(column),
                        hide: isColumnHidden(column),
                        valueFormatter: applyValueFormatting(column)
                    };
                    columnDefinitions.push(columnDefinition);
                }
            } else if (column.toLowerCase() === 'dataset') {
                let columnDefinition: ITableGridColumnDefinition = {
                    field: 'ReportName',
                    //type: 'editableColumn',
                    headerName: fetchDisplayName(column),
                    headerTooltip: fetchDisplayName(column),
                    headerComponentParams: columnHeaderTemplate(fetchDisplayName(column)),
                    cellClass: ['ag-cell-custom'],
                    sortable: true,
                    cellStyle: { textAlign: 'left' },
                    cellRendererSelector: (params: any) => {
                        if (params.node.rowPinned || pivotMode) {
                            return undefined;
                        } else {
                            // rows that are not pinned 
                            return {
                                component: ReportNameRenderer,
                                params: {
                                    ShowInfo: true,
                                    DataAuditData: estimateDataAudit,
                                    ReportConfig: reportConfigurations
                                }
                            };
                        }
                    },
                    enableRowGroup: true,
                    pivot: pivotMode && pivotColumns.includes(column) ? true : false,
                    rowGroup: pivotMode && rowGroupsColumns.includes(column) ? true : false,
                    aggFunc: aggrSumColumns.includes(column) ? "sum" : (aggrAvgColumns.includes(column) ? "avg" : ""),
                    enablePivot: pivotMode ? true : false,
                    filter: true,
                    editable: !nonEditable.includes(column),
                    hide: isColumnHidden(column),
                    valueFormatter: applyValueFormatting(column)
                };
                columnDefinitions.push(columnDefinition);

            } else if (column.toLowerCase().includes('depreciation')) {
                let columnDefinition: ITableGridColumnDefinition = {
                    field: column,
                    //type: 'editableColumn',
                    headerName: fetchDisplayName(column),
                    headerTooltip: fetchDisplayName(column),
                    isPropreitary : true,
                    headerComponentParams: {
                        menuIcon: 'fa-bars',
                        template: privateFieldTemplate
                    },
                    cellClass: ['ag-cell-custom'],
                    sortable: true,
                    enableRowGroup: true,
                    pivot: pivotMode && pivotColumns.includes(column) ? true : false,
                    rowGroup: pivotMode && rowGroupsColumns.includes(column) ? true : false,
                    aggFunc: aggrSumColumns.includes(column) ? "sum" : (aggrAvgColumns.includes(column) ? "avg" : ""),
                    enablePivot: pivotMode ? true : false,
                    filter: true,
                    editable: !nonEditable.includes(column),
                    hide: isColumnHidden(column),
                    valueFormatter: applyValueFormatting(column)
                };
                columnDefinitions.push(columnDefinition);
            } else if (column.toLowerCase().includes('l6')
                || column.toLowerCase().includes('l5')
                || column.toLowerCase().includes('l4')
                || column.toLowerCase().includes('l3')
                || column.toLowerCase().includes('l2')
                || column.toLowerCase().includes('l1')) {
                let columnDefinition: ITableGridColumnDefinition = {
                    field: column,
                    //type: 'editableColumn',
                    headerName: fetchDisplayName(column),
                    headerTooltip: fetchDisplayName(column),
                    headerComponentParams: columnHeaderTemplate(fetchDisplayName(column)),
                    cellClass: ['ag-cell-custom'],
                    cellRenderer: ReportNameRenderer,
                    cellRendererParams: {
                        ShowInfo: false,
                        DataAuditData: estimateDataAudit,
                        ReportConfig: reportConfigurations
                    },
                    sortable: true,
                    cellStyle: { textAlign: 'left' },
                    enableRowGroup: true,
                    pivot: pivotMode && pivotColumns.includes(column) ? true : false,
                    rowGroup: pivotMode && rowGroupsColumns.includes(column) ? true : false,
                    aggFunc: aggrSumColumns.includes(column) ? "sum" : (aggrAvgColumns.includes(column) ? "avg" : ""),
                    enablePivot: pivotMode ? true : false,
                    filter: true,
                    editable: !nonEditable.includes(column),
                    hide: isColumnHidden(column),
                    valueFormatter: applyValueFormatting(column)
                };
                columnDefinitions.push(columnDefinition);
            } else if (column.toLowerCase().includes('comment')) {
                if (selectedOrder.length > 0) {
                    columnDefinitions.unshift({
                        field: "Comment",
                        //type: 'editableColumn',
                        width: 120,
                        headerName: fetchDisplayName("Comment"),
                        headerTooltip: fetchDisplayName("Comment"),
                        headerComponentParams: columnHeaderTemplate(fetchDisplayName("Comment")),
                        tooltipField: "Comment",
                        cellClass: ['ag-cell-custom'],
                        sortable: true,
                        rowGroup: false,
                        hide: isColumnHidden("Comment"),
                        aggFunc: "",
                        filter: 'agTextColumnFilter',
                        cellRendererSelector: (params: any) => {
                            if (params.node.rowPinned || pivotMode) {
                                return undefined;
                            } else {
                                // rows that are not pinned 
                                return {
                                    component: AddCommentRenderer,
                                    params: {
                                        setSelectedEstimate: (selectedValuation: any) => { setselectedEstimate(selectedValuation) },
                                        orderComments: orderComments,
                                        setCommentOpen: setCommentOpen
                                    }
                                };
                            }
                        },
                    });
                }
            } else {
                let columnDefinition: ITableGridColumnDefinition = {
                    field: column,
                    //type: 'editableColumn',
                    headerName: fetchDisplayName(column),
                    headerTooltip: fetchDisplayName(column),
                    headerComponentParams: columnHeaderTemplate(fetchDisplayName(column)),
                    cellClass: ['ag-cell-custom'],
                    sortable: true,
                    cellStyle: { textAlign: 'left' },
                    enableRowGroup: true,
                    pivot: pivotMode && pivotColumns.includes(column) ? true : false,
                    rowGroup: pivotMode && rowGroupsColumns.includes(column) ? true : false,
                    aggFunc: aggrSumColumns.includes(column) ? "sum" : (aggrAvgColumns.includes(column) ? "avg" : ""),
                    enablePivot: pivotMode ? true : false,
                    filter: true,
                    editable: !nonEditable.includes(column),
                    hide: isColumnHidden(column),
                    valueFormatter: applyValueFormatting(column)
                };
                columnDefinitions.push(columnDefinition);
            }
        });

        columnDefinitions = rearrangeColumnsBasedOnPreference(columnDefinitions, []);
        let hiddenItem = columnDefinitions.filter((elem: any) => { return !elem.hide });
        hiddenItem[0].headerCheckboxSelection = true;
        hiddenItem[0].checkboxSelection = true;
        return columnDefinitions;
    }

    const handlePickListConstruction = (columnName: string, pickListColumnName: string, isColumnEdittable: boolean) => {
        if (pickListDefinitions !== undefined && pickListDefinitions.length > 0) {
            const pickListMatch = pickListDefinitions
                .filter((ele: any) => {
                    return ele.ColumnName.toLowerCase()
                        === pickListColumnName.toLowerCase() &&
                        ele.SchemaName === 'orders' &&
                        ele.TableName === 'Estimate'
                });
            if (pickListMatch.length > 0) {
                if (pickListMatch[0].ListValues[0] === '[') {

                    const tradeType = cartOrders.map((element: any) => { return element.TradeType });

                    const pickListColumnDefinition = {
                        field: columnName,
                        //type: 'editableColumn',
                        headerName: fetchDisplayName(columnName),
                        headerTooltip: fetchDisplayName(columnName),
                        headerComponentParams: columnHeaderTemplate(fetchDisplayName(columnName)),
                        sortable: true,
                        filter: true,
                        cellEditorPopup: true,
                        singleClickEdit: false,
                        suppressSizeToFit: true,
                        editable: isColumnEdittable,
                        cellStyle: (params: any) => {
                            if (params.data.IsIncludedInOrder === 'Y' && (params.data.DataOwnerProposedValue !== '' && params.data.DataOwnerProposedValue > 0 &&    (params.value === null || params.value === ''))) {
                                return { backgroundColor: '#ffc0cb', textAlign: 'left' };
                            }
                            return { textAlign: 'left' };
                        },
                        cellRendererSelector: (params: any) => {
                            if (params.node.rowPinned || pivotMode) {
                                return undefined;
                            } else {
                                // rows that are not pinned 
                                return {
                                    component: ApproveDropDownCellEditor,
                                    params: {
                                        IsRentalOrPurchaseOrder : tradeType.includes('Rental') || tradeType.includes('Purchase'),
                                        DropDownOptionsJSON: pickListMatch[0].ListValues,
                                        onDropDownValueSelected: (value: any, rowData: any, applySameBelow: boolean, CopyValues : boolean) => {
                                            const rowIndex = estimateData.findIndex((element) => element.EstimateId === rowData.EstimateId);
                                            let _tableData = estimateData;

                                            if (applySameBelow) {
                                                let applyToAll = false;
                                                EstimateGridRef.current!.api.forEachNodeAfterFilterAndSort((params: any) => {
                                                    let estimateId = params.data?.EstimateId;
                                                    if (estimateId === rowData.EstimateId) {
                                                        applyToAll = true;
                                                    }

                                                    if (applyToAll) {
                                                        let element = _tableData.find(i => i.EstimateId === estimateId);
                                                        if (element["IsIncludedInOrder"].toLowerCase() == "y") {
                                                            element[columnName] = value;
                                                            element["isModified"] = true;

                                                            if (element["modifiedAttrs"] === undefined) {
                                                                element["modifiedAttrs"] = columnName;
                                                            } else {
                                                                let modAttrs = element["modifiedAttrs"];
                                                                modAttrs = modAttrs + ',' + columnName;
                                                                element["modifiedAttrs"] = modAttrs;
                                                            }

                                                            if(columnName.toLowerCase().includes('orderownerapproval') &&  value.toUpperCase() == 'ACCEPT'){
                                                                element["DataOwnerApproval"] = value;
                                                                element["isModified"] = true;
    
                                                                if (element["modifiedAttrs"] === undefined) {
                                                                    element["modifiedAttrs"] = "DataOwnerApproval";
                                                                } else {
                                                                    let modAttrs = element["modifiedAttrs"];
                                                                    modAttrs = modAttrs + ',' + "DataOwnerApproval";
                                                                    element["modifiedAttrs"] = modAttrs;
                                                                }
                                                            } 

                                                            if(CopyValues) {
                                                                element["OrderOwnerProposedValue"] = element["DataOwnerProposedValue"];
                                                                element["isModified"] = true;
    
                                                                if (element["modifiedAttrs"] === undefined) {
                                                                    element["modifiedAttrs"] = "OrderOwnerProposedValue";
                                                                } else {
                                                                    let modAttrs = element["modifiedAttrs"];
                                                                    modAttrs = modAttrs + ',' + "OrderOwnerProposedValue";
                                                                    element["modifiedAttrs"] = modAttrs;
                                                                }
                                                            }
                                                        }
                                                    }
                                                });

                                                EstimateGridRef.current?.api?.setRowData(_tableData);
                                            } else {
                                                _tableData[rowIndex][columnName] = value;
                                                _tableData[rowIndex]["isModified"] = true;

                                                if (_tableData[rowIndex]["modifiedAttrs"] === undefined) {
                                                    _tableData[rowIndex]["modifiedAttrs"] = columnName;
                                                } else {
                                                    let modAttrs = _tableData[rowIndex]["modifiedAttrs"];
                                                    modAttrs = modAttrs + ',' + columnName;
                                                    _tableData[rowIndex]["modifiedAttrs"] = modAttrs;
                                                }

                                                if(columnName.toLowerCase().includes('orderownerapproval') &&  value.toUpperCase() == 'ACCEPT'){
                                                    _tableData[rowIndex]["DataOwnerApproval"] = value;
                                                    _tableData[rowIndex]["isModified"] = true;

                                                    if (_tableData[rowIndex]["modifiedAttrs"] === undefined) {
                                                        _tableData[rowIndex]["modifiedAttrs"] = "DataOwnerApproval";
                                                    } else {
                                                        let modAttrs = _tableData[rowIndex]["modifiedAttrs"];
                                                        modAttrs = modAttrs + ',' + "DataOwnerApproval";
                                                        _tableData[rowIndex]["modifiedAttrs"] = modAttrs;
                                                    }
                                                } 

                                                if(CopyValues){
                                                    _tableData[rowIndex]["OrderOwnerProposedValue"] = _tableData[rowIndex]["DataOwnerProposedValue"];
                                                    _tableData[rowIndex]["isModified"] = true;

                                                    if (_tableData[rowIndex]["modifiedAttrs"] === undefined) {
                                                        _tableData[rowIndex]["modifiedAttrs"] = "OrderOwnerProposedValue";
                                                    } else {
                                                        let modAttrs = _tableData[rowIndex]["modifiedAttrs"];
                                                        modAttrs = modAttrs + ',' + "OrderOwnerProposedValue";
                                                        _tableData[rowIndex]["modifiedAttrs"] = modAttrs;
                                                    }
                                                }

                                                EstimateGridRef.current?.api?.setRowData(_tableData);
                                            }

                                            onValidate();
                                        }
                                    }
                                };
                            }
                        },
                        suppressToolPanel: false,
                        cellClass: ['ag-cell-custom']
                    };

                    return pickListColumnDefinition;
                }
            }
        }
        else {
            return false;
        }
    }

    const aggFuncs = {
        'currentValueSum': (params: any) => {
            let data = params.rowNode?.childrenAfterGroup;
            let sum = 0;
            data.forEach((value: any) => (sum += (value?.data?.IsIncludedInOrder === 'Y') ? value?.data?.CurrentValue : 0));
            return sum;
        },
        'costSum': (params: any) => {
            let data = params.rowNode?.childrenAfterGroup;
            let sum = 0;
            data.forEach((value: any) => (sum += !(value?.data?.IsIncludedInOrder === 'Y') ? 0 : isNaN(value?.data?.CostFromAFE) ? 0 : Number(value?.data?.CostFromAFE)));
            return sum;
        },
        'oasisCostSum': (params: any) => {
            let data = params.rowNode?.childrenAfterGroup;
            let sum = 0;
            data.forEach((value: any) => (sum += !(value?.data?.IsIncludedInOrder === 'Y') ? 0 : isNaN(value?.data?.OasisCostFromAFE) ? 0 : Number(value?.data?.OasisCostFromAFE)));
            return sum;
        },
        'proposedValuationSum': (params: any) => {
            let data = params.rowNode?.childrenAfterGroup;
            let sum = 0;
            data.forEach((value: any) => (sum += !(value?.data?.IsIncludedInOrder === 'Y') ? 0 : isNaN(value?.data?.DataOwnerProposedValue) ? 0 : Number(value?.data?.DataOwnerProposedValue)));
            return sum;
        },
        'orderownerValueSum': (params: any) => {
            let data = params.rowNode?.childrenAfterGroup;
            let sum = 0;
            data.forEach((value: any) => (sum += !(value?.data?.IsIncludedInOrder === 'Y') ? 0 : isNaN(value?.data?.OrderOwnerProposedValue) ? 0 : Number(value?.data?.OrderOwnerProposedValue)));
            return sum;
        }
    };

    function updateTotalRowNode() {
        generatePinnedBottomData();
        let totalRowNode: any;
        let costfromafe = 0;
        let oasiscostfromafe = 0;
        let currentValueSum = 0;
        let proposedvalueSum = 0;
        let dataOwnervalueSum = 0;

        EstimateGridRef.current?.api?.rowModel?.rowsToDisplay?.forEach((node: any) => {
            if (node.footer || node.rowPinned) {
                totalRowNode = node;
            } else {
                currentValueSum += node?.data?.IsIncludedInOrder === 'Y' ? node?.data?.CurrentValue : 0;
                costfromafe += !(node?.data?.IsIncludedInOrder === 'Y') ? 0 : isNaN(node?.data?.CostFromAFE) ? 0 : Number(node?.data?.CostFromAFE);
                oasiscostfromafe += !(node?.data?.IsIncludedInOrder === 'Y') ? 0 : isNaN(node?.data?.OasisCostFromAFE) ? 0 : Number(node?.data?.OasisCostFromAFE);
                proposedvalueSum += !(node?.data?.IsIncludedInOrder === 'Y') ? 0 : isNaN(node?.data?.OrderOwnerProposedValue) ? 0 : Number(node?.data?.OrderOwnerProposedValue);
                dataOwnervalueSum += !(node?.data?.IsIncludedInOrder === 'Y') ? 0 : isNaN(node?.data?.DataOwnerProposedValue) ? 0 : Number(node?.data?.DataOwnerProposedValue);
            }
        });

        totalRowNode?.setDataValue('CostFromAFE', costfromafe);
        totalRowNode?.setDataValue('OasisCostFromAFE', oasiscostfromafe);

        totalRowNode?.setDataValue('CurrentValue', currentValueSum);
        if (selectedOrder.length > 0) {
            totalRowNode?.setDataValue('OrderOwnerProposedValue', proposedvalueSum);
            totalRowNode?.setDataValue('DataOwnerProposedValue', dataOwnervalueSum);
        }
    }

    const applyValueFormatting = (column: any) => {
        const dollarColumns: any[] = ["CostFromAFE", "CurrentValue", "OrderOwnerProposedValue", "DataOwnerProposedValue","OasisCostFromAFE"];
        const fixDecimalTwoColumns: any[] = [];
        const percentageSuffixColumns: any[] = ["Depreciation"];

        if (dollarColumns.includes(column)) {
            return (params: any) => currencyFormatter(params.value, '$');
        } else if (fixDecimalTwoColumns.includes(column)) {
            return (params: any) => parseFloat(params.value)?.toFixed(2);
        } else if (percentageSuffixColumns.includes(column)) {
            return (params: any) => percentageFormatter(params.value);
        }
    }

    const formatHeaderName = (value: string) => {
        return value;
    }

    const postSortRows = (params: any) => {
        let rowNodes = params.nodes;
        let nextInsertPos = 0;
        rowNodes.forEach((element: any) => {
            let index = rowNodes.indexOf(element);
            const isIncluded = element?.data?.IsIncludedInOrder;
            if (isIncluded === 'Y') {
                rowNodes.splice(nextInsertPos, 0, rowNodes?.splice(index, 1)[0]);
                nextInsertPos++;
            }
        });
    }

    function currencyFormatter(currency: number, sign: string) {
        if (currency && currency !== null && currency?.toString() !== '' && currency !== undefined && !isNaN(currency)) {
            let sansDec = parseFloat(currency?.toString())?.toFixed(2);
            let formatted = sansDec?.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
            return sign + `${formatted}`;
        }
    }

    function percentageFormatter(currency: number) {
        if (currency !== null && currency !== undefined && currency.toString() !== '') {
            let formatted = parseFloat(currency.toString())?.toFixed(2);
            return `${formatted}` + '%';
        }
    }

    const loadColumnPreferences = () => {
        async function loadColumnPreferencesData() {
            setTablePreferenceList([]);

            let getDataUrl = '/Common/GetColumnPreferencesByTable?schema=ESTIMATES&table=ESTIMATES';
            await api.API_GET(getDataUrl).then((response) => {
                if (response?.data?.length > 0) {
                    let globalPreference = response?.data.filter((ele: any) => { return ele.PreferenceName.toLowerCase().includes('global') });
                    let defaultPreference = response?.data.filter((ele: any) => { return ele.UserMailId == props.loggedInUserMailId && ele.IsDefault });
                    let preferences = response?.data.filter((ele: any) => {
                        return ((ele.UserMailId === props.loggedInUserMailId) ||
                            (ele.UserMailId !== props.loggedInUserMailId && ele.IsPublic))
                    });

                    setTablePreferenceList(preferences);

                    if (defaultPreference.length > 0) {
                        setselectedPreference(defaultPreference[0]);
                    }
                    else if (globalPreference.length > 0) {
                        setselectedPreference(globalPreference[0]);
                    }
                    else if (preferences.length > 0) {
                        //setselectedPreference(preferences[0]);
                    }
                }
            }).catch(error => {
                console.log("error in post call", error)
            }).finally(() => {
            });
        };

        loadColumnPreferencesData();
    }

    const handleClickColumnsOpen = (event: React.MouseEvent<HTMLElement>) => {
        setInitialColumnPreferenceState();
        setAnchorEl(event.currentTarget);
        setcolumnsPerfOpen(!columnsPerfOpen);
    };

    const setInitialColumnPreferenceState = () => {
        let columnPreference: any[] = [];
        if (selectedPreference == null) {
            displayColumns.forEach((element: any) => {
                columnPreference.push({
                    ColumnName: element,
                    Enable: true,
                    AvailableOrDisplayed: "D" // A for Available columns , D for Displayed columns
                });
            });

            if (columnPreference.length > 0) {
                let preference: any = {
                    PreferenceJson: columnPreference,
                    PreferenceId: 0,
                    PreferenceName: '',
                    SchemaName: 'ESTIMATES',
                    TableName: 'ESTIMATES',
                    UserMailId: props.loggedInUserMailId,
                    IsPublic: true,
                    IsDefault: false,
                    CreatedBy: props.loggedInUserMailId
                };

                setselectedPreference(preference);
            }
        }
    }

    const applyDisplayColumnNames = (_columnPreference: any[], colDefs: any) => {
        colDefs.forEach((colDef: any) => {
            let colPref = _columnPreference.filter((ele) => { return ele.ColumnName == colDef.field });
            if (colPref.length > 0) {
                colDef.headerName = colPref[0].DisplayName;
                colDef.headerComponentParams = columnHeaderTemplate(colPref[0].DisplayName, colDef.isPropreitary);
            }
        });

        return colDefs;
    }

    const applyColumnPreferenceHandler = async (selectedPreferene: any) => {
        setselectedPreference(selectedPreferene);
        applyColumnPreference(selectedPreferene.PreferenceJson);
    }

    const isColumnHidden = (columnName: string) => {
        if (columnPreference != null) {
            let colMatch = columnPreference.filter((element: any) => {
                return element.ColumnName === columnName;
            });
            if (colMatch.length > 0) {
                return !colMatch[0].Enable;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }

    const applyColumnPreference = (_columnPreference: any[]) => {
        let updatedColumnDefs = rearrangeColumnsBasedOnPreference([...columnDefinitions], _columnPreference);
        _columnPreference.forEach((colPreference: any) => {
            let columnDefMatch = updatedColumnDefs.filter((element: any) => { return element.field.toLowerCase().match(colPreference.ColumnName.toLowerCase()) || element.headerName.trim().toLowerCase().match(colPreference.ColumnName.toLowerCase()) });
            if (columnDefMatch.length > 0) {
                if (colPreference.Enable === false) {
                    updatedColumnDefs[updatedColumnDefs.indexOf(columnDefMatch[0])].hide = true;
                } else {
                    updatedColumnDefs[updatedColumnDefs.indexOf(columnDefMatch[0])].hide = false;
                }
            }
        });

        updatedColumnDefs.forEach((elem: any) => {
            elem.headerCheckboxSelection = false;
            elem.checkboxSelection = false;
        });

        let visibleItem = updatedColumnDefs.filter((elem: any) => { return !elem.hide });
        if (visibleItem && visibleItem.length > 0){
            visibleItem[0].headerCheckboxSelection = true;
            visibleItem[0].checkboxSelection = true;
        }

        EstimateGridRef.current?.columnApi?.columnModel?.setColumnDefs(applyDisplayColumnNames(_columnPreference, updatedColumnDefs));
        EstimateGridRef.current?.api?.setRowData(estimateData);
    }

    const rearrangeColumnsBasedOnPreference = (columnDefinitions: any[], _columnPreference: any[]) => {
        let _reArrangedColumnDefinitions: any[] = [];
        let colPrefs = _columnPreference.length > 0 ? _columnPreference : [...columnPreference];

        if (colPrefs != null && colPrefs.length > 0) {
            colPrefs.forEach((preference: any) => {
                const columnDefinitionObj = columnDefinitions.filter((ele: any) => { return ele.field.toLowerCase() === preference.ColumnName.toLowerCase() || ele.headerName.trim().toLowerCase() === preference.ColumnName.toLowerCase() });
                if (columnDefinitionObj.length > 0) {
                    _reArrangedColumnDefinitions.push(columnDefinitionObj[0]);
                }
            });

            const columnsNotInPreference = columnDefinitions.filter(o => !colPrefs.some(({ ColumnName }) => o.field.toLowerCase() === ColumnName.toLowerCase() || o.headerName.trim().toLowerCase() === ColumnName.toLowerCase()));
            columnsNotInPreference.forEach((column: any) => {
                _reArrangedColumnDefinitions.push(column);
            });
        }

        return _reArrangedColumnDefinitions.length > 0 ? _reArrangedColumnDefinitions : columnDefinitions;
    }

    const loadEstimateData = (operator: any, apis: any, reports: any, dataOwner: any, orderId: any) => {

        async function getEstimatesData() {
            let getEstimateDataUrl = '/OrderManagement/getOrderEstimates?_operator=' + operator;
            if (operator !== "" && apis !== "") {
                getEstimateDataUrl = getEstimateDataUrl + "&apis=" + apis.toString() + '&reports=' + reports + '';
            }
            if (dataOwner != "") {
                getEstimateDataUrl = getEstimateDataUrl + "&dataOwner=" + dataOwner;
            }
            if (orderId != "") {
                getEstimateDataUrl = getEstimateDataUrl + "&orderId=" + orderId;
            }

            await api.API_GET(getEstimateDataUrl).then((response) => {
                if (response.data.length > 0) {
                    const _data = response.data
                        .filter((element: any) => { return element.ReportYn === "Y" })
                        .sort((x: any, y: any) => { return ((y.IsIncludedInOrder === 'Y' ? 1 : 0) - (x.IsIncludedInOrder === 'Y' ? 1 : 0) || x.Report_Display_order) });
                    const unique = _data.filter((item: any, i: any, arr: any) => {
                        return arr.indexOf(arr.find((t: any) => t.API === item.API)) === i;
                    });
                    const auditInfo = dataAuditData.filter(o => unique.some((a: any) => o.API.toLowerCase().match(a.API.toLowerCase())));

                    setEstimateDataAudit(auditInfo);
                    setColumns(Object.keys(response.data[0]));
                    setEstimateData(_data);
                    setEstimateTempData(_data);
                    if (props.IsSummary) {
                        props.setSelectedEstValData(_data);
                        props.setIsDataModified();
                        setFilterEstimateType('Y');
                    }
                } else {
                    setEstimateData([]);
                }

                if (orderId != "") {
                    api.LOG_MESSAGE(["Page : Estimate", "Action : Estimate Data Loaded for OrderId : " + orderId], { 'UserName': props.loggedInUser });
                }
                else {
                    api.LOG_MESSAGE(["Page : Estimate", "Action : Estimate Data Loaded for Operator : " + operator], { 'UserName': props.loggedInUser });
                }
            }).finally(() => {
                props.setShowProgress(false);
            });
        }

        if (operator !== undefined && operator !== '')
            getEstimatesData();

    }

    const autoSizeColumns = (timeoutPeriod: number) => {
        setTimeout(() => {
            EstimateGridRef?.current?.columnApi?.autoSizeAllColumns(false);
        }, timeoutPeriod);
    }

    const onFirstDataRendered = useCallback((timeoutPeriod: number) => {
        autoSizeColumns(timeoutPeriod);
    }, []);

    const orderPickHandler = (orderData: any[]) => {
        const orderStatus = orderData.map((element: any) => { return element.OrderStatus });
        setIsExecutedOrder(orderStatus.includes('Executed'));
        const reports: any[] = orderData.map((element: any) => { return element.SelectedReports });
        const _orderData: any[] = orderData.map((element: any) => {
            if (element.OrderId == 'N/A') {
                return element.Data
            }
            else {
                return JSON.parse(element.Data)
            }
        });
        const apis: string[] = _orderData.map((element: any) => { return element.map((b: any) => { return b.API }); });
        const orderIds: any[] = orderData.map((element: any) => { return element.OrderId });

        setReports(reports);
        setApis(getUniqueApi(apis).toString());
        setselectedOrder(orderIds);
        regenerateEstimates(orderData);
        SetCartOrders(orderData);
        if (orderData.length > 0) {
            setFirstSelectedOrderData(orderData[0]);
        }
    }

    const getUniqueApi = (apiData: string[]) => {
        const uniqueData: string[] = [];
        apiData.map((element: any) => {
            element.forEach((a: any) => {
                if (!uniqueData.includes(a)) {
                    uniqueData.push(a);
                }
            });
        });

        return uniqueData;
    }

    const resetEstimateHandler = () => {
        let orderId = selectedOrder.length > 0 ? selectedOrder.toString() : ""
        loadEstimateData(operator, apis, reports, "", orderId);
        autoSizeColumns(1000);
    }

    const onSave = () => {
        onSubmitHandler(false);
    }

    const onValidate = () => {
        if (cartOrders.length > 0) {
            let columns: any[] = [];
            EstimateGridRef?.current!.api?.forEachNodeAfterFilterAndSort((params: any) => {
                if (params.data.IsIncludedInOrder === 'Y') {
                    if (params.data.CurrentValue === 0 || params.data.CurrentValue === null) {
                        columns.push('CurrentValue');
                    }
                    if (params.data.OrderOwnerProposedValue === 0 || params.data.OrderOwnerProposedValue === null) {
                        columns.push('OrderOwnerProposedValue');
                    }
                    if (params.data.DataSetFrequency === 0 || params.data.DataSetFrequency === null) {
                        columns.push('DataSetFrequency');
                    }
                    if (cartOrders[0].OrderStatus != "Drafted" && cartOrders[0].OrderStatus != "Pending" && (params.data.DataOwnerProposedValue !== '' && params.data.DataOwnerProposedValue > 0 && (params.data.OrderOwnerApproval === '' || params.data.OrderOwnerApproval === null))) {
                        columns.push('OrderOwnerApproval');
                    }
                }
            });

            if (columns.length > 0) {
                let unique = columns.filter((item, i, ar) => ar.indexOf(item) === i);
                setMissingColumns(unique);
            } else {
                setMissingColumns([]);
            }
        }
    }

    const getModifiedAttributes = (editedObject: any) => {
        const actualKeys = Object.keys(editedObject);
        const modifiedKeys = editedObject["modifiedAttrs"];
        let _objectClone: any = {};
        for (let key of actualKeys) {
            if (modifiedKeys.includes(key) || key === "EstimateId") {
                if (key === "OrderOwnerProposedValue") {
                    _objectClone["ProposedValue"] = editedObject[key];
                }
                else if (key === "OrderOwnerApproval") {
                    _objectClone["ApprovalStatus"] = editedObject[key];
                }
                else {
                    _objectClone[key] = editedObject[key];
                }
            }
        }
        return _objectClone;
    }

    const onCellValueChanged = (params: any) => {
        if (!params.node.footer || !params.node.rowPinned) {
            setIsEditted(true);
            let _tableData = estimateData;
            if (_tableData.length > 0) {

                if (params.column.colId == "CostFromAFE" || params.column.colId == "Depreciation") {
                    let value = params.column.colId == "CostFromAFE" ? Number(params.node.data.CostFromAFE) : Number(params.node.data.Depreciation);
                    let isNotNumber = isNaN(+value);
                    if (isNotNumber) {
                        params.node.setDataValue(params.column.colId, 0);
                    }
                }

                if (params.node.id !== "rowGroupFooter_ROOT_NODE_ID") {
                    params.node.data.isModified = true;
                    _tableData[params.node.id]["isModified"] = true;
                    _tableData[params.node.id][params.column.colId] = params.node.data[params.column.colId];
                    if (_tableData[params.node.id]["modifiedAttrs"] === undefined) {
                        _tableData[params.node.id]["modifiedAttrs"] = params.column.colId;
                    } else {
                        let modAttrs = _tableData[params.node.id]["modifiedAttrs"];
                        modAttrs = modAttrs + ',' + params.column.colId;
                        _tableData[params.node.id]["modifiedAttrs"] = modAttrs;
                    }

                    params.node.setData({ ...params.node.data, isModified: true });
                }

                if (params.column.colId == "CostFromAFE" || params.column.colId == "Depreciation"
                    || params.column.colId == "DateOfAcquisition") {
                    CalculateCurrentValue(params.node.data);
                    params.node.setDataValue('CurrentValue', params.node.data.CurrentValue);
                }

                if (params.column.colId == "CostFromAFE" || params.column.colId == "Depreciation"
                    || params.column.colId == "DateOfAcquisition" || params.column.colId == "OrderOwnerProposedValue") {
                    updateTotalRowNode();
                }
            }
            onValidate();

        }
    }

    const onCellValueEnded = (params: any) => {
        if (params.column.colId == "DateOfAcquisition") {
            let _tableData = estimateData;
            let rowNode = EstimateGridRef.current.api.getRowNode(params.rowIndex);
            CalculateCurrentValue(rowNode.data);
            rowNode.setDataValue('DateOfAcquisition', rowNode.data.DateOfAcquisition);
            rowNode.setDataValue('CurrentValue', rowNode.data.CurrentValue);
            updateTotalRowNode();
            rowNode.data.isModified = true;
            _tableData[params.rowIndex]["isModified"] = true;
            if (_tableData[params.rowIndex]["modifiedAttrs"] === undefined) {
                _tableData[params.rowIndex]["modifiedAttrs"] = "DateOfAcquisition";
            } else {
                let modAttrs = _tableData[params.rowIndex]["modifiedAttrs"];
                modAttrs = modAttrs + ',' + "DateOfAcquisition";
                _tableData[params.rowIndex]["modifiedAttrs"] = modAttrs;
            }
        } else {
            let rowNode = EstimateGridRef.current.api.getRowNode(params.rowIndex);
            rowNode.data.isModified = true;
        }

        onValidate();
    }

    const CalculateCurrentValue = (rowData: any) => {
        let currentValue = 0;
        if (rowData.DateOfAcquisition != null && rowData.CostFromAFE != null && rowData.Depreciation != null) {
            //Current Value = Max(0, AFE Cost – ( AFE Cost * Depreciation% ) *  ( (Today's Date - Acquisition Date) / 365 )   )
            let dateFrom = new Date();
            let dateTo = new Date(rowData.DateOfAcquisition);
            const MS_PER_DAY: number = 1000 * 60 * 60 * 24;
            const daysBetweenDates: number = Math.ceil((dateFrom.getTime() - dateTo.getTime()) / MS_PER_DAY);

            if (daysBetweenDates > 0) {
                const dateDiff = daysBetweenDates / 365;
                const cost = rowData.CostFromAFE * (rowData.Depreciation / 100);
                const value = (rowData.CostFromAFE - (cost * dateDiff));
                currentValue = Math.max(0, value);
            }
        }

        let _tableData = estimateData;
        let index = _tableData.findIndex(i => i.EstimateId === rowData.EstimateId);
        rowData.CurrentValue = currentValue;
        _tableData[index]["CurrentValue"] = currentValue;
        _tableData[index]["isModified"] = true;
        if (_tableData[index]["modifiedAttrs"] === undefined) {
            _tableData[index]["modifiedAttrs"] = "CurrentValue";
        } else {
            let modAttrs = _tableData[index]["modifiedAttrs"];
            modAttrs = modAttrs + ',' + "CurrentValue";
            _tableData[index]["modifiedAttrs"] = modAttrs;
        }
    }

    const getRowStyle = (params: any) => {
        let rowStyle: Record<string, string> = {};
        if (params?.node?.data?.isModified) {
            rowStyle.fontStyle = 'italic';
            rowStyle.fontWeight = 'bold';
        }

        if (selectedOrder.length > 0 && !pivotMode) {
            let bgColor = params.node?.data?.IsIncludedInOrder == 'Y' ? '#fff' : 'lightgrey';
            rowStyle.backgroundColor = bgColor;
            if (isExecutedOrder) {
                rowStyle.pointerEvents = 'none';
            }
        }
        else {
            rowStyle.backgroundColor = '#fff';
        }

        if (params.node.rowPinned) {
            rowStyle.pointerEvents = 'none';
            rowStyle.fontWeight = 'bold';
            rowStyle.backgroundColor = '#87CEEB';
        }

        return rowStyle;
    }

    const getModifiedValuationAttributes = (editedObject: any) => {
        const actualKeys = ['DataOwnerApproval', 'OrderValuationId'];
        const modifiedKeys = editedObject["modifiedAttrs"];
        let _objectClone: any = {};
        for (let key of actualKeys) {
            if (modifiedKeys.includes(key) || key === "OrderValuationId") {
                if (key === "DataOwnerApproval") {
                    _objectClone["ApprovalStatus"] = editedObject[key];
                }
                else {
                    _objectClone[key] = editedObject[key];
                }
            }
        }
        return _objectClone;
    }

    const onSubmitHandler = (changeMode: boolean) => {
        const includedEst = estimateData.filter((data) => { return data.IsIncludedInOrder === 'Y' });

        const groupedKeys = includedEst.reduce((group: {[key: string] : number}, item) => {
            if (!group[item.WellName]) {
             group[item.WellName] = 0;
            }
            group[item.WellName]++;
            return group;
           }, {});

           let resultArray = Object.keys(groupedKeys).map(function(personNamedIndex){
            let obj : any = {};
            obj['Name'] = personNamedIndex;
            obj['Count'] = groupedKeys[personNamedIndex];;
            return obj;
        });

        let dataSetCountExceeded = false;
        let wellname = '';

        resultArray.forEach((element : any) => {
            if(element.Count > dataSetRestriction) {
                dataSetCountExceeded = true;
                wellname = element.Name;
            }
        });
        
        const edittedData = estimateData.filter((data) => { return data.isModified });
        const newData: any = [];
        const editedRows: any = [];
        const editedOrderValuationRows: any = [];

        if(edittedData.length > 0) {
            if(!dataSetCountExceeded ) {
                props.setShowProgress(true);
                //let isApprovalStatusModified : boolean = false;
                    let isIncludedInOrderEdited: boolean = false;
                    edittedData.forEach((element) => {
                        if (element.modifiedAttrs !== undefined) {
                            //isApprovalStatusModified = element["modifiedAttrs"].includes("OrderOwnerApproval");
                            let updatedData = getModifiedAttributes(element);
                            updatedData.LastModifiedBy = props.loggedInUser;
                            updatedData.LastModifiedDate = new Date().toISOString();
                            editedRows.push(updatedData);
    
                            let orderValuationData = getModifiedValuationAttributes(element);
                            if (orderValuationData) {
                                //isApprovalStatusModified = element["modifiedAttrs"].includes("DataOwnerApproval");
                                orderValuationData.LastModifiedBy = props.loggedInUser;
                                orderValuationData.LastModifiedDate = new Date().toISOString();
                                editedOrderValuationRows.push(orderValuationData);
                            }
    
                            if (!isIncludedInOrderEdited && updatedData.IsIncludedInOrder !== undefined) {
                                isIncludedInOrderEdited = true;
                            }
                        }
                    }
                );
    
                SaveData(isIncludedInOrderEdited);
            } else {
                formMessage('Cannot Include more than ' + dataSetRestriction + ' Datasets for a Well in an Order', 'error');
                props.setShowProgress(false);
            }
        } else {
            formMessage('No records to save', 'info');
        }
       

        async function SaveData(isIncludedInOrderEdited: boolean) {
            await api.API_POST('/Table/SaveTableData', {
                schema: 'orders',
                table: 'ESTIMATES',
                insert: newData,
                update: editedRows,
                ids: ""
            }).then((response) => {
                if (response.data.length > 0) {
                    api.LOG_MESSAGE(["Page : Estimate", "Action : Saved Estimates data "], { 'UserName': props.loggedInUser });
                    formMessage('Data Saved successfully', 'success');
                    if (selectedOrder.length > 0) {
                        if (isIncludedInOrderEdited && cartOrders[0].OrderStatus != "Drafted") {
                            //Call to Regenerate Workflow
                            RegenWorkflow();
                        }
                    }

                    let rowNode: any = null;
                    edittedData.forEach((element) => {
                        let estimate = estimateData.findIndex(item => item.EstimateId === element.EstimateId);
                        if (estimate > -1) {
                            rowNode = EstimateGridRef.current.api.getRowNode(estimate);
                            rowNode.data.isModified = false;
                            estimateData[estimate].isModified = false;
                            delete estimateData[estimate].modifiedAttrs;
                            rowNode.setData({ ...estimateData[estimate], isModified: false });
                        }
                    });

                    if (rowNode !== null) {
                        eventMessaging.EstimateUpdatedBroadcast(rowNode?.data, operator);
                        if (rowNode?.data?.OrderId !== undefined) {
                            eventMessaging.GetOrderAndBroadcastOrderUpdate(rowNode.data.OrderId, operator);
                        }
                    }

                    if (props.IsSummary) {
                        // Update in TempData
                        props.setIsDataModified();
                        props.setSelectedEstValData(estimateTempData);
                    }

                    if (changeMode) {
                        setOpenConfirmDialog(false);
                        setOpenSaveConfirmDialog(false);
                    }

                    setIsEditted(false);
                }
            }).catch(() => {
                setIsEditted(false);
            });

            await api.API_POST('/Table/SaveTableData', {
                schema: 'orders',
                table: 'ORDER_VALUATION',
                insert: newData,
                update: editedOrderValuationRows,
                ids: ""
            }).then((response) => {
                if (response.data.length > 0) {
                    formMessage('Data Saved successfully', 'success');
                    if (selectedOrder.length > 0) {
                       //Check for approved and Initiate Round 2
                       CheckAndIntiateWorkflow();
                    }
                   
                    props.setShowProgress(false);
                }
            }).catch(() => {
            });
        };

        async function CheckAndIntiateWorkflow() {

            const hasUnApprovedEstimates = estimateData.filter((i: any) => { return i.IsIncludedInOrder == 'Y' && i.OrderOwnerApproval?.toLowerCase() !== 'accept' });
            const hasUnApprovedValuation = estimateData.filter((i : any) => {return i.IsIncludedInOrder == 'Y' && i.DataOwnerApproval?.toLowerCase() !== 'accept'});

            if (hasUnApprovedEstimates.length == 0 && hasUnApprovedValuation.length == 0) {
                let reInitiateUrl = '/OrderManagement/CheckEstimateValuation?orderId='
                    + selectedOrder[0];
                await api.API_GET(reInitiateUrl).then((response) => {
                    if (response.data) {
                        formMessage('Internal workflow Re-Initiated', 'success');
                        api.LOG_MESSAGE(["Page : Estimate", "Action : Workflow ReInitiated for OrderId : + " + selectedOrder[0]], { 'UserName': props.loggedInUser });
                        props.setShowProgress(false);
                    }
                }).catch(() => {

                });
            }
        };

        async function RegenWorkflow() {
            let initiateWorkflowUrl = '/OrderManagement/InitiateWorkflow'
            await api.API_POST(initiateWorkflowUrl, {
                orderId: selectedOrder[0].toString(),
                operator: operator,
                workflowType: "I",
                AuditIds: "",
                SelectedReports: "",
                userEmailId: props.loggedInUserMailId,
                userName: props.loggedInUser,
                database: api.env === 'OASIS' ? 'oasis' : 'envoy_dev'
            }).then((response) => {
                api.LOG_MESSAGE(["Page : Transaction ", "Action : Regenerate Approval Workflow : " + selectedOrder[0]], { 'UserName': props.loggedInUser });
            }).catch(() => {
                console.log('Error while regenerating workflow', 'error');
            });
        };
    }

    const saveEditedDataHandler = () => {
        onSubmitHandler(true)
    }

    const onCancelHandler = () => {
        EstimateGridRef.current.api.undoCellEditing();
        setPivotMode(true);
        setOpenConfirmDialog(false);
    }

    const onNavigateTabHandler = () => {
        EstimateGridRef.current.api.undoCellEditing();
        setOpenSaveConfirmDialog(false);
    }

    const closeMessage = () => {
        setMessage(initialMessage);
    }

    const formMessage = (message: string, severity: string) => {
        setMessage({
            open: true,
            msg: message,
            severity: severity
        });
    }

    const onPivotModeChanged = (params: any) => {
        if (!pivotMode) {
            const edittedData = estimateData.filter((data) => { return data.isModified });
            if (edittedData.length > 0) {
                let confirmDialogObject = {
                    title: "Estimates",
                    body: "You have made some changes. Do you want to Save and Continue?, else click CANCEL",
                    okHandler: saveEditedDataHandler
                };

                setConfirmDialogObject(confirmDialogObject);
                setOpenConfirmDialog(true);
            }
            else {
                setPivotMode((pivotMode) => !pivotMode);
            }
        }
        else {
            setPivotMode((pivotMode) => !pivotMode);
        }
    }

    const cellClicked = (params: any) => {
        params.node.setSelected(true)
    }

    const onRowSelectedHandler = () => {
        const selectedRow = EstimateGridRef.current?.api?.getSelectedRows();
        if (selectedRow.length > 0) {
            setselectedEstimate(selectedRow[0]);
        }
    }

    const handleCollapseExplorer = () => {
        setCommentOpen(!commentOpen);
    }

    const IsIncludedTypeFilterChanged = useCallback((newValue: string) => {
        setFilterEstimateType(newValue);
    }, []);

    const isExternalFilterPresent = useCallback((): boolean => {
        return filterEstimateType !== 'All';
    }, [filterEstimateType]);

    const doesExternalFilterPass = useCallback((node: any): boolean => {
        if (node.data) {
            let isValid: boolean = false;
            switch (filterEstimateType) {
                case 'Y':
                    isValid = node.data.IsIncludedInOrder === "Y";
                    break;
                case 'N':
                    isValid = node.data.IsIncludedInOrder === "N";
                    break;
                default:
                    isValid = filterEstimateType === 'All';
                    break;
            }

            return isValid;
        }
        return false;
    }, [filterEstimateType]);

    const generatePinnedBottomData = () => {
        let result: any = {};
        if (EstimateGridRef) {
            let colDefs = EstimateGridRef.current?.api?.getColumnDefs();
            let firstColumn = colDefs?.find((ele: any) => { return !ele.hide });
            EstimateGridRef.current?.columnApi?.getAllGridColumns().forEach((item: any) => {
                if (firstColumn && (item.colId == firstColumn.field || item.colId === firstColumn.headerName)) {
                    result[item.colId] = "Total";
                } else {
                    result[item.colId] = null;
                }
            });

            calculatePinnedBottomData(result);
            if (pivotMode) {
                EstimateGridRef.current?.api?.setPinnedTopRowData([]);
                //EstimateGridRef.current?.api?.setPinnedTopRowData([params?.node?.aggData]);
            }
            else {
                EstimateGridRef.current?.api?.setPinnedTopRowData([result]);
            }
        }
    }

    const calculatePinnedBottomData = (result: any) => {
        let costfromafe = 0;
        let oasiscostfromafe = 0;
        let currentValueSum = 0;
        let proposedvalueSum = 0;
        let dataOwnervalueSum = 0;

        estimateData.forEach((node: any) => {
            currentValueSum += node?.IsIncludedInOrder === 'Y' ? node?.CurrentValue : 0;
            costfromafe += !(node?.IsIncludedInOrder === 'Y') ? 0 : isNaN(node?.CostFromAFE) ? 0 : Number(node?.CostFromAFE);
            oasiscostfromafe += !(node?.IsIncludedInOrder === 'Y') ? 0 : isNaN(node?.OasisCostFromAFE) ? 0 : Number(node?.OasisCostFromAFE);
            proposedvalueSum += !(node?.IsIncludedInOrder === 'Y') ? 0 : isNaN(node?.OrderOwnerProposedValue) ? 0 : Number(node?.OrderOwnerProposedValue);
            dataOwnervalueSum += !(node?.IsIncludedInOrder === 'Y') ? 0 : isNaN(node?.DataOwnerProposedValue) ? 0 : Number(node?.DataOwnerProposedValue);
        });

        result['CostFromAFE'] = costfromafe;
        result['OasisCostFromAFE'] = oasiscostfromafe;

        result['CurrentValue'] = currentValueSum;

        if (selectedOrder.length > 0) {
            result['OrderOwnerProposedValue'] = proposedvalueSum;
            result['DataOwnerProposedValue'] = dataOwnervalueSum;
        }
    }

    return (
        <>

            <Paper className="estimatesHeader" elevation={0} sx={{ height: 'auto !important', padding: '10px', marginBottom: '5px' }}>
                {props.IsSummary === true
                    && <Typography variant="button" sx={{ fontSize: '1rem', color: 'rgba(0, 0, 0, 0.6) !important', paddingRight: '5%' }}>ESTIMATES</Typography>
                }

                {props.IsSummary === false && <TextField style={{ fontSize: 'x-small !important' }}
                    id="Operator" name="Operator" select label="Operator"
                    size="small"
                    SelectProps={{
                        native: true,
                    }}
                    value={operator}
                    onChange={e => {
                        setOperator(e.target.value);
                        autoSizeColumns(1000);
                        setReports([]);
                        setApis("");
                        setselectedOrder([]);
                        setOrderComments([]);
                        setselectedEstimate(null);
                    }}>
                    {operatorList?.map((operator: string) => (
                        <option key={operator} value={operator}>
                            {operator}
                        </option>
                    ))}
                </TextField>}

                {props.IsSummary === false && <MyOrdersFilter operator={operator}
                    type={"Estimate"}
                    loggedInUser={props.loggedInUser}
                    loggedInUserMailId={props.loggedInUserMailId}
                    selectedOrder={selectedOrder}
                    orderPickHandler={orderPickHandler}></MyOrdersFilter>}

                <Button id="reset"
                    variant="outlined"
                    color="success"
                    sx={{ marginLeft: '10px', fontSize: '11px' }}
                    startIcon={<RestartAltOutlinedIcon />}
                    onClick={resetEstimateHandler}>RESET</Button>


                <Tooltip title="Save">
                    <IconButton
                        id="success"
                        color="success"
                        aria-label="Save"
                        component="label"
                        style={{ backgroundColor: "rgb(239 238 238)", marginLeft: '10px', padding: '2px' }}
                        onClick={onSave}>
                        <SaveIcon />
                    </IconButton>
                </Tooltip>

                <Tooltip title="Validate">
                    <IconButton
                        id="successValidate"
                        color="success"
                        aria-label="Save"
                        component="label"
                        style={{ backgroundColor: "rgb(239 238 238)", marginLeft: '10px', padding: '2px' }}
                        onClick={onValidate}>
                        <ChecklistIcon />
                    </IconButton>
                </Tooltip>

                <ExcelUpload
                    selectedTableInfo={{
                        schema: "orders",
                        name: "ESTIMATES",
                        pkColumnName: "EstimateId"
                    }}
                    columns={columns}
                    loggedInUser={props.loggedInUser}
                    loggedInUserOperator={operator}
                    environment={api.env}
                    tableData={estimateData}
                    setTableData={setEstimateData}
                    setTableDataMonitor={setEstimateData}
                    setShowProgress={props.setShowProgress}
                    setReplaceDeleteIds={() => { }}
                    refreshdatahandler={() => { }}
                    setSaveAndUpdateInfo={() => { }}
                    setOpenSuccessMessage={() => { }}
                    setErrorList={() => { }}
                    setOpenErrorMessage={() => { }}></ExcelUpload>

                <Tooltip title="columns">
                    <IconButton
                        id="successcolumn"
                        color="success"
                        aria-label="columns"
                        component="label"
                        style={{ backgroundColor: "rgb(239 238 238)", marginRight: '5px', padding: '2px' }}
                        onClick={handleClickColumnsOpen}>
                        <ViewColumnOutlinedIcon />
                    </IconButton>
                </Tooltip>

                {selectedOrder.length == 1 &&
                    <Tooltip title="Map View">
                        <MapViewRenderer
                            data={firstSelectedOrderData}
                            setTabIndex={props.setTabIndex}
                            selectedOperator={operator}
                            isGreenButton={true}
                            IsSummary={props.IsSummary}
                            groupedOrders={props.groupedOrders}
                            summaryCloseHandler={props.summaryCloseHandler}
                        />
                    </Tooltip>
                }

                {missingColumns && missingColumns.length > 0 &&
                    <Collapse in={missingColumns.length > 0} sx={{ display: "inline-block", mt: -11 }}>
                        <Alert severity="error"
                            sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                            <strong> Missing Values</strong>  {missingColumns.map((_errorInfo, index) => {
                                return <><strong> {index + 1}.</strong> {_errorInfo} </>
                            })}
                        </Alert>
                    </Collapse>
                }

                {showGif &&
                    <img style={{ height: "31px", position: "fixed" }} src={circleImage} />
                }

                {selectedOrder.length == 1 &&
                    <IconButton id="handlecollapse" style={{ float: 'right', backgroundColor: 'rgb(239, 238, 238)', fontSize: '14px' }} onClick={() => { handleCollapseExplorer(); }}>
                        {commentOpen == false && <ChevronLeftIcon />}
                        {commentOpen == true && <ChevronRightIcon />}
                    </IconButton>}

                {selectedOrder.length == 1 &&
                    <Stack sx={{ float: 'right', display: 'flex' }} direction="row" spacing={1}>
                        <Typography variant="subtitle1" component="div">
                            Filter Datasets Included:
                        </Typography>
                        {filterTypeList.map((item) => (
                            <Chip
                                key={item}
                                color="primary"
                                size="small"
                                //avatar={<Avatar>{item.charAt(0)}</Avatar>}
                                label={item}
                                variant={item === filterEstimateType ? "filled" : "outlined"}
                                onClick={(event) => {
                                    IsIncludedTypeFilterChanged(item);
                                }} />
                        ))}
                    </Stack>
                }

                <div style={{ height: '20px' }}>
                    <Collapse in={message.open}>
                        <Alert onClose={closeMessage} severity={message.severity as AlertColor}
                            sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                            {message.msg}
                        </Alert>
                    </Collapse>
                </div>


            </Paper>

            <div style={{ display: 'flex' }}>
                <div style={{ width: commentOpen ? "80%" : "100%" }}>
                    <Paper id='orderEstimateGridContent' className="ag-theme-balham" elevation={0} sx={{ height: props.IsSummary ? '65vh' : '77vh', padding: '10px' }}>
                        <AgGridReact
                            ref={EstimateGridRef}
                            //autoGroupColumnDef={autoGroupColumnDef}
                            gridOptions={gridOptions}
                            pagination={props.enablePagination !== undefined ? props.enablePagination : true}
                            pivotMode={pivotMode}
                            getRowStyle={getRowStyle}
                            aggFuncs={aggFuncs}
                            suppressExpandablePivotGroups={true}
                            onColumnGroupOpened={() => { onFirstDataRendered(1000) }}
                            sideBar={sideBar}
                            rowSelection={'single'}
                            rowGroupPanelShow={'always'}
                            onSelectionChanged={onRowSelectedHandler}
                            onCellClicked={cellClicked}
                            groupDefaultExpanded={-1}
                            groupIncludeFooter={false}
                            groupIncludeTotalFooter={pivotMode}
                            onCellEditingStopped={onCellValueEnded}
                            onColumnPivotModeChanged={onPivotModeChanged}
                            suppressAggFuncInHeader={true}
                            onCellValueChanged={onCellValueChanged}
                            isExternalFilterPresent={isExternalFilterPresent}
                            doesExternalFilterPass={doesExternalFilterPass}
                            postSortRows={postSortRows}
                            onFirstDataRendered={() => { onFirstDataRendered(1000) }}
                            overlayNoRowsTemplate={'<span style="padding: 10px; border: 2px solid #444; background: lightgoldenrodyellow;">Loading...</span>'}>
                        </AgGridReact>
                    </Paper>
                </div>
                {commentOpen &&
                    <div style={{ width: '20%' }}>
                        <Paper id='orderComment' className="ag-theme-balham" elevation={0} sx={{ height: 'auto', padding: '10px' }}>
                            <OrderComment
                                orderInfo={{ OrderId: selectedOrder[0] }}
                                operator={operator}
                                loggedInUserName={props.loggedInUser}
                                loggedInUserMailId={props.loggedInUserMailId}
                                messageTag={MessageTags.Estimates}
                                completeObject={selectedEstimate}
                                selectedEstimate={selectedEstimate !== null ? {
                                    Api: selectedEstimate?.API,
                                    ReportName: selectedEstimate?.DaColName,
                                    OrderId: selectedOrder[0],
                                    CreatedBy: props.loggedInUserMailId,
                                    DataOwnerOperator: selectedEstimate.DataOwnerOperator,
                                    OrderOwnerOperator: selectedEstimate.OrderOwnerOperator
                                } : null} >

                            </OrderComment>
                        </Paper>
                    </div>}
            </div>


            <ConfirmDialog
                open={openConfirmDialog}
                setOpenConfirmDialogHandler={onCancelHandler}
                title={confirmDialogObject.title}
                body={confirmDialogObject.body}
                okHandler={confirmDialogObject.okHandler}
            ></ConfirmDialog>

            <ConfirmDialog
                open={openSaveConfirmDialog}
                setOpenConfirmDialogHandler={onNavigateTabHandler}
                title={confirmDialogObject.title}
                body={confirmDialogObject.body}
                okHandler={confirmDialogObject.okHandler}
            ></ConfirmDialog>

            <ColumnSelection
                tablePreferenceList={tablePreferenceList}
                selectedPreference={selectedPreference}
                setselectedPreference={setselectedPreference}
                loggedInUserMailId={props.loggedInUserMailId}
                loggedInUser={props.loggedInUser}
                hiddenColumns={hiddenColumns}
                columnsPerfOpen={columnsPerfOpen}
                anchorEl={anchorEl}
                applyColumnPreferenceHandler={applyColumnPreferenceHandler}
                setcolumnsPerfOpen={setcolumnsPerfOpen}
                displayColumns={displayColumns}
            ></ColumnSelection>

        </>
    )
}

export default Estimates;