import { TextField } from "@mui/material";
import { useState } from "react";

function TradeTypeRenderer(props: any) {
    const tradeTypes = ['Purchase', 'Trade', 'Rental'];
    const [value, setValue] = useState(props.value);
    const [rowIndex] = useState(props.rowIndex);
    const [isExecutedOrder] = useState(props.data?.OrderStatus === 'Executed');

    const valueChangeHandler = (e: any) => {
        props.onValueSelected(e.target.value, rowIndex, props.data);
        setValue(e.target.value);
    }

    return (
        <TextField
            id="tradeType" name="tradeType" select
            size="small"
            disabled = {isExecutedOrder}
            SelectProps={{
                native: true,
            }}
            value={value}
            onChange={valueChangeHandler}>
            {tradeTypes.map((value) => (
                <option key={value} value={value}>
                    {value}
                </option>
            ))}
        </TextField>
    );

};

export default TradeTypeRenderer;
