import { Grid, Paper } from "@mui/material";
//import CircularIcon from "./Movables/CircularIcon";
import Draggable from 'react-draggable';

function CirclePoc() {
    return (
        <></>
        // <Grid item xs={12}>
        //     <Paper sx={{ p: 3, display: 'flex', flexDirection: 'column', height: '85vh' }}>
        //         <Draggable>
        //             <div style={{width:'fit-content',position:'absolute'}}><CircularIcon></CircularIcon></div>                  
        //         </Draggable>
        //         <Draggable>
        //             <div style={{width:'fit-content',position:'absolute'}}><CircularIcon></CircularIcon></div>                  
        //         </Draggable>
        //         <Draggable>
        //             <div style={{width:'fit-content',position:'absolute'}}><CircularIcon></CircularIcon></div>                  
        //         </Draggable>
        //         <Draggable>
        //             <div style={{width:'fit-content',position:'absolute'}}><CircularIcon></CircularIcon></div>                  
        //         </Draggable>
        //         <svg width="100%" height="inherit">
        //             <line x1="80" y1="400" x2="430" y2="120" stroke="black"/>
        //             <line x1="0" y1="0" x2="430" y2="80" stroke="black"/>
        //         </svg>

        //     </Paper></Grid>
    );
}

export default CirclePoc;