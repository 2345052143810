import { AgGridReact } from 'ag-grid-react';
import React, { useCallback, useEffect, useMemo, useRef } from "react";

import { Alert, AlertColor, AppBar, Avatar, Box, Button, ButtonGroup, Chip, Collapse, IconButton, Paper, Slide, Snackbar, Stack, Tab, Tabs, TextField, Tooltip, Typography, useTheme } from "@mui/material";
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-material.css';
import 'ag-grid-enterprise';
import './Cart.css';
import 'src/Common/Styles/heartbeat.css';

import { TransitionProps } from "@mui/material/transitions";

import EditIcon from '@mui/icons-material/Edit';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import GroupRemoveIcon from '@mui/icons-material/GroupRemove';
import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined';
import ShoppingCartCheckoutIcon from '@mui/icons-material/ShoppingCartCheckout';
import { IDetailCellRendererParams, RowSelectedEvent, ValueFormatterParams } from 'ag-grid-community';
import { useSearchParams } from 'react-router-dom';
import ConfirmDialog from 'src/Common/ConfirmDialog';
import * as api from 'src/Utility/api';
import * as eventMessaging from 'src/Utility/Messaging/Messaging';
import { columnHeaderTemplate, groupByObjectArray, isJsonString } from 'src/Utility/common';
import AlertDialog from '../../../../Common/AlertDialog';
import { AppContext } from '../../@State/context/AppContext';
import { CartContext } from "../../@State/context/CartContext";
import { CartType, ExchangeOrderType, ICartInfo, IExchangeOrder, IFilterApplied, IOrderInfo, ITableGridColumnDefinition, IUpdateOrder, RenderTree, TabOrder } from "../../@State/types";
import CartDataRenderer from "../CellRenderers/CartDataRenderer";
import DateTimeRenderer from '../CellRenderers/DateTimeRenderer';
import DialogViewRenderer from './DialogViewRenderer';
import OrderApprovalRenderer from './OrderApprovalRenderer';
import ReportPickerRenderer from './ReportGroup/ReportPickerRenderer';
import TradeTypeRenderer from './TradeTypeRenderer';
import ValuationRenderer from './ValuationRenderer';
import OrderSummary from '../OrderSummary/OrderSummary';
import TimeLineProgressRenderer from '../CellRenderers/TimelineProgressRenderer';
import ViewColumnOutlinedIcon from '@mui/icons-material/ViewColumnOutlined';
import { useEffectOnce } from 'src/hooks/useEffectOnce';
import ColumnSelection from '../ColumnSelection/ColumnSelection';
import DropDownCellRenderer from '../CellEditors/DropDownCellEditor';
import DatePickerEditor from '../CellEditors/DatePickerEditor';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import SwipeableViews from 'react-swipeable-views';
import { TabPanel } from 'src/Common/TabPanel';
import DataTransferRenderer from './DataTransferRenderer';

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function Cart(props: any) {
    const { exchangeOrder, saveExchangeOrder } = React.useContext(AppContext) as ExchangeOrderType;
    const { modifiedOrder, saveModifiedOrder } = React.useContext(AppContext) as ExchangeOrderType;
    const { pickListDefinitions, setOrderWells } = React.useContext(AppContext) as ExchangeOrderType;
    const { screenUpdaterTransaction, setScreenUpdaterTransaction, ordersWithEstimates, IsAdminUser, fetchCommonAppConfigHandler,
        CommonAppConfig } = React.useContext(AppContext) as ExchangeOrderType;

    const [openAlertDialog, setOpenAlertDialog] = React.useState(false);
    const [openConfirmDialog, setOpenConfirmDialog] = React.useState(false);

    const [tablePreferenceList, setTablePreferenceList] = React.useState<any[]>([]);
    const [selectedPreference, setselectedPreference] = React.useState<any>();

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const [requestedColumnPreference, setRequestedColumnPreference] = React.useState<any[]>([]);
    const [pendingColumnPreference, setPendingColumnPreference] = React.useState<any[]>([]);

    const [requestedColumnDefinitions, setRequestedColumnDefinitions] = React.useState<any[]>([]);
    const [tradeColumnDefinitions, setTradeColumnDefinitions] = React.useState<any[]>([]);
    const [pendingColumnDefinitions, setPendingColumnDefinitions] = React.useState<any[]>([]);

    const [selectedRequestedPreference, setselectedRequestedPreference] = React.useState<any>();
    const [selectedPendingPreference, setselectedPendingPreference] = React.useState<any>();

    const [tableRequestedPreferenceList, setTableRequestedPreferenceList] = React.useState<any[]>([]);
    const [tablePendingPreferenceList, setTablePendingPreferenceList] = React.useState<any[]>([]);

    const [operator, setOperator] = React.useState<string>('');
    const [confirmDialogObject, setConfirmDialogObject] = React.useState<any>({
        title: "",
        body: "",
        okHandler: () => undefined
    });

    const requestedGridRef: any = useRef();
    const pendingGridRef: any = useRef();
    const groupedGridRef: any = useRef();

    const [selectedCartItems, setSelectedCartItems] = React.useState<any[]>([]);
    const [currentOrders, setCurrentOrders] = React.useState<any[]>([]);
    const [requestedOrders, setRequestedOrders] = React.useState<any[]>([]);
    const [groupedOrders, setGroupedOrders] = React.useState<any[]>([]);
    const [pendingOrders, setPendingOrders] = React.useState<any[]>([]);
    const [selectedOrder, setSelectedOrder] = React.useState<any>();
    const [currentCartInformation, setCurrentCartInformation] = React.useState<ICartInfo>();

    const [columns, setColumns] = React.useState<any[]>(['OrderId',
        'TradeGroupId',
        'TradeType',
        'StartDate',
        'EndDate',
        'Amount',
        'RequestedBy',
        'RequestedTo',
        'SelectedReports',
        'Data',
        'PaymentFrequency', 'DataTransfer']);
    const [reportSelectionData, setReportSelectionData] = React.useState<any[]>([]);
    const { reportGroups, loadInitialPrerequisiteData } = React.useContext(CartContext) as CartType;
    const { operatorList, loadInitialOperatorData } = React.useContext(CartContext) as CartType;
    const [columnsPerfOpen, setcolumnsPerfOpen] = React.useState(false);
    const [filterTradeType, setFilterTradeType] = React.useState<string>('All');
    const [filterStatus, setFilterStatus] = React.useState<string[]>(['Drafted', 'In Progress', 'Executed']);
    const [columnApi, setColumnApi] = React.useState<any>(null);
    const [searchParams, setSearchParams] = useSearchParams();
    const [isCartLoaded, setIsCartLoaded] = React.useState(false);
    const [tabIndex, setTabIndex] = React.useState(props.value);
    const [isEditted, setIsEditted] = React.useState(false);
    const initialMessage = {
        open: false,
        msg: '',
        severity: 'info'
    };
    const [hiddenColumns, setHiddenColumns] = React.useState<string[]>(["Approver1"
        , "Comment1"
        , "ApprovedDate1"
        , "ApprovalStatus1"
        , "Approver2"
        , "Comment2"
        , "ApprovedDate2"
        , "ApprovalStatus2"
        , "Approver3"
        , "Comment3"
        , "ApprovedDate3"
        , "ApprovalStatus3"]);
    const [message, setMessage] = React.useState(initialMessage);

    const displayColumns = [
        'OrderId',
        'TradeGroupId',
        'TradeType',
        'StartDate',
        'EndDate',
        'Amount',
        'RequestedBy',
        'RequestedTo',
        'SelectedReports',
        'Data',
        'PaymentFrequency', 'DataTransfer'];
    const dateColumns = ["StartDate", "EndDate"];
    const tradeTypesList = ['All', 'Purchase', 'Trade', 'Rental'];
    const approvalStatusList = ['All', 'Drafted', 'In Progress', 'Executed', 'Closed'];
    const [wellsRestriction, setWellsRestriction] = React.useState<number>(500);

    const [gridTabValue, setGridTabValue] = React.useState(0);
    const theme = useTheme();
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setGridTabValue(newValue);
    };

    useEffectOnce(() => {
        loadColumnPreferences();
    });

    useEffect(() => {
        if (selectedRequestedPreference != null) {
            setRequestedColumnPreference(selectedRequestedPreference.PreferenceJson);
        }
        else {
            setInitialColumnPreferenceState('Requested');
        }
    }, [selectedRequestedPreference]);

    useEffect(() => {
        if (selectedPendingPreference != null) {
            setPendingColumnPreference(selectedPendingPreference.PreferenceJson);
        }
        else {
            setInitialColumnPreferenceState('Pending');
        }
    }, [selectedPendingPreference]);


    useEffect(() => {
        if (operatorList?.length == 0) {
            loadInitialOperatorData(props.loggedInUserMailId);
        }
        if (typeof (operatorList) != "undefined" && operatorList.length >= 0) {
            const queyParamOperator = searchParams.get("operator");
            if (queyParamOperator === null) {
                setOperator(operatorList[0]);
            } else {
                setOperator(queyParamOperator);
            }
        }
    }, [operatorList]);

    useEffect(() => {
        if (CommonAppConfig !== null && CommonAppConfig.length > 0) {
            let noOfWells = CommonAppConfig.find((temp: any) => {
                return temp.ConfigShortDesc === "NoOfWells" && temp.Operators.includes(operator);
            });

            if (noOfWells) {
                setWellsRestriction(noOfWells.ConfigValue);
            }
        }
    }, [CommonAppConfig]);

    useEffect(() => {
        if (operator) {
            setFilterTradeType('All');
            setFilterStatus(['Drafted', 'In Progress', 'Executed']);
            props.setCartInformation([]);
            loadPendingRequests();
            fetchCommonAppConfigHandler();
        }
    }, [operator]);

    useEffect(() => {
        requestedGridRef.current?.api?.onFilterChanged();
        groupedGridRef.current?.api?.onFilterChanged();
        pendingGridRef.current?.api?.onFilterChanged();
    }, [filterTradeType, filterStatus]);

    useEffect(() => {
        loadInitialPrerequisiteData();
        loadInitialOperatorData(props.loggedInUserMailId);
    }, [props.IsRefreshClicked]);

    useEffect(() => {
        setCurrentCartInformation(props.cartInformation[0]);
    }, [props.cartInformation]);

    useEffect(() => {
        props.setCurrentCartCount(currentCartInformation?.dataMap?.size);
    });

    useEffect(() => {
        loadCurrentInformation();
        setInitialColumnPreferenceState('Requested');

    }, [requestedOrders, reportSelectionData]);

    useEffect(() => {
        loadPendingInformation();
        setInitialColumnPreferenceState('Pending');

    }, [pendingOrders, reportSelectionData]);

    useEffect(() => {
        loadGroupedInformation();
        setInitialColumnPreferenceState('Trade');

    }, [groupedOrders, reportSelectionData]);

    useEffect(() => {
        if (reportGroups?.length === 0) {
            loadInitialPrerequisiteData();
            fetchCommonAppConfigHandler();
        } else {
            loadCartReportGroupData();
        }
    }, [groupedOrders]);

    useEffect(() => {
        if (props.value == TabOrder.Transaction) {
            setIsCartLoaded(true);
            constructCart();
        }
        else {
            setIsCartLoaded(false);
        }
        props.setTabIndex(props.value);
    }, [props.value]);

    useEffect(() => {
        if (screenUpdaterTransaction && screenUpdaterTransaction?.length > 0 && isEditted === false) {
            // if (isJsonString(screenUpdaterTransaction[0])) {
            //     // Enable when node update is ready
            //     let orderChangeObject = JSON.parse(screenUpdaterTransaction[0]);
            //     updateNodeDataHandler([orderChangeObject], 'update', false);
            // } else {
                loadPendingRequests('messaging');
            //}
            setScreenUpdaterTransaction([]);
        }
    }, [screenUpdaterTransaction]);

    useEffect(() => {
        if (ordersWithEstimates.length > 0) {
            loadPendingRequests('messaging');
            setScreenUpdaterTransaction([]);
        }
    }, [ordersWithEstimates]);

    const defaultColDef = useMemo(() => {
        return {
            resizable: true,
            wrapHeaderText: true,
            autoHeaderHeight: true,
            editable: false,
            headerCheckboxSelectionFilteredOnly: true,
            enableCellChangeFlash: true
        };
    }, []);

    const gridOptions = {
        // PROPERTIES
        defaultColDef: defaultColDef,
        pagination: false,
        defaultCsvExportParams: {
            columnGroups: true,
            fileName: 'name_of_file'
        }
    }

    const getOrderCellClassRules = (gridType: string) => {
        if (gridType === "requested") {
            return {
                "pending-dialogue": (params: any) => (params.data.OrderEvent === "COUNTER_CREATED"
                    || params.data.OrderEvent === "COUNTER_AMEND"),
                "approved-order": (params: any) => (params.data.OrderEvent === "ORDER_APPROVED"),
                "rejected-order": (params: any) => (params.data.OrderEvent === "ORDER_REJECTED"),
                "new-order": (params: any) => (params.data.OrderId === "N/A" || params.data.OrderEvent === 'ORDER_DRAFTED')
            }
        } else if (gridType === "pending") {
            return {
                "pending-dialogue": (params: any) => (params.data.OrderEvent === "INT_WORKFLOW_COMPLETED"
                    || params.data.OrderEvent === "COUNTER_RESPONSE"),
                "approved-order": (params: any) => (params.data.OrderEvent === "COUNTER_APPROVED" || params.data.OrderEvent === "COUNTER_ACCEPTED"),
                "rejected-order": (params: any) => (params.data.OrderEvent === "ORDER_WITHDRAWN"
                    || params.data.OrderEvent === "COUNTER_REJECTED")
            }
        }
        else if (gridType === "grouped") {
            return {
                "new-order": (params: any) => (params.data.OrderEvent === "ORDER_EXCHANGE")
            }
        }
    }

    const pendingGridOptions = {
        // PROPERTIES
        defaultColDef: defaultColDef,
        pagination: false,
        defaultCsvExportParams: {
            columnGroups: true,
            fileName: 'name_of_file'
        }
    }

    const currencyFormatter = (params: ValueFormatterParams) => {
        if (params.value !== null && params.value !== undefined && !isNaN(params.value)) {
            return '$' + formatNumber(params.value);
        }
    }

    const formatNumber = (number: number) => {
        return Math.floor(number)
            .toString()
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    }

    const getCurrentFilter = (currentCartInformation: ICartInfo, key: string) => {
        const currentOperatorFilter = currentCartInformation.operatorFilter
            .filter((element: IFilterApplied) => { return element.operator === key })[0]
            .filterApplied.toString()
        return currentOperatorFilter.length > 0 ? currentOperatorFilter : "All"
    }

    const constructCart = () => {
        let _requestedOrders = [...requestedOrders];
        let _groupedOrders = [...pendingOrders];
        let currentDate = new Date().toISOString();
        let _currentOrders: any[] = [];
        let isCartModified = false;
        let isWellCountExceeding = false
        if (currentCartInformation?.dataMap) {
            currentCartInformation.dataMap.forEach((dataRows: any[], key: string) => {
                if (key !== operator) {
                    if (!currentCartInformation.isModified) {
                        isCartModified = true;
                        let existingOperatorOrder: any = {};
                        existingOperatorOrder = currentCartInformation.isExchange ?
                            _groupedOrders.find(a => a.RequestedTo === key && (a.OrderId === 'N/A' || a.OrderEvent == 'ORDER_DRAFTED'))
                            : _requestedOrders.find(a => a.RequestedTo === key && (a.OrderId === 'N/A' || a.OrderEvent == 'ORDER_DRAFTED'));

                        if (existingOperatorOrder) {
                            let _selectedOrder = { ...existingOperatorOrder };
                            let _jsonData: any[] = [];
                            if (typeof (_selectedOrder.Data) === 'string') {
                                _jsonData = JSON.parse(_selectedOrder.Data);
                            }
                            else if (_selectedOrder.Data.length > 0) {
                                _jsonData = _selectedOrder.Data;
                            }

                            let updatedReports = currentCartInformation.operatorFilter.length > 0 ?
                                _selectedOrder.SelectedReports + "," + currentCartInformation.operatorFilter[0]?.filterApplied.toString() : _selectedOrder.SelectedReports;

                            if (updatedReports.length > 0) {
                                updatedReports = updatedReports.split(",").filter((item: any, i: any, ar: any) => ar.indexOf(item) === i);
                                updatedReports = updatedReports.toString();
                            }

                            let uniqueRows: any[] = [];

                            dataRows.forEach((rowInfo: any) => {
                                const id = rowInfo["DataAuditId"];
                                const dataExists = _jsonData.filter(a => a.DataAuditId === id).length > 0
                                if (!dataExists) {
                                    uniqueRows.push(rowInfo);
                                }
                            });

                            _jsonData = _jsonData.concat(uniqueRows);
                            isWellCountExceeding = _jsonData.length > wellsRestriction;
                            if (!isWellCountExceeding) {
                                if (_selectedOrder.RequestedBy == operator) {
                                    let index = requestedOrders.indexOf(existingOperatorOrder);

                                    _requestedOrders[index!]["isModified"] = true;
                                    let modAttrs = _requestedOrders[index!]["modifiedAttrs"];
                                    if (modAttrs === undefined) {
                                        _requestedOrders[index!]["modifiedAttrs"] = "Data,SelectedReports";
                                    } else {
                                        modAttrs = modAttrs + ',Data,SelectedReports';
                                        _requestedOrders[index!]["modifiedAttrs"] = modAttrs;
                                    }
                                    _requestedOrders[index!]["Data"] = _jsonData;
                                    _requestedOrders[index!]["SelectedReports"] = updatedReports;
                                    setRequestedOrders(_requestedOrders);
                                }
                                else {
                                    let index = groupedOrders.indexOf(existingOperatorOrder);

                                    _groupedOrders[index!]["isModified"] = true;
                                    let modAttrs = _groupedOrders[index!]["modifiedAttrs"];
                                    if (modAttrs === undefined) {
                                        _groupedOrders[index!]["modifiedAttrs"] = "Data,SelectedReports";
                                    } else {
                                        modAttrs = modAttrs + ',Data,SelectedReports';
                                        _groupedOrders[index!]["modifiedAttrs"] = modAttrs;
                                    }
                                    _groupedOrders[index!]["Data"] = _jsonData;
                                    _groupedOrders[index!]["SelectedReports"] = updatedReports;
                                    setGroupedOrders(_groupedOrders);
                                }
                            } else {
                                formMessage('Cannot add more than ' + wellsRestriction + ' wells to an Order', 'error');
                            }
                        }
                        else {
                            const orderObject = {
                                OrderId: 'N/A',
                                TradeGroupId: currentCartInformation.isExchange ? exchangeOrder.tradeGroupId : 'N/A',
                                TradeType: currentCartInformation.isExchange ? 'Trade' : 'Purchase',
                                StartDate: null,
                                EndDate: null,
                                Amount: 0,
                                RequestedBy: operator,
                                RequestedTo: key,
                                SelectedReports: getCurrentFilter(currentCartInformation, key),
                                Data: dataRows,
                                CommentsShared: "Initial Order",
                                CommentsPrivate: "Initial Order",
                                OrderStatus: 'Drafted',
                                OrderEvent: 'ORDER_DRAFTED',
                                OrderedBy: props.loggedInUser,
                                OrderedDate: currentDate,
                                Info1: "",
                                Info2: "",
                                Info3: "",
                                ActiveYn: "Y",
                                EffStartDate: currentDate,
                                EffEndDate: currentDate,
                                CreatedBy: props.loggedInUser,
                                CreatedDate: currentDate,
                                LastModifiedBy: props.loggedInUser,
                                LastModifiedDate: currentDate,
                                IsCurrent: true,
                                IsGrouped: false,
                                IsPending: false
                            };

                            _currentOrders.push(orderObject);
                        }
                    }
                }
            });

            if (!currentCartInformation.isModified && !currentCartInformation?.isExchange) {
                _requestedOrders = _currentOrders.concat(_requestedOrders);
                setRequestedOrders(_requestedOrders);
            }
        }

        let groupedResult: any[] = [];
        if (currentCartInformation?.isExchange) {
            groupedResult = constructExchangeOrders(_currentOrders);
        } else if (currentCartInformation?.isModified) {
            let _selectedOrder = { ...selectedOrder };
            let _jsonData: any[] = [];
            if (typeof (_selectedOrder.Data) === 'string') {
                _jsonData = JSON.parse(_selectedOrder.Data);
            }
            else if (_selectedOrder.Data.length > 0) {
                _jsonData = _selectedOrder.Data;
            }

            let updatedReports = currentCartInformation.operatorFilter.length > 0 && _selectedOrder.SelectedReports.toLowerCase() !== 'all' ?
                _selectedOrder.SelectedReports + "," + currentCartInformation.operatorFilter[0]?.filterApplied.toString() : _selectedOrder.SelectedReports;
            if (updatedReports.length > 0) {
                updatedReports = updatedReports.split(",").filter((item: any, i: any, ar: any) => ar.indexOf(item) === i);
                updatedReports = updatedReports.toString();
            }

            let uniqueRows: any[] = [];

            currentCartInformation.dataRows.forEach((rowInfo: any) => {
                const id = rowInfo["DataAuditId"];
                const operator = rowInfo["Operator"];
                if (operator === selectedOrder.RequestedTo) {
                    const dataExists = _jsonData.filter(a => a.DataAuditId === id).length > 0
                    if (!dataExists) {
                        uniqueRows.push(rowInfo);
                    }
                }
            });

            _jsonData = _jsonData.concat(uniqueRows)

            let grpOrd = groupedOrders.filter((elem) => {
                return (elem.TradeGroupId === _selectedOrder.TradeGroupId && elem.OrderId < _selectedOrder.OrderId);
            });

            if (_selectedOrder.TradeGroupId == 'N/A' || (_selectedOrder.TradeGroupId !== 'N/A' && grpOrd.length == 0)) {
                let _requestedOrders = [...requestedOrders];
                _requestedOrders[modifiedOrder.rowIndex!]["isModified"] = true;
                let modAttrs = _requestedOrders[modifiedOrder.rowIndex!]["modifiedAttrs"];
                if (modAttrs === undefined) {
                    _requestedOrders[modifiedOrder.rowIndex!]["modifiedAttrs"] = "Data,SelectedReports";
                } else {
                    modAttrs = modAttrs + ',Data,SelectedReports';
                    _requestedOrders[modifiedOrder.rowIndex!]["modifiedAttrs"] = modAttrs;
                }
                _requestedOrders[modifiedOrder.rowIndex!]["Data"] = _jsonData;
                _requestedOrders[modifiedOrder.rowIndex!]["SelectedReports"] = updatedReports;
                setRequestedOrders(_requestedOrders);
            }
            else {

                let _orders: any[] = [...pendingOrders];
                let selectedOrder = { ..._selectedOrder };

                selectedOrder["isModified"] = true;

                let modAttrs = selectedOrder["modifiedAttrs"];
                if (modAttrs === undefined) {
                    selectedOrder["modifiedAttrs"] = "Data,SelectedReports";
                } else {
                    modAttrs = modAttrs + ',Data,SelectedReports';
                    selectedOrder["modifiedAttrs"] = modAttrs;
                }
                selectedOrder["Data"] = _jsonData;
                selectedOrder["SelectedReports"] = updatedReports;

                let newData = { ...grpOrd[0], detailData: [selectedOrder] }

                _orders.splice(modifiedOrder.rowIndex!, 1, newData);
                setPendingOrders(_orders);
            }
        }

        if (isCartModified && !isWellCountExceeding) {
            draftOrder(_requestedOrders, groupedResult);
        }
    }

    const fetchDisplayName = (columnName: string, gridType: string) => {
        let _displayName = columnName;
        let matchingPreferance = [];
        if (gridType == 'requested') {
            matchingPreferance = requestedColumnPreference.filter((element) => { return element.ColumnName == columnName });
        } else {
            matchingPreferance = pendingColumnPreference.filter((element) => { return element.ColumnName == columnName });
        }
        if (matchingPreferance.length > 0) {
            _displayName = matchingPreferance[0].DisplayName;
        }

        return _displayName;
    }

    const constructColumnDefinitions = (tableData: IOrderInfo[], gridType: string, parentGrid?: string) => {
        let columnDefinitions: ITableGridColumnDefinition[] = [];
        columns.forEach((column) => {
            let header = column;
            const pickListColumnDefinition = handlePickListConstruction(header, gridType);
            if (pickListColumnDefinition === undefined || pickListColumnDefinition === false) {
                if (displayColumns.includes(header)) {
                    if (header === "Data") {
                        columnDefinitions.push({
                            field: header,
                            //type: 'editableColumn',
                            headerName: fetchDisplayName(header, gridType),
                            headerTooltip: fetchDisplayName(header, gridType),
                            headerComponentParams: columnHeaderTemplate(fetchDisplayName(header, gridType)),
                            cellRenderer: CartDataRenderer,
                            cellRendererParams: {
                                cartData: tableData,
                                setIsEdittedHandler: (value: boolean) => {
                                    setIsEditted(value);
                                },
                                maxWellsCount: wellsRestriction,
                                isReadOnly: gridType !== 'requested' && gridType !== 'pending',
                                selectedOperator: operator,
                                onAddWells: (value: any, rowIndex: any, rowData: any) => {
                                    let index = -1;
                                    switch (gridType) {
                                        case "requested":

                                            if (rowData.TradeGroupId !== 'N/A' && rowData.RequestedBy === operator) {
                                                // get from pending based on grouped Orders
                                                let grpOrd = groupedOrders.filter((elem) => {
                                                    return (elem.TradeGroupId === rowData.TradeGroupId && elem.OrderId < rowData.OrderId);
                                                });

                                                if (grpOrd.length == 0) {
                                                    index = requestedOrders.findIndex(x => x.OrderId === rowData.OrderId);
                                                }
                                                else {
                                                    index = pendingOrders.findIndex(x => x.TradeGroupId === rowData.TradeGroupId);
                                                }
                                            } else {
                                                index = requestedOrders.findIndex(x => x.OrderId === rowData.OrderId);
                                            }

                                            addNewWells(value, index, rowData)
                                            break;
                                        case "pending":
                                            index = pendingOrders.findIndex(x => x.OrderId === rowData.OrderId);
                                            addNewWells(value, index, rowData)
                                            break;
                                        default:
                                            break;
                                    }
                                },
                                onRemoveWells: (value: any, rowIndex: any, rowData: any) => {
                                    if (rowData?.TradeGroupId === 'N/A') {
                                        removeWellsforOrders(gridType, rowIndex, value, rowData);
                                    } else {
                                        //Check if there is a parent Order
                                        let grpOrd = groupedOrders.filter((elem) => {
                                            return (elem.TradeGroupId === rowData.TradeGroupId && elem.OrderId < rowData.OrderId);
                                        });

                                        if (grpOrd.length > 0) {
                                            let _orders: any[] = [...pendingOrders];
                                            let orderId = rowData.OrderId;
                                            rowData["isModified"] = true;
                                            let modAttrs = rowData["modifiedAttrs"];
                                            if (modAttrs === undefined) {
                                                rowData["modifiedAttrs"] = "Data";
                                            } else {
                                                modAttrs = modAttrs + ',' + "Data";
                                                rowData["modifiedAttrs"] = modAttrs;
                                            }
                                            rowData["Data"] = value;

                                            let newData = { ...grpOrd[0], detailData: [rowData] }

                                            _orders.splice(rowIndex, 1, newData);
                                            setPendingOrders(_orders);

                                            api.LOG_MESSAGE(["Page : Transaction ", "Action : Removed Wells from Order : " + orderId], { 'UserName': props.loggedInUser });

                                        } else {
                                            removeWellsforOrders(gridType, rowIndex, value, rowData);
                                        }
                                    }
                                },
                                setTabIndex: props.setTabIndex,
                                groupedOrders: groupedOrders,
                                orderPickHandler: EstimatesHandler,
                                isFromCart: true
                            },
                            cellClass: ['ag-cell-custom'],
                            cellStyle: { justifyContent: 'center' },
                            hide: isColumnHidden(header, gridType),
                            suppressSizeToFit: true
                        })
                    } else if (header === "SelectedReports") {
                        columnDefinitions.push({
                            field: header,
                            //type: 'editableColumn',
                            headerName: fetchDisplayName(header, gridType),
                            headerTooltip: fetchDisplayName(header, gridType),
                            headerComponentParams: columnHeaderTemplate(fetchDisplayName(header, gridType)),
                            suppressSizeToFit: true,
                            cellRenderer: ReportPickerRenderer,
                            cellRendererParams: {
                                operator: operator,
                                setIsEdittedHandler: (value: boolean) => {
                                    setIsEditted(value);
                                },
                                onColumnSelected: (value: any, rowIndex: any, rowData: any) => {
                                    switch (gridType) {
                                        case "requested":
                                            rowIndex = requestedOrders.findIndex(x => x.OrderId === rowData.OrderId);
                                            let rowNode = requestedGridRef?.current?.api?.getRowNode(rowIndex);
                                            if (rowData?.TradeGroupId === 'N/A') {
                                                let _requestedOrders = [...requestedOrders];
                                                _requestedOrders[rowIndex]["isModified"] = true;
                                                let modAttrs = _requestedOrders[rowIndex]["modifiedAttrs"];
                                                if (modAttrs === undefined) {
                                                    _requestedOrders[rowIndex]["modifiedAttrs"] = header;
                                                } else {
                                                    modAttrs = modAttrs + ',' + header;
                                                    _requestedOrders[rowIndex]["modifiedAttrs"] = modAttrs;
                                                }
                                                _requestedOrders[rowIndex][header] = value;
                                                rowNode.setData({ ..._requestedOrders[rowIndex] });
                                                setRequestedOrders(_requestedOrders);
                                            } else {

                                                //Check if there is a parent Order
                                                let grpOrd = groupedOrders.filter((elem) => {
                                                    return (elem.TradeGroupId === rowData.TradeGroupId && elem.OrderId < rowData.OrderId);
                                                });
                                                if (grpOrd.length > 0) {
                                                    let _orders: any[] = [...pendingOrders];
                                                    let orderId = rowData.OrderId;
                                                    rowData["isModified"] = true;
                                                    let modAttrs = rowData["modifiedAttrs"];
                                                    if (modAttrs === undefined) {
                                                        rowData["modifiedAttrs"] = header;
                                                    } else {
                                                        modAttrs = modAttrs + ',' + header;
                                                        rowData["modifiedAttrs"] = modAttrs;
                                                    }
                                                    rowData[header] = value;

                                                    let newData = { ...grpOrd[0], detailData: [rowData] }

                                                    _orders.splice(rowIndex, 1, newData);
                                                    setPendingOrders(_orders);


                                                } else {
                                                    let _requestedOrders = [...requestedOrders];
                                                    _requestedOrders[rowIndex]["isModified"] = true;
                                                    let modAttrs = _requestedOrders[rowIndex]["modifiedAttrs"];
                                                    if (modAttrs === undefined) {
                                                        _requestedOrders[rowIndex]["modifiedAttrs"] = header;
                                                    } else {
                                                        modAttrs = modAttrs + ',' + header;
                                                        _requestedOrders[rowIndex]["modifiedAttrs"] = modAttrs;
                                                    }
                                                    _requestedOrders[rowIndex][header] = value;
                                                    rowNode.setData({ ..._requestedOrders[rowIndex] });
                                                    setRequestedOrders(_requestedOrders);
                                                }
                                            }
                                            break;

                                        default:
                                            break;
                                    }
                                }
                            },
                            cellClass: ['ag-cell-custom'],
                            cellClassRules: getCellClassRuleHeartbeatSelectedDataSet(gridType),
                            sortable: true,
                            hide: isColumnHidden(header, gridType),
                            cellStyle: { textAlign: 'right' },
                            filter: 'agTextColumnFilter'
                        })
                    } else if (header === "TradeType") {
                        columnDefinitions.push({
                            field: header,
                            //type: 'editableColumn',
                            headerName: fetchDisplayName(header, gridType),
                            headerTooltip: fetchDisplayName(header, gridType),
                            headerComponentParams: columnHeaderTemplate(fetchDisplayName(header, gridType)),
                            suppressSizeToFit: true,
                            cellEditorPopup: true,
                            singleClickEdit: true,
                            editable: gridType === "requested",
                            cellEditor: TradeTypeRenderer,
                            cellEditorParams: {
                                column: header,
                                onValueSelected: (value: any, rowIndex: any, rowData: any) => {
                                    switch (gridType) {
                                        case "requested":
                                            let rowNode = requestedGridRef?.current?.api?.getRowNode(rowIndex);
                                            let _requestedOrders = [...requestedOrders];
                                            _requestedOrders[rowIndex]["isModified"] = true;
                                            let modAttrs = _requestedOrders[rowIndex]["modifiedAttrs"];
                                            if (modAttrs === undefined) {
                                                modAttrs = header;
                                                _requestedOrders[rowIndex]["modifiedAttrs"] = header;
                                            } else {
                                                modAttrs = modAttrs + ',' + header;
                                                _requestedOrders[rowIndex]["modifiedAttrs"] = modAttrs;
                                            }
                                            if (value === 'Rental') {
                                                let currentDate = _requestedOrders[rowIndex]['StartDate'] != null ? new Date(_requestedOrders[rowIndex]['StartDate']) : new Date();
                                                _requestedOrders[rowIndex]['StartDate'] = currentDate.toISOString().slice(0, 10);
                                                modAttrs = modAttrs + ", StartDate";
                                                let newDate = new Date(currentDate.setMonth(currentDate.getMonth() + 3));
                                                _requestedOrders[rowIndex]['EndDate'] = newDate.toISOString().slice(0, 10);
                                                modAttrs = modAttrs + ", EndDate";
                                                _requestedOrders[rowIndex]["modifiedAttrs"] = modAttrs;
                                            }
                                            _requestedOrders[rowIndex][header] = value;
                                            rowNode.setData({ ..._requestedOrders[rowIndex] });
                                            setRequestedOrders(_requestedOrders);
                                            break;
                                        default:
                                            break;
                                    }
                                }
                            },
                            cellClass: ['ag-cell-custom'],
                            sortable: true,
                            hide: isColumnHidden(header, gridType),
                            filter: 'agTextColumnFilter',
                            suppressToolPanel: false
                        })
                    } else if (dateColumns.includes(header)) {
                        columnDefinitions.push({
                            field: header,
                            //type: 'editableColumn',
                            suppressSizeToFit: true,
                            singleClickEdit: true,
                            headerName: fetchDisplayName(header, gridType),
                            headerTooltip: fetchDisplayName(header, gridType),
                            headerComponentParams: columnHeaderTemplate(fetchDisplayName(header, gridType)),
                            cellEditorPopup: true,
                            editable: gridType === "requested",
                            cellEditor: DatePickerEditor,
                            cellEditorParams: {
                                column: header,
                                onDateValueSelected: (value: any, rowIndex: any, rowData: any) => {
                                    switch (gridType) {
                                        case "requested":
                                            let _requestedOrders = [...requestedOrders];
                                            _requestedOrders[rowIndex]["isModified"] = true;
                                            let modAttrs = _requestedOrders[rowIndex]["modifiedAttrs"];
                                            if (modAttrs === undefined) {
                                                _requestedOrders[rowIndex]["modifiedAttrs"] = header;
                                            } else {
                                                modAttrs = modAttrs + ',' + header;
                                                _requestedOrders[rowIndex]["modifiedAttrs"] = modAttrs;
                                            }
                                            _requestedOrders[rowIndex][header] = value;
                                            break;
                                        default:
                                            break;
                                    }
                                }
                            },
                            cellRenderer: DateTimeRenderer,
                            cellRendererParams: {
                                showTime: false
                            },
                            hide: isColumnHidden(header, gridType),
                            cellClass: ['ag-cell-custom'],
                            suppressToolPanel: false,
                            filter: (column.DataType !== "Int32" && column.DataType !== "Int64") ? 'agMultiColumnFilter' : 'agNumberColumnFilter'
                        })
                    } else if (header === "Amount") {
                        columnDefinitions.push({
                            field: header,
                            //type: 'editableColumn',
                            suppressSizeToFit: true,
                            headerName: fetchDisplayName(header, gridType),
                            headerTooltip: fetchDisplayName(header, gridType),
                            headerComponentParams: columnHeaderTemplate(fetchDisplayName(header, gridType)),
                            filter: 'agNumberColumnFilter',
                            cellClass: ['ag-cell-custom'],
                            cellStyle: { textAlign: 'right' },
                            sortable: true,
                            hide: isColumnHidden(header, gridType),
                            editable: false,
                            valueFormatter: currencyFormatter,
                            cellEditor: 'numericCellEditor'
                        })
                    } else if (header === "OrderId") {
                        columnDefinitions.push({
                            field: header,
                            type: 'editable',
                            headerName: fetchDisplayName(header, gridType),
                            headerTooltip: fetchDisplayName(header, gridType),
                            headerComponentParams: columnHeaderTemplate(fetchDisplayName(header, gridType)),
                            suppressSizeToFit: true,
                            sortable: true,
                            cellClass: ['ag-cell-custom'],
                            cellClassRules: getOrderCellClassRules(gridType),
                            cellRenderer: DialogViewRenderer,
                            cellRendererParams: {
                                cartData: tableData,
                                operator: operator,
                                //loadCounter: gridType === "requested" || gridType === "pending",
                                loadCounter: false,
                                loggedInUser: props.loggedInUser,
                                loggedInUserMailId: props.loggedInUserMailId,
                                loadPendingRequests: loadPendingRequests,
                                formMessage: formMessage,
                                setShowProgress: props.setShowProgress,
                                initiateExchange: initiateExchange,
                                gridType: gridType,
                                onEventUpdateGifComplete: (rowIndex: any, rowData: any) => {
                                    switch (gridType) {
                                        case "requested":
                                            let _requestedOrders = [...requestedOrders];
                                            _requestedOrders[rowIndex]["isEventUpdated"] = false;
                                            break;
                                        case "pending":
                                            let _pendingOrders = [...pendingOrders];
                                            _pendingOrders[rowIndex]["isEventUpdated"] = false;
                                            break;
                                        default:
                                            break;
                                    }

                                }
                            },
                            hide: isColumnHidden(header, gridType),
                            suppressToolPanel: false
                        });
                    }
                    else {
                        let columnDefinition: ITableGridColumnDefinition = {
                            field: header,
                            //type: 'editableColumn',
                            headerName: fetchDisplayName(header, gridType),
                            headerTooltip: fetchDisplayName(header, gridType),
                            headerComponentParams: columnHeaderTemplate(fetchDisplayName(header, gridType)),
                            cellClass: ['ag-cell-custom'],
                            sortable: true,
                            hide: isColumnHidden(header, gridType),
                            // sort: (gridType === "grouped" && header === "TradeGroupId") ? 'asc' : 'none',
                            filter: 'agTextColumnFilter'
                        };
                        if (["TradeGroupId", "RequestedBy", "RequestedTo"].includes(header)) {
                            columnDefinition.width = (header.length * 13);
                            columnDefinition.suppressSizeToFit = true;
                        }
                        columnDefinitions.push(columnDefinition);
                    }
                }
            } else {
                pickListColumnDefinition.headerName = header;
                pickListColumnDefinition.headerTooltip = header;
                columnDefinitions.push(pickListColumnDefinition);
            }
        });

        columnDefinitions.push({
            field: 'ApprovalStatus',
            //type: 'editableColumn',
            headerName: fetchDisplayName('ApprovalStatus', gridType),
            headerTooltip: fetchDisplayName('ApprovalStatus', gridType),
            headerComponentParams: columnHeaderTemplate(fetchDisplayName('ApprovalStatus', gridType)),
            cellClass: ['ag-cell-custom'],
            cellRenderer: OrderApprovalRenderer,
            cellRendererParams: {
                loggedInUserMailId: props.loggedInUserMailId,
                loggedInUser: props.loggedInUser,
                loadPendingRequests: loadPendingRequests,
                formMessage: formMessage,
                setShowProgress: props.setShowProgress,
                operator: operator,
                gridType: gridType
            },
            filter: 'agTextColumnFilter',
            hide: isColumnHidden('ApprovalStatus', gridType),
            suppressSizeToFit: true,
            cellStyle: { justifyContent: 'center' }
        });

        columnDefinitions.push({
            field: 'Summary',
            //type: 'editableColumn',
            headerName: fetchDisplayName('Summary', gridType),
            headerTooltip: fetchDisplayName('Summary', gridType),
            headerComponentParams: columnHeaderTemplate(fetchDisplayName('Summary', gridType)),
            cellClass: ['ag-cell-custom'],
            cellRenderer: OrderSummary,
            cellRendererParams: {
                cartData: tableData,
                setIsEdittedHandler: (value: boolean) => {
                    setIsEditted(value);
                },
                reportGroups: reportGroups,
                operator: operator,
                loggedInUserName: props.loggedInUser,
                loggedInUserMailId: props.loggedInUserMailId,
                groupedOrders: [...groupedOrders],
                setTabIndex: props.setTabIndex,
                onAddWells: (value: any, rowIndex: any, rowData: any) => {
                    let index = -1;
                    switch (gridType) {
                        case "requested":
                            if (rowData.TradeGroupId !== 'N/A' && rowData.RequestedBy === operator) {
                                // get from pending based on grouped Orders
                                let grpOrd = groupedOrders.filter((elem) => {
                                    return (elem.TradeGroupId === rowData.TradeGroupId && elem.OrderId < rowData.OrderId);
                                });

                                if (grpOrd.length == 0) {
                                    index = requestedOrders.findIndex(x => x.OrderId === rowData.OrderId);
                                }
                                else {
                                    index = pendingOrders.findIndex(x => x.TradeGroupId === rowData.TradeGroupId);
                                }
                            } else {
                                index = requestedOrders.findIndex(x => x.OrderId === rowData.OrderId);
                            }
                            addNewWells(value, index, rowData)
                            break;
                        case "pending":
                            index = pendingOrders.findIndex(x => x.OrderId === rowData.OrderId);
                            addNewWells(value, index, rowData)
                            break;
                        default:
                            break;
                    }
                }
            },
            filter: 'agTextColumnFilter',
            suppressSizeToFit: true,
            hide: isColumnHidden('Summary', gridType),
            cellStyle: { 'text-align': 'center' }
        });

        columnDefinitions.push({
            field: 'datatransfer',
            //type: 'editableColumn',
            headerName: fetchDisplayName('DataTransfer', gridType),
            headerTooltip: fetchDisplayName('DataTransfer', gridType),
            headerComponentParams: columnHeaderTemplate(fetchDisplayName('DataTransfer', gridType)),
            cellClass: ['ag-cell-custom'],
            cellRenderer: DataTransferRenderer,
            cellRendererParams: {
                orderPickHandler: DataTransferHandler,
                cartData: tableData,
                gridType: gridType
            },
            filter: 'agTextColumnFilter',
            suppressSizeToFit: true,
            cellClassRules: getCellClassRuleHeartbeatDataTransfer(gridType),
            hide: isColumnHidden('datatransfer', gridType),
            cellStyle: { 'text-align': 'center' }
        });

        constructCustomColumnDefinitions(columnDefinitions, gridType);

        columnDefinitions.push({
            field: 'Timeline',
            //type: 'editableColumn',
            headerName: fetchDisplayName('Timeline', gridType),
            headerTooltip: fetchDisplayName('Timeline', gridType),
            headerComponentParams: columnHeaderTemplate(fetchDisplayName('Timeline', gridType)),
            cellClass: ['ag-cell-custom'],
            cellRenderer: TimeLineProgressRenderer,
            cellRendererParams: {
                operator: operator,
                cartData: tableData
            },
            filter: 'agTextColumnFilter',
            width: 200,
            hide: isColumnHidden('Timeline', gridType),
            suppressSizeToFit: true
        });

        columnDefinitions = rearrangeColumnsBasedOnPreference(columnDefinitions, [], gridType);

        let hiddenItem = columnDefinitions.filter((elem: any) => { return !elem.hide });
        hiddenItem[0].headerCheckboxSelection = true;
        hiddenItem[0].checkboxSelection = true;

        return columnDefinitions;
    }

    const isCellEditable = (params: any) => {
        if (params.data.OrderStatus === 'Executed') {
            requestedGridRef.current?.api.stopEditing();
        }
    }

    const constructCustomColumnDefinitions = (columnDefinitions: ITableGridColumnDefinition[], gridType: string) => {
        switch (gridType) {
            case "pending":
                columnDefinitions.push({
                    field: 'valuation',
                    //type: 'editableColumn',
                    headerName: fetchDisplayName('valuation', gridType),
                    headerTooltip: fetchDisplayName('valuation', gridType),
                    headerComponentParams: columnHeaderTemplate(fetchDisplayName('valuation', gridType)),
                    cellClass: ['ag-cell-custom'],
                    cellRenderer: ValuationRenderer,
                    cellRendererParams: {
                        orderPickHandler: ValuationHandler,
                        gridType: gridType
                    },
                    filter: 'agTextColumnFilter',
                    cellClassRules: getCellClassRuleHeartbeatEstVal(gridType),
                    hide: isColumnHidden('valuation', gridType),
                    suppressSizeToFit: true
                });
                break;

            case "requested":
                columnDefinitions.push({
                    field: 'estimates',
                    //type: 'editableColumn',
                    headerName: fetchDisplayName('estimates', gridType),
                    headerTooltip: fetchDisplayName('estimates', gridType),
                    cellClass: ['ag-cell-custom'],
                    cellRenderer: ValuationRenderer,
                    cellClassRules: getCellClassRuleHeartbeatEstVal(gridType),
                    cellRendererParams: {
                        gridType: gridType,
                        orderPickHandler: EstimatesHandler
                    },
                    filter: 'agTextColumnFilter',
                    hide: isColumnHidden('estimates', gridType),
                    suppressSizeToFit: true,
                    cellStyle: { 'text-align': 'center' }
                });

                break;

            default: break;
        }

        return columnDefinitions;
    }

    const loadColumnPreferences = () => {
        async function loadColumnPreferencesData() {
            setTablePendingPreferenceList([]);
            setTableRequestedPreferenceList([]);
            let getDataUrl = '/Common/GetColumnPreferencesByTable?schema=Transaction';
            await api.API_GET(getDataUrl).then((response) => {
                if (response?.data?.length > 0) {

                    let requestedGridData = response?.data.filter((ele: any) => { return ele.TableName.includes('Requested') });
                    let pendingGridData = response?.data.filter((ele: any) => { return ele.TableName.includes('Pending') });

                    SetGridPreferences(requestedGridData, 'Requested');
                    SetGridPreferences(pendingGridData, 'Pending');
                }
            }).catch(error => {
                console.log("error in post call", error)
            }).finally(() => {
            });
        };

        loadColumnPreferencesData();
    }

    const handlePickListConstruction = (columnName: string, gridType: string) => {
        if (pickListDefinitions !== undefined && pickListDefinitions.length > 0) {
            const pickListMatch = pickListDefinitions
                .filter((ele) => {
                    return ele.ColumnName.toLowerCase()
                        === columnName.toLowerCase() &&
                        ele.SchemaName === 'custom' &&
                        ele.TableName === 'cart_myrequest'
                });
            if (pickListMatch.length > 0) {
                if (pickListMatch[0].ListValues[0] === '[') {
                    const pickListColumnDefinition = {
                        field: columnName,
                        //type: 'editableColumn',
                        headerName: columnName.toUpperCase(),
                        headerTooltip: columnName.toUpperCase(),
                        headerComponentParams: columnHeaderTemplate(columnName),
                        sortable: true,
                        filter: true,
                        cellEditorPopup: true,
                        singleClickEdit: true,
                        suppressSizeToFit: true,
                        cellStyle: { textAlign: 'left' },
                        editable: gridType == "requested" ? true : false,
                        cellEditor: DropDownCellRenderer,
                        cellEditorParams: {
                            DropDownOptionsJSON: pickListMatch[0].ListValues,
                            onDropDownValueSelected: (value: any, rowIndex: any) => {
                                switch (gridType) {
                                    case "requested":
                                        let rowNode = requestedGridRef?.current?.api?.getRowNode(rowIndex);
                                        let _requestedOrders = [...requestedOrders];
                                        _requestedOrders[rowIndex]["isModified"] = true;
                                        let modAttrs = _requestedOrders[rowIndex]["modifiedAttrs"];
                                        if (modAttrs === undefined) {
                                            _requestedOrders[rowIndex]["modifiedAttrs"] = columnName;
                                        } else {
                                            modAttrs = modAttrs + ',' + columnName;
                                            _requestedOrders[rowIndex]["modifiedAttrs"] = modAttrs;
                                        }
                                        _requestedOrders[rowIndex][columnName] = value;
                                        rowNode.setData({ ..._requestedOrders[rowIndex] });
                                        setRequestedOrders(_requestedOrders);
                                        break;
                                    default:
                                        break;
                                }
                            }
                        },
                        suppressToolPanel: false,
                        cellClass: ['ag-cell-custom']
                    };

                    return pickListColumnDefinition;
                }
            }
        }
        else {
            return false;
        }
    }

    const removeWellsforOrders = (gridType: string, rowIndex: any, value: any, rowData: any) => {
        let _orders: any[] = [];
        switch (gridType) {
            case "requested":
                _orders = [...requestedOrders];
                break;
            case "pending":
                _orders = [...pendingOrders];
                break;
            default:
                break;
        }

        if (_orders) {
            let index = _orders.findIndex(x => x.OrderId === rowData.OrderId);
            let orderId = _orders[index]["OrderId"];
            _orders[index]["isModified"] = true;
            let modAttrs = _orders[index]["modifiedAttrs"];
            if (modAttrs === undefined) {
                _orders[index]["modifiedAttrs"] = "Data";
            } else {
                modAttrs = modAttrs + ',' + "Data";
                _orders[index]["modifiedAttrs"] = modAttrs;
            }
            _orders[index]["Data"] = value;

            switch (gridType) {
                case "requested":
                    setRequestedOrders(_orders);
                    break;
                case "pending":
                    setPendingOrders(_orders);
                    break;
                default:
            }

            api.LOG_MESSAGE(["Page : Transaction ", "Action : Removed Wells from Order : " + orderId], { 'UserName': props.loggedInUser });
        }
    }

    function SetGridPreferences(response: any, gridType: any) {
        let globalPreference = response?.filter((ele: any) => { return ele.PreferenceName.toLowerCase().includes('global'); });
        let defaultPreference = response?.filter((ele: any) => { return ele.UserMailId == props.loggedInUserMailId && ele.IsDefault; });
        let preferences = response?.filter((ele: any) => {
            return ((ele.UserMailId === props.loggedInUserMailId) ||
                (ele.UserMailId !== props.loggedInUserMailId && ele.IsPublic));
        });

        switch (gridType) {
            case 'Pending':
                setTablePendingPreferenceList(preferences);
                break;
            case 'Requested':
                setTableRequestedPreferenceList(preferences);
                break;
        }

        if (defaultPreference.length > 0) {
            switch (gridType) {
                case 'Pending':
                    setselectedPendingPreference(defaultPreference[0]);
                    break;
                case 'Requested':
                    setselectedRequestedPreference(defaultPreference[0]);
                    break;
            }

        }
        else if (globalPreference.length > 0) {
            switch (gridType) {
                case 'Pending':
                    setselectedPendingPreference(globalPreference[0]);
                    break;
                case 'Requested':
                    setselectedRequestedPreference(globalPreference[0]);
                    break;
            }
        }
    }

    const handleClickColumnsOpen = (currentTarget: any, gridType: any) => {
        setInitialColumnPreferenceState(gridType);
        setAnchorEl(currentTarget);
        setcolumnsPerfOpen(!columnsPerfOpen);
    };

    const applyDisplayColumnNames = (_columnPreference: any[], colDefs: any, gridType: any) => {
        colDefs.forEach((colDef: any) => {
            let colPref = _columnPreference.filter((ele) => { return ele.ColumnName == colDef.field });
            if (colPref.length > 0) {
                colDef.headerName = colPref[0].DisplayName;
                colDef.headerComponentParams = columnHeaderTemplate(colPref[0].DisplayName);
            }
        });

        return colDefs;
    }

    const applyColumnPreference = (_columnPreference: any[], gridType: any) => {
        let gridModel: any = {}
        let apiModel: any = {}
        let gridData: any[] = []
        let colDefs: any[] = []
        switch (gridType) {
            case 'Pending':
                colDefs = [...pendingColumnDefinitions]
                gridModel = pendingGridRef.current?.columnApi?.columnModel;
                apiModel = pendingGridRef.current?.api;
                gridData = pendingOrders;
                break;
            case 'Requested':
                colDefs = [...requestedColumnDefinitions]
                gridModel = requestedGridRef.current?.columnApi?.columnModel;
                apiModel = requestedGridRef.current?.api;
                gridData = requestedOrders;

                break;
        }

        let updatedColumnDefs = rearrangeColumnsBasedOnPreference(colDefs, _columnPreference, gridType);
        _columnPreference.forEach((colPreference: any) => {
            let columnDefMatch = updatedColumnDefs.filter((element: any) => { return element.headerName === colPreference.ColumnName });
            if (columnDefMatch.length > 0) {
                if (colPreference.Enable === false) {
                    updatedColumnDefs[updatedColumnDefs.indexOf(columnDefMatch[0])].hide = true;
                } else {
                    updatedColumnDefs[updatedColumnDefs.indexOf(columnDefMatch[0])].hide = false;
                }
            }
        });

        updatedColumnDefs.forEach((elem: any) => {
            elem.headerCheckboxSelection = false;
            elem.checkboxSelection = false;
        });

        let visibleItem = updatedColumnDefs.filter((elem: any) => { return !elem.hide });
        if (visibleItem && visibleItem.length > 0) {
            visibleItem[0].headerCheckboxSelection = true;
            visibleItem[0].checkboxSelection = true;
        }

        gridModel?.setColumnDefs(applyDisplayColumnNames(_columnPreference, updatedColumnDefs, gridType));
        apiModel?.setRowData(gridData);
    }

    const rearrangeColumnsBasedOnPreference = (columnDefinitions: any[], _columnPreference: any[], gridType: any) => {
        let _reArrangedColumnDefinitions: any[] = [];
        let colPref: any[] = [];
        switch (gridType) {
            case 'Pending':
            case 'pending':
                colPref = [...pendingColumnPreference]
                break;
            case 'Requested':
            case 'requested':
                colPref = [...requestedColumnPreference]
                break;
        }

        let colPrefs = _columnPreference.length > 0 ? _columnPreference : colPref;

        if (colPrefs != null && colPrefs.length > 0) {
            colPrefs.forEach((preference: any) => {
                const columnDefinitionObj = columnDefinitions.filter((ele: any) => { return ele.field.toLowerCase() === preference.ColumnName.toLowerCase() || ele.headerName?.trim().toLowerCase() === preference.ColumnName.toLowerCase() });
                if (columnDefinitionObj.length > 0) {
                    _reArrangedColumnDefinitions.push(columnDefinitionObj[0]);
                }
            });

            const columnsNotInPreference = columnDefinitions.filter(o => !colPrefs.some(({ ColumnName }) => o.field.toLowerCase() === ColumnName.toLowerCase() || o.headerName?.trim().toLowerCase() === ColumnName.toLowerCase()));
            columnsNotInPreference.forEach((column: any) => {
                _reArrangedColumnDefinitions.push(column);
            });
        }

        return _reArrangedColumnDefinitions.length > 0 ? _reArrangedColumnDefinitions : columnDefinitions;
    }

    const isColumnHidden = (columnName: string, gridType: any) => {
        let colPref: any[] = [];
        switch (gridType) {
            case 'Pending':
            case 'pending':
                colPref = [...pendingColumnPreference]
                break;
            case 'Requested':
            case 'requested':
                colPref = [...requestedColumnPreference]
                break;
        }

        if (colPref != null) {
            let colMatch = colPref.filter((element: any) => {
                return element.ColumnName === columnName;
            });
            if (colMatch.length > 0) {
                return !colMatch[0].Enable;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }

    const setInitialColumnPreferenceState = (gridType: string) => {
        let columnPreference: any[] = [];
        let displayedColumns: any[] = [];
        let selectedPrefrence: any = null;
        switch (gridType) {
            case 'Pending':
                displayedColumns = pendingGridRef.current?.columnApi?.columnModel?.gridColumns;
                setTablePreferenceList(tablePendingPreferenceList);
                selectedPrefrence = selectedPendingPreference ?? null;
                break;
            case 'Requested':
                displayedColumns = requestedGridRef.current?.columnApi?.columnModel?.gridColumns;
                selectedPrefrence = selectedRequestedPreference ?? null;
                setTablePreferenceList(tableRequestedPreferenceList);
                break;
        }

        if (selectedPrefrence == null || selectedPrefrence == undefined) {
            displayedColumns?.forEach((element: any) => {
                columnPreference.push({
                    ColumnName: element.colDef?.headerName,
                    Enable: true,
                    AvailableOrDisplayed: "D" // A for Available columns , D for Displayed columns
                });
            });

            if (columnPreference.length > 0) {
                let preference: any = {
                    PreferenceJson: columnPreference,
                    PreferenceId: 0,
                    PreferenceName: '',
                    SchemaName: 'Transaction',
                    TableName: gridType,
                    UserMailId: props.loggedInUserMailId,
                    IsPublic: true,
                    IsDefault: false,
                    CreatedBy: props.loggedInUserMailId
                };

                switch (gridType) {
                    case 'Pending':
                        setselectedPendingPreference(preference);
                        setselectedPreference(preference);
                        break;
                    case 'Requested':
                        setselectedRequestedPreference(preference);
                        setselectedPreference(preference);
                        break;
                }
            }
        }
        else {
            setselectedPreference(selectedPrefrence);
        }
    }

    const applyColumnPreferenceHandler = async (selectedPreferene: any) => {
        switch (selectedPreferene.TableName) {
            case 'Pending':
                setselectedPendingPreference(selectedPreferene);
                break;
            case 'Requested':
                setselectedRequestedPreference(selectedPreferene);
                break;
        }

        applyColumnPreference(selectedPreferene.PreferenceJson, selectedPreferene.TableName);
    }

    const ValuationHandler = (orderData: any) => {
        if (orderData.data.OrderEvent !== "ORDER_CLOSED" && ValuationHandlerValidation(orderData)) {
            let orderArray: any[] = [];
            orderArray.push(orderData.data);
            props.setTabIndex(TabOrder.Valuation);
            props.setSelectedCartOrder(orderArray);
        }
    }

    const ValuationHandlerValidation = (orderData:any) =>{
        let result = true;

        if(orderData.data.TradeGroupId === 'N/A' && orderData.data.TradeType === "Trade"){
            result = false;
            formMessage("Please create a counter order.","error");
        }
        return result;
    }

    const DataTransferHandler = (orderData: any) => {
        if (orderData.data.OrderEvent === "EXT_WORKFLOW_COMPLETED") {
            let orderArray: any[] = [];
            orderArray.push(orderData.data);
            props.setTabIndex(TabOrder.DataTransfer);
            props.setSelectedCartOrder(orderArray);
        }
    }

    const PopulateEstimatesHandler = (orderData: any) => {
        props.setTabIndex(TabOrder.Estimate);
        props.setSelectedCartOrder(orderData);
    }

    const EstimatesHandler = (orderData: any) => {
        if (orderData.data.OrderEvent !== "ORDER_CLOSED") {
            let orderArray: any[] = [];
            orderArray.push(orderData.data);
            props.setTabIndex(TabOrder.Estimate);
            props.setSelectedCartOrder(orderArray);
        }
    }

    const handleGridReady = (params: any) => {
        setColumnApi(params.columnApi);
    }

    const loadCurrentInformation = () => {
        if (requestedOrders !== undefined && requestedOrders.length >= 0) {
            let columnDefinitions = constructColumnDefinitions(requestedOrders, "requested");
            setRequestedColumnDefinitions(columnDefinitions);
            if (requestedGridRef !== undefined && requestedGridRef.current !== undefined) {
                if (requestedGridRef.current.api !== undefined) {
                    requestedGridRef.current.api.setColumnDefs(columnDefinitions);
                    if (requestedGridRef.current.api.getRenderedNodes() === undefined) {
                        requestedGridRef.current.api.setRowData(requestedOrders);
                    }
                    requestedGridRef.current.api.sizeColumnsToFit();
                } else {
                    requestedGridRef.current.props.gridOptions.columnDefs = columnDefinitions;
                    requestedGridRef.current.props.gridOptions.rowData = requestedOrders;
                }
            }
        }
    }

    const loadPendingInformation = () => {
        if (pendingOrders !== undefined && pendingOrders.length >= 0) {
            let columnDefinitions = constructColumnDefinitions(pendingOrders, "pending");
            setPendingColumnDefinitions(columnDefinitions);
            if (pendingGridRef !== undefined && pendingGridRef.current !== undefined) {
                if (pendingGridRef.current.api !== undefined) {
                    pendingGridRef.current.api.setColumnDefs(columnDefinitions);
                    pendingGridRef.current.api.setRowData(pendingOrders);
                    pendingGridRef.current.api.sizeColumnsToFit();
                } else {
                    pendingGridRef.current.props.gridOptions.columnDefs = columnDefinitions;
                    pendingGridRef.current.props.gridOptions.rowData = pendingOrders;
                }
            }
        }
    }
    const loadGroupedInformation = () => {
        if (groupedOrders !== undefined && groupedOrders.length >= 0) {
            let columnDefinitions = constructColumnDefinitions(groupedOrders, "grouped");
            setTradeColumnDefinitions(columnDefinitions);
            if (groupedGridRef !== undefined && groupedGridRef.current !== undefined) {
                if (groupedGridRef.current.api !== undefined) {
                    groupedGridRef.current.api.setColumnDefs(columnDefinitions);
                    groupedGridRef.current.api.setRowData(groupedOrders);
                    groupedGridRef.current.api.sizeColumnsToFit();
                } else {
                    groupedGridRef.current.props.gridOptions.columnDefs = columnDefinitions;
                    groupedGridRef.current.props.gridOptions.rowData = groupedOrders;
                }
            }
        }
    }

    const loadCartReportGroupData = () => {
        if (typeof (reportGroups) != "undefined" && reportGroups.length >= 0) {
            const groupReports = groupByObjectArray(reportGroups, 'GroupName');
            const groups = Object.keys(groupReports);
            const reportGroupRenderTree: RenderTree[] = [];
            groups.forEach((group: any, index: any) => {
                reportGroupRenderTree.push(loadCartReportGroup(group, groupReports, index));
            });
            setReportSelectionData(reportGroupRenderTree);
        }
    }

    const loadCartReportGroup = (group: any, groupReports: any, index: any) => {
        return {
            id: index.toString(),
            name: group,
            children: groupReports[group].length > 0 ? loadCartReportGroupItem(groupReports[group], index) : undefined
        }
    }

    const loadCartReportGroupItem = (groupItems: any[], parentIndex: any) => {
        const reportGroupRenderItemsTree: RenderTree[] = [];
        groupItems.forEach((groupItem: any, index: any) => {
            reportGroupRenderItemsTree.push({
                id: parentIndex.toString() + "-" + index.toString(),
                name: groupItem.ReportName
            });
        });
        return reportGroupRenderItemsTree;
    }

    const loadPendingRequests = async (event?: string) => {
        props.setShowProgress(true);
        if (operator !== undefined) {
            let orderDataUrl = '/OrderManagement/getOrderTableData?schema=orders&table=ORDER_MANAGEMENT&operatorName=' + operator;
            await api.API_GET(orderDataUrl).then((response) => {
                if (response?.data?.Orders && response?.data?.Orders.length > 0) {
                    setColumns(Object.keys(response.data.Orders[0]));
                    loadPendingAndGroupGridData(response?.data.Orders);
                } else {
                    setRequestedOrders([]);
                    setPendingOrders([]);
                    setGroupedOrders([]);
                    props.setShowProgress(false);
                }
                switch (event?.toLowerCase()) {
                    case 'draft':
                        formMessage('Order drafted successfully', 'success');
                        props.setCartInformation([]);
                        props.setShowProgress(false);
                        break;
                    case 'create':
                        formMessage('Order placed successfully', 'success');
                        props.setCartInformation([]);
                        props.setShowProgress(false);
                        break;
                    case 'update':
                        formMessage('Order updated successfully', 'success');
                        props.setShowProgress(false);
                        break;
                    case 'countercreate':
                        formMessage('Counter order created successfully', 'success');
                        props.setShowProgress(false);
                        break;
                    case 'counterupdate':
                        formMessage('Counter order updated successfully', 'success');
                        props.setShowProgress(false);
                        break;
                    case 'cancel':
                        formMessage('Order Cancelled successfully', 'success');
                        props.setCartInformation([]);
                        props.setShowProgress(false);
                        break;
                    default:
                        props.setShowProgress(false);
                }
            }).catch((error) => {
                console.log('Error while retrieving order details', error);
                props.setShowProgress(false);
            })
        }
    }

    const loadPendingAndGroupGridData = (responseData: any) => {
        let tableData: any[] = responseData;
        let _requestedOrders: any[] = [];
        let _pendingOrders: any[] = [];
        let _groupedOrders: any[] = [];
        let grpOrders = tableData.filter((data) => {
            return (data.TradeGroupId !== 'N/A');
        });

        tableData = tableData.filter((data) => {
            return (data.RequestedBy === operator || data.RequestedTo === operator);
        });

        let skipOrders: any[] = [];

        tableData.forEach((data) => {
            if (data.TradeGroupId === "N/A") {
                if (data.RequestedBy === operator) {
                    data.IsRequested = true;
                    data.IsGrouped = false;
                    data.IsPending = false;
                    _requestedOrders.push(data);
                } else if (data.RequestedTo === operator && data.OrderStatus !== "Pending" && data.OrderStatus !== "Drafted") {
                    data.IsRequested = false;
                    data.IsGrouped = false;
                    data.IsPending = true;
                    _pendingOrders.push(data);
                }
            } else if (data.TradeGroupId !== "N/A") {

                let relatedOrders = grpOrders.filter((elem) => {
                    return (elem.TradeGroupId === data.TradeGroupId);
                });

                relatedOrders = relatedOrders.sort((a, b) => {
                    return a.OrderId - b.OrderId;
                });

                if (relatedOrders.length >= 2) {
                    for (let i = 1; i < relatedOrders.length; i++) {
                        skipOrders.push(relatedOrders[i].OrderId);
                    }
                }

                if (!skipOrders.includes(data.OrderId)) {
                    if (data.RequestedBy === operator) {
                        data.IsRequested = true;
                        data.IsGrouped = false;
                        data.IsPending = false;

                        let grpedOrders = grpOrders.filter((elem) => {
                            return (elem.TradeGroupId === data.TradeGroupId && elem.RequestedTo === operator &&
                                elem.OrderId !== data.OrderId && elem.OrderStatus !== "Pending" && elem.OrderStatus !== "Drafted");
                        });

                        let newData = { ...data, detailData: grpedOrders }
                        _requestedOrders.push(newData);

                    } else if (data.RequestedTo === operator && data.OrderStatus !== "Pending" && data.OrderStatus !== "Drafted") {
                        data.IsRequested = false;
                        data.IsGrouped = false;
                        data.IsPending = true;

                        let grpedOrders = grpOrders.filter((elem) => {
                            return (elem.TradeGroupId === data.TradeGroupId && elem.RequestedBy === operator &&
                                elem.OrderId !== data.OrderId);
                        });

                        let newData = { ...data, detailData: grpedOrders }
                        _pendingOrders.push(newData);
                    }

                }

                data.IsRequested = false;
                data.IsGrouped = true;
                data.IsPending = false;
                _groupedOrders.push(data)
            }
        });
        _requestedOrders = _requestedOrders.concat(currentOrders);
        setRequestedOrders(_requestedOrders);
        setPendingOrders(_pendingOrders);
        setGroupedOrders(_groupedOrders);
    }

    const detailCellRendererParams = (params: any) => {
        if (params.data.detailData) {
            let coldef = constructColumnDefinitions(params.data.detailData, "pending");
            return {

                detailGridOptions: {
                    columnDefs: coldef,
                    getRowStyle: getRowStyle,
                    getRowHeight: getRowHeight,
                    defaultColDef: {
                        flex: 1,
                        resizable: true,
                        wrapHeaderText: true,
                        autoHeaderHeight: true,
                        editable: false,
                        headerCheckboxSelectionFilteredOnly: true,
                        suppressSizeToFit: true
                    },
                },
                getDetailRowData: (params) => {
                    params.successCallback(params.data.detailData);

                },
                refreshStrategy: 'nothing'
            } as IDetailCellRendererParams<any, any>;
        }
    };

    const detailPendingCellRendererParams = (params: any) => {
        if (params.data.detailData) {
            let coldef = constructColumnDefinitions(params.data.detailData, "requested");
            return {
                detailGridOptions: {
                    context: { componentParent: params.data },
                    columnDefs: coldef,
                    getRowStyle: getRowStyle,
                    getRowHeight: getRowHeight,
                    defaultColDef: {
                        flex: 1,
                        resizable: true,
                        wrapHeaderText: true,
                        autoHeaderHeight: true,
                        editable: false,
                        headerCheckboxSelectionFilteredOnly: true
                    },
                },
                getDetailRowData: (params) => {
                    params.successCallback(params.data.detailData);
                },
            } as IDetailCellRendererParams<any, any>;
        }
    };

    const clearCart = () => {
        setRequestedOrders([]);
        setPendingOrders([]);
        setGroupedOrders([]);
        props.setCartInformation([]);
        loadPendingRequests();
        formMessage('Cart refreshed successfully', 'success');
        api.LOG_MESSAGE(["Page : Transaction ", "Action : Clear Order Successfull"], { 'UserName': props.loggedInUser });
    }

    const draftOrder = (requestedOrds: any[], groupedOrds: any[]) => {

        let newOrders: any[] = [];

        requestedOrds?.forEach((cartItem: any) => {
            if (cartItem.OrderId === 'N/A') {
                let _cartItem = cartItem;
                _cartItem.Data = JSON.stringify(_cartItem.Data);
                _cartItem.OrderId = 0;
                _cartItem.TradeType = "Trade";
                newOrders.push(_cartItem);
            } else if (cartItem.OrderId !== 'N/A' && cartItem.OrderEvent === 'ORDER_DRAFTED') {
                let _cartItem = cartItem;
                const modifiedValues = cartItem.modifiedAttrs;
                if (modifiedValues?.includes('Data') || modifiedValues?.includes('SelectedReports')) {
                    if (typeof (_cartItem.Data) === 'string') {
                    }
                    else if (_cartItem.Data.length > 0) {
                        _cartItem.Data = JSON.stringify(_cartItem.Data);
                    }
                }

                newOrders.push(_cartItem);
            }
        });

        let hasExchangeOrders: boolean = false;
        groupedOrds?.forEach((cartItem: any) => {
            if (cartItem.OrderId === 'N/A') {
                hasExchangeOrders = true;
                let _cartItem = cartItem;
                _cartItem.Data = JSON.stringify(_cartItem.Data);
                _cartItem.OrderId = 0;
                newOrders.push(_cartItem);
            } else if (cartItem.OrderId !== 'N/A' && cartItem.OrderEvent === 'ORDER_DRAFTED') {
                let _cartItem = cartItem;
                hasExchangeOrders = true;
                const modifiedValues = cartItem.modifiedAttrs;
                if (modifiedValues?.includes('Data') || modifiedValues?.includes('SelectedReports')) {
                    if (typeof (_cartItem.Data) === 'string') {
                    }
                    else if (_cartItem.Data.length > 0) {
                        _cartItem.Data = JSON.stringify(_cartItem.Data);
                    }
                }

                newOrders.push(_cartItem);
            } else {
                let _cartItem = cartItem;
                newOrders.push(_cartItem);
            }
        });

        if (hasExchangeOrders) {
            saveGroupedOrders(groupedOrders);
        }

        if (newOrders.length > 0) {
            draftOrder();
        }

        async function draftOrder() {
            props.setShowProgress(true);
            await api.API_POST('/OrderManagement/saveOrder', {
                orders: newOrders,
                GenerateWorkflow: false,
                Environment: api.env
            }).then((response) => {
                api.LOG_MESSAGE(["Page : Transaction ", "Action : Order Drafted Successfull"], { 'UserName': props.loggedInUser });
                eventMessaging.DrafterOrderBroadcast(newOrders);
                loadPendingRequests('draft');
            }).catch(() => {
                formMessage('Error while saving order', 'error');

                props.setCartInformation([]);
                props.setShowProgress(false);
                loadPendingRequests();
            });
        }
    }

    const placeOrder = () => {
        let hasAllReports: boolean = false;
        let hasUnPopulatedEstimates: boolean = false;
        let estimateOrder: any[] = [];
        let requestDrafedOrders: any[] = requestedGridRef.current!.api.getSelectedRows();

        requestDrafedOrders.forEach((cartItem: any) => {
            if (cartItem.OrderEvent === 'ORDER_DRAFTED' || cartItem.OrderId === 'N/A') {
                if (cartItem.SelectedReports === 'All') {
                    hasAllReports = true;
                }

                if (cartItem.ZeroCurrentValueCount > 0 || cartItem.ZeroProposedValueCount > 0) {
                    hasUnPopulatedEstimates = true;
                    estimateOrder.push(cartItem);
                }
            }
        });

        pendingGridRef.current!.api.forEachDetailGridInfo(function (detailGridApi: any) {
            let pendingGroupedDrafted: any[] = detailGridApi.api.getSelectedRows();
            pendingGroupedDrafted.forEach((cartItem: any) => {
                if (cartItem.OrderEvent === 'ORDER_DRAFTED' || cartItem.OrderId === 'N/A') {
                    if (cartItem.SelectedReports === 'All') {
                        hasAllReports = true;
                    }

                    if (cartItem.ZeroCurrentValueCount > 0 || cartItem.ZeroProposedValueCount > 0) {
                        hasUnPopulatedEstimates = true;
                        estimateOrder.push(cartItem);
                    }
                }
            });
        });

        if (hasUnPopulatedEstimates) {
            let confirmDialogObject = {
                title: "Place order",
                body: "Populate the Current values and Proposed values in Estimates before placing the Order",
                okHandler: () => { PopulateEstimatesHandler(estimateOrder) }
            };

            setConfirmDialogObject(confirmDialogObject);
            setOpenConfirmDialog(true);
        }
        else {
            if (hasAllReports) {
                let confirmDialogObject = {
                    title: "Place order",
                    body: "You have selected All Datasets. Click Yes to continue",
                    okHandler: SaveOrderApi
                };

                setConfirmDialogObject(confirmDialogObject);
                setOpenConfirmDialog(true);
            }
            else {
                SaveOrderApi();
            }
        }
    }

    const SaveOrderApi = () => {
        let newOrders: any[] = [];
        let requestDrafedOrders: any[] = requestedGridRef.current!.api.getSelectedRows();
        let pendingGroupedDrafted: any[] = pendingGridRef.current!.api.getSelectedRows();


        requestDrafedOrders.forEach((cartItem: any) => {
            if (cartItem.OrderEvent === 'ORDER_DRAFTED' || cartItem.OrderId === 'N/A') {
                let _cartItem = cartItem;
                if (typeof (_cartItem.Data) === 'string') {
                }
                else if (_cartItem.Data.length > 0) {
                    _cartItem.Data = JSON.stringify(_cartItem.Data);
                }

                _cartItem.OrderEvent = "ORDER_CREATED"
                _cartItem.Amount = _cartItem.Amount ? _cartItem.Amount : 0;
                _cartItem.OrderId = cartItem.OrderId === 'N/A' ? 0 : cartItem.OrderId;
                newOrders.push(_cartItem);
            }
        });

        //let groupedPendingOrders = pendingGroupedDrafted.filter((element: any) => { return element.TradeGroupId !== 'N/A' });

        let hasExchangeOrders: boolean = false;
        pendingGridRef.current!.api.forEachDetailGridInfo(function (detailGridApi: any) {
            let pendingGroupedDrafted: any[] = detailGridApi.api.getSelectedRows();
            pendingGroupedDrafted.forEach((cartItem: any) => {
                if (cartItem.OrderEvent === 'ORDER_DRAFTED' || cartItem.OrderId === 'N/A') {
                    hasExchangeOrders = true;
                    let _cartItem = cartItem;
                    if (typeof (_cartItem.Data) === 'string') {
                    }
                    else if (_cartItem.Data.length > 0) {
                        _cartItem.Data = JSON.stringify(_cartItem.Data);
                    }

                    _cartItem.OrderEvent = "ORDER_CREATED"
                    _cartItem.Amount = _cartItem.Amount ? _cartItem.Amount : 0;
                    _cartItem.OrderId = cartItem.OrderId === 'N/A' ? 0 : cartItem.OrderId;
                    newOrders.push(_cartItem);
                }
            });
        });

        if (hasExchangeOrders) {
            saveGroupedOrders(groupedOrders);
        }

        if (newOrders.length <= 0) {
            formMessage('No orders to create. Select the checkbox on the order number to place order', 'info');
        } else {
            placeOrder();
        }

        async function placeOrder() {
            props.setShowProgress(true);
            await api.API_POST('/OrderManagement/saveOrder', {
                orders: newOrders,
                GenerateWorkflow: true,
                Environment: api.env
            }).then((response) => {
                loadPendingRequests('create'); // Commenting this has we are instant updating node //
                //updateNodeDataHandler(newOrders, 'create');// TODO : enable when node update is fixed
                props.setShowProgress(false);
                api.LOG_MESSAGE(["Page : Transaction ", "Action : Place Order Successfull"], { 'UserName': props.loggedInUser });
            }).catch(() => {
                formMessage('Error while saving order', 'error');
                props.setShowProgress(false);
                loadPendingRequests();
            });
        }
    }

    const updateNodeDataHandler = (changedObjects: any[], type: string, isBroadcast: boolean = true) => {
        let gridRef = requestedGridRef;
        let dataState = requestedOrders;
        let orderObject = undefined;
        let isChildOrderUpdate = false;
        changedObjects.forEach((changeObj: any, index: number) => {
            gridRef = requestedGridRef;
            dataState = requestedOrders;
            isChildOrderUpdate = false;
            orderObject = requestedOrders.find((element: any) => { //Check if its a requested order //
                return element.OrderId === changeObj.OrderId
            });
            if (orderObject == undefined) { // else if its a pending order //
                orderObject = pendingOrders.find((element: any) => {
                    return element.OrderId === changeObj.OrderId
                });
                if (orderObject !== undefined) {
                    gridRef = pendingGridRef;
                    dataState = pendingOrders;
                } else {
                    // Checking for pending child //
                    const _pendingMatch = pendingOrders.filter((element) => { return element.detailData && element.detailData.length > 0 && element.detailData[0].OrderId === changeObj.OrderId });
                    if (_pendingMatch.length > 0) {
                        orderObject = _pendingMatch[0];
                    }

                    if (orderObject !== undefined) {
                        orderObject["detailData"] = [{...changeObj,isModified:false}];
                        isChildOrderUpdate = true;

                        gridRef = pendingGridRef;
                        dataState = pendingOrders;
                    } else {
                        // Checking for requested child //
                        const _requestedMatch = requestedOrders.filter((element) => { return element.detailData && element.detailData.length > 0 && element.detailData[0].OrderId === changeObj.OrderId });
                        if (_requestedMatch.length > 0) {
                            orderObject = _requestedMatch[0];
                        }

                        if (orderObject !== undefined) {
                            orderObject["detailData"] = [{...changeObj,isModified:false}];
                            isChildOrderUpdate = true;

                            gridRef = requestedGridRef;
                            dataState = requestedOrders;
                        }else{
                            loadPendingRequests('messaging');
                        }
                    }
                }
            }

            if (orderObject !== undefined) {
                if (isBroadcast == true) { // If its not broadcasting its a incoming update via messaging //
                    orderObject["isEventUpdated"] = true;
                    updateNodeDataRequestedOrders(orderObject, gridRef, dataState, type, isBroadcast);
                } else {
                    changeObj["isEventUpdated"] = true;
                    updateNodeDataRequestedOrders(isChildOrderUpdate ? orderObject : changeObj, gridRef, dataState, type, isBroadcast);
                }
            }

        });
    }

    const updateNodeDataRequestedOrders = (orderObject: any, gridRef: any, dataState: any[], type: string, isBroadcast: boolean) => {

        if (type === 'create') {
            orderObject.OrderStatus = "Pending";
            orderObject.OrderEvent = "INT_WORKFLOW_INITIATED";
            if (isBroadcast) {
                if (orderObject?.OrderId !== undefined) {
                    eventMessaging.GetOrderAndBroadcastOrderUpdate(orderObject?.OrderId, operator);
                }
            }
        }
        if (type === 'update') {
            if (orderObject?.isModified) {
                orderObject.isModified = null;
            }
            if (isBroadcast) {
                if (orderObject?.OrderId !== undefined) {
                    eventMessaging.GetOrderAndBroadcastOrderUpdate(orderObject?.OrderId, operator);
                }
            }
        }

        if (orderObject !== null && orderObject !== undefined) {
            let nodeIndex = dataState.findIndex(x => x.OrderId === orderObject.OrderId);
            let rowNode = gridRef?.current?.api?.getRowNode(nodeIndex);
            let combineObj = { ...rowNode.data, ...orderObject };
            rowNode.setData(combineObj);


            //user message
            if (isBroadcast) {
                switch (type?.toLowerCase()) {
                    case 'draft':
                        formMessage('Order drafted successfully', 'success');
                        props.setCartInformation([]);
                        props.setShowProgress(false);
                        break;
                    case 'create':
                        formMessage('Order placed successfully', 'success');
                        props.setCartInformation([]);
                        props.setShowProgress(false);
                        break;
                    case 'update':
                        formMessage('Order updated successfully', 'success');
                        props.setShowProgress(false);
                        break;
                    case 'countercreate':
                        formMessage('Counter order created successfully', 'success');
                        props.setShowProgress(false);
                        break;
                    case 'counterupdate':
                        formMessage('Counter order updated successfully', 'success');
                        props.setShowProgress(false);
                        break;
                    case 'cancel':
                        formMessage('Order Cancelled successfully', 'success');
                        props.setCartInformation([]);
                        props.setShowProgress(false);
                        break;
                    default:
                        props.setShowProgress(false);
                }
            }
        }

        gridRef?.current?.api?.redrawRows();
    }

    const updateOrders = () => {
        let updatedOrders: any[] = [];
        let currentDate = new Date().toISOString();

        requestedOrders.forEach((cartItem: any) => {
            if (cartItem.OrderId !== 'N/A' || cartItem.OrderEvent === 'ORDER_DRAFTED') {
                if (cartItem.isModified) {
                    let updatedOrder: IOrderInfo = {
                        OrderId: cartItem.OrderId,
                        LastModifiedBy: props.loggedInUser,
                        LastModifiedDate: currentDate,
                        PaymentFrequency: cartItem.PaymentFrequency
                    };
                    const modifiedValues = cartItem.modifiedAttrs;
                    if (modifiedValues.includes('TradeType')) {
                        updatedOrder.TradeType = cartItem.TradeType;
                    }
                    if (modifiedValues.includes('StartDate')) {
                        updatedOrder.StartDate = cartItem.StartDate;
                    }
                    if (modifiedValues.includes('EndDate')) {
                        updatedOrder.EndDate = cartItem.EndDate;
                    }
                    if (modifiedValues.includes('Amount')) {
                        updatedOrder.Amount = cartItem.Amount;
                    }
                    if (modifiedValues.includes('Data')) {
                        updatedOrder.Data = JSON.stringify(cartItem.Data);
                    }
                    if (modifiedValues.includes('SelectedReports')) {
                        updatedOrder.SelectedReports = cartItem.SelectedReports;
                    }
                    updatedOrders.push(updatedOrder);
                }
            }
        });

        let groupedPendingOrders = pendingOrders.filter((element: any) => { return element.TradeGroupId !== 'N/A' });

        groupedPendingOrders.forEach((cartItem: any) => {
            if (cartItem.isModified) {
                let updatedOrder: IOrderInfo = {
                    OrderId: cartItem.OrderId,
                    LastModifiedBy: props.loggedInUser,
                    LastModifiedDate: currentDate
                };
                const modifiedValues = cartItem.modifiedAttrs;
                if (modifiedValues.includes('SelectedReports')) {
                    updatedOrder.SelectedReports = cartItem.SelectedReports;
                }
                if (modifiedValues.includes('Data')) {
                    updatedOrder.Data = JSON.stringify(cartItem.Data);
                }
                updatedOrders.push(updatedOrder);
            }

            cartItem.detailData.forEach((cartItem: any) => {
                if (cartItem.isModified) {
                    let updatedOrder: IOrderInfo = {
                        OrderId: cartItem.OrderId,
                        LastModifiedBy: props.loggedInUser,
                        LastModifiedDate: currentDate
                    };
                    const modifiedValues = cartItem.modifiedAttrs;
                    if (modifiedValues.includes('SelectedReports')) {
                        updatedOrder.SelectedReports = cartItem.SelectedReports;
                    }
                    if (modifiedValues.includes('Data')) {
                        updatedOrder.Data = JSON.stringify(cartItem.Data);
                    }
                    updatedOrders.push(updatedOrder);
                }
            });
        });

        if (updatedOrders.length <= 0) {
            formMessage('No orders to update.', 'info');
        } else {
            updateOrdersApi(updatedOrders, false);
        }
    }

    const saveGroupedOrders = (_groupedOrders: any[]) => {
        let _updatedOrders: any[] = [];
        let workflowOrders: any[] = [];
        let currentDate = new Date().toISOString();
        _groupedOrders.forEach((grouped: any) => {
            if (!grouped.IsGrouped && (grouped.IsPending || grouped.IsRequested)) {
                let groupedInfo: IOrderInfo = {
                    OrderId: grouped.OrderId,
                    TradeGroupId: grouped.TradeGroupId,
                    TradeType: grouped.TradeType,
                    OrderEvent: "ORDER_EXCHANGE",
                    LastModifiedBy: props.loggedInUser,
                    LastModifiedDate: currentDate
                }
                if (grouped.initiateWorkflow) {
                    groupedInfo.OrderStatus = 'Approved';
                    workflowOrders.push(grouped);
                }
                _updatedOrders.push(groupedInfo);
            }
        });
        if (_updatedOrders.length > 0) {
            updateOrdersApi(_updatedOrders, false);
        }
        if (workflowOrders.length > 0) {
            //initiate workflow
        }
        saveExchangeOrder({});
    }

    const saveUngroupedOrders = (_requestedOrders: any[], _pendingOrders: any[]) => {
        let _updatedOrders: any[] = [];
        let currentDate = new Date().toISOString();
        _requestedOrders.forEach((requested: any) => {
            if (!requested.IsRequested && requested.IsGrouped) {
                let currentInfo = {
                    OrderId: requested.OrderId,
                    TradeGroupId: requested.TradeGroupId,
                    // TradeType: requested.TradeType,
                    // OrderStatus: 'Pending',
                    OrderEvent: "ORDER_UNGROUP",
                    LastModifiedBy: props.loggedInUser,
                    LastModifiedDate: currentDate
                }
                _updatedOrders.push(currentInfo);
            }
        });
        _pendingOrders.forEach((pending: any) => {
            if (!pending.IsPending && pending.IsGrouped) {
                let pendingInfo = {
                    OrderId: pending.OrderId,
                    TradeGroupId: pending.TradeGroupId,
                    // TradeType: pending.TradeType,
                    // OrderStatus: 'Pending',
                    OrderEvent: "ORDER_UNGROUP",
                    LastModifiedBy: props.loggedInUser,
                    LastModifiedDate: currentDate
                }
                _updatedOrders.push(pendingInfo);
            }
        });
        if (_updatedOrders.length > 0) {
            updateOrdersApi(_updatedOrders, false);
        }
    }

    const updateOrdersApi = async function (updatedOrders: any[], intitiateWorkflow: boolean) {
        props.setShowProgress(true);
        await api.API_POST('/OrderManagement/UpdateOrder', {
            orders: updatedOrders,
            Environment: api.env
        }).then((response) => {
            api.LOG_MESSAGE(["Page : Transaction ", "Action : Update Order Successfull"], { 'UserName': props.loggedInUser });
            loadPendingRequests('update');
            setIsEditted(false);
            //updateNodeDataHandler(updatedOrders, 'update'); //TODO : enable when node update is fixed
        }).catch(() => {
            setIsEditted(false);
            formMessage('Error while updating order', 'error');
            props.setShowProgress(false);
        });
    }

    const groupOrdersHandler = () => {
        let selectedPendingRequests: any[] = pendingGridRef.current!.api.getSelectedRows();
        let selectedGroupedRequests: any[] = groupedGridRef.current!.api.getSelectedRows();
        if (selectedCartItems.length <= 0) {
            formMessage('No orders to group.', 'info');
        } else if (selectedPendingRequests.length > 0 || selectedGroupedRequests.length > 0) {
            setOpenAlertDialog(true);
        }
    }

    const removeOrders = () => {
        let selectedOrders: any[] = requestedGridRef.current!.api.getSelectedRows();
        let selectedPendingOrders: any[] = pendingGridRef.current!.api.getSelectedRows();
        let orders: any[] = [];
        let canCancelOrder: boolean = false;
        selectedOrders.forEach((cartItem: any) => {
            if (cartItem.OrderEvent === 'ORDER_DRAFTED' || cartItem.OrderEvent === 'INT_WORKFLOW_INITIATED') {
                canCancelOrder = true;
                orders.push(cartItem);
            } else {
                canCancelOrder = false;
            }
        });


        selectedPendingOrders.forEach((cartItem: any) => {
            cartItem.detailData.forEach((cartItem: any) => {
                if (cartItem.OrderEvent === 'ORDER_DRAFTED' || cartItem.OrderEvent === 'INT_WORKFLOW_INITIATED') {
                    canCancelOrder = true;
                    orders.push(cartItem);
                } else {
                    canCancelOrder = false;
                }
            });
        });

        if (canCancelOrder && orders.length === (selectedOrders.length + selectedPendingOrders.length)) {
            let confirmDialogObject = {
                title: "Cancel order",
                body: "Selected Orders will be marked as Closed. Do you want to continue?, else click CANCEL",
                okHandler: closeOrdersapi
            };

            setConfirmDialogObject(confirmDialogObject);
            setOpenConfirmDialog(true);
        } else {
            formMessage('Selected Orders cannot be marked as Closed.', 'info');
        }
    }

    const closeOrdersapi = () => {
        let selectedOrders: any[] = requestedGridRef.current!.api.getSelectedRows();
        let selectedPendingOrders: any[] = pendingGridRef.current!.api.getSelectedRows();
        let orders: any[] = [];
        let currentDate = new Date().toISOString();

        selectedOrders.forEach((cartItem: any) => {
            if (cartItem.OrderEvent === 'ORDER_DRAFTED' || cartItem.OrderEvent === 'INT_WORKFLOW_INITIATED') {
                let _cartItem = cartItem;
                _cartItem.OrderEvent = "ORDER_CLOSED";
                _cartItem.OrderStatus = "Closed";
                _cartItem.LastModifiedBy = props.loggedInUser;
                _cartItem.LastModifiedDate = currentDate;
                orders.push(_cartItem);
            }
        });

        selectedPendingOrders.forEach((cartItem: any) => {
            cartItem.detailData.forEach((cartItem: any) => {
                if (cartItem.OrderEvent === 'ORDER_DRAFTED' || cartItem.OrderEvent === 'INT_WORKFLOW_INITIATED') {
                    let _cartItem = cartItem;
                    _cartItem.OrderEvent = "ORDER_CLOSED";
                    _cartItem.OrderStatus = "Closed";
                    _cartItem.LastModifiedBy = props.loggedInUser;
                    _cartItem.LastModifiedDate = currentDate;
                    orders.push(_cartItem);
                }
            });
        });

        if (orders.length <= 0) {
            formMessage('No orders to cancel.', 'info');
        } else {
            closeOrders();
        }

        async function closeOrders() {
            props.setShowProgress(true);
            await api.API_POST('/OrderManagement/CancelOrder', {
                orders: orders,
                Environment: api.env
            }).then((response) => {
                loadPendingRequests('cancel');
                api.LOG_MESSAGE(["Page : Transaction ", "Action : Cancel Order Successfull"], { 'UserName': props.loggedInUser });
            }).catch(() => {
                formMessage('Error while saving order', 'error');
                props.setShowProgress(false);
                loadPendingRequests();
            });
        }
    }

    const initiateExchange = (_selectedOrder: any) => {
        let order = { ..._selectedOrder };
        let tradeGroupId: number = _selectedOrder.TradeGroupId !== 'N/A' ? _selectedOrder.TradeGroupId : getRandomIntInclusive();
        if (_selectedOrder) {
            let _exchangeOrder: IExchangeOrder = {
                requestedTo: _selectedOrder.RequestedBy,
                requestedBy: _selectedOrder.RequestedTo,
                selectedReports: _selectedOrder.SelectedReports,
                tradeGroupId: tradeGroupId
            }
            saveExchangeOrder(_exchangeOrder);
            order.OrderEvent = 'ORDER_EXCHANGE';
            order.TradeGroupId = tradeGroupId;
            setSelectedOrder(order);
            setRequestedByWellsInfo(order);
            props.setTabIndex(TabOrder.MapView);
        }
    }

    const setRequestedByWellsInfo = (_selectedOrder: any) => {
        let _selectedData: any[] = [];
        if (typeof (_selectedOrder.Data) === 'string') {
            _selectedData = JSON.parse(_selectedOrder.Data);
        }

        _selectedData[0].orderId = _selectedOrder.OrderId;
        _selectedData[0].addExchangeWells = true;
        setOrderWells(_selectedData);
    }

    const addNewWells = (value: any, rowIndex: any, rowData: any) => {
        if (rowData) {
            let _modifiedOrder: IUpdateOrder = {
                requestedTo: rowData.RequestedTo,
                requestedBy: rowData.RequestedBy,
                data: rowData.Data,
                rowIndex: rowIndex
            }

            saveModifiedOrder(_modifiedOrder);
            setSelectedOrder(rowData);
            props.setTabIndex(TabOrder.MapView);
            api.LOG_MESSAGE(["Page : Transaction ", "Action : Add Wells to Order : " + rowData.OrderId], { 'UserName': props.loggedInUser });
        }
    }

    const constructExchangeOrders = (_currentOrders: any[]) => {
        let _groupedOrders = [...groupedOrders];
        let _pendingOrders = [...pendingOrders];
        _currentOrders.push(selectedOrder);
        _pendingOrders.forEach((data: any) => {
            if (selectedOrder.OrderId === data.OrderId) {
                data.grouped = true;
            }
        })

        _pendingOrders = _pendingOrders.filter((item) => { return !item.grouped });
        _groupedOrders = _groupedOrders.filter((item) => { return item.OrderId !== 'N/A' && item.OrderId != selectedOrder.OrderId })
        _groupedOrders = _currentOrders.concat(_groupedOrders);
        setGroupedOrders(_groupedOrders);
        setPendingOrders(_pendingOrders);

        return _groupedOrders;
    }


    const groupOrders = (initiateWorkflow: boolean) => {
        let selectedPendingRequests: any[] = pendingGridRef.current!.api.getSelectedRows();
        let selectedGroupedRequests: any[] = groupedGridRef.current!.api.getSelectedRows();
        let _requestedOrders = [...requestedOrders];
        let _groupedOrders = [...groupedOrders];
        let _pendingOrders = [...pendingOrders];
        let hasGrouping: boolean = false;
        if (selectedPendingRequests.length > 0 || selectedGroupedRequests.length > 0) {
            /* iterate through selectedCartItems and update the records with the below flags*/
            selectedCartItems.forEach((selectedCartItem) => {
                if (selectedGroupedRequests.length > 0) {
                    let selectedGroupOrders: string[] = [];
                    selectedGroupedRequests.forEach((item) => {
                        selectedGroupOrders.push(item.OrderId)
                    })
                    let tradeGroupId: number;
                    _groupedOrders.forEach((data: any) => {
                        if (selectedGroupOrders.includes(data.OrderId) && selectedCartItem.RequestedTo === data.RequestedBy) {
                            tradeGroupId = data.TradeGroupId;
                            hasGrouping = true;
                        }
                    })
                    _requestedOrders.forEach((data: any) => {
                        if (selectedCartItem.OrderId === data.OrderId) {
                            data.initiateWorkflow = initiateWorkflow;
                            data.TradeGroupId = tradeGroupId;
                            data.grouped = true;
                            data.ungrouped = false;
                            hasGrouping = true;
                            _groupedOrders.push(data);
                        }
                    })
                }
                if (selectedPendingRequests.length > 0) {
                    let selectedPendingOrders: string[] = [];
                    selectedPendingRequests.forEach((item) => {
                        selectedPendingOrders.push(item.OrderId)
                    })
                    let tradeGroupId: number = getRandomIntInclusive();
                    _pendingOrders.forEach((data: any) => {
                        if (selectedPendingOrders.includes(data.OrderId)
                            && selectedCartItem.RequestedTo === data.RequestedBy) {
                            if (data.TradeGroupId !== "N/A") {
                                tradeGroupId = data.TradeGroupId;
                            }
                            data.TradeGroupId = tradeGroupId;
                            data.initiateWorkflow = initiateWorkflow;
                            if (!data.grouped) {
                                data.grouped = true;
                                data.ungrouped = false;
                                hasGrouping = true;
                                _groupedOrders.push(data);
                            }
                        }
                    })
                    _requestedOrders.forEach((data: any) => {
                        if (selectedCartItem.OrderId === data.OrderId) {
                            data.TradeGroupId = tradeGroupId;
                            data.initiateWorkflow = initiateWorkflow;
                            data.grouped = true;
                            data.ungrouped = false;
                            hasGrouping = true;
                            _groupedOrders.push(data);
                        }
                    })
                }
            })
            _requestedOrders = _requestedOrders.filter((item) => { return !item.grouped });
            _pendingOrders = _pendingOrders.filter((item) => { return !item.grouped });
            setRequestedOrders(_requestedOrders);
            setGroupedOrders(_groupedOrders);
            setPendingOrders(_pendingOrders);
        }
        if (hasGrouping) {
            saveGroupedOrders(_groupedOrders);
            formMessage('Orders grouped successfully', 'success');
        }
        setSelectedCartItems([]);
    }

    const ungroupOrdersHandler = () => {
        let selectedGroupedRequests: any[] = groupedGridRef.current!.api.getSelectedRows();
        if (selectedGroupedRequests.length <= 0) {
            formMessage('No orders to ungroup.', 'info');
        } else {
            let confirmDialogObject = {
                title: "UnGroup orders",
                body: "Select OK to ungroup and terminate current workflow(s), else click CANCEL",
                okHandler: ungroupOrders
            };

            setConfirmDialogObject(confirmDialogObject);
            setOpenConfirmDialog(true);
        }
    }

    const ungroupOrders = () => {
        setSelectedCartItems([]);
        let _requestedOrders = [...requestedOrders];
        let _groupedOrders = [...groupedOrders];
        let _pendingOrders = [...pendingOrders];
        let hasUngrouping: boolean = false;
        let selectedGroupedRequests: any[] = groupedGridRef.current!.api.getSelectedRows();
        let selectedGroupOrders: string[] = [];
        selectedGroupedRequests.forEach((item) => {
            selectedGroupOrders.push(item.OrderId)
        })
        if (selectedGroupOrders.length > 0) {
            _groupedOrders.forEach((data: any) => {
                if (selectedGroupOrders.includes(data.OrderId)) {
                    if (data.RequestedTo === operator) {
                        data.ungrouped = true;
                        data.grouped = false;
                        data.OrderStatus = 'Pending';
                        data.TradeGroupId = 'N/A'
                        hasUngrouping = true;
                        _pendingOrders.push(data);
                    } else if (data.RequestedBy === operator) {
                        data.ungrouped = true;
                        data.grouped = false;
                        data.OrderStatus = 'Pending';
                        data.TradeGroupId = 'N/A'
                        hasUngrouping = true;
                        _requestedOrders.push(data);
                    }
                }
            })
            _groupedOrders = _groupedOrders.filter((item) => { return !item.ungrouped })
            setRequestedOrders(_requestedOrders);
            setGroupedOrders(_groupedOrders);
            setPendingOrders(_pendingOrders);
        }
        if (hasUngrouping) {
            saveUngroupedOrders(_requestedOrders, _pendingOrders);
            formMessage('Orders ungrouped successfully', 'success');
        } else {
            formMessage('No orders to ungroup.', 'info');
        }
    }

    const formMessage = (message: string, severity: string) => {
        setMessage({
            open: true,
            msg: message,
            severity: severity
        });
    }

    const closeMessage = () => {
        setMessage(initialMessage);
    }

    const getRandomIntInclusive = () => {
        let min = Math.ceil(1);
        let max = Math.floor(32322);
        return Math.floor(Math.random() * (max - min + 1) + min); // The maximum is inclusive and the minimum is inclusive
    }

    const onSelectCartItem = (event: RowSelectedEvent) => {
        let _selectedCartItems = [...selectedCartItems]
        let _requestedOrders = [...requestedOrders];
        _requestedOrders.forEach((cartItem) => {
            if (event.node.data.OrderId === cartItem.OrderId && event.node.data.TradeType === "Trade") {
                if (event.node.isSelected()) {
                    // Select corresponding nodes in 2nd and 3rd grid
                    let hasMatch: boolean = false;
                    pendingGridRef.current!.api.forEachNode((node: any) => {
                        if (!!node.data && node.data.TradeType === "Trade" && cartItem.RequestedTo === node.data.RequestedBy) {
                            //node.setSelected(true);
                            hasMatch = true;
                        }
                    });
                    groupedGridRef?.current?.api.forEachNode((node: any) => {
                        if (!!node.data && node.data.TradeType === "Trade" && cartItem.RequestedTo === node.data.RequestedBy) {
                            node.setSelected(true);
                            hasMatch = true;
                        }
                    });
                    // Ensure current node was not selected previously
                    if (hasMatch &&
                        (_selectedCartItems.length === 0
                            || !_selectedCartItems.find((item) => item.OrderId === cartItem.OrderId))) {
                        _selectedCartItems.push(cartItem);
                    }
                } else {
                    // Deselect corresponding nodes in 2nd and 3rd grid
                    pendingGridRef.current!.api.forEachNode((node: any) => {
                        if (!!node.data && cartItem.RequestedTo === node.data.RequestedBy) {
                            node.setSelected(false);
                        }
                    });
                    groupedGridRef?.current?.api.forEachNode((node: any) => {
                        if (!!node.data && cartItem.RequestedTo === node.data.RequestedBy) {
                            node.setSelected(false);
                        }
                    });
                    // Remove the deselected nodes
                    _selectedCartItems = _selectedCartItems.filter((item) => item.OrderId !== cartItem.OrderId);
                }
            }
        });
        setSelectedCartItems(_selectedCartItems);
    }

    const onCellValueChanged = (params: any) => {
        let _tableData = [...requestedOrders];
        if (_tableData.length > 0) {
            _tableData[params.node.id] = params.node.data;
            _tableData[params.node.id]["isModified"] = true;
            if (_tableData[params.node.id]["modifiedAttrs"] === undefined) {
                _tableData[params.node.id]["modifiedAttrs"] = params.column.colId;
                params.node.setData({ ...params.node.data, isModified: true });
            } else {
                let modAttrs = _tableData[params.node.id]["modifiedAttrs"];
                modAttrs = modAttrs + ',' + params.column.colId;
                _tableData[params.node.id]["modifiedAttrs"] = modAttrs;
                params.node.setData({ ...params.node.data, isModified: true, modifiedAttrs: modAttrs });
            }

            setIsEditted(true);
            setRequestedOrders(_tableData);

            /* if (params.column.colId === 'TradeType' &&
                _tableData[params.node.id]['TradeType'] === 'Rental') {
                let currentDate = new Date(_tableData[params.node.id]['StartDate']);
                let newDate = new Date(currentDate.setMonth(currentDate.getMonth() + 3));
                _tableData[params.node.id]['EndDate'] = newDate.toISOString().slice(0, 19);
                let modAttrs = _tableData[params.node.id]["modifiedAttrs"];
                if (modAttrs === undefined) {
                    _tableData[params.node.id]["modifiedAttrs"] = "EndDate";
                } else {
                    modAttrs = modAttrs + ", EndDate";
                    _tableData[params.node.id]["modifiedAttrs"] = modAttrs;
                }
                setRequestedOrders(_tableData);
            }
    */
        }
    }

    // const onGroupCellValueChanged = (params: any) => {
    //     let _tableData = [...groupedOrders];
    //     if (_tableData.length > 0) {
    //         _tableData[params.node.id] = params.node.data;
    //         _tableData[params.node.id]["isModified"] = true;
    //         if (_tableData[params.node.id]["modifiedAttrs"] === undefined) {
    //             _tableData[params.node.id]["modifiedAttrs"] = params.column.colId;
    //         } else {
    //             let modAttrs = _tableData[params.node.id]["modifiedAttrs"];
    //             modAttrs = modAttrs + ',' + params.column.colId;
    //             _tableData[params.node.id]["modifiedAttrs"] = modAttrs;
    //         }
    //     }
    // }

    const isExternalFilterPresent = useCallback((): boolean => {
        // if tradeType is not all, then we are filtering
        return filterTradeType !== 'All' || !filterStatus.includes('All');
    }, [filterTradeType, filterStatus]);

    const doesExternalFilterPass = useCallback((node: any): boolean => {
        if (node.data) {
            let isValid: boolean = false;

            for (const status in filterStatus) {
                switch (filterStatus[status]) {
                    case 'Drafted':
                        isValid = (filterTradeType === 'All' || node.data.TradeType === filterTradeType)
                            && (node.data.OrderEvent === "ORDER_DRAFTED");
                        break;
                    case 'Closed':
                        isValid = (filterTradeType === 'All' || node.data.TradeType === filterTradeType)
                            && (node.data.OrderEvent === "ORDER_REJECTED" || node.data.OrderEvent === "ORDER_WITHDRAWN" || node.data.OrderEvent === "ORDER_CLOSED");
                        break;
                    case 'Pending':
                        isValid = (filterTradeType === 'All' || node.data.TradeType === filterTradeType)
                            && (filterStatus.includes(node.data.OrderStatus));
                        break;
                    case 'In Progress':
                        isValid = (filterTradeType === 'All' || node.data.TradeType === filterTradeType)
                            && (node.data.OrderEvent === "INT_WORKFLOW_INITIATED" || node.data.OrderEvent === "INT_WORKFLOW_REINITIATED" || node.data.OrderEvent === "ORDER_UNGROUP" || node.data.OrderEvent === "EXT_WORKFLOW_INITIATED" ||
                                node.data.OrderEvent === "ORDER_APPROVED" || node.data.OrderEvent === "ORDER_EXCHANGE" || node.data.OrderEvent === "ORDER_CREATED"
                                || node.data.OrderEvent === "COUNTER_RESPONSE" || node.data.OrderEvent === "COUNTER_AMEND" || node.data.OrderEvent === "COUNTER_APPROVED" || node.data.OrderEvent === "COUNTER_REJECTED");
                        break;
                    case 'Executed':
                        isValid = (filterTradeType === 'All' || node.data.TradeType === filterTradeType)
                            && (node.data.OrderEvent === "EXT_WORKFLOW_COMPLETED" || node.data.OrderEvent === "INT_WORKFLOW_COMPLETED");
                        break;
                    default:
                        isValid = (filterTradeType === 'All' || node.data.TradeType === filterTradeType);
                        break;
                }

                if (isValid) {
                    break;
                }
                else {
                    continue;
                }
            }

            return isValid;
        }

        return true;
    }, [filterTradeType, filterStatus]);

    const tradeTypeFilterChanged = useCallback((newValue: string) => {
        setFilterTradeType(newValue);
    }, []);

    const getRowStyle = (params: any) => {
        let rowStyle = {} //{ 'pointer-events': 'none', opacity: '0.7' }
        if (params.node.data.OrderEvent === 'ORDER_CLOSED') {
            return { pointerEvents: 'none', backgroundColor: 'lightgrey' };
        }
        else if (params.node.data.isModified) {
            return { fontStyle: 'italic', fontWeight: 'bold' };
        }
        else {
            return rowStyle;
        }
    }

    const getRowHeight = useCallback((params: any) => {
        if (params.node.level === 0) {
            return 120;
        }
        if (params.node.level === 1) {
            return 60;
        }
        return 40;
    }, []);

    const getCellClassRuleHeartbeatSelectedDataSet = (gridType: string) => {
        if (gridType === "requested") {
            return {
                "heart": (params: any) => (params?.data?.OrderStatus?.toLowerCase() === "drafted" &&
                    (params?.data?.SelectedReports?.toLowerCase() === "all" || params?.data?.SelectedReports?.toLowerCase() === "all,"))
            }
        }
    }

    const getCellClassRuleHeartbeatEstVal = (gridType: any) => {
        if (gridType === "requested") {
            return {
                "heart": (params: any) =>  (params?.data?.OrderStatus?.toLowerCase() !== "closed") &&  (params.data.ZeroProposedValueCount > 0 || params.data.ZeroCurrentValueCount > 0 || //current value or proposed value is 0
                    (params?.data?.ZeroProposedValueCount == 0 && params?.data?.ZeroCurrentValueCount == 0 && // when current value and proposed value are filled and order is INT-EXECUTED
                        params.data.OrderStatus.toLowerCase() === "placed" && (params.data.ZeroDOProposedValueCount == 0 && params.data.DOApprovalValueCount == 0 && params.data.OOApprovalValueCount > 0)))
            }
        }
        else {
            return {
                "heart": (params: any) => (params?.data?.OrderStatus?.toLowerCase() !== "closed") && (params.data.ZeroProposedValueCount > 0 || params.data.ZeroCurrentValueCount > 0 ||
                    (params?.data?.ZeroProposedValueCount == 0 && params?.data?.ZeroCurrentValueCount == 0 &&
                        params.data.OrderStatus.toLowerCase() === "placed" && params.data.DOApprovalValueCount > 0))


            }
        }
    }

    const getCellClassRuleHeartbeatDataTransfer = (gridType : any) => {
        if (gridType === "requested") {
            return {
                "heart": (params: any) =>  (params?.data?.OrderStatus?.toLowerCase() === "executed") &&  
                    (params.data.DODataTrasferredCount > 0 && params.data.DODataNotTrasferredCount == 0 && params.data.OODataNotRecievedCount > 0)
            }
        }
        else {
            return {
                "heart": (params: any) => (params?.data?.OrderStatus?.toLowerCase() === "executed") && 
                (params.data.DODataTrasferredCount  == 0 || params.data.DODataNotTrasferredCount > 0)


            }
        }
    }

    const statusFilterChanged = (newValue: string) => {
        let filters = [...filterStatus];

        if (newValue !== 'All') {
            const index = filters.indexOf('All', 0);
            const newValueIndex = filters.indexOf(newValue, 0);
            if (index > -1) {
                filters.splice(index, 1);
                filters.push(newValue);
            }
            else {
                if (newValueIndex > -1) {
                    filters.splice(newValueIndex, 1);
                    if (filters.length == 0)
                        filters.push('All');
                }
                else {
                    filters.push(newValue);
                }
            }
        }
        else {
            filters = [];
            filters.push('All');
        }

        setFilterStatus(filters);
    };

    const autoSizeColumns = (timeoutPeriod: number) => {
        setTimeout(() => {
            requestedGridRef?.current?.columnApi.autoSizeColumns(columns, false);
            groupedGridRef?.current?.columnApi.autoSizeColumns(columns, false);
            pendingGridRef?.current?.columnApi.autoSizeColumns(columns, false);
        }, timeoutPeriod);
    }

    const onFirstDataRendered = React.useCallback((timeoutPeriod: number) => {
        autoSizeColumns(timeoutPeriod);
    }, []);

    const handleChangeIndex = (index: number) => {
        setGridTabValue(index);
    };


    return (
        <>
            <div style={{ display: props.value == 1 ? "" : "none" }}>
                <Snackbar
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                    open={message.open}
                    autoHideDuration={1800}
                    onClose={() => { closeMessage() }}>
                    <p></p>
                </Snackbar>
                <Paper elevation={0} sx={{ height: 'auto', padding: '10px', marginBottom: "5px" }}>
                    <TextField
                        id="Operator" name="Operator" select label="Operator"
                        size="small"
                        SelectProps={{
                            native: true,
                        }}
                        value={operator}
                        onChange={e => setOperator(e.target.value)}>
                        {operatorList?.map((operator: string) => (
                            <option key={operator} value={operator}>
                                {operator}
                            </option>
                        ))}
                    </TextField>

                    <div style={{ paddingLeft: '20px', width: '20%', display: 'inline-block' }}>
                        <Collapse in={message.open}>
                            <Alert onClose={closeMessage} severity={message.severity as AlertColor}
                                sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                                {message.msg}
                            </Alert>
                        </Collapse>
                    </div>

                    <ButtonGroup sx={{ float: 'right', marginRight: '0' }} color="primary" size="small">
                        <Button id="updateOrders" aria-label="add" onClick={updateOrders} startIcon={<EditIcon />}>
                            Update order(s)
                        </Button>
                        <Button id="removeOrders" aria-label="add" onClick={removeOrders} startIcon={<CancelOutlinedIcon />}>
                            Cancel order(s)
                        </Button>
                        <Button id="placeOrder" aria-label="remove" onClick={placeOrder} startIcon={<ShoppingCartCheckoutIcon />}>
                            Place order
                        </Button>
                        <Button id="clearCart" aria-label="remove" onClick={clearCart} startIcon={<RefreshOutlinedIcon />}>
                            Refresh
                        </Button>
                    </ButtonGroup>
                    {/* {IsAdminUser &&
                        <ButtonGroup sx={{ float: 'right', marginRight: "5%" }} color="primary" size="small">
                            <Button aria-label="add" onClick={groupOrdersHandler} startIcon={<GroupAddIcon />}>
                                Group orders
                            </Button>
                            <Button aria-label="remove" onClick={ungroupOrdersHandler} startIcon={<GroupRemoveIcon />}>
                                Ungroup orders
                            </Button>
                        </ButtonGroup>} */}
                </Paper>
                <Paper elevation={0} sx={{ height: '70px' }}>
                    <Stack sx={{ float: 'left', padding: '20px' }} direction="row" spacing={1}>
                        <Typography variant="subtitle1" component="div">
                            Trade Type:
                        </Typography>
                        {tradeTypesList.map((item) => (
                            <Chip 
                                key={item}
                                color="primary"
                                avatar={<Avatar>{item.charAt(0)}</Avatar>}
                                label={item}
                                variant={item === filterTradeType ? "filled" : "outlined"}
                                onClick={(event) => {
                                    tradeTypeFilterChanged(item);
                                }} />
                        ))}

                    </Stack>

                    <Stack sx={{ float: 'right', padding: '20px' }} direction="row" spacing={1}>
                        <Typography variant="subtitle1" component="div">
                            Approval Status:
                        </Typography>
                        {approvalStatusList.map((item) => (
                            <Chip
                                key={item}
                                color="primary"
                                avatar={<Avatar>{item.charAt(0)}</Avatar>}
                                label={item}
                                variant={filterStatus.includes(item) ? "filled" : "outlined"}
                                onClick={(event) => {
                                    statusFilterChanged(item);
                                }} />
                        ))}
                    </Stack>
                </Paper>
            </div>
            <div>
                <Box sx={{ bgcolor: 'background.paper' }}>
                    <AppBar position="static">
                        <Tabs
                            className="headerTab"
                            sx={{ minHeight: "auto !important" }}
                            value={gridTabValue}
                            onChange={handleChange}
                            indicatorColor="secondary"
                            textColor="inherit"
                            aria-label="lab API tabs example">
                            <Tab iconPosition="start" label={<Tooltip title="Orders & Counters"><span>Orders & Counters</span></Tooltip>} />
                            <Tab iconPosition="start" label={<Tooltip title="Requests & Responses"><span>Requests & Responses</span></Tooltip>} />
                        </Tabs>
                    </AppBar>
                    <SwipeableViews
                        className='tabPanel'
                        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                        index={gridTabValue}
                        onChangeIndex={handleChangeIndex}>
                        <TabPanel value={0} index={0} dir={theme.direction}>
                            <div id="cartGrid" className="ag-theme-balham ag-theme-material-cart" style={{ height: '33%', width: "auto" }}>
                                <div style={{ display: "flex", width: '100%' }}>
                                    <Tooltip title="columns" style={{ margin: 2, float: 'right' }}>
                                        <IconButton
                                            id="columntest"
                                            color="success"
                                            aria-label="columns test"
                                            component="label"
                                            style={{ backgroundColor: "rgb(239 238 238)", marginRight: '5px' }}
                                            onClick={(e: any) => handleClickColumnsOpen(e.currentTarget, 'Requested')}>
                                            <ViewColumnOutlinedIcon />
                                        </IconButton>
                                    </Tooltip>
                                </div>
                                <div style={{ height: '55vh' }} className="ag-theme-balham">
                                    <AgGridReact
                                        rowSelection={'multiple'}
                                        ref={requestedGridRef}
                                        rowData={requestedOrders}
                                        gridOptions={gridOptions}
                                        getRowStyle={getRowStyle}
                                        masterDetail={true}
                                        onCellValueChanged={onCellValueChanged}
                                        onCellEditingStarted={isCellEditable}
                                        onRowSelected={onSelectCartItem}
                                        onGridReady={handleGridReady}
                                        detailCellRendererParams={detailCellRendererParams}
                                        detailRowHeight={190}
                                        rowHeight={90}
                                        isExternalFilterPresent={isExternalFilterPresent}
                                        doesExternalFilterPass={doesExternalFilterPass}
                                        onFirstDataRendered={() => { onFirstDataRendered(50) }}
                                        overlayNoRowsTemplate={'<span style="padding: 10px; border: 2px solid #444; background: lightgoldenrodyellow;">No requested orders!!</span>'}>
                                    </AgGridReact>
                                </div>

                                <br /><br />
                            </div>

                        </TabPanel>
                        <TabPanel value={1} index={1} dir={theme.direction}>
                            <div id="pendingGrid" className="ag-theme-balham ag-theme-material-cart" style={{ height: "33%", width: "auto" }}>
                                <div style={{ display: "flex", width: '100%' }}>
                                    <Tooltip title="columns" style={{ margin: 2, float: 'right' }}>
                                        <IconButton
                                            id="Successid"
                                            color="success"
                                            aria-label="columns test"
                                            component="label"
                                            style={{ backgroundColor: "rgb(239 238 238)", marginRight: '5px' }}
                                            onClick={(e: any) => handleClickColumnsOpen(e.currentTarget, 'Pending')}>
                                            <ViewColumnOutlinedIcon />
                                        </IconButton>
                                    </Tooltip>
                                </div>
                                <div style={{ height: '55vh' }} className="ag-theme-balham">
                                    <AgGridReact
                                        rowSelection={'multiple'}
                                        ref={pendingGridRef}
                                        getRowStyle={getRowStyle}
                                        gridOptions={pendingGridOptions}
                                        onCellEditingStarted={isCellEditable}
                                        masterDetail={true}
                                        detailCellRendererParams={detailPendingCellRendererParams}
                                        detailRowHeight={190}
                                        rowHeight={90}
                                        isExternalFilterPresent={isExternalFilterPresent}
                                        doesExternalFilterPass={doesExternalFilterPass}
                                        onFirstDataRendered={() => { onFirstDataRendered(50) }}
                                        overlayNoRowsTemplate={'<span style="padding: 10px; border: 2px solid #444; background: lightgoldenrodyellow;">No pending requests!!</span>'}>
                                    </AgGridReact> </div>
                                <br />
                            </div>
                        </TabPanel>
                    </SwipeableViews>
                </Box>
            </div>
            <div style={{ height: 'auto', width: 'auto' }}>

                {/* <div id="groupedGrid" className="ag-theme-balham ag-theme-material-cart" style={{ height: "33%", width: "auto" }}>

                    <div style={{ display: "flex", width: '100%' }}>
                        <Typography variant="h6" component="div" style={{ margin: 2, float: 'right', flex: 1 }}>
                            Trade Orders:
                        </Typography>

                        <Tooltip title="columns" style={{ margin: 2, float: 'right' }}>
                            <IconButton
                                color="success"
                                aria-label="columns test"
                                component="label"
                                style={{ backgroundColor: "rgb(239 238 238)", marginRight: '5px' }}
                                onClick={(e: any) => handleClickColumnsOpen(e.currentTarget, 'Trade')}>
                                <ViewColumnOutlinedIcon />
                            </IconButton>
                        </Tooltip>
                    </div>
                    <div style={{ height: '25vh' }} className="ag-theme-balham">
                        <AgGridReact
                            rowSelection={'multiple'}
                            ref={groupedGridRef}
                            getRowStyle={getRowStyle}
                            gridOptions={groupedGridOptions}
                            isExternalFilterPresent={isExternalFilterPresent}
                            doesExternalFilterPass={doesExternalFilterPass}
                            onCellValueChanged={onGroupCellValueChanged}
                            onFirstDataRendered={() => { onFirstDataRendered(50) }}
                            overlayNoRowsTemplate={'<span style="padding: 10px; border: 2px solid #444; background: lightgoldenrodyellow;">No grouped orders!!</span>'}>
                        </AgGridReact> </div>
                    <br /><br />
                </div> */}


            </div>
            <AlertDialog
                open={openAlertDialog}
                title={'Group orders'}
                content={'Select Yes to also initiate workflow or No to only group the orders?'}
                setAlertDialogOpen={setOpenAlertDialog}
                yesHandler={groupOrders} noHandler={groupOrders}></AlertDialog>
            <ConfirmDialog
                open={openConfirmDialog}
                setOpenConfirmDialogHandler={setOpenConfirmDialog}
                title={confirmDialogObject.title}
                body={confirmDialogObject.body}
                okHandler={confirmDialogObject.okHandler}></ConfirmDialog>

            <ColumnSelection
                tablePreferenceList={tablePreferenceList}
                selectedPreference={selectedPreference}
                setselectedPreference={setselectedPreference}
                loggedInUserMailId={props.loggedInUserMailId}
                loggedInUser={props.loggedInUser}
                hiddenColumns={hiddenColumns}
                columnsPerfOpen={columnsPerfOpen}
                anchorEl={anchorEl}
                applyColumnPreferenceHandler={applyColumnPreferenceHandler}
                setcolumnsPerfOpen={setcolumnsPerfOpen}
                displayColumns={displayColumns}
            ></ColumnSelection>
        </>
    )
}

export default Cart;