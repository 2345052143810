import {Grid,Typography} from '@mui/material';
import './ChatMsg.css';
import { Chip } from '@mui/material';

const ChatMsg = (props: any) => {
  return (
    <Grid
      container
      spacing={2}
      justifyContent={props.side === 'right' ? 'flex-end' : 'flex-start'}
    >
      {(
        <Grid item>
          <Chip label={props.avatar} variant="outlined" />
        </Grid>
      )}
      <Grid item xs={8}>
        {props.messages.map((msg: any, i: number) => {
          return (
            <div
              style={{ textAlign: "end" }}
              key={msg.id || i}
              className={props.side === 'left' ? "leftContent" : "rightContent"}>
              <Typography
                style={{ fontSize: "12px" }}
                align={'left'}>
                {msg.comment}
              </Typography>
              <Chip
                label={msg.commentByUser +" | "+msg.commentDateTime}
                size="small"
                variant="outlined"
                style={{ fontSize: "10px" }} />
            </div>
          );
        })}
      </Grid>
    </Grid>
  );
};

export default ChatMsg;