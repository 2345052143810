import CloseIcon from '@mui/icons-material/Close';
import { Alert, AlertTitle, Checkbox, Collapse, Typography } from "@mui/material";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import React from "react";
import { useEffectOnce } from "src/hooks/useEffectOnce";
import * as api from 'src/Utility/api';
import { Itable } from "../@State/types";
import "./AddTable.css";
import ColumnList from "./columnList";

function AddTable() {

  const [tableModel, setTableModel] = React.useState<Itable>(
    {
      columnList: [
        {
          index: Math.random(),
          ColumnName: "",
          DataType: "",
          Length: "",
          ColumnUnique: "",
          ForeignKey: "",
          EncryptedYn: "",
          Tooltip: "",
          Label: "",
          ColumnDescpn: ""
        },
      ],
      schemaName: "",
      tableName: ""
    }
  );
  const [foreignKeys, setForeignKeys] = React.useState<any[]>([]);
  const [dataTypes, setdataTypes] = React.useState<any[]>([]);
  const [openSuccessMessage, setOpenSuccessMessage] = React.useState(false);
  const [openErrorMessage, setOpenErrorMessage] = React.useState(false);
  const [history, setHistory] = React.useState(true);
  let datatypelist = [
    "Number",
    "Date",
    "varchar"
  ];


  useEffectOnce(() => {
    loadDataType();
  });

  const loadDataType = () => {
    async function fetchTableModelSettings() {
      await api.API_GET("/Table/GetDataForCreateTableModel").then((res) => {
        if (res?.data.DataTypes.length > 0) {
          let columns: any[] = [];
          let pks: any[] = [];
          columns = res.data.DataTypes;
          pks = res.data.PrimaryKeys;
          setdataTypes(columns);
          setForeignKeys(pks);
        }
      })
    }
    fetchTableModelSettings();
  }

  const handleChange = (e: any) => {
    if (!['DataType', 'ForeignKey'].includes(e.target.name)) {
      let _columnList = [...tableModel.columnList]

      let _tableModel = { ...tableModel };

      // _tableModel.columnList = _columnList;
      const name: string = e.target.name;
      const value = e.target.value;
      if (name === 'schemaName') {
        _tableModel.schemaName = value;
      }
      if (name === 'tableName') {
        _tableModel.tableName = value;
      }
      if (name === 'enableHistory') {
        setHistory(e.target.checked);
      }
      if (name === 'ColumnName' || name === 'ColumnUnique' || name === 'Length' || name === 'EncryptedYn' || name === 'Label' || name === 'ColumnDescpn' || name === 'Tooltip') {
        let __columnObject = _columnList.filter((element: any) => {
          return element.index == e.target.dataset.id;
        });
        if (__columnObject.length > 0) {
          let objectIndex = _columnList.indexOf(__columnObject[0]);
          __columnObject[0][name] = value;
          _columnList[objectIndex] = __columnObject[0];
        }
        _tableModel.columnList = _columnList;
      }

      setTableModel(_tableModel);
    }
  }
  const handleChangeOptions = (index: number, e: any) => {
    if (['DataType', 'ForeignKey'].includes(e.target.name)) {
      let _columnList = [...tableModel.columnList]

      let _tableModel = { ...tableModel };

      // _tableModel.columnList = _columnList;
      const name: string = e.target.name;
      const value = e.target.value;

      if (name === 'DataType' || name === 'ForeignKey') {
        let __columnObject = _columnList.filter((element: any) => {
          return element.index == index;
        });
        if (__columnObject.length > 0) {
          let objectIndex = _columnList.indexOf(__columnObject[0]);
          __columnObject[0][name] = value;
          _columnList[objectIndex] = __columnObject[0];
        }
        _tableModel.columnList = _columnList;
      }

      setTableModel(_tableModel);
    }
  }
  const addNewRow = () => {
    let _columnList = [...tableModel.columnList]
    _columnList.push({ index: Math.random(), ColumnName: "", DataType: "", Length: "", ColumnUnique: "", ForeignKey: "", EncryptedYn: "", Tooltip: "", Label: "", ColumnDescpn: "" })

    let _tableModel = { ...tableModel };
    _tableModel.columnList = _columnList;
    setTableModel(_tableModel);
  }


  const deleteRow = (index: number) => {
    let _columnList = [...tableModel.columnList]
    _columnList = _columnList.filter(row => row.index !== index)

    let _tableModel = { ...tableModel };
    _tableModel.columnList = _columnList;
    setTableModel(_tableModel);
  }

  const resetColumns = () => {
    setTableModel({
      columnList: [
        {
          index: Math.random(),
          ColumnName: "",
          DataType: "",
          Length: "",
          ColumnUnique: "",
          ForeignKey: "",
          EncryptedYn: "",
          Tooltip: "",
          Label: "",
          ColumnDescpn: ""
        },
      ],
      schemaName: "",
      tableName: ""
    });
  }

  const handleSubmit = (e: any) => {
    e.preventDefault();
    async function saveCreateTableModel() {
      let data = {
        schemaName: tableModel.schemaName,
        tableName: tableModel.tableName,
        columns: tableModel.columnList
      }
      await api.API_POST("/Table/CreateTable", data).then((res) => {
        e.target.reset();
        resetColumns();
        setOpenSuccessMessage(true);
        if (history) {
          createHistoryTable();
        }
      }).catch(error => {
      });

    }
    async function createHistoryTable() {
      let data = {
        schemaName: tableModel.schemaName,
        tableName: tableModel.tableName,
      };
      await api
        .API_POST("/Table/CreateHistoryTable", data)
        .then((res) => {
        })
        .catch((error) => {
        });
    }
    saveCreateTableModel();
  }

  return (

    <div className="content">
      <Collapse in={openSuccessMessage}>
        <Alert severity="success">
          <CloseIcon fontSize="inherit"
            style={{ float: 'right', cursor: 'pointer' }}
            onClick={() => { setOpenSuccessMessage(false) }} />
          <AlertTitle style={{ width: "100%" }}>Success</AlertTitle>
          Data saved successfully
        </Alert>
      </Collapse>
      <form onSubmit={handleSubmit} onChange={handleChange} >
        <div className="row" style={{ marginTop: 20 }}>
          <div className="col-sm-12">
            <div className="card">
              <Box
                sx={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  '& > :not(style)': {
                    m: 1,
                    width: '100%',
                    height: 50,
                    marginBottom: 2
                  },
                }}
              >
                <Paper sx={{ p: 1, display: 'flex', flexDirection: 'row', marginBottom: '15px' }} >
                  <Typography variant="h6" gutterBottom component="div" sx={{ color: '#505050', marginLeft: '15px' }}>
                    Create Dataset
                  </Typography>

                </Paper >

              </Box>
              <div className="card-body">
                <div className="row">
                  <div className="col-sm-4">
                    <div className="form-group ">
                      <label style={{ marginLeft: '15px' }} className="required">Schema Name</label>
                      <div>
                        <input required style={{ marginLeft: '15px' }} type="text" name="schemaName" id="Schema" className="form-control" placeholder="Enter Schema" />
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <div className="form-group ">
                      <label style={{ marginLeft: '15px' }} className="required">Table Name</label>
                      <div>
                        <input required style={{ marginLeft: '15px' }} type="text" name="tableName" id="Table" className="form-control" placeholder="Enter Table" />
                      </div>
                    </div>
                    <div className="form-group">
                      <label style={{ marginLeft: '15px' }} className="required">Enable History</label>
                      <Checkbox name="enableHistory" size="small" checked={history}
                        inputProps={{ 'aria-label': 'controlled' }} />
                    </div>
                  </div>
                </div>
                <table style={{ marginLeft: '15px' }} className="table">
                  <thead style={{ color: '#505050' }}>
                    <tr >
                      <th className="required" >Column Name</th>
                      <th className="required" >Column Data Type</th>
                      <th className="required">Length</th>
                      <th className="required" >Column Unique YN</th>
                      <th className="required" >Foreign Key</th>
                      <th className="required" >Encrypted YN</th>
                      <th className="required" >Label</th>
                      <th className="required" >Tooltip</th>
                      <th className="required" >Column Description</th>
                    </tr>
                  </thead>
                  <tbody >

                    {
                      tableModel.columnList.map((col) => (
                        <ColumnList col={col}
                          addTable={tableModel.columnList}
                          addNewRow={addNewRow}
                          deleteRow={deleteRow}
                          dataTypeList={dataTypes}
                          foreignKeyList={foreignKeys}
                          handleChangeOptions={handleChangeOptions}
                        ></ColumnList>
                      ))}

                  </tbody>

                </table>
              </div>
              <div className="card-footer text-center">

                <Button id="submit" type="submit" style={{ marginLeft: '15px', marginTop: '15px' }} variant="contained" size="small">
                  Submit
                </Button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  )
}

export default AddTable;