import {
    Box, Checkbox, DialogActions, DialogTitle,
    IconButton, List, ListItem, ListItemButton, ListItemIcon,
    ListItemText, Popper, PopperPlacementType, TextField, Tooltip, Typography
} from '@mui/material';
import React, { useEffect } from 'react';
import { useEffectOnce } from 'src/hooks/useEffectOnce';
import { TableType } from '../../@State/types';
import { debounce } from 'src/Utility/api';
import CancelIcon from '@mui/icons-material/Cancel';
import DoneIcon from '@mui/icons-material/Done';
import LayersOutlinedIcon from '@mui/icons-material/LayersOutlined';
import { TableContext } from '../../@State/context/TableContext';

function CountySelection(props: any) {
    const [open, setOpen] = React.useState(false);
    const [placement, setPlacement] = React.useState<PopperPlacementType>('bottom-start');
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [columnSelection, setColumnSelection] = React.useState<any[]>([]);
    const [selectedColumns, SetSelectedColumns] = React.useState<string>(props.value ? props.value : "All");
    const [searchText, setSearchText] = React.useState<string>("");
    const [columnSelectionFiltered, setColumnSelectionFiltered] = React.useState<any[]>([]);
    const [kmlAppliedState, setKmlAppliedState] = React.useState<any[]>([]);

    useEffectOnce(() => {
        setTimeout(() => {
            loadCountyStateInformation();
        }, 200);
    });

    useEffect(() => {
        if (searchText !== "") {
            const filteredColumnSelection = columnSelection.filter((ele) => {
                return ele.ColumnName.toLowerCase().includes(searchText.toLowerCase());
            });
            setColumnSelectionFiltered(filteredColumnSelection);
        } else {
            setColumnSelectionFiltered([]);
        }
    }, [searchText]);

    const handleClickOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
        setOpen(true);
    };

    const clearSelection = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
        setOpen(false);
    };

    const chooseSelection = (event: React.MouseEvent<HTMLButtonElement>) => {
        SetSelectedColumns(getSelectedColumns());
        applyCountyLines();
        setAnchorEl(event.currentTarget);
        setOpen(false);
    };

    const setColumnPreferenceHandler = (columnPerferenceObj: any, isEnable: boolean) => {
        let _columnPreference = [...columnSelection];
        _columnPreference[_columnPreference.indexOf(columnPerferenceObj)].Enable = isEnable;
        setColumnSelection(_columnPreference);
    }

    const getSelectedColumns = () => {
        if (columnSelection.length > 0) {
            const selectedColumns = columnSelection.filter((ele: any) => { return ele.Enable });
            if (selectedColumns.length > 0) {
                const columnsNames = selectedColumns.map((ele: any) => { return ele.ColumnName });
                return columnsNames.toString();
            } else {
                return "All";
            }
        } else {
            return "All";
        }
    }

    const loadCountyStateInformation = () => {
        let columnPreference: any[] = [];

        props.countyKmlFiles?.forEach((element: any) => {
            const stateShortDescription = element.substring((element.indexOf(".") - 2), element.indexOf("."));
            if (props.value?.includes(element)) {
                columnPreference.push({
                    ColumnName: stateShortDescription,
                    fileName: element,
                    Enable: true
                });
            } else {
                columnPreference.push({
                    ColumnName: stateShortDescription,
                    fileName: element,
                    Enable: false
                });
            }
        });
        setColumnSelection(columnPreference);
    }

    const applyCountyLines = () => {
        let selectedState = columnSelection.filter((element) => { return element.Enable });
        let _kmlAppliedState = [...kmlAppliedState];

        google.maps.event.trigger(props.mapObj, 'resize');
        const ctaLayer = new google.maps.KmlLayer(null);
        ctaLayer.setMap(null);

        selectedState.map((element: any) => {


            if (_kmlAppliedState.indexOf(element.ColumnName) === -1) {
                _kmlAppliedState.push(element.ColumnName);

                let countyLayerNM = new google.maps.KmlLayer({ url: 'https://kmlfiles.blob.core.windows.net/kmlfiles/' + element.fileName });
                countyLayerNM.setOptions({ preserveViewport: true });
                countyLayerNM.setMap(props.mapObj);
            }
        });

        setKmlAppliedState(_kmlAppliedState);
    }


    return (
        <>
            <Tooltip title="County / Township Lines">
                <IconButton id="county"
                    color="success"
                    aria-label="Add to cart"
                    style={{ backgroundColor: "rgb(239 238 238)", width: "fit-content" }}
                    onClick={handleClickOpen}>
                    <LayersOutlinedIcon fontSize="small" />
                </IconButton></Tooltip>
            <Popper open={open} placement={placement} anchorEl={anchorEl} style={{ zIndex: '10000' }}>
                <Box sx={{ border: '2px solid #bfbfbf', p: 1, bgcolor: 'background.paper' }}>
                    <DialogTitle sx={{ padding: "0px" }}>County / Township lines</DialogTitle>
                    <Typography>(Recommended up to 7)</Typography>
                    <TextField
                        label="Search"
                        id="columnselection-search-text-box"
                        value={searchText}
                        sx={{ width: '100%' }}
                        size="small"
                        onChange={(ele) => { debounce(setSearchText(ele.target.value)) }}
                    />
                    <Box sx={{ border: '1px solid #bfbfbf', p: 1, bgcolor: 'background.paper' }} style={{ height: '25vh', width: '100%' }} className="ag-theme-material">
                        <List sx={{ width: '100%', maxWidth: 360, maxHeight: '25vh', overflow: 'scroll', bgcolor: 'background.paper' }}>
                            {columnSelection.map((column: any) => {
                                const labelId = `checkbox-list-label-${column.ColumnName}`;
                                if (columnSelectionFiltered.length === 0
                                    || columnSelectionFiltered
                                        .map((ele: any) => { return ele.ColumnName.toLowerCase() })
                                        .includes(column.ColumnName.toLowerCase())) {
                                    return (
                                        <ListItem
                                            key={column.ColumnName}
                                            secondaryAction={
                                                <IconButton id="countycomments" edge="end" aria-label="comments">
                                                </IconButton>
                                            }
                                            disablePadding>
                                            <ListItemButton role={undefined} dense onClick={() => { setColumnPreferenceHandler(column, !column.Enable) }}>
                                                <ListItemIcon>
                                                    <Checkbox
                                                        id="chkbox"
                                                        style={{ padding: '0px' }}
                                                        edge="start"
                                                        checked={column.Enable}
                                                        tabIndex={-1}
                                                        disableRipple
                                                        inputProps={{ 'aria-labelledby': labelId }}
                                                    />
                                                </ListItemIcon>
                                                <ListItemText id={labelId} primary={column.ColumnName} />
                                            </ListItemButton>
                                        </ListItem>
                                    );
                                }

                            })}
                        </List>
                    </Box>
                    <DialogActions sx={{ padding: "2px" }}>
                        <IconButton id="successIcon" color="success" aria-label="done" onClick={chooseSelection}>
                            <DoneIcon />
                        </IconButton>
                        <IconButton id="erroricon" color="error" aria-label="cancel" onClick={clearSelection}>
                            <CancelIcon />
                        </IconButton>
                    </DialogActions>
                </Box>
            </Popper>
        </>
    )
}

export default CountySelection;