import { Box, Chip, Grid, Paper, styled, Tooltip, Typography } from '@mui/material';
import React from 'react'
import { AppContext } from '../../@State/context/AppContext';
import { ExchangeOrderType } from '../../@State/types';
import { useEffectOnce } from 'src/hooks/useEffectOnce';

  
const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

export default function TimeLineProgressRenderer(props: any) {

    const { CommonAppConfig, fetchCommonAppConfigHandler } = React.useContext(AppContext) as ExchangeOrderType;
    const [totalTime, setTotalTime] = React.useState<string>('');
    const [totalOOTime, setTotalOOTime] = React.useState<string>('');
    const [totalDOTime, setTotalDOTime] = React.useState<string>('');

    const Parentdiv = {
                height: 25,
                width: '100%',
                backgroundColor: 'black',
                display : 'flex'
              }

    const getColorClassName = (value : any) => {
                                if(value === props.operator)
                                    return '#135fac';
                                else
                                    return '#32ac13';
                            }

    useEffectOnce(() => {
        fetchCommonAppConfigHandler();
    });

    const getTotalTime = (events: any[]) => {
        const totalTime = {
            Days : 0,
            Hours: 0,
            Minutes : 0
        }

        events?.map((eve : any) => {
            let formattedValues = eve.split(/[.:::/]/);
            if(formattedValues.length > 3) {
                let days : number = +formattedValues[0];
                let hours : number = +formattedValues[1];
                let minutes : number = +formattedValues[2];
    
                totalTime['Days']  += days;
                totalTime['Hours']  += hours;
                totalTime['Minutes']  += minutes;
            }
        });
        
        if(totalTime['Minutes'] > 59) {
            let minutes = totalTime['Minutes'];
            const quotient = Math.floor(minutes/60);
            const remainder = minutes % 60;
            totalTime['Hours']  += quotient;
            totalTime['Minutes'] = remainder;
        }

        if(totalTime['Hours'] > 23) {
            let hours = totalTime['Hours'];
            const quotient = Math.floor(hours/24);
            const remainder = hours % 24;
            totalTime['Days']  += quotient;
            totalTime['Hours'] = remainder;
        }

        return totalTime;
    }

    React.useEffect(() => {
        let orderOwnerEvents = props.data?.OrderEvents?.filter((ele : any) => { return ele.Operator === props.data?.RequestedBy})?.map((element: any) => { return element.TimeSpan });
        let dataOwnerEvents = props.data?.OrderEvents?.filter((ele : any) => { return ele.Operator === props.data?.RequestedTo})?.map((element: any) => { return element.TimeSpan });


            let totalOrderOwnerTime = getTotalTime(orderOwnerEvents) 
            setTotalOOTime(totalOrderOwnerTime["Days"]+ 'D : ' + totalOrderOwnerTime["Hours"]+ 'H : ' + totalOrderOwnerTime["Minutes"]+ 'M' );

            let totaldataOwnerTime = getTotalTime(dataOwnerEvents) 
            setTotalDOTime(totaldataOwnerTime["Days"]+ 'D : ' + totaldataOwnerTime["Hours"]+ 'H : ' + totaldataOwnerTime["Minutes"]+ 'M' );
    }, [props.data]);

    React.useEffect(() => {
        if (CommonAppConfig) {
          const timeLineConfig = CommonAppConfig.find((element) => { return element.ConfigShortDesc === "Timeline" });
          if (timeLineConfig) {
                setTotalTime(timeLineConfig.ConfigValue);
            }
        }
      }, [CommonAppConfig])
        
    return (
        <>
                    <div style={Parentdiv}>
                    {
                        props.data?.OrderEvents?.map((element: any, index: any) => (
                        <>
                         <Tooltip arrow placement="right-start"
                                title={
                                    <React.Fragment>
                                        <b>{'Order Event: '}{element.OrderEvent}</b>
                                        {<><br/><b>{'Operator: '}{element.Operator}</b></>}
                                        {<><br/><b>{'Time: '}{element.TimeSpan}</b></>}
                                    </React.Fragment>
                                }>
                                    <div style={{ height: '100%', width: element.TimeSpanPercentage + '%', backgroundColor: getColorClassName(element.Operator)}}>
                                    </div>
                            </Tooltip>
                             </>
                        )
                    )}

                    </div>        

                    <Grid>
                        <Grid item xs={12}>
                                    {
                                        props.data?.OrderEvents?.map((element: any, index: any) => {
                                            <>
                                                <Tooltip arrow placement="right-start"
                                                        title={
                                                            <React.Fragment>
                                                                <b>{'Order Event: '}{element.OrderEvent}</b>
                                                                {<><br/><b>{'Operator: '}{element.Operator}</b></>}
                                                                {<><br/><b>{'Time: '}{element.TimeSpan}</b></>}
                                                            </React.Fragment>
                                                        }>
                                                            <div style={{ height: '100%', width: element.TimeSpanPercentage + '%', backgroundColor: getColorClassName(element.Operator)}}>
                                                            </div>
                                                </Tooltip>
                                            </>
                                        })
                                    }

                                            <div>
                                                <Typography sx={{
                                                            fontSize: 'x-small',
                                                            color:getColorClassName(props.data.RequestedBy),
                                                            display: 'inline-block',
                                                            backgroundColor: 'transparent',
                                                        }} gutterBottom > {props.data.RequestedBy} : </Typography>
                                                <Chip   style={{ width: '90px', height: '25px', float : 'right' }} size="small" sx={{
                                                            fontSize: 'x-small',
                                                            display: 'inline-block',
                                                            color:getColorClassName(props.data.RequestedBy),
                                                            backgroundColor: 'transparent',
                                                        }} label = {totalOOTime} variant="outlined" ></Chip>
                                            </div>
                                            <div>
                                            <Typography sx={{
                                                        fontSize: 'x-small',
                                                        display: 'inline-block',
                                                        color:getColorClassName(props.data.RequestedTo),
                                                        backgroundColor: 'transparent',
                                                    }} gutterBottom >{props.data.RequestedTo} : </Typography>
                                             <Chip   style={{ width: '90px', height: '25px', float : 'right' }} size="small" sx={{
                                                            fontSize: 'x-small',
                                                            display: 'inline-block',
                                                            color:getColorClassName(props.data.RequestedTo),
                                                            backgroundColor: 'transparent',
                                                        }} label = {totalDOTime} variant="outlined" ></Chip>

                                            </div>
                        </Grid>
                    </Grid>
                   
        </>
            )
};