import { Avatar, Box, Button, Checkbox, CssBaseline, FormControlLabel, FormGroup, Grid, LinearProgress, TextField, Typography } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import React, { useEffect } from 'react';
import "./login.css";
import Dashboard from './Components/Dashboard/Dashboard';
import logo from './Utility/Logo/oasisLogo.jpg';
import * as api from 'src/Utility/api';
import Paper from '@mui/material/Paper';
import crypto from 'crypto-js';
import { IUserSecurityInfo } from './Components/Dashboard/@State/types';

const key = crypto.enc.Utf8.parse('oasisdataexchang');
const iv = crypto.enc.Utf8.parse('oasisdataexchang');

function Login(props: any) {
  const [isLoginInProgress, setIsLoginInProgress] = React.useState(false);
  const [errorMessages, setErrorMessages] = React.useState({ name: "", message: "" });
  const [userSecurityProfile, setuserSecurityProfile] = React.useState<IUserSecurityInfo[]>([]);

  const renderErrorMessage = (name: any) =>
    name === errorMessages.name && (
      <div className="error">{errorMessages.message}</div>
    );

  const openPassWordResetPage = () => {
    window.open("https://aka.ms/sspr", "_blank");
  }

  useEffect(() => {
    if (userSecurityProfile.length > 0) { // only if security profile is set user need to be allowed in //
      props.setIsUserLoggedIn(true);
    }
  }, [userSecurityProfile]);

  function handleSubmit(event: any) {
    event.preventDefault();
    setIsLoginInProgress(true);
    setErrorMessages({ name: "password", message: "" });
    const data = new FormData(event.currentTarget);
    if (data.get('email') === '' || data.get('password') === '') {
      setErrorMessages({ name: "password", message: "Please enter username and password" });
      setIsLoginInProgress(false);
    }
    else {
      LoginWithUser(data.get('email'), data.get('password'));
    }
  }

  async function fetchUserInfo(loggedInUserMailId: string) {
    await api.API_GET('/Common/GetSecurityProfile?userName=' + loggedInUserMailId)
      .then((response) => {
        if (response.data.SecurityGroups.length > 0) {
          setuserSecurityProfile(response.data.SecurityGroups);
          if (sessionStorage.getItem('Operators')) {
            sessionStorage.removeItem('Operators');
            sessionStorage.setItem('Operators', response.data.Operators);
          }
          else {
            sessionStorage.setItem('Operators', response.data.Operators);
          }
        } else {
          clearSessionsHandler();
          setIsLoginInProgress(false);
          setErrorMessages({ name: "password", message: "Invalid username and password" });
        }
      });
  }

  const clearSessionsHandler = () =>{
    sessionStorage.removeItem('access_Token');
    sessionStorage.removeItem('userEmailId');
    sessionStorage.removeItem('userName');
    sessionStorage.removeItem('Operators');
  }

  async function LoginWithUser(username: any, password: any) {

    let encrypted = crypto.AES.encrypt(crypto.enc.Utf8.parse(password), key, {
      keySize: 128 / 8,
      iv: iv,
      mode: crypto.mode.CBC,
      padding: crypto.pad.Pkcs7
    }).toString();

    let data = {
      UserName: username,
      Password: encrypted,
    };

    await api.API_LOGIN_POST("/Common/Login", data).then((response) => {
      if (response.data.IsLoginSuccess) {
        props.setLoggedInUser(response.data.UserName);
        props.setLoggedInUserMailId(response.data.UserEmailId);
        sessionStorage.setItem('access_Token', response.data.Token);
        sessionStorage.setItem('userEmailId', response.data.UserEmailId);
        sessionStorage.setItem('userName', response.data.UserName);
        fetchUserInfo(response.data.UserEmailId);
        api.LOG_MESSAGE(["Page : Login","Action : User Logged In"], {'UserName' : response.data.UserName});
        //props.setIsUserLoggedIn(true);
      }
      else {
        setIsLoginInProgress(false);
        if (response.data.ErrorClasification === "UserPasswordExpired") {
          setErrorMessages({ name: "password", message: "User Password Expired" });
          window.open("https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=4765445b-32c6-49b0-83e6-1d93765276ca&redirect_uri=https%3A%2F%2Fwww.office.com%2Flandingv2&response_type=code%20id_token&scope=openid%20profile%20https%3A%2F%2Fwww.office.com%2Fv2%2FOfficeHome.All&response_mode=form_post&nonce=638179746130451315.ZWRkYWQyNmItYWJjOS00OTYxLTgwZWYtMjA4MzY2YzZjNjA3ZjA1ZTgyZmItZjBkYi00NWJmLWEyM2QtMTkwY2FjMTExMmEx&ui_locales=en-US&mkt=en-US&state=DPU2_VONn34KvVDUXf6_Jd63xJD_5YI8R7r5pBshYcmBtKaUJQqBZgqye5IO5uRNzcveDbpRlRHHkRbS_YKnALIy-x5-UFRXKNVhwmzfzECnmDGidQxbk2aZhxNKE79UzdXg_RvhgdrOwuOvhkpZA_Nt57tU6qJgUszXAlrrsTFj_3pnkLdkpxaOKhH5VwXJ3Qz0X5sV9j51KS-6Q0chEV9IYNWGqBVFUcSdDYcl0R-CJc9f3ZZEz3D3M0MXzLRxhqRXtFLVmZKj74zjB6NZgg&x-client-SKU=ID_NET6_0&x-client-ver=6.26.1.0&login_hint={UserEmailId}".replaceAll("{UserEmailId}", username), "_blank");
        }
        else {
          setErrorMessages({ name: "password", message: "Invalid username and password" });
        }
      }
    }).catch(
      (res) => {
        setIsLoginInProgress(false);
        setErrorMessages({ name: "password", message: "Invalid username and password" });
      }
    );
  }

  async function LoginWithSSO() {
    await api.API_GET_AUTH_TOKEN().then((response) => {
      if (response) {
        let username = response.data[0]?.user_claims?.find((element: any) => { return element.typ === "name" })?.val;
        let emailId = response.data[0]?.user_claims?.find((element: any) => { return element.typ === "preferred_username" })?.val;
        let token = response.data[0]?.user_claims?.find((element: any) => { return element.typ === "access_token" })?.val


        props.setLoggedInUser(username);
        props.setLoggedInUserMailId(emailId);
        sessionStorage.setItem('access_Token', token);
        sessionStorage.setItem('userEmailId', emailId);
        sessionStorage.setItem('userName', username);
        fetchUserInfo(emailId);
        api.LOG_MESSAGE(["Page : Login","Action : User Logged In"], {'UserName' : username});
        //props.setIsUserLoggedIn(true);
      }
      else {
        setIsLoginInProgress(false);
        setErrorMessages({ name: "password", message: "Invalid username and password" });
      }
    }).catch(
      (res) => {
        setIsLoginInProgress(false);
        setErrorMessages({ name: "password", message: "Invalid username and password" });
      }
    );
  }

  return (
    <div>
      <Grid container component="main" sx={{ height: '100vh' }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage: `url(${logo})`,
            backgroundRepeat: 'no-repeat',
            backgroundColor: (t) =>
              t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
            backgroundSize: '80%',
            backgroundPosition: 'center',
          }}
        />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box
          
            sx={{
              my: 8,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              marginTop:'20%'
            }}
          >
            
            <Typography component="h1" variant="h5">
              Sign in to your account
            </Typography>
            <Box className='loginBox' component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
              <TextField disabled={isLoginInProgress}
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
              />
              <TextField disabled={isLoginInProgress}
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
              />
              {renderErrorMessage("password")}
              <Button id="SignIn" disabled={isLoginInProgress}
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}>
                Sign In
              </Button>

              {/* <Button disabled={isLoginInProgress}
                onClick= {LoginWithSSO}
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}>
                Login with SSO
              </Button> */}

              {isLoginInProgress && <LinearProgress color="inherit" style={{width:"100%"}}/>}
              <Typography variant="button"><a href="#" onClick={openPassWordResetPage} >Forgot Password ?</a></Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
}

export default Login;
