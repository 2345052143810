import { Rating } from "@mui/material";
import React, { useState } from "react";

import './QualityRatingRenderer.css';

export default function QualityRatingRenderer(props: any) {
    const [value, setValue] = useState<number | null>(Number(props.value) ? props.value : 0);
    const [rowIndex, setRowIndex] = useState(props.rowIndex);

    const valueChangeHandler = (event: any, newValue: any) => {
        setValue(newValue);
        props.onRatingChange(newValue, rowIndex, props.data);
    }

    const getColorClassName = () => {
        switch (Math.ceil(value?value:0)) {
            case 1: return "UpToTwoStar"
            case 2: return "UpToTwoStar"
            case 3: return "UpToThreeStar"
            case 4: return "UpToFourStar"
            case 5: return "UpToFiveStar"
            default: return "NoRating"
        }
    }

    return (
        <Rating
            disabled={props.isReadonly}
            className={getColorClassName()}
            name="simple-controlled"
            value={Math.ceil(value?value:0)}
            size="small"
            onChange={valueChangeHandler}
        />
    )
}