import {
  Box,
  Checkbox,
  DialogActions,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Popper,
  PopperPlacementType,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import { useEffectOnce } from "src/hooks/useEffectOnce";
import { debounce } from "src/Utility/api";
import CancelIcon from "@mui/icons-material/Cancel";
import DoneIcon from "@mui/icons-material/Done";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import RefreshOutlinedIcon from "@mui/icons-material/RefreshOutlined";

function MapFilter(props: any) {
  const [open, setOpen] = React.useState(false);
  const [placement, setPlacement] =
    React.useState<PopperPlacementType>("bottom-start");
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [columnSelection, setColumnSelection] = React.useState<any[]>([]);
  const [selectedColumns, SetSelectedColumns] = React.useState<string>(
    props.value ? props.value : "All"
  );
  const [searchText, setSearchText] = React.useState<string>("");
  const [columnSelectionFiltered, setColumnSelectionFiltered] = React.useState<
    any[]
  >([]);
  const [alignment, setAlignment] = React.useState("OR");

  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string
  ) => {
    setAlignment(newAlignment);
  };

  useEffectOnce(() => {
    setTimeout(() => {
      loadCountyStateInformation();
    }, 200);
  });

  useEffect(() => {
    loadCountyStateInformation();
  }, props.mapFilters);

  useEffect(() => {
    if (searchText !== "") {
      const filteredColumnSelection = columnSelection.filter((ele) => {
        return ele.ColumnName.toLowerCase().includes(searchText.toLowerCase());
      });
      setColumnSelectionFiltered(filteredColumnSelection);
    } else {
      setColumnSelectionFiltered([]);
    }
  }, [searchText]);

  const handleClickOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
    setAlignment("OR");
  };

  const clearSelection = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setOpen(false);
  };

  const chooseSelection = (event: React.MouseEvent<HTMLButtonElement>) => {
    props.resetMapHandler();
    SetSelectedColumns(getSelectedColumns());
    applyMapFilters();
    setAnchorEl(event.currentTarget);
    setOpen(false);
  };

  const setColumnPreferenceHandler = (
    columnPerferenceObj: any,
    isEnable: boolean
  ) => {
    let _columnPreference = [...columnSelection];
    _columnPreference[_columnPreference.indexOf(columnPerferenceObj)].Enable =
      isEnable;
    setColumnSelection(_columnPreference);
  };

  const getSelectedColumns = () => {
    if (columnSelection.length > 0) {
      const selectedColumns = columnSelection.filter((ele: any) => {
        return ele.Enable;
      });
      if (selectedColumns.length > 0) {
        const columnsNames = selectedColumns.map((ele: any) => {
          return ele.ColumnName;
        });
        return columnsNames.toString();
      } else {
        return "All";
      }
    } else {
      return "All";
    }
  };

  const loadCountyStateInformation = () => {
    let columnPreference: any[] = [];

    let mapFiltersByOperator = props.mapFilters.filter((element: any) => {
      return element.OperatorShortName === props.operator;
    });

    mapFiltersByOperator?.forEach((element: any) => {
      const stateShortDescription = element.FilterName;
      if (props.value?.includes(element)) {
        columnPreference.push({
          ColumnName: stateShortDescription,
          fileName: element,
          Enable: true,
        });
      } else {
        columnPreference.push({
          ColumnName: stateShortDescription,
          fileName: element,
          Enable: false,
        });
      }
    });
    setColumnSelection(columnPreference);
    props.resetMapHandler();
  };

  const applyMapFilters = () => {
    let selectedState = columnSelection.filter((element: any) => {
      return element.Enable;
    });
    let filterModels = selectedState.map((element: any) => {
      return element.fileName.FilterModelJson;
    });
    if (selectedState.length > 0 && filterModels.length > 0) {
      if (alignment === "AND" || selectedState.length == 1) {
        let filterModelTOApply = {};
        filterModels.forEach((element: any) => {
          let _filterObj = JSON.parse(element);
          filterModelTOApply = { ...filterModelTOApply, ..._filterObj };
        });
        props.mapDataGridRef?.current?.api.setFilterModel(filterModelTOApply);
      } else {
        
        props.setFilterModelSelection(selectedState);
        props.setFilterAlignment(alignment);
        
        parseFilterModel(filterModels);
      }
    }
  };

  const parseFilterModel = (filterModels: any[]) => {
    let filteredData:any[] = [];
    
    filterModels.forEach((element: any) => {
      let filterModel =  JSON.parse(element);
      let filterDataModel = [...props.dataAuditData];

      let filterProperties = Object.keys(filterModel);

      filterProperties.forEach((propElement: string) => {
        const _filterModelElement = filterModel[propElement];

        if (_filterModelElement?.filterType === "multi") {
          // Filter is on string or number type
          const innerFilterModels = _filterModelElement.filterModels;
          if (innerFilterModels?.length > 0) {
            innerFilterModels.forEach((InnerFilterModelelement: any) => {
              if (InnerFilterModelelement !== null) {
                filterDataModel = applyModelParsedFilter(
                  //Filters and stores
                  filterDataModel,
                  propElement,
                  InnerFilterModelelement.type,
                  InnerFilterModelelement.filter
                );
              }
            });
          }
        }

        if (_filterModelElement?.filterType === "set") {
          // Filter is on a set - Dataset or other sets
          filterDataModel = filterDataModel.filter((element: any) => {
            return element[propElement] === ((_filterModelElement.values[0] === "1") ? 1 : 0);
          });
        }
      });

      filterDataModel.map((DataModelelement)=>{
        let matchElement = filteredData.filter((element)=>{ return element.DataAuditId === DataModelelement.DataAuditId});
        if(matchElement.length == 0){
          filteredData.push(DataModelelement);
        }
      });
    });

    props?.mapDataGridRef?.current?.api?.setRowData(filteredData);
    props.setDataAuditData(filteredData);
  };

  const applyModelParsedFilter = (
    filterDataModel: any,
    property: string,
    type: string,
    filter: any,
    filterTo: any = null
  ) => {
    switch (type?.toLocaleLowerCase()) {
      case "contains":
        filterDataModel = filterDataModel.filter((element: any) => {
          return element[property]
            ?.toLowerCase()
            ?.contains(filter?.toLowerCase());
        });
        break;
      case "notcontains":
        filterDataModel = filterDataModel.filter((element: any) => {
          return (
            element[property]?.toLowerCase()?.indexOf(filter?.toLowerCase()) >=
            0
          );
        });
        break;
      case "notequal":
        filterDataModel = filterDataModel.filter((element: any) => {
          return element[property]?.toLowerCase() === filter?.toLowerCase();
        });
        break;
      case "startswith":
        filterDataModel = filterDataModel.filter((element: any) => {
          return element[property]
            ?.toLowerCase()
            ?.startsWith(filter?.toLowerCase());
        });
        break;
      case "endswith":
        filterDataModel = filterDataModel.filter((element: any) => {
          return element[property]
            ?.toLowerCase()
            ?.endsWith(filter?.toLowerCase());
        });
        break;
      case "blank":
        filterDataModel = filterDataModel.filter((element: any) => {
          return (
            element[property] === "" ||
            element[property] === undefined ||
            element[property] === null
          );
        });
        break;
      case "notblank":
        filterDataModel.filter((element: any) => {
          return (
            element[property] !== "" &&
            element[property] !== undefined &&
            element[property] !== null
          );
        });
        break;
      case "lessthan":
        filterDataModel = filterDataModel.filter((element: any) => {
          return element[property] < filter;
        });
        break;
      case "lessthanorequal":
        filterDataModel = filterDataModel.filter((element: any) => {
          return element[property] <= filter;
        });
        break;
      case "greaterthan":
        filterDataModel = filterDataModel.filter((element: any) => {
          return element[property] > filter;
        });
        break;
      case "greaterthanorequal":
        filterDataModel = filterDataModel.filter((element: any) => {
          return element[property] >= filter;
        });
        break;
      case "inRange":
        filterDataModel = filterDataModel.filter((element: any) => {
          return element[property] >= filter && element[property] <= filterTo;
        });
        break;
      default:
        break;
    }

    return filterDataModel;
  };

  const selectedFiltersLength = () =>{
    return props?.filterModelSelection?.length;
  }

  return (
    <>
      <Tooltip title="Map Filters">
        <IconButton id="mapfilter"
          sx={{ marginLeft: "5px",color: (selectedFiltersLength() > 0) ? "red":"" }}
          color="success"
          aria-label="Add to cart"
          style={{ backgroundColor: "rgb(239 238 238)", width: "fit-content" }}
          onClick={handleClickOpen}
        >
          <FilterAltOutlinedIcon fontSize="small" />
        </IconButton>
      </Tooltip>
      <Popper
        open={open}
        placement={placement}
        anchorEl={anchorEl}
        style={{ zIndex: "10000" }}
      >
        <Box
          sx={{
            border: "2px solid #bfbfbf",
            p: 1,
            bgcolor: "background.paper",
          }}
        >
          <DialogTitle sx={{ padding: "0px" }}>Map filters</DialogTitle>
          <Typography>(pick any filter to apply)</Typography>
          <TextField
            label="Search"
            id="columnselection-search-text-box-filter"
            value={searchText}
            sx={{ width: "100%" }}
            size="small"
            onChange={(ele) => {
              debounce(setSearchText(ele.target.value));
            }}
          />
          <Box
            sx={{
              border: "1px solid #bfbfbf",
              p: 1,
              bgcolor: "background.paper",
            }}
            style={{ height: "25vh", width: "100%" }}
            className="ag-theme-material"
          >
            <List
              sx={{
                width: "100%",
                maxWidth: 360,
                maxHeight: "25vh",
                overflow: "scroll",
                bgcolor: "background.paper",
              }}
            >
              {columnSelection.map((column: any, index: number) => {
                const labelId = `checkbox-list-label-${column.ColumnName}`;
                if (
                  columnSelectionFiltered.length === 0 ||
                  columnSelectionFiltered
                    .map((ele: any) => {
                      return ele.ColumnName.toLowerCase();
                    })
                    .includes(column.ColumnName.toLowerCase())
                ) {
                  return (
                    <ListItem
                      key={column.ColumnName + "index" + index}
                      secondaryAction={
                        <IconButton id="comments"
                          edge="end"
                          aria-label="comments"
                        ></IconButton>
                      }
                      disablePadding
                    >
                      <ListItemButton
                        role={undefined}
                        dense
                        onClick={() => {
                          setColumnPreferenceHandler(column, !column.Enable);
                        }}
                      >
                        <ListItemIcon>
                          <Checkbox
                            style={{ padding: "0px" }}
                            edge="start"
                            checked={column.Enable}
                            tabIndex={-1}
                            disableRipple
                            inputProps={{ "aria-labelledby": labelId }}
                          />
                        </ListItemIcon>
                        <ListItemText
                          id={labelId}
                          primary={column.ColumnName}
                        />
                      </ListItemButton>
                    </ListItem>
                  );
                }
              })}
            </List>
          </Box>
          <DialogActions sx={{ padding: "2px", marginTop: "12px" }}>
            {columnSelection?.filter((element) => {
              return element.Enable === true;
            })?.length > 1 && (
              <ToggleButtonGroup
                color="primary"
                size="small"
                value={alignment}
                exclusive
                onChange={handleChange}
                aria-label="Platform"
              >
                <ToggleButton value="AND">AND</ToggleButton>
                <ToggleButton value="OR">OR</ToggleButton>
              </ToggleButtonGroup>
            )}

            <IconButton
              id="successmapfilter"
              color="success"
              aria-label="done"
              onClick={() => {
                loadCountyStateInformation();
              }}
            >
              <RefreshOutlinedIcon />
            </IconButton>
            <IconButton
              id="successmapfilter2"
              color="success"
              aria-label="done"
              onClick={chooseSelection}
            >
              <DoneIcon />
            </IconButton>
            <IconButton
            id="errorMapfilter"
              color="error"
              aria-label="cancel"
              onClick={clearSelection}
            >
              <CancelIcon />
            </IconButton>
          </DialogActions>
        </Box>
      </Popper>
    </>
  );
}

export default MapFilter;
