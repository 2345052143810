import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import { alpha, styled } from '@mui/material/styles';
import TreeView from '@mui/lab/TreeView';
import TreeItem, { TreeItemProps, treeItemClasses } from '@mui/lab/TreeItem';
import Collapse from '@mui/material/Collapse';
import { useSpring, animated } from '@react-spring/web';
import { TransitionProps } from '@mui/material/transitions';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Box, Chip, Rating, TextField, Typography } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import Paper from '@mui/material/Paper';
import {AppContext} from '../../@State/context/AppContext';
import { ExchangeOrderType } from '../../@State/types';

function MinusSquare(props: SvgIconProps) {
    return (
        <SvgIcon fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
            {/* tslint:disable-next-line: max-line-length */}
            <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 11.023h-11.826q-.375 0-.669.281t-.294.682v0q0 .401.294 .682t.669.281h11.826q.375 0 .669-.281t.294-.682v0q0-.401-.294-.682t-.669-.281z" />
        </SvgIcon>
    );
}

function PlusSquare(props: SvgIconProps) {
    return (
        <SvgIcon fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
            {/* tslint:disable-next-line: max-line-length */}
            <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 12.977h-4.923v4.896q0 .401-.281.682t-.682.281v0q-.375 0-.669-.281t-.294-.682v-4.896h-4.923q-.401 0-.682-.294t-.281-.669v0q0-.401.281-.682t.682-.281h4.923v-4.896q0-.401.294-.682t.669-.281v0q.401 0 .682.281t.281.682v4.896h4.923q.401 0 .682.281t.281.682v0q0 .375-.281.669t-.682.294z" />
        </SvgIcon>
    );
}

function CloseSquare(props: SvgIconProps) {
    return (
        <SvgIcon
            className="close"
            fontSize="inherit"
            style={{ width: 14, height: 14 }}
            {...props}
        >
            {/* tslint:disable-next-line: max-line-length */}
            <path d="M17.485 17.512q-.281.281-.682.281t-.696-.268l-4.12-4.147-4.12 4.147q-.294.268-.696.268t-.682-.281-.281-.682.294-.669l4.12-4.147-4.12-4.147q-.294-.268-.294-.669t.281-.682.682-.281.696 .268l4.12 4.147 4.12-4.147q.294-.268.696-.268t.682.281 .281.669-.294.682l-4.12 4.147 4.12 4.147q.294.268 .294.669t-.281.682zM22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0z" />
        </SvgIcon>
    );
}

function TransitionComponent(props: TransitionProps) {
    const style = useSpring({
        from: {
            opacity: 0,
            transform: 'translate3d(20px,0,0)',
        },
        to: {
            opacity: props.in ? 1 : 0,
            transform: `translate3d(${props.in ? 0 : 20}px,0,0)`,
        },
    });

    return (
        <animated.div style={style}>
            <Collapse {...props} />
        </animated.div>
    );
}

const StyledTreeItem = styled((props: TreeItemProps) => (
    <TreeItem
        {...props}
        TransitionComponent={TransitionComponent} />
))(({ theme }) => ({
    [`& .${treeItemClasses.iconContainer}`]: {
        '& .close': {
            opacity: 0.3,
        },
    },
    [`& .${treeItemClasses.group}`]: {
        marginLeft: 15,
        paddingLeft: 18,
        borderLeft: `1px dashed ${alpha(theme.palette.text.primary, 0.4)}`,
    },
}));

interface INodeIndfo {
    reportGroups: any[] | undefined;
    currentMapNodeData: any;
    qualityRatings: any[];
    expandAll: boolean;
    defaultExpandedInfo? : any
}

let _nodeIds: string[] = [];
let showChild: boolean = false;

export default function NodeInfo(props: INodeIndfo) {
    const [expanded, setExpanded] = React.useState<any[]>([]);
    const {fetchReportDisplayName } = React.useContext(AppContext) as ExchangeOrderType;

    const constructTreeView = () => {
        let _nodeTree: any[] = [];
        _nodeIds.length = 0;
        showChild = false;
        if (props.reportGroups &&
            props.reportGroups?.length > 0) {
            props.reportGroups?.map((group, index: number) => {
                showChild = false;
                _nodeTree.push(constructNode(group, index, _nodeIds, showChild));
            })
        }
        return _nodeTree;
    }

    const getColorClassName = (value: any) => {
        switch (value) {
            case 1: return "UpToTwoStar"
            case 2: return "UpToTwoStar"
            case 3: return "UpToThreeStar"
            case 4: return "UpToFourStar"
            case 5: return "UpToFiveStar"
            default: return "NoRating"
        }
    }

    const constructNode = (group: any, index: number, _nodeIds: string[], showChild: boolean) => {
        if ((group.SubReports.length > 1 && group.SubReports[0]?.HeaderName !== group.HeaderName) ||
            (group.SubReports.length == 1 && group.SubReports[0]?.HeaderName !== group.HeaderName)) {
                if(props.defaultExpandedInfo) {
                    if(group.HeaderName == props.defaultExpandedInfo?.L1 || group.HeaderName == props.defaultExpandedInfo?.L2 || 
                        group.HeaderName == props.defaultExpandedInfo?.L3 || group.HeaderName == props.defaultExpandedInfo?.L4 ||
                        group.HeaderName == props.defaultExpandedInfo?.L5 || group.HeaderName == props.defaultExpandedInfo?.L6) {
                        _nodeIds.push(group.HeaderName + "-" + index);
                    }
                    // else if(group.HeaderName == props.defaultExpandedInfo?.L3) {
                    //     _nodeIds.push(group.HeaderName + "-" + index);
                    //     showChild = true;
                    // }
                    // else if(showChild) {
                    //     _nodeIds.push(group.HeaderName + "-" + index);
                    // }
                }
                else {  
                    _nodeIds.push(group.HeaderName + "-" + index);
                }
            return (
                <StyledTreeItem
                    key={group.HeaderName + "-" + index}
                    nodeId={group.HeaderName + "-" + index}
                    label={group.HeaderName}>
                    {
                        group.SubReports.map((subReport: any, _index: number) => {
                            return constructNode(subReport, _index, _nodeIds, showChild);
                        })
                    }
                </StyledTreeItem>
            )
        } else {
            const chipValue = props.currentMapNodeData ? props.currentMapNodeData[group.HeaderName] : ""
            const qualityRating = props.qualityRatings.find(a => a.ReportName == group.HeaderName);
            const chipLabel = fetchReportDisplayName(group.HeaderName) + " : " + ((chipValue !== null && chipValue !== undefined) ? chipValue : "");
            return (<>
                <div style={{ display: 'flex' }}>
                    {chipValue != "0" && <Chip size="small"
                        sx={{ width: "100%", margin: "2px", display: "flex", justifyContent: "left" }}
                        label={chipLabel} variant="outlined" />}
                    {chipValue != "0" && qualityRating && <Rating readOnly
                        className={getColorClassName(qualityRating?.QualityRating)}
                        name="simple-controlled"
                        value={Math.ceil(qualityRating?.QualityRating)}
                        size="small" />}
                </div>
            </>
            )
        }
    }

    return (
       <>
        {props.expandAll === true &&
            <TreeView
                aria-label="customized"
                defaultCollapseIcon={<MinusSquare />}
                defaultExpandIcon={<PlusSquare />}
                defaultEndIcon={<InfoOutlinedIcon />}
                defaultExpanded={_nodeIds}
                sx={{ flexGrow: 1, padding: '10px' }}
            >
                {constructTreeView()}
            </TreeView> }
            {props.expandAll === false &&
            <TreeView
                aria-label="customized"
                defaultCollapseIcon={<MinusSquare />}
                defaultExpandIcon={<PlusSquare />}
                defaultEndIcon={<InfoOutlinedIcon />}
                defaultExpanded={[]}
                sx={{ flexGrow: 1, padding: '10px' }}
            >
                {constructTreeView()}
            </TreeView> }
       </>
    );
}