import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';

import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-material.css';
import 'ag-grid-enterprise';

import { SideBarDef } from 'ag-grid-enterprise';
import { ExchangeOrderType, ITableGridColumnDefinition, MapViewType } from 'src/Components/Dashboard/@State/types';
import { useEffectOnce } from 'src/hooks/useEffectOnce';
import { constructColumnGrouping } from '../../TableView/columnGrouping';
import { Avatar, Box, Skeleton, Typography } from '@mui/material';
import { AppContext } from 'src/Components/Dashboard/@State/context/AppContext';

import './MapGrid.css';

function MapGrid(props: any) {
    const [columns, setColumns] = useState<any[]>([]);
    const { fetchReportDisplayName } = useContext(AppContext) as ExchangeOrderType;

    const dataAuditGridRef: any = useRef();

    useEffectOnce(() => {
        props.loadInitialPrerequisiteData();
    });

    useEffect(() => {
        if (props.dataAuditData.length > 0) {
            setColumns(Object.keys(props.dataAuditData[0]));
        }
    }, [props.dataAuditData]);

    useEffect(() => {
        if (columns.length > 0) {
            loadDataAuditGrid();
        }
    }, [columns]);

    const defaultColDef = useMemo(() => {
        return {
            resizable: true,
            wrapHeaderText: true,
            autoHeaderHeight: true,
            editable: false
        };
    }, []);


    const gridOptions = {
        // PROPERTIES
        defaultColDef: defaultColDef,
        pagination: false
    }

    const sideBar: SideBarDef = useMemo(() => {
        return {
            toolPanels: [
                {
                    id: 'filters',
                    labelDefault: 'Filters',
                    labelKey: 'filters',
                    iconKey: 'filter',
                    toolPanel: 'agFiltersToolPanel',
                    minWidth: 270,
                    maxWidth: 270,
                    width: 270,
                },
            ],
            position: 'right',
            defaultToolPanel: 'filters'
        };
    }, []);

    const loadDataAuditGrid = () => {
        if (props.dataAuditData !== undefined && props.dataAuditData.length >= 0) {
            let columnDefinitions = constructColumnDefinitions();
            if (dataAuditGridRef !== undefined && dataAuditGridRef.current !== undefined) {
                if (dataAuditGridRef?.current?.api !== 'undefined') {
                    dataAuditGridRef?.current?.api?.setColumnDefs(columnDefinitions);
                    dataAuditGridRef?.current?.api?.setRowData(props.dataAuditData);
                    dataAuditGridRef?.current?.api?.sizeColumnsToFit();
                    hideAllFilter();
                    props.setMapDataGridRefHandler(dataAuditGridRef);
                } else {
                    dataAuditGridRef.current.props.gridOptions.columnDefs = columnDefinitions;
                    dataAuditGridRef.current.props.gridOptions.rowData = props.dataAuditData;
                    hideAllFilter();
                    props.setMapDataGridRefHandler(dataAuditGridRef);
                }
            }
        }
    }

    const formatHeaderName = (value: string) => {
        return value.charAt(0).toUpperCase() + value.slice(1);
    }

    const removeYNFormatter = (value:string) =>{
        return value.replaceAll("_YN","").replaceAll("_"," ");
    }

    const constructColumnDefinitions = () => {
        let columnDefinitions: ITableGridColumnDefinition[] = [];
        columns.forEach((column) => {
            let columnDefinition: ITableGridColumnDefinition = {
                field: column,
                //type: 'editableColumn',
                headerName: fetchReportDisplayName(formatHeaderName(column)),
                headerTooltip: fetchReportDisplayName(formatHeaderName(column)),
                cellClass: ['ag-cell-custom'],
                filter: 'agMultiColumnFilter',
                sortable: true
            };

            columnDefinitions.push(columnDefinition);
        });
        columnDefinitions = constructColumnGrouping(columnDefinitions, props.reportConfigurations);

        return columnDefinitions;
    }

    const hideAllFilter = () => {
        if (dataAuditGridRef?.current?.api !== typeof (undefined)) {
            const filtersToolPanel = dataAuditGridRef?.current?.api?.getToolPanelInstance('filters');
            if (filtersToolPanel) {
                filtersToolPanel.collapseFilterGroups();
                filtersToolPanel.expandFilterGroups(['generalinformation', 'geology', 'engineering', 'finance','software']);
            }
        }
    }

    const onFilterChangedInternalHandler = () =>{
        props.onFilterChangedHandler(props.reportGroupInfo);
    }

    return (
        <div className={"ag-theme-material map"} style={{ height: "78vh" }}>
            {columns.length === 0 &&
                <>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Box sx={{ margin: 1 }}>
                            <Skeleton variant="circular">
                                <Avatar />
                            </Skeleton>
                        </Box>
                        <Box sx={{ width: '100%' }}>
                            <Skeleton width="100%">
                                <Typography>.</Typography>
                            </Skeleton>
                        </Box>
                    </Box>
                    <Skeleton />
                    <Skeleton animation="wave" />
                    <Skeleton animation={false} />
                    <Skeleton />
                    <Skeleton animation="wave" />
                    <Skeleton animation={false} />
                    <Skeleton />
                    <Skeleton animation="wave" />
                    <Skeleton animation={false} />
                    <Skeleton />
                    <Skeleton animation="wave" />
                    <Skeleton animation={false} />
                    <Skeleton animation="wave" />
                    <Skeleton animation={false} />
                    <Skeleton />
                    <Skeleton animation="wave" />
                    <Skeleton animation={false} />
                    <Skeleton />
                    <Skeleton animation="wave" />
                    <Skeleton animation={false} />
                    <Skeleton />
                    <Skeleton animation="wave" />
                    <Skeleton animation={false} />
                    <Skeleton animation="wave" />
                    <Skeleton animation={false} />
                    <Skeleton />
                    <Skeleton animation="wave" />
                    <Skeleton animation={false} />
                    <Skeleton animation="wave" />
                    <Skeleton animation={false} />
                    <Skeleton />
                    <Skeleton animation="wave" />
                    <Skeleton animation={false} />
                    <Skeleton />
                    <Skeleton animation="wave" />
                    <Skeleton animation={false} />
                    <Skeleton />
                    <Skeleton animation="wave" />
                    <Skeleton animation={false} />
                </>
            }

            <AgGridReact
                ref={dataAuditGridRef}
                gridOptions={gridOptions}
                pagination={false}
                sideBar={sideBar}
                onFilterChanged={onFilterChangedInternalHandler}>
            </AgGridReact>

        </div>
    )
}

export default MapGrid;