import { IMessaging, MessageActions, MessageTags } from "src/Components/Dashboard/@State/types";
import { API_POST,API_GET } from "../api";

// Broadcast Points 
// ----------------
// 1. Order Update
// 2. Order related value change
// 3. Estimates change and comments
// 4. Valuation change and comments
// 5. Approval 

export const BroadcastMessage = (message: IMessaging) => {
    async function sendMessage() {
        await API_POST("/Messaging/BroadcastMessage", message).then((res) => {
        }).catch(error => {
            console.log("error in post call", error)
        });
    }

    sendMessage();
}

export const PlaceOrderBroadcast = (newOrders: any[]) => {
    let orderMessages: IMessaging[] = [];

    //Messages within the organization ( to the order owner )
    newOrders.map((order) => {
        const orderMessage: IMessaging = {
            Name: order.CreatedBy,
            Tag: MessageTags.Order,
            Action: MessageActions.Create,
            EmailAddress: "",
            Operator: order.RequestedBy,
            OperatorTo: order.RequestedBy,
            Message: `${order.TradeType} Order #${order.OrderId} created to ${order.RequestedTo}.`,
            isEmailNotification:false,
            ChangedJSONObject: JSON.stringify(order)
        };

        orderMessages.push(orderMessage);
    });

    orderMessages.forEach((message: IMessaging) => { 
        BroadcastMessage(message);
    });
}

export const DrafterOrderBroadcast = (newOrders: any[]) => {
    let orderMessages: IMessaging[] = [];
    newOrders.map((order) => {
        const orderMessage: IMessaging = {
            Name: order.CreatedBy,
            Tag: MessageTags.Order,
            Action: MessageActions.Create,
            EmailAddress: "",
            Operator: order.RequestedBy,
            OperatorTo: order.RequestedBy,
            Message: `${order.TradeType} Order is been drafted to ${order.RequestedTo}.`,
            isEmailNotification:false,
            ChangedJSONObject: JSON.stringify(order)
        };

        orderMessages.push(orderMessage);
    });

    orderMessages.forEach((message: IMessaging) => { // Informing other side operator that an order is drafted //
        BroadcastMessage(message);
    });
}

export const UpdateOrderBroadcast = (updateOrders: any[], requestedOrders: any[]) => {
    let orderMessages: IMessaging[] = [];
    // Messages to opposite side ( to the data owner )
    updateOrders.map((order) => {
        const orderFullObject = requestedOrders.filter((requestedOrder) => { return requestedOrder.OrderId === order.OrderId });
        if (orderFullObject.length > 0) {
            const orderMessage: IMessaging = {
                Name: orderFullObject[0].CreatedBy,
                Tag: MessageTags.Order,
                Action: MessageActions.Update,
                EmailAddress: "",
                Operator: orderFullObject[0].RequestedBy,
                OperatorTo: orderFullObject[0].RequestedTo,
                Message: `${orderFullObject[0].TradeType} Order #${orderFullObject[0].OrderId} between ${orderFullObject[0].RequestedBy} and ${orderFullObject[0].RequestedTo} is updated updated by ${orderFullObject[0].RequestedBy}.`,
                isEmailNotification:false,
                ChangedJSONObject: JSON.stringify(order)
            };

            orderMessages.push(orderMessage);
        }
    });

    //Messages within the organization ( to the order owner )
    updateOrders.map((order) => {
        const orderFullObject = requestedOrders.filter((requestedOrder) => { return requestedOrder.OrderId === order.OrderId });
        if (orderFullObject.length > 0) {
            const orderMessage: IMessaging = {
                Name: orderFullObject[0].CreatedBy,
                Tag: MessageTags.Order,
                Action: MessageActions.Update,
                EmailAddress: "",
                Operator: orderFullObject[0].RequestedBy,
                OperatorTo: orderFullObject[0].RequestedBy,
                Message: `${orderFullObject[0].TradeType} Order #${orderFullObject[0].OrderId} between ${orderFullObject[0].RequestedBy} and ${orderFullObject[0].RequestedTo} has been updated.`,
                isEmailNotification:false,
                ChangedJSONObject: JSON.stringify(order)
            };

            orderMessages.push(orderMessage);
        }
    });

    orderMessages.forEach((message: IMessaging) => { // Informing other side operator that an order is Updated //
        BroadcastMessage(message);
    });
}

export const EstimateUpdatedBroadcast = (estimateData: any,operator:string) => {
    // Broadcast to opposite Side operator //
    const operatorTo = (operator == estimateData.DataOwnerOperator) ? estimateData.OrderOwnerOperator : estimateData.DataOwnerOperator;
    const estimateMessage: IMessaging = {
        Name: estimateData.CreatedBy,
        Tag: MessageTags.Estimates,
        Action: MessageActions.Update,
        EmailAddress: "",
        Operator: operator,
        OperatorTo: operatorTo,
        Message: `Estimates for Order #${estimateData.OrderId} between ${operator} and ${operatorTo} has been updated by ${operator}.`,
        isEmailNotification:false,
        ChangedJSONObject: JSON.stringify(estimateData)
    };

    BroadcastMessage(estimateMessage);

    // Broadcast with in same operator to all users //
    const estimateMessageWithIn: IMessaging = {
        Name: estimateData.CreatedBy,
        Tag: MessageTags.Estimates,
        Action: MessageActions.Update,
        EmailAddress: "",
        Operator: operator,
        OperatorTo: operator,
        Message: `Estimates for Order #${estimateData.OrderId} between ${operator} and ${operatorTo} has been updated by ${operator}.`,
        isEmailNotification:false,
        ChangedJSONObject: JSON.stringify(estimateData)
    };

    BroadcastMessage(estimateMessageWithIn);
}

export const CommentAddedBroadcast = (estimateData: any,operator:string,tag:MessageTags) => {
    const operatorTo = (operator == estimateData.DataOwnerOperator) ? estimateData.OrderOwnerOperator : estimateData.DataOwnerOperator;
    
    // Broadcast to opposite Side operator //
    const commentExternalMessage: IMessaging = {
        Name: estimateData.CreatedBy,
        Tag: tag,
        Action: MessageActions.Update,
        EmailAddress: "",
        Operator: operator,
        OperatorTo: operatorTo,
        Message: `Comments updated in Estimate data for Order #${estimateData.OrderId} between ${operator} and ${operatorTo} by ${operator}.`,
        isEmailNotification:false,
        ChangedJSONObject: JSON.stringify(estimateData)
    };

    BroadcastMessage(commentExternalMessage);

    // Broadcast with in same operator to all users //
    const commentInternalMessage: IMessaging = {
        Name: estimateData.CreatedBy,
        Tag: tag,
        Action: MessageActions.Update,
        EmailAddress: "",
        Operator: operator,
        OperatorTo: operator,
        Message: `Comments updated in Estimate data for Order #${estimateData.OrderId} between ${operator} and ${operatorTo}.`,
        isEmailNotification:false,
        ChangedJSONObject: JSON.stringify(estimateData)
    };

    BroadcastMessage(commentInternalMessage);
}

export const ValuationUpdatedBroadcast = (valuationData: any,operator:string) => {
    const valuationMessage: IMessaging = {
        Name: valuationData.CreatedBy,
        Tag: MessageTags.Valuation,
        Action: MessageActions.Update,
        EmailAddress: "",
        Operator: operator,
        OperatorTo: operator,
        Message: (valuationData.OrderId !== null ) ? `Valuation for Order #${valuationData.OrderId} has been updated.` : 
        `Valuation has been updated.`,
        isEmailNotification:false,
        ChangedJSONObject: JSON.stringify(valuationData)
    };


    BroadcastMessage(valuationMessage);
}

export const GetOrderAndBroadcastOrderUpdate = (OrderId: any,operator:string) => {
    async function getOrderInfoAndBroadcastOrderUpdate() {
        let getEstimateCommentsUrl = '/OrderManagement/GetOrderById?OrderId=' + OrderId +'&_operator='+operator;
        await API_GET(getEstimateCommentsUrl).then((response) => {
            if (response.data !== undefined) {
                UpdateOrderBroadcast([response.data],[response.data])
            }
        }).catch(() => {
        });
    }

    getOrderInfoAndBroadcastOrderUpdate();
}