import { Button } from "@mui/material";
import { InfoWindow, Marker } from "@react-google-maps/api";
import { useCallback, useEffect, useState } from "react";
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import { currencyFormatter } from "src/Utility/common";
import { TradeOrderFromTo } from "../../@State/types";
const oilpumpicon = require("./icons/oil_50.ico");
const oilpumpiconselected = require("./icons/oil-well-selected.ico");
const oilpumpiconAddedToCart = require("./icons/oil-well-addedtocart.ico");
const oilpumpiconExchange = require("./icons/oil-well-exchange.ico");
const oilpumpiconSelectedAndAddedToCart = require("./icons/oil-well-selected-and-addedtocart.ico");

export default function CustomMarker(props: any) {
  const { position, clusterer } = props;
  const [showInfoWindow, setShowInfoWindow] = useState<boolean>(false);
  const [enableAnimation, setEnableAnimation] = useState<any>();

  useEffect(() => {
    if (props.currentMarkerDataAuditId && props.currentMarkerDataAuditId === props.nodeData?.DataAuditId) {
      setShowInfoWindow(true);
    } else {
      setShowInfoWindow(false);
    }
  }, [props.currentMarkerDataAuditId]);

  const onMarkerClickHandler = (event: any) => {
    props.setNodeData(props.nodeData);
    props.handleMapInfoWindowOpenClose(true);

    const isInCart = props.addedToCartDataAuditId.includes(props.nodeData.DataAuditId);
    props.setCurrentMapNodeInCart(isInCart);
  }

  const fetchMarkerIcon = () => {
    if (props.isMapIconEnabled) {
      let wellIcon = oilpumpicon;

      // Conditions for icons when adding wells to cart //

      if (props.currentMapNodeData
        && props.currentMapNodeData?.DataAuditId === props.nodeData?.DataAuditId
        && props.addedToCartDataAuditId.includes(props.nodeData.DataAuditId)) {
        wellIcon = oilpumpiconSelectedAndAddedToCart;
      }
      else if (props.currentMapNodeData
        && props.currentMapNodeData?.DataAuditId === props.nodeData?.DataAuditId) {
        wellIcon = oilpumpiconselected;
      }
      else if (props.addedToCartDataAuditId.includes(props.nodeData.DataAuditId)) {
        wellIcon = oilpumpiconAddedToCart;
      }


      // Conditions for order to map mapping //
      if (props.wellsInOrder.length > 0
        && props.wellsInOrder.includes(props.nodeData.API)) {
        if (props.isExchangeAddWells) {
          wellIcon = oilpumpiconExchange;
        }

        if (props.oppositeTradeWells.length > 0) {
          if (props.oppositeTradeWells.includes(props.nodeData.API)) {
            wellIcon = oilpumpiconAddedToCart;
          } else {
            wellIcon = oilpumpiconExchange;
          }
        }
      }

      return wellIcon;
    }
  }

  const fetchMarkerIconDollarAmount = () => {
    if (props.oppositeTradeWells.includes(props.nodeData.API)) {
      const apiDollarValue = getApiDollarValue();
      return "https://chart.googleapis.com/chart?chst=d_bubble_texts_big&chld=edge_bc|b5ebbb|000000||  Curr$%20%20:%20%20OO$%20%20:%20%20DO$%20%20:%20%20Diff$|-----------:----------:----------:-----------|  "
        + addPadding(apiDollarValue.totalAmount,6) + " : "
        + addPadding(apiDollarValue.totalOrderOwnerProposedAmount,6) + " : "
        + addPadding(apiDollarValue.totalDataOwnerProposedAmount,6) + " : ( "
        + addPadding(apiDollarValue.totalAmountDiff,2) + ")|"
        + "";
    } else {
      const apiDollarValue = getApiDollarValue();
      return "https://chart.googleapis.com/chart?chst=d_bubble_texts_big&chld=edge_bc|94c1fc|000000||  Curr$%20%20:%20%20OO$%20%20:%20%20DO$%20%20:%20%20Diff$|-----------:----------:----------:-----------|  "
        + addPadding(apiDollarValue.totalAmount,6) + " : "
        + addPadding(apiDollarValue.totalOrderOwnerProposedAmount,6) + " : "
        + addPadding(apiDollarValue.totalDataOwnerProposedAmount,6) + " :("
        + addPadding(apiDollarValue.totalAmountDiff,2) + ")|"
        + ""
    }
  }

  const addPadding = (text: string, padLength: number) => {
    const padSize = padLength - text.length;
    if (padSize > 0) {
      let arr = Array.apply(null, Array(padSize));
      arr.forEach(() => {
        text = text + " ";
      })
    }
    return text;
  }

  const isShowMarker = () => {
    let _isShowMarker = true;
    if (props.isExchangeAddWells
      && props.nodeData.Operator == props.exchangeOrder.requestedBy
      && !props.wellsInOrder.includes(props.nodeData.API)) {
      _isShowMarker = false;
    }

    // Check if marker within bounds , if bounds are defined //
    if (props.polygonInfo !== null) {
      let bounds = new google.maps.LatLngBounds();
      for (let i = 0; i < props.polygonInfo.getPath().getLength(); i++) {
        bounds.extend(props.polygonInfo.getPath().getAt(i));
      }
      if (!bounds.contains(new google.maps.LatLng(position.lat, position.lng))) {
        _isShowMarker = false;
      }
    }

    // Check if marker within circle bounds , if bounds are defined //
    if (props.circleInfo !== null) {
      let bounds = props.circleInfo.getBounds();
      if (!bounds.contains(new google.maps.LatLng(position.lat, position.lng))) {
        _isShowMarker = false;
      }
    }

    // Check if marker within rectangle bounds , if bounds are defined //
    if (props.reactangleInfo !== null) {
      let bounds = props.reactangleInfo.getBounds();
      if (!bounds.contains(new google.maps.LatLng(position.lat, position.lng))) {
        _isShowMarker = false;
      }
    }

    return _isShowMarker;
  }

  const isNodeInCart = () => {
    return props.addedToCartDataAuditId.includes(props.nodeData.DataAuditId);
  }

  const isShowDollar = () => {
    return props.wellsInOrder.length > 0 && props.isAddWells == false && props.isExchangeAddWells == false && props.showDollarValues == true
  }

  const getApiDollarValue: any = useCallback(() => {
    let totalAmounts: any = "";
    let totalAmount: any = "0";
    let totalOrderOwnerProposedAmount: any = "0";
    let totalDataOwnerProposedAmount: any = "0";
    if (props.oppositeTradeWells.includes(props.nodeData.API)) {
      totalAmounts = getOppositeSideDollarValue();
    } else {
      totalAmounts = getDollarValue();
    }

    if (totalAmounts !== "" && totalAmounts !== undefined && totalAmounts != 0) {
      totalAmount = parseFloat(totalAmounts.totalAmount) / 1000;
      totalOrderOwnerProposedAmount = parseFloat(totalAmounts.totalOrderOwnerProposedAmount) / 1000;
      totalDataOwnerProposedAmount = parseFloat(totalAmounts.totalDataOwnerProposedAmount) / 1000;
    }
    return {
      totalAmount: currencyFormatter(totalAmount.toString()) + (totalAmount > 0 ? "K" : ""),
      totalOrderOwnerProposedAmount: currencyFormatter(totalOrderOwnerProposedAmount.toString()) + (totalOrderOwnerProposedAmount > 0 ? "K" : ""),
      totalDataOwnerProposedAmount: currencyFormatter(totalDataOwnerProposedAmount.toString()) + (totalDataOwnerProposedAmount > 0 ? "K" : ""),
      totalAmountDiff: currencyFormatter((totalOrderOwnerProposedAmount - totalDataOwnerProposedAmount).toString())
        + "K"
    };
  },[]);

  const getDollarValue = () => {
    let totalDollarValue: any = 0;
    if (props.dollarValues.length > 0) {
      const _apiRecord = props.dollarValues.filter((element: any) => { return element.API === props.nodeData.API });
      if (_apiRecord.length === 1 && _apiRecord[0].ReportDollarValue.length > 0) {
        totalDollarValue = calculateCurrentValue(props.tradeOrderFromTo, _apiRecord,props.orderReportSelectionOrder1);
      }
    }
    return totalDollarValue;
  }

  const getOppositeSideDollarValue = () => {
    let totalDollarValue: any = 0;
    if (props.oppositeTradeOrderDollarValues.length > 0) {
      const _apiRecord = props.oppositeTradeOrderDollarValues.filter((element: any) => { return element.API === props.nodeData.API });
      if (_apiRecord.length === 1 && _apiRecord[0].ReportDollarValue.length > 0) {
        totalDollarValue = calculateCurrentValue(props.oppositeTradeOrderFromTo, _apiRecord,props.orderReportSelectionOrder2);
      }
    }
    return totalDollarValue;
  }

  const calculateCurrentValue = useCallback((_orderFromTo: TradeOrderFromTo, _apiRecord: any,orderReportSelection:any) => {
    let totalAmount = 0;
    let totalOrderOwnerProposedAmount = 0;
    let totalDataOwnerProposedAmount = 0;
    const _selectedOrderReports: any[] = orderReportSelection?.filter((ele: any) => { return ele.Enable === true })
      .map((ele: any) => { return ele.ReportName });

    if (props.selectedOperator == _orderFromTo.OrderBy 
      || props.tradeOrderFromTo?.OrderBy == _orderFromTo.OrderBy 
      || props.tradeOrderFromTo?.OrderTo == _orderFromTo.OrderBy) { // Calculate current value using estimate data //
      _apiRecord[0].ReportDollarValue.forEach((_reportDollarValue: any) => {
        if (_selectedOrderReports.includes(_reportDollarValue.ReportName)) {
          if (_reportDollarValue.EstimateCurrentValue > 0) {
            totalAmount = _reportDollarValue.EstimateCurrentValue;
          } else {
            totalAmount += CalculateCurrentValue({
              DateOfAcquisition: _reportDollarValue.EstimateDateOfAcquisition,
              CostFromAFE: _reportDollarValue.EstimateCostFromAFE,
              Depreciation: _reportDollarValue.EstimateDepreciation
            });
          }
          totalOrderOwnerProposedAmount += _reportDollarValue.EstimateOrderOwnerProposedValue;
          totalDataOwnerProposedAmount += _reportDollarValue.EstimateDataOwnerProposedValue;
        }
      });
    } else if (props.selectedOperator == _orderFromTo.OrderTo 
      || props.tradeOrderFromTo?.OrderTo == _orderFromTo.OrderTo 
      || props.tradeOrderFromTo?.OrderBy == _orderFromTo.OrderTo) { // Calculate current value using valuation data //
      _apiRecord[0].ReportDollarValue.forEach((_reportDollarValue: any) => {
        if (_selectedOrderReports.includes(_reportDollarValue.ReportName)) {
          if (_reportDollarValue.ValuationCurrentValue > 0) {
            totalAmount = _reportDollarValue.ValuationCurrentValue;
          } else {
            totalAmount += CalculateCurrentValue({
              DateOfAcquisition: _reportDollarValue.ValuationDateOfAcquisition,
              CostFromAFE: _reportDollarValue.ValuationCostFromAFE,
              Depreciation: _reportDollarValue.ValuationDepreciation
            });
          }
          totalOrderOwnerProposedAmount += _reportDollarValue.ValuationOrderOwnerProposedValue;
          totalDataOwnerProposedAmount += _reportDollarValue.ValuationDataOwnerProposedValue;
        }
      });
    }

    return {
      totalAmount: totalAmount,
      totalOrderOwnerProposedAmount: totalOrderOwnerProposedAmount,
      totalDataOwnerProposedAmount: totalDataOwnerProposedAmount
    };
  },[]);

  const CalculateCurrentValue = (rowData: any) => {
    let currentValue = 0;
    if (rowData.DateOfAcquisition != null && rowData.CostFromAFE != null && rowData.Depreciation != null) {
      //Current Value = Max(0, AFE Cost – ( AFE Cost * Depreciation% ) *  ( (Today's Date - Acquisition Date) / 365 )   )
      let dateFrom = new Date();
      let dateTo = new Date(rowData.DateOfAcquisition);
      const MS_PER_DAY: number = 1000 * 60 * 60 * 24;
      const daysBetweenDates: number = Math.ceil((dateFrom.getTime() - dateTo.getTime()) / MS_PER_DAY);

      if (daysBetweenDates > 0) {
        const dateDiff = daysBetweenDates / 365;
        const cost = rowData.CostFromAFE * (rowData.Depreciation / 100);
        const value = (rowData.CostFromAFE - (cost * dateDiff));
        currentValue = Math.max(0, value);
      }
    }

    return currentValue;
  }

  return (isShowMarker() ?
    <a href="#" className="ico">
      <Marker
        key={props.nodeData.DataAuditId}
        icon={fetchMarkerIcon()}
        zIndex={1000}
        animation={enableAnimation}
        onClick={(event: any) => {
          onMarkerClickHandler(event);
        }}
        onMouseOver={(ele: any) => {
          setShowInfoWindow(true);
        }}
        onMouseOut={() => {
          setTimeout(() => {
            setShowInfoWindow(false);
          }, 3000);
        }}
        position={position}
        clusterer={clusterer}
        options={{ title: props.nodeData[props.primaryKeyColumnName]?.toString() }}>
        {(showInfoWindow && props.isNodeInfoEnabled) &&
          <InfoWindow position={position} onCloseClick={() => setShowInfoWindow(false)}>
            <>
              <div><strong>API : </strong>{props.nodeData["API"]}</div>
              <div><strong>Well name : </strong>{props.nodeData["WellName"]}</div>
              <br />
              <Button id="custommarkeraddtocartbtn"
                disabled={isNodeInCart() ? true : false}
                onClick={() => { props.onAddtoCart(props.nodeData) }}
                size="small" variant="outlined"
                startIcon={<AddShoppingCartIcon />}>
                Add to cart
              </Button>
            </>
          </InfoWindow>}
      </Marker>
      {isShowDollar() &&
        <Marker
          key={props.nodeData.DataAuditId + "shadow"}
          icon={fetchMarkerIconDollarAmount()}
          position={{ lat: position.lat, lng: position.lng }}>
        </Marker>
      }
    </a> : <></>
  );
}
