import { FormControlLabel, IconButton, styled, Switch, SwitchProps, Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";
import MoveDownOutlinedIcon from '@mui/icons-material/MoveDownOutlined';
import './ExcludeIncludeOrderRenderer.css';

const IOSSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 35,
  height: 15,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(14px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 15,
    height: 15,
    margin: -2
  },
  '& .MuiSwitch-track': {
    width : 30,
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));


export default function ExcludeIncludeOrderRenderer(props: any) {
    const [rowIndex, setRowIndex] = useState(props.rowIndex);
    const [isDisabled, setIsDisabled] = useState(props.IsDisabled);
    const [checked, setChecked] = React.useState<boolean>(props.data?.IsIncludedInOrder === 'Y' ? true : false);

    useEffect(() => {
        if(props.value != null)
        {
            setChecked(props.value === 'Y');
        }
         
     }, [props.value]);

     useEffect(() => {
      if (props.IsDataTransfer && !isDisabled)
      {
          if(props.Column.toLowerCase().includes('isdatatransferred')){
              let isdatarecieved =  props.data?.IsDataRecieved == "Y";
              setIsDisabled(isdatarecieved);
          } else {
            let isdatarecieved =  props.data?.IsDataTransferred == "N" || props.data?.IsDataTransferred == null || props.data?.IsDataTransferred == undefined;
            setIsDisabled(isdatarecieved);
          }
      }
   }, [props.IsDataTransfer]);

    const valueChangeHandler = (event: any, newValue: any) => {
        setChecked(newValue);
        const stringValue = newValue ? 'Y' : 'N';
        props.onExcludeInclude(stringValue, props.data, false);
        props.node.setData({ ...props.node.data, isIncludedInOrderModified: true });
    }

    const applySameDecisionHandler = () => {
      const stringValue = checked ? 'Y' : 'N';
      props.onExcludeInclude(stringValue, props.data, true);
    }


    return (
      <> 
        { !props.node.footer && 
        <div className = "parent">
              <FormControlLabel control={<IOSSwitch checked={checked} onChange={valueChangeHandler} color="success" disabled = {isDisabled} />} label={props.label} />   
               { !isDisabled && <Tooltip title="Apply same below" style={{ margin: 2, float: 'right' }} className = "child">
                  <IconButton id="data" color="primary" size="small"
                      onClick={applySameDecisionHandler} aria-label="data">
                      <MoveDownOutlinedIcon fontSize="small" />
                  </IconButton>
              </Tooltip> }
        </div>
        }
    </>
    )
}