import * as React from 'react';
import Box from '@mui/material/Box';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faOilWell } from '@fortawesome/free-solid-svg-icons';
import { Avatar, Divider, List, ListItem, ListItemAvatar, ListItemIcon, ListItemText } from '@mui/material';
import "./WellsInfo.css";

export default function WellsInfo(props: any) {
  const [wells, setWells] = React.useState<any[]>([]);

  React.useEffect(() => {
    if (props.wellsInOrder) {
      if (props.wellsInOrder.length > 0 && props.selectedReport !== null) {
        const _wells = props.wellsInOrder.filter((element: any) => { return element[props.selectedReport] === 1 });
        setWells(_wells);
      }

      if (props.selectedReport == null) { // its for wells in which reports doesnt exist//
        const _wells = props.wellsInOrder;
        setWells(_wells);
      }
    }
  }, [props.wellsInOrder]);

  const generateWellsInfo = () => {
    return wells.map((value) =>
      <div >
        <ListItem>
          <ListItemIcon>
            <FontAwesomeIcon icon={faOilWell} type="regular" size="1x" />
          </ListItemIcon>
          <ListItemText style={{ fontSize: "9px !important" }}
            primary={value.WellName}
            secondary={value.API + " ( " + value.COUNTY + " )"}
          />
        </ListItem>
        <Divider variant="inset" component="li" />
      </div>
    );
  }

  return (
    <Box id="wellsInfo" sx={{ minWidth: 275 }}>
      <List dense={true}>
        {generateWellsInfo()}
      </List>
    </Box>
  );
}