import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import HistoryToggleOffIcon from '@mui/icons-material/HistoryToggleOff';
import { Box, DialogActions, DialogTitle } from '@mui/material';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Popper from '@mui/material/Popper';
import { AgGridReact } from 'ag-grid-react';
import * as React from "react";
import * as api from 'src/Utility/api';
import { ITableGridColumnDefinition } from "../../@State/types";

function HistoryViewRenderer(props: any) {
    const innerGridHistoryRef: any = React.useRef();
    const [open, setOpen] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const placement = 'bottom-start';
    const id = open ? 'history-popper' : undefined;
    const columnsToDisplay = ["Action", "UpdatedBy", "UpdatedDateTime", "AffectedColumns"];

    const defaultColDef = React.useMemo(() => {
        return {
            minWidth: 100,
            resizable: true,
            cellStyle: { textAlign: 'left' }
        };
    }, []);

    const [gridOptions] = React.useState<any>({
        rowData: [],
        columnDefs: [],
        defaultColDef: defaultColDef,
        pagination: false,
        rowSelection: 'single',
        getRowHeight: () => { return 25 }
    });

    const handleClickOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
        getRowHistoryHandler();
        setAnchorEl(event.currentTarget);
        setOpen(true);
    };

    const handleClickClose = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
        setOpen(false);
    };


    const getRowHistoryHandler = () => {
        async function retrieveHistory() {
            let getTableDataUrl = '/Table/GetTablesData?schema='
                + props.schemaName
                + '&table=' + props.tableName
                + '&historyView=True'
                + '&keyId=' + props.value;
            await api.API_GET(getTableDataUrl).then((response) => {
                props.setShowProgress(true);
                if (response?.data?.TableData.length > 0) {
                    const columns = Object.keys(response?.data?.TableData[0]);
                    setColumnDefinitions(columns)
                    setRowHistoryGrid(response?.data?.TableData);
                }
                props.setShowProgress(false);
            }).finally(() => {
                props.setShowProgress(false);
            });
        }
        retrieveHistory();
    }
    const setColumnDefinitions = (_columns: string[]) => {
        let columnDefinition: ITableGridColumnDefinition[] = [];
        columnsToDisplay.push(props.tablePrimaryKey);
        _columns.forEach((col) => {
            //if (columnsToDisplay.includes(col)) {
            if (col === 'AffectedColumns') {
                columnDefinition.push({
                    field: col,
                    type: 'columnStyle',
                    headerName: col,
                    width: (col.length * 35),
                    cellClass: ['ag-cell-custom'],
                    sortable: true,
                    filter: 'agTextColumnFilter',
                })
            } else {
                columnDefinition.push({
                    field: col,
                    type: 'columnStyle',
                    headerName: col,
                    width: (col.length * 20),
                    cellClass: ['ag-cell-custom'],
                    sortable: true,
                    filter: 'agTextColumnFilter',
                })
            }
            //}
        });
        if (typeof (innerGridHistoryRef?.current?.api) !== "undefined") {
            innerGridHistoryRef.current.api.setColumnDefs(columnDefinition);
        }
    }
    const setRowHistoryGrid = (_rowHistory: any) => {
        if (_rowHistory.length > 0) {
            if (typeof (innerGridHistoryRef?.current?.api) !== 'undefined') {
                if (_rowHistory.length > 0) {
                    innerGridHistoryRef.current.api.setRowData(_rowHistory);
                } else {
                    innerGridHistoryRef.current.api.setColumnDefs([]);
                    innerGridHistoryRef.current.api.setRowData([]);
                }
            }
        }
    }

    const columnTypes = React.useMemo(() => {
        return {
            columnStyle: {
                cellStyle: (params: any) => {
                    if (params.data.AffectedColumns !== undefined
                        && params.data.AffectedColumns.includes(params.column.colId)) {
                        return { fontWeight: 'bold', backgroundColor: 'lightgrey' };
                    }
                }
            },
        };
    }, []);


    return (
        <div>
            {props.IsSummary ? '' : props.value}
            <IconButton id="history" color="primary" size="small" onClick={handleClickOpen} aria-label="history" style={{ color: props.IsSummary ? 'white' : '#0091ea'  }} >
                <HistoryToggleOffIcon />
            </IconButton>
            <Popper id={id} open={open} placement={placement} anchorEl={anchorEl} style={{ zIndex: '10000' }}>
                <Box sx={{ border: '2px solid #bfbfbf', p: 1, bgcolor: 'background.paper' }}>
                    <DialogTitle>{props.tablePrimaryKey} - {props.value} - History</DialogTitle>
                    <Box sx={{ border: '1px solid #bfbfbf', p: 1, bgcolor: 'background.paper' }} style={{ height: '25vh', width: '100vh' }} className="ag-theme-material">
                        <AgGridReact
                            ref={innerGridHistoryRef}
                            columnHoverHighlight={true}
                            rowSelection = {'multiple'}
                            columnTypes={columnTypes}
                            gridOptions={gridOptions}>
                        </AgGridReact>
                    </Box>
                    <DialogActions>
                        <Button id="historyclosebtn"
                            variant="outlined"
                            color="error"
                            startIcon={<CancelOutlinedIcon />}
                            onClick={handleClickClose}>
                            CLOSE</Button>
                    </DialogActions>
                </Box>
            </Popper>
        </div>
    )
}

export default HistoryViewRenderer;