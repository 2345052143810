import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { Chip, Typography } from '@mui/material';
import { convertUTCtoCDT, currencyFormatter } from 'src/Utility/common';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

export default function Balances(props: any) {

    const [operatorProfile, setOperatorProfile] = React.useState<any>({});


    React.useEffect(() => {
        if (props.securityProfile.length > 0) {
            let operators = props.securityProfile[0].Operators;
            if(operators.length > 0) {
                setOperatorProfile(operators[0]);
            }
        }
    }, [props.securityProfile]);

    return (
        <div id='balances'>
            <Box>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Item sx={{ borderRadius: '20px' }}>
                            <Typography variant="button" gutterBottom >
                                Subscription Term  <Chip sx={{ backgroundColor: '#7eaedd2e' }} label={operatorProfile?.BalanceModel?.SubscriptionTerm} variant="outlined" />
                            </Typography>
                        </Item>
                    </Grid>
                    <Grid item xs={6}>
                        <Item sx={{ borderRadius: '20px' }}>
                            <Typography variant="button" gutterBottom >
                                Start Date  <Chip sx={{ backgroundColor: '#7eaedd2e' }} label={convertUTCtoCDT(operatorProfile?.BalanceModel?.SubscriptionStart + "Z")} variant="outlined" />
                            </Typography>
                        </Item>
                    </Grid>
                    <Grid item xs={6}>
                        <Item sx={{ borderRadius: '20px' }}>
                            <Typography variant="button" display="block" gutterBottom >
                                End Date  <Chip sx={{ backgroundColor: '#7eaedd2e' }} label={convertUTCtoCDT(operatorProfile?.BalanceModel?.SubscriptionEnd + "Z")} variant="outlined" />
                            </Typography>
                        </Item>
                    </Grid>
                    <Grid item xs={6}>
                        <Item sx={{ borderRadius: '20px' }}>
                            <Typography variant="button" display="block" gutterBottom >
                                Purchase
                            </Typography>
                            <Typography variant="button" display="block" gutterBottom >
                                Income  <Chip sx={{ backgroundColor: '#7eaedd2e' }} label={currencyFormatter(operatorProfile?.BalanceModel?.PurchaseRevenue?.Income)} variant="outlined" />
                            </Typography>
                            <Typography variant="button" display="block" gutterBottom >
                                Expense  <Chip sx={{ backgroundColor: '#7eaedd2e' }} label={currencyFormatter(operatorProfile?.BalanceModel?.PurchaseRevenue?.Expense)} variant="outlined" />
                            </Typography>
                        </Item>
                    </Grid>

                    <Grid item xs={6}>
                        <Item sx={{ borderRadius: '20px' }}>
                            <Typography variant="button" display="block" gutterBottom >
                                Trade
                            </Typography>
                            <Typography variant="button" display="block" gutterBottom >
                                Income  <Chip sx={{ backgroundColor: '#7eaedd2e' }} label={currencyFormatter(operatorProfile?.BalanceModel?.RentalRevenue?.Income)} variant="outlined" />
                            </Typography>
                            <Typography variant="button" display="block" gutterBottom >
                                Expense  <Chip sx={{ backgroundColor: '#7eaedd2e' }} label={currencyFormatter(operatorProfile?.BalanceModel?.RentalRevenue?.Expense)} variant="outlined" />
                            </Typography>
                        </Item>
                    </Grid>

                 
                </Grid>
            </Box>
        </div>
    );
}