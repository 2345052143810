import { IconButton, TextField, Tooltip } from '@mui/material';
import React, { useState } from 'react';
import { useEffectOnce } from 'src/hooks/useEffectOnce';
import MoveDownOutlinedIcon from '@mui/icons-material/MoveDownOutlined';

import './ApproveDropdownRenderer.css';
import ConfirmDialog from 'src/Common/ConfirmDialog';

function ApproveDropDownCellEditor(props: any) {
    const [option, setOption] = useState(props.value);
    const [DropDownOptions, setDropDownOptions] = useState([]);
    const [rowIndex, setRowIndex] = useState(props.rowIndex);
    const [openSaveConfirmDialog, setOpenSaveConfirmDialog] = React.useState(false);
    const [confirmDialogObject, setConfirmDialogObject] = React.useState<any>({
        title: "",
        body: "",
        okHandler: () => undefined
    });

    const [isOptionSet, setIsOptionSet] = React.useState(false);


    useEffectOnce(() => {
        setDropDownOptions(JSON.parse(props.DropDownOptionsJSON));
    });

    const onOptionChange = (event: any) => {
        let option = event.target.value;
        if (option?.toUpperCase() == 'ACCEPT' && props.IsRentalOrPurchaseOrder){
            setIsOptionSet(false);
            let confirmDialogObject = {
                title: "Estimates",
                body: "This will Override the proposed value from other party Proposed value . Do you Wish to Continue?, else click CANCEL",
                okHandler: () => { saveEditedDataHandler(option, false) }
            };

            setConfirmDialogObject(confirmDialogObject);
            setOpenSaveConfirmDialog(true);
            setOption(option);
        } else {
            props.onDropDownValueSelected(event.target.value, props.data, false, false);
            setOption(event.target.value);
        }
    }

    const saveEditedDataHandler = (opt : any, applySame : boolean) => {
        setIsOptionSet(true);
        props.onDropDownValueSelected(opt, props.data, applySame, true);
        setOption(opt);
    }

    const applySameDecisionHandler = () => {
        if(option != null && option != '') {
            if (option.toUpperCase() == 'ACCEPT' && props.IsRentalOrPurchaseOrder){
                let confirmDialogObject = {
                    title: "Estimates",
                    body: "This will Override the proposed value from other party Proposed value . Do you Wish to Continue?, else click CANCEL",
                    okHandler: () => { saveEditedDataHandler(option, true) }
                };
    
                setConfirmDialogObject(confirmDialogObject);
                setOpenSaveConfirmDialog(true);
            } else {
                props.onDropDownValueSelected(option, props.data, true, false);
            }
        }
    }

    const onCancelHandler = () => {
        setOpenSaveConfirmDialog(false);
        if(!isOptionSet) {
            setOption("");
        }
    }

    const getByKey = (arr: any[], key: any) => (arr.find(x => Object.keys(x)[0] === key) || {})[key]

    return (
        <>
            <div style={{ display: "flex", alignItems: "flex-start" }}  className = "parent" > 
                <TextField
                    select size="small"
                    SelectProps={{
                        native: true,
                    }}
                    style={{ width: "100%", margin: '0px' }}
                    value={option} onChange={onOptionChange}>
                    {DropDownOptions
                        && DropDownOptions.map((key: any, index: number) => {
                            return <option
                                value={key}
                                key={index + "" + key}>{key}</option>
                        })}
                </TextField>
                <Tooltip title="Apply same below" style={{ margin: 2, float: 'right' }} className = "child" >
                    <IconButton id="data" color="primary" size="small"
                        onClick={applySameDecisionHandler} aria-label="data">
                        <MoveDownOutlinedIcon fontSize="small" />
                    </IconButton>
                </Tooltip>
            </div>

            <ConfirmDialog
                open={openSaveConfirmDialog}
                setOpenConfirmDialogHandler={onCancelHandler}
                title={confirmDialogObject.title}
                body={confirmDialogObject.body}
                okHandler={confirmDialogObject.okHandler}
            ></ConfirmDialog>
        </>
        
    )
}

export default ApproveDropDownCellEditor;