import React, { useEffect } from "react";
import { GoogleMap, useLoadScript } from "@react-google-maps/api";

import './Map.css';

const defaultCenter = { lat: 39.8097343, lng: -98.5556199 };
const libraries:any[] = ["drawing", "places"];

export default function Map(props: any) {
  
  const { setMap, children } = props;
  const [isClusteringClicked, setIsClusteringClicked] = React.useState<boolean>(false);
  const [isWellIconNeededClicked, setIsWellIconNeededClicked] = React.useState<boolean>(false);
  const [isRefreshClicked, setIsRefreshClicked] = React.useState<boolean>(false);
  const [isWholeMapRefreshClicked, setIsWholeMapRefreshClicked] = React.useState<boolean>(false);
  const [isNodePopUpClicked, setIsNodePopUpClicked] = React.useState<boolean>(false);

  const [options, setOptions] = React.useState<google.maps.MapOptions>({
    disableDefaultUI: true,
    scaleControl: true,
    mapTypeControl: true,
    mapTypeId: "roadmap",
    zoomControl: true,
    fullscreenControl: true
  });
  const { isLoaded, loadError } = useLoadScript({
    libraries,
    googleMapsApiKey: "AIzaSyC66JypgOggWj7gJ9S5gfWwR12WaMB0aug" // ,
    // ...otherOptions
  });

  useEffect(() => {
    if (isClusteringClicked == true) {
      props.setIsClusteringEnabled(!props.isClusteringEnabled);
      setIsClusteringClicked(false);
    }
  }, [isClusteringClicked]);


  useEffect(() => {
    if (isWellIconNeededClicked == true) {
      props.setIsMapIconEnabled(!props.isMapIconEnabled);
      setIsWellIconNeededClicked(false);
    }
  }, [isWellIconNeededClicked]);

  useEffect(() => {
    if (isWholeMapRefreshClicked == true) {
      props.resetMapHandler();
      setIsWholeMapRefreshClicked(false);
    }
  }, [isWholeMapRefreshClicked]);


  useEffect(() => {
    if (isNodePopUpClicked == true) {
      props.setIsNodeInfoEnabled(!props.isNodeInfoEnabled);
      setIsNodePopUpClicked(false);
    }
  }, [isNodePopUpClicked])


  useEffect(() => {
    if (isRefreshClicked == true) {
      if (props.isClusteringEnabled === false) {
        props.setIsClusteringEnabled(!props.isClusteringEnabled);
      }
      if (props.isMapIconEnabled === false) {
        props.setIsMapIconEnabled(!props.isMapIconEnabled);
      }
      if (props.polygonInfo !== null) {
        props.polygonInfo?.setMap(null);
        props.setPolygonInfo(null);
      }
      if (props.circleInfo !== null) {
        props.circleInfo?.setMap(null);
        props.setCircleInfo(null);
      }
      if (props.reactangleInfo !== null) {
        props.reactangleInfo?.setMap(null);
        props.setReactangleInfo(null);
      }
      setIsRefreshClicked(false);
    }
  }, [isRefreshClicked]);

  const insertNodePopUpButton = (map: any) => {
    let controlDiv = document.createElement('div');
    let button = document.createElement('Button');
    let active = false;
    button.title = "Node popup (On/Off)";
    button.innerHTML = "Node popup";
    button.setAttribute("style", "background-color:white; font-size:1.4em; border: 1px gray; margin-top:5px; margin-left:5px; height:26px; cursor:pointer");
    button.onclick = function () {
      active = !active;
      active ? button.setAttribute("style", "background-color:white; font-weight:bold; font-size:1.4em; border: 1px gray; margin-top:5px; margin-left:5px; height:26px; cursor:pointer")
        :
        button.setAttribute("style", "background-color:white; font-size:1.4em; border: 1px gray; margin-top:5px; margin-left:5px; height:26px; cursor:pointer");
      setIsNodePopUpClicked(true);
    };
    controlDiv.appendChild(button);
    map.controls[google.maps.ControlPosition.TOP_RIGHT].push(controlDiv);
  }

  const insertRefreshButton = (map: any) => {
    let controlDiv = document.createElement('div');
    let button = document.createElement('Button');
    button.title = "Erase Drawing";
    button.innerHTML = "Erase";
    button.setAttribute("style", "background-color:white; font-size:1.4em; border: 1px gray; margin-top:5px; margin-left:5px; height:26px; cursor:pointer");
    button.onclick = function () {
      setIsRefreshClicked(true);
    };
    controlDiv.appendChild(button);
    map.controls[google.maps.ControlPosition.TOP_RIGHT].push(controlDiv);
  }

  const insertClusteringButton = (map: any) => {
    let controlDiv = document.createElement('div');
    let button = document.createElement('Button');
    let active = true;
    button.title = "Cluster (On/Off)";
    button.innerHTML = "Cluster";
    button.setAttribute("style", "background-color:white; font-weight:bold; font-size:1.4em; border: 1px gray; margin-top:5px; margin-left:5px; height:26px; cursor:pointer");
    button.onclick = () => {
      active = !active;
      active ? button.setAttribute("style", "background-color:white; font-weight:bold; font-size:1.4em; border: 1px gray; margin-top:5px; margin-left:5px; height:26px; cursor:pointer")
        :
        button.setAttribute("style", "background-color:white; font-size:1.4em; border: 1px gray; margin-top:5px; margin-left:5px; height:26px; cursor:pointer");

      setIsClusteringClicked(true);
    };
    controlDiv.appendChild(button);

    map.controls[google.maps.ControlPosition.TOP_RIGHT].push(controlDiv);
  }

  const insertWellIconButton = (map: any) => {
    let controlDiv = document.createElement('div');
    let button = document.createElement('Button');
    let active = true;
    button.title = "Icon (On/Off)";
    button.innerHTML = "Icon";
    button.setAttribute("style", "background-color:white; font-weight:bold; font-size:1.4em; border: 1px gray; margin-top:5px; margin-left:5px; height:26px; cursor:pointer");
    button.onclick = () => {
      active = !active;
      active ? button.setAttribute("style", "background-color:white; font-weight:bold; font-size:1.4em; border: 1px gray; margin-top:5px; margin-left:5px; height:26px; cursor:pointer")
        :
        button.setAttribute("style", "background-color:white; font-size:1.4em; border: 1px gray; margin-top:5px; margin-left:5px; height:26px; cursor:pointer");

      setIsWellIconNeededClicked(true);
    };
    controlDiv.appendChild(button);
    map.controls[google.maps.ControlPosition.TOP_RIGHT].push(controlDiv);
  }

  const insertMapRefreshButton = (map: any) => {
    let controlDiv = document.createElement('div');
    let button = document.createElement('Button');
    button.title = "Reset";
    button.innerHTML = "Reset";
    button.setAttribute("style", "background-color:white; font-size:1.4em; border: 1px gray; margin-top:5px; margin-left:5px; height:26px; cursor:pointer");
    button.onclick = () => {
      setIsWholeMapRefreshClicked(true);

      map.controls[google.maps.ControlPosition.TOP_RIGHT].clear();

      insertRefreshButton(map);
      insertClusteringButton(map);
      insertWellIconButton(map);
      insertMapRefreshButton(map);
      insertNodePopUpButton(map);
    };
    controlDiv.appendChild(button);
    map.controls[google.maps.ControlPosition.TOP_RIGHT].push(controlDiv);
  }


  const renderMap = (props: any) => {
    const loadHandler = (map: any) => {
      props.setMap(map);
      props.setInfoWindow(new google.maps.InfoWindow());
      renderCustomControls(map);
    };

    const renderCustomControls = (map: any) => {
      map.controls[google.maps.ControlPosition.TOP_RIGHT].clear();

      insertRefreshButton(map);
      insertClusteringButton(map);
      insertWellIconButton(map);
      insertMapRefreshButton(map);
      insertNodePopUpButton(map);
    }

    return (
      <GoogleMap
        id="circle-example"
        mapContainerStyle={{
          height: "82vh",
          width: "100%"
        }}
        zoom={5}

        center={defaultCenter}
        options={options}
        onLoad={loadHandler}
      >
        {children}
      </GoogleMap>
    );
  };

  if (loadError) {
    return <div>Map cannot be loaded right now, sorry.</div>;
  }

  return isLoaded ? renderMap(props) : <div>Loading...</div>;
}
