import LanguageOutlinedIcon from '@mui/icons-material/LanguageOutlined';
import { IconButton } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useEffectOnce } from 'src/hooks/useEffectOnce';
import { AppContext } from '../../@State/context/AppContext';
import { ExchangeOrderType, TradeOrderFromTo } from '../../@State/types';

function MapViewRenderer(props: any) {
    const [orderDataWells, setOrderDataWells] = useState<any[]>([]);
    const [exchangeOrderOtherSideWells, setExchangeOrderOtherSideWells] = useState<any[]>([]);
    const [OrderFromTo, setOrderFromTo] = useState<TradeOrderFromTo>({
        OrderId: "",
        OrderBy: "",
        OrderTo: ""
    });
    const [oppositeOrderFromTo, setOppositeOrderFromTo] = useState<TradeOrderFromTo>({
        OrderId: "",
        OrderBy: "",
        OrderTo: ""
    });
    const [orderSelectedReports, setSelectedOrderReports] = useState<any[]>([]);

    const {
        oppositeTradeWells,
        setIsMapViewDollar,
        setTradeOrderFromTo,
        setOppositeTradeOrderFromTo,
        setOrderWells,
        setOppositeTradeWells,
        setOrderReports } = useContext(AppContext) as ExchangeOrderType;

    useEffect(() => {
        if (props?.data?.Data && props?.data?.Data.length > 0) {
            if (typeof props?.data?.Data === 'string' || props?.data?.Data instanceof String) {
                //cleanUp();
                const _oppositeSideWells = setOppositeSideWellsHandler();
                const _orderWells = JSON.parse(props.data.Data);
                if (typeof _orderWells !== 'string' && _orderWells.length > 0) {
                    _orderWells[0]["orderId"] = props.data.OrderId;
                    _orderWells[0].selectedOperator = props.selectedOperator;
                    setOrderDataWells(_orderWells);
                    setSelectedOrderReports((_orderReports: any[]) => {
                        return [..._orderReports, ...props.data.SelectedReports?.split(",")]
                    });
                    addOppositeSideWells(_orderWells, _oppositeSideWells);
                }
            } else {
                const _oppositeSideWells = setOppositeSideWellsHandler();
                const _orderWells = props.data.Data;
                if (typeof _orderWells !== 'string') {
                    _orderWells[0].orderId = props.data.OrderId;
                    _orderWells[0].selectedOperator = props.selectedOperator;
                    setOrderDataWells(_orderWells);
                    setSelectedOrderReports((_orderReports: any[]) => {
                        return [_orderReports, ...props.data.SelectedReports?.split(",")]
                    });
                    addOppositeSideWells(_orderWells, _oppositeSideWells);
                }
            }

            const tradeOrderFromTo: TradeOrderFromTo = {
                OrderId: props.data.OrderId,
                OrderBy: props.data.RequestedBy,
                OrderTo: props.data.RequestedTo
            }
            setOrderFromTo(tradeOrderFromTo);
        }
    }, [props?.data]);

    const cleanUp = () => {
        setOrderFromTo({
            OrderId: "",
            OrderBy: "",
            OrderTo: ""
        });
        setOppositeOrderFromTo({
            OrderId: "",
            OrderBy: "",
            OrderTo: ""
        });
    }

    const setOppositeSideWellsHandler = () => { //This is just for view purpose , to also show the opposite side wells when its a trade //
        let _orderOpppositeSideWells: any[] = [];
        if (props.data.TradeGroupId?.toString().toLowerCase() !== 'n/a'
            && props.data.TradeGroupId?.toString().toLowerCase() !== ''
            && props.data.TradeGroupId?.toString().toLowerCase() !== undefined
            && props.groupedOrders?.length > 0) {
            const _oppositeSideOrder = props.groupedOrders
                .filter((element: any) => {
                    return element.TradeGroupId === props.data.TradeGroupId
                        && element.OrderId !== props.data.OrderId
                });
            if (_oppositeSideOrder.length === 1) {

                if (typeof _oppositeSideOrder[0]?.Data === 'string'
                    || _oppositeSideOrder[0]?.Data instanceof String) {
                    _orderOpppositeSideWells = JSON.parse(_oppositeSideOrder[0]?.Data);
                } else {
                    _orderOpppositeSideWells = _oppositeSideOrder[0]?.Data;
                }

                //Setting order and opposite side order from and to//
                const oppositeSideTradeOrderFromTo: TradeOrderFromTo = {
                    OrderId: _oppositeSideOrder[0].OrderId,
                    OrderBy: _oppositeSideOrder[0].RequestedBy,
                    OrderTo: _oppositeSideOrder[0].RequestedTo
                }
                setOppositeOrderFromTo(oppositeSideTradeOrderFromTo);
                setSelectedOrderReports((_orderReports: any[]) => {
                    return [..._orderReports, ..._oppositeSideOrder[0].SelectedReports?.split(",")]
                });
                //---------------------------------------//

                const wellApis = _orderOpppositeSideWells.map((element: any) => { return element?.API });
                setExchangeOrderOtherSideWells(wellApis);
            }
        }

        return _orderOpppositeSideWells;
    }

    const addOppositeSideWells = (_orderWells: any[], _oppositeSideWells: any[]) => {
        if (_oppositeSideWells.length > 0) {
            const _currentOrderDataWells = [..._orderWells];
            _oppositeSideWells.map((wellInfo: any) => {
                _currentOrderDataWells.push(wellInfo);
            });
            setOrderDataWells(_currentOrderDataWells);
        }
    }

    const setOrderWellsHandler = () => {
        if (orderDataWells.length > 0) {
            setOppositeTradeWells(exchangeOrderOtherSideWells);
            setOrderWells(orderDataWells);
            setTradeOrderFromTo(OrderFromTo);
            setOppositeTradeOrderFromTo(oppositeOrderFromTo);
            setIsMapViewDollar(props.isGreenButton ? true : false);
            setOrderReports(orderSelectedReports);
            if (props.summaryCloseHandler !== undefined) {
                props.summaryCloseHandler();
            };
            props.setTabIndex(0);
        }
    }

    return (
        <>
            {props.isGreenButton &&
                <IconButton
                    id="mapview"
                    color="success"
                    aria-label="Map View"
                    component="label"
                    style={{ backgroundColor: "rgb(239 238 238)", marginRight: '5px',padding: '2px'  }}
                    onClick={setOrderWellsHandler}>
                    <LanguageOutlinedIcon />
                </IconButton>
            }

            {!props.isGreenButton &&
                <IconButton
                    id="data"
                    sx={{ padding: "0px" }}
                    color="primary"
                    size="small"
                    onClick={setOrderWellsHandler}
                    aria-label="data">
                    <LanguageOutlinedIcon />
                </IconButton>
            }
        </>
    )
}

export default MapViewRenderer;