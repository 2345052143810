import * as React from 'react';
import * as api from 'src/Utility/api';
import {
    TableType,
    ITableGridRowDefinition,
    ITableGridColumnDefinition,
    IDropDownSelectList,
    ITableData
} from '../types';

export const TableContext = React.createContext<TableType | null>(null);

interface BaseLayoutProps {
    children?: React.ReactNode;
}

const TableProvider: React.FC<BaseLayoutProps> = ({ children }) => {
    const [tableInfo, setTableInfo] = React.useState<ITableData[]>([]);
    const [rows, setRowDefinition] = React.useState<ITableGridRowDefinition[]>([]);
    const [columns, setColumnsDefinition] = React.useState<ITableGridColumnDefinition[]>([]);
    const [pickListDefinitions, setPickListDefinitions] = React.useState<any[]>();
    const [fileUploadColumns, setfileUploadColumns] = React.useState<any[]>();
    const [approvalConfig, SetApprovalConfig] = React.useState<any[]>();
    const [approvalStatus, SetApprovalStatus] = React.useState<any[]>();
    const [operators, SetOperators] = React.useState<any[]>();
    const [reportGroups, SetReportGroups] = React.useState<any[]>([]);
    
    const [reportConfigurations, setReportConfigurations] = React.useState<any[]>();
    const [dropDownSelectListCollection, setDropDownSelectListCollection] = React.useState<IDropDownSelectList[]>([]);

    const saveTableInfo = (_tableData: ITableData) => {
        const _tableInfo = tableInfo.filter((element) => {
            return element.schema === _tableData.schema && element.tableName === _tableData.tableName
        });

        let _existingtableData = [...tableInfo];
        if (_tableInfo.length > 0) {
            _existingtableData.splice(_existingtableData.indexOf(_tableInfo[0]), 1);
        }
        _existingtableData.push(_tableData);
        setTableInfo(_existingtableData);
    };

    const clearTableInfo = () => {
        setTableInfo([]);
    };


    const saveRowDefinition = (rows: ITableGridRowDefinition[]) => {
        setRowDefinition([...rows]);
    };

    const saveColumnDefinition = (columnDefinition: ITableGridColumnDefinition[]) => {
        setColumnsDefinition([...columnDefinition]);
    };

    const saveDropDownSelectList = (_dropDownSelectList: IDropDownSelectList) => {
        const _DropDownSelect = dropDownSelectListCollection.filter((element) => {
            return element.schema === _dropDownSelectList.schema && element.tableName === _dropDownSelectList.tableName && element.colId === _dropDownSelectList.colId;
        });

        let _existingDropDownSelectListCollection = [...dropDownSelectListCollection];
        if (_DropDownSelect.length > 0) {
            _existingDropDownSelectListCollection.splice(_existingDropDownSelectListCollection.indexOf(_DropDownSelect[0]), 1);
        }
        _existingDropDownSelectListCollection.push(_dropDownSelectList);
        setDropDownSelectListCollection(_existingDropDownSelectListCollection);
    }

    const clearDropDownSelectList = () => {
        setDropDownSelectListCollection([]);
    }

    const loadInitialPrerequisiteData = () => {
        async function fetchPicklistDefinitions() {
            await api.API_GET('/Table/GetTablesData?schema=settings&table=PICKLIST_DEFINITIONS')
                .then((response) => {
                    setPickListDefinitions(response.data.TableData);
                });
        };
        async function fetchFileUploadColumns() {
            await api.API_GET('/Table/GetTablesData?schema=settings&table=FILEUPLOAD_COLUMNS')
                .then((response) => {
                    setfileUploadColumns(response.data.TableData);
                });
        };
        async function fetchApprovalConfig() {
            await api.API_GET('/Table/GetTablesData?schema=settings&table=APPROVAL_CONFIG')
                .then((response) => {
                    SetApprovalConfig(response.data.TableData);
                });
        };
        async function fetchApprovalStatus() {
            await api.API_GET('/Table/GetTablesData?schema=settings&table=APPROVAL_STATUS')
                .then((response) => {
                    SetApprovalStatus(response.data.TableData);
                });
        };
        async function fetchOperators() {
            await api.API_GET('/Table/GetTablesData?schema=settings&table=OPERATOR')
                .then((response) => {
                    SetOperators(response.data.TableData);
                });
        };
        
        async function fetchReportGroups() {
            await api.API_GET('/Report/GetReportConfiguration')
                .then((response) => {
                    setReportConfigurations(response.data);
                });
        };

        fetchPicklistDefinitions();
        fetchFileUploadColumns();
        fetchApprovalConfig();
        fetchApprovalStatus();
        fetchOperators();
        fetchReportGroups();
    };

    return <TableContext.Provider
        value={{
            tableInfo,
            rows,
            columns,
            pickListDefinitions,
            fileUploadColumns,
            approvalConfig,
            approvalStatus,
            operators,
            reportGroups,
            dropDownSelectListCollection,
            reportConfigurations,
            saveRowDefinition,
            saveColumnDefinition,
            loadInitialPrerequisiteData,
            saveDropDownSelectList,
            saveTableInfo,
            clearDropDownSelectList,
            clearTableInfo
        }}>
        {children}</TableContext.Provider>;
};

export default TableProvider;