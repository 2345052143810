import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';
import DoNotTouchOutlinedIcon from '@mui/icons-material/DoNotTouchOutlined';
import PendingOutlinedIcon from '@mui/icons-material/PendingOutlined';
import TaskAltOutlinedIcon from '@mui/icons-material/TaskAltOutlined';
import ChecklistRtlIcon from '@mui/icons-material/ChecklistRtl';
import { Box, Button, Checkbox, DialogActions, DialogContent, DialogTitle, Fade, FormControl, FormControlLabel, Popper, TextField, Tooltip } from "@mui/material";
import React, { useState } from "react";
import { useEffectOnce } from 'src/hooks/useEffectOnce';
import * as api from 'src/Utility/api';
import ConfirmDialog from 'src/Common/ConfirmDialog';
import { Rnd } from 'react-rnd';

function OrderApproveRenderer(props: any) {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [disableButton, setDisableButton] = useState<boolean>(true);
    const [open, setOpen] = useState(false);
    const [comment, setComment] = useState<string>();
    const [status, setStatus] = useState<number>(1);
    const [statusLabel, setStatusLabel] = useState<string>('Approved');
    const [approver, setApprover] = useState<string>('N/A');
    const [approverDisplay, setApproverDisplay] = useState<string>('');
    const [approverLevel, setApproverLevel] = useState<number>();
    const [readOnly, setReadOnly] = React.useState<boolean>(false);
    const canBeOpen = open && Boolean(anchorEl);
    const [checked, setChecked] = React.useState(false);
    const [isSelectAll,setIsSelectAll] = React.useState(false);
    const [openSaveConfirmDialog, setOpenSaveConfirmDialog] = React.useState(false);
    const [confirmDialogObject, setConfirmDialogObject] = React.useState<any>({
        title: "",
        body: "",
        okHandler: () => undefined
    });

    const displayStatus = props.value ? props.value : 'N/A'
    const id = canBeOpen ? 'transition-popper' : undefined;

    const statusList = [
        {
            value: 1,
            label: 'Approved',
        },
        {
            value: 2,
            label: 'Pending',
        },
        {
            value: 3,
            label: 'Closed',
        },
        {
            value: 4,
            label: 'Rejected',
        },
        {
            value: 5,
            label: 'On Hold',
        },
        {
            value: 6,
            label: 'Defaulted',
        },
        {
            value: 7,
            label: 'Withdrawn',
        }
    ];

    const handleClickOpen = (event: React.MouseEvent<HTMLElement>) => {
        if (props.node.selected === false) {
            props.node.setSelected(true);
        }

        // Set Status open selection to current value //
        const statusObj = statusList.filter((ele:any)=>{return ele.label === props.value});

        if(statusObj){
            setStatus(statusObj[0].value);
        }
        
        setAnchorEl(event.currentTarget);
        setOpen(true);
    };

    useEffectOnce(() => {
        let _approver: string = "";
        if (props.dataRequestor) {
            setReadOnly(props.workflowType === 'E');
        }
        if (props.data) {
            switch (props.column.colId) {
                case 'ApprovalStatus1':
                    if (props.data['Approver1']) {
                        _approver = props.data['Approver1'];
                        setApprover(_approver);
                        setApproverDisplay(_approver.substring(0, _approver.indexOf('@')).substring(0, 15));
                        setDisableButton(props.loggedInUserMailId !== props.data['Approver1'] ||( props.value == 'Approved' || props.value == 'Defaulted'));
                        setApproverLevel(1);
                    }
                    break;
                case 'ApprovalStatus2':
                    if (props.data['Approver2']) {
                        _approver = props.data['Approver2'];
                        setApprover(_approver);
                        setApproverDisplay(_approver.substring(0, _approver.indexOf('@')).substring(0, 15));
                        setApproverLevel(2);
                        setDisableButton(props.loggedInUserMailId !== props.data['Approver2'] ||( props.value == 'Approved' || props.value == 'Defaulted'));
                    }
                    break;
                case 'ApprovalStatus3':
                    if (props.data['Approver3']) {
                        _approver = props.data['Approver3'];
                        setApprover(_approver);
                        setApproverDisplay(_approver.substring(0, _approver.indexOf('@')).substring(0, 15));
                        setApproverLevel(3);
                        setDisableButton(props.loggedInUserMailId !== props.data['Approver3'] ||( props.value == 'Approved' || props.value == 'Defaulted'));
                    }
                    break;
                default:
                    break;
            }
        }
    });

    const getButtonIconHandler = () => {
        switch (props.value) {
            case 'Defaulted': case 'Approved': return <CheckCircleOutlineOutlinedIcon color='success' />;
            case 'Pending': return <PendingOutlinedIcon color='warning' />;
            case 'Closed': return <TaskAltOutlinedIcon color='success' />;
            case 'Rejected': return <CancelOutlinedIcon color='error' />;
            case 'On Hold': return <DoNotTouchOutlinedIcon color='warning' />;
            default:
                break;
        }
    }

    const submitHandler = () => {
        let selectedWorkflows: any[] = props.getSelectedWorkflows();
        selectedWorkflows = selectedWorkflows.filter((item) => { return item['Approver' + approverLevel] === props.loggedInUserMailId })
        let updatedWorkflows: any[] = [];
        let isRecordsInValid : boolean = false;

        if (checked) {
            if (selectedWorkflows.length > 0) {
                selectedWorkflows.forEach((workflow) => {
                    if(props.dataRequestor && (workflow.OrderOwnerProposedValue == null || workflow.OrderOwnerProposedValue == 0)){
                        isRecordsInValid = true;
                    } else if(!props.dataRequestor && (workflow.DataOwnerProposedValue == null || workflow.DataOwnerProposedValue == 0)) {
                        isRecordsInValid = true;
                    }

                    let updateWorkflow: any = {
                        OrderId: props.orderId,
                        ReportName: workflow.ReportName,
                        Available: workflow.Available,
                        ApprovalLevel: approverLevel!,
                        StatusId: status,
                        Comment: comment!,
                        ModifiedBy: props.loggedInUser,
                        workflowType: props.workflowType
                    }
                    updatedWorkflows.push(updateWorkflow);
                });
            } else {
                if(props.dataRequestor && (props.data.OrderOwnerProposedValue == null || props.data.OrderOwnerProposedValue == 0)){
                    isRecordsInValid = true;
                } else if(!props.dataRequestor && (props.data.DataOwnerProposedValue == null || props.data.DataOwnerProposedValue == 0)) {
                    isRecordsInValid = true;
                }

                let updateWorkflow: any = {
                    OrderId: props.orderId,
                    ReportName: props.data.ReportName,
                    Available: props.data.Available,
                    ApprovalLevel: approverLevel!,
                    StatusId: status,
                    Comment: comment!,
                    ModifiedBy: props.loggedInUser,
                    workflowType: props.workflowType
                }
                updatedWorkflows.push(updateWorkflow);
            }
        } else {
            if(props.dataRequestor && (props.data.OrderOwnerProposedValue == null || props.data.OrderOwnerProposedValue == 0)){
                isRecordsInValid = true;
            } else if(!props.dataRequestor && (props.data.DataOwnerProposedValue == null || props.data.DataOwnerProposedValue == 0)) {
                isRecordsInValid = true;
            }

            let updateWorkflow: any = {
                OrderId: props.orderId,
                ReportName: props.data.ReportName,
                Available: props.data.Available,
                ApprovalLevel: approverLevel!,
                StatusId: status,
                Comment: comment!,
                ModifiedBy: props.loggedInUser,
                workflowType: props.workflowType
            }
            updatedWorkflows.push(updateWorkflow);
        }

        if (isRecordsInValid){
            let confirmDialogObject = {
                title: "Warning",
                body: "Proposed Value are not defined for certain records(Marked in red). Click Yes if you still wish to approve",
                okHandler: () => { updateWorkflow(updatedWorkflows); }
            };

            setConfirmDialogObject(confirmDialogObject);
            setOpenSaveConfirmDialog(true);
        }
        else {
            if (updatedWorkflows.length > 0)
            updateWorkflow(updatedWorkflows);
        }
    }

    async function updateWorkflow(updatedWorkflows: any[]) {
        props.setLoading(true);
        await api.API_POST('/OrderManagement/updateOrderWorkflow', {
            workflowDetails: updatedWorkflows,
            OrderId : props.orderId,
            Operator : props.operator,
            Environment : api.env,
            UserName : props.loggedInUser
        }).then((response) => {
            setOpen(false);
            props.refresh(props.workflowType);
            props.setLoading(false);
            props.formMessage('Order ' + statusLabel?.toLowerCase() + ' successfully', 'success');
        }).catch(() => {
            setOpen(false);
            props.setLoading(false);
            props.formMessage('Order update failed', 'error');
        });
    }

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setChecked(event.target.checked);
        selectAllHandler(); // Also select all 
    };

    const onCancelHandler = () => {
        setOpenSaveConfirmDialog(false);
    }

    const selectAllHandler = () => {
        setIsSelectAll(!isSelectAll);
       
        props.api.forEachNodeAfterFilter((node:any) => {
            node.setSelected(!isSelectAll);
        });
    }

    return (<>{props.data &&
        <div>

            {readOnly ?
                <span>
                    <Button id="orderapprovalbtn2" size="small" variant="outlined" startIcon={getButtonIconHandler()} disabled>
                        {displayStatus}
                    </Button>
                </span>
                :
                <Tooltip arrow placement="bottom-start"
                    title={
                        <React.Fragment>
                            <b>{'Approver Email: '}{approver}</b>
                        </React.Fragment>
                    }>
                    <span className={!disableButton ? "heart":"" }>
                        <Button id="orderapprovebtn3" size="small" variant="outlined" startIcon={getButtonIconHandler()} onClick={handleClickOpen} disabled={disableButton}>
                            {displayStatus}
                        </Button>
                        <span style={{ color: 'darkgrey' }}>{' ' + approverDisplay}</span>
                    </span>
                </Tooltip>
            }

        </div>
    }
        <Popper id={id} open={open} placement='bottom-start' anchorEl={anchorEl} style={{ zIndex: '10000' }}>
        <Rnd default={{
                    x: 0,
                    y: 0,
                    width: '100%',
                    height: '80vh'
                }}>
                    <Box sx={{ border: '1px solid #bfbfbf', p: 1, bgcolor: 'background.paper' }} style={{ height: 'auto', width: '60vh' }}>
                        <DialogTitle>Workflow action: </DialogTitle>
                        <DialogContent>
                            <FormControl>
                                <TextField
                                    id="orderStatus" name="OrderStatus" select label="Status"
                                    SelectProps={{
                                        native: true,
                                    }}
                                    value={status}
                                    onChange={(event) => {
                                        setStatus(Number(event.target.value));
                                        setStatusLabel(statusList.find((item) => item.value === Number(event.target.value))?.label!);
                                    }}>
                                    {statusList.map((option) => (
                                        <option key={option.value} value={option.value}>
                                            {option.label}
                                        </option>
                                    ))}
                                </TextField>
                            </FormControl>
                            <br /><br />
                            <FormControl fullWidth>
                                <TextField id="Comments" name="Comments" label="Comments" multiline fullWidth
                                    value={comment} rows={4} maxRows={4}
                                    onChange={(value) => { setComment(value.target.value) }}>
                                </TextField>
                            </FormControl>
                            <FormControlLabel control={<Checkbox checked={checked} onChange={handleChange} />}
                                label="Apply the same decision for all datasets where I am the approver." />
                        </DialogContent>
                        <DialogActions>
                            {/* <Button variant="outlined" color="success" startIcon={<ChecklistRtlIcon />}
                                onClick={selectAllHandler}>{isSelectAll ? "Deselect All" : "Select All"}</Button> */}
                            <Button id="OASubmit" type="submit" variant="outlined" color="success" startIcon={<DoneOutlinedIcon />}
                                onClick={submitHandler}>Submit</Button>
                            <Button id="OAclose" variant="outlined" color="error" startIcon={<CancelOutlinedIcon />}
                                onClick={() => { setOpen(false); }}>Close</Button>
                        </DialogActions>
                    </Box>
        </Rnd>
        </Popper>

            <ConfirmDialog
                    open={openSaveConfirmDialog}
                    setOpenConfirmDialogHandler={onCancelHandler}
                    title={confirmDialogObject.title}
                    body={confirmDialogObject.body}
                    okHandler={confirmDialogObject.okHandler}
                ></ConfirmDialog>
    </>
    )
}

export default OrderApproveRenderer;