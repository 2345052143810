import { ISecurityPermissions } from "../../@State/types";

export const checkDataAuditOperators = (newRecords:any[],edittedRecords:any[],securityPermissons:ISecurityPermissions) =>{
    let result = true;

    edittedRecords.forEach((element:any)=>{
        if(element.hasOwnProperty("Operator")){
            result = (securityPermissons.operators?.includes(element["Operator"]) || securityPermissons.operators === undefined || securityPermissons.operators?.toLocaleLowerCase() === "all");
        }
    });

    newRecords.forEach((element:any)=>{
        if(element.hasOwnProperty("Operator")){
            result = (securityPermissons.operators?.includes(element["Operator"]) || securityPermissons.operators === undefined || securityPermissons.operators?.toLocaleLowerCase() === "all");
        }
    });

    return result;
}