import * as React from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import AddBoxOutlined from '@mui/icons-material/AddBoxOutlined';
import TableRowsIcon from '@mui/icons-material/TableRows';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import { Link } from 'react-router-dom';

export const mainListItems = (
  <React.Fragment>
    {/* <Link to={"/tables"} style={{ textDecoration: 'none', color: 'inherit' }}>
      <ListItemButton title="Tables">
        <ListItemIcon>
          <TableRowsIcon />
        </ListItemIcon>
        <ListItemText primary="Tables" />
      </ListItemButton>
    </Link>
    <Link to={"/addtable"} style={{ textDecoration: 'none', color: 'inherit' }}>
      <ListItemButton title="New Table">
        <ListItemIcon>
          <AddBoxOutlined />
        </ListItemIcon>
        <ListItemText primary="New Table" />
      </ListItemButton>
    </Link>
    <Link to={"/historytable"} style={{ textDecoration: 'none', color: 'inherit' }}>
      <ListItemButton title="New History Table">
        <ListItemIcon>
          <AddBoxOutlined />
        </ListItemIcon>
        <ListItemText primary="New History Table" />
      </ListItemButton>
    </Link> */}
    {/* <Link to={"/circleDragPoc"} style={{ textDecoration: 'none', color: 'inherit' }}>
      <ListItemButton>
        <ListItemIcon>
          <AccountTreeOutlinedIcon />
        </ListItemIcon>
        <ListItemText primary="Circle Draggable POC" />
      </ListItemButton>
    </Link> */}
    <Link to={"/DataQualityDashboard"} style={{ textDecoration: 'none', color: 'inherit' }}>
      <ListItemButton title="Data Quality Dashboard">
        <ListItemIcon>
          <DashboardOutlinedIcon />
        </ListItemIcon>
        <ListItemText primary="Data Quality Dashboard" />
      </ListItemButton>
    </Link>

  </React.Fragment>
);
