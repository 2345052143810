import { Box, Chip, Collapse, DialogTitle, IconButton, Popper, PopperPlacementType, styled, SvgIcon, SvgIconProps, TextField, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { alpha } from '@mui/material/styles';
import TreeItem, { TreeItemProps, treeItemClasses } from '@mui/lab/TreeItem';
import { debounce } from 'src/Utility/api';
import { groupByObjectArray } from 'src/Utility/common';
import { useSpring, animated } from 'react-spring';
import { TransitionProps } from "@mui/material/transitions";
import TreeView from "@mui/lab/TreeView";
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
import WellsInfo from "../Poppers/WellsInfo";
import "./ReportInformation.css";
import { ExchangeOrderType } from "src/Components/Dashboard/@State/types";
import { AppContext } from "src/Components/Dashboard/@State/context/AppContext";
import { useEffectOnce } from "src/hooks/useEffectOnce";
import RestartAltOutlinedIcon from '@mui/icons-material/RestartAltOutlined';
import IOSSwitchToggle from "src/Common/IOSSwitchToggle";

function MinusSquare(props: SvgIconProps) {
    return (
        <SvgIcon fontSize="inherit" style={{ width: 14, height: 14, color: "rgba(0, 0, 0, 0.54)" }} {...props}>
            {/* tslint:disable-next-line: max-line-length */}
            <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 11.023h-11.826q-.375 0-.669.281t-.294.682v0q0 .401.294 .682t.669.281h11.826q.375 0 .669-.281t.294-.682v0q0-.401-.294-.682t-.669-.281z" />
        </SvgIcon>
    );
}

function PlusSquare(props: SvgIconProps) {
    return (
        <SvgIcon fontSize="inherit" style={{ width: 14, height: 14, color: "rgba(0, 0, 0, 0.54)" }} {...props}>
            {/* tslint:disable-next-line: max-line-length */}
            <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 12.977h-4.923v4.896q0 .401-.281.682t-.682.281v0q-.375 0-.669-.281t-.294-.682v-4.896h-4.923q-.401 0-.682-.294t-.281-.669v0q0-.401.281-.682t.682-.281h4.923v-4.896q0-.401.294-.682t.669-.281v0q.401 0 .682.281t.281.682v4.896h4.923q.401 0 .682.281t.281.682v0q0 .375-.281.669t-.682.294z" />
        </SvgIcon>
    );
}


function TransitionComponent(props: TransitionProps) {
    const style = useSpring({
        from: {
            opacity: 0,
            transform: 'translate3d(20px,0,0)',
        },
        to: {
            opacity: props.in ? 1 : 0,
            transform: `translate3d(${props.in ? 0 : 20}px,0,0)`,
        },
    });

    return (
        <animated.div style={style}>
            <Collapse {...props} />
        </animated.div>
    );
}

type StyledTreeItemProps = TreeItemProps & {
    DA_ColName: string;
    isEnabled?: boolean;
    itemLevel: string;
    wellcount?: number;
    wellsInOrder?: any[];
    data?: any;
    summaryCloseHandler?: any;
    selectedReportClicked?: string;
    setTabIndex?: React.Dispatch<React.SetStateAction<number>>;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    setAnchorEl: React.Dispatch<React.SetStateAction<HTMLElement | null>>;
    setSelectedReport: React.Dispatch<React.SetStateAction<any>>;
    onReportSelect: (selectedNode: any) => void;
    onReportParentSelect: (selectedNode: any, levelName: string) => void;
    setWellsWithReport: React.Dispatch<React.SetStateAction<any[]>>;
    selectedEstValData?: any[],
    IsDataModified?: boolean,
    IsValuationView?: boolean,
    groupKey?: string
};

const nodeIds: string[] = [];

const StyledTreeItem = styled((props: StyledTreeItemProps) => {
    const [orderDataWells, setOrderDataWells] = useState<any[]>([]);
    const [wellsWithReport, setWellsWithReport] = useState<any[]>([]);
    const [wellsWithoutReport, setWellsWithoutReport] = useState<any[]>([]);
    const [proposedValue, setProposedValue] = useState<string>('');

    const {
        orderWells,
        setOrderWells } = useContext(AppContext) as ExchangeOrderType;

    useEffectOnce(() => {
        setWellsInfo();
    });

    React.useEffect(() => {
        if (props.IsDataModified !== null) {
            setWellsInfo();
        }
    }, [props.IsDataModified]);

    const setWellsInfo = () => {
        if (props.selectedEstValData && props.selectedEstValData.length > 0 && props.wellsInOrder && props.wellsInOrder.length > 0) {
            const reportIncludedWells = props.selectedEstValData.filter((element: any) => { return element.IsIncludedInOrder === 'Y' && element[props.itemLevel] === props.groupKey });
            const reportNotIncludedWells = props.selectedEstValData.filter((element: any) => { return element.IsIncludedInOrder !== 'Y' && element[props.itemLevel] === props.groupKey });

            const _wellsWithReport = props.wellsInOrder.filter(o => reportIncludedWells.some(({ API, WellName }) => o.API === API && o.WellName === WellName));
            const _wellsWithoutReport = props.wellsInOrder.filter(o => reportNotIncludedWells.some(({ API, WellName }) => o.API === API && o.WellName === WellName));
            if (_wellsWithReport.length > 0) {
                _wellsWithReport[0].orderId = props.data.OrderId;
                setWellsWithReport(_wellsWithReport);

                let sum: number = 0;
                if (props.IsValuationView) {
                    reportIncludedWells.forEach((value: any) => (sum += Number(value?.DataOwnerProposedValue)));

                }
                else {
                    reportIncludedWells.forEach((value: any) => (sum += Number(value?.OrderOwnerProposedValue)));
                }

                let value = (sum / 1000).toFixed(1);
                let formattedSum = '$' + value + 'K'

                setProposedValue(formattedSum);
            }

            if (_wellsWithoutReport.length > 0) {
                _wellsWithoutReport[0].orderId = props.data.OrderId;
                setWellsWithoutReport(_wellsWithoutReport);
            }
        }
        else if (props.wellsInOrder && props.wellsInOrder.length > 0) {
            const _wellsWithReport = props.wellsInOrder.filter((element: any) => { return element[props.DA_ColName] === 1 });
            const _wellsWithoutReport = props.wellsInOrder.filter((element: any) => { return element[props.DA_ColName] === 0 });
            if (_wellsWithReport.length > 0) {
                _wellsWithReport[0].orderId = props.data.OrderId;
                setWellsWithReport(_wellsWithReport);
            }
            if (_wellsWithoutReport.length > 0) {
                _wellsWithoutReport[0].orderId = props.data.OrderId;
                setWellsWithoutReport(_wellsWithoutReport);
            }
        }
    }

    const setWellsWithReportHandler = () => {
        if (wellsWithReport.length > 0 && props.setTabIndex !== undefined) {
            setOrderWells(wellsWithReport);
            props.setTabIndex(0);
        }
    }

    const setWellsWithoutReportHandler = () => {
        if (wellsWithoutReport.length > 0 && props.setTabIndex !== undefined) {
            setOrderWells(wellsWithoutReport);
            props.setTabIndex(0);
        }
    }

    return <div style={{ display: 'flex', alignItems: 'flex-start' }} >
        <TreeItem
            {...props}
            TransitionComponent={TransitionComponent}
            onClick={() => { props.onReportSelect(props) }} />
        {
            props.children === undefined
            && props.wellcount !== undefined && wellsWithoutReport.length > 0
            && <span onClick={props.summaryCloseHandler}>
                <Chip
                    onClick={setWellsWithoutReportHandler}
                    style={{
                        width: '30px',
                        height: '15px'
                    }}
                    onMouseOver={(event: React.MouseEvent<any>) => {
                        props.setOpen(true);
                        props.setAnchorEl(event.currentTarget);
                        props.setSelectedReport(null);
                        props.setWellsWithReport(wellsWithoutReport);
                    }}
                    onMouseOut={() => {
                        props.setOpen(false);
                        props.setAnchorEl(null);
                        props.setSelectedReport("");
                    }}
                    size="small"
                    sx={{ backgroundColor: '#FFBF00', border: '1px solid #a5a5a5', marginLeft: "2px" }}
                    label={wellsWithoutReport.length}
                    variant="outlined" /></span>
        }
        {
            props.children === undefined
            && props.wellcount !== undefined
            && props.wellcount > 0
            && <span onClick={props.summaryCloseHandler}>
                <Chip
                    style={{ width: '30px', height: '15px' }}
                    onClick={setWellsWithReportHandler}
                    size="small"
                    onMouseOver={(event: React.MouseEvent<any>) => {
                        props.setOpen(true);
                        props.setAnchorEl(event.currentTarget);
                        props.setSelectedReport(props.DA_ColName);
                        props.setWellsWithReport(wellsWithReport);
                    }}
                    onMouseOut={() => {
                        props.setOpen(false);
                        props.setAnchorEl(null);
                        props.setSelectedReport("");
                    }}
                    sx={{ backgroundColor: '#6fd6b0' }}
                    label={(wellsWithReport.length)}
                    variant="outlined" /></span>
        }

        {
            props.children === undefined
            && (props.wellcount === undefined || props.wellcount == 0)
            && <span onClick={props.summaryCloseHandler}><Chip
                size="small"
                sx={{ backgroundColor: '#ebd5d3' }}
                label={"0"} variant="outlined"
                color="error" /></span>
        }

        {
            props.wellcount !== undefined
            && props.wellcount > 0 &&
            proposedValue != ''
            && <span>
                <Chip
                    style={{
                        width: '60px',
                        height: '15px',
                        position: !(props.children === undefined && props.wellcount !== undefined && props.wellcount > 0) ? 'absolute' : 'inherit',
                        right: !(props.children === undefined && props.wellcount !== undefined && props.wellcount > 0) ? '0%' : 'inherit'
                    }}
                    size="small"
                    sx={{ backgroundColor: '#FFFAFA' }}
                    label={(proposedValue)}
                    variant="outlined" /></span>
        }

    </div>
})(({ theme }) => ({
    [`& .${treeItemClasses.iconContainer}`]: {
        '& .close': {
            opacity: 0.3,
        },
    },
    [`& .${treeItemClasses.group}`]: {
        marginLeft: 15,
        paddingLeft: 18,
        borderLeft: `1px dashed ${alpha(theme.palette.text.primary, 0.4)}`,
    },
}));

export default function ReportInformation(props: any) {
    const [reportGroupsOnlyReports, setReportGroupsOnlyReports] = React.useState<any[] | undefined>([]);
    const [reportGroupsFiltered, setReportGroupsFiltered] = React.useState<any[] | undefined>([]);
    const [searchText, setSearchText] = React.useState<string>("");
    const [reportSelection, setReportSelection] = React.useState<any[]>([]);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [open, setOpen] = React.useState(false);
    const [selectedReport, setSelectedReport] = React.useState<any>("");
    const [placement] = React.useState<PopperPlacementType>('bottom-end');
    const [wellsWithReport, setWellsWithReport] = React.useState<any[]>([]);

    const { fetchReportDisplayName } = React.useContext(AppContext) as ExchangeOrderType;

    useEffect(() => {
        if (searchText !== "") {
            const filterReportSelectionResult = reportGroupsOnlyReports?.filter((element) => {
                return element.L1.toLowerCase().includes(searchText.toLowerCase())
                    || element.L2.toLowerCase().includes(searchText.toLowerCase())
                    || element.L3.toLowerCase().includes(searchText.toLowerCase())
                    || element.L4.toLowerCase().includes(searchText.toLowerCase())
                    || element.L5.toLowerCase().includes(searchText.toLowerCase())
                    || element.L6.toLowerCase().includes(searchText.toLowerCase())
            });
            setReportGroupsFiltered(filterReportSelectionResult);
            console.log("Search Result : ", filterReportSelectionResult);
        } else {
            if (props.reportGroups) {
                setReportGroupsFiltered(reportGroupsOnlyReports);
            }
        }
    }, [searchText]);

    React.useEffect(() => {
        loadReportSelectionInformation();
    }, [props.reportGroups]);

    React.useEffect(() => {
        loadReportSelectionInformation();
    }, [props.selectedEstValData, props.IsDataModified]);

    React.useEffect(() => {
        loadReportSelectionInformation();
    }, [selectedReport]);

    const removeYNFormatter = (value: string) => {
        return value.replaceAll("_YN", "").replaceAll("_", " ");
    }

    const getWellsCount = (reportName: string) => {
        let _wellCount = 0;
        if (props.selectedEstValData.length > 0) {
            const _wells = props.selectedEstValData.filter((element: any) => { return element.IsIncludedInOrder === 'Y' && element.ReportName === reportName });
            _wellCount = _wells.length;

        } else {
            if (props.wellsInOrder.length > 0) {
                const _wells = props.wellsInOrder?.filter((element: any) => { return element[reportName] === 1 });
                _wellCount = _wells.length;
            }
        }

        return _wellCount;
    }

    const onReportClickEvent = (reportName: string, level: any) => {
        props.setSelectedReportClicked(reportName, level);
    }

    const loadReportSelectionInformation = () => {
        if (props.reportGroups) {
            let reportPreference: any[] = [];
            let onlyReports = props.reportGroups.filter((element: any) => { return element.Report_YN === "Y" });

            const distinctreports = props.selectedEstValData.filter((element: any) => { return element.IsIncludedInOrder === "Y" }).filter(
                (thing: any, i: any, arr: any[]) => arr.findIndex(t => t.ReportName === thing.ReportName) === i).map((x: any) => x.ReportName);

            let selectedReports = props.selectedEstValData.length > 0 ? distinctreports.join(",") : props.orderInfo.SelectedReports;
            onlyReports?.forEach((report: any) => {
                if (selectedReports.includes(report.DA_ColName) || selectedReports.toLowerCase() == "all") {
                    reportPreference.push({
                        ColumnName: report.DA_ColName,
                        Enable: true
                    });
                } else {
                    reportPreference.push({
                        ColumnName: report.DA_ColName,
                        Enable: false
                    });
                }
            });

            selectedReports = reportPreference.filter((element: any) => { return element.Enable }).map(x => x.ColumnName);
            onlyReports = props.reportGroups.filter((element: any) => {
                return element.Report_YN === "Y" && selectedReports.includes(element.DA_ColName);
            });

            setReportGroupsOnlyReports(onlyReports);
            setReportGroupsFiltered(onlyReports);
            setReportSelection(reportPreference);
        }
    }

    const constructAllReportsParent = () => {
        nodeIds.push(0 + "AllReports");

        return <StyledTreeItem
            key={0 + "AllReports"}
            onReportSelect={() => { onReportClickEvent("", ""); }}
            nodeId={0 + "AllReports"}
            label={"ALL"}
            isEnabled={true}
            setOpen={setOpen}
            setAnchorEl={setAnchorEl}
            setSelectedReport={setSelectedReport}
            setWellsWithReport={setWellsWithReport}
            DA_ColName={"AllReports"}
            itemLevel={"AllReports"}
            IsValuationView={props.IsValuationView}
            selectedEstValData={props.selectedEstValData}
            IsDataModified={props.IsDataModified}
            selectedReportClicked={props.selectedReportClicked}
            groupKey={"All"}
            onReportParentSelect={() => { }}>
            {constructLevelOne()}
        </StyledTreeItem>
    }

    const constructLevelOne = () => {
        const level1Groups = groupByObjectArray(reportGroupsFiltered, "L1");
        const level1GroupsKeys = Object.keys(level1Groups);
        return level1GroupsKeys.map((levelOneGroupKey: any, index: number) => {
            const daColumnName = reportGroupsFiltered?.filter((element: any) => { return element.L1 === levelOneGroupKey })[0].DA_ColName;

            let children: any = [];
            children = reportGroupsFiltered?.filter((element: any) => { return element.L1 === levelOneGroupKey }).map((x: any) => x.DA_ColName);
            const isEnabled = isParentEnabled(children);
            nodeIds.push(index + "l1" + levelOneGroupKey);
            return <StyledTreeItem
                key={index + "l1" + levelOneGroupKey}
                onReportSelect={() => { onReportClickEvent(levelOneGroupKey, "L1"); }}
                nodeId={index + "l1" + levelOneGroupKey}
                wellsInOrder={props.wellsInOrder}
                label={fetchReportDisplayName(levelOneGroupKey)}
                isEnabled={isEnabled}
                setOpen={setOpen}
                setAnchorEl={setAnchorEl}
                setSelectedReport={setSelectedReport}
                setWellsWithReport={setWellsWithReport}
                DA_ColName={daColumnName}
                wellcount={getWellsCount(daColumnName)}
                itemLevel={"L1"}
                data={props.orderInfo}
                IsValuationView={props.IsValuationView}
                selectedEstValData={props.selectedEstValData}
                IsDataModified={props.IsDataModified}
                groupKey={levelOneGroupKey}
                onReportParentSelect={() => { }}>
                {constructLevelTwo(level1Groups[levelOneGroupKey])}
            </StyledTreeItem>
        });
    }

    const constructLevelTwo = (levelOneReportGroups: any) => {
        const level2Groups = groupByObjectArray(levelOneReportGroups, 'L2');
        const level2GroupsKeys = Object.keys(level2Groups);
        return level2GroupsKeys.map((levelTwoGroupKey: any, index: number) => {
            const daColumnName = levelOneReportGroups.filter((element: any) => { return element.L2 === levelTwoGroupKey })[0].DA_ColName;

            const children = levelOneReportGroups.filter((element: any) => { return element.L2 === levelTwoGroupKey }).map((x: any) => x.DA_ColName);
            const isEnabled = isParentEnabled(children);
            nodeIds.push(index + "l2" + levelTwoGroupKey);

            return <StyledTreeItem
                key={index + "l2" + levelTwoGroupKey}
                onReportSelect={() => { onReportClickEvent(levelTwoGroupKey, "L2"); }}
                nodeId={index + "l2" + levelTwoGroupKey}
                label={fetchReportDisplayName(levelTwoGroupKey)}
                setOpen={setOpen}
                wellsInOrder={props.wellsInOrder}
                setAnchorEl={setAnchorEl}
                wellcount={getWellsCount(daColumnName)}
                setSelectedReport={setSelectedReport}
                setWellsWithReport={setWellsWithReport}
                isEnabled={isEnabled}
                DA_ColName={daColumnName}
                data={props.orderInfo}
                IsValuationView={props.IsValuationView}
                selectedEstValData={props.selectedEstValData}
                IsDataModified={props.IsDataModified}
                itemLevel={"L2"}
                groupKey={levelTwoGroupKey}
                onReportParentSelect={() => { }}>
                {constructLevelThree(level2Groups[levelTwoGroupKey])}
            </StyledTreeItem>
        });
    }

    const constructLevelThree = (levelTwoReportGroups: any) => {
        const level3Groups = groupByObjectArray(levelTwoReportGroups, 'L3');
        const level3GroupsKeys = Object.keys(level3Groups);
        return level3GroupsKeys.map((levelThreeGroupKey: any, index: number) => {
            const daColumnName = levelTwoReportGroups.filter((element: any) => { return element.L3 === levelThreeGroupKey })[0].DA_ColName;

            const children = levelTwoReportGroups.filter((element: any) => { return element.L3 === levelThreeGroupKey }).map((x: any) => x.DA_ColName);
            const isEnabled = isParentEnabled(children);
            nodeIds.push(index + "l3" + levelThreeGroupKey);
            if (getWellsCount(daColumnName) > 0) {
                return <StyledTreeItem
                    key={index + "l3" + levelThreeGroupKey}
                    onReportSelect={() => { onReportClickEvent(levelThreeGroupKey, "L3"); }}
                    wellsInOrder={props.wellsInOrder}
                    wellcount={getWellsCount(daColumnName)}
                    nodeId={index + "l3" + levelThreeGroupKey}
                    label={fetchReportDisplayName(levelThreeGroupKey)}
                    setOpen={setOpen}
                    setAnchorEl={setAnchorEl}
                    setSelectedReport={setSelectedReport}
                    setWellsWithReport={setWellsWithReport}
                    isEnabled={isEnabled}
                    DA_ColName={daColumnName}
                    itemLevel={"L3"}
                    data={props.orderInfo}
                    selectedEstValData={props.selectedEstValData}
                    IsDataModified={props.IsDataModified}
                    onReportParentSelect={() => { }}
                    setTabIndex={props.setTabIndex}
                    groupKey={levelThreeGroupKey}
                    IsValuationView={props.IsValuationView}
                    summaryCloseHandler={props.summaryCloseHandler}>
                    {constructLevelFour(level3Groups[levelThreeGroupKey], levelThreeGroupKey)}
                </StyledTreeItem>
            }
        });
    }

    const constructLevelFour = (levelThreeReportGroups: any, levelThreeGroupKey: string) => {
        const level4Groups = groupByObjectArray(levelThreeReportGroups, 'L4');
        const level4GroupsKeys = Object.keys(level4Groups);

        if (level4GroupsKeys.length === 1 && level4GroupsKeys[0] === levelThreeGroupKey) { } else {
            return level4GroupsKeys.map((levelFourGroupKey: any, index: number) => {
                const daColumnName = levelThreeReportGroups.filter((element: any) => { return element.L4 === levelFourGroupKey })[0].DA_ColName;
                const isEnabled = reportSelection.filter((element) => { return element.ColumnName === daColumnName })[0]?.Enable;
                nodeIds.push(index + "l4" + levelFourGroupKey);

                if (getWellsCount(daColumnName) > 0) {
                    return <StyledTreeItem
                        key={index + "l4" + levelFourGroupKey}
                        onReportSelect={() => { onReportClickEvent(levelFourGroupKey, "L4"); }}
                        wellsInOrder={props.wellsInOrder}
                        wellcount={getWellsCount(daColumnName)}
                        nodeId={index + "l4" + levelFourGroupKey}
                        label={fetchReportDisplayName(levelFourGroupKey)}
                        setOpen={setOpen}
                        setAnchorEl={setAnchorEl}
                        setSelectedReport={setSelectedReport}
                        setWellsWithReport={setWellsWithReport}
                        isEnabled={isEnabled}
                        DA_ColName={daColumnName}
                        itemLevel={"L4"}
                        selectedEstValData={props.selectedEstValData}
                        IsDataModified={props.IsDataModified}
                        data={props.orderInfo}
                        groupKey={levelFourGroupKey}
                        onReportParentSelect={() => { }}
                        setTabIndex={props.setTabIndex}
                        IsValuationView={props.IsValuationView}
                        summaryCloseHandler={props.summaryCloseHandler}>
                        {constructLevelFive(level4Groups[levelFourGroupKey], levelFourGroupKey)}
                    </StyledTreeItem>
                }
            });
        }
    }

    const constructLevelFive = (levelfourReportGroups: any, levelfourGroupKey: string) => {
        const level5Groups = groupByObjectArray(levelfourReportGroups, 'L5');
        const level5GroupsKeys = Object.keys(level5Groups);

        if (level5GroupsKeys.length === 1 && level5GroupsKeys[0] === levelfourGroupKey) { } else {
            return level5GroupsKeys.map((levelFiveGroupKey: any, index: number) => {
                const daColumnName = levelfourReportGroups.filter((element: any) => { return element.L5 === levelFiveGroupKey })[0].DA_ColName;
                const isEnabled = reportSelection.filter((element) => { return element.ColumnName === daColumnName })[0]?.Enable;
                nodeIds.push(index + "l5" + levelFiveGroupKey);

                if (getWellsCount(daColumnName) > 0) {
                    return <StyledTreeItem
                        key={index + "l5" + levelFiveGroupKey}
                        onReportSelect={() => { onReportClickEvent(levelFiveGroupKey, "L5"); }}
                        wellsInOrder={props.wellsInOrder}
                        wellcount={getWellsCount(daColumnName)}
                        nodeId={index + "l5" + levelFiveGroupKey}
                        label={fetchReportDisplayName(levelFiveGroupKey)}
                        setOpen={setOpen}
                        setAnchorEl={setAnchorEl}
                        setSelectedReport={setSelectedReport}
                        setWellsWithReport={setWellsWithReport}
                        isEnabled={isEnabled}
                        DA_ColName={daColumnName}
                        itemLevel={"L5"}
                        selectedEstValData={props.selectedEstValData}
                        IsDataModified={props.IsDataModified}
                        data={props.orderInfo}
                        IsValuationView={props.IsValuationView}
                        onReportParentSelect={() => { }}
                        setTabIndex={props.setTabIndex}
                        groupKey={levelFiveGroupKey}
                        summaryCloseHandler={props.summaryCloseHandler}>
                        {constructLevelSix(level5Groups[levelFiveGroupKey], levelFiveGroupKey)}
                    </StyledTreeItem>
                }
            });
        }
    }

    const constructLevelSix = (levelfiveReportGroups: any, levelfiveGroupKey: string) => {
        const level6Groups = groupByObjectArray(levelfiveReportGroups, 'L6');
        const level6GroupsKeys = Object.keys(level6Groups);

        if (level6GroupsKeys.length === 1 && level6GroupsKeys[0] === levelfiveGroupKey) { } else {
            return level6GroupsKeys.map((levelSixGroupKey: any, index: number) => {
                const daColumnName = levelfiveReportGroups.filter((element: any) => { return element.L6 === levelSixGroupKey })[0].DA_ColName;
                const isEnabled = reportSelection.filter((element) => { return element.ColumnName === daColumnName })[0]?.Enable;
                nodeIds.push(index + "l6" + levelSixGroupKey);

                if (getWellsCount(daColumnName) > 0) {
                    return <StyledTreeItem
                        key={index + "l6" + levelSixGroupKey}
                        onReportSelect={() => { onReportClickEvent(levelSixGroupKey, "L6"); }}
                        wellsInOrder={props.wellsInOrder}
                        wellcount={getWellsCount(daColumnName)}
                        nodeId={index + "l6" + levelSixGroupKey}
                        label={fetchReportDisplayName(levelSixGroupKey)}
                        setOpen={setOpen}
                        setAnchorEl={setAnchorEl}
                        setSelectedReport={setSelectedReport}
                        setWellsWithReport={setWellsWithReport}
                        isEnabled={isEnabled}
                        DA_ColName={daColumnName}
                        itemLevel={"L6"}
                        data={props.orderInfo}
                        onReportParentSelect={() => { }}
                        setTabIndex={props.setTabIndex}
                        IsDataModified={props.IsDataModified}
                        IsValuationView={props.IsValuationView}
                        groupKey={levelSixGroupKey}
                        selectedEstValData={props.selectedEstValData}
                        summaryCloseHandler={props.summaryCloseHandler}>
                    </StyledTreeItem>
                }
            });
        }
    }

    const isParentEnabled = (levelReportGroups: any[]) => {
        let isEnabled = false;
        levelReportGroups.forEach((element) => {
            const isChildChecked = reportSelection.filter((reportSelectionElement) => {
                return reportSelectionElement.ColumnName === element;
            })[0]?.Enable;

            if (isChildChecked) {
                isEnabled = true;
            }
        });

        return isEnabled;
    }

    return (
        <div id="reportInformation">
            <div style={{ display: "flex", width: '100%' }}>
                <Typography variant="button" sx={{ fontSize: '1rem', float: 'right', flex: 1 }}>DataSets</Typography>
                <IOSSwitchToggle label={""} value={props.showOnlyReportInfoTrade} handleCallBack={() => { props.setShowOnlyReportInfoTrade(!props.showOnlyReportInfoTrade) }}></IOSSwitchToggle>
                <IconButton id="reportinfoclick" style={{ float: 'right' }} color="inherit" onClick={() => { onReportClickEvent("", ""); }} aria-label="close">
                    <RestartAltOutlinedIcon />
                </IconButton>
            </div>

            <TextField
                label="Search"
                id="columnselection-search-text-box"
                value={searchText}
                sx={{ width: '100%' }}
                size="small"
                onChange={(ele) => { debounce(setSearchText(ele.target.value)) }}
            />
            <Box
                sx={{
                    border: '1px solid #bfbfbf',
                    p: 1,
                    bgcolor: 'background.paper',
                    height: '63vh',
                    width: '100%',
                    paddingLeft: '0px'
                }}
                className="ag-theme-material">
                <TreeView
                    aria-label="customized"
                    defaultExpanded={nodeIds}
                    defaultSelected={0 + "AllReports"}
                    defaultCollapseIcon={<MinusSquare />}
                    defaultExpandIcon={<PlusSquare />}
                    defaultEndIcon={<AssessmentOutlinedIcon />}
                    sx={{ flexGrow: 1, overflowY: 'auto', height: '60vh' }}
                >
                    {(reportGroupsFiltered && props.setTabIndex !== undefined) && constructAllReportsParent()}
                </TreeView>
            </Box>
            <Popper
                open={open}
                placement={placement}
                anchorEl={anchorEl}
                style={{ zIndex: '10000' }}>
                <Box sx={{ border: '2px solid #bfbfbf', p: 1, bgcolor: 'background.paper', padding: "0px" }}>
                    <WellsInfo
                        selectedReport={selectedReport}
                        wellsInOrder={wellsWithReport}>
                    </WellsInfo>
                </Box>
            </Popper>
        </div>
    )
}