import { FormControlLabel, styled, Switch, SwitchProps } from "@mui/material";
import React from "react";

const IOSSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));

function EnableDisableRenderer(props: any) {
  const [checked, setChecked] = React.useState<boolean>(typeof (props.value) === 'string'  ? props.value  === 'Y' ? true : false : props.value  === 1 ? true : false);

  const checkHandler = () => {

    let _tableData = [...props.tableDataMonitor];
    if (props.tableDataMonitor.length > 0) {
      _tableData[props.node.id] = props.node.data;
      _tableData[props.node.id]["isModified"] = true;
      if (_tableData[props.node.id]["modifiedAttrs"] === undefined) {
        _tableData[props.node.id]["modifiedAttrs"] = props.column.colId;
      } else {
        let modAttrs = _tableData[props.node.id]["modifiedAttrs"];
        modAttrs = modAttrs + ',' + props.column.colId;
        _tableData[props.node.id]["modifiedAttrs"] = modAttrs;
      }
    }

    props.node.data[props.column.colId] = typeof (props.value) === 'string'  ? !checked ? 'Y' : 'N' : !checked ? 1 : 0;

    props.node.setData({ ...props.node.data, isModified: true });

    props.setTableDataMonitor(_tableData);

    setChecked(!checked);
  }

  const label = { inputProps: { 'aria-label': 'Switch demo' } };

  return (
    <IOSSwitch {...label} checked={checked} onChange={checkHandler} color="success" />
  )
}

export default EnableDisableRenderer;