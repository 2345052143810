import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import ReplayIcon from '@mui/icons-material/Replay';
import { Alert, AlertColor, AlertTitle, Avatar, Box, Button, Chip, Collapse, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, IconButton, LinearProgress, Paper, Popper, Snackbar, Stack, TextField, Tooltip, Typography } from '@mui/material';
import { ColDef, SideBarDef } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import ConfirmDialog from 'src/Common/ConfirmDialog';
import * as api from 'src/Utility/api';
import { ApprovalStatusAbbrev, ITableGridColumnDefinition } from '../../@State/types';
import './OrderApprovalRenderer.css';
import OrderApproveRenderer from './OrderApproveRenderer';
import SettingsViewRenderer from './SettingsViewRenderer';
import WellDataRenderer from './WellDataRenderer';
import { Rnd } from 'react-rnd';
import ViewColumnOutlinedIcon from '@mui/icons-material/ViewColumnOutlined';
import ColumnSelection from "../ColumnSelection/ColumnSelection";
import { useEffectOnce } from 'src/hooks/useEffectOnce';

function OrderApprovalRenderer(props: any) {
    const workflowGridRef: any = useRef();
    const [open, setOpen] = useState(false);
    const [workflowUpdated, setWorkflowUpdated] = useState(false);
    const [round, setRound] = useState(0);
    const [userWorkflowResultData, setUserWorkflowResultData] = React.useState<any[]>([]);

    const [userWorkflowData, setUserWorkflowData] = React.useState<any[]>([]);
    const [displayStatus, setDisplayStatus] = React.useState<string>('Pending');
    const [displayStatusAbbrev, setDisplayStatusAbbrev] = React.useState<string>('Pending OrderOwner');
    const [loading, setLoading] = React.useState(false);
    const [openConfirmDialog, setOpenConfirmDialog] = React.useState(false);
    const [disableRegenerate, setDisableRegenerate] = React.useState(false);
    const [displayFilter, setDisplayFilter] = React.useState(false);
    const [disableButton, setDisableButton] = React.useState(false);
    const [roundList, setRoundList] = React.useState<any[]>([]);

    const [tablePreferenceList, setTablePreferenceList] = React.useState<any[]>([]);
    const [selectedPreference, setselectedPreference] = React.useState<any>();
    const [columnPreference, setColumnPreference] = React.useState<any[]>([]);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [columnsPerfOpen, setcolumnsPerfOpen] = React.useState(false);
    const canBeOpen = columnsPerfOpen && Boolean(anchorEl);
    const [columnDefinitions, setcolumnDefinitions] = React.useState<any[]>([]);
    const [pivotMode, setPivotMode] = React.useState<boolean>(false);
    const [isEstimate, setIsEstimate] = React.useState(props.gridType === 'requested')
    const [missingColumns, setMissingColumns] = React.useState<any[]>([]);


    const sideBar: SideBarDef = useMemo(() => {
        return {
            toolPanels: [
                {
                    id: 'columns',
                    labelDefault: 'Columns',
                    labelKey: 'columns',
                    iconKey: 'columns',
                    toolPanel: 'agColumnsToolPanel',
                    minWidth: 225,
                    maxWidth: 225,
                    width: 225
                },
                {
                    id: 'filters',
                    labelDefault: 'Filters',
                    labelKey: 'filters',
                    iconKey: 'filter',
                    toolPanel: 'agFiltersToolPanel',
                    minWidth: 270,
                    maxWidth: 270,
                    width: 270,
                },
            ],
            position: 'right',
            defaultToolPanel: 'columns'
        };
    }, []);

    const defaultColDef = useMemo(() => {
        return {
            resizable: true,
            wrapHeaderText: true,
            autoHeaderHeight: true,
            headerCheckboxSelectionFilteredOnly: true,
            editable: (params: any) => {
                return isCellEditable(params);
            }
        };
    }, []);

    const gridOptions = {
        // PROPERTIES
        defaultColDef: defaultColDef,
        pagination: false,
        suppressCsvExport: true,
        suppressExcelExport: true,
        suppressCutToClipboard: true,
        suppressCopyRowsToClipboard: true,
        defaultExportParams: {
            columnGroups: true,
            fileName: 'name_of_file'
        },
        autoGroupColumnDef: {
            hide: true,
            cellRendererParams: {
                suppressCount: true,
            },
        },
        enableRangeSelection: true,
        suppressAggFuncInHeader: true,
        groupingShowCounts: false,
        getRowHeight: () => { return 24 }
    }

    const [filterWorkflowType, setFilterWorkflowType] = React.useState<string>(
        props.operator !== props.data.RequestedTo ? 'I' : 'E');
    const workflowTypesList = ['External', 'Internal'];
    const rowGroupsColumns = ["L1", "L2", "L3", "ReportName"];
    const hideColumns = ["ReportName"];

    const initialMessage = {
        open: false,
        msg: '',
        severity: 'info'
    };

    const [message, setMessage] = React.useState(initialMessage);

    const buttonSx = {
        ...(message.open ? {
            color: 'success',
            float: 'right'
        } :
            {
                color: 'primary',
                float: 'right'
            }),
    };

    const displayColumns = [
        'L1', 'L2', 'L3', 'L4', 'L5', 'L6', 'NoOfWells',
        'DataSet',
        'Available',
        'SettingsId',
        'ApprovalStatus1',
        'ApprovalStatus2',
        'ApprovalStatus3',
        'OrderOwnerProposedValue',
        'DataOwnerProposedValue',
        'OrderOwnerApproval',
        'DataOwnerApproval'
    ];

    useEffectOnce(() => {
        getUserWorkflowData(filterWorkflowType);
    });

    useEffect(() => {
        if (selectedPreference != null) {
            setColumnPreference(selectedPreference.PreferenceJson);
        }
        else {
            setInitialColumnPreferenceState();
        }
    }, [selectedPreference]);

    useEffect(() => {
        switch (props.data.OrderEvent) {
            case "ORDER_DRAFTED":
                setDisplayStatus('DRAFTED');
                setDisplayStatusAbbrev("Pending " + props.data.RequestedBy);
                setDisableButton(props.operator === props.data.RequestedTo || props.data.OrderId === 'N/A');
                break;

            case "INT_WORKFLOW_INITIATED":
            case "INT_WORKFLOW_REINITIATED":
            case "ORDER_CREATED":
            case "ORDER_UNGROUP":
                setDisplayStatus('INT-InProgress');
                setDisplayStatusAbbrev("Pending " + props.data.RequestedBy);
                setDisableButton(props.operator === props.data.RequestedTo || props.data.OrderId === 'N/A');
                break;
            case "INT_WORKFLOW_COMPLETED":
            case "COUNTER_CREATED":
            case "ORDER_EXCHANGE":
                setDisplayStatus('INT-Executed');
                setDisplayStatusAbbrev("Pending " + props.data.RequestedTo);
                setDisableButton(props.operator === props.data.RequestedTo);
                break;
            case "ORDER_APPROVED":
            case "COUNTER_APPROVED":
            case "COUNTER_REJECTED":
            case "COUNTER_RESPONSE":
            case "COUNTER_AMEND":
            case "EXT_WORKFLOW_INITIATED":
                setDisplayStatus('EXT-InProgress');
                setDisplayStatusAbbrev("Pending " + props.data.RequestedTo);
                break;
            case "EXT_WORKFLOW_COMPLETED":
                setDisplayStatus('EXT-Executed');
                setDisplayStatusAbbrev(ApprovalStatusAbbrev.EXTExecuted);
                break;
            case "ORDER_REJECTED":
            case "ORDER_WITHDRAWN":
            case "ORDER_CLOSED":
                setDisplayStatus('Closed');
                setDisplayStatusAbbrev(ApprovalStatusAbbrev.Closed);
                setDisableButton(true);
                break;
            // case "ORDER_EXCHANGE":
            //     setDisplayStatus('Closed');
            //     setDisableButton(true);
            //     break;
            default:
                setDisplayStatus('Pending');
                setDisableButton(true);
                break;
        }
        setWorkflowGrid();
    }, [userWorkflowData]);

    useEffect(() => {
        setWorkflowGrid();
    }, [pivotMode]);

    useEffectOnce(() => {
        if (workflowGridRef !== undefined && workflowGridRef?.current !== undefined) {
            setTimeout(() => {
                workflowGridRef?.current?.api?.closeToolPanel();
            }, 200);
        }
    });

    useEffect(() => {
        if (filterWorkflowType === 'I') {
            if (props.operator === props.data.RequestedBy) {
                setDisableRegenerate(false);
            } else if (props.operator === props.data.RequestedTo) {
                setDisableRegenerate(true);
            }
        } else if (filterWorkflowType === 'E') {
            if (props.operator === props.data.RequestedBy) {
                setDisableRegenerate(true);
            } else if (props.operator === props.data.RequestedTo) {
                setDisableRegenerate(false);
            }
        }

        if (props.data.OrderStatus === 'Executed') {
            setDisableRegenerate(true);
        }

    }, [filterWorkflowType]);

    const onCellValueChanged = (params: any) => {
        let _tableData = [...userWorkflowData];
        if (userWorkflowData.length > 0) {
            _tableData[params.node.id] = params.node.data;
            _tableData[params.node.id]["isModified"] = true;
        }
        setUserWorkflowData(_tableData);
    }

    const formMessage = (message: string, severity: string) => {
        setMessage({
            open: true,
            msg: message,
            severity: severity
        });
        setWorkflowUpdated(true);
    }

    const getUserWorkflowData = async (workflowType: string) => {
        let url = '/OrderManagement/getOrderWorkflowData?orderId='
            + props.data.OrderId + '&workflowType=' + workflowType;
        await api.API_GET(url).then((response) => {
            if (response?.data?.TableData.length > 0) {
                api.LOG_MESSAGE(["Page : Transaction ", "Action : Open Approval Workflow : " + props.data.OrderId], { 'UserName': props.loggedInUser });
                const round = Math.max(...response?.data?.TableData.map(function (data: any) { return data.Round; }));
                setRound(round);
                const res = Array.from(Array(round).keys()).map(x => x + 1);
                setRoundList(res);

                setUserWorkflowResultData(response?.data?.TableData);
                let roundData = response?.data?.TableData.filter((data: any) => {
                    return (data.Round === round);
                });

                setUserWorkflowData(roundData);
            } else {
                setRound(0);
                setUserWorkflowData([]);
            }
        }).catch((error) => console.log('Error while retrieving workflow details', error))
    }

    const isCellEditable = (params: any) => {
        if (!disableRegenerate && params.data.UserEmailId === props.loggedInUserMailId) {
            return false;
        }
        return false;
    }

    const fetchDisplayName = (columnName: string) => {
        let _displayName = columnName;
        let matchingPreferance = [];
        matchingPreferance = columnPreference.filter((element) => { return element.ColumnName == columnName });

        if (matchingPreferance.length > 0) {
            _displayName = matchingPreferance[0].DisplayName;
        }

        return _displayName;
    }

    const setWorkflowGrid = () => {
        if (typeof (userWorkflowData) != "undefined" && open) {
            let columnDefinitions: ITableGridColumnDefinition[] = [];
            if (userWorkflowData.length > 0) {

                const rowGroupsColumns = ["L1", "DataSet"];
                const pivotColumns = ['L2'];

                const columns = Object.keys(userWorkflowData[0]);
                displayColumns.forEach((column) => {
                    if (column === "ApprovalStatus1" || column === "ApprovalStatus2" || column === "ApprovalStatus3") {
                        columnDefinitions.push({
                            field: column,
                            //type: 'editableColumn',
                            filter: 'agTextColumnFilter',
                            sortable: true,
                            width: 80,
                            headerName: fetchDisplayName(column),
                            autoHeight: true,
                            cellRenderer: OrderApproveRenderer,
                            cellRendererParams: {
                                loggedInUserMailId: props.loggedInUserMailId,
                                loggedInUser: props.loggedInUser,
                                dataRequestor: props.operator === props.data.RequestedBy,
                                workflowType: filterWorkflowType,
                                operator: props.operator,
                                orderId: props.data.OrderId,
                                getSelectedWorkflows: getSelectedWorkflows,
                                formMessage: formMessage,
                                setLoading: setLoading,
                                refresh: getUserWorkflowData
                            },
                            editable: false,
                            enableRowGroup: true,
                            pivot: pivotMode && pivotColumns.includes(column) ? true : false,
                            enablePivot: pivotMode ? true : false,
                            hide: isColumnHidden(column),
                            cellClass: ['ag-cell-custom'],
                            suppressToolPanel: false
                        });
                    } else if (column === "NoOfWells") {
                        columnDefinitions.push({
                            field: column,
                            //type: 'editableColumn',
                            headerName: fetchDisplayName(column),
                            headerTooltip: fetchDisplayName(column),
                            cellRenderer: WellDataRenderer,
                            enableRowGroup: true,
                            cellRendererParams: {
                                wellData: props.data
                            },
                            cellClass: ['ag-cell-custom'],
                            filter: 'agTextColumnFilter',
                            width: 50,
                            pivot: pivotMode && pivotColumns.includes(column) ? true : false,
                            enablePivot: pivotMode ? true : false,
                            hide: isColumnHidden(column),
                            autoHeight: true
                        })
                    } else if (column === "SettingsId") {
                        columnDefinitions.push({
                            field: column,
                            //type: 'editableColumn',
                            headerName: fetchDisplayName(column),
                            headerTooltip: fetchDisplayName(column),
                            cellRenderer: SettingsViewRenderer,
                            cellRendererParams: {
                                readOnly: disableRegenerate
                            },
                            cellClass: ['ag-cell-custom'],
                            filter: 'agTextColumnFilter',
                            width: 40,
                            hide: isColumnHidden(column),
                            autoHeight: true
                        })
                    } else if (column === "DataSet") {
                        columnDefinitions.push({
                            field: column,
                            //type: 'editableColumn',
                            tooltipField: column,
                            width: 40,
                            headerName: fetchDisplayName("DataSet"),
                            editable: false,
                            cellClass: ['ag-cell-custom'],
                            autoHeight: true,
                            filter: true,
                            enableRowGroup: true,
                            pivot: pivotMode && pivotColumns.includes(column) ? true : false,
                            rowGroup: pivotMode && rowGroupsColumns.includes(column) ? true : false,
                            enablePivot: pivotMode ? true : false,
                            hide: isColumnHidden(column),
                            valueFormatter: applyValueFormatting(column)
                        });
                    } else if (column === "OrderOwnerProposedValue") {
                        columnDefinitions.push({
                            field: column,
                            //type: 'editableColumn',
                            tooltipField: column,
                            width: 40,
                            headerName: fetchDisplayName(column),
                            editable: false,
                            sortable : true,
                            cellClass: ['ag-cell-custom'],
                            autoHeight: true,
                            enableRowGroup: true,
                            pivot: pivotMode && pivotColumns.includes(column) ? true : false,
                            rowGroup: pivotMode && rowGroupsColumns.includes(column) ? true : false,
                            aggFunc: "sum",
                            cellStyle : (params : any) => {
                                if (props.operator === props.data.RequestedBy && (params.value === null || params.value === 0)) {
                                    return {backgroundColor: '#FF0000', textAlign: 'right'};
                                }
                                return  {textAlign: 'right'};;
                            },
                            enablePivot: pivotMode ? true : false,
                            enableValue: true,
                            filter: true,
                            hide: isColumnHidden(column),
                            valueFormatter: applyValueFormatting(column)
                        });
                    }else if (column === "DataOwnerProposedValue") {
                        columnDefinitions.push({
                            field: column,
                            //type: 'editableColumn',
                            tooltipField: column,
                            width: 40,
                            headerName: fetchDisplayName(column),
                            editable: false,
                            cellClass: ['ag-cell-custom'],
                            autoHeight: true,
                            sortable : true,
                            enableRowGroup: true,
                            pivot: pivotMode && pivotColumns.includes(column) ? true : false,
                            rowGroup: pivotMode && rowGroupsColumns.includes(column) ? true : false,
                            aggFunc: "sum",
                            cellStyle : (params : any) => {
                                if (props.operator === props.data.RequestedTo && (params.value === null || params.value === 0)) {
                                    return {backgroundColor: '#FF0000', textAlign: 'right'};
                                }
                                return  {textAlign: 'right'};;
                            },
                            enablePivot: pivotMode ? true : false,
                            enableValue: true,
                            filter: true,
                            hide: isColumnHidden(column),
                            valueFormatter: applyValueFormatting(column)
                        });
                    } else {
                        columnDefinitions.push({
                            field: column,
                            //type: 'editableColumn',
                            tooltipField: column,
                            width: 40,
                            headerName: fetchDisplayName(column),
                            editable: false,
                            sortable: true,
                            cellStyle: { textAlign: 'left' },
                            pivot: pivotMode && pivotColumns.includes(column) ? true : false,
                            rowGroup: pivotMode && rowGroupsColumns.includes(column) ? true : false,
                            cellClass: ['ag-cell-custom'],
                            autoHeight: true,
                            filter: true,
                            enableRowGroup: true,
                            enablePivot: pivotMode ? true : false,
                            hide: isColumnHidden(column),
                            valueFormatter: applyValueFormatting(column)
                        });
                    }
                });

                columnDefinitions = rearrangeColumnsBasedOnPreference(columnDefinitions, []);
                let hiddenItem = columnDefinitions.filter((elem: any) => { return !elem.hide });
                hiddenItem[0].headerCheckboxSelection = true;
                hiddenItem[0].checkboxSelection = true;


                // columnDefinitions[0].headerCheckboxSelection = true;
                // columnDefinitions[0].checkboxSelection = true;

                setcolumnDefinitions(columnDefinitions);
                
                if (typeof (workflowGridRef?.current?.api) !== 'undefined') {
                    workflowGridRef.current.api.setColumnDefs(columnDefinitions);
                    workflowGridRef.current.api.setRowData(userWorkflowData);
                    // workflowGridRef.current.api.autoSizeAllColumns(true);
                } else {
                    if (workflowGridRef.current !== null) {
                        workflowGridRef.current.props.gridOptions.columnDefs = columnDefinitions;
                        workflowGridRef.current.props.gridOptions.rowData = userWorkflowData;
                    }
                }
                onValidate();
                workflowGridRef.current?.api?.sizeColumnsToFit();
                // workflowGridRef.current.api.expandAll();
            } else {
                if (typeof (workflowGridRef?.current?.api) !== 'undefined') {
                    workflowGridRef.current.api.setColumnDefs([]);
                    workflowGridRef.current.api.setRowData([]);
                }
            }
        }
    }

    const loadColumnPreferences = () => {
        async function loadColumnPreferencesData() {
            setTablePreferenceList([]);

            let getDataUrl = '/Common/GetColumnPreferencesByTable?schema=ORDER&table=WORKFLOW';
            await api.API_GET(getDataUrl).then((response) => {
                if (response?.data?.length > 0) {
                    let globalPreference = response?.data.filter((ele: any) => { return ele.PreferenceName.toLowerCase().includes('global') });
                    let defaultPreference = response?.data.filter((ele: any) => { return ele.UserMailId == props.loggedInUserMailId && ele.IsDefault });
                    let preferences = response?.data.filter((ele: any) => {
                        return ((ele.UserMailId === props.loggedInUserMailId) ||
                            (ele.UserMailId !== props.loggedInUserMailId && ele.IsPublic))
                    });

                    setTablePreferenceList(preferences);

                    if (defaultPreference.length > 0) {
                        setselectedPreference(defaultPreference[0]);
                    }
                    else if (globalPreference.length > 0) {
                        setselectedPreference(globalPreference[0]);
                    }
                    else if (preferences.length > 0) {
                        //setselectedPreference(preferences[0]);
                    }
                }
            }).catch(error => {
                console.log("error in post call", error)
            }).finally(() => {
            });
        };

        loadColumnPreferencesData();
    }

    const handleClickColumnsOpen = (event: React.MouseEvent<HTMLElement>) => {
        setInitialColumnPreferenceState();
        setAnchorEl(event.currentTarget);
        setcolumnsPerfOpen(!columnsPerfOpen);
    };

    const applyColumnPreferenceHandler = async (selectedPreferene: any) => {
        setselectedPreference(selectedPreferene);
        applyColumnPreference(selectedPreferene.PreferenceJson);
    }

    const isColumnHidden = (columnName: string) => {
        if (columnPreference != null) {
            let colMatch = columnPreference.filter((element: any) => {
                return element.ColumnName === columnName;
            });
            if (colMatch.length > 0) {
                return !colMatch[0].Enable;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }

    const applyDisplayColumnNames = (_columnPreference: any[], colDefs: any) => {
        colDefs.forEach((colDef: any) => {
            let colPref = _columnPreference.filter((ele) => { return ele.ColumnName == colDef.field });
            if (colPref.length > 0) {
                colDef.headerName = colPref[0].DisplayName
            }
        });

        return colDefs;
    }

    const applyColumnPreference = (_columnPreference: any[]) => {
        let updatedColumnDefs = rearrangeColumnsBasedOnPreference([...columnDefinitions], _columnPreference);
        _columnPreference.forEach((colPreference: any) => {
            let columnDefMatch = updatedColumnDefs.filter((element: any) => { return element.field.toLowerCase().match(colPreference.ColumnName.toLowerCase()) || element.headerName.toLowerCase().match(colPreference.ColumnName.toLowerCase()) });
            if (columnDefMatch.length > 0) {
                if (colPreference.Enable === false) {
                    updatedColumnDefs[updatedColumnDefs.indexOf(columnDefMatch[0])].hide = true;
                } else {
                    updatedColumnDefs[updatedColumnDefs.indexOf(columnDefMatch[0])].hide = false;
                }
            }
        });

        updatedColumnDefs.forEach((elem: any) => {
            elem.headerCheckboxSelection = false;
            elem.checkboxSelection = false;
        });

        let hiddenItem = updatedColumnDefs.filter((elem: any) => { return !elem.hide });
        hiddenItem[0].headerCheckboxSelection = true;
        hiddenItem[0].checkboxSelection = true;

        workflowGridRef.current?.columnApi?.columnModel?.setColumnDefs(applyDisplayColumnNames(_columnPreference, updatedColumnDefs));
        workflowGridRef.current?.api?.setRowData(userWorkflowData);
        workflowGridRef.current?.api?.sizeColumnsToFit();
    }

    const rearrangeColumnsBasedOnPreference = (columnDefinitions: any[], _columnPreference: any[]) => {
        let _reArrangedColumnDefinitions: any[] = [];
        let colPrefs = _columnPreference.length > 0 ? _columnPreference : [...columnPreference];

        if (colPrefs != null && colPrefs.length > 0) {
            colPrefs.forEach((preference: any) => {
                const columnDefinitionObj = columnDefinitions.filter((ele: any) => { return ele.field.toLowerCase().match(preference.ColumnName.toLowerCase()) || ele.headerName.toLowerCase().match(preference.ColumnName.toLowerCase()) });
                if (columnDefinitionObj.length > 0) {
                    _reArrangedColumnDefinitions.push(columnDefinitionObj[0]);
                }
            });

            const columnsNotInPreference = columnDefinitions.filter(o => !colPrefs.some(({ ColumnName }) => o.field.toLowerCase().match(ColumnName.toLowerCase()) || o.headerName.toLowerCase().match(ColumnName.toLowerCase())));
            columnsNotInPreference.forEach((column: any) => {
                _reArrangedColumnDefinitions.push(column);
            });
        }

        return _reArrangedColumnDefinitions.length > 0 ? _reArrangedColumnDefinitions : columnDefinitions;
    }

    const setInitialColumnPreferenceState = () => {
        let columnPreference: any[] = [];
        if (selectedPreference == null) {
            displayColumns.forEach((element: any) => {
                columnPreference.push({
                    ColumnName: element,
                    Enable: true,
                    AvailableOrDisplayed: "D" // A for Available columns , D for Displayed columns
                });
            });

            if (columnPreference.length > 0) {
                let preference: any = {
                    PreferenceJson: columnPreference,
                    PreferenceId: 0,
                    PreferenceName: '',
                    SchemaName: 'ORDER',
                    TableName: 'WORKFLOW',
                    UserMailId: props.loggedInUserMailId,
                    IsPublic: true,
                    IsDefault: false,
                    CreatedBy: props.loggedInUserMailId
                };

                setselectedPreference(preference);
            }
        }
    }

    const handleClickOpen = (event: React.MouseEvent<HTMLElement>) => {
        if (props.operator === props.data.RequestedBy) {
            setDisplayFilter(true);
        } else if (props.operator === props.data.RequestedTo) {
            setDisplayFilter(false);
        }
        loadColumnPreferences();
        getUserWorkflowData(filterWorkflowType);
        setOpen(true);
    };

    const regenerateWorkflow = () => {
        triggerWorkflow(props.data.OrderId, props);
    };

    const regenerateHandler = (event: React.MouseEvent<HTMLElement>) => {
        setOpenConfirmDialog(true);
    };

    const getSelectedWorkflows = (): any => {
        return workflowGridRef.current.api.getSelectedRows();
    };

    const triggerWorkflow = async (orderId: string, props: any) => {
        setLoading(true);
        setMessage({ ...message, open: false });
        let initiateWorkflowUrl = '/OrderManagement/InitiateWorkflow'
        await api.API_POST(initiateWorkflowUrl, {
            orderId: orderId,
            operator: filterWorkflowType === "E" ? props.data.RequestedTo : props.data.RequestedBy,
            auditIds: getAudidIds(props.data.Data),
            selectedReports: props.data.SelectedReports,
            workflowType: filterWorkflowType,
            Regenerate: true,
            userEmailId: props.loggedInUserMailId,
            userName: props.loggedInUser,
            database: api.env === 'OASIS' ? 'oasis' : 'envoy_dev'
        }).then((response) => {
            if (response?.data?.length > 0) {
                formMessage('Order workflow initiated successfully', 'success');
                api.LOG_MESSAGE(["Page : Transaction ", "Action : Regenerate Approval Workflow : " + orderId], { 'UserName': props.loggedInUser });
                getUserWorkflowData(filterWorkflowType);
            } else {
                formMessage('Order workflow initiate failed', 'error');
            }
            setLoading(false);
        }).catch(() => {
            console.log('Error while regenerating workflow', 'error');
            setLoading(false);
        });
    }

    const applyValueFormatting = (column: any) => {
        const dollarColumns: any[] = ["DataOwnerProposedValue", "OrderOwnerProposedValue"];
        const fixDecimalTwoColumns: any[] = [];
        if (dollarColumns.includes(column)) {
            return (params: any) => currencyFormatter(params.value, '$');
        }
    }

    function currencyFormatter(currency: number, sign: string) {
        if (currency !== null && currency !== undefined && !isNaN(currency)) {
            let sansDec = parseFloat(currency.toString())?.toFixed(2);
            let formatted = sansDec?.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
            return sign + `${formatted}`;
        }
    }

    const getAudidIds = (jsonString: string): string => {
        const jsonData = JSON.parse(jsonString);
        let auditIds: string[] = [];
        for (let prop in jsonData) {
            auditIds.push(jsonData[prop]['DataAuditId']);
        }
        return auditIds.join(",");;
    }

    const autoGroupColumnDef = useMemo<ColDef>(() => {
        return {
            minWidth: 200,
        };
    }, []);

    const workflowTypeFilterChanged = useCallback((newValue: string) => {
        setFilterWorkflowType(newValue);
        getUserWorkflowData(newValue);
    }, []);

    const autoSizeColumns = (timeoutPeriod: number) => {
        setTimeout(() => {
            workflowGridRef?.current?.columnApi.autoSizeAllColumns(false);
            //workflowGridRef.current.columnApi.sizeColumnsToFit();
        }, timeoutPeriod);
    }

    const onFirstDataRendered = useCallback((timeoutPeriod: number) => {
        autoSizeColumns(timeoutPeriod);
        setTimeout(() => {
            workflowGridRef?.current?.api?.closeToolPanel();
        }, timeoutPeriod);
    }, []);

    const ShowRoundWorkflow = (key: any) => {
        const index = key.options.selectedIndex;
        let selectedRound = roundList[index];
        let roundData = userWorkflowResultData.filter((data: any) => {
            return (data.Round === selectedRound);
        });

        setRound(selectedRound);
        setUserWorkflowData(roundData);
    }

    const onPivotModeChanged = (params: any) => {
        setPivotMode((pivotMode) => !pivotMode);
    }

    const fetchStatusDescription = (status: string) => {
        let statusDescription = "...";
        switch (status) {
            case "Pending": statusDescription = checkIfAnyPendingActions() === "" ? "Click PLACE ORDER to initiate approvals." : "Populate Estimates $$$ and add datasets.";
                break;
            case "DRAFTED": statusDescription = checkIfAnyPendingActions() === "" ? "Click PLACE ORDER to initiate approvals." : "Populate Estimates $$$ and add datasets.";
                break;
            case "INT-InProgress":
                if (round === 0 && userWorkflowData.length == 0)
                {
                    if(props.gridType === 'requested') statusDescription = "Pending " + props.data.RequestedBy + " approval. (Missing workflow) (Click here)";
                    if(props.gridType === 'pending') statusDescription = "Pending " + props.data.RequestedBy + " approval.";
                }
                if (round === 1){
                    if(props.gridType === 'requested') statusDescription = "Pending " + props.data.RequestedBy + " approval. (Click here)";
                    if(props.gridType === 'pending') statusDescription = "Pending " + props.data.RequestedBy + " approval.";
                };
                if (round >= 2){
                    if(props.gridType === 'requested') statusDescription = "Pending " + props.data.RequestedBy + " final approval. (Click here)";
                    if(props.gridType === 'pending') statusDescription = "Pending " + props.data.RequestedBy + " final approval.";
                };
                break;
            case "INT-Executed":
                statusDescription = "Dataowner " + props.data.RequestedTo + " notified.";
                if(props?.data?.TradeType == "Trade" && props.data?.TradeGroupId?.toLowerCase() === "n/a"){
                    statusDescription = "Dataowner " + props.data.RequestedTo + " notified.";
                }
                else if(props.gridType === 'requested'){ // Order owner side view
                    if(props?.data?.TotalCount === props?.data?.ZeroDOProposedValueCount){
                        statusDescription = "Dataowner " + props.data.RequestedTo + " notified.";
                    }
                    else if(props?.data?.ZeroDOProposedValueCount > 0){ 
                        statusDescription = "Data owner populated valuation (In progress)";
                    }
                    else if(props?.data?.ZeroDOProposedValueCount == 0 && props?.data?.OOApprovalValueCount > 0){
                        statusDescription = "Data owner populated valuation , Order owner acceptance pending.";
                    }
                    else if(props?.data?.ZeroDOProposedValueCount == 0 && props?.data?.OOApprovalValueCount == 0){
                        statusDescription = "Data owner acceptance pending.";
                    }
                }
                if ((checkIfAnyPendingActions() || props?.data?.DOApprovalValueCount > 0) && props.gridType === 'pending') {
                    statusDescription = "Populate valuation.";
                    if(props?.data?.ZeroCurrentValueCount > 0 && props.gridType === 'pending'){
                        statusDescription = "Populate valuation.";
                    }
                    else if(props?.data?.ZeroProposedValueCount > 0 && props.gridType === 'pending'){
                        statusDescription = "Populate valuation.";
                    }
                    else if (props?.data?.DOApprovalValueCount > 0 && props.gridType === 'pending') {
                        statusDescription = "Data owner " + props.data.RequestedTo + " approval pending.";
                    }
                }
                else if (props?.data?.detailData
                    && props.gridType === 'pending'
                    && (round === 0 || round === 1)
                    && props.data.detailData.length > 0
                    && props.data.detailData[0].OrderEvent === "INT_WORKFLOW_COMPLETED") {
                    statusDescription = "Pending order owner " + props.data.RequestedBy + " approval."
                }
                else if (props?.data?.detailData
                    && round >= 2
                    && props.gridType === 'pending'
                    && props.data.detailData.length > 0
                    && props.data.detailData[0].OrderEvent === "INT_WORKFLOW_COMPLETED") {
                    statusDescription = "Pending order owner " + props.data.RequestedBy + " final approval."
                }
                break;
            case "EXT-InProgress":
                if (round === 1) statusDescription = "Pending " + props.data.RequestedTo + " approval.";
                if (round >= 2 ) statusDescription = "Pending " + props.data.RequestedTo + " final approval.";
                break;
            case "EXT-Executed":
                if (props?.data?.TradeType == "Trade") {
                    statusDescription = "Pending exchange order approval."
                    if (props.data?.TradeGroupId?.toLowerCase() === "n/a") {
                        statusDescription = "Pending exchange order creation."
                    }
                    else if ((props.data?.detailData
                        && props.data?.detailData?.length > 0
                        && props.data.detailData[0].OrderStatus === "Executed")
                        && props.data?.OrderStatus === "Executed") {
                        statusDescription = "Fully Executed."
                        if(props.gridType === 'requested'){ 
                            //order owner side view
                            if(props?.data?.DODataNotTrasferredCount > 0){
                                statusDescription = "Fully Executed.Pending Data Transfer from Data Owner " + props.data.RequestedTo;
                            } else if (props?.data?.DODataNotTrasferredCount == 0 && props?.data?.OODataNotRecievedCount > 0){
                                statusDescription = "Fully Executed.Data Transferred. Order Owner "+ props.data.RequestedBy +" Data Recieved pending";
                            } else if(props?.data?.DODataNotTrasferredCount == 0 && props?.data?.OODataNotRecievedCount == 0){
                                statusDescription = "Fully Executed.Data Recieved";
                            }
                        }
                        else{
                            // data owner side view
                            if(props?.data?.DODataNotTrasferredCount > 0){
                                statusDescription = "Fully Executed.Pending Data Transfer";
                            } else if (props?.data?.DODataNotTrasferredCount == 0 && props?.data?.OODataNotRecievedCount > 0){
                                statusDescription = "Fully Executed.Data Transferred.Pending Confirmation from Order Owner " + props.data.RequestedBy;
                            } else if(props?.data?.DODataNotTrasferredCount == 0 && props?.data?.OODataNotRecievedCount == 0){
                                statusDescription = "Fully Executed.Data Recieved";
                            }
                        }
                    }
                    else if (props.data?.detailData === undefined
                        && props?.data?.OrderStatus === "Executed") {
                        statusDescription = "Fully Executed."
                        if(props.gridType === 'requested'){ 
                            //order owner side view
                            statusDescription = GetOrderOwnerSideStatusDescription(statusDescription);
                        }
                        else{
                            // data owner side view
                            statusDescription = GetDataOwnerSideStatusDescription(statusDescription);
                        }
                    }
                } 
                else {
                    statusDescription = "Fully Executed."
                    // Purchase and rental order
                    if(props.gridType === 'requested'){ 
                        //order owner side view
                        statusDescription = GetOrderOwnerSideStatusDescription(statusDescription);
                    }
                    else{
                        // data owner side view
                        statusDescription = GetDataOwnerSideStatusDescription(statusDescription);
                    }
                }
                break;
            default:
                break;
        }
        return statusDescription;
    }

    function GetDataOwnerSideStatusDescription(statusDescription: string) {
        if(props?.data?.DODataNotTrasferredCount > 0){
            statusDescription = "Fully Executed.Pending Data Transfer";
        } else if (props?.data?.DODataNotTrasferredCount == 0 && props?.data?.OODataNotRecievedCount > 0){
            statusDescription = "Fully Executed.Data Transferred.Pending Confirmation from Order Owner " + props.data.RequestedBy;
        } else if(props?.data?.DODataNotTrasferredCount == 0 && props?.data?.OODataNotRecievedCount == 0){
            statusDescription = "Fully Executed.Data Recieved";
        }
        return statusDescription;
    }

    function GetOrderOwnerSideStatusDescription(statusDescription: string) {
        if (props?.data?.DODataNotTrasferredCount > 0) {
            statusDescription = "Fully Executed.Pending Data Transfer from Data Owner " + props.data.RequestedTo;
        } else if (props?.data?.DODataNotTrasferredCount == 0 && props?.data?.OODataNotRecievedCount > 0) {
            statusDescription = "Fully Executed.Data Transferred. Order Owner " + props.data.RequestedBy + " Data Recieved pending";
        } else if (props?.data?.DODataNotTrasferredCount == 0 && props?.data?.OODataNotRecievedCount == 0) {
            statusDescription = "Fully Executed.Data Recieved";
        }
        return statusDescription;
    }

    function GetPendingStateForExecutedOrder(isPending: boolean) {
        isPending = false;
        if (props.gridType === 'requested' && props?.data?.DODataNotTrasferredCount == 0 && props.data?.OODataNotRecievedCount > 0) {
            isPending = true;
        }
        else if (props.gridType === 'pending' && props?.data?.DODataNotTrasferredCount > 0) {
            isPending = true;
        }
        return isPending;
    }


    const fetchStatusAnythingPending = (status: string) => {
        let isPending = true;
        switch (status) {
            case "Pending":
            case "DRAFTED": if (props?.data?.OOApprovalValueCount > 0 || props.gridType === 'requested') {
                isPending = false;
            } else {
                isPending = true;
            }
            break;
            case "INT-InProgress": if(props.gridType === 'pending'){
                isPending = false;
            }
                break;
            case "INT-Executed":

                isPending = false;
                
                break;
                case "EXT-InProgress":  
                  if(props.gridType !== 'pending'){
                    isPending = false;
                  }
                break;
            case "EXT-Executed":
                if (props.data.TradeType == "Trade") {
                    if ((props.data?.detailData && props.data?.detailData?.length > 0 && props.data.detailData[0].OrderStatus === "Executed")
                        || props.data?.OrderStatus === "Executed") {
                        isPending = GetPendingStateForExecutedOrder(isPending);
                    }
                } else {
                    // Purchase and rental order
                    isPending = GetPendingStateForExecutedOrder(isPending);
                }
                break;
            case "Data owner populated valuation , Order owner acceptance pending.":
                    isPending = true;
                    break;
            default:
                break;
        }
        return isPending;
    }
    
    const checkIfAnyPendingActions = () => {
        let heartBeatClassName = "";
        if ((props?.data?.ZeroProposedValueCount > 0
            || props?.data?.ZeroCurrentValueCount > 0)
            || fetchStatusAnythingPending(displayStatus)) {
            heartBeatClassName = "heart";
        }
        return heartBeatClassName;
    }

    const checkIfAnyPendingActionsHeartBeatClass = (nextStepMessage: string = "") => {
        let heartBeatClassName = "";
        if ((props?.data?.ZeroProposedValueCount > 0
            || props?.data?.ZeroCurrentValueCount > 0)
            || fetchStatusAnythingPending(displayStatus)
            || nextStepMessage == "Click PLACE ORDER to initiate approvals."
            || nextStepMessage == "Data owner populated valuation , Order owner acceptance pending."
            || nextStepMessage == "Data owner " + props.data.RequestedTo + " approval pending.") {
            heartBeatClassName = "heart";
        }
        return heartBeatClassName;   
    }

    const fetchStatusBackgroundColor = (nextStepMessage: string = "") => {
        return checkIfAnyPendingActionsHeartBeatClass(nextStepMessage) === "heart" ? "red" : "black"
    }

    const closeMessage = () => {
        setMessage(initialMessage);
    }

    const onValidate = () => {
        let columns : any[] = [];
        workflowGridRef.current!.api.forEachNodeAfterFilterAndSort((params: any) => {
                if(props.operator === props.data.RequestedBy && (params.data.OrderOwnerProposedValue === 0 || params.data.OrderOwnerProposedValue === null)){
                    columns.push('OrderOwnerProposedValue');                
                }

                if(props.operator === props.data.RequestedTo && (params.data.DataOwnerProposedValue === 0 || params.data.DataOwnerProposedValue === null)){
                    columns.push('DataOwnerProposedValue');                
                }
        });

        if(columns.length > 0){
            let unique = columns.filter((item, i, ar) => ar.indexOf(item) === i);
            setMissingColumns(unique);
        }
    }

    return (
        <React.Fragment>
            <Button id="orderapprovalbtn1" size="small" sx={{ width: '100%' }} onClick={handleClickOpen} disabled={disableButton}>
                <Grid
                    container
                    direction="column"
                    justifyContent="space-evenly"
                    alignItems="stretch"
                >
                    <Grid item xs={12}>
                        {displayStatus}
                    </Grid>
                    <Grid item xs={12}>
                        <Chip
                            className={checkIfAnyPendingActionsHeartBeatClass(fetchStatusDescription(displayStatus))}
                            sx={{
                                //width:'70%',
                                height: 'auto',
                                '& .MuiChip-label': {
                                    display: 'block',
                                    whiteSpace: 'normal',
                                },
                                fontSize: 'x-small',
                                backgroundColor: 'transparent',
                                color: fetchStatusBackgroundColor(fetchStatusDescription(displayStatus))
                            }}
                            label={fetchStatusDescription(displayStatus)}
                        />
                    </Grid>
                </Grid>
            </Button>


            <Popper open={open} style={{ width: '100%', background: 'white', top: '70px' }}>
                <Rnd default={{
                    x: 0,
                    y: 0,
                    width: '100%',
                    height: '80vh'
                }} cancel=".cancelGrid">
                    <Box sx={{ zIndex: '10000', border: '2px solid #bfbfbf', width: '100%', bgcolor: 'background.paper' }}>
                        <DialogTitle>{props.data.TradeType} order : {props.data.OrderId}</DialogTitle>
                        {loading && (
                            <LinearProgress />
                        )}

                        <Snackbar
                            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                            open={message.open}
                            autoHideDuration={1800}
                            onClose={() => { closeMessage() }}>
                            <p></p>
                        </Snackbar>
                        {message.open && <Alert variant="outlined" severity={message.severity as AlertColor}
                            onClose={() => { setMessage({ ...message, open: false }) }}>
                            <AlertTitle>{message.severity.toUpperCase()}</AlertTitle>
                            {message.msg}
                        </Alert>}
                        <DialogContent>
                            <DialogContentText>
                                Current User : {props.loggedInUser}<br />
                                Data Owner : {props.data.RequestedTo}<br />
                                Round : <TextField
                                    id="Round" name="Round" select label="Round"
                                    size="small"
                                    sx={{
                                        width: '100%',
                                        maxWidth: 90,
                                        minWidth: 30
                                    }}
                                    SelectProps={{
                                        native: true,
                                    }}
                                    value={round}
                                    onChange={e => ShowRoundWorkflow(e.target)}>
                                    {roundList?.map((round: any) => (
                                        <option key={round} value={round}>
                                            {round}
                                        </option>
                                    ))}
                                </TextField>
                             
                                <br />
                                <br />
                                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                                    <Grid item xs={3}>
                                        {displayFilter &&
                                            <Stack direction="row" spacing={1}>
                                                <Typography variant="subtitle1" component="div">
                                                    Workflow Type:
                                                </Typography>
                                                {workflowTypesList.map((item) => (
                                                    <Chip
                                                        key={item}
                                                        color="primary"
                                                        avatar={<Avatar>{item.charAt(0)}</Avatar>}
                                                        label={item}
                                                        variant={item.charAt(0) === filterWorkflowType ? "filled" : "outlined"}
                                                        onClick={(event) => {
                                                            workflowTypeFilterChanged(item.charAt(0));
                                                        }} />
                                                ))}

                                            </Stack>
                                        }
                                          
                                    </Grid>
                                    <Grid item xs={4}>
                                    {missingColumns && missingColumns.length > 0 && 
                                                                                    <Collapse in={missingColumns.length > 0} sx = {{display:"inline-block", ml: -20}}>
                                                                                    <Alert severity="error"
                                                                                        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                                                                                        <strong>Missing Values</strong>  {missingColumns.map((_errorInfo, index) => {
                                                                                        return <><strong> {index + 1}.</strong> {_errorInfo} </>
                                                                                        })}
                                                                                    </Alert>
                                                                                </Collapse>
                                                                                }           
                                    </Grid>

                                    <Grid item xs={5}>

                                        <Tooltip title="columns" style={{ margin: 2, float: 'right' }}>
                                            <IconButton
                                                id="columns"
                                                color="success"
                                                aria-label="columns"
                                                component="label"
                                                style={{ backgroundColor: "rgb(239 238 238)", marginRight: '5px', marginLeft: '10px', float: 'right' }}
                                                onClick={handleClickColumnsOpen}>
                                                <ViewColumnOutlinedIcon />
                                            </IconButton>
                                        </Tooltip>

                                        <Button id="OAclose" sx={{ float: 'right', marginLeft: '10px', padding: '3px 15px' }} variant="outlined" color="error" startIcon={<CancelOutlinedIcon />}
                                            onClick={() => {
                                                if (!disableRegenerate && workflowUpdated) {
                                                    props.loadPendingRequests();
                                                }
                                                setOpen(false);
                                            }}>Close</Button>

                                        <Button id="regenerate" sx={buttonSx} disabled={disableRegenerate} variant="contained"
                                            onClick={regenerateHandler}
                                            size="small" startIcon={<ReplayIcon />}>
                                            Regenerate
                                        </Button>
                                    </Grid>
                                </Grid><br />
                             
                            </DialogContentText>
                            <div style={{ height: '60vh', width: 'auto' }} className="ag-theme-balham">


                                <AgGridReact
                                    className='cancelGrid'
                                    ref={workflowGridRef}
                                    rowSelection={'multiple'}
                                    gridOptions={gridOptions}
                                    pivotMode={pivotMode}
                                    suppressExpandablePivotGroups={true}
                                    onColumnGroupOpened={() => { onFirstDataRendered(0) }}
                                    sideBar={sideBar}
                                    groupDefaultExpanded={-1}
                                    groupIncludeFooter={false}
                                    groupIncludeTotalFooter={false}
                                    suppressAggFuncInHeader={true}
                                    onColumnPivotModeChanged={onPivotModeChanged}
                                    onCellValueChanged={onCellValueChanged}
                                    onFirstDataRendered={() => { onFirstDataRendered(0) }}
                                    overlayNoRowsTemplate={'<span style="padding: 10px; border: 2px solid #444; background: lightgoldenrodyellow;">No workflow configured!!!!</span>'}>
                                </AgGridReact>
                            </div>
                        </DialogContent>
                        <DialogActions>
                            {/* <Button variant="outlined" color="error" startIcon={<CancelOutlinedIcon />}
                                onClick={() => {
                                    if (!disableRegenerate && workflowUpdated) {
                                        props.loadPendingRequests();
                                    }
                                    setOpen(false);
                                }}>Close</Button> */}
                        </DialogActions>
                    </Box>
                </Rnd>
            </Popper>

            <ConfirmDialog
                open={openConfirmDialog}
                setOpenConfirmDialogHandler={setOpenConfirmDialog}
                title="Regenerate workflow"
                body="Select OK to terminate current workflow(s) and regenerate, else click CANCEL"
                okHandler={regenerateWorkflow}></ConfirmDialog>

            <ColumnSelection
                tablePreferenceList={tablePreferenceList}
                selectedPreference={selectedPreference}
                setselectedPreference={setselectedPreference}
                loggedInUserMailId={props.loggedInUserMailId}
                loggedInUser={props.loggedInUser}
                hiddenColumns={hideColumns}
                columnsPerfOpen={columnsPerfOpen}
                anchorEl={anchorEl}
                applyColumnPreferenceHandler={applyColumnPreferenceHandler}
                setcolumnsPerfOpen={setcolumnsPerfOpen}
                displayColumns={displayColumns}
            ></ColumnSelection>
        </React.Fragment >
    )
}

export default OrderApprovalRenderer;