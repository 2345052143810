import ViewColumnOutlinedIcon from '@mui/icons-material/ViewColumnOutlined';
import { Box, Checkbox, DialogActions, DialogTitle, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Popper, PopperPlacementType, TextField } from '@mui/material';
import React, { useEffect } from 'react';
import { useEffectOnce } from 'src/hooks/useEffectOnce';
import { CartContext } from "../../@State/context/CartContext";
import { CartType } from '../../@State/types';
import { debounce } from 'src/Utility/api';
import CancelIcon from '@mui/icons-material/Cancel';
import DoneIcon from '@mui/icons-material/Done';

function OperatorSelectorRenderer(props: any) {
    const [open, setOpen] = React.useState(false);
    const [placement, setPlacement] = React.useState<PopperPlacementType>('bottom-start');
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [columnSelection, setColumnSelection] = React.useState<any[]>([]);
    const [selectedColumns, SetSelectedColumns] = React.useState<string>(props.value ? props.value : "All");
    const [searchText, setSearchText] = React.useState<string>("");
    const [columnSelectionFiltered, setColumnSelectionFiltered] = React.useState<any[]>([]);

    useEffectOnce(() => {
        setTimeout(() => {
            loadCartInformation();
        }, 200);
    });

    useEffect(() => {
        if (searchText !== "") {
            const filteredColumnSelection = columnSelection.filter((ele) => {
                return ele.ColumnName.toLowerCase().includes(searchText.toLowerCase());
            });
            setColumnSelectionFiltered(filteredColumnSelection);
        } else {
            setColumnSelectionFiltered([]);
        }
    }, [searchText]);

    const handleClickOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
        setOpen(true);
    };

    const clearSelection = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
        setOpen(false);
    };

    const chooseSelection = (event: React.MouseEvent<HTMLButtonElement>) => {
        props.onColumnSelected(getSelectedColumns(), props.rowIndex, props.data)
        SetSelectedColumns(getSelectedColumns());
        setAnchorEl(event.currentTarget);
        setOpen(false);
    };

    const setColumnPreferenceHandler = (columnPerferenceObj: any, isEnable: boolean) => {
        let _columnPreference = [...columnSelection];
        _columnPreference[_columnPreference.indexOf(columnPerferenceObj)].Enable = isEnable;
        setColumnSelection(_columnPreference);
    }

    const getSelectedColumns = () => {
        if (columnSelection.length > 0) {
            const selectedColumns = columnSelection.filter((ele: any) => { return ele.Enable });
            if (selectedColumns.length > 0) {
                const columnsNames = selectedColumns.map((ele: any) => { return ele.ColumnName });
                return columnsNames.toString();
            } else {
                return "All";
            }
        } else {
            return "All";
        }
    }

    const loadCartInformation = () => {
        let columnPreference: any[] = [];

        props.operatorData.forEach((element: any) => {
            if (props.value?.includes(element.ShortName)) {
                columnPreference.push({
                    ColumnName: element.ShortName,
                    Enable: true
                });
            } else {
                columnPreference.push({
                    ColumnName: element.ShortName,
                    Enable: false
                });
            }
        });
        setColumnSelection(columnPreference);
    }


    return (
        <div style={{ cursor: 'pointer' }}>
            <div>
                <span style={{float:"left"}} title={selectedColumns}>{selectedColumns?.substring(0, 8) + '...'}</span>
                <IconButton id="operator-data" style={{ float: 'right' }} color="primary" size="small"
                    onClick={handleClickOpen} aria-label="data">
                    <ViewColumnOutlinedIcon fontSize="small" />
                </IconButton>
            </div>
            <Popper open={open} placement={placement} anchorEl={anchorEl} style={{ zIndex: '10000' }}>
                <Box sx={{ border: '2px solid #bfbfbf', p: 1, bgcolor: 'background.paper' }}>
                    <DialogTitle>Selection</DialogTitle>
                    <TextField
                        label="Search"
                        id="columnselection-search-text-box"
                        value={searchText}
                        sx={{ width: '100%' }}
                        size="small"
                        onChange={(ele) => { debounce(setSearchText(ele.target.value)) }}
                    />
                    <Box sx={{ border: '1px solid #bfbfbf', p: 1, bgcolor: 'background.paper' }} style={{ height: '25vh', width: '100%' }} className="ag-theme-material">
                        <List sx={{ width: '100%', maxWidth: 360, maxHeight: '25vh', overflow: 'scroll', bgcolor: 'background.paper' }}>
                            {columnSelection.map((column: any) => {
                                const labelId = `checkbox-list-label-${column.ColumnName}`;
                                if (columnSelectionFiltered.length === 0
                                    || columnSelectionFiltered
                                        .map((ele: any) => { return ele.ColumnName.toLowerCase() })
                                        .includes(column.ColumnName.toLowerCase())) {
                                    return (
                                        <ListItem
                                            key={column.ColumnName}
                                            secondaryAction={
                                                <IconButton id="comments" edge="end" aria-label="comments">
                                                </IconButton>
                                            }
                                            disablePadding>
                                            <ListItemButton role={undefined} dense onClick={() => { setColumnPreferenceHandler(column, !column.Enable) }}>
                                                <ListItemIcon>
                                                    <Checkbox
                                                        style={{ padding: '0px' }}
                                                        edge="start"
                                                        checked={column.Enable}
                                                        tabIndex={-1}
                                                        disableRipple
                                                        inputProps={{ 'aria-labelledby': labelId }}
                                                    />
                                                </ListItemIcon>
                                                <ListItemText id={labelId} primary={column.ColumnName} />
                                            </ListItemButton>
                                        </ListItem>
                                    );
                                }

                            })}
                        </List>
                    </Box>
                    <DialogActions>
                        <IconButton id="operator-success" color="success" aria-label="done" onClick={chooseSelection}>
                            <DoneIcon />
                        </IconButton>
                        <IconButton id="cancel" color="error" aria-label="cancel" onClick={clearSelection}>
                            <CancelIcon />
                        </IconButton>
                    </DialogActions>
                </Box>
            </Popper>
        </div>
    )
}

export default OperatorSelectorRenderer;