import React, { useContext, useEffect, useRef } from "react";

import {
  MarkerClusterer,
  Autocomplete,
  DrawingManager,
} from "@react-google-maps/api";
import { Cluster, Clusterer } from "@react-google-maps/marker-clusterer";
import GoogleMap from "../Map/Map";
import MapGrid from "./MapGrid/MapGrid";
import {
  Alert,
  AlertTitle,
  Button,
  Chip,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  Paper,
  Snackbar,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CustomMarker from "../Map/CustomMarker";
import NodeInfo from "../TableView/NodeInfo";
import { ExchangeOrderType, MapViewType } from "../../@State/types";
import { MapViewContext } from "../../@State/context/MapViewContext";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { AppContext } from "../../@State/context/AppContext";
import CountySelection from "../CountySelection/CountySelection";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import MonetizationOnOutlinedIcon from "@mui/icons-material/MonetizationOnOutlined";
import * as api from "src/Utility/api";
import "./MapView.css";
import ReportSelectionMapDollarAmounts from "./ReportSelectionMapDollarAmounts/ReportSelectionMapDollarAmounts";

import UnfoldLessOutlinedIcon from "@mui/icons-material/UnfoldLessOutlined";
import UnfoldMoreOutlinedIcon from "@mui/icons-material/UnfoldMoreOutlined";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import { debounce } from "src/Utility/api";
import { useEffectOnce } from "src/hooks/useEffectOnce";
import MapFilter from "../MapFilter/MapFilter";
import { generateKey, getMatch } from "src/Utility/common";

function MapView(props: any) {
  const [locs, setLocs] = React.useState<any>([]);
  const [map, setMap] = React.useState<any>(null);
  const [currentMapNodeData, setCurrentMapNodeData] = React.useState<any>(null);
  const [mapWidth, setMapWidth] = React.useState<number>(12);
  const [infoWindow, setInfoWindow] = React.useState<any>();
  const [openClusterInfo, setOpenClusterInfo] = React.useState(false);
  const [nodeInfoViewWidth, setnodeInfoViewWidth] = React.useState<number>(0);
  const [mapDataGridRef, setMapDataGridRef] = React.useState<any>(null);
  const [filtersAppliedReports, setFiltersAppliedReports] = React.useState<
    any[]
  >([]);
  const [filtersApplied, setFiltersApplied] = React.useState<any[]>([]);
  const [currentMarkerDataAuditId, setCurrentMarkerDataAuditId] =
    React.useState<any>(null);
  const [addedToCartDataAuditId, setAddedToCartDataAuditId] = React.useState<
    any[]
  >([]);

  const [wellsInOrder, setWellsInOrder] = React.useState<any[]>([]);
  const [selectedOrderId, setSelectedOrderId] = React.useState<string>("");
  const [selectedOperator, setSelectedOperator] = React.useState<string>("");
  const [isAddWells, setIsAddWells] = React.useState<boolean>(false);
  const [isAddExchangeWells, setIsAddExchangeWells] =
    React.useState<boolean>(false);
  const [currentMapNodeInCart, setCurrentMapNodeInCart] =
    React.useState<boolean>(false);
  const [message, setMessageInfo] = React.useState<string>(
    "Data added to Cart Successfully."
  );
  const [qualityRatings, setQualityRatings] = React.useState<any[]>([]);
  const [dollarValues, setDollarValues] = React.useState<any[]>([]);
  const [oppositeTradeOrderDollarValues, setOppositeTradeOrderDollarValues] =
    React.useState<any[]>([]);

  const [isClusteringEnabled, setIsClusteringEnabled] =
    React.useState<boolean>(true);
  const [isMapIconEnabled, setIsMapIconEnabled] = React.useState<boolean>(true);
  const [isNodeInfoEnabled, setIsNodeInfoEnabled] =
    React.useState<boolean>(false);

  const [polygonInfo, setPolygonInfo] = React.useState<any>(null);
  const [circleInfo, setCircleInfo] = React.useState<any>(null);
  const [reactangleInfo, setReactangleInfo] = React.useState<any>(null);
  const [orderReportSelectionOrder1, setOrderReportSelectionOrder1] =
    React.useState<any[]>([]);
  const [orderReportSelectionOrder2, setOrderReportSelectionOrder2] =
    React.useState<any[]>([]);
  const [estimateOrderReports, setEstimateOrderReports] = React.useState<any[]>(
    []
  );
  const [estimateOrderReportsBothSides, setEstimateOrderReportsBothSides] =
    React.useState<any[]>([]);

  const [expandAll, setExpandAll] = React.useState<boolean>(true);
  const [openSaveFilter, setOpenSaveFilter] = React.useState(false);
  const [filterName, setFilterName] = React.useState<string>("");
  const [wellsRestriction, setWellsRestriction] = React.useState<number>(500);

  const [filterModelSelection, setFilterModelSelection] = React.useState<any[]>([]);
  const [filterAlignment, setFilterAlignment] = React.useState("OR");

  const drawingManagerRef: any = useRef();
  const locationSearchBox: any = useRef();

  const { loadInitialPrerequisiteData, mapFilters } = useContext(
    MapViewContext
  ) as MapViewType;

  const {
    exchangeOrder,
    modifiedOrder,
    orderWells,
    reportGroups,
    oppositeTradeWells,
    tradeOrderFromTo,
    oppositeTradeOrderFromTo,
    isMapViewDollar,
    orderReports,
    setIsInfoColumnsOperator,
    setOrderWells,
    setOppositeTradeWells,
    setIsMapViewDollar,
    fetchReportDisplayName,
    loadInitialMapData,
    fetchCommonAppConfigHandler,
    countyKmlFiles,
    dataAuditData,
    dataAuditDataImmutable,
    setDataAuditData,
    reportConfigurations,
    CommonAppConfig,
    reportGroupInfo,
  } = React.useContext(AppContext) as ExchangeOrderType;

  const [showDollarValues, setShowDollarValues] =
    React.useState<boolean>(false);

  useEffectOnce(() => {
    loadInitialPrerequisiteData();
    fetchCommonAppConfigHandler();
  });

  useEffect(() => {
    if (mapDataGridRef !== null) {
      setMapLocs();
    }
  }, [mapDataGridRef, dataAuditData]);

  useEffect(() => {
    if (CommonAppConfig !== null && CommonAppConfig.length > 0) {
      let operators = sessionStorage.getItem("Operators");
      let noOfWells = CommonAppConfig.find((temp: any) => {
        return (
          temp.ConfigShortDesc === "NoOfWells" &&
          temp.Operators.includes(operators)
        );
      });

      if (noOfWells) {
        setWellsRestriction(noOfWells.ConfigValue);
      }
    }
  }, [CommonAppConfig]);

  useEffect(() => {
    if (currentMapNodeData !== null) {
      getQualityRating();
    }
  }, currentMapNodeData);

  useEffect(() => {
    if (exchangeOrder?.requestedTo !== undefined) {
      restoreFromHardCoded([
        exchangeOrder.requestedTo,
        exchangeOrder.requestedBy,
      ]);
    }
  }, [exchangeOrder]);

  useEffect(() => {
    if (modifiedOrder?.requestedTo !== undefined) {
      restoreFromHardCoded([modifiedOrder.requestedTo]);
    }
  }, [modifiedOrder]);

  useEffect(() => {
    if (orderWells.length > 0) {
      //ClearFilter
      if (polygonInfo !== null) {
        polygonInfo?.setMap(null);
        setPolygonInfo(null);
      }
      filtersApplied.forEach((filter: any) => {
        clearFilter(filter);
      });

      props.setCartInformation([]);
      const wellApis = orderWells.map((element) => {
        return element?.API;
      });
      setSelectedOrderId(orderWells[0].orderId);
      setSelectedOperator(orderWells[0].selectedOperator);
      setIsAddWells(orderWells[0].addWells ? true : false);
      setIsAddExchangeWells(orderWells[0].addExchangeWells ? true : false);
      setWellsInOrder(wellApis);

      const _wellsInCart = orderWells.map((element) => {
        return element.DataAuditId;
      });
      if (_wellsInCart.length > 0) {
        setAddedToCartDataAuditId(_wellsInCart);
      }

      // Set the Filter for Map view after old filter is cleared
      if (exchangeOrder?.requestedTo !== undefined) {
        restoreFromHardCoded([
          exchangeOrder.requestedTo,
          exchangeOrder.requestedBy,
        ]);
      }

      if (modifiedOrder?.requestedTo !== undefined) {
        restoreFromHardCoded([modifiedOrder.requestedTo]);
      }
    }
  }, [orderWells]);

  useEffect(() => {
    if (
      wellsInOrder.length > 0 &&
      isAddWells == false &&
      isAddExchangeWells == false
    ) {
      setAPIFilterModel(wellsInOrder);
      getDollarValue();
      if (oppositeTradeWells.length > 0) {
        getOppositeSideDollarValue();
      }
    }
  }, [wellsInOrder]);

  useEffect(() => {
    if (polygonInfo !== null) {
      let bounds = new google.maps.LatLngBounds();
      for (let i = 0; i < polygonInfo.getPath().getLength(); i++) {
        bounds.extend(polygonInfo.getPath().getAt(i));
      }
      map.fitBounds(bounds);
    }
  }, [polygonInfo]);

  useEffect(() => {
    if (circleInfo !== null) {
      map.fitBounds(circleInfo.getBounds());
    }
  }, [circleInfo]);

  useEffect(() => {
    if (reactangleInfo !== null) {
      map.fitBounds(reactangleInfo.getBounds());
    }
  }, [reactangleInfo]);

  const setMapLocs = () => {
    let locations: any[] = [];
    const _rowsToDisplay = mapDataGridRef.current?.api
      ?.getModel()
      .rowsToDisplay.map((element: any) => {
        return element.data;
      });

    _rowsToDisplay.forEach((ele: any) => {
      if (
        ele.Longitude !== null &&
        ele.Longitude !== undefined &&
        ele.Latitude !== null &&
        ele.Latitude !== undefined
      ) {
        locations.push({
          lat: parseFloat(ele.Longitude),
          lng: parseFloat(ele.Latitude),
          nodeData: ele,
        });
      }
    });

    // This code is responsible for allways showing the data owner's wells //
    if (isAddExchangeWells || isAddWells) {
      const _alwaysVisibleWells = dataAuditData.filter((element) => {
        return wellsInOrder.includes(element.API);
      });
      _alwaysVisibleWells.forEach((element: any) => {
        const _matchFound = _rowsToDisplay.filter((ele: any) => {
          return ele.API === element.API;
        });
        if (
          _matchFound.length === 0 &&
          element.Longitude !== null &&
          element.Longitude !== undefined &&
          element.Latitude !== null &&
          element.Latitude !== undefined
        ) {
          locations.push({
            lat: parseFloat(element.Longitude),
            lng: parseFloat(element.Latitude),
            nodeData: element,
          });
        }
      });
    }

    setLocs(locations);
  };

  const handleMapInfoWindowOpenClose = (isOpen: boolean) => {
    if (isOpen) {
      if (currentMapNodeData !== null) {
        getQualityRating();
      }
    } else {
      setMapWidth(12);
      setnodeInfoViewWidth(0);
    }
  };

  const getQualityRating = async () => {
    if (currentMapNodeData !== null) {
      await api
        .API_GET("/Report/GetReportRatingByAPI?api=" + currentMapNodeData.API)
        .then((response) => {
          if (response.data.ReportRatings.length > 0) {
            setQualityRatings(response.data.ReportRatings);
          }

          setMapWidth(7.5);
          setnodeInfoViewWidth(4.5);
        })
        .catch((ex: any) => {
          console.log(ex);
        });
    }
  };

  const getDollarValue = async () => {
    let _wellsInOrder = [...wellsInOrder];
    if (oppositeTradeWells.length > 0) {
      _wellsInOrder = wellsInOrder.filter((element) => {
        return !oppositeTradeWells.includes(element);
      });
    }
    await api
      .API_GET(
        "/Report/GetReportAmountByAPIs?apis=" +
          _wellsInOrder.toString() +
          "" +
          "&orderid=" +
          tradeOrderFromTo.OrderId +
          "" +
          "&requestedByOperator=" +
          tradeOrderFromTo.OrderBy +
          "" +
          "&requestedToOperator=" +
          tradeOrderFromTo.OrderTo +
          ""
      )
      .then((response) => {
        if (response.data.length > 0) {
          setDollarValues(response.data);
        }
      })
      .catch((ex: any) => {
        console.log(ex);
      });
  };

  const getOppositeSideDollarValue = async () => {
    await api
      .API_GET(
        "/Report/GetReportAmountByAPIs?apis=" +
          oppositeTradeWells.toString() +
          "" +
          "&orderid=" +
          oppositeTradeOrderFromTo.OrderId +
          "" +
          "&requestedByOperator=" +
          oppositeTradeOrderFromTo.OrderBy +
          "" +
          "&requestedToOperator=" +
          oppositeTradeOrderFromTo.OrderTo +
          ""
      )
      .then((response) => {
        if (response.data.length > 0) {
          setOppositeTradeOrderDollarValues(response.data);
        }
      })
      .catch((ex: any) => {
        console.log(ex);
      });
  };

  const setMapDataGridRefHandler = (gridRef: any) => {
    setMapDataGridRef(gridRef);
  };

  const onFilterChangedHandler = (reportGroupInfo: any) => {
    setMapLocs();
    printFilterModel();
    //checkAllChildReportsUnderParentHandler(reportGroupInfo);
  };

  const checkAllChildReportsUnderParentHandler = (reportGroupInfo: any) => {
    let _reportParentName = "";
    const filterModel = Object.keys(
      mapDataGridRef.current.api.getFilterModel()
    );
    let existingModel = mapDataGridRef.current.api.getFilterModel();
    filterModel.forEach((filtermodelObj: any) => {
      const reportObj = reportGroupInfo.filter((element: any) => {
        return element.ReportName == filtermodelObj;
      });
      if (reportObj) {
        const reportParent = reportObj[0]?.ReportParentName;
        if (_reportParentName !== reportObj[0]?.ReportParentName) {
          //Need not repeate for some parent node again//
          _reportParentName = reportParent;
          if (reportParent && reportParent !== null && reportParent !== "") {
            const reportParentObj = reportGroupInfo.filter((element: any) => {
              return element.ReportParentName == reportParent;
            });
            if (reportParentObj && reportParentObj !== null) {
              let matchReports = getMatch(
                reportParentObj.map((element: any) => {
                  return element.ReportName;
                }),
                Object.keys(mapDataGridRef.current.api.getFilterModel())
              );
              if (matchReports.length == 1) {
                reportParentObj.forEach((childReports: any) => {
                  existingModel[childReports.ReportName] = {
                    values: ["1"],
                    filterType: "set",
                  };
                });
              }
            }
          }
        }
      }
    });

    mapDataGridRef.current.api.setFilterModel(existingModel);
  };

  const onAddtoCart = (nodeData?: any) => {
    let selectedRows = [nodeData];
    let uniqueRows: any[] = [];
    let _cartInformation = props.cartInformation
      ? [...props.cartInformation]
      : [];
    let matchingObject = props.cartInformation?.filter((info: any) => {
      return info.schemaName === "DataAudit" && info.tableName === "DATA_AUDIT";
    });

    let isWellsAddedtoCart: boolean = false;
    let isWellExistInOrder: boolean = false;
    let isWellCountExceeding: boolean = false;

    // Add Wells to Existing Order
    if (selectedOrderId != "" && wellsInOrder.length > 0) {
      selectedRows.forEach((rowInfo: any) => {
        const api = rowInfo["API"];
        if (wellsInOrder.includes(api)) {
          isWellExistInOrder = true;
        } else {
          uniqueRows.push(rowInfo);
        }
      });
    }

    if (!isWellExistInOrder) {
      if (matchingObject && matchingObject.length > 0) {
        let matchingObjectIndex = props.cartInformation?.indexOf(
          matchingObject[0]
        );
        let _currentObject = { ...matchingObject[0] };
        let _currentData: any[] = [];
        _currentData = [..._currentObject.dataRows];
        let _currentDataMap = _currentObject.dataMap;
        let _currentDataIds: any[] = [];
        let _currentNodesToCart: any[] = [];

        _currentDataIds = _currentData.map((ele) => {
          return ele["DataAuditId"];
        });
        let wellsCount = 0;
        let dataRows = uniqueRows.length > 0 ? uniqueRows : selectedRows;

        let wellsOperator = dataRows.length > 0 ? dataRows[0]["Operator"] : "";
        if (_currentDataMap.get(wellsOperator)) {
          wellsCount =
            _currentDataMap.get(wellsOperator)?.length + dataRows.length;
        } else {
          wellsCount = dataRows.length;
        }

        wellsCount =
          wellsCount +
          (exchangeOrder?.requestedTo !== undefined ? 0 : wellsInOrder.length);

        isWellCountExceeding = wellsCount > wellsRestriction;

        if (!isWellCountExceeding) {
          dataRows.forEach((rowInfo: any) => {
            const rowId = rowInfo["DataAuditId"];
            if (!_currentDataIds.includes(rowId)) {
              _currentData.push(rowInfo);
              _currentNodesToCart.push(rowId);
              if (_currentDataMap.get(rowInfo["Operator"])) {
                _currentDataMap.get(rowInfo["Operator"])?.push(rowInfo);
              } else {
                let _rowData: any[] = [rowInfo];
                _currentDataMap.set(rowInfo["Operator"], _rowData);
              }
            }
          });

          const nodeDataAuditId = nodeData["DataAuditId"];
          if (!addedToCartDataAuditId.includes(nodeDataAuditId)) {
            setAddedToCartDataAuditId([
              ...addedToCartDataAuditId,
              nodeDataAuditId,
            ]);
          }

          _currentObject.dataMap = _currentDataMap;
          _currentObject.dataRows = _currentData;
          if (exchangeOrder?.requestedTo !== undefined) {
            _currentObject.isExchange = true;
          }
          if (modifiedOrder?.requestedTo !== undefined) {
            _currentObject.isModified = true;
          }
          if (
            _currentObject.operatorFilter.filter((element: any) => {
              return element.operator === dataRows[0].Operator;
            }).length > 0
          ) {
            _currentObject.operatorFilter.filter((element: any) => {
              return element.operator === dataRows[0].Operator;
            })[0].filterApplied = filtersAppliedReports;
          } else {
            _currentObject.operatorFilter.push({
              operator: dataRows[0].Operator,
              filterApplied: filtersAppliedReports,
            });
          }

          if (matchingObjectIndex !== undefined) {
            _cartInformation.splice(matchingObjectIndex, 1, _currentObject);
            props.setCartInformation(_cartInformation);
            isWellsAddedtoCart = true;
          }
        }
      } else {
        let wellsCount =
          (exchangeOrder?.requestedTo !== undefined ? 0 : wellsInOrder.length) +
          (uniqueRows.length > 0 ? uniqueRows.length : selectedRows.length);
        isWellCountExceeding = wellsCount > wellsRestriction;
        if (selectedRows.length > 0 && !isWellCountExceeding) {
          let dataRows = uniqueRows.length > 0 ? uniqueRows : selectedRows;

          _cartInformation.push({
            schemaName: "DataAudit",
            tableName: "DATA_AUDIT",
            dataRows: dataRows,
            dataMap: groupBy(dataRows, (x: any) => x.Operator),
            isExchange: exchangeOrder?.requestedTo !== undefined,
            isModified: modifiedOrder?.requestedTo !== undefined,
            operatorFilter: [
              {
                operator: dataRows[0].Operator,
                filterApplied: filtersAppliedReports,
              },
            ],
          });

          const _addedToCartDataAuditIds = dataRows.map((element) => {
            return element.DataAuditId;
          });
          setAddedToCartDataAuditId(_addedToCartDataAuditIds);
          props.setCartInformation(_cartInformation);
          isWellsAddedtoCart = true;
        }
      }

      if (isWellCountExceeding) {
        setMessageInfo(
          "Cannot add more than " + wellsRestriction + " Wells to the Order."
        );
      } else if (isWellsAddedtoCart) {
        setMessageInfo("Data added to Cart Successfully.");
        api.LOG_MESSAGE(
          ["Page : Map View ", "Action : Data added to Cart Successfully"],
          { UserName: props.loggedInUser }
        );
      }

      setCurrentMapInTheNode();
      setOpenClusterInfo(true);
    } else {
      setMessageInfo("Selected Well already exists in the Order");
      setOpenClusterInfo(true);
    }
  };

  const addToCartCluster = (nodes: any[]) => {
    let selectedRows = [...nodes];
    let uniqueRows: any[] = [];
    let _cartInformation = props.cartInformation
      ? [...props.cartInformation]
      : [];
    let matchingObject = props.cartInformation?.filter((info: any) => {
      return info.schemaName === "DataAudit" && info.tableName === "DATA_AUDIT";
    });
    let isWellExistInOrder: boolean = false;
    let isWellsAddedtoCart: boolean = false;
    let isWellCountExceeding: boolean = false;

    if (selectedOrderId != "" && wellsInOrder.length > 0) {
      selectedRows.forEach((rowInfo: any) => {
        const api = rowInfo["API"];
        const operator = rowInfo["Operator"];
        if (operator === selectedOperator) {
          if (wellsInOrder.includes(api)) {
            isWellExistInOrder = true;
          } else {
            uniqueRows.push(rowInfo);
          }
        }
      });
    }

    if (matchingObject && matchingObject.length > 0) {
      let matchingObjectIndex = props.cartInformation?.indexOf(
        matchingObject[0]
      );
      let _currentObject = { ...matchingObject[0] };
      let _currentData: any[] = [];
      _currentData = [..._currentObject.dataRows];
      let _currentDataMap = _currentObject.dataMap;
      let _currentDataIds: any[] = [];
      let _currentNodesToCart: any[] = [];

      _currentDataIds = _currentData.map((ele) => {
        return ele["DataAuditId"];
      });

      let wellsCount = 0;

      let dataRows = !isWellExistInOrder ? selectedRows : uniqueRows;

      let wellsOperator = dataRows.length > 0 ? dataRows[0]["Operator"] : "";
      if (_currentDataMap.get(wellsOperator)) {
        wellsCount =
          _currentDataMap.get(wellsOperator)?.length + dataRows.length;
      } else {
        wellsCount = dataRows.length;
      }

      wellsCount =
        wellsCount +
        (exchangeOrder?.requestedTo !== undefined ? 0 : wellsInOrder.length);

      isWellCountExceeding = wellsCount > wellsRestriction;
      if (!isWellCountExceeding) {
        if (dataRows.length > 0) {
          dataRows.forEach((rowInfo: any) => {
            const rowId = rowInfo["DataAuditId"];
            if (!_currentDataIds.includes(rowId)) {
              _currentData.push(rowInfo);
              _currentNodesToCart.push(rowId);
              if (_currentDataMap.get(rowInfo["Operator"])) {
                _currentDataMap.get(rowInfo["Operator"])?.push(rowInfo);
              } else {
                let _rowData: any[] = [rowInfo];
                _currentDataMap.set(rowInfo["Operator"], _rowData);
              }
            }
          });

          setAddedToCartDataAuditId([...addedToCartDataAuditId, ...nodes]);

          _currentObject.dataMap = _currentDataMap;
          _currentObject.dataRows = _currentData;
          if (exchangeOrder?.requestedTo !== undefined) {
            _currentObject.isExchange = true;
          }
          if (modifiedOrder?.requestedTo !== undefined) {
            _currentObject.isModified = true;
          }

          if (
            _currentObject.operatorFilter.filter((element: any) => {
              return element.operator === dataRows[0].Operator;
            }).length > 0
          ) {
            _currentObject.operatorFilter.filter((element: any) => {
              return element.operator === dataRows[0].Operator;
            })[0].filterApplied = filtersAppliedReports;
          } else {
            _currentObject.operatorFilter.push({
              operator: dataRows[0].Operator,
              filterApplied: filtersAppliedReports,
            });
          }

          if (matchingObjectIndex !== undefined) {
            isWellsAddedtoCart = true;
            _cartInformation.splice(matchingObjectIndex, 1, _currentObject);
            props.setCartInformation(_cartInformation);
          }
        }
      }
    } else {
      let wellsCount =
        (exchangeOrder?.requestedTo !== undefined ? 0 : wellsInOrder?.length) +
        (!isWellExistInOrder ? selectedRows.length : uniqueRows.length);
      let dataRows = !isWellExistInOrder ? selectedRows : uniqueRows;
      isWellCountExceeding = wellsCount > wellsRestriction;
      if (dataRows.length > 0 && !isWellCountExceeding) {
        if (modifiedOrder?.requestedTo !== undefined) {
          if (dataRows[0].Operator === modifiedOrder.requestedTo) {
            isWellsAddedtoCart = true;
            _cartInformation.push({
              schemaName: "DataAudit",
              tableName: "DATA_AUDIT",
              dataRows: dataRows,
              dataMap: groupBy(dataRows, (x: any) => x.Operator),
              isExchange: exchangeOrder?.requestedTo !== undefined,
              isModified: modifiedOrder?.requestedTo !== undefined,
              operatorFilter: [
                {
                  operator: dataRows[0].Operator,
                  filterApplied: filtersAppliedReports,
                },
              ],
            });
          }
        } else {
          isWellsAddedtoCart = true;
          _cartInformation.push({
            schemaName: "DataAudit",
            tableName: "DATA_AUDIT",
            dataRows: dataRows,
            dataMap: groupBy(dataRows, (x: any) => x.Operator),
            isExchange: exchangeOrder?.requestedTo !== undefined,
            isModified: modifiedOrder?.requestedTo !== undefined,
            operatorFilter: [
              {
                operator: dataRows[0].Operator,
                filterApplied: filtersAppliedReports,
              },
            ],
          });
        }

        const _addedToCartDataAuditIds = dataRows.map((element) => {
          return element.DataAuditId;
        });
        setAddedToCartDataAuditId(_addedToCartDataAuditIds);
        props.setCartInformation(_cartInformation);
      }
    }

    if (isWellCountExceeding) {
      setMessageInfo(
        "Cannot add more than " + wellsRestriction + " Wells to the Order."
      );
    } else if (isWellExistInOrder && uniqueRows.length == 0) {
      setMessageInfo("Selected Wells are already added to the Order.");
    } else {
      if (isWellsAddedtoCart) {
        setMessageInfo("Data added to Cart Successfully.");
        api.LOG_MESSAGE(
          ["Page : Map View ", "Action : Data added to Cart Successfully"],
          { UserName: props.loggedInUser }
        );
      }
    }

    setOpenClusterInfo(true);
  };

  const setMarkerClusterTitle = (ele: any) => {
    const nodeIds: any[] = ele.getMarkers().map((marker: any) => {
      return marker.getTitle();
    });
    const pkcolName = "DataAuditId";
    const nodeDatas = dataAuditData.filter((data: any) => {
      return nodeIds.includes(data[pkcolName].toString());
    });
    let Operators: string[] = [];
    nodeDatas.forEach((ele: any) => {
      if (!Operators.includes(ele.Operator)) {
        Operators.push(ele.Operator);
      }
    });
    let infoWindowNode = document.createElement("div");
    let table = document.createElement("table");
    let node = document.createElement("div");
    Operators.forEach((operator: any) => {
      const wells = nodeDatas.filter((ele) => {
        return ele.Operator === operator;
      });

      let button = document.createElement("Button");
      button.title = "Add to cart";
      button.innerHTML = "Add to cart";
      button.onclick = () => {
        addToCartCluster(wells);
      };
      let aTag = document.createElement("a");
      aTag.setAttribute("href", "#");
      aTag.innerHTML = operator + " (" + wells.length + ")";

      let tr = document.createElement("tr");
      let td1 = document.createElement("td");
      let td2 = document.createElement("td");
      td1.appendChild(aTag);
      td2.appendChild(button);
      td2.className = "FloatRight";
      tr.appendChild(td1);
      tr.appendChild(td2);
      table.appendChild(tr);

      aTag.onclick = () => {
        restoreFromHardCoded([operator]);
      };
    });
    node.appendChild(table);
    infoWindowNode.appendChild(node);

    handleOpenInfoWindow(ele, infoWindowNode, nodeDatas);
  };

  const restoreFromHardCoded = (operator: any[]) => {
    let existingModel = mapDataGridRef?.current?.api?.getFilterModel();
    existingModel.Operator = {
      filterModels: [
        null,
        {
          values: [...operator],
          filterType: "set",
        },
      ],
      filterType: "multi",
    };
    mapDataGridRef?.current?.api.setFilterModel(existingModel);
  };

  const handleOpenInfoWindow = (
    cluster: any,
    content: HTMLDivElement,
    nodeDatas: any[]
  ) => {
    if (infoWindow) {
      infoWindow.setContent(content);
      infoWindow.setPosition(cluster.getCenter());
      infoWindow.open(cluster.map);
    }
  };

  const handleCloseInfoWindow = (cluster: Cluster) => {
    if (infoWindow) {
      setTimeout(() => {
        infoWindow.close();
      }, 7000);
    }
  };

  const printFilterModel = () => {
    let filterModel = mapDataGridRef.current.api.getFilterModel();
    setFiltersApplied(Object.keys(filterModel));
    setReportFiltersApplied(filterModel);
    checkIfInfoColumnsNeeded(filterModel);
  };

  const checkIfInfoColumnsNeeded = (filterModel: any) => {
    let isOperatorFilter = Object.keys(filterModel).includes("Operator");
    let operatorName = filterModel["Operator"]?.filterModels[1]?.values;
    if (isOperatorFilter && operatorName.length === 1) {
      setIsInfoColumnsOperator(operatorName);
    } else {
      setIsInfoColumnsOperator(operatorName);
    }
  };

  const setReportFiltersApplied = (filterModel: any[]) => {
    //Check if its a report filter by checking for boolean values

    let filterReports: string[] = [];
    filterReports = Object.keys(filterModel).filter((element: any) => {
      const _values =
        filterModel[element].filterType == "set"
          ? filterModel[element]?.values
          : filterModel[element]?.filterModels[1]?.values;
      const filterValues = _values ? _values[_values.length - 1] : [];
      return (
        (filterValues?.includes("1") || filterValues?.includes("0")) &&
        element.toLowerCase().includes("_yn")
      );
    });
    setFiltersAppliedReports(filterReports);
  };

  function groupBy<K, V>(array: V[], grouper: (item: V) => K) {
    return array.reduce((store, item) => {
      let key = grouper(item);
      if (!store.has(key)) {
        store.set(key, [item]);
      } else {
        store.get(key)?.push(item);
      }
      return store;
    }, new Map<K, V[]>());
  }

  function clearFilter(filterName: any) {
    setOrderWells([]);
    setWellsInOrder([]);
    setEstimateOrderReports([]);
    setIsAddWells(false);
    setIsAddExchangeWells(false);
    setShowDollarValues(false);
    setIsMapViewDollar(false);
    setAddedToCartDataAuditId([]);
    setCurrentMapNodeData(null);

    let FilterComponent =
      mapDataGridRef.current.api.getFilterInstance(filterName);
    FilterComponent.setModel(null);
    if (showOrderId()) {
      setAddedToCartDataAuditId([]);
    }
    if (oppositeTradeWells.length > 0) {
      setOppositeTradeWells([]);
    }

    mapDataGridRef.current.api.onFilterChanged();
  }

  const scollToFilter = (filterName: any) => {
    Array.prototype.slice
      .call(document.getElementsByClassName("ag-filter-toolpanel-group-title"))
      .filter((element) => {
        return element.innerHTML === filterName.toUpperCase();
      })[0]
      ?.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "nearest",
      });
  };

  const setCurrentMapInTheNode = () => {
    setCurrentMapNodeInCart(true);
  };

  const setAPIFilterModel = (Apis: any) => {
    let existingModel = mapDataGridRef.current.api.getFilterModel();
    existingModel.API = {
      filterModels: [
        null,
        {
          values: [...Apis],
          filterType: "set",
        },
      ],
      filterType: "multi",
    };
    mapDataGridRef.current.api.setFilterModel(existingModel);
  };

  const showDollarValuesHandler = () => {
    return isMapViewDollar ? isMapViewDollar : showDollarValues;
  };

  const constructMapNodes = (clusterer?: Clusterer) => {
    const _markers = locs.map((loc: any, index: number) => (
      <CustomMarker
        key={"mark-" + index}
        position={loc}
        isMapIconEnabled={isMapIconEnabled}
        clusterer={clusterer ? clusterer : null}
        nodeData={loc.nodeData}
        currentMapNodeData={currentMapNodeData}
        addedToCartDataAuditId={addedToCartDataAuditId}
        wellsInOrder={wellsInOrder}
        oppositeTradeWells={oppositeTradeWells}
        primaryKeyColumnName={"DataAuditId"}
        currentMarkerDataAuditId={currentMarkerDataAuditId}
        setCurrentMapNodeInCart={setCurrentMapNodeInCart}
        setCurrentMarkerDataAuditId={setCurrentMarkerDataAuditId}
        setNodeData={setCurrentMapNodeData}
        handleMapInfoWindowOpenClose={handleMapInfoWindowOpenClose}
        onAddtoCart={onAddtoCart}
        noClustererRedraw={true}
        isAddWells={isAddWells}
        isExchangeAddWells={isAddExchangeWells}
        exchangeOrder={exchangeOrder}
        polygonInfo={polygonInfo}
        circleInfo={circleInfo}
        reactangleInfo={reactangleInfo}
        dollarValues={dollarValues}
        oppositeTradeOrderDollarValues={oppositeTradeOrderDollarValues}
        showDollarValues={showDollarValuesHandler()}
        selectedOperator={selectedOperator}
        tradeOrderFromTo={tradeOrderFromTo}
        oppositeTradeOrderFromTo={oppositeTradeOrderFromTo}
        orderReportSelectionOrder1={orderReportSelectionOrder1}
        orderReportSelectionOrder2={orderReportSelectionOrder2}
        isNodeInfoEnabled={isNodeInfoEnabled}
      />
    ));

    return _markers;
  };

  const constructMapClusters = () => {
    const clusters = (
      <MarkerClusterer
        minimumClusterSize={7}
        onMouseOver={(cluster) => setMarkerClusterTitle(cluster)}
        onMouseOut={(cluster) => handleCloseInfoWindow(cluster)}
      >
        {(clusterer) => constructMapNodes(clusterer)}
      </MarkerClusterer>
    );

    return clusters;
  };

  const showOrderId = () => {
    return filtersApplied.length > 0 && wellsInOrder.length > 0;
  };

  const resetMapHandler = () => {
    filtersApplied.forEach((filter: any) => {
      clearFilter(filter);
    });
    const defaultCenter = { lat: 39.8097343, lng: -98.5556199 };
    map.setZoom(5);
    map.setCenter(defaultCenter);

    if (isClusteringEnabled === false) {
      setIsClusteringEnabled(!isClusteringEnabled);
    }
    if (isMapIconEnabled === false) {
      setIsMapIconEnabled(!isMapIconEnabled);
    }
    if (polygonInfo !== null) {
      polygonInfo?.setMap(null);
      setPolygonInfo(null);
    }
    if (circleInfo !== null) {
      circleInfo?.setMap(null);
      setCircleInfo(null);
    }
    if (reactangleInfo !== null) {
      reactangleInfo?.setMap(null);
      setReactangleInfo(null);
    }

    handleMapInfoWindowOpenClose(false);

    setFilterModelSelection([]);
    setFilterAlignment("OR");

    mapDataGridRef?.current?.api?.setRowData(dataAuditDataImmutable);
    setDataAuditData(dataAuditDataImmutable);
  };

  const handleOpenSaveFilter = () => {
    setOpenSaveFilter(true);
  };

  const handleCloseSaveFilter = () => {
    setOpenSaveFilter(false);
  };

  const handleSaveMapFilter = () => {
    async function saveMapFilter(currentFilterModel: string) {
      await api
        .API_POST("/Common/SaveMapFilter", {
          filterName: filterName,
          operator: fetchUserOperator(),
          mailId: props.loggedInUserMailId,
          mapFilterJson: currentFilterModel,
          isPublic: true,
        })
        .then((response) => {
          loadInitialPrerequisiteData(); //To fetch newly added filter//
          setOpenSaveFilter(false);
          setMessageInfo("Filter ( " + filterName + " ) Save successfully.");
          setOpenClusterInfo(true);
        })
        .catch(() => {});
    }
    const currentFilterModel = mapDataGridRef.current?.api?.getFilterModel();
    if (
      Object.keys(currentFilterModel).length > 0 &&
      filterName.trim().length > 0
    ) {
      saveMapFilter(currentFilterModel);
    } else {
      alert("Please enter a filter name to save.");
    }
  };

  const fetchUserOperator = () => {
    let operatorName = "";
    if (
      props.userSecurityPermission &&
      props.userSecurityPermission.length > 0
    ) {
      const operators = props.userSecurityPermission[0].Operators;
      if (operators.length > 0) {
        operatorName = operators.split(",")[0];
      }
    }
    return operatorName;
  };

  return (
    <div id="mapview">
      <Grid container spacing={2}>
        <Grid
          item
          style={{ display: showOrderId() ? "block" : "none" }}
          xs={showOrderId() ? 3 : 0}
        >
          <Paper
            id="orderid"
            sx={{
              p: 1,
              display: "flex",
              flexDirection: "row",
              marginBottom: "10px",
              backgroundColor: "#e9ff9c",
              animation: "animate 3s linear infinite",
            }}
          >
            {oppositeTradeOrderFromTo.OrderBy == "" && (
              <Typography
                variant="h6"
                sx={{
                  color: "#505050",
                  textAlign: "center",
                  paddingRight: "10px",
                  fontSize: "1rem",
                  marginBottom: "0px",
                  width: "100%",
                }}
                gutterBottom
              >
                ORDER : {selectedOrderId}
              </Typography>
            )}
            {oppositeTradeOrderFromTo.OrderBy != "" && (
              <>
                <Typography
                  variant="h6"
                  sx={{
                    color: "#505050",
                    textAlign: "center",
                    paddingRight: "10px",
                    fontSize: "1rem",
                    marginBottom: "0px",
                  }}
                  gutterBottom
                >
                  ORDER :
                </Typography>
                <Chip
                  sx={{ backgroundColor: "#b5ebbb" }}
                  label={
                    tradeOrderFromTo.OrderId +
                    " (" +
                    tradeOrderFromTo.OrderBy +
                    ")"
                  }
                  variant="outlined"
                />
                <Typography
                  variant="h6"
                  sx={{
                    color: "#505050",
                    textAlign: "center",
                    paddingRight: "2px",
                    paddingLeft: "2px",
                    fontSize: "1rem",
                    marginBottom: "0px",
                  }}
                  gutterBottom
                >
                  -
                </Typography>
                <Chip
                  sx={{ backgroundColor: "#94c1fc" }}
                  label={
                    oppositeTradeOrderFromTo.OrderId +
                    " (" +
                    tradeOrderFromTo.OrderTo +
                    ")"
                  }
                  variant="outlined"
                />
              </>
            )}
          </Paper>
        </Grid>
        <Grid item xs={showOrderId() ? 9 : 12}>
          <Paper
            sx={{
              p: 1,
              display: filtersApplied.length > 0 ? "block" : "none",
              flexDirection: "row",
              marginBottom: "10px",
            }}
          >
            <Snackbar
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
              open={openClusterInfo}
              autoHideDuration={1200}
              onClose={() => {
                setOpenClusterInfo(false);
              }}
            >
              <p></p>
            </Snackbar>
            <Typography
              variant="h6"
              sx={{
                color: "#505050",
                paddingRight: "10px",
                fontSize: "1rem",
                marginBottom: "0px",
              }}
              gutterBottom
            >
              Filter :
            </Typography>
            {filtersApplied.map((filter: any, index: number) => {
              return (
                <Chip
                  key={"mapfilter" + index}
                  label={fetchReportDisplayName(filter)}
                  sx={{ marginRight: "5px" }}
                  variant="outlined"
                  onClick={() => {
                    scollToFilter(filter);
                  }}
                  onDelete={() => {
                    clearFilter(filter);
                  }}
                />
              );
            })}

            {!showOrderId() && (
              <Tooltip title="Save filter">
                <IconButton id="addtocart"
                  sx={{ padding: "4px" }}
                  color="success"
                  aria-label="Add to cart"
                  style={{
                    backgroundColor: "rgb(239 238 238)",
                    width: "fit-content",
                  }}
                  onClick={() => {
                    handleOpenSaveFilter();
                  }}
                >
                  <SaveOutlinedIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            )}
            <Dialog open={openSaveFilter} onClose={handleCloseSaveFilter}>
              <DialogTitle>Save Map Filter</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  To save this filter applied , please enter a name here. We
                  will save this for future use.
                </DialogContentText>
                <TextField
                  autoFocus
                  margin="dense"
                  id="name"
                  label="Filter name"
                  type="text"
                  fullWidth
                  variant="standard"
                  onChange={(ele) => {
                    debounce(setFilterName(ele.target.value));
                  }}
                />
              </DialogContent>
              <DialogActions>
                <Button id="cancel" onClick={handleCloseSaveFilter}>Cancel</Button>
                <Button id="save" onClick={handleSaveMapFilter}>Save</Button>
              </DialogActions>
            </Dialog>
          </Paper>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
      {filterModelSelection.length > 0 && <Grid item xs={12}>
          <Paper
            sx={{
              p: 1,
              display: filterModelSelection.length > 0 ? "block" : "none",
              flexDirection: "row",
              marginBottom: "10px",
            }}
          >
            <Typography
              variant="h6"
              sx={{
                color: "#505050",
                paddingRight: "10px",
                fontSize: "1rem",
                marginBottom: "0px",
              }}
              gutterBottom
            >
              Saved Filter Groups :
            </Typography>
            {filterModelSelection.map((filter: any, index: number) => {
              return (
                <>
                 <Chip
                  key={"mapfilter" + index}
                  label={filter.ColumnName}
                  sx={{ marginRight: "5px" }}
                  variant="outlined"
                />

                { filterModelSelection.length - 1 !== index && <Chip label="OR" sx={{ marginRight: "5px" }}/>}
                </>
               
              );
            })}
            </Paper>
            </Grid>}
      </Grid>

      <div style={{ display: "flex" }}>
        <div id="map-canvas" style={{ display: "flex", width: "82%" }}>
          <Grid item xs={mapWidth}>
            <Collapse in={openClusterInfo}>
              <Alert severity="success">
                <CloseIcon
                  fontSize="inherit"
                  style={{ float: "right", cursor: "pointer" }}
                  onClick={() => {
                    setOpenClusterInfo(false);
                  }}
                />
                <AlertTitle>{message}</AlertTitle>
              </Alert>
            </Collapse>
            <Paper sx={{ p: 1, display: "flex", flexDirection: "column" }}>
              <GoogleMap
                setMap={setMap}
                drawingMngRef={drawingManagerRef}
                setInfoWindow={setInfoWindow}
                isClusteringEnabled={isClusteringEnabled}
                isMapIconEnabled={isMapIconEnabled}
                setIsClusteringEnabled={setIsClusteringEnabled}
                setIsMapIconEnabled={setIsMapIconEnabled}
                polygonInfo={polygonInfo}
                circleInfo={circleInfo}
                reactangleInfo={reactangleInfo}
                isNodeInfoEnabled={isNodeInfoEnabled}
                setPolygonInfo={setPolygonInfo}
                setCircleInfo={setCircleInfo}
                setReactangleInfo={setReactangleInfo}
                setIsNodeInfoEnabled={setIsNodeInfoEnabled}
                resetMapHandler={resetMapHandler}
              >
                {infoWindow !== undefined &&
                  props.tabIndex === 0 &&
                  map !== null &&
                  (isClusteringEnabled && showDollarValuesHandler() == false
                    ? constructMapClusters()
                    : constructMapNodes())}
                {infoWindow !== undefined &&
                  props.tabIndex === 0 &&
                  map !== null && (
                    <DrawingManager
                      ref={drawingManagerRef}
                      onOverlayComplete={(info: any) => {}}
                      onPolygonComplete={(info: any) => {
                        polygonInfo?.setMap(null);
                        setPolygonInfo(info);
                      }}
                      onCircleComplete={(info: any) => {
                        circleInfo?.setMap(null);
                        setCircleInfo(info);
                      }}
                      onRectangleComplete={(info: any) => {
                        reactangleInfo?.setMap(null);
                        setReactangleInfo(info);
                      }}
                      options={{
                        drawingControl: true,
                        drawingControlOptions: {
                          position: google?.maps.ControlPosition.TOP_RIGHT,
                          drawingModes: [
                            google?.maps.drawing?.OverlayType.POLYGON,
                            google?.maps.drawing?.OverlayType.CIRCLE,
                            google?.maps.drawing?.OverlayType.RECTANGLE,
                          ],
                        },
                      }}
                    />
                  )}
                {
                  <Autocomplete
                    onLoad={() => {}}
                    onPlaceChanged={() => {
                      let geocoder = new google.maps.Geocoder();
                      geocoder.geocode(
                        { address: locationSearchBox.current.value },
                        function (results, status) {
                          if (status == google.maps.GeocoderStatus.OK) {
                            if (results && results?.length > 0) {
                              map.fitBounds(results[0].geometry.bounds);
                            }
                          }
                        }
                      );
                    }}
                  >
                    <input
                      type="text"
                      ref={locationSearchBox}
                      placeholder="Search for a location"
                      style={{
                        boxSizing: `border-box`,
                        border: `1px solid transparent`,
                        width: `240px`,
                        height: `32px`,
                        padding: `0 12px`,
                        borderRadius: `3px`,
                        boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                        fontSize: `14px`,
                        outline: `none`,
                        textOverflow: `ellipses`,
                        position: "absolute",
                        left: "50%",
                        marginLeft: "-380px",
                      }}
                    />
                  </Autocomplete>
                }
              </GoogleMap>
            </Paper>
          </Grid>

          {nodeInfoViewWidth > 0 && (
            <Grid item xs={nodeInfoViewWidth} style={{ maxHeight: "85vh" }}>
              <Paper sx={{ margin: "5px", height: "98%", overflowY: "scroll" }}>
                <IconButton id="mapinfoclose"
                  style={{
                    backgroundColor: "rgb(239, 238, 238)",
                    margin: "7px",
                  }}
                  onClick={() => {
                    handleMapInfoWindowOpenClose(false);
                  }}
                >
                  <ChevronRightIcon />
                </IconButton>
                <Button id="addtocart"
                  variant="outlined"
                  sx={{ display: currentMapNodeInCart ? "none" : "" }}
                  onClick={() => {
                    onAddtoCart(currentMapNodeData);
                  }}
                  startIcon={<AddShoppingCartIcon />}
                >
                  Add to cart
                </Button>
                <IconButton id="mapviewdone"
                  sx={{ float: "right" }}
                  color="success"
                  aria-label="done"
                  onClick={() => {
                    setExpandAll(!expandAll);
                  }}
                >
                  {expandAll === false && <UnfoldMoreOutlinedIcon />}
                  {expandAll === true && <UnfoldLessOutlinedIcon />}
                </IconButton>
                <NodeInfo
                  qualityRatings={qualityRatings}
                  reportGroups={reportConfigurations}
                  currentMapNodeData={currentMapNodeData}
                  expandAll={expandAll}
                ></NodeInfo>
              </Paper>
            </Grid>
          )}
        </div>
        <Paper
          sx={{
            p: 3,
            display: "flex",
            flexDirection: "column",
            padding: "10px",
            width: "17%",
            marginLeft: "5px",
          }}
        >
          {map !== null && dataAuditData.length > 0 && (
            <div style={{ display: "flex" }}>
              <Tooltip title="Apply County">
                <CountySelection mapObj={map} countyKmlFiles={countyKmlFiles} />
              </Tooltip>
              {!showOrderId() && (
                <Tooltip title="Apply Map Filter">
                  <MapFilter
                    dataAuditData={dataAuditDataImmutable}
                    setDataAuditData={setDataAuditData}
                    mapFilters={mapFilters}
                    mapDataGridRef={mapDataGridRef}
                    operator={fetchUserOperator()}
                    filterModelSelection={filterModelSelection}
                    setFilterModelSelection={setFilterModelSelection}
                    setFilterAlignment={setFilterAlignment}
                    resetMapHandler={resetMapHandler}
                  />
                </Tooltip>
              )}
              <Tooltip title="Dollar Values">
                <IconButton id="mapviewdollarvalues"
                  color="success"
                  aria-label="Dollar Values"
                  style={{
                    backgroundColor: "rgb(239 238 238)",
                    width: "fit-content",
                    marginLeft: "5px",
                    display: showOrderId() ? "" : "none",
                  }}
                  onClick={() => {
                    setShowDollarValues(!showDollarValues);
                  }}
                >
                  <MonetizationOnOutlinedIcon fontSize="small" />
                </IconButton>
              </Tooltip>
              {showOrderId() && (
                <Tooltip title="Reports">
                  <ReportSelectionMapDollarAmounts
                    reportGroups={reportGroups}
                    orderReports={orderReports}
                    mapObj={map}
                    selectedOrderId={selectedOrderId}
                    tradeOrderFromTo={tradeOrderFromTo}
                    oppositeTradeOrderFromTo={oppositeTradeOrderFromTo}
                    setOrderReportSelectionOrder1={
                      setOrderReportSelectionOrder1
                    }
                    setOrderReportSelectionOrder2={
                      setOrderReportSelectionOrder2
                    }
                    estimateOrderReports={estimateOrderReports}
                    estimateOrderReportsBothSides={
                      estimateOrderReportsBothSides
                    }
                    setEstimateOrderReports={setEstimateOrderReports}
                    setEstimateOrderReportsBothSides={
                      setEstimateOrderReportsBothSides
                    }
                  />
                </Tooltip>
              )}
            </div>
          )}

          <MapGrid
            dataAuditData={dataAuditData}
            reportConfigurations={reportConfigurations}
            reportGroupInfo={reportGroupInfo}
            loadInitialPrerequisiteData={loadInitialMapData}
            setMapDataGridRefHandler={setMapDataGridRefHandler}
            onFilterChangedHandler={onFilterChangedHandler}
          ></MapGrid>
        </Paper>
      </div>
    </div>
  );
}

export default MapView;
