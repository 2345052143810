import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import Grid from '@mui/material/Grid';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import ViewTimelineOutlinedIcon from '@mui/icons-material/ViewTimelineOutlined';
import OpenInFullOutlinedIcon from '@mui/icons-material/OpenInFullOutlined';
import CloseFullscreenOutlinedIcon from '@mui/icons-material/CloseFullscreenOutlined';
import * as api from 'src/Utility/api';
import { TransitionProps } from '@mui/material/transitions';
import OrderInformation from './OrderInformation/OrderInformation';
import PrintIcon from '@mui/icons-material/Print';

import './OrderSummary.css';
import { Alert, Collapse, AlertColor, Box } from '@mui/material';
import HistoryViewRenderer from '../CellRenderers/HistoryViewRenderer';
import { ExchangeOrderType } from '../../@State/types';
import { AppContext } from '../../@State/context/AppContext';

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function OrderSummary(props: any) {
    const [tradeOrders, setTradeOrders] = React.useState<any[]>([]);
    const [showTimeLine, setShowTimeLine] = React.useState<boolean>(true);
    const [otherText, setOtherText] = React.useState<string>(props.data.Info1);
    const [otherTextTradeOrder1, setOtherTextTradeOrder1] = React.useState<string>("");
    const [otherTextTradeOrder2, setOtherTextTradeOrder2] = React.useState<string>("");
    const [open, setOpen] = React.useState(false);
    const [wellsRestriction, setWellsRestriction] = React.useState<number>(500);

    const [expandTradeSection, setExpandTradeSection] = React.useState<boolean>(false);
    const [agreement, setAgreement] = React.useState<string>('');

    const [tradeSideExpanded, setTradeSideExpanded] = React.useState<string>("");
    const [enablePagination,setEnablePagination]= React.useState<boolean>(true);

    const [showOnlyReportInfoTrade,setShowOnlyReportInfoTrade]= React.useState<boolean>(false);
    const { CommonAppConfig,
        fetchCommonAppConfigHandler } = React.useContext(AppContext) as ExchangeOrderType;

    const initialMessage = {
        open: false,
        msg: '',
        severity: 'info'
    };
    const [message, setMessage] = React.useState(initialMessage);

    const formMessage = (message: string, severity: string) => {
        setMessage({
            open: true,
            msg: message,
            severity: severity
        });
    }

    const handTradeSectionExpansion = (side: string) => {
        setTradeSideExpanded(side);
        setExpandTradeSection(!expandTradeSection);
    }

    const closeMessage = () => {
        setMessage(initialMessage);
    }

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const printScreenHandler = () =>{
        setEnablePagination(false);
        
        setTimeout(() => {
            window.print();
            //setEnablePagination(true);
        }, 2000);
    }

    React.useEffect(() => {
        if (props.groupedOrders.length > 0 && props.data.TradeGroupId !== "N/A") { //which means its an exchange order //
            const groupOrders = props.groupedOrders.filter((order: any) => {
                return order.TradeGroupId === props.data.TradeGroupId
            });

            if (groupOrders.length > 0) {
                let tradeOrders: any[] = [];

                const requestedOrder = groupOrders.find((order: any) => {
                    return order.RequestedBy === props.operator
                });

                if (requestedOrder) {
                    tradeOrders.push(requestedOrder);
                }

                const responseOrder = groupOrders.find((order: any) => {
                    return order.RequestedTo === props.operator && 
                    (order.OrderEvent !== "ORDER_DRAFTED" && order.OrderEvent !== "INT_WORKFLOW_INITIATED" && order.OrderEvent !== "ORDER_CREATED")
                });

                if (responseOrder) {
                    tradeOrders.push(responseOrder);
                }

                setTradeOrders(tradeOrders);
                if (tradeOrders.length === 2) {
                    setOtherTextTradeOrder1(tradeOrders[0].Info1);
                    setOtherTextTradeOrder2(tradeOrders[1].Info1);
                }

                api.LOG_MESSAGE(["Page : Transaction ", "Action : Open Summary for Order : " + props.data.OrderId], { 'UserName': props.loggedInUserName });
            }

        }
    }, [props.data]);

    React.useEffect(() => {
        if (CommonAppConfig) {
          const agreementConfig = CommonAppConfig.find((element) => { return element.ConfigLongDesc === "Agreement" && element.ConfigShortDesc === props.data.TradeType });
          if (agreementConfig) {
                setAgreement(agreementConfig.ConfigValue);
            }

            let noOfWells = CommonAppConfig.find((temp : any) => {
                return  temp.ConfigShortDesc === "NoOfWells" && temp.Operators.includes(props.operator);
            });

            if(noOfWells){
                setWellsRestriction(noOfWells.ConfigValue);
            }
        }
      }, [CommonAppConfig])

    React.useEffect(() => {
        if (typeof (props.setIsEdittedHandler) !== "undefined") {
            if (open) {
                if(CommonAppConfig === null) {
                    fetchCommonAppConfigHandler();
                }
                
                props.setIsEdittedHandler(true);
            } else {
                props.setIsEdittedHandler(false);
            }
        }
    }, [open])

    return (
        <div style={{height:'100%'}}>
            <IconButton id="ordersummarydata" sx={{ padding: '0px',width:'100%',height:'100%' }}
                color="primary"
                disabled={props.cartData != null
                    && props.cartData?.length > 0
                    && props.cartData[props.rowIndex]?.OrderId === 'N/A'}
                size="small" onClick={handleClickOpen}
                aria-label="data">
                <ArticleOutlinedIcon />
            </IconButton>
            <Dialog id='ordersummary'
                fullScreen
                open={open}
                onClose={handleClose}
                TransitionComponent={Transition}
            >
                <Box>
                    <Collapse in={message.open} style={{ minHeight: "auto" }}>
                        <Alert onClose={closeMessage} severity={message.severity as AlertColor}
                            sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                            {message.msg}
                        </Alert>
                    </Collapse>
                    <Grid item xs={12}>
                        <div style={{ borderRadius: '20px', backgroundColor: "#ffd1d1" }}>
                            <Typography sx={{ marginLeft: "1%" }} display="block" >
                               {agreement}
                            </Typography>
                        </div>
                    </Grid>
                    {props.reportGroups?.length > 0 && 
                    (props.data.TradeGroupId == "N/A" || (props.data.TradeGroupId !== "N/A" && props.data.detailData?.length == 0)) &&
                        <div>
                            <AppBar sx={{ position: 'relative' }}>
                                <Toolbar>
                                    <IconButton id="close"
                                        edge="start"
                                        color="inherit"
                                        onClick={handleClose}
                                        aria-label="close"
                                    >
                                        <CloseIcon />
                                    </IconButton>
                                    <Typography sx={{ ml: 2, flex: 1 }} variant="subtitle1" component="div">
                                        ORDER SUMMARY {'( # ' + props.data.OrderId + ' ) ( ' + props.data.RequestedBy + ' Requested to ' + props.data.RequestedTo + ' )'}
                                    </Typography>

                                    {/* <IconButton
                                        edge="start"
                                        color="inherit"
                                        onClick={() => { setShowTimeLine(true); }}
                                        aria-label="close"
                                    >
                                        <ViewTimelineOutlinedIcon />
                                    </IconButton> */}
                                    <HistoryViewRenderer
                                        schemaName={'ORDERS'}
                                        tableName={'ORDER_MANAGEMENT'}
                                        tablePrimaryKey={'OrderId'}
                                        IsSummary={true}
                                        setShowProgress={() => { }}
                                        value={props.data.OrderId}
                                        loggedInUser={props.loggedInUserName} />

                                    {/* <IconButton
                                        sx={{ marginLeft: "10px" }}
                                        edge="start"
                                        color="inherit"
                                        onClick={() => { printScreenHandler() }}
                                        aria-label="Print"
                                    >
                                        <PrintIcon />
                                    </IconButton> */}
                                    <Button id="close" autoFocus color="inherit" onClick={handleClose}>
                                        CLOSE
                                    </Button>
                                </Toolbar>
                            </AppBar>

                            <OrderInformation
                                maxWellsCount = {wellsRestriction}
                                orderInfo={props.data}
                                operator={props.operator}
                                loggedInUserName={props.loggedInUserName}
                                loggedInUserMailId={props.loggedInUserMailId}
                                reportGroups={props.reportGroups}
                                setTabIndex={props.setTabIndex}
                                enablePagination={enablePagination}
                                summaryCloseHandler={handleClose}
                                setShowTimeLine={setShowTimeLine}
                                showTimeLine={showTimeLine}
                                otherText={otherText}
                                setOtherText={setOtherText}
                                formMessage={formMessage}
                                onAddWells={props.onAddWells}
                                rowIndex={props.rowIndex}
                                cartData={props.cartData} 
                                showOnlyReportInfoTrade={showOnlyReportInfoTrade}
                                setShowOnlyReportInfoTrade={setShowOnlyReportInfoTrade}/>
                        </div>}

                    {props.reportGroups?.length > 0 && tradeOrders.length === 2 &&
                        <div style={{ display: 'flex' }}>
                            <div style={{
                                width: (expandTradeSection == true && tradeSideExpanded == "left") ? "100%" : "50%",
                                display: ((expandTradeSection == true && tradeSideExpanded == "left") || expandTradeSection == false) ? "" : "none"
                            }}>
                                <AppBar sx={{ position: 'relative' }}>
                                    <Toolbar>
                                        <IconButton id="os-close"
                                            edge="start"
                                            color="inherit"
                                            onClick={handleClose}
                                            aria-label="close"
                                        >
                                            <CloseIcon />
                                        </IconButton>
                                        <Typography sx={{ ml: 2, flex: 1 }} variant="subtitle1" component="div">
                                            ORDER SUMMARY {'( # ' + tradeOrders[0].OrderId + ' ) ( ' + tradeOrders[0].RequestedBy + ' Requested to ' + tradeOrders[0].RequestedTo + ' )'}
                                        </Typography>
                                        <HistoryViewRenderer
                                            schemaName={'ORDERS'}
                                            tableName={'ORDER_MANAGEMENT'}
                                            tablePrimaryKey={'OrderId'}
                                            IsSummary={true}
                                            setShowProgress={() => { }}
                                            value={tradeOrders[0].OrderId}
                                            loggedInUser={props.loggedInUserName} />
                                        {/* <IconButton
                                            sx={{ marginLeft: "10px" }}
                                            edge="start"
                                            color="inherit"
                                            onClick={() => { printScreenHandler() }}
                                            aria-label="Print"
                                        >
                                            <PrintIcon />
                                        </IconButton> */}
                                        <IconButton id="ordersummaryclose"
                                            sx={{ marginLeft: "10px" }}
                                            edge="start"
                                            color="inherit"
                                            onClick={() => { handTradeSectionExpansion("left"); }}
                                            aria-label="close"
                                        >
                                            {!expandTradeSection && <OpenInFullOutlinedIcon />}
                                            {expandTradeSection && <CloseFullscreenOutlinedIcon />}
                                        </IconButton>
                                        {/* <IconButton
                                            edge="start"
                                            color="inherit"
                                            onClick={() => { setShowTimeLine(true); }}
                                            aria-label="close"
                                        >
                                            <ViewTimelineOutlinedIcon />
                                        </IconButton> */}
                                        <Button id="closebtn2" autoFocus color="inherit" onClick={handleClose}>
                                            CLOSE
                                        </Button>
                                    </Toolbar>
                                </AppBar>

                                <OrderInformation
                                    orderInfo={tradeOrders[0]}
                                    operator={props.operator}
                                    loggedInUserName={props.loggedInUserName}
                                    loggedInUserMailId={props.loggedInUserMailId}
                                    reportGroups={props.reportGroups}
                                    setTabIndex={props.setTabIndex}
                                    enablePagination={enablePagination}
                                    summaryCloseHandler={handleClose}
                                    setShowTimeLine={setShowTimeLine}
                                    showTimeLine={showTimeLine}
                                    otherText={otherTextTradeOrder1}
                                    setOtherText={setOtherTextTradeOrder1}
                                    formMessage={formMessage}
                                    onAddWells={props.onAddWells}
                                    rowIndex={props.rowIndex}
                                    cartData={props.cartData}
                                    groupedOrders={props.groupedOrders}
                                    showOnlyReportInfoTrade={showOnlyReportInfoTrade}
                                    setShowOnlyReportInfoTrade={setShowOnlyReportInfoTrade}
                                />
                            </div>
                            <div style={{
                                width: (expandTradeSection == true && tradeSideExpanded == "right") ? "100%" : "50%",
                                display: ((expandTradeSection == true && tradeSideExpanded == "right") || expandTradeSection == false) ? "" : "none"
                            }}>
                                <AppBar sx={{ position: 'relative' }}>
                                    <Toolbar>
                                        <IconButton id="ordersummary-close"
                                            edge="start"
                                            color="inherit"
                                            onClick={handleClose}
                                            aria-label="close"
                                        >
                                            <CloseIcon />
                                        </IconButton>
                                        <Typography
                                            sx={{ ml: 2, flex: 1 }}
                                            variant="subtitle1"
                                            component="div">
                                            ORDER SUMMARY {'( # ' + tradeOrders[1].OrderId + ' ) ( ' + tradeOrders[1].RequestedBy + ' Requested to ' + tradeOrders[1].RequestedTo + ' )'}
                                        </Typography>
                                        <HistoryViewRenderer
                                            schemaName={'ORDERS'}
                                            tableName={'ORDER_MANAGEMENT'}
                                            tablePrimaryKey={'OrderId'}
                                            IsSummary={true}
                                            setShowProgress={() => { }}
                                            value={tradeOrders[1].OrderId}
                                            loggedInUser={props.loggedInUserName} />
                                        {/* <IconButton
                                            sx={{ marginLeft: "10px" }}
                                            edge="start"
                                            color="inherit"
                                            onClick={() => { printScreenHandler() }}
                                            aria-label="Print"
                                        >
                                            <PrintIcon />
                                        </IconButton> */}
                                        <IconButton id="osclose"
                                            sx={{ marginLeft: "10px" }}
                                            edge="start"
                                            color="inherit"
                                            onClick={() => { handTradeSectionExpansion("right"); }}
                                            aria-label="close"
                                        >
                                            {!expandTradeSection && <OpenInFullOutlinedIcon />}
                                            {expandTradeSection && <CloseFullscreenOutlinedIcon />}
                                        </IconButton>
                                        {/* <IconButton
                                            edge="start"
                                            color="inherit"
                                            onClick={() => { setShowTimeLine(true); }}
                                            aria-label="close"
                                        >
                                            <ViewTimelineOutlinedIcon />
                                        </IconButton> */}

                                        <Button id="closebtn3" autoFocus color="inherit" onClick={handleClose}>
                                            CLOSE
                                        </Button>

                                    </Toolbar>
                                </AppBar>

                                <OrderInformation
                                    orderInfo={tradeOrders[1]}
                                    operator={props.operator}
                                    loggedInUserName={props.loggedInUserName}
                                    loggedInUserMailId={props.loggedInUserMailId}
                                    reportGroups={props.reportGroups}
                                    setTabIndex={props.setTabIndex}
                                    enablePagination={enablePagination}
                                    summaryCloseHandler={handleClose}
                                    setShowTimeLine={setShowTimeLine}
                                    showTimeLine={showTimeLine}
                                    otherText={otherTextTradeOrder2}
                                    setOtherText={setOtherTextTradeOrder2}
                                    formMessage={formMessage}
                                    onAddWells={props.onAddWells}
                                    rowIndex={props.rowIndex}
                                    cartData={props.cartData}
                                    groupedOrders={props.groupedOrders} 
                                    showOnlyReportInfoTrade={showOnlyReportInfoTrade}
                                    setShowOnlyReportInfoTrade={setShowOnlyReportInfoTrade}/>
                            </div>
                        </div>
                    }
                  
                </Box>
                
            </Dialog>
        </div>
    );
}