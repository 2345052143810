import Grid from '@mui/material/Grid';

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Box, IconButton, Paper } from "@mui/material";
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-material.css';
import React, { useState } from 'react';
import ExplorerProvider from "../@State/context/ExplorerContext";
import TableProvider from "../@State/context/TableContext";
import { ICartInfo, IExplorerTableInfo } from '../@State/types';
import Explorer from "./Explorer/Explorer";
import TabView from './TabView/TabView';

interface ITablesProps {
  loggedInUser: string,
  loggedInUserMailId: string,
  userSecurityPermission: any,
  //cartInformation?: ICartInfo[],
  IsRefreshClicked: boolean,
  IsAdminUser:boolean,
  setShowProgress: (show: boolean) => void,
  //setCartInformation: React.Dispatch<React.SetStateAction<ICartInfo[]>>,
  setIsRefreshClicked: (show: boolean) => void
}

function Tables(props: ITablesProps) {
  const [selectedTable, setSelectedTable] = useState<IExplorerTableInfo>({
    name: "",
    schema: ""
  });
  const [open, setOpen] = React.useState(false);
  const [explorerWidth, setExplorerWidth] = React.useState<number>(0);
  const [tableViewWidth, setTableViewWidth] = React.useState<number>(12);
  const [tabIndex, setTabIndex] = React.useState<number>(0);

  const handleCollapseExplorer = () => {
    setOpen(false);
    setExplorerWidth(0);
    setTableViewWidth(12);
  }

  const handleExpandExplorer = () => {
    setOpen(true);
    setExplorerWidth(2);
    setTableViewWidth(10);
  }


  return (
    <>
      <Grid item xs={tableViewWidth} style={{ paddingTop: "0px" }}>
        <TabView
          loggedInUser={props.loggedInUser}
          loggedInUserMailId={props.loggedInUserMailId}
          userSecurityPermission={props.userSecurityPermission}
          selectedTableInfo={selectedTable}
          IsRefreshClicked={props.IsRefreshClicked}
          IsAdminUser={props.IsAdminUser}
          setTabIndex={setTabIndex}
          setShowProgress={props.setShowProgress}
          setIsRefreshClicked={props.setIsRefreshClicked}></TabView>
      </Grid>
    </>
  );
}

export default Tables;