import * as React from "react";
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";
import { alpha, styled } from "@mui/material/styles";
import TreeView from "@mui/lab/TreeView";
import TreeItem, { TreeItemProps, treeItemClasses } from "@mui/lab/TreeItem";
import Collapse from "@mui/material/Collapse";
// web.cjs is required for IE11 support
import { useSpring, animated } from "react-spring";
import { TransitionProps } from "@mui/material/transitions";
import {
  Tooltip,
  IconButton,
  Popper,
  Box,
  DialogTitle,
  DialogActions,
  PopperPlacementType,
  TextField,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import DoneIcon from "@mui/icons-material/Done";
import ViewColumnOutlinedIcon from "@mui/icons-material/ViewColumnOutlined";
import { CartContext } from "src/Components/Dashboard/@State/context/CartContext";
import {
  CartType,
  ExchangeOrderType,
  IResizePosition,
} from "src/Components/Dashboard/@State/types";
import { groupByObjectArray } from "src/Utility/common";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { debounce } from "src/Utility/api";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import { useEffect } from "react";
import IOSSwitchToggle from "src/Common/IOSSwitchToggle";
import "./ReportPickerRenderer.css";
import { Rnd } from "react-rnd";
import * as api from 'src/Utility/api';

import UnfoldLessOutlinedIcon from "@mui/icons-material/UnfoldLessOutlined";
import UnfoldMoreOutlinedIcon from "@mui/icons-material/UnfoldMoreOutlined";

import "../../../../../Hoc/css/styles.css";
import { AppContext } from "src/Components/Dashboard/@State/context/AppContext";
import { useEffectOnce } from "src/hooks/useEffectOnce";

function MinusSquare(props: SvgIconProps): JSX.Element {
  return (
    <SvgIcon
      fontSize="inherit"
      style={{ width: 14, height: 14, color: "rgba(0, 0, 0, 0.54)" }}
      {...props}
    >
      {/* tslint:disable-next-line: max-line-length */}
      <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 11.023h-11.826q-.375 0-.669.281t-.294.682v0q0 .401.294 .682t.669.281h11.826q.375 0 .669-.281t.294-.682v0q0-.401-.294-.682t-.669-.281z" />
    </SvgIcon>
  );
}

function PlusSquare(props: SvgIconProps) {
  return (
    <SvgIcon
      fontSize="inherit"
      style={{ width: 14, height: 14, color: "rgba(0, 0, 0, 0.54)" }}
      {...props}
    >
      {/* tslint:disable-next-line: max-line-length */}
      <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 12.977h-4.923v4.896q0 .401-.281.682t-.682.281v0q-.375 0-.669-.281t-.294-.682v-4.896h-4.923q-.401 0-.682-.294t-.281-.669v0q0-.401.281-.682t.682-.281h4.923v-4.896q0-.401.294-.682t.669-.281v0q.401 0 .682.281t.281.682v4.896h4.923q.401 0 .682.281t.281.682v0q0 .375-.281.669t-.682.294z" />
    </SvgIcon>
  );
}

function CloseSquare(props: SvgIconProps) {
  return (
    <SvgIcon
      className="close"
      fontSize="inherit"
      style={{ width: 14, height: 14, color: "rgba(0, 0, 0, 0.54)" }}
      {...props}
    >
      {/* tslint:disable-next-line: max-line-length */}
      <path d="M17.485 17.512q-.281.281-.682.281t-.696-.268l-4.12-4.147-4.12 4.147q-.294.268-.696.268t-.682-.281-.281-.682.294-.669l4.12-4.147-4.12-4.147q-.294-.268-.294-.669t.281-.682.682-.281.696 .268l4.12 4.147 4.12-4.147q.294-.268.696-.268t.682.281 .281.669-.294.682l-4.12 4.147 4.12 4.147q.294.268 .294.669t-.281.682zM22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0z" />
    </SvgIcon>
  );
}

function TransitionComponent(props: TransitionProps) {
  const style = useSpring({
    from: {
      opacity: 0,
      transform: "translate3d(20px,0,0)",
    },
    to: {
      opacity: props.in ? 1 : 0,
      transform: `translate3d(${props.in ? 0 : 20}px,0,0)`,
    },
  });

  return (
    <animated.div style={style}>
      <Collapse {...props} />
    </animated.div>
  );
}

type StyledTreeItemProps = TreeItemProps & {
  DA_ColName?: string;
  isEnabled?: boolean;
  itemLevel: string;
  onReportSelect: (selectedNode: any) => void;
  onReportParentSelect: (selectedNode: any, levelName: string) => void;
};

const StyledTreeItem = styled((props: StyledTreeItemProps) => (
  <Box
    sx={{ display: "flex", alignItems: "flex-start", p: 0.5, pr: 0 }}
    onClick={() => {
      props.children === undefined ? props.onReportSelect(props) : <></>;
    }}
  >
    <Box
      style={{ color: "#ff4081", cursor: "pointer" }}
      onClick={() => {
        props.children === undefined ? (
          <></>
        ) : (
          props.onReportParentSelect(props, props.itemLevel)
        );
      }}
      component={props.isEnabled ? CheckBoxIcon : CheckBoxOutlineBlankIcon}
      color="inherit"
      sx={{ mr: 1 }}
    />
    <TreeItem {...props} TransitionComponent={TransitionComponent} />
  </Box>
))(({ theme }) => ({
  [`& .${treeItemClasses.iconContainer}`]: {
    "& .close": {
      opacity: 0.3,
    },
  },
  [`& .${treeItemClasses.group}`]: {
    marginLeft: 15,
    paddingLeft: 18,
    borderLeft: `1px dashed ${alpha(theme.palette.text.primary, 0.4)}`,
  },
}));

const nodeIds: string[] = [];

function ReportPickerRenderer(props: any) {
  const [selectedColumns, SetSelectedColumns] = React.useState<string>(
    props.value
  );
  const [open, setOpen] = React.useState(false);
  const [disableEdit, setDisableEdit] = React.useState(
    props.operator !== props.data.RequestedBy ||
      props.data.OrderStatus === "Executed"
  );
  const [placement, setPlacement] =
    React.useState<PopperPlacementType>("bottom-start");
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [reportSelection, setReportSelection] = React.useState<any[]>([]);
  const [reportGroups, setReportGroups] = React.useState<any[]>([]);
  const [searchText, setSearchText] = React.useState<string>("");
  const { saveColumnSelection } = React.useContext(
    CartContext
  ) as CartType;
  const [reportGroupsOnlyReports, setReportGroupsOnlyReports] = React.useState<
    any[] | undefined
  >([]);
  const [reportGroupsFiltered, setReportGroupsFiltered] = React.useState<
    any[] | undefined
  >([]);
  const [positions, setPositions] = React.useState<IResizePosition>({
    width: 400,
    height: 300,
  });
  const [expandAll, setExpandAll] = React.useState<boolean>(false);

  const { fetchReportDisplayName } = React.useContext(
    AppContext
  ) as ExchangeOrderType;

  useEffectOnce(()=>{
    loadInitialReportData();
  });

  React.useEffect(() => {
    initLoad();
  }, [reportGroups]);

  const loadInitialReportData = () => {
    async function fetchReportGroups() {
        await api.API_GET('/Table/GetTablesData?schema=settings&table=REPORT_GROUP'+"&infoColumnsOperator="+props.data.RequestedTo)
            .then((response) => {
                if(response?.data){
                setReportGroups(response.data.TableData);
                }
            });
    };
    fetchReportGroups();
};

  useEffect(() => {
    if (searchText !== "") {
      const filterReportSelectionResult = reportGroupsOnlyReports?.filter(
        (element) => {
          return (
            element.L1.toLowerCase().includes(searchText.toLowerCase()) ||
            element.L2.toLowerCase().includes(searchText.toLowerCase()) ||
            element.L3.toLowerCase().includes(searchText.toLowerCase()) ||
            element.L4.toLowerCase().includes(searchText.toLowerCase())
          );
        }
      );
      setReportGroupsFiltered(filterReportSelectionResult);
    } else {
      if (reportGroups) {
        const onlyReports = reportGroups?.filter((element) => {
          return element.Report_YN === "Y";
        });
        setReportGroupsFiltered(onlyReports);
      }
    }
  }, [searchText]);

  useEffect(() => {
    if (typeof props.setIsEdittedHandler !== "undefined") {
      if (open) {
        props.setIsEdittedHandler(true);
      } else {
        props.setIsEdittedHandler(false);
      }
    }
  }, [open]);

  const initLoad = () => {
    if (reportGroups) {
      const onlyReports = reportGroups.filter((element) => {
        return element.Report_YN === "Y";
      });
      setReportGroupsOnlyReports(onlyReports);
      setReportGroupsFiltered(onlyReports);
      loadCartInformation(onlyReports);
    }
  };

  const removeYNFormatter = (value: string) => {
    return value.replaceAll("_YN", "").replaceAll("_", " ");
  };

  const handleClickOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };

  const clearSelection = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setOpen(false);
  };

  const chooseSelection = (event: React.MouseEvent<HTMLButtonElement>) => {
    props.onColumnSelected(getSelectedColumns(), props.rowIndex, props.data);
    SetSelectedColumns(getSelectedColumns());
    setAnchorEl(event.currentTarget);
    setOpen(false);
  };

  const setColumnPreferenceHandler = (
    columnPerferenceObj: any,
    isEnable: boolean
  ) => {
    let _reportPreference = [...reportSelection];
    let _matchingReport = _reportPreference.filter((element) => {
      return element.ColumnName === columnPerferenceObj.DA_ColName;
    });
    if (_matchingReport.length > 0) {
      _reportPreference[_reportPreference.indexOf(_matchingReport[0])].Enable =
        isEnable;
      setReportSelection(_reportPreference);
      saveColumnSelection(_reportPreference, props.node.data);
    }
  };

  const getSelectedColumns = () => {
    if (reportSelection.length > 0) {
      const selectedColumns = reportSelection.filter((ele: any) => {
        return ele.Enable;
      });
      if (selectedColumns.length > 0) {
        const columnsNames = selectedColumns.map((ele: any) => {
          return ele.ColumnName;
        });
        return columnsNames.toString();
      } else {
        return "All";
      }
    } else {
      return "All";
    }
  };

  const loadCartInformation = (onlyReports: any[]) => {
    let reportPreference: any[] = [];

    onlyReports?.forEach((report: any) => {
      if (props.value?.split(",").includes(report.DA_ColName)) {
        reportPreference.push({
          ColumnName: report.DA_ColName,
          Enable: true,
        });
      } else {
        reportPreference.push({
          ColumnName: report.DA_ColName,
          Enable: false,
        });
      }
    });

    setReportSelection(reportPreference);
  };

  const onReportSelect = (selectedNode: any) => {
    setColumnPreferenceHandler(selectedNode, !selectedNode.isEnabled);
  };

  const onReportParentSelect = (selectedNode: any, levelName: string) => {
    const allChildren = reportGroupsOnlyReports?.filter((element: any) => {
      return element[levelName] === selectedNode.label;
    });
    let _reportSelection = [...reportSelection];
    allChildren?.forEach((reportNode: any) => {
      let matchSelection = _reportSelection.filter((element: any) => {
        return element.ColumnName === reportNode.DA_ColName;
      });

      if (matchSelection.length > 0) {
        matchSelection[0].Enable = !selectedNode.isEnabled;
      }
    });

    setReportSelection(_reportSelection);
  };

  const constructLevelOne = () => {
    const level1Groups = groupByObjectArray(reportGroupsFiltered, "L1");
    const level1GroupsKeys = Object.keys(level1Groups);
    return level1GroupsKeys.map((levelOneGroupKey: any, index: number) => {
      const daColumnName = reportGroupsFiltered?.filter((element: any) => {
        return element.L1 === levelOneGroupKey;
      })[0].DA_ColName;

      let children: any = [];
      children = reportGroupsFiltered
        ?.filter((element: any) => {
          return element.L1 === levelOneGroupKey;
        })
        .map((x: any) => x.DA_ColName);
      const isEnabled = isParentEnabled(children);
      nodeIds.push(index + "l1" + levelOneGroupKey);

      return (
        <StyledTreeItem
          key={index + "l1" + levelOneGroupKey}
          onReportSelect={onReportSelect}
          nodeId={index + "l1" + levelOneGroupKey}
          label={fetchReportDisplayName(levelOneGroupKey)}
          isEnabled={isEnabled}
          DA_ColName={daColumnName}
          itemLevel={"L1"}
          onReportParentSelect={onReportParentSelect}
        >
          {constructLevelTwo(level1Groups[levelOneGroupKey])}
        </StyledTreeItem>
      );
    });
  };

  const constructLevelTwo = (levelOneReportGroups: any) => {
    const level2Groups = groupByObjectArray(levelOneReportGroups, "L2");
    const level2GroupsKeys = Object.keys(level2Groups);
    return level2GroupsKeys.map((levelTwoGroupKey: any, index: number) => {
      const daColumnName = levelOneReportGroups.filter((element: any) => {
        return element.L2 === levelTwoGroupKey;
      })[0].DA_ColName;

      const children = levelOneReportGroups
        .filter((element: any) => {
          return element.L2 === levelTwoGroupKey;
        })
        .map((x: any) => x.DA_ColName);
      const isEnabled = isParentEnabled(children);
      nodeIds.push(index + "l2" + levelTwoGroupKey);

      return (
        <StyledTreeItem
          key={index + "l2" + levelTwoGroupKey}
          onReportSelect={onReportSelect}
          nodeId={index + "l2" + levelTwoGroupKey}
          label={fetchReportDisplayName(levelTwoGroupKey)}
          isEnabled={isEnabled}
          DA_ColName={daColumnName}
          itemLevel={"L2"}
          onReportParentSelect={onReportParentSelect}
        >
          {constructLevelThree(level2Groups[levelTwoGroupKey])}
        </StyledTreeItem>
      );
    });
  };

  const constructLevelThree = (levelTwoReportGroups: any) => {
    const level3Groups = groupByObjectArray(levelTwoReportGroups, "L3");
    const level3GroupsKeys = Object.keys(level3Groups);
    return level3GroupsKeys.map((levelThreeGroupKey: any, index: number) => {
      const daColumnName = levelTwoReportGroups.filter((element: any) => {
        return element.L3 === levelThreeGroupKey;
      })[0].DA_ColName;

      const children = levelTwoReportGroups
        .filter((element: any) => {
          return element.L3 === levelThreeGroupKey;
        })
        .map((x: any) => x.DA_ColName);
      const isEnabled = isParentEnabled(children);
      nodeIds.push(index + "l3" + levelThreeGroupKey);

      //const isEnabled = reportSelection.filter((element)=>{ return element.ColumnName === daColumnName})[0]?.Enable;
      return (
        <StyledTreeItem
          key={index + "l3" + levelThreeGroupKey}
          onReportSelect={onReportSelect}
          nodeId={index + "l3" + levelThreeGroupKey}
          label={fetchReportDisplayName(levelThreeGroupKey)}
          isEnabled={isEnabled}
          DA_ColName={daColumnName}
          itemLevel={"L3"}
          onReportParentSelect={onReportParentSelect}
        >
          {constructLevelFour(
            level3Groups[levelThreeGroupKey],
            levelThreeGroupKey
          )}
        </StyledTreeItem>
      );
    });
  };

  const constructLevelFour = (
    levelThreeReportGroups: any,
    levelThreeGroupKey: string
  ) => {
    const level4Groups = groupByObjectArray(levelThreeReportGroups, "L4");
    const level4GroupsKeys = Object.keys(level4Groups);

    if (
      level4GroupsKeys.length === 1 &&
      level4GroupsKeys[0] === levelThreeGroupKey
    ) {
    } else {
      return level4GroupsKeys.map((levelFourGroupKey: any, index: number) => {
        const daColumnName = levelThreeReportGroups.filter((element: any) => {
          return element.L4 === levelFourGroupKey;
        })[0].DA_ColName;
        const isEnabled = reportSelection.filter((element) => {
          return element.ColumnName === daColumnName;
        })[0]?.Enable;
        nodeIds.push(index + "l4" + levelFourGroupKey);

        return (
          <StyledTreeItem
            key={index + "l4" + levelFourGroupKey}
            onReportSelect={onReportSelect}
            nodeId={index + "l4" + levelFourGroupKey}
            label={fetchReportDisplayName(levelFourGroupKey)}
            isEnabled={isEnabled}
            DA_ColName={daColumnName}
            itemLevel={"L4"}
            onReportParentSelect={onReportParentSelect}
          >
            {constructLevelFive(
              level4Groups[levelFourGroupKey],
              levelFourGroupKey
            )}
          </StyledTreeItem>
        );
      });
    }
  };

  const constructLevelFive = (
    levelFourReportGroups: any,
    levelFiveGroupKey: string
  ) => {
    const level5Groups = groupByObjectArray(levelFourReportGroups, "L5");
    const level5GroupsKeys = Object.keys(level5Groups);

    if (
      level5GroupsKeys.length === 1 &&
      level5GroupsKeys[0] === levelFiveGroupKey
    ) {
    } else {
      return level5GroupsKeys.map((levelFiveGroupKey: any, index: number) => {
        const daColumnName = levelFourReportGroups.filter((element: any) => {
          return element.L5 === levelFiveGroupKey;
        })[0].DA_ColName;
        const isEnabled = reportSelection.filter((element) => {
          return element.ColumnName === daColumnName;
        })[0]?.Enable;
        nodeIds.push(index + "l5" + levelFiveGroupKey);

        return (
          <StyledTreeItem
            key={index + "l5" + levelFiveGroupKey}
            onReportSelect={onReportSelect}
            nodeId={index + "l5" + levelFiveGroupKey}
            label={fetchReportDisplayName(levelFiveGroupKey)}
            isEnabled={isEnabled}
            DA_ColName={daColumnName}
            itemLevel={"L5"}
            onReportParentSelect={onReportParentSelect}
          >
            {constructLevelSix(
              level5Groups[levelFiveGroupKey],
              levelFiveGroupKey
            )}
          </StyledTreeItem>
        );
      });
    }
  };

  const constructLevelSix = (
    levelFiveReportGroups: any,
    levelsixGroupKey: string
  ) => {
    const level6Groups = groupByObjectArray(levelFiveReportGroups, "L6");
    const level6GroupsKeys = Object.keys(level6Groups);

    if (
      level6GroupsKeys.length === 1 &&
      level6GroupsKeys[0] === levelsixGroupKey
    ) {
    } else {
      return level6GroupsKeys.map((levelsixGroupKey: any, index: number) => {
        const daColumnName = levelFiveReportGroups.filter((element: any) => {
          return element.L6 === levelsixGroupKey;
        })[0].DA_ColName;
        const isEnabled = reportSelection.filter((element) => {
          return element.ColumnName === daColumnName;
        })[0]?.Enable;
        nodeIds.push(index + "l6" + levelsixGroupKey);

        return (
          <StyledTreeItem
            key={index + "l6" + levelsixGroupKey}
            onReportSelect={onReportSelect}
            nodeId={index + "l6" + levelsixGroupKey}
            label={fetchReportDisplayName(levelsixGroupKey)}
            isEnabled={isEnabled}
            DA_ColName={daColumnName}
            itemLevel={"L6"}
            onReportParentSelect={onReportParentSelect}
          ></StyledTreeItem>
        );
      });
    }
  };

  const isParentEnabled = (levelReportGroups: any[]) => {
    let isEnabled = false;
    levelReportGroups.forEach((element) => {
      const isChildChecked = reportSelection.filter(
        (reportSelectionElement) => {
          return reportSelectionElement.ColumnName === element;
        }
      )[0]?.Enable;

      if (isChildChecked) {
        isEnabled = true;
      }
    });

    return isEnabled;
  };

  const showOnlySelected = (checked: boolean) => {
    if (checked) {
      if (reportGroups) {
        const selectedReports = reportSelection
          .filter((element) => {
            return element.Enable;
          })
          .map((x) => x.ColumnName);
        const onlyReports = reportGroups.filter((element) => {
          return (
            element.Report_YN === "Y" &&
            selectedReports.includes(element.DA_ColName)
          );
        });
        setReportGroupsOnlyReports(onlyReports);
        setReportGroupsFiltered(onlyReports);
        loadCartInformation(onlyReports);
      }
    } else {
      initLoad();
    }
  };

  const onResize = (event: any, { node, size, handle }: any) => {
    setPositions({ width: size.width, height: size.height });
  };

  return (
    <>
      <div style={{ cursor: "pointer" }}>
        <div>
          <Tooltip
            arrow
            placement="bottom-start"
            title={
              <React.Fragment>
                <b>{fetchReportDisplayName(selectedColumns)}</b>
              </React.Fragment>
            }
          >
            <span style={{ float: "left" }}>
              {selectedColumns &&
                fetchReportDisplayName(selectedColumns.substring(0, 8)) + "..."}
            </span>
          </Tooltip>
          <p style={{ margin: "0px", float: "right" }}>
            <IconButton
              color="primary"
              size="small"
              onClick={handleClickOpen}
              aria-label="data"
            >
              <ViewColumnOutlinedIcon fontSize="small" />
            </IconButton>
          </p>
        </div>
        <Popper
          open={open}
          placement={placement}
          anchorEl={anchorEl}
          style={{ zIndex: "10000" }}
        >
          <Rnd>
            <Box
              sx={{
                border: "2px solid #bfbfbf",
                p: 1,
                bgcolor: "background.paper",
                height: "auto",
                width: "auto",
                minWidth: "400px",
              }}
            >
              <div style={{ display: "flex" }}>
                <DialogTitle>
                  Dataset Selection {"(#" + props.data.OrderId + ")"}
                </DialogTitle>
                <IconButton
                  color="success"
                  disabled={disableEdit}
                  aria-label="done"
                  onClick={() => {
                    setExpandAll(!expandAll);
                  }}
                >
                  {expandAll === false && <UnfoldMoreOutlinedIcon />}
                  {expandAll === true && <UnfoldLessOutlinedIcon />}
                </IconButton>
              </div>
              <TextField
                label="Search"
                id="columnselection-search-text-box"
                value={searchText}
                sx={{ width: "100%" }}
                size="small"
                onChange={(ele) => {
                  debounce(setSearchText(ele.target.value));
                }}
              />
              <Box
                sx={{
                  border: "1px solid #bfbfbf",
                  p: 1,
                  bgcolor: "background.paper",
                }}
                style={{
                  width: "100%",
                  maxHeight: "600px",
                  overflow: "scroll",
                }}
                className="ag-theme-material"
              >
                {expandAll && (
                  <TreeView
                    aria-label="customized"
                    defaultExpanded={nodeIds}
                    defaultCollapseIcon={<MinusSquare />}
                    defaultExpandIcon={<PlusSquare />}
                    sx={{ flexGrow: 1, overflowY: "auto" }}
                  >
                    {reportGroupsFiltered && constructLevelOne()}
                  </TreeView>
                )}
                {!expandAll && (
                  <TreeView
                    aria-label="customized"
                    defaultExpanded={["1"]}
                    defaultCollapseIcon={<MinusSquare />}
                    defaultExpandIcon={<PlusSquare />}
                    sx={{ flexGrow: 1, overflowY: "auto" }}
                  >
                    {reportGroupsFiltered && constructLevelOne()}
                  </TreeView>
                )}
              </Box>
              <DialogActions>
                <IOSSwitchToggle
                  label={"selected"}
                  handleCallBack={showOnlySelected}
                ></IOSSwitchToggle>
                <IconButton
                  color="success"
                  disabled={disableEdit}
                  aria-label="done"
                  onClick={chooseSelection}
                >
                  <DoneIcon />
                </IconButton>
                <IconButton
                  color="error"
                  aria-label="cancel"
                  onClick={clearSelection}
                >
                  <CancelIcon />
                </IconButton>
              </DialogActions>
            </Box>
          </Rnd>
        </Popper>
      </div>
    </>
  );
}

export default ReportPickerRenderer;
