import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import { Box, Button, DialogActions, DialogTitle, IconButton, Popper, PopperPlacementType } from '@mui/material';
import { AgGridReact } from 'ag-grid-react';
import React, { useEffect } from 'react';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { ITableGridColumnDefinition } from '../../@State/types';

function TableDataRenderer(props: any) {
  const [open, setOpen] = React.useState(false);
  const [placement, setPlacement] = React.useState<PopperPlacementType>('bottom-start');
  const innerGridCartRef: any = React.useRef();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const defaultColDef = React.useMemo(() => {
    return {
      minWidth: 100,
      resizable: true,
      cellStyle: { textAlign: 'left' }
    };
  }, []);

  const [gridOptions, setGridOptions] = React.useState<any>({
    rowData: [],
    columnDefs: [],
    defaultColDef: defaultColDef,
    pagination: false,
    getRowHeight:() =>{ return 25}
  });

  const handleClickOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
    setTimeout(() => {
      loadCartInformation();
    }, 200);
  };

  const handleClickClose = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setOpen(false);
  };

  const loadCartInformation = () => {
    if (props.data["Data"] !== undefined || props.data["Data"] !== "") {
      if (typeof (innerGridCartRef?.current?.api) !== 'undefined') {
        const dataInput = JSON.parse(props.data["Data"]);
        if (dataInput.length > 0) {

          const columns = Object.keys(dataInput[0]);
          let columnDefinition: ITableGridColumnDefinition[] = [];
          columns.forEach((row) => {
            columnDefinition.push({
              field: row,
              //type: 'editableColumn',
              headerName: row,
              width: 140,
              cellClass: ['ag-cell-custom'],
              sortable: true,
              filter: 'agTextColumnFilter'
            });
          });
          innerGridCartRef.current.api.setColumnDefs(columnDefinition);
          innerGridCartRef.current.api.setRowData(dataInput);
        } else {
          innerGridCartRef.current.api.setColumnDefs([]);
          innerGridCartRef.current.api.setRowData([]);
        }
      }
    }
  }


  return (
    <div style={{ cursor: 'pointer' }}>
      <IconButton id="tabledata" color="primary" size="small" onClick={handleClickOpen} aria-label="data">
        <MoreVertOutlinedIcon />
      </IconButton>
      <Popper open={open} placement={placement} anchorEl={anchorEl} style={{ zIndex: '10000' }}>
        <Box sx={{ border: '2px solid #bfbfbf', p: 1, bgcolor: 'background.paper' }}>
          <DialogTitle>Data</DialogTitle>
          <Box sx={{ border: '1px solid #bfbfbf', p: 1, bgcolor: 'background.paper' }} style={{ height: '25vh', width: '50vh' }} className="ag-theme-material">
            <AgGridReact
              ref={innerGridCartRef}
              columnHoverHighlight={true}
              rowSelection = {'multiple'}
              gridOptions={gridOptions}>
            </AgGridReact>
          </Box>
          <DialogActions>
            <Button id="tabledataclosebtn"
              variant="outlined"
              color="error"
              startIcon={<CancelOutlinedIcon />}
              onClick={handleClickClose}>
              CLOSE</Button>
          </DialogActions>
        </Box>
      </Popper>
    </div>
  )
}

export default TableDataRenderer;