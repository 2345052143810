//TODO : This is harcoded column grouping specific to data audit table, need to be made dynamic

interface IHeaderBaseObject {
    headerName: string,
    children: any[]
}

const RenderChildren = (element: any, columnDefinition: any[]) => {
    let childArray: any[] = [];
    if (element.SubReports.length >= 1 || element.SubReports[0]?.SubReports.length <= 1) {
        element.SubReports.forEach((ele: any) => {

            if (ele.SubReports.length <= 1 && (ele.HeaderName === ele.SubReports[0]?.HeaderName || ele.SubReports.length == 0)) {
                const groupObjects: any = columnDefinition
                    .find((elem: any) => {
                        return elem.field.toLowerCase() === ele.HeaderName.toLowerCase()
                    });

                let dataType = ele.SubReports.length > 0 ? ele.SubReports[0].SubReports.length > 0 ?
                    ele.SubReports[0].SubReports[0].DataType :
                    ele.SubReports[0].DataType : ele.DataType;
                if (groupObjects) {
                    const _filter = ((dataType !== null || dataType !== undefined) && dataType == 'Number') ? 'agMultiColumnFilter' :
                    (element?.ReportYN?.toLowerCase() == "y" || dataType == 'Report') ? 'agSetColumnFilter' : 'agMultiColumnFilter';

                    groupObjects.filter = _filter;

                    groupObjects.filterParams = ((dataType !== null || dataType !== undefined) && dataType == 'Number') ? {
                        filters: [
                            {
                                filter: 'agNumberColumnFilter',
                            },
                            {
                                filter: 'agSetColumnFilter',
                                
                            },
                            
                        ]
                       
                    } : {
                        suppressMiniFilter: true,
                        suppressSelectAll: true,
                        valueFormatter: setValueFormatter
                    };


                    childArray.push(groupObjects);
                }
            }
            else {
                childArray.push(RenderChildren(ele, columnDefinition));
            }
        });
    }
    else {
        element.SubReports.forEach((ele: any) => {
            childArray.push(RenderChildren(ele, columnDefinition));
        });
    }

    let ele = {
        groupId: element.HeaderName.split(" ").join("").toLowerCase(),
        headerName: element.HeaderName,
        children: childArray
    };

    return ele;
};

const setValueFormatter = (params: any) => {
    const setValue = params.value;
    if (setValue) {
        return "YES ( Available )"
    } else {
        return "NO ( Not Available )";
    }
};

export const constructColumnGrouping = (columnDefinition: any[], response: any) => {
    let _columnDefinition: any[] = [...columnDefinition];

    columnDefinition = [];
    response?.forEach((element: any) => {
        columnDefinition.push(RenderChildren(element, _columnDefinition));
    });

    return columnDefinition;
}