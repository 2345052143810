var W3CWebSocket = require('websocket').w3cwebsocket;

export const setUpWebSocket = (
    clientUrl: string,
    broadcastMessages: any[],
    setBroadcastMessages: any,
    distributeForScreenRefresh: any) => {
    const client = new W3CWebSocket(clientUrl);

    client.onopen = () => {
        console.log('WebSocket Client Connected');
    };

    client.onmessage = (message: any) => {
        const incomingMessage: any = JSON.parse(message.data);
        const existingInList = broadcastMessages
            .filter((element: any) => {
                return element.id == incomingMessage.id
            });

        if (existingInList.length == 0) {
            setBroadcastMessages(function (_broadcastMessages: any) {
                return [incomingMessage, ..._broadcastMessages.reverse()];
            });

            distributeForScreenRefresh(incomingMessage);
        }
    };
    client.onerror = function () {
        console.log('Connection Error');
    };
}