import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

interface IAlertDialogProps {
    open: boolean,
    title: string,
    content: string,
    setAlertDialogOpen: (open: boolean) => void,
    yesHandler: (initiate: boolean) => void,
    noHandler: (initiate: boolean) => void
}
export default function AlertDialog(props: IAlertDialogProps) {

    const handleYes = () => {
        props.yesHandler(true);
        props.setAlertDialogOpen(false);
    };

    const handleNo = () => {
        props.noHandler(false);
        props.setAlertDialogOpen(false);
    };

    const handleClose = () => {
        props.setAlertDialogOpen(false);
    };

    return (
        <div>
            <Dialog
                open={props.open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">
                    {props.title}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {props.content}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button id="yes" onClick={handleYes} autoFocus>Yes</Button>
                    <Button id="no" onClick={handleNo}>No</Button>
                    <Button id="cancel" onClick={handleClose}>Cancel</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
