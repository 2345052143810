import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-balham.css';
import 'ag-grid-enterprise';
import './ValuationRenderer.css';

import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Paper, Popper, Tooltip, Typography } from '@mui/material';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import RestartAltOutlinedIcon from '@mui/icons-material/RestartAltOutlined';
import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined';

import { useState } from 'react';
import * as api from 'src/Utility/api';
import { ITableGridColumnDefinition } from '../../@State/types';
import { SideBarDef } from 'ag-grid-enterprise';
import { useSearchParams } from 'react-router-dom';
import { uiBase_url } from 'src/Utility/api';

interface IValuationPickerProps {
    orderPickHandler: (orderData: any) => void;
    gridType : string;
}

function ValuationRenderer(props: any) {
    const [rowIndex] = useState(props);
    const [columns, setColumns] = React.useState<any[]>([]);
    const [valuationData, setValuationData] = React.useState<any[]>([]);
    const [searchParams, setSearchParams] = useSearchParams();
    const valuationGridRef: any = useRef();
    const [open, setOpen] = React.useState(false);
    const [isEstimate, setIsEstimate] = React.useState(props.gridType === 'requested')
    const placement = 'bottom-start';
    const id = open ? 'settings-popper' : undefined;
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const handleClickOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
        if(isEstimate && (props?.data?.ZeroCurrentValueCount > 0 || props?.data?.ZeroProposedValueCount > 0) ||
            (props?.data?.ZeroProposedValueCount == 0 && props?.data?.ZeroCurrentValueCount == 0 &&
                                    props?.data?.OrderStatus === 'Placed' && (props?.data?.ZeroDOProposedValueCount == 0 && props?.data?.DOApprovalValueCount == 0 && props?.data?.OOApprovalValueCount > 0))) {
            setAnchorEl(event.currentTarget);
            setOpen(true);
        }
        else if(!isEstimate){
        if((props?.data?.ZeroCurrentValueCount > 0 || props?.data?.ZeroProposedValueCount > 0) ||
        (props?.data?.ZeroProposedValueCount == 0 && props?.data?.ZeroCurrentValueCount == 0 &&
                                props?.data?.OrderStatus === 'Placed' && props?.data.DOApprovalValueCount > 0))
        {
            setAnchorEl(event.currentTarget);
            setOpen(true);
        }
    }
    };

    const handleClickClose = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(null);
        setOpen(false);
    };

    const loadValuationByOrderHandler = () => {
        props.orderPickHandler(props);
    }

    return (
        <div style={{height:"100%"}}>
            <IconButton id="loadvaluation"
            sx={{height:'100%',width:'100%'}}
                color="primary"
                size="small"
                onMouseOver={(event : any) => handleClickOpen(event)}
                onMouseOut={(event:any) => handleClickClose(event)} 
                onClick={loadValuationByOrderHandler}>
                $$$
            </IconButton >

            <Popper id={id} open={open} placement={placement} anchorEl={anchorEl} style={{ zIndex: '50000' }}>
                <Box sx={{ border: '2px solid #bfbfbf', p: 1, bgcolor: 'background.paper' }}>
                        <Paper>
                            {isEstimate && ( (props?.data?.ZeroCurrentValueCount > 0 || props?.data?.ZeroProposedValueCount > 0) ||
                                        (props?.data?.ZeroProposedValueCount == 0 && props?.data?.ZeroCurrentValueCount == 0 &&
                                    props?.data?.OrderStatus === 'Placed' && props?.data.ZeroDOProposedValueCount == 0 && props?.data?.OOApprovalValueCount > 0)) && <div>
                                <Typography>Missing Estimate Data : </Typography>
                                { props?.data?.ZeroCurrentValueCount > 0 && <Typography>{props?.data?.ZeroCurrentValueCount} records missing current value</Typography> }
                                { props?.data?.ZeroProposedValueCount > 0 && <Typography>{props?.data?.ZeroProposedValueCount} records missing proposed value</Typography> }
                                { props?.data?.ZeroProposedValueCount == 0 && props?.data?.ZeroCurrentValueCount == 0 &&
                                    props?.data?.OrderStatus === 'Placed' &&  props?.data.ZeroDOProposedValueCount == 0 &&  props?.data?.OOApprovalValueCount > 0 && 
                                    <Typography>{props?.data?.OOApprovalValueCount} records missing approval</Typography> }
                            </div>
                        }

                            {!isEstimate && ( props?.data?.ZeroCurrentValueCount > 0 || props?.data?.ZeroProposedValueCount > 0 || props?.data?.DOApprovalValueCount > 0) && <div>
                                <Typography>Missing Valuation Data : </Typography>
                                { props?.data?.ZeroCurrentValueCount > 0 && <Typography>{props?.data?.ZeroCurrentValueCount} records missing current value</Typography> }
                                { props?.data?.ZeroProposedValueCount > 0 && <Typography>{props?.data?.ZeroProposedValueCount} records missing proposed value</Typography> }
                                { props?.data?.DOApprovalValueCount > 0 && <Typography>{props?.data?.DOApprovalValueCount} records missing approval </Typography> }
                            </div>
                        }
                        </Paper>
                </Box>
        </Popper>

        </div>
    )
}

export default ValuationRenderer;