import axios, { AxiosRequestConfig } from 'axios';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import {validURL} from './common';
import config from '../config'

export const env: string = 'PROD';
export let uiBase_url = "";
let base_url = '';
let auth_url = '';
let clientId = '';
let azure_url = ''
let clientSecret = ''
let InstrumentationKey = ''
let appInsights: ApplicationInsights;

export let logout_url: string = '';
  
if (env === 'OASIS') {
    base_url = 'https://oasis-dx-api.azurewebsites.net';
    auth_url = 'https://oasis-dx.azurewebsites.net/.auth/me';
    logout_url = 'https://oasis-dx.azurewebsites.net/.auth/logout';
    uiBase_url = 'https://oasis-dx.azurewebsites.net/';
    clientId = 'cd9497eb-fc10-40e7-aa18-a6552acb4360';
    InstrumentationKey = "cda32b64-6d78-47a7-afbd-d231e89aa068";
    azure_url = 'https://envoy-upload-replace-fn.azurewebsites.net/api';
} else if (env === 'ENVOY') {
    base_url = 'https://envoy-web-api.azurewebsites.net';
    auth_url = 'https://envoy-beta.azurewebsites.net/.auth/me';
    logout_url = 'https://envoy-beta.azurewebsites.net/.auth/logout';
    uiBase_url = 'https://envoy-beta.azurewebsites.net/';
    clientId = 'f31ac047-90cf-4a75-ad0b-5583fbeab0d8';
    InstrumentationKey = "b635a81f-3ad8-4496-94a8-ed638d222873";
    azure_url = 'https://envoy-dev-upload-replace-fn.azurewebsites.net/api';
} else if (env === 'PROD') {
    base_url = 'https://onyxdatawebapi.azurewebsites.net';
    auth_url = 'https://onyxdata-oasis.org/.auth/me';
    logout_url = 'https://onyxdata-oasis.org/.auth/logout';
    uiBase_url = 'https://onyxdata-oasis.org/';
    clientId = '55a4bc49-161e-4499-9b6e-c89ddda2ccaf';
    InstrumentationKey = "139453da-5e2a-4273-90d7-a46a0a5c5d6b";
    azure_url = 'https://envoy-dev-upload-replace-fn-prod.azurewebsites.net/api';
}

// App Insights //
appInsights = new ApplicationInsights({
    config: {
      instrumentationKey: InstrumentationKey,
      enableAutoRouteTracking: false,
      enableRequestHeaderTracking : false,
      enableResponseHeaderTracking : false,
    },
  });
  
  let filteringFunction = (envelope : any) => {
    if (envelope.baseType === 'MessageData') {
        return true;
    }
    return false;
  };

  appInsights.loadAppInsights();
  appInsights.addTelemetryInitializer(filteringFunction);
// ------------- //


// Interceptors//
axios.interceptors.response.use(response => {
    return response;
}, error => {
    if (error.response.status === 401) {
        sessionStorage.removeItem('access_Token');
        sessionStorage.removeItem('userEmailId');
        sessionStorage.removeItem('userName');
        sessionStorage.removeItem('Operators');
        window.location.reload();
    }
    return error;
});

//-----------//

export const API_GET_AUTH_TOKEN = (params?: any): Promise<any> => {
    return axios.get(auth_url, { ...params });
}

export const API_GET = (url: string, params?: any, isAuthUrl?: boolean): Promise<any> => {
    if (!isAuthUrl)
        url = base_url.concat(url);

    const token = sessionStorage.getItem('access_Token');
   
    return axios.get(url, {...params,  headers: {
        "Authorization": `Bearer ${token}`,
        "ClientId": clientId,
        "ClientSecret" : clientSecret
    }});
}

export const API_GET_EXTERNAL = (url: string, params?: any, isAuthUrl?: boolean): Promise<any> => {   
    return axios.get(url, {...params,  headers: {
    }});
}

export const API_POST = (url: string, params?: any): Promise<any> => {
    url = base_url.concat(url);
    const token = sessionStorage.getItem('access_Token');

    return axios.post(url, {...params}, {headers: {"Authorization": `Bearer ${token}`,
                                                                            "ClientId": clientId,
                                                                            "ClientSecret" : clientSecret} });
}

export const API_AF_POST = (url: string, params?: any): Promise<any> => {
    url = azure_url.concat(url);
    return axios.post(url, { ...params });
}

export const API_POST_FILEUPLOAD = (url: string, params?: any): Promise<any> => {
    url = base_url.concat(url);
    return axios.post(url, { ...params }, {
        headers: {
            "Content-Type": "multipart/form-data",
        },
        timeout: 1000 * 60
    });
}

export const API_LOGIN_POST = (url: string, params?: any): Promise<any> => {
    url = base_url.concat(url);
    return axios.post(url, { ...params });
}

export const API_AF_POST_FILEUPLOAD = (url: string, params?: any): Promise<any> => {
    url = azure_url.concat(url);
    const token = sessionStorage.getItem('access_Token');
    return axios.post(url, { ...params }, {
        headers: {
            "Content-Type": "multipart/form-data",
            "Authorization": `Bearer ${token}`,
            "ClientId": clientId,
            "ClientSecret" : clientSecret
        },
        timeout: 1000 * 60
    });
}

export const debounce = (func: any, timeout = 250) => {
    let timer: any;
    return (...args: any) => {
        clearTimeout(timer);
        timer = setTimeout(() => { func.apply(this, args); }, timeout);
    };
}

export const LOG_MESSAGE = (messageKeys: string[], properties?: { [key: string]: any }) => {
    const message  = messageKeys.join("|")
    appInsights.trackTrace({ message: message}, properties);
}