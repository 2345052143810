import * as React from 'react';

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-balham.css';
import 'ag-grid-enterprise';

import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import { Button, IconButton, Paper, Tooltip } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import DoneIcon from '@mui/icons-material/Done';
import * as api from 'src/Utility/api';
import './MyOrdersFilter.css';
import { ExchangeOrderType, IOrderInfo, ITableGridColumnDefinition } from '../../../@State/types';
import CartDataRenderer from '../../CellRenderers/CartDataRenderer';
import TradeTypeRenderer from '../../Cart/TradeTypeRenderer';
import DateTimeEditor from '../../Cart/DateTimeEditor';
import DateTimeRenderer from '../../CellRenderers/DateTimeRenderer';
import DialogViewRenderer from '../../Cart/DialogViewRenderer';
import OrderApprovalRenderer from '../../Cart/OrderApprovalRenderer';
import { ValueFormatterParams } from 'ag-grid-enterprise';
import SeletedReportsRenderer from '../../Cart/ReportGroup/SelectedReportsRenderer';
import { AppContext } from 'src/Components/Dashboard/@State/context/AppContext';
import RefreshIcon from '@mui/icons-material/Refresh';

interface IMyOrdersFilterProps {
    operator: string,
    type: string,
    selectedOrder: string[],
    loggedInUser: string;
    loggedInUserMailId: string;
    orderPickHandler: (orderData: any[]) => void
}

export default function MyOrdersFilter(props: IMyOrdersFilterProps) {
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const [columns, setColumns] = React.useState<any[]>([]);
    const [requestedOrders, setRequestedOrders] = React.useState<any[]>([]);
    const [selectedOrder, setselectedOrder] = React.useState<any[]>([]);
    const [title, setTitle] = React.useState<any>("Orders");
    const [toolTip, setTooltip] = React.useState<any>("Orders");
    const open = Boolean(anchorEl);
    const displayColumns = [
        'OrderId',
        'TradeGroupId',
        'TradeType',
        'StartDate',
        'EndDate',
        'Amount',
        'RequestedBy',
        'RequestedTo',
        'SelectedDatasets',
        'Data'];
    const dateColumns = ["StartDate", "EndDate"];
    const tradeTypesList = ['All', 'Purchase', 'Trade', 'Rental'];
    const approvalStatusList = ['All', 'Closed', 'Pending', 'In Progress', 'Executed'];
    const { reportGroups, fetchOperatorOrders, orders } = React.useContext(AppContext) as ExchangeOrderType;

    const requestedGridRef: any = React.useRef();

    React.useEffect(() => {
        loadCurrentInformation();
    }, [requestedOrders]);

    React.useEffect(() => {
        if (props.operator !== '' && props.operator !== undefined) {
            fetchOperatorOrders(props.operator);
        }
    }, [props.operator]);

    React.useEffect(() => {
        if(orders.length > 0){
            loadPendingAndGroupGridData(orders);
            setColumns(Object.keys(orders[0]));
        }    
    }, [orders]);

    React.useEffect(() => {
        setselectedOrder(props.selectedOrder);
        if(props.selectedOrder)
        {
            if(props.selectedOrder.length > 1)
            {
                setTitle("Order #MULTIPLE");
                setTooltip("Order #" + props.selectedOrder.toString());
            }
            else if(props.selectedOrder.length == 1)
            {
                setTitle("Order #" + props.selectedOrder[0]);
                setTooltip("Order #" + props.selectedOrder[0]);
            }
            else
            {
                setTitle("Orders");
            }
         }
        
    }, [props.selectedOrder]);

    const defaultColDef = React.useMemo(() => {
        return {
            resizable: true,
            wrapHeaderText: true,
            autoHeaderHeight: true,
            editable: false,
            headerCheckboxSelectionFilteredOnly: true
        };
    }, []);

    const gridOptions = {
        // PROPERTIES
        defaultColDef: defaultColDef,
        pagination: false,
        defaultExportParams: {
            columnGroups: true,
            fileName: 'name_of_file'
        }
    }

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
        if (props.operator !== '') {
            if(orders.length > 0){
                loadPendingAndGroupGridData(orders);
                setColumns(Object.keys(orders[0]));
            }
        }
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const loadCurrentInformation = () => {
        if (requestedOrders !== undefined && requestedOrders.length >= 0) {
            let columnDefinitions = constructColumnDefinitions(requestedOrders);
            if (requestedGridRef !== undefined && requestedGridRef.current !== undefined) {
                if (requestedGridRef.current?.api !== 'undefined') {
                    requestedGridRef.current?.api.setColumnDefs(columnDefinitions);
                    requestedGridRef.current?.api.setRowData(requestedOrders);
                    requestedGridRef.current?.api.sizeColumnsToFit();
                } else {
                    requestedGridRef.current.props.gridOptions.columnDefs = columnDefinitions;
                    requestedGridRef.current.props.gridOptions.rowData = requestedOrders;
                }

                requestedGridRef?.current?.columnApi?.autoSizeAllColumns(false);
                requestedGridRef.current?.api.forEachNode((rowNode : any) => {
                    if(props.selectedOrder.includes(rowNode.data.OrderId))
                    {
                        rowNode.setSelected(true, false);
                    }
                  });
            }
        }
    }

    const formatHeaderName = (value: string) => {
        return value.charAt(0).toUpperCase() + value.slice(1);
    }

    const constructColumnDefinitions = (tableData: IOrderInfo[]) => {
        let columnDefinitions: ITableGridColumnDefinition[] = [];
        columns.forEach((column) => {
            let header = column;
            if (displayColumns.includes(header)) {
                if (header === "Data") {
                    columnDefinitions.push({
                        field: header,
                        //type: 'editableColumn',
                        headerName: header,
                        headerTooltip: header,
                        cellRenderer: CartDataRenderer,
                        cellRendererParams: {
                            cartData: tableData,
                            isReadOnly:true,
                            selectedOperator : props.operator
                        },
                        cellStyle: { justifyContent: 'center' },
                        suppressSizeToFit: true
                    })
                } else if (header === "SelectedDatasets") {
                    columnDefinitions.push({
                        field: header,
                        //type: 'editableColumn',
                        headerName: formatHeaderName("Datasets"),
                        headerTooltip: formatHeaderName("Datasets"),
                        suppressSizeToFit: true,
                        cellRenderer: SeletedReportsRenderer,
                        cellRendererParams: {
                            operator: props.operator
                        },
                        cellClass: ['ag-cell-custom'],
                        sortable: true,
                        cellStyle: { textAlign: 'right' },
                        filter: 'agTextColumnFilter'
                    })
                } else if (header === "TradeType") {
                    columnDefinitions.push({
                        field: header,
                        //type: 'editableColumn',
                        headerName: formatHeaderName(header),
                        headerTooltip: formatHeaderName(header),
                        suppressSizeToFit: true,
                        cellEditorPopup: true,
                        singleClickEdit: true,
                        editable: false,
                        cellEditor: TradeTypeRenderer,
                        cellClass: ['ag-cell-custom'],
                        sortable: true,
                        filter: 'agTextColumnFilter',
                        suppressToolPanel: false
                    })
                } else if (dateColumns.includes(header)) {
                    columnDefinitions.push({
                        field: header,
                        //type: 'editableColumn',
                        suppressSizeToFit: true,
                        singleClickEdit: true,
                        headerName: formatHeaderName(header),
                        headerTooltip: formatHeaderName(header),
                        cellEditorPopup: true,
                        editable: false,
                        cellEditor: DateTimeEditor,
                        cellEditorParams: {
                            column : header
                        },
                        cellRenderer: DateTimeRenderer,
                        cellRendererParams: {
                            showTime : false
                        },
                        cellClass: ['ag-cell-custom'],
                        suppressToolPanel: false,
                        filter: (column.DataType !== "Int32" && column.DataType !== "Int64") ? 'agMultiColumnFilter' : 'agNumberColumnFilter'
                    })
                } else if (header === "Amount") {
                    columnDefinitions.push({
                        field: header,
                        //type: 'editableColumn',
                        suppressSizeToFit: true,
                        headerName: formatHeaderName(header),
                        headerTooltip: formatHeaderName(header),
                        filter: 'agNumberColumnFilter',
                        cellClass: ['ag-cell-custom'],
                        cellStyle: { textAlign: 'right' },
                        sortable: true,
                        editable: false,
                        valueFormatter: currencyFormatter,
                        cellEditor: 'numericCellEditor'
                    })
                } else {
                    if (header === "OrderId") {
                        columnDefinitions.push({
                            field: header,
                            type: 'editable',
                            headerName: formatHeaderName(header),
                            headerTooltip: formatHeaderName(header),
                            suppressSizeToFit: true,
                            sortable: true,
                            cellClassRules: getOrderCellClassRules(),
                            cellRenderer: DialogViewRenderer,
                            cellRendererParams: {
                                operator: props.operator,
                                loadCounter: false,
                                loggedInUser: props.loggedInUser,
                                loggedInUserMailId: props.loggedInUserMailId,
                                loadPendingRequests: [],
                                formMessage: '',
                                setShowProgress: null,
                                closeCart: handleClose,
                                initiateExchange: null,
                                gridType: ''
                            },
                            suppressToolPanel: false
                        });
                    } else {
                        let columnDefinition: ITableGridColumnDefinition = {
                            field: header,
                            //type: 'editableColumn',
                            headerName: formatHeaderName(header),
                            headerTooltip: formatHeaderName(header),
                            cellClass: ['ag-cell-custom'],
                            sortable: true,
                            // sort: (gridType === "grouped" && header === "TradeGroupId") ? 'asc' : 'none',
                            filter: 'agTextColumnFilter'
                        };
                        if (["TradeGroupId", "RequestedBy", "RequestedTo"].includes(header)) {
                            columnDefinition.width = (header.length * 13);
                            columnDefinition.suppressSizeToFit = true;
                        }
                        columnDefinitions.push(columnDefinition);
                    }
                }
            }
        });

        columnDefinitions.push({
            field: 'ApprovalStatus',
            //type: 'editableColumn',
            headerName: 'ApprovalStatus',
            headerTooltip: 'ApprovalStatus',
            cellClass: ['ag-cell-custom'],
            cellRenderer: OrderApprovalRenderer,
            cellRendererParams: {
                loggedInUserMailId: props.loggedInUserMailId,
                loggedInUser: props.loggedInUser,
                loadPendingRequests: [],
                formMessage: '',
                setShowProgress: [],
                operator: props.operator
            },
            filter: 'agTextColumnFilter',
            suppressSizeToFit: true,
            cellStyle: { justifyContent: 'center' }
        });

        if (columnDefinitions.length > 0) {
            columnDefinitions[0].checkboxSelection = true;
            columnDefinitions[0].checkboxSelection = true;
        }
        return columnDefinitions;
    }

    const getOrderCellClassRules = () => {
       
        return {
            "pending-dialogue": (params: any) => (params.data.OrderEvent === "COUNTER_CREATED"
                || params.data.OrderEvent === "COUNTER_AMEND"),
            "approved-order": (params: any) => (params.data.OrderEvent === "ORDER_APPROVED"),
            "rejected-order": (params: any) => (params.data.OrderEvent === "ORDER_REJECTED"),
            "new-order": (params: any) => (params.data.OrderId === "N/A")
        }
    }

    const currencyFormatter = (params: ValueFormatterParams) => {
        return '$' + formatNumber(params.value);
    }

    const formatNumber = (number: number) => {
        return Math.floor(number)
            .toString()
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    }

    const loadPendingAndGroupGridData = (responseData: any) => {
        let tableData: any[] = responseData;
        let _requestedOrders: any[] = [];

        tableData = tableData.filter((data) => {
            return (data.RequestedBy === props.operator || data.RequestedTo === props.operator);
        });
        
        tableData.forEach((data) => {
            if (data.TradeGroupId === "N/A") {
                if(props.type == "Valuation")
                {
                    if (data.RequestedTo === props.operator && data.OrderStatus !== "Pending") {
                        _requestedOrders.push(data);
                    }
                }
                else if(props.type == "DataTransfer")
                {
                    if ((data.RequestedTo === props.operator|| data.RequestedBy === props.operator)  && data.OrderStatus === "Executed") {
                        _requestedOrders.push(data);
                    }
                }
                else
                {
                    if (data.RequestedBy === props.operator) {
                        _requestedOrders.push(data);
                    }
                }
                
            }
        });

        setRequestedOrders(_requestedOrders);
    }

    const chooseSelection = (event: React.MouseEvent<HTMLButtonElement>) => {
        let selectedRows = requestedGridRef.current!.api.getSelectedRows();
        
        props.orderPickHandler(selectedRows);
        setAnchorEl(null);
    };

    const autoSizeColumns = (timeoutPeriod: number) => {
        setTimeout(() => {
            requestedGridRef.current.columnApi.autoSizeAllColumns(false);
        }, timeoutPeriod);
    }

    const onFirstDataRendered = React.useCallback((timeoutPeriod: number) => {
        autoSizeColumns(timeoutPeriod);
    }, []);
    
    const refreshGrid = (event: React.MouseEvent<HTMLButtonElement>) => {
        if (props.operator !== '') {
            fetchOperatorOrders(props.operator);
        }
    };

    return (
        <>
            <Tooltip title={toolTip}>
                        <Button id="outlined" sx={{ marginLeft: '10px',fontSize:'11px' }} variant="outlined" startIcon={<AssignmentOutlinedIcon />} onClick={handleClick}>
                            {title}
                        </Button>
            </Tooltip>
         
            <Popover
                id="simple-popover"
                className="ag-theme-balham"
                open={true}
                anchorEl={anchorEl}
                sx={{ zIndex: 1,display: open ? "" : "none" }}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <div id='MyOrdersGridContent' style={{ height: '25vh' }} className="ag-theme-balham">
                    <AgGridReact
                        ref={requestedGridRef}
                        gridOptions={gridOptions}
                        rowSelection = {'multiple'}
                        onFirstDataRendered={() => { onFirstDataRendered(5) }}
                        overlayNoRowsTemplate={'<span style="padding: 10px; border: 2px solid #444; background: lightgoldenrodyellow;">No requested orders!!</span>'}>
                    </AgGridReact>
                </div>
                <div style={{textAlign: 'right'}}>
                     <IconButton id="refreshgrid" color="success" aria-label="done" onClick={refreshGrid}>
                                <RefreshIcon />
                    </IconButton>
                    <IconButton id="chooseSelection" color="success" aria-label="done" onClick={chooseSelection}>
                                <DoneIcon />
                    </IconButton>
                    <IconButton id="handleClose" color="error" aria-label="cancel" onClick={handleClose}>
                            <CancelIcon />
                    </IconButton>
                </div>

            </Popover>
        </>
    );
}
