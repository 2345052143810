export interface IExplorerTableInfo {
    schema: string,
    name: string,
    pkColumnName?: string
}

export type ExplorerType = {
    tableList: IExplorerTableInfo[];
    saveTables: (tables: IExplorerTableInfo[]) => void;
};

export type MapViewType = {
    reportConfigurations?: any[];
    dataAuditData: any[];
    countyKmlFiles?: any[];
    mapFilters: any[];
    setMapFilters: React.Dispatch<React.SetStateAction<any[]>>;
    loadInitialPrerequisiteData: () => void;
};


export interface ITableGridColumnDefinition {
    field?: string,
    type?: string,
    headerName: string,
    headerTooltip?: string,
    width?: number,
    sortable?: boolean,
    pivot?: boolean,
    enablePivot?: boolean,
    rowGroup?: boolean,
    enableRowGroup?: boolean,
    enableValue?: boolean,
    aggFunc?: string,
    filter?: any,
    resizable?:boolean,
    headerComponent? : any,
    headerComponentParams? :any, 
    filterParams?: any,
    editable?: boolean,
    cellEditor?: any,
    cellEditorPopup?: boolean,
    cellEditorParams?: any,
    cellStyle?: any,
    hide?: boolean,
    cellRenderer?: any,
    cellRendererParams?: any,
    singleClickEdit?: boolean,
    headerCheckboxSelection?: boolean,
    checkboxSelection?: boolean,
    showDisabledCheckboxes?: boolean,
    suppressToolPanel?: boolean,
    floatingFilter?: boolean
    cellClass?: any[],
    sort?: string,
    cellEditorPopupPosition?: string,
    valueFormatter?: any,
    valueGetter?: any,
    suppressSizeToFit?: boolean,
    tooltipField?: string,
    cellClassRules?: any,
    showRowGroup?: boolean,
    minWidth?: any,
    autoHeight?: boolean,
    suppressMenu? : boolean,
    cellRendererSelector? : any,
    isPropreitary?: boolean
}

export interface ITableGridRowDefinition {
    [key: string]: string
}

export type TableType = {
    tableInfo: ITableData[],
    rows: ITableGridRowDefinition[];
    columns: ITableGridColumnDefinition[];
    pickListDefinitions?: any[];
    fileUploadColumns?: any[];
    approvalConfig?: any[];
    approvalStatus?: any[];
    operators?: any[];
    countyKmlFiles?: any[];
    reportGroups?: any[];
    dropDownSelectListCollection?: IDropDownSelectList[];
    reportConfigurations?: any[];
    saveRowDefinition: (rowDefinition: ITableGridRowDefinition[]) => void;
    saveColumnDefinition: (columnDefinition: ITableGridColumnDefinition[]) => void;
    saveDropDownSelectList: (_dropDownSelectList: IDropDownSelectList) => void;
    loadInitialPrerequisiteData: () => void;
    saveTableInfo: (_tableData: ITableData) => void;
    clearDropDownSelectList: () => void;
    clearTableInfo: () => void;
}

export interface IColumnSelectionInfo {
    SchemaName: string,
    TableName: string,
    columnSelectionInfo: any
}

export type CartType = {
    columnSelectionList: IColumnSelectionInfo[];
    reportGroups?: any[];
    operatorList?: string[];
    saveColumnSelection: (columnSelection: any, nodeData: any) => void;
    loadInitialPrerequisiteData: () => void;
    loadInitialOperatorData: (_loggedInUser: any) => void;
};

export interface IAction {
    type: string,
    payload: any
}

export interface IDropDownSelectList {
    schema: string,
    tableName: string,
    colId: string,
    data: any
}

export interface ITableData {
    schema: string,
    tableName: string,
    data: any
}
export interface IColumnList {
    index: number,
    ColumnName: string,
    DataType: string,
    Length: string,
    ColumnUnique: string,
    ForeignKey: string,
    EncryptedYn: string,
    Tooltip: string,
    Label: string,
    ColumnDescpn: string

}
export interface Itable {
    schemaName: string,
    tableName: string,
    columnList: IColumnList[]
}

export interface IColumnLabelData {
    columnName: string,
    columnLabel: string,
    columnDescription: string
}

export interface ICartInfo {
    schemaName: string,
    tableName: string,
    dataRows: any[],
    isExchange?: boolean,
    isModified?: boolean,
    dataMap: Map<string, any[]>,
    operatorFilter: IFilterApplied[]
}

export interface IUserSecurityInfo {
    SecurityGroupId: string,
    SecurityGroupName: string,
    SecurityGroupDescription: string,
    Operators: IOperatorInfo[],
}

export interface IOperatorInfo {
    OperatorId: string,
    ShortName: string,
    LegalName: string
}

export interface IFilterApplied {
    operator: string,
    filterApplied: string[]
}

export interface IOperatorInfo {
    schemaName: string,
    tableName: string,
    dataRows: any[]
}

export interface IEmailInfo {
    fromAddress?: string,
    toList?: string[],
    ccList?: string[],
    templateId?: string,
    dynamicTemplateData: {},
    attachment: {},
    selectedReports: string,
    auditIds: string
}

export interface ICommon {
    Info1?: string,
    Info2?: string,
    Info3?: string,
    Active_YN?: string,
    EffStartDate?: string,
    EffEndDate?: string,
    CreatedBy?: string,
    CreatedDate?: string,
    LastModifiedBy?: string,
    LastModifiedDate?: string
}

export interface IOrderInfo extends ICommon {
    OrderId?: string,
    TradeGroupId?: string,
    TradeType?: string,
    StartDate?: string,
    EndDate?: string,
    Amount?: number,
    RequestedBy?: string,
    RequestedTo?: string,
    SelectedReports?: string,
    Data?: any,
    PaymentFrequency?: string,
    OrderStatus?: string,
    OrderEvent?: string,
    OrderedBy?: string,
    OrderedDate?: string,
    SchemaName?: string,
    TableName?: string
    IsCurrent?: boolean,
    IsGrouped?: boolean,
    IsPending?: boolean
}

export interface ISecurityPermissions {
    isAddAllowed: boolean,
    isEditAllowed: boolean,
    isDeleteAllowed: boolean,
    isViewAll: boolean,
    isRowLevelSecurity: boolean,
    isLoaded: boolean,
    operators: string
}

export interface IOrderWorkflow extends ICommon {
    OrderId: string,
    WorkflowType: string,
    DataAuditId: string,
    SettingsId: string,
    Operator: string,
    API: string,
    WellName: string,
    ReportName: string,
    ApprovalLevel: number,
    UserEmailId: string,
    UserName: string,
    StatusId: number,
    ApprovalDate: string,
    Comment: string
}

export interface RenderTree {
    id: string;
    name: string;
    children?: RenderTree[];
}

export interface IExchangeOrder {
    requestedTo?: string;
    requestedBy?: string;
    tradeGroupId?: number;
    selectedReports?: string;
}

export interface IUpdateOrder {
    orderId?: string;
    rowIndex?: number;
    requestedTo?: string;
    requestedBy?: string;
    data?: string;
}
export interface TradeOrderFromTo {
    OrderId: string,
    OrderBy: string;
    OrderTo: string
}

export interface IMessaging{
    Name:string,
    Tag:string,
    Action:string,
    EmailAddress:string,
    Operator:string,
    Message:string,
    OperatorTo:string,
    isEmailNotification:boolean,
    ChangedJSONObject?:string
}

export interface IResizePosition {
    width: number,
    height: number
}


export type ExchangeOrderType = { // This type is used in app context , this cannot be speccific to exchange order type , need to change to a common type //
    exchangeOrder: IExchangeOrder;
    saveExchangeOrder: (exchangeOrder: IExchangeOrder) => void;
    modifiedOrder: IUpdateOrder;
    saveModifiedOrder: (modifiedOrder: IUpdateOrder) => void;
    reportGroups: any[],
    loadInitialPrerequisiteData: () => void;
    orderWells: any[],
    orders : any[],
    oppositeTradeWells: any[],
    pickListDefinitions: any[] | undefined,
    tradeOrderFromTo: TradeOrderFromTo,
    oppositeTradeOrderFromTo: TradeOrderFromTo,
    isMapViewDollar: boolean,
    orderReports: string[],
    screenUpdaterTransaction: any[] | undefined,
    screenUpdaterEstimates: any[] | undefined,
    screenUpdaterValuation: any[] | undefined,
    ordersWithEstimates: any[],
    betaDataAuditInfo: boolean,
    isInfoColumnsOperator: string,
    setTradeOrderFromTo: React.Dispatch<React.SetStateAction<TradeOrderFromTo>>,
    setOppositeTradeOrderFromTo: React.Dispatch<React.SetStateAction<TradeOrderFromTo>>
    setOrderWells: React.Dispatch<React.SetStateAction<string[]>>,
    setOppositeTradeWells: React.Dispatch<React.SetStateAction<string[]>>,
    setIsMapViewDollar: React.Dispatch<React.SetStateAction<boolean>>,
    setOrderReports: React.Dispatch<React.SetStateAction<string[]>>,
    setScreenUpdaterTransaction: React.Dispatch<React.SetStateAction<any[] | undefined>>,
    setScreenUpdaterEstimates: React.Dispatch<React.SetStateAction<any[] | undefined>>,
    setScreenUpdaterValuation: React.Dispatch<React.SetStateAction<any[] | undefined>>,
    setBetaDataAuditInfo: React.Dispatch<React.SetStateAction<boolean>>,
    setDataAuditData: React.Dispatch<React.SetStateAction<any[]>>,
    setIsInfoColumnsOperator: React.Dispatch<React.SetStateAction<string>>,
    fetchReportDisplayName: (reportNames: string) => string,
    fetchCommonAppConfigHandler: (isReload?: boolean) => void,
    fetchOperatorOrders:(operator: any) => void,
    fetchOrdersWithEstimatesHandler: () => void,
    fetchReportGroupsHandler: () => void,
    fetchDataAuditHandler: () => void,
    CommonAppConfig: any[],
    helpVideoFiles: any[] | undefined,
    loadInitialMapData: () => void;
    reportConfigurations : any[] | undefined,
    dataAuditData : any[],
    dataAuditDataImmutable: any[],
    countyKmlFiles : any[]| undefined,
    IsAdminUser: boolean,
    reportGroupInfo: any[] | undefined,
    setAdminUser: React.Dispatch<React.SetStateAction<boolean>>,
    fetchColumnDisplayName: (columnName: string, schemaName:string, tableName : string) => string,
    operatorList?: string[];
    loadInitialOperatorData: (_loggedInUser: any) => void;
};

export const enum TabOrder {
    MapView = 0,
    Transaction,
    Valuation,
    Estimate,
    DataTransfer,
    DataMaintenance,
    DataQualityDashboard
};

export type CommnentType = {
    comment: string,
    commentDateTime: string,
    commentByUser: string
}

export enum MessageTags {
    Order = "Order",
    Estimates = "Estimates",
    Valuation = "Valuation",
    Approval = "Approval"
};

export enum MessageActions {
    Create = "create",
    Update = "update",
    Delete = "delete"
};

export enum ApprovalStatusAbbrev {
    drafted = "Pending OrderOwner",
    INTInProgress = "Pending OrderOwner",
    INTExecuted = "Pending DataOwner",
    EXTInProgress = "Pending DataOwner",
    EXTExecuted = "Completed",
    Closed = "Closed"
};
