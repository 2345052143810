import { Avatar, Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Fade, FormControl, FormControlLabel, InputLabel, List, ListItem, ListItemAvatar, ListItemText, MenuItem, Popper, Select, styled, Switch, SwitchProps, TextField, Typography } from "@mui/material";
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import TaskAltOutlinedIcon from '@mui/icons-material/TaskAltOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import DoNotTouchOutlinedIcon from '@mui/icons-material/DoNotTouchOutlined';
import PendingOutlinedIcon from '@mui/icons-material/PendingOutlined';
import { AgGridReact } from 'ag-grid-react';

import 'ag-grid-enterprise';

import './ApproveDisapproveRenderer.css';
import 'ag-grid-community/styles/ag-grid.css';
import * as api from 'src/Utility/api';
import React, { useEffect, useMemo, useRef, useState } from "react";
import { ITableGridColumnDefinition } from "../../@State/types";


function ApproveDisapproveRenderer(props: any) {
    const innerGridRef: any = useRef();
    const [checked, setChecked] = React.useState<any>(props.value);
    const [open, setOpen] = React.useState(false);
    const [comment, setComment] = React.useState<string>();
    const [status, setStatus] = React.useState<any>();
    const [approvalHistory, setApprovalHistory] = React.useState<any[]>([]);
    const [columnsToDisplay, setColumnsToDisplay] = React.useState<string[]>(
        ["ApprovalStatusName", "UserName", "EffStartDate", "Comment", "SchemaName", "TableName", "ApprovalLevel"]
    );

    const handleClickOpen = (event: React.MouseEvent<HTMLElement>) => {
        getApprovalHistoryHandler();
        setAnchorEl(event.currentTarget);
        setOpen(true);
    };

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const canBeOpen = open && Boolean(anchorEl);
    const id = canBeOpen ? 'transition-popper' : undefined;
    const approvalConfigEntry: any[] = props.approvalConfig.filter((config: any) => {
        return config.SchemaName === props.schemaName &&
            config.TableName === props.tableName &&
            config.ApprovalLevel === props.approvalLevel
    });

    const isFirstColumn = (params: any) => {
        let displayedColumns = params.columnApi.getAllDisplayedColumns();
        let thisIsFirstColumn = displayedColumns[0] === params.column;
        return thisIsFirstColumn;
    };

    const defaultColDef = useMemo(() => {
        return {
            minWidth: 100,
            resizable: true,
            headerCheckboxSelectionFilteredOnly: true,
            cellStyle: { textAlign: 'left' }
        };
    }, []);

    const [gridOptions, setGridOptions] = useState<any>({
        rowData: [],
        columnDefs: [],
        defaultColDef: defaultColDef,
        pagination: false,
        rowSelection: 'single',
        getRowHeight: () => { return 25 }
    });

    const disableButton = props.loggedInUser !== approvalConfigEntry[0].UserName;

    const ApproveDisapproveHandler = (_approvalStatusId: boolean, _comment: string) => {
        async function ApproveDisapprove() {
            let approvalConfigId = props.approvalConfig.filter((element: any) => {
                return element.SchemaName === props.schemaName
                    && element.TableName === props.tableName
                    && element.ApprovalLevel === props.approvalLevel
            });

            await api.API_POST('/Table/ApproveDisapproveData', {
                ApprovalStatusId: _approvalStatusId,
                comment: _comment,
                lastModifiedBy: props.loggedInUser,
                approvalConfigId: approvalConfigId[0].ApprovalConfigId,
                tablePrimaryKey: props.node.data[props.tablePrimaryKey]
            }).then((response) => {
                props.openSuccess(true);
                props.reloadData(true);
            }).finally(() => {
                props.setShowProgress(false);
            });
        }

        ApproveDisapprove();
    }

    const getApprovalHistoryHandler = () => {
        async function ApproveDisapprove() {
            await api.API_POST('/Table/GetApprovalWorkflowHistory', {
                SchemaName: props.schemaName,
                TableName: props.tableName,
                tablePrimaryKey: props.node.data[props.tablePrimaryKey]
            }).then((response) => {
                setApprovalHistory(response?.data?.TableData);
                setWorkFlowHistoryGrid(response?.data?.TableData);
            }).finally(() => {
                props.setShowProgress(false);
            });
        }

        ApproveDisapprove();
    }

    const getButtonIconHandler = (status: any) => {
        switch (status) {
            case '1': return <CheckCircleOutlineOutlinedIcon color='success' />;
            case '2': return <PendingOutlinedIcon color='warning' />;
            case '3': return <TaskAltOutlinedIcon color='success' />;
            case '4': return <CancelOutlinedIcon color='error' />;
            case '5': return <DoNotTouchOutlinedIcon color='warning' />;
            default:
                break;
        }
    }

    const getButtonColorHandler = (status: any) => {
        switch (status) {
            case '1': return 'success';
            case '3': return 'success';
            case '4': return 'error';
            case '5': return 'warning';
            default:
                break;
        }
    }

    const setWorkFlowHistoryGrid = (_approvalHistory: any) => {
        if (typeof (_approvalHistory) != "undefined") {
            if (_approvalHistory.length > 0) {
                const columns = Object.keys(_approvalHistory[0]);
                let columnDefinition: ITableGridColumnDefinition[] = [];
                columns.forEach((row) => {
                    if (columnsToDisplay.includes(row)) {
                        columnDefinition.push({
                            field: row,
                            //type: 'editableColumn',
                            headerName: row,
                            width: 180,
                            cellClass: ['ag-cell-custom']
                        })
                    }
                });

                if (typeof (innerGridRef?.current?.api) !== 'undefined') {
                    innerGridRef.current.api.setColumnDefs(columnDefinition);
                    innerGridRef.current.api.setRowData(_approvalHistory);
                } else {
                    innerGridRef.current.props.gridOptions.columnDefs = columnDefinition;
                    innerGridRef.current.props.gridOptions.rowData = _approvalHistory;
                }
            }
        }
    }

    return (<>
        <div>
            {(!checked || checked === '2') && <Button onClick={handleClickOpen}
                disabled={disableButton}>Pending</Button>
            }
            {(checked && checked !== '2') &&
                <Button id="approvedisapprvebtn1" variant="outlined"
                    style={{ width: '100%', height: '90%', padding: '0px' }}
                    color={getButtonColorHandler(checked)}
                    startIcon={getButtonIconHandler(checked)}
                    disabled={disableButton}
                    onClick={handleClickOpen}>
                    {props.approvalStatus
                        .filter((_status: any) => {
                            return _status.ApprovalStatusId === parseInt(props.value)
                        })[0].ApprovalStatusName}
                </Button>}
            <Popper id={id} open={open} anchorEl={anchorEl} transition>
                {({ TransitionProps }) => (
                    <Fade {...TransitionProps} timeout={350}>
                        <Box sx={{ border: '1px solid #bfbfbf', p: 1, bgcolor: 'background.paper' }}>
                            <DialogTitle>Process</DialogTitle>
                            <DialogContent>
                                <DialogContentText>
                                    Based on the data in the row please approve or disapprove.
                                </DialogContentText>
                                <br />
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Status</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={status}
                                        label="Age"
                                        onChange={(event) => { setStatus(event.target.value); }}
                                    >
                                        {props.approvalStatus.map((status: any) => {
                                            return <MenuItem key={status.ApprovalStatusId} value={status.ApprovalStatusId}>{status.ApprovalStatusName}</MenuItem>
                                        })}
                                    </Select>
                                </FormControl>
                                <FormControl fullWidth>
                                    <TextField
                                        autoFocus
                                        margin="dense"
                                        id="name"
                                        label="Comment"
                                        type="email"
                                        fullWidth
                                        value={comment}
                                        onChange={(value) => { setComment(value.target.value) }}
                                        variant="standard"
                                    />
                                </FormControl>
                            </DialogContent>

                            <DialogActions>
                                <Button id="approvedisapprveokbtn"
                                    variant="outlined"
                                    color="success"
                                    startIcon={<CheckCircleOutlineOutlinedIcon />}
                                    onClick={() => { ApproveDisapproveHandler(status, comment ? comment : ""); setOpen(false); }}>
                                    OK</Button>
                                <Button id="approvedisapprvecancelbtn"
                                    variant="outlined"
                                    color="error"
                                    startIcon={<CancelOutlinedIcon />}
                                    onClick={() => { setOpen(false); }}>
                                    CANCEL</Button>
                            </DialogActions>

                            <>
                                <DialogTitle>History</DialogTitle>
                                <Box style={{ height: '20vh', overflowX: 'scroll' }} className="ag-theme-material">
                                    <AgGridReact
                                        ref={innerGridRef}
                                        rowSelection = {'multiple'}
                                        gridOptions={gridOptions}>
                                    </AgGridReact>
                                </Box>
                            </>
                        </Box>
                    </Fade>
                )}
            </Popper>
        </div>
    </>
    )
}

export default ApproveDisapproveRenderer;