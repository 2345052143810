import * as React from 'react';
import { MapViewType, IExplorerTableInfo } from '../types';
import * as api from 'src/Utility/api';

export const MapViewContext = React.createContext<MapViewType | null>(null);

interface BaseLayoutProps {
    children?: React.ReactNode;
}

const MapViewProvider: React.FC<BaseLayoutProps> = ({children}) => {
    const [reportConfigurations, setReportConfigurations] = React.useState<any[]>();
    const [dataAuditData, setDataAuditData] = React.useState<any[]>([]);
    const [mapFilters, setMapFilters] = React.useState<any[]>([]);
    const [countyKmlFiles, SetCountyKmlFiles] = React.useState<any[]>();

    const loadInitialPrerequisiteData = () => {
        const fetchMapFilters = async () => {
            await api.API_GET('/Table/GetTablesData?schema=settings&table=MAP_FILTER')
                .then((response) => {
                    setMapFilters(response.data.TableData);
                });
        };

        fetchMapFilters();
    };

    return <MapViewContext.Provider value={{ 
        reportConfigurations,
        dataAuditData,
        countyKmlFiles,
        mapFilters,
        loadInitialPrerequisiteData,
        setMapFilters }}>{children}</MapViewContext.Provider>;
};

export default MapViewProvider;