import { Box, Checkbox, DialogActions, DialogTitle, FormControlLabel, Grid, IconButton, Popper, PopperPlacementType, styled, Switch, SwitchProps, TextField, Tooltip, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import MoveDownOutlinedIcon from '@mui/icons-material/MoveDownOutlined';
import { Rnd } from "react-rnd";
import CancelIcon from '@mui/icons-material/Cancel';
import DoneIcon from '@mui/icons-material/Done';

export default function DollarValueRenderer(props: any) {
    function currencyFormatter(currency: number, sign: string) {
        if (currency !== null && currency !== undefined && !isNaN(currency)) {
            let sansDec = parseFloat(currency.toString())?.toFixed(2);
            let formatted = sansDec?.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
            return sign + `${formatted}`;
        }

        return '';
    }
    const [rowIndex, setRowIndex] = useState(props.rowIndex);
    const [dollarValue, setDollarValue] = React.useState<string>(currencyFormatter(props.value, '$'));
    const [initialValue, setInitialValue] = React.useState<number>(props.value);
    const [percent, setPercent] = React.useState<number>();
    const [applySame, setApplySame] = React.useState<boolean>(false);
    const [applyRound, setApplyRound] = React.useState<boolean>(false);
    const [selectedColumn, setSelectedColumn] = React.useState<string>('CurrentValue');

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [placement] = React.useState<PopperPlacementType>('top-start');
    const [open, setOpen] = React.useState(false);
    
    useEffect(() => {
        let currentValue = props.data?.CurrentValue;
        if(currentValue && props.value){
            let dataDiffPercent = (props.value - currentValue)/currentValue * 100;
            let percent = parseFloat(dataDiffPercent.toFixed(2));
            setPercent(percent);
            if(initialValue != props.value) {
                    props.ScaleValues(false, percent, false, props.value, props.data, selectedColumn)
            }
        }
     }, [props.value]);

     const handleClickOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
        setOpen(true);
   
      };

      const clearSelection = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
        setOpen(false);
    };

    const applySameDecisionHandler = () => {
        props.ScaleValues(applySame, percent, applyRound, props.value, props.data, selectedColumn)
        setOpen(false);
    }

    const handleInputChange = (e: any) => {
        if(isNaN(Number(e.target.value))){
            setPercent(0);
        } else {
            setPercent(e.target.value);
        }
    };

    const handleChecked = (e: any) => {
        setApplySame(true)
    }

    const handleRoundChecked = (e: any) => {
        setApplyRound(true)
    }

    const setColumn = (key: any) => {
        const index = key.options.selectedIndex;
        if(index == 1) {
            setSelectedColumn('OasisCostFromAFE');
        } else {
            setSelectedColumn('CurrentValue');
        }
    }

    return (
      <> 
          <div style={{ alignItems: "right" }}  className = "parent" > 
            {dollarValue}
            <Tooltip title="Scale Values" style={{ margin: 2, float: 'right' }} >
                <IconButton id="data" color="primary" size="small"
                    onClick={handleClickOpen} aria-label="data">
                    <MoveDownOutlinedIcon fontSize="small" />
                </IconButton>
            </Tooltip>
            <Popper open={open} placement={placement} anchorEl={anchorEl} style={{ zIndex: '10000' }}>
                <Rnd>
                    <Box sx={{ border: '2px solid #bfbfbf', p: 1, bgcolor: 'background.paper', height: 'auto', width: 'auto',minWidth:'270px' }}>
                        <div>
                            <DialogTitle>Scale Values</DialogTitle>
                        </div>
                        <Grid container rowSpacing={1} columnSpacing={2}>
                            <Grid item xs={6}>
                                    <Typography sx={{ ml: 2, mt : 1 }} component="div">
                                        Scale :
                                    </Typography>
                            </Grid>
                            <Grid item xs={6}>
                            <TextField
                                        id="scaleColumn" name="scaleColumn" select label="Scale Using"
                                        size="small"
                                        sx={{
                                            ml: -3,
                                            width: '100%',
                                            minWidth: 145
                                        }}
                                        SelectProps={{
                                            native: true,
                                        }}
                                        value={selectedColumn}
                                        onChange={e => setColumn(e.target)}>
                                            <option key= {0} value={'CurrentValue'}>
                                                {'CurrentValue'}
                                            </option>
                                                <option key= {1} value={'OasisCostFromAFE'}>
                                                {'OasisCostFromAFE'}
                                            </option>
                                    </TextField>
                            </Grid>

                            <Grid item xs={6}>
                                    <Typography sx={{ ml: 2, mt : 1 }} component="div">
                                        Scale by :
                                    </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                    <TextField  sx={{ ml: -3  }}
                                        id="amount" name="ScalePercentage" label="Scale Percentage" size="small"
                                        value={percent}
                                        onChange={handleInputChange} />
                            </Grid>
                            <Grid item xs={12}>
                                <FormControlLabel sx={{ ml: 1 }}
                                    label="Round to nearest Dollar"
                                    control={<Checkbox onChange={handleRoundChecked} />} />
                            </Grid>
                            <Grid item xs={12}>
                                <FormControlLabel sx={{ ml: 1 }}
                                    label="Apply same below"
                                    control={<Checkbox onChange={handleChecked} />} />
                            </Grid>
                        </Grid>
                        <DialogActions>
                            <IconButton id="done" color="success" aria-label="done" onClick={applySameDecisionHandler}>
                                <DoneIcon />
                            </IconButton>
                            <IconButton id="cancell" color="error" aria-label="cancel" onClick={clearSelection}>
                                <CancelIcon />
                            </IconButton>
                        </DialogActions>
                    </Box>
                </Rnd>
            </Popper>

        </div>
    </>
    )
}