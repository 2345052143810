import * as React from 'react';
import { ExplorerType, IExplorerTableInfo } from '../types';

export const ExplorerContext = React.createContext<ExplorerType | null>(null);

interface BaseLayoutProps {
    children?: React.ReactNode;
}

const ExplorerProvider: React.FC<BaseLayoutProps> = ({children}) => {
    const [tableList, setTableList] = React.useState<IExplorerTableInfo[]>([]);

    const saveTables = (tables: IExplorerTableInfo[]) =>{
        setTableList([...tables]);
    };

    return <ExplorerContext.Provider value={{ tableList,saveTables }}>{children}</ExplorerContext.Provider>;
};

export default ExplorerProvider;