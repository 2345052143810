import { Box, Grid, TextField, Typography } from '@mui/material';
import { ReactNode, useContext, useEffect, useState } from 'react';
import ChatMsg from '../ChatMsg/ChatMsg';
import * as api from 'src/Utility/api';
import './OrderComment.css';
import { convertUTCtoCDT } from 'src/Utility/common';
import { CommnentType, ExchangeOrderType } from 'src/Components/Dashboard/@State/types';
import { AppContext } from 'src/Components/Dashboard/@State/context/AppContext';
import * as eventMessaging from 'src/Utility/Messaging/Messaging';

export default function OrderComment(props: any) {
    const [estimateComments, setEstimateComments] = useState<any[]>([]);
    const [comment, setComment] = useState<string>("");
    const [commentBlocks, setCommentBlocks] = useState<any[]>([]);
    const { CommonAppConfig } = useContext(AppContext) as ExchangeOrderType;
    const [refreshInterval, setRefreshInterval] = useState<number>(0);

    useEffect(() => {
        if (props.selectedEstimate !== null) {
            loadComments();
        }
    }, [props.selectedEstimate]);

    useEffect(() => {
        if (estimateComments.length > 0) {
            constructComments();
        } else {
            setCommentBlocks([]);
        }
    }, [estimateComments]);

    useEffect(() => {
        if (CommonAppConfig.length > 0) {
            const commentRefreshConfig = CommonAppConfig
                .filter((element) => {
                    return element.ConfigShortDesc === "Comment_Refresh"
                });
            if (commentRefreshConfig.length > 0) {
                const refreshInterval = parseInt(commentRefreshConfig[0].ConfigValue);
                setRefreshInterval(refreshInterval);
            }
        }
    }, [CommonAppConfig]);

    useEffect(() => {
        let intervalID: any = null;
        if (refreshInterval > 0 && props.selectedEstimate !== null) {
            intervalID = setInterval(() => {
                loadComments();
            }, refreshInterval);
        }

        return () => {
            clearInterval(intervalID);
        }
    }, [refreshInterval, props.selectedEstimate]);

    const loadComments = () => {
        async function getOrderEstimatesComment() {
            let getEstimateCommentsUrl = '/OrderManagement/getOrderComments?OrderId='
                + props.orderInfo.OrderId
                + '&API=' + props.selectedEstimate.Api
                + '&ReportName=' + props.selectedEstimate.ReportName;
            await api.API_GET(getEstimateCommentsUrl).then((response) => {
                if (response.data.length > 0) {
                    const _data = response.data;
                    setEstimateComments(_data);
                } else {
                    setEstimateComments([]);
                }
            })
        }

        getOrderEstimatesComment();
    }

    async function saveEstimateComment(_comment: string) {
        if (_comment !== "") {
            await api.API_POST('/OrderManagement/SaveOrderComment', {
                OrderId: props.orderInfo.OrderId,
                API: props.selectedEstimate.Api,
                ReportName: props.selectedEstimate.ReportName,
                comment: _comment,
                commentByName: props.loggedInUserName,
                commentByEmail: props.loggedInUserMailId,
                commentByOperator: props.operator,
                CreatedBy: props.loggedInUserMailId,
                CreatedDate: new Date().toISOString(),
                LastModifiedBy: props.loggedInUserMailId,
                LastModifiedDate: new Date().toISOString(),
                Order: null
            }).then((response) => {
                loadComments();
                eventMessaging.CommentAddedBroadcast(props.completeObject, props.operator, props.messageTag);
            }).catch(() => {
            });
        }
    }

    const onCommentChangeHandler = (e: any) => {
        setComment(e.target.value);
    }

    const saveCommentChangeHandler = (e: any) => {
        if (e.keyCode == 13) {
            saveEstimateComment(e.target.value);
            setComment("");
        }
    }

    const isLoadCommentSection = () => {
        return props.selectedEstimate !== null;
    }

    const constructComments = () => {
        let currentOperator = estimateComments[0].CommentByOperator;
        let commentsDOM: any = null;
        let _commentBlocks: any[] = [];
        let messageBlock: CommnentType[] = [];
        let _commentDateTime: string = "";

        estimateComments.map((comment, index: number) => {
            if (comment.CommentByOperator.toLowerCase() === currentOperator.toLowerCase()) {
                _commentDateTime = convertUTCtoCDT(comment.LastModifiedDate + "Z");
                messageBlock.push({
                    comment: comment.Comment,
                    commentByUser: comment.CommentByName,
                    commentDateTime: _commentDateTime
                });
            } else {
                _commentDateTime = convertUTCtoCDT(comment.LastModifiedDate + "Z");
                _commentBlocks.push({
                    operator: currentOperator,
                    side: comment.CommentByOperator.toLowerCase() === props.operator.toLowerCase() ? 'left' : 'right',
                    messageBlock: [...messageBlock]
                });

                messageBlock = [];
                currentOperator = comment.CommentByOperator;
                messageBlock.push({
                    comment: comment.Comment,
                    commentByUser: comment.CommentByName,
                    commentDateTime: _commentDateTime
                });
            }
        });

        if (messageBlock.length > 0) {
            _commentBlocks.push({
                operator: currentOperator,
                side: currentOperator.toLowerCase() !== props.operator.toLowerCase() ? 'left' : 'right',
                messageBlock: [...messageBlock]
            });
        }

        setCommentBlocks([..._commentBlocks]);
    }

    return (
        <>
            <Typography variant="button" sx={{ fontSize: '1rem' }}>Addendum / Other conditions</Typography>
            <TextField
                label="Comment"
                id="columnselection-search-text-box"
                sx={{ width: '100%' }}
                disabled={!isLoadCommentSection()}
                size="small"
                value={comment}
                onChange={onCommentChangeHandler}
                onKeyDown={saveCommentChangeHandler}
            />
            {isLoadCommentSection() &&
                <Box sx={{ border: '1px solid #bfbfbf', p: 1, backgroundColor: '#ebebeb !important', height: '71vh', overflow: 'scroll' }} style={{ width: '100%' }} >
                    {
                        commentBlocks.map((commentBlock) => {
                            return <ChatMsg
                                avatar={commentBlock.operator}
                                side={commentBlock.side}
                                commentDateTime={commentBlock.commentDateTime}
                                messages={[...commentBlock.messageBlock]}
                            />
                        })
                    }
                    {commentBlocks.length == 0 &&
                        <Typography variant="body1" sx={{ fontSize: "12px" }}>No Comments</Typography>
                    }
                </Box>}
            {!isLoadCommentSection() &&
                <Typography variant="body1" sx={{ fontSize: "12px" }}>Please select a Dataset to view comments.</Typography>
            }
            <Box>
                <Grid item xs={12} sx={{ position: 'sticky' }}>
                    <div style={{ borderRadius: '20px', backgroundColor: "#ffd1d1" }}>
                        <Typography sx={{ marginLeft: "1%",padding:"10px" }} display="block" >
                            All information, exchanges performed on the system are subject to the terms and conditions of the Oasis Agreement
                        </Typography>
                    </div>
                </Grid>
            </Box>
        </>
    )
}