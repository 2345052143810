import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import NotificationsIcon from '@mui/icons-material/Notifications';
import * as api from 'src/Utility/api';
import { Alert, Badge, Button, IconButton } from '@mui/material';
import { useEffectOnce } from 'src/hooks/useEffectOnce';
import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';

import './TableLogs.css';
import { ExchangeOrderType, MessageTags } from '../../@State/types';
import { AppContext } from '../../@State/context/AppContext';
import { convertUTCtoCDT } from 'src/Utility/common';
import { setUpWebSocket } from 'src/Utility/Messaging/WebSocketSetup';

type Anchor = 'top' | 'left' | 'bottom' | 'right';

interface ITableLogsProps {
  loggedInUser: string;
  loggedInUserMailId: string;
  pubSubClientUrl: any;
  savedBroadcastMessages: any[];
}

let W3CWebSocket = require('websocket').w3cwebsocket;

export default function TableLogs(props: ITableLogsProps) {
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const [pendingDetails, setPendingDetails] = React.useState([]);
  const [pendingCount, setPendingCount] = React.useState<number>(0);
  const [broadcastMessages, setBroadcastMessages] = React.useState<any[]>([]);
  const [clearedTimeStamp, setClearedTimeStamp] = React.useState<string>("");

  const { setScreenUpdaterTransaction,
    setScreenUpdaterEstimates,
    setScreenUpdaterValuation,
    CommonAppConfig,
    fetchCommonAppConfigHandler } = React.useContext(AppContext) as ExchangeOrderType;

  useEffectOnce(() => {
    props
      .pubSubClientUrl
      .forEach((_pubSubClientUrl: any) => {
        setUpWebSocket(_pubSubClientUrl, broadcastMessages, setBroadcastMessages, distributeForScreenRefresh)
      })
  });

  React.useEffect(() => {
    if (props.savedBroadcastMessages?.length > 0) {
      const _messages = [...broadcastMessages, ...props.savedBroadcastMessages];
      setBroadcastMessages(_messages.reverse());
    }
  }, [props.savedBroadcastMessages]);

  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
      (event: React.KeyboardEvent | React.MouseEvent) => {
        if (
          event.type === 'keydown' &&
          ((event as React.KeyboardEvent).key === 'Tab' ||
            (event as React.KeyboardEvent).key === 'Shift')
        ) {
          return;
        }

        setState({ ...state, [anchor]: open });
      };

  React.useEffect(() => {
    getPendingDetailsHandler();
  }, [props.loggedInUser]);

  React.useEffect(() => {
    if (CommonAppConfig) {
      const clearNotificationConfig = CommonAppConfig.find((element) => { return element.ConfigShortDesc === "ClearMessages" });
      if (clearNotificationConfig) {
        const parsedConfigs = JSON.parse(clearNotificationConfig.ConfigValue);
        const loggedInUserConfig = parsedConfigs.find((element: any) => { return element.MailId === props.loggedInUserMailId });
        if (loggedInUserConfig) {
          setClearedTimeStamp(loggedInUserConfig.cleartimestamp);
        }
      }
    }
  }, [CommonAppConfig])

  function pending(item: any) {
    return item.pending;
  }

  function sum(prev: number, next: number) {
    return prev + next;
  }

  const getPendingDetailsHandler = () => {
    async function fetchPendingDetails() {
      await api.API_GET('/Table/GetApprovalPendingDetails?loggedInUser=' + props.loggedInUser)
        .then((response) => {
          if (response.data.length > 0) {
            setPendingDetails(response.data);
            const _pendingCount = response.data.map(pending).reduce(sum);
            setPendingCount(_pendingCount);
          }
        });
    }

    fetchPendingDetails();
  }

  const clearNotificationHandler = (timeStamp: any) => {
    async function clearNotification() {
      await api.API_POST('/Common/UpdateCommonKeyValueConfig?configShortDescription=' + 'ClearMessages&value='
        + timeStamp + '&loggedInUserEmail=' + props.loggedInUserMailId)
        .then((response) => {
          fetchCommonAppConfigHandler(true);
        }).catch(() => {
        });
    }
    clearNotification();
  }

  const distributeForScreenRefresh = (message: any) => {
    if (message.Tag !== undefined && message.Tag == MessageTags.Order) {
      setScreenUpdaterTransaction([message.ChangedJSONObject]);
    }
    if (message.Tag !== undefined && message.Tag == MessageTags.Estimates) {
      setScreenUpdaterEstimates([message.ChangedJSONObject]);
    }
    if (message.Tag !== undefined && message.Tag == MessageTags.Valuation) {
      setScreenUpdaterValuation([message.ChangedJSONObject]);
    }
  }

  const isShowNotificationHandler = (messageTimeStamp: any) => {
    let result = true;

    if (messageTimeStamp && clearedTimeStamp !== "") {
      const messageDateTime = Date.parse(messageTimeStamp);
      const clearedDateTime = Date.parse(clearedTimeStamp);

      if (messageDateTime < clearedDateTime) {
        result = false;
      }
    }

    return result;
  }

  const broadcastMessageCount = () => {
    return broadcastMessages.filter((element: any) => { return isShowNotificationHandler(element.TimeStamp) }).length;
  }

  const list = (anchor: Anchor) => (
    <Box
      sx={{ width: "100%" }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >

      <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
        <Divider variant="inset" component="li" />
        {
          broadcastMessages.map((element: any, index: number) => {
            return (isShowNotificationHandler(element.TimeStamp) && <ListItem sx={{ paddingBottom: '0px' }} alignItems="flex-start" key={index}>
              <Alert style={{ padding: '0px 16px', width: '100%', border: '1px solid #0288d1' }} key={element.id} severity="info">{element.Message}
                <ListItemText sx={{ float: 'right' }} secondary={convertUTCtoCDT(element.TimeStamp)} /></Alert>
            </ListItem>)
          })
        }
      </List>
    </Box>
  );

  return (
    <div id="TableLogs">
      {(['right'] as const).map((anchor, index: number) => (
        <React.Fragment key={index}>
          <IconButton id="tablelogsshownotification"
            size="large"
            aria-label="show 17 new notifications"
            color="inherit"
            onClick={toggleDrawer(anchor, true)}
          >
            <Badge badgeContent={broadcastMessageCount()} color="error">
              <NotificationsIcon />
            </Badge>
          </IconButton>
          <Drawer
            id="notificationDrawer"
            sx={{ width: '25%' }}
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
          >
            <Box sx={{ textAlign: 'center' }}>
              <Button id="clear" className='button' aria-label="remove" onClick={() => { clearNotificationHandler(new Date().toISOString()); }} startIcon={<CancelOutlinedIcon />}>
                Clear
              </Button>
              <Button id="reload" className='button' aria-label="remove" onClick={() => { clearNotificationHandler(new Date('1/1/111').toISOString()); }} startIcon={<RefreshOutlinedIcon />}>
                Reload
              </Button>
            </Box>
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
}
