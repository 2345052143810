import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-balham.css';
import 'ag-grid-enterprise';
import {Alert, AlertColor, Collapse, IconButton, Paper, TextField, Tooltip } from "@mui/material";
import * as api from 'src/Utility/api';
import { ExchangeOrderType, ITableGridColumnDefinition, TabOrder } from "../../@State/types";
import './OrderDataTransfer.css';
import MyOrdersFilter from "../Valuation/MyOrders/MyOrdersFilter";
import SaveIcon from '@mui/icons-material/Save';
import ConfirmDialog from "src/Common/ConfirmDialog";
import ViewColumnOutlinedIcon from '@mui/icons-material/ViewColumnOutlined';
import ColumnSelection from "../ColumnSelection/ColumnSelection";
import DateTimeRenderer from "../CellRenderers/DateTimeRenderer";
import DatePickerEditor from "../CellEditors/DatePickerEditor";
import ExcludeIncludeOrderRenderer from "../CellRenderers/ExcludeIncludeOrderRenderer";
import { AppContext } from '../../@State/context/AppContext';
import ReportNameRenderer from "../CellRenderers/ReportNameRenderer";
import { columnHeaderTemplate } from "src/Utility/common";
import * as eventMessaging from 'src/Utility/Messaging/Messaging';

function OrderDataTransfer(props: any) {
    const initialMessage = {
        open: false,
        msg: '',
        severity: 'info'
    };

    const [message, setMessage] = React.useState(initialMessage);
    const [operator, setOperator] = React.useState<string>('');
    const [dataTransferData, setdataTransferData] = React.useState<any[]>([]);
    const [selectedOrder, setselectedOrder] = React.useState<any[]>([]);
    const [cartOrders, SetCartOrders] = React.useState<any[]>([]);
    const [openSaveConfirmDialog, setOpenSaveConfirmDialog] = React.useState(false);
    const [isUserIndataTransfer, setIsUserIndataTransfer] = React.useState(false);
    const [isOperatorADataOwner, setIsOperatorADataOwner] = React.useState(false);
    const [tablePreferenceList, setTablePreferenceList] = React.useState<any[]>([]);
    const [selectedPreference, setselectedPreference] = React.useState<any>();
    const [columnPreference, setColumnPreference] = React.useState<any[]>([]);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const { reportConfigurations,dataAuditData, operatorList, loadInitialOperatorData } = React.useContext(AppContext) as ExchangeOrderType;
    const [columnsPerfOpen, setcolumnsPerfOpen] = React.useState(false);
    const [columnDefinitions, setcolumnDefinitions] = React.useState<any[]>([]);
    const [dataTransferDataAudit, setdataTransferDataAudit] = React.useState<any[] | null>(null);
    const [hiddenColumns, setHiddenColumns] = React.useState<string[]>(["DataTransferId"
        , "Info1"
        , "Info2"
        , "Info3"
        , "EffStartDate"
        , "EffEndDate"
        , "CreatedBy"
        , "CreatedDate"
        , "LastModifiedBy"
        , "LastModifiedDate"
        , "LastModifiedBy"]);

    const displayColumns: any[] = ['WellName', 'L1', 'L2', 'L3', 'L4', 'L5', 'L6', 'API', 'DataSet', 'OrderId',
        'IsDataTransferred', 'DataTransferredDate', 'IsDataRecieved', 'DataRecievedDate', 'DataTransferredBy', 'DataRecievedBy'];

    const [confirmDialogObject, setConfirmDialogObject] = React.useState<any>({
        title: "",
        body: "",
        okHandler: () => undefined
    });
    const dataTransferGridRef: any = useRef();

    const defaultColDef = useMemo(() => {
        return {
            resizable: true,
            wrapHeaderText: true,
            autoHeaderHeight: true,
            editable: false,
            headerCheckboxSelectionFilteredOnly: true,
            enableCellChangeFlash: true
        };
    }, []);

    const gridOptions = {
        // PROPERTIES
        defaultColDef: defaultColDef,
        pagination: false,
        enableRowGroup: true,
        groupSelectsChildren: true,
        suppressCsvExport: true,
        suppressExcelExport: true,
        suppressCutToClipboard: true,
        suppressCopyRowsToClipboard: true,
        autoGroupColumnDef: {
            hide: true,
            cellRendererParams: {
                suppressCount: true,
            },
        },
        enableRangeSelection: true,
        suppressAggFuncInHeader: true,
        groupingShowCounts: false,
        getRowHeight: () => { return 24 }
    }

    useEffect(() => {
        if (operatorList?.length == 0) {
            loadInitialOperatorData(props.loggedInUserMailId);
        }
        if (typeof (operatorList) != "undefined" && operatorList.length > 0) {
            setOperator(operatorList[0]);
            setcolumnsPerfOpen(false);
            loadColumnPreferences();
        }
    }, [operatorList]);

    useEffect(() => {
        if (props.tabIndex != TabOrder.DataTransfer) {
            if (isUserIndataTransfer) {
                const edittedData = dataTransferData.filter((data) => { return data.isModified });
                if (edittedData.length > 0) {
                    let confirmDialogObject = {
                        title: "DataTransfers",
                        body: "You have made some changes. Do you want to Save and Continue?, else click CANCEL",
                        okHandler: saveEditedDataHandler
                    };

                    setConfirmDialogObject(confirmDialogObject);
                    setOpenSaveConfirmDialog(true);
                    setIsUserIndataTransfer(false);
                }
            }
        } else {
            setIsUserIndataTransfer(true);
        }
    }, [props.tabIndex]);

    useEffect(() => {
        if (props.selectedCartOrder?.length > 0) {
            props.setShowProgress(true);
            SetCartOrders(props.selectedCartOrder);
        }
    }, [props.IsdataTransfer]);

    useEffect(() => {
        loaddataTransferDataHandler();
    }, [operator]);

    const loaddataTransferDataHandler = () => {
        if (operator !== '' && operator !== undefined && cartOrders.length > 0) {
            let order = cartOrders.length > 0 ? cartOrders[0].OrderId.toString() : "";
            const orderIds: any[] = cartOrders?.map((element: any) => { return element.OrderId });
            setselectedOrder(orderIds);
            setIsOperatorADataOwner(cartOrders[0].RequestedTo == operator);
            loaddataTransferData(order);
        }
    }

    useEffect(() => {
        if (selectedPreference != null) {
            setColumnPreference(selectedPreference.PreferenceJson);
        }
        else {
            setInitialColumnPreferenceState();
        }
    }, [selectedPreference]);

    useEffect(() => {
        loaddataTransferTable();
    }, [dataTransferData]);

    useEffect(() => {
        applyColumnPreference(columnPreference);
    }, [columnPreference])

    const loaddataTransferTable = () => {
        if (dataTransferData !== undefined && dataTransferData.length >= 0) {
            let columnDefinitions = constructdataTransferColumnDefinitions();
            setcolumnDefinitions(columnDefinitions);
            if (dataTransferGridRef !== undefined && dataTransferGridRef.current !== undefined) {
                if (dataTransferGridRef.current.api !== 'undefined') {
                    dataTransferGridRef.current?.api?.setColumnDefs(columnDefinitions);
                    dataTransferGridRef.current?.api?.setRowData(dataTransferData);
                } else {
                    dataTransferGridRef.current.props.gridOptions.columnDefs = columnDefinitions;
                    dataTransferGridRef.current.props.gridOptions.rowData = dataTransferData;
                }
            } else {
                dataTransferGridRef.current?.api?.setColumnDefs([]);
                dataTransferGridRef.current?.api?.setRowData([]);
            }
        }
        else {
            dataTransferGridRef.current?.api?.setColumnDefs([]);
            dataTransferGridRef.current?.api?.setRowData([]);
        }
    }

    const regeneratedataTransfers = async (orderArray: any[]) => {
        const orderIds: any[] = orderArray?.map((element: any) => { return element.OrderId });
        setselectedOrder(orderIds);
        loaddataTransferData(orderIds[0]);
    }

    const constructdataTransferColumnDefinitions = () => {
        let columnDefinitions: ITableGridColumnDefinition[] = [];
        const nonEditable = ["API", "WellName", "L1", "L2", "L3", "L4", "L5", "L6", "DataSet", "OrderId", "DataTransferredBy", "DataRecievedBy"];

        displayColumns.forEach((column) => {
             if (column.toLowerCase().includes('datatransferreddate') || column.toLowerCase().includes('datarecieveddate')) {
                columnDefinitions.push({
                    field: column,
                    //type: 'editableColumn',
                    width: 200,
                    suppressSizeToFit: true,
                    singleClickEdit: false,
                    headerName: fetchDisplayName(column),
                    headerTooltip: fetchDisplayName(column),
                    headerComponentParams: columnHeaderTemplate(fetchDisplayName(column)),
                    cellEditorPopup: true,
                    enableRowGroup: true,
                    editable: getIsColumnDisabled(column),
                    cellEditor: DatePickerEditor,
                    cellEditorParams: {
                        column: column,
                        disableColumn: false,
                        onDateValueSelected: (value: any, rowIndex: any, rowData: any) => {
                            let _tableData = dataTransferData;
                            let index = _tableData.findIndex(i => i.OrderDataTransferId === rowData.OrderDataTransferId);
                            rowData[column] = value;
                            _tableData[index] = rowData;
                            _tableData[index]["isModified"] = true;
                            
                            if (_tableData[index]["modifiedAttrs"] === undefined) {
                                _tableData[index]["modifiedAttrs"] = column;
                            } else {
                                let modAttrs = _tableData[index]["modifiedAttrs"];
                                modAttrs = modAttrs + ',' + column;
                                _tableData[index]["modifiedAttrs"] = modAttrs;
                            }

                            dataTransferGridRef.current?.api?.setRowData(_tableData);
                        }
                    },
                    cellRenderer : DateTimeRenderer,
                    cellRendererParams : {
                        showTime: false
                    },
                    cellClass: ['ag-cell-custom'],
                    suppressToolPanel: false,
                    hide: isColumnHidden(column),
                    filter: (column.DataType !== "Int32" && column.DataType !== "Int64") ? 'agMultiColumnFilter' : 'agNumberColumnFilter'
                });
            } else if (column.toLowerCase().includes('isdatatransferred') || column.toLowerCase().includes('isdatarecieved')) {
                    let columnDefinition: ITableGridColumnDefinition = {
                        field: column,
                        headerName: fetchDisplayName(column),
                        headerTooltip: fetchDisplayName(column),
                        headerComponentParams: columnHeaderTemplate(fetchDisplayName(column)),
                        sortable: true,
                        width: 120,
                        filter: (column.DataType !== "Int32" && column.DataType !== "Int64") ? 'agMultiColumnFilter' : 'agNumberColumnFilter',
                        hide: false,
                        editable : false,
                        aggFunc: "",
                        cellRenderer : ExcludeIncludeOrderRenderer,
                        cellRendererParams : {
                                IsDisabled: getIsColumnDisabled(column),
                                IsDataTransfer : true,
                                Column : column,
                                transferData : dataTransferData,
                                onExcludeInclude: (value: any, rowData: any, applySame: boolean) => {
                                    let _tableData = dataTransferData;
                                    let index = _tableData.findIndex(i => i.OrderDataTransferId === rowData.OrderDataTransferId);
                                    if (applySame) {
                                        let applyToAll = false;
                                        dataTransferGridRef.current!.api.forEachNodeAfterFilterAndSort((params: any) => {
                                            let dataTransferId = params.data?.OrderDataTransferId;
                                            if (dataTransferId === rowData.OrderDataTransferId) {
                                                applyToAll = true;
                                            }

                                            if (applyToAll) {
                                                let element = _tableData.find(i => i.OrderDataTransferId === dataTransferId);
                                                let modifiedAttrs = column;
                                                let isColumnModified = false;
                                                if(column.toLowerCase().includes('isdatatransferred')){
                                                    element[column] = value;
                                                    element["DataTransferredDate"] =  new Date().toISOString();
                                                    element["DataTransferredBy"] = props.loggedInUserMailId;
                                                    modifiedAttrs = modifiedAttrs + ',' + "DataTransferredDate,DataTransferredBy"
                                                    isColumnModified = true;
                                                }
                                                if(column.toLowerCase().includes('isdatarecieved') && element['IsDataTransferred'] === 'Y'){
                                                    element[column] = value;
                                                    element["DataRecievedDate"] =  new Date().toISOString();
                                                    element["DataRecievedBy"] = props.loggedInUserMailId;
                                                    modifiedAttrs = modifiedAttrs + ',' + "DataRecievedDate,DataRecievedBy"
                                                    isColumnModified = true;
                                                }
    
                                                if(isColumnModified){
                                                    element["isModified"] = true;
                                                    if (element["modifiedAttrs"] === undefined) {
                                                        element["modifiedAttrs"] = modifiedAttrs;
                                                    } else {
                                                        let modAttrs = element["modifiedAttrs"];
                                                        modAttrs = modAttrs + ',' + modifiedAttrs;
                                                        element["modifiedAttrs"] = modAttrs;
                                                    }
                                                }                                                
                                            }
                                        });

                                        dataTransferGridRef.current?.api?.setRowData(_tableData);
                                    } else {
                                        _tableData[index][column] = value;
                                        let modifiedAttrs = column;
                                        if(column.toLowerCase().includes('isdatatransferred')){
                                            _tableData[index]["DataTransferredDate"] =  new Date().toISOString();
                                            _tableData[index]["DataTransferredBy"] = props.loggedInUserMailId;
                                            modifiedAttrs = modifiedAttrs + ',' + "DataTransferredDate,DataTransferredBy"
                                        } else {
                                            _tableData[index]["DataRecievedDate"] =  new Date().toISOString();
                                            _tableData[index]["DataRecievedBy"] = props.loggedInUserMailId;
                                            modifiedAttrs = modifiedAttrs + ',' + "DataRecievedDate,DataRecievedBy"
                                        }

                                        _tableData[index]["isModified"] = true;

                                        if (_tableData[index]["modifiedAttrs"] === undefined) {
                                            _tableData[index]["modifiedAttrs"] = modifiedAttrs;
                                        } else {
                                            let modAttrs = _tableData[index]["modifiedAttrs"];
                                            modAttrs = modAttrs + ',' + modifiedAttrs;
                                            _tableData[index]["modifiedAttrs"] = modAttrs;
                                        }
                                    }
                            }
                        },
                        cellClass: ['ag-cell-custom'],
                    };
                    columnDefinitions.push(columnDefinition);
            }  else if (column.toLowerCase() === 'dataset') {
                let columnDefinition: ITableGridColumnDefinition = {
                    field: column,
                    //type: 'editableColumn',
                    headerName: fetchDisplayName(column),
                    headerTooltip: fetchDisplayName(column),
                    headerComponentParams: columnHeaderTemplate(fetchDisplayName(column)),
                    cellClass: ['ag-cell-custom'],
                    sortable: true,
                    cellStyle: { textAlign: 'left' },
                    cellRenderer : ReportNameRenderer,
                    cellRendererParams :{ ShowInfo: true,
                        DataAuditData: dataTransferDataAudit,
                        ReportConfig: reportConfigurations},
                    filter: true,
                    editable: !nonEditable.includes(column),
                    hide: isColumnHidden(column),
                };
                columnDefinitions.push(columnDefinition);

            }  else if (column.toLowerCase().includes('l6')
                || column.toLowerCase().includes('l5')
                || column.toLowerCase().includes('l4')
                || column.toLowerCase().includes('l3')
                || column.toLowerCase().includes('l2')
                || column.toLowerCase().includes('l1')) {
                let columnDefinition: ITableGridColumnDefinition = {
                    field: column,
                    //type: 'editableColumn',
                    headerName: fetchDisplayName(column),
                    headerTooltip: fetchDisplayName(column),
                    headerComponentParams: columnHeaderTemplate(fetchDisplayName(column)),
                    cellClass: ['ag-cell-custom'],
                    cellRenderer: ReportNameRenderer,
                    cellRendererParams: {
                        ShowInfo: false,
                        DataAuditData: dataTransferDataAudit,
                        ReportConfig: reportConfigurations
                    },
                    sortable: true,
                    cellStyle: { textAlign: 'left' },
                    enableRowGroup: true,
                    filter: true,
                    editable: !nonEditable.includes(column),
                    hide: isColumnHidden(column),
                };
                columnDefinitions.push(columnDefinition);
            }  else {
                let columnDefinition: ITableGridColumnDefinition = {
                    field: column,
                    //type: 'editableColumn',
                    headerName: fetchDisplayName(column),
                    headerTooltip: fetchDisplayName(column),
                    headerComponentParams: columnHeaderTemplate(fetchDisplayName(column)),
                    cellClass: ['ag-cell-custom'],
                    sortable: true,
                    cellStyle: { textAlign: 'left' },
                    filter: true,
                    editable: !nonEditable.includes(column),
                    hide: isColumnHidden(column),
                };
                columnDefinitions.push(columnDefinition);
            }
        });

        columnDefinitions = rearrangeColumnsBasedOnPreference(columnDefinitions, []);
        let hiddenItem = columnDefinitions.filter((elem: any) => { return !elem.hide });
        hiddenItem[0].headerCheckboxSelection = true;
        hiddenItem[0].checkboxSelection = true;
        return columnDefinitions;
    }

    const fetchDisplayName = (columnName: string) => {
        let _displayName = columnName;
        let matchingPreferance = [];
        matchingPreferance = columnPreference.filter((element) => { return element.ColumnName == columnName });

        if (matchingPreferance.length > 0) {
            _displayName = matchingPreferance[0].DisplayName;
        }

        return _displayName;
    }

    const getIsColumnDisabled = (columnName: string) => {
        let isDisabled = false;
        switch(columnName.toLowerCase()){
            case 'isdatatransferred' :
            case 'datatransferreddate' :
                isDisabled = !isOperatorADataOwner; 
                break;
            case 'isdatarecieved':
            case 'datarecieveddate':
                isDisabled = isOperatorADataOwner; 
                break;
        }

        return isDisabled;
    }

    const loadColumnPreferences = () => {
        async function loadColumnPreferencesData() {
            setTablePreferenceList([]);

            let getDataUrl = '/Common/GetColumnPreferencesByTable?schema=DATATRANSFER&table=DATATRANSFER';
            await api.API_GET(getDataUrl).then((response) => {
                if (response?.data?.length > 0) {
                    let globalPreference = response?.data.filter((ele: any) => { return ele.PreferenceName.toLowerCase().includes('global') });
                    let defaultPreference = response?.data.filter((ele: any) => { return ele.UserMailId == props.loggedInUserMailId && ele.IsDefault });
                    let preferences = response?.data.filter((ele: any) => {
                        return ((ele.UserMailId === props.loggedInUserMailId) ||
                            (ele.UserMailId !== props.loggedInUserMailId && ele.IsPublic))
                    });

                    setTablePreferenceList(preferences);

                    if (defaultPreference.length > 0) {
                        setselectedPreference(defaultPreference[0]);
                    }
                    else if (globalPreference.length > 0) {
                        setselectedPreference(globalPreference[0]);
                    }
                    else if (preferences.length > 0) {
                        //setselectedPreference(preferences[0]);
                    }
                }
            }).catch(error => {
                console.log("error in post call", error)
            }).finally(() => {
            });
        };

        loadColumnPreferencesData();
    }

    const handleClickColumnsOpen = (event: React.MouseEvent<HTMLElement>) => {
        setInitialColumnPreferenceState();
        setAnchorEl(event.currentTarget);
        setcolumnsPerfOpen(!columnsPerfOpen);
    };

    const setInitialColumnPreferenceState = () => {
        let columnPreference: any[] = [];
        if (selectedPreference == null) {
            displayColumns.forEach((element: any) => {
                columnPreference.push({
                    ColumnName: element,
                    Enable: true,
                    AvailableOrDisplayed: "D" // A for Available columns , D for Displayed columns
                });
            });

            if (columnPreference.length > 0) {
                let preference: any = {
                    PreferenceJson: columnPreference,
                    PreferenceId: 0,
                    PreferenceName: '',
                    SchemaName: 'DATATRANSFER',
                    TableName: 'DATATRANSFER',
                    UserMailId: props.loggedInUserMailId,
                    IsPublic: true,
                    IsDefault: false,
                    CreatedBy: props.loggedInUserMailId
                };

                setselectedPreference(preference);
            }
        }
    }

    const applyDisplayColumnNames = (_columnPreference: any[], colDefs: any) => {
        colDefs.forEach((colDef: any) => {
            let colPref = _columnPreference.filter((ele) => { return ele.ColumnName == colDef.field });
            if (colPref.length > 0) {
                colDef.headerName = colPref[0].DisplayName;
                colDef.headerComponentParams = columnHeaderTemplate(colPref[0].DisplayName, colDef.isPropreitary);
            }
        });

        return colDefs;
    }

    const applyColumnPreferenceHandler = async (selectedPreferene: any) => {
        setselectedPreference(selectedPreferene);
        applyColumnPreference(selectedPreferene.PreferenceJson);
    }

    const isColumnHidden = (columnName: string) => {
        if (columnPreference != null) {
            let colMatch = columnPreference.filter((element: any) => {
                return element.ColumnName === columnName;
            });
            if (colMatch.length > 0) {
                return !colMatch[0].Enable;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }

    const applyColumnPreference = (_columnPreference: any[]) => {
        let updatedColumnDefs = rearrangeColumnsBasedOnPreference([...columnDefinitions], _columnPreference);
        _columnPreference.forEach((colPreference: any) => {
            let columnDefMatch = updatedColumnDefs.filter((element: any) => { return element.field.toLowerCase().match(colPreference.ColumnName.toLowerCase()) || element.headerName.trim().toLowerCase().match(colPreference.ColumnName.toLowerCase()) });
            if (columnDefMatch.length > 0) {
                if (colPreference.Enable === false) {
                    updatedColumnDefs[updatedColumnDefs.indexOf(columnDefMatch[0])].hide = true;
                } else {
                    updatedColumnDefs[updatedColumnDefs.indexOf(columnDefMatch[0])].hide = false;
                }
            }
        });

        updatedColumnDefs.forEach((elem: any) => {
            elem.headerCheckboxSelection = false;
            elem.checkboxSelection = false;
        });

        let visibleItem = updatedColumnDefs.filter((elem: any) => { return !elem.hide });
        if (visibleItem && visibleItem.length > 0){
            visibleItem[0].headerCheckboxSelection = true;
            visibleItem[0].checkboxSelection = true;
        }

        dataTransferGridRef.current?.columnApi?.columnModel?.setColumnDefs(applyDisplayColumnNames(_columnPreference, updatedColumnDefs));
        dataTransferGridRef.current?.api?.setRowData(dataTransferData);
    }

    const rearrangeColumnsBasedOnPreference = (columnDefinitions: any[], _columnPreference: any[]) => {
        let _reArrangedColumnDefinitions: any[] = [];
        let colPrefs = _columnPreference.length > 0 ? _columnPreference : [...columnPreference];

        if (colPrefs != null && colPrefs.length > 0) {
            colPrefs.forEach((preference: any) => {
                const columnDefinitionObj = columnDefinitions.filter((ele: any) => { return ele.field.toLowerCase() === preference.ColumnName.toLowerCase() || ele.headerName.trim().toLowerCase() === preference.ColumnName.toLowerCase() });
                if (columnDefinitionObj.length > 0) {
                    _reArrangedColumnDefinitions.push(columnDefinitionObj[0]);
                }
            });

            const columnsNotInPreference = columnDefinitions.filter(o => !colPrefs.some(({ ColumnName }) => o.field.toLowerCase() === ColumnName.toLowerCase() || o.headerName.trim().toLowerCase() === ColumnName.toLowerCase()));
            columnsNotInPreference.forEach((column: any) => {
                _reArrangedColumnDefinitions.push(column);
            });
        }

        return _reArrangedColumnDefinitions.length > 0 ? _reArrangedColumnDefinitions : columnDefinitions;
    }

    const loaddataTransferData = (orderId: any) => {

        async function getdataTransfersData() {
            let getdataTransferDataUrl = '/OrderManagement/GetOrdersDataTransfers?orderId=' + orderId;

            await api.API_GET(getdataTransferDataUrl).then((response) => {
                if (response.data.length > 0) {
                    const _data = response.data;
                    const unique = _data.filter((item: any, i: any, arr: any) => {
                        return arr.indexOf(arr.find((t: any) => t.API === item.API)) === i;
                    });
                    const auditInfo = dataAuditData.filter(o => unique.some((a: any) => o.API.toLowerCase().match(a.API.toLowerCase())));

                    setdataTransferDataAudit(auditInfo);
                    setdataTransferData(_data);
                } else {
                    setdataTransferData([]);
                }

                if (orderId != "") {
                    api.LOG_MESSAGE(["Page : dataTransfer", "Action : dataTransfer Data Loaded for OrderId : " + orderId], { 'UserName': props.loggedInUser });
                }
                else {
                    api.LOG_MESSAGE(["Page : dataTransfer", "Action : dataTransfer Data Loaded for Operator : " + operator], { 'UserName': props.loggedInUser });
                }
            }).finally(() => {
                props.setShowProgress(false);
            });
        }

        getdataTransfersData();

    }

    const autoSizeColumns = (timeoutPeriod: number) => {
        setTimeout(() => {
            dataTransferGridRef?.current?.columnApi?.autoSizeAllColumns(false);
        }, timeoutPeriod);
    }

    const onFirstDataRendered = useCallback((timeoutPeriod: number) => {
        autoSizeColumns(timeoutPeriod);
    }, []);

    const orderPickHandler = (orderData: any[]) => {
        const orderIds: any[] = orderData.map((element: any) => { return element.OrderId });
        setIsOperatorADataOwner(orderData[0].RequestedTo == operator);
        setselectedOrder(orderIds);
        regeneratedataTransfers(orderData);
        SetCartOrders(orderData); 
    }

    const onSave = () => {
        onSubmitHandler();
    }

    const getModifiedAttributes = (editedObject: any) => {
        const actualKeys = Object.keys(editedObject);
        const modifiedKeys = editedObject["modifiedAttrs"];
        let _objectClone: any = {};
        for (var key of actualKeys) {
            if (modifiedKeys.includes(key) || key === "OrderDataTransferId") {
                _objectClone[key] = editedObject[key];
            }
        }
        return _objectClone;
    }

    const onCellValueChanged = (params: any) => {
        if (!params.node.footer || !params.node.rowPinned) {
            let _tableData = dataTransferData;
            if (_tableData.length > 0) {
                if (params.node.id !== "rowGroupFooter_ROOT_NODE_ID") {
                    params.node.data.isModified = true;
                    _tableData[params.node.id]["isModified"] = true;
                    _tableData[params.node.id][params.column.colId] = params.node.data[params.column.colId];
                    if (_tableData[params.node.id]["modifiedAttrs"] === undefined) {
                        _tableData[params.node.id]["modifiedAttrs"] = params.column.colId;
                    } else {
                        let modAttrs = _tableData[params.node.id]["modifiedAttrs"];
                        modAttrs = modAttrs + ',' + params.column.colId;
                        _tableData[params.node.id]["modifiedAttrs"] = modAttrs;
                    }

                    params.node.setData({ ...params.node.data, isModified: true });
                }
            }
        }
    }

    const onCellValueEnded = (params: any) => {
        if (params.column.colId == "DateOfAcquisition") {
            let _tableData = dataTransferData;
            var rowNode = dataTransferGridRef.current.api.getRowNode(params.rowIndex);
            rowNode.setDataValue('DateOfAcquisition', rowNode.data.DateOfAcquisition);
            rowNode.data.isModified = true;
            _tableData[params.rowIndex]["isModified"] = true;
            if (_tableData[params.rowIndex]["modifiedAttrs"] === undefined) {
                _tableData[params.rowIndex]["modifiedAttrs"] = "DateOfAcquisition";
            } else {
                let modAttrs = _tableData[params.rowIndex]["modifiedAttrs"];
                modAttrs = modAttrs + ',' + "DateOfAcquisition";
                _tableData[params.rowIndex]["modifiedAttrs"] = modAttrs;
            }
        } else {
            var rowNode = dataTransferGridRef.current.api.getRowNode(params.rowIndex);
            rowNode.data.isModified = true;
        }
    }

    const getRowStyle = (params: any) => {
        let rowStyle: Record<string, string> = {};
        if (params?.node?.data?.isModified) {
            rowStyle.fontStyle = 'italic';
            rowStyle.fontWeight = 'bold';
        }

        return rowStyle;
    }

    const onSubmitHandler = () => {
        const edittedData = dataTransferData.filter((data) => { return data.isModified });
        const editedRows: any = [];

        if(edittedData.length > 0) {
                props.setShowProgress(true);
                    edittedData.forEach((element) => {
                        if (element.modifiedAttrs !== undefined) {
                            let updatedData = getModifiedAttributes(element);
                            updatedData.LastModifiedBy = props.loggedInUser;
                            updatedData.LastModifiedDate = new Date().toISOString();
                            editedRows.push(updatedData);
                        }
                    }
                );
    
                SaveData();
            
        } else {
            formMessage('No records to save', 'info');
        }
       

        async function SaveData() {
            await api.API_POST('/Table/SaveTableData', {
                schema: 'orders',
                table: 'ORDER_DATA_TRANSFER',
                insert: [],
                update: editedRows,
                ids: ""
            }).then((response) => {
                if (response.data.length > 0) {
                    api.LOG_MESSAGE(["Page : dataTransfer", "Action : Saved dataTransfers data "], { 'UserName': props.loggedInUser });
                    formMessage('Data Saved successfully', 'success');

                    let rowNode: any = null;
                    edittedData.forEach((element) => {
                        var dataTransfer = dataTransferData.findIndex(item => item.OrderDataTransferId === element.OrderDataTransferId);
                        if (dataTransfer > -1) {
                            rowNode = dataTransferGridRef.current.api.getRowNode(dataTransfer);
                            rowNode.data.isModified = false;
                            dataTransferData[dataTransfer].isModified = false;
                            delete dataTransferData[dataTransfer].modifiedAttrs;
                            rowNode.setData({ ...dataTransferData[dataTransfer], isModified: false });
                        }
                    });

                    if(rowNode?.data?.OrderId !== undefined){
                        eventMessaging.GetOrderAndBroadcastOrderUpdate(rowNode.data.OrderId,operator);
                    }

                    props.setShowProgress(false);
                }
            }).catch(() => {
            });

        };
    }

    const saveEditedDataHandler = () => {
        onSubmitHandler()
    }

    const onNavigateTabHandler = () => {
        dataTransferGridRef.current.api.undoCellEditing();
        setOpenSaveConfirmDialog(false);
    }

    const formMessage = (message: string, severity: string) => {
        setMessage({
            open: true,
            msg: message,
            severity: severity
        });
    }

    const cellClicked = (params: any) => {
        params.node.setSelected(true)
    }

    const closeMessage = () => {
        setMessage(initialMessage);
    }

    return (
        <>
            <Paper className="dataTransfersHeader" elevation={0} sx={{ height: 'auto !important', padding: '10px', marginBottom: '5px' }}>
                <TextField style={{ fontSize: 'x-small !important' }}
                    id="Operator" name="Operator" select label="Operator"
                    size="small"
                    SelectProps={{
                        native: true,
                    }}
                    value={operator}
                    onChange={e => {
                        setOperator(e.target.value);
                        autoSizeColumns(1000);
                        setselectedOrder([]);
                    }}>
                    {operatorList?.map((operator: string) => (
                        <option key={operator} value={operator}>
                            {operator}
                        </option>
                    ))}
                </TextField>

                <MyOrdersFilter operator={operator}
                    type={"DataTransfer"}
                    loggedInUser={props.loggedInUser}
                    loggedInUserMailId={props.loggedInUserMailId}
                    selectedOrder={selectedOrder}
                    orderPickHandler={orderPickHandler}></MyOrdersFilter>

                <Tooltip title="Save">
                    <IconButton
                        id="saveorderdata"
                        color="success"
                        aria-label="Save"
                        component="label"
                        style={{ backgroundColor: "rgb(239 238 238)", marginLeft: '10px', padding: '2px' }}
                        onClick={onSave}>
                        <SaveIcon />
                    </IconButton>
                </Tooltip>

                <Tooltip title="columns">
                    <IconButton id="odtcolumns"
                        color="success"
                        aria-label="columns"
                        component="label"
                        style={{ backgroundColor: "rgb(239 238 238)", marginRight: '5px', padding: '2px' }}
                        onClick={handleClickColumnsOpen}>
                        <ViewColumnOutlinedIcon />
                    </IconButton>
                </Tooltip>

                <div style={{ height: '20px' }}>
                    <Collapse in={message.open}>
                        <Alert onClose={closeMessage} severity={message.severity as AlertColor}
                            sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                            {message.msg}
                        </Alert>
                    </Collapse>
                </div>

            </Paper>

            <div style={{ display: 'flex' }}>
                <div style={{ width: "100%" }}>
                    <Paper id='orderDataTransferGridContent' className="ag-theme-balham" elevation={0} sx={{ height: '77vh', padding: '10px' }}>
                        <AgGridReact
                            ref={dataTransferGridRef}
                            gridOptions={gridOptions}
                            pagination={props.enablePagination !== undefined ? props.enablePagination : true}
                            getRowStyle={getRowStyle}
                            suppressExpandablePivotGroups={true}
                            onColumnGroupOpened={() => { onFirstDataRendered(1000) }}
                            rowSelection={'single'}
                            onCellClicked={cellClicked}
                            groupDefaultExpanded={-1}
                            onCellEditingStopped={onCellValueEnded}
                            onCellValueChanged={onCellValueChanged}
                            onFirstDataRendered={() => { onFirstDataRendered(1000) }}
                            overlayNoRowsTemplate={'<span style="padding: 10px; border: 2px solid #444; background: lightgoldenrodyellow;">No Data...</span>'}>
                        </AgGridReact>
                    </Paper>
                </div>
            </div>

            <ConfirmDialog
                open={openSaveConfirmDialog}
                setOpenConfirmDialogHandler={onNavigateTabHandler}
                title={confirmDialogObject.title}
                body={confirmDialogObject.body}
                okHandler={confirmDialogObject.okHandler}
            ></ConfirmDialog>

            <ColumnSelection
                tablePreferenceList={tablePreferenceList}
                selectedPreference={selectedPreference}
                setselectedPreference={setselectedPreference}
                loggedInUserMailId={props.loggedInUserMailId}
                loggedInUser={props.loggedInUser}
                hiddenColumns={hiddenColumns}
                columnsPerfOpen={columnsPerfOpen}
                anchorEl={anchorEl}
                applyColumnPreferenceHandler={applyColumnPreferenceHandler}
                setcolumnsPerfOpen={setcolumnsPerfOpen}
                displayColumns={displayColumns}
            ></ColumnSelection>
        </>
    )
}

export default OrderDataTransfer;