import * as React from 'react';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import IconButton from '@mui/material/IconButton';
import FullscreenOutlinedIcon from '@mui/icons-material/FullscreenOutlined';
import CloseFullscreenOutlinedIcon from '@mui/icons-material/CloseFullscreenOutlined';
import TableChartOutlinedIcon from '@mui/icons-material/TableChartOutlined';
import ReportGrid from './ReportGrid';
import "./QualityReport.css";
import Information from 'src/Common/Information';
import { Dialog, Popper, Slide } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';

interface IQualityReportProps {
  sequenceIndex: number,
  reportTitle: string,
  dataSource: string,
  ReportShortDesc: string
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function QualityReport(props: IQualityReportProps) {
  const [isFullScreen, setIsFullScreen] = React.useState<boolean>(false);

  const fullScreenToggle = () => {
    setIsFullScreen(!isFullScreen);
  }

  const qualityReportCard = () => {
    return <Card elevation={3} className={isFullScreen ? "fullScreen" : ""}>
      <CardHeader
        sx={{ backgroundColor: "#f1f1f1", color: "#505050" }}
        avatar={
          <TableChartOutlinedIcon />
        }
        action={
          <>
            <Information message={props.ReportShortDesc}></Information>
            <IconButton id="fullscreen" aria-label="Fullscreen" onClick={fullScreenToggle}>
              {isFullScreen ? (<CloseFullscreenOutlinedIcon />) : (<FullscreenOutlinedIcon />)}
            </IconButton>
          </>
        }
        title={props.reportTitle}
      />
      <CardContent>
        {props.dataSource?.length > 0 &&
          <ReportGrid
            sequenceIndex={props.sequenceIndex}
            dataSource={props.dataSource}
            isFullScreen={isFullScreen}
            reportTitle={props.reportTitle}></ReportGrid>}
      </CardContent>
    </Card>
  }

  return (
    <>
      <Dialog
        id='qualityDashboard'
        fullScreen
        open={true}
        sx={{ display: isFullScreen ? 'block' : 'none' }}
        onClose={() => { setIsFullScreen(false) }}
        TransitionComponent={Transition}
      >
        {qualityReportCard()}
      </Dialog>

      {qualityReportCard()}
    </>
  );
}