import { useEffect, useState } from "react";

function ColumnNameEditor(props: any) {
    const [displayName, setDisplayName] = useState(props.DisplayName);

    useEffect(()=>{
        setDisplayName(props.DisplayName);
    },[props.DisplayName]);

    const changeDisplayNameHandler = (value: string) => {
        setDisplayName(value);
        props.setColumnPreferenceDisplayNameHandler(props.ColumnName,value);
    }

    return (
        <>
            <input
                type="text"
                style={{margin:'0px',padding:'10px'}}
                name={props.DisplayName}
                value={displayName}
                onChange={(event) => { changeDisplayNameHandler(event.target.value); }}
                className="form-control " />
        </>
    )
}

export default ColumnNameEditor;