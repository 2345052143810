import { Button, Chip } from "@mui/material";
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import React, { useState } from "react";
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import * as api from 'src/Utility/api';

function FileUploadRenderer(props: any) {
    const inputFileRef: any = React.useRef();

    const handleFileUpload = async (e: any) => {
        const file = e.target.files[0];
        let primaryKeyId = props.node.data["" + props.pkColumnName];
        props.setShowProgress(true);

        await api.API_POST_FILEUPLOAD('/Table/UploadFileToAdls', {
            "SchemaName": props.schemaName,
            "TableName": props.tableName,
            "RowId": primaryKeyId,
            "PkColumnName": props.pkColumnName,
            "ColumnName": props.columnName,
            "lasFile": file
        })
            .then((response: any) => {
                props.setShowProgress(false);
                props.loadData(true);
                props.openSuccessMessage({
                    success: true,
                    message: "File uploaded successfully",
                    isSaveData: false
                });
            }).catch(() => {
                props.setShowProgress(false);
            });
    }

    const handleFileDelete = async () => {
        let primaryKeyId = props.node.data["" + props.pkColumnName];
        props.setShowProgress(true);

        await api.API_POST_FILEUPLOAD('/Table/DeleteFileInAdls', {
            "SchemaName": props.schemaName,
            "TableName": props.tableName,
            "RowId": primaryKeyId,
            "PkColumnName": props.pkColumnName,
            "ColumnName": props.columnName
        })
            .then((response: any) => {
                props.setShowProgress(false);
                props.loadData(true);
                props.openSuccessMessage({
                    success: true,
                    message: "File deleted successfully",
                    isSaveData: false
                });
            }).catch(() => {
                props.setShowProgress(false);
            });
    }

    return (
        <>
            <input
                ref={inputFileRef}
                type="file"
                accept="image/*"
                style={{ display: 'none' }}
                onChange={(e) => { handleFileUpload(e) }}
                id="contained-button-file"
            />
            {(props.value == null || props.value?.length == 0) &&
                <Button id="adlsbtn" variant="outlined"
                    style={{ width: '100%', height: '90%' }}
                    color="success"
                    startIcon={<DriveFolderUploadIcon color='success' />}
                    disabled={false}
                    onClick={() => inputFileRef.current.click()}>
                    ADLS
                </Button>}
            {(props.value != null && props.value?.length > 0) &&
                <Chip
                    label={props.value}
                    title={props.value}
                    onClick={() => { }}
                    onDelete={() => { handleFileDelete(); }}
                    deleteIcon={<DeleteOutlineOutlinedIcon style={{ cursor: 'pointer' }} />}
                    variant="outlined"
                >{props.value}</Chip>
            }
        </>
    )
}

export default FileUploadRenderer;