import React, { useEffect, useMemo, useRef, useState } from 'react';
import RefreshIcon from '@mui/icons-material/Refresh';
import * as api from 'src/Utility/api';
import { useEffectOnce } from 'src/hooks/useEffectOnce';
import { TableContext } from "../../@State/context/TableContext";
import { IDropDownSelectList, ITableGridColumnDefinition, TableType } from '../../@State/types';
import { AgGridReact } from 'ag-grid-react';
import './DropDownAgGridEditor.css';
import { Button } from '@mui/material';

function DropDownAgGridEditor(props: any) {
    const innerGridRef: any = useRef();
    const [selectQueryDetails, setSelectQueryDetails] = useState({
        schema: "",
        table: "",
        selectQuery: ""
    });
    const [rowIndex, setRowIndex] = useState(props.rowIndex);

    const isFirstColumn = (params: any) => {
        let displayedColumns = params.columnApi.getAllDisplayedColumns();
        let thisIsFirstColumn = displayedColumns[0] === params.column;
        return thisIsFirstColumn;
    };

    const defaultColDef = useMemo(() => {
        return {
            minWidth: 100,
            resizable: true,
            cellStyle: { textAlign: 'left' },
            headerCheckboxSelectionFilteredOnly: true,
            headerCheckboxSelection: isFirstColumn,
            checkboxSelection: isFirstColumn,
        };
    }, []);

    const [gridOptions, setGridOptions] = useState({
        rowData: [],
        columnDefs: [],
        defaultColDef: defaultColDef,
        pagination: false,
        getRowHeight: () => { return 25 }
    });

    const { saveDropDownSelectList, dropDownSelectListCollection, clearDropDownSelectList } = React.useContext(TableContext) as TableType;

    useEffectOnce(() => {
        setSelectQueryDetails({
            schema: props.schemaName,
            table: props.tableName,
            selectQuery: props.dropDownSelectQuery
        });
        fetchDropDownData();
    });

    const loadDropDownlist = (dropDownDataExists: any) => {
        if (typeof (dropDownDataExists) != "undefined") {
            if (dropDownDataExists?.data.TableData.length > 0) {
                const columns = Object.keys(dropDownDataExists.data.TableData[0]);
                let columnDefinition: ITableGridColumnDefinition[] = [];
                columns.forEach((row) => {
                    columnDefinition.push({
                        field: row,
                        //type: 'editableColumn',
                        headerName: row,
                        width: 180,
                        sortable: true,
                        filter: 'agTextColumnFilter',
                        cellClass: ['ag-cell-custom']
                    })
                });

                if (typeof (innerGridRef.current.api) !== 'undefined') {
                    innerGridRef.current.api.setColumnDefs(columnDefinition);
                    innerGridRef.current.api.setRowData(dropDownDataExists.data.TableData);
                } else {
                    innerGridRef.current.props.gridOptions.columnDefs = columnDefinition;
                    innerGridRef.current.props.gridOptions.rowData = dropDownDataExists.data.TableData;
                }
            }
        }
    }

    const fetchDropDownData = async (skipCache?: boolean) => {
        const dropDownDataExists = dropDownSelectListCollection?.filter((dropDownSelectList: IDropDownSelectList) => {
            return dropDownSelectList.colId == props.column.colId
                && dropDownSelectList.tableName == props.tableName
                && dropDownSelectList.schema == props.schemaName;
        });

        if (typeof (dropDownDataExists) == "undefined" || dropDownDataExists?.length == 0 || skipCache === true) {
            await api.API_GET('/Table/GetSelectQueryResult?schema='
                + props.schemaName + '&table='
                + props.tableName + '&selectQuery='
                + props.dropDownSelectQuery + '').then((response: any) => {
                    saveDropDownSelectList({
                        tableName: props.tableName,
                        schema: props.schemaName,
                        colId: props.column.colId,
                        data: response.data
                    });
                    loadDropDownlist({
                        tableName: props.tableName,
                        schema: props.schemaName,
                        colId: props.column.colId,
                        data: response.data
                    });
                });
        } else {
            loadDropDownlist(dropDownDataExists[0]);
        }
    }

    const onRowSelectedHandler = (rowSelected: any) => {
        props.onDropDownValueSelected(rowSelected.data, rowIndex, props.data);
    }

    return (
        <>
            <div style={{ textAlign: 'right' }}>
                <Button id="aggriddropdownbtn1"
                    variant="contained"
                    color="success"
                    startIcon={<RefreshIcon />}
                    onClick={() => { fetchDropDownData(true) }}>
                </Button>
            </div>
            <div style={{ height: "40vh", width: "60vh" }}>
                <AgGridReact
                    ref={innerGridRef}
                    gridOptions={gridOptions}
                    onRowSelected={onRowSelectedHandler}></AgGridReact>
            </div>
        </>
    )
}

export default DropDownAgGridEditor;