import * as React from 'react';
import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import TableView from '../TableView/TableView';

import './TabView.css';
import Valuation from '../Valuation/Valuation';
import CartProvider from '../../@State/context/CartContext';
import Cart from '../Cart/Cart';
import { ICartInfo, TabOrder } from '../../@State/types';
import TableProvider from '../../@State/context/TableContext';
import MapOutlinedIcon from '@mui/icons-material/MapOutlined';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import EngineeringOutlinedIcon from '@mui/icons-material/EngineeringOutlined';
import MapView from '../MapView/MapView';
import MapViewProvider from '../../@State/context/MapViewContext';
import Estimates from '../Estimates/Estimates';
import { Badge, Button, IconButton, Tooltip } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHandHoldingDollar, faMagnifyingGlassDollar } from '@fortawesome/free-solid-svg-icons';
import { useEffect } from 'react';
import { LicenseManager } from 'ag-grid-enterprise';
import { TabPanel } from 'src/Common/TabPanel';
import OrderDataTransfer from '../DataTransfer/OrderDataTransfer';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import DataQualityDashboard from '../../DataQualityDashboard/DataQualityDashboard';
import FilePresentIcon from '@mui/icons-material/FilePresent';


function a11yProps(index: number) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

export default function TabView(props: any) {
  LicenseManager.setLicenseKey("CompanyName=Zeus Solutions Inc,LicensedApplication=Oasis Data Exchange,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=0,AssetReference=AG-038725,SupportServicesEnd=29_February_2024_[v2]_MTcwOTE2NDgwMDAwMA==1651fd0655b06ef24f0110ac943c1e32");
  const [cartInformation, setCartInformation] = React.useState<ICartInfo[]>([]);
  const [IsRefreshClicked, setIsRefreshClicked] = React.useState(false);
  const [currentCartCount, setCurrentCartCount] = React.useState<number>(0);
  const [tabIndex, setTabIndex] = React.useState<number>(0);
  const [selectedCartOrder, setSelectedCartOrder] = React.useState<any[]>([]);

  const [IsValuation, setIsValuation] = React.useState(false);
  const [IsEstimate, setIsEstimate] = React.useState(false);
  const [IsdataTransfer, setIsdataTransfer] = React.useState(false);
  const [IsValuationLoaded, setIsValuationLoaded] = React.useState(false);
  const [IsEstimateLoaded, setIsEstimateLoaded] = React.useState(false);
  const [IsDataMaintenanceLoaded, setIsDataMaintenanceLoaded] = React.useState(false);
  const [IsDataTransferLoaded, setIsDataTransferLoaded] = React.useState(false);
  const [IsDataQualityDashboardLoaded, setIsDataQualityDashboardLoaded] = React.useState(false);

  const [selectedEstimateOrder, setSelectedEstimateOrder] = React.useState<any[]>([]);
  const [selectedValuationOrder, setSelectedValuationOrder] = React.useState<any[]>([]);
  const [selectedDataTransferOrder, setSelectedDataTransferOrder] = React.useState<any[]>([]);


  const theme = useTheme();
  const [value, setValue] = React.useState(TabOrder.MapView);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    props.setTabIndex(newValue);
  };

  useEffect(() => {
    if (value === TabOrder.Valuation && IsValuationLoaded === false) {
      setIsValuationLoaded(true);
    }
    if (value === TabOrder.Estimate && IsEstimateLoaded === false) {
      setIsEstimateLoaded(true);
    }
    if (value === TabOrder.DataTransfer && IsDataTransferLoaded === false) {
      setIsDataTransferLoaded(true);
    }
    if (value === TabOrder.DataMaintenance && IsDataMaintenanceLoaded === false) {
      setIsDataMaintenanceLoaded(true);
    }
    if (value === TabOrder.DataQualityDashboard && IsDataQualityDashboardLoaded === false) {
      setIsDataQualityDashboardLoaded(true);
    }
  }, [value]);

  useEffect(() => {
    handleChangeIndex(tabIndex);
  }, [tabIndex]);

  useEffect(() => {
    if (tabIndex == TabOrder.Valuation) {
      setIsValuation((IsValuation) => !IsValuation);
      setSelectedValuationOrder(selectedCartOrder);
    }

    else if (tabIndex == TabOrder.Estimate) {
      setIsEstimate((IsEstimate) => !IsEstimate);
      setSelectedEstimateOrder(selectedCartOrder);
    }

    else if (tabIndex == TabOrder.DataTransfer) {
      setIsdataTransfer((IsdataTransfer) => !IsdataTransfer);
      setSelectedDataTransferOrder(selectedCartOrder);
    }

  }, [selectedCartOrder]);

  const handleChangeIndex = (index: number) => {
    setValue(index);
  };

  const fetchTransactionIconHandler = () => {
    if (currentCartCount > 0) {
      return <Badge badgeContent={currentCartCount} color="primary"></Badge>;
    } else {
      return <ShoppingCartOutlinedIcon />;
    }
  }

  return (
    <Box sx={{ bgcolor: 'background.paper' }}>
      <AppBar position="static">
        <Tabs
          className="headerTab"
          sx={{ minHeight: "auto !important" }}
          value={value}
          onChange={handleChange}
          indicatorColor="secondary"
          textColor="inherit"
          aria-label="lab API tabs example">
          <Tab id="mapSearch" icon={<MapOutlinedIcon />} iconPosition="start" label={<Tooltip title="Map based well search"><span>Map Search</span></Tooltip>} />
          <Tab id="transactions" icon={fetchTransactionIconHandler()} iconPosition="start" label={<Tooltip title="Order Transactions"><span>&nbsp;Transactions</span></Tooltip>} />
          <Tab id="valuation" icon={<FontAwesomeIcon icon={faHandHoldingDollar} type="regular" size="2x" />} iconPosition="start" label={<Tooltip title="Your asset valuation"><span>Valuation</span></Tooltip>} />
          <Tab id="estimate" icon={<FontAwesomeIcon icon={faMagnifyingGlassDollar} size="2x" />} iconPosition="start" label={<Tooltip title="Order estimate"><span>Estimate</span></Tooltip>} />
          <Tab id="dtransfer" icon={<FilePresentIcon />} iconPosition="start" label={<Tooltip title="Order Data Transfer"><span>Data Transfer</span></Tooltip>} />
          <Tab id="dMaintenance" icon={<EngineeringOutlinedIcon />} iconPosition="start" label={<Tooltip title="Data Maintenance"><span>Data Maintenance</span></Tooltip>} />
          {props.IsAdminUser && <Tab icon={<DashboardOutlinedIcon />} iconPosition="start" label={<Tooltip title="Data Quality Dashboard"><span>Data Quality Dashboard</span></Tooltip>} />}
        </Tabs>
      </AppBar>
      <SwipeableViews
        className='tabPanel'
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={value}
        onChangeIndex={handleChangeIndex}>
        <TabPanel value={0} index={0} dir={theme.direction}>
          <MapViewProvider>
            <MapView
              cartInformation={cartInformation}
              setCartInformation={setCartInformation}
              loggedInUser={props.loggedInUser}
              loggedInUserMailId={props.loggedInUserMailId}
              userSecurityPermission={props.userSecurityPermission}
              tabIndex={value}>
            </MapView>
          </MapViewProvider>
        </TabPanel>
        <TabPanel value={1} index={1} dir={theme.direction}>
          <CartProvider>
            <Cart
              loggedInUserMailId={props.loggedInUserMailId}
              loggedInUser={props.loggedInUser}
              cartInformation={cartInformation}
              value={value}
              tabIndex={value}
              setTabIndex={setTabIndex}
              setCurrentCartCount={setCurrentCartCount}
              setShowProgress={props.setShowProgress}
              setCartInformation={setCartInformation}
              IsRefreshClicked={props.IsRefreshClicked}
              setSelectedCartOrder={setSelectedCartOrder}>
            </Cart>
          </CartProvider>
        </TabPanel>
        <TabPanel value={IsValuationLoaded ? 2 : value} index={2} dir={theme.direction}>
          <Valuation
            value={value}
            loggedInUser={props.loggedInUser}
            loggedInUserMailId={props.loggedInUserMailId}
            setShowProgress={props.setShowProgress}
            selectedCartOrder={selectedValuationOrder}
            IsValuation={IsValuation}
            IsValuationLoaded={IsValuationLoaded}
            IsSummary={false}
            setTabIndex={setTabIndex}
            tabIndex={value}
          ></Valuation>
        </TabPanel>
        <TabPanel value={IsEstimateLoaded ? 3 : value} index={3} dir={theme.direction}>
          <Estimates
            value={value}
            loggedInUser={props.loggedInUser}
            loggedInUserMailId={props.loggedInUserMailId}
            setShowProgress={props.setShowProgress}
            selectedCartOrder={selectedEstimateOrder}
            IsEstimate={IsEstimate}
            IsEstimateLoaded={IsEstimateLoaded}
            IsSummary={false}
            setTabIndex={setTabIndex}
            tabIndex={value}
          ></Estimates>
        </TabPanel>

        <TabPanel value={IsDataTransferLoaded ? 4 : value} index={4} dir={theme.direction}>
          <OrderDataTransfer
            value={value}
            loggedInUser={props.loggedInUser}
            loggedInUserMailId={props.loggedInUserMailId}
            setShowProgress={props.setShowProgress}
            selectedCartOrder={selectedDataTransferOrder}
            setTabIndex={setTabIndex}
            IsdataTransfer = {IsdataTransfer}
            tabIndex={value}
          ></OrderDataTransfer>
        </TabPanel>


        <TabPanel value={IsDataMaintenanceLoaded ? 5 : value} index={5} dir={theme.direction}>
          <TableProvider>
            <TableView
              loggedInUser={props.loggedInUser}
              loggedInUserMailId={props.loggedInUserMailId}
              userSecurityPermission={props.userSecurityPermission}
              selectedTableInfo={props.selectedTableInfo}
              cartInformation={cartInformation}
              tabIndex={5}
              setShowProgress={props.setShowProgress}
              setCartInformation={setCartInformation}
              IsRefreshClicked={props.IsRefreshClicked}
              setIsRefreshClicked={props.setIsRefreshClicked} />
          </TableProvider>
        </TabPanel>
       <TabPanel value={IsDataQualityDashboardLoaded ? 5 : value} index={5} dir={theme.direction}>
          <TableProvider>
            <DataQualityDashboard/>
          </TableProvider>
        </TabPanel>
      </SwipeableViews>
    </Box>
  );
}
