import { Route, Routes } from 'react-router-dom';
import AddTable from 'src/Components/Dashboard/AddTable/AddTable';
import HistoryTable from 'src/Components/Dashboard/HistoryTable/HistoryTable'
import Tables from 'src/Components/Dashboard/Tables/Tables';
import { ICartInfo } from '../@State/types';
import CirclePoc from '../CirclePoc/CirclePoc';
import Dashboard from '../Dashboard';
import DataQualityDashboard from '../DataQualityDashboard/DataQualityDashboard';

interface IRoutingProps {
    loggedInUser: string,
    loggedInUserMailId: string,
    userSecurityPermission: any,
    IsAdminUser:boolean,
    //cartInformation?: ICartInfo[],
    IsRefreshClicked:boolean,
    setShowProgress: (show: boolean) => void,
    //setCartInformation: React.Dispatch<React.SetStateAction<ICartInfo[]>>,
    setIsRefreshClicked: (show: boolean) => void
}

function Routing(props: IRoutingProps) {

    return (
        <Routes>
             <Route path='/' element={<Tables
                loggedInUser={props.loggedInUser}
                loggedInUserMailId={props.loggedInUserMailId}
                userSecurityPermission={props.userSecurityPermission}
                IsRefreshClicked={props.IsRefreshClicked}
                IsAdminUser={props.IsAdminUser}
                setShowProgress={props.setShowProgress}
                setIsRefreshClicked = {props.setIsRefreshClicked}></Tables>}>
            </Route>
            <Route path='addtable' element={<AddTable />}></Route>

            <Route path='historytable' element={
                <HistoryTable
                    loggedInUserMailId={props.loggedInUserMailId}
                    setShowProgress={props.setShowProgress}>
                </HistoryTable>}>
            </Route>

            <Route path='tables' element={
                <Tables
                    loggedInUser={props.loggedInUser}
                    loggedInUserMailId={props.loggedInUserMailId}
                    userSecurityPermission={props.userSecurityPermission}
                    IsRefreshClicked={props.IsRefreshClicked}
                    IsAdminUser={props.IsAdminUser}
                    setShowProgress={props.setShowProgress}
                    setIsRefreshClicked = {props.setIsRefreshClicked}></Tables>}>
            </Route>

            <Route path='circleDragPoc' element={<CirclePoc></CirclePoc>}></Route>

            <Route path='DataQualityDashboard' element={<DataQualityDashboard></DataQualityDashboard>}></Route>

        </Routes>
    );
}

export default Routing;