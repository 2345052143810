import React from 'react';
import * as api from 'src/Utility/api';
import './App.css';
import AppProvider from './Components/Dashboard/@State/context/AppContext';
import Dashboard from './Components/Dashboard/Dashboard';
import { useEffectOnce } from './hooks/useEffectOnce';
import Login from './login';
import IdleTimer from 'react-idle-timer';

function App() {

  const [IsUserLoggedIn, setIsUserLoggedIn] = React.useState(false);

  // If changed for local development , please change it back to ( UI USER and  uiuser@ui.com )

  const [loggedInUser, setLoggedInUser] = React.useState("UI USER"); //UI USER
  const [loggedInUserMailId, setLoggedInUserMailId] = React.useState("uiuser@ui.com");
  const [idleTimer,setIdleTimer] = React.useState<any>("uiuser@ui.com");


  useEffectOnce(() => {
    async function fetchUserInfo() {

      let username = sessionStorage.getItem('userName');
      let userEmailId = sessionStorage.getItem('userEmailId');
      let token = sessionStorage.getItem('access_Token');

      setLoggedInUser(username != null ? username : "");
      setLoggedInUserMailId(userEmailId != null ? userEmailId : "");

      if(username != null && userEmailId != null){
        setIsUserLoggedIn(true);
        sessionStorage.setItem('access_Token', token != null ? token : "");
      }
    }

    fetchUserInfo().then(() => {
    }
    ).catch(
      () => {
      }
    )
  });

  return (
    <div className="App">
      {/* <IdleTimer
          ref={(ref:any) => { this.idleTimer = ref }}
          timeout={1000 * 60 * 15}
          onActive={this.handleOnActive}
          onIdle={this.handleOnIdle}
          onAction={this.handleOnAction}
          debounce={250}
        /> */}
       {(sessionStorage.getItem('userName') != null && sessionStorage.getItem('userEmailId') != null) ?
      <AppProvider>
       
         <Dashboard
          loggedInUserMailId={loggedInUserMailId}
          loggedInUser={loggedInUser}
        ></Dashboard>  
        
      </AppProvider>
        :
        <Login 
        setIsUserLoggedIn={setIsUserLoggedIn}
        setLoggedInUser = {setLoggedInUser} 
        setLoggedInUserMailId = {setLoggedInUserMailId}
        ></Login>  
        }
      
    </div>
  );
}

export default App;
