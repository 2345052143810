import { TextField } from '@mui/material';
import React, { useState } from 'react';
import { useEffectOnce } from 'src/hooks/useEffectOnce';

function DropDownCellEditor(props: any) {
    const [option, setOption] = useState(props.value);
    const [DropDownOptions, setDropDownOptions] = useState([]);
    const [rowIndex, setRowIndex] = useState(props.rowIndex);
    const [isExecutedOrder] = useState(props.data?.OrderStatus === 'Executed');

    useEffectOnce(() => {
        setDropDownOptions(JSON.parse(props.DropDownOptionsJSON));
    });

    const onOptionChange = (event: any) => {
        props.onDropDownValueSelected(event.target.value, rowIndex);
        setOption(event.target.value);
    }

    const getByKey = (arr: any[], key: any) => (arr.find(x => Object.keys(x)[0] === key) || {})[key]

    return (
        <div>
            <TextField 
            select size="small" 
            SelectProps={{
                native: true,
            }} 
            disabled = {isExecutedOrder}
            style={{ width: "100%",margin:'0px' }} 
            value={option} onChange={onOptionChange}>
                {DropDownOptions 
                && DropDownOptions.map((key: any, index: number) => {
                    return <option
                        value={key}
                        key={index + "" + key}>{key}</option>
                })}
            </TextField>
        </div>
    )
}

export default DropDownCellEditor;