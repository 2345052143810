import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import * as api from 'src/Utility/api';
import { Chip, IconButton, TextField, Typography } from '@mui/material';
import { convertUTCtoCDT, currencyFormatter } from 'src/Utility/common';
import ReportInformation from '../ReportInformation/ReportInformation';
import MapViewRenderer from '../../Cart/MapViewRenderer';
import CartDataRenderer from '../../CellRenderers/CartDataRenderer';
import OrderApprovalRenderer from '../../Cart/OrderApprovalRenderer';
import OrderTimeLine from '../OrderTimeLine/OrderTimeLine';
import Valuation from '../../Valuation/Valuation';
import Estimates from '../../Estimates/Estimates';
import SaveIcon from '@mui/icons-material/Save';
import { debounce } from 'src/Utility/api';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

export default function OrderInformation(props: any) {
    const [wellsInOrder, setWellsInOrder] = React.useState<any[]>([]);
    const [selectedEstimate, setSelectedEstimate] = React.useState<any>(null);
    const [selectedValuation, setSelectedValuation] = React.useState<any>(null);
    const [selectedReportClicked, setSelectedReportClicked] = React.useState<any>("");
    const [selectedReportLevel, setSelectedReportLevel] = React.useState<any>("");
    const [selectedEstValData, setSelectedEstValData] = React.useState<any[]>([]);
    const [IsDataModified, setIsDataModified] = React.useState(false);
    const [IsValuationView, setIsValuationView] = React.useState(false);

    React.useEffect(() => {
        if (props.orderInfo && props.orderInfo?.Data) {
            let _jsonData = [];
            if (typeof (props.orderInfo.Data) === 'string') {
                _jsonData =  JSON.parse(props.orderInfo.Data);
            }
            else {
                _jsonData =  props.orderInfo.Data;
            }

            setWellsInOrder(_jsonData);
        }

        setIsValuationView(props.operator === props.orderInfo.RequestedTo);

    }, [props.orderInfo]);

    React.useEffect(() => {
        if (selectedEstimate !== null || selectedValuation !== null) {
            props.setShowTimeLine(false);
        }
    }, [selectedEstimate, selectedValuation]);

    const updateOrdersApi = async function () {
        if (props.otherText.trim().length > 0) {
            await api.API_POST('/OrderManagement/UpdateOrder', {
                orders: [{
                    OrderId: props.orderInfo.OrderId,
                    Info1: props.otherText
                }]
            }).then((response) => {
                props.formMessage('Message updated successfully', 'success');
            }).catch(() => {
            });
        }
    }

    const setIsDataModifiedHandler = (isClicked: boolean) => {
        setIsDataModified((isClicked) => !isClicked);
    };

    const setSelectedReports = async function (reportName : any, level : any) {
        setSelectedReportClicked(reportName);
        setSelectedReportLevel(level);
    }


    return (
        <div id='orderinformation'>
            <Box sx={{ flexGrow: 1, height: '90vh' }}>
                <Grid container spacing={1}>
                    <Grid item xs={1.5}>
                        <Item sx={{ borderRadius: '20px' }}>
                            <Typography variant="button" display="block" gutterBottom >
                                TRADE TYPE
                            </Typography>
                            <Chip sx={{ backgroundColor: '#7eaedd2e', width: '90%' }} label={props.orderInfo.TradeType} variant="outlined" />
                        </Item>
                    </Grid>
                    <Grid item xs={1.5}>
                        <Item sx={{ borderRadius: '20px' ,display: 'flex', flexDirection: 'column'  }}>
                            <Typography variant="button" display="block" gutterBottom >
                                Transaction #
                            </Typography>
                            <Chip sx={{ backgroundColor: '#7eaedd2e', width: '90%' }} label={props.orderInfo.TradeType === "Trade" ? props.orderInfo.TradeGroupId : props.orderInfo.OrderId} variant="outlined" />
                        </Item>
                    </Grid>
                    <Grid item xs={1.5}>
                        <Item sx={{ borderRadius: '20px' }}>
                            <Typography variant="button" display="block" gutterBottom >
                                START DATE

                            </Typography>
                            <Chip
                                sx={{ backgroundColor: '#7eaedd2e', width: '90%' }}
                                label={convertUTCtoCDT(props.orderInfo.StartDate + "Z", true)}
                                variant="outlined" />
                        </Item>
                    </Grid>
                    <Grid item xs={1.5}>
                        <Item sx={{ borderRadius: '20px' }}>
                            <Typography variant="button" display="block" gutterBottom >
                                END DATE

                            </Typography>
                            <Chip
                                sx={{ backgroundColor: '#7eaedd2e', width: '90%' }}
                                label={convertUTCtoCDT(props.orderInfo.EndDate + "Z", true)}
                                variant="outlined" />
                        </Item>
                    </Grid>
                    <Grid item xs={2}>
                        <Item sx={{ borderRadius: '20px' }}>
                            <Typography variant="button" display="block" gutterBottom >
                                AMOUNT
                            </Typography>
                            <Chip sx={{ backgroundColor: '#7eaedd2e', width: '80%' }} label={currencyFormatter(props.orderInfo.Amount)} variant="outlined" />
                        </Item>
                    </Grid>


                    <Grid item xs={1.5}><Item sx={{ borderRadius: '20px', display: 'flex', flexDirection: 'column' }}>
                        <div style={{ width: '100%' }}>
                            <Typography variant="button" display="block" gutterBottom >
                                WELLS
                            </Typography>
                        </div>
                        <div style={{ width: '100%', justifyContent: 'center', display: 'flex' }}>
                            <Chip sx={{ backgroundColor: '#7eaedd2e' }} label={wellsInOrder.length} variant="outlined" />
                            {props.setTabIndex
                                && <>
                                    {(props.orderInfo && props.orderInfo?.Data) &&
                                        <span style={{ alignSelf: 'center', marginLeft: '2%' }}>
                                            <CartDataRenderer
                                                maxWellsCount = {props.maxWellsCount}
                                                cartData={props.cartData}
                                                selectedOperator={props.operator}
                                                rowIndex={props.rowIndex}
                                                hideText={true}
                                                isReadOnly={props.operator === props.orderInfo.RequestedTo} 
                                                onAddWells={props.onAddWells}
                                                data={props.orderInfo}
                                                isOrderSummary={true}
                                                closeOrderSummaryHandler={props.summaryCloseHandler}/>
                                        </span>
                                    }
                                    <span style={{ alignSelf: 'center', marginLeft: '2%' }} onClick={props.summaryCloseHandler}>
                                        <MapViewRenderer
                                            data={props.orderInfo}
                                            groupedOrders={props.groupedOrders}
                                            setTabIndex={props.setTabIndex}
                                            selectedOperator={props.operator} />
                                    </span>
                                </>
                            }
                        </div>
                    </Item>
                    </Grid>

                    <Grid item xs={2.5}><Item sx={{ borderRadius: '20px', display: 'flex', flexDirection: 'column', width: '100%' }}>
                        <div style={{ width: 'inherit' }}>
                            <Typography variant="button" display="block" gutterBottom >
                                APPROVAL STATUS
                            </Typography>
                        </div>
                        <div style={{ width: 'inherit' }}>
                            <OrderApprovalRenderer data={props.orderInfo} loggedInUserMailId={props.loggedInUserMailId} operator={props.operator} displayAbbrev={true} />
                        </div>
                    </Item>
                    </Grid>

                    <Grid item xs={12}>
                        <Item sx={{ borderRadius: '20px' }}>
                            <TextField
                                label={"Other items as per " + props.orderInfo.TradeType + ""}
                                id="columnselection-search-text-box"
                                sx={{ width: '90%' }}
                                value={props.otherText}
                                size="small"
                                onChange={(ele) => { debounce(props.setOtherText(ele.target.value)) }}
                            />
                            <IconButton id="orderinfosave"
                                color="success"
                                aria-label="Save"
                                component="label"
                                style={{ backgroundColor: "rgb(239 238 238)", marginLeft: '10px' }}
                                onClick={() => { updateOrdersApi() }}>
                                <SaveIcon />
                            </IconButton>
                        </Item>
                    </Grid>

                    <Grid item xs={props.showOnlyReportInfoTrade ? 12 : 3}>
                        <Item>
                            <ReportInformation
                                orderInfo={props.orderInfo}
                                wellsInOrder={wellsInOrder}
                                reportGroups={props.reportGroups}
                                setTabIndex={props.setTabIndex}
                                selectedReportClicked={selectedReportClicked}
                                setSelectedReportClicked={setSelectedReports}
                                IsDataModified = {IsDataModified}
                                selectedEstValData = {selectedEstValData}
                                IsValuationView = {IsValuationView}
                                summaryCloseHandler={props.summaryCloseHandler}
                                showOnlyReportInfoTrade={props.showOnlyReportInfoTrade}
                                setShowOnlyReportInfoTrade={props.setShowOnlyReportInfoTrade}></ReportInformation>
                        </Item>
                    </Grid>
                    {
                        props.operator === props.orderInfo.RequestedTo &&
                        props.showOnlyReportInfoTrade == false &&
                        <Grid
                            item xs={9}
                            sx={{ height: '60vh' }}>
                            <Valuation
                                loggedInUser={props.loggedInUserName}
                                loggedInUserMailId={props.loggedInUserMailId}
                                setShowProgress={() => { }}
                                selectedCartOrder={[props.orderInfo]}
                                IsValuation={true}
                                IsValuationLoaded={true}
                                IsSummary={true}
                                enablePagination={props.enablePagination}
                                setTabIndex={props.setTabIndex}
                                selectedReportClicked = {selectedReportClicked}
                                selectedReportLevel = {selectedReportLevel}
                                setSelectedEstValData={setSelectedEstValData}
                                setIsDataModified = {setIsDataModifiedHandler}
                                summaryCloseHandler={props.summaryCloseHandler}
                                groupedOrders={props.groupedOrders}
                            >
                            </Valuation>
                        </Grid>
                    }
                    {
                        props.operator === props.orderInfo.RequestedBy &&
                        props.showOnlyReportInfoTrade == false &&
                        <Grid
                            item xs={9}
                            sx={{ height: '60vh' }}>
                            <Estimates
                                loggedInUser={props.loggedInUserName}
                                loggedInUserMailId={props.loggedInUserMailId}
                                setShowProgress={() => { }}
                                selectedCartOrder={[props.orderInfo]}
                                IsEstimate={true}
                                IsEstimateLoaded={true}
                                IsSummary={true}
                                setTabIndex={props.setTabIndex}
                                enablePagination={props.enablePagination}
                                selectedReportClicked = {selectedReportClicked}
                                selectedReportLevel = {selectedReportLevel}
                                setSelectedEstValData={setSelectedEstValData}
                                setIsDataModified = {setIsDataModifiedHandler}
                                IsSummaryTabIndex={props.setTabIndex}
                                summaryCloseHandler={props.summaryCloseHandler}
                                groupedOrders={props.groupedOrders}
                            ></Estimates>
                        </Grid>
                    }
                  
                </Grid>
            </Box>
        </div>
    );
}