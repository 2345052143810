import React from "react";
import TreeItem from "@mui/lab/TreeItem";
import TreeView from "@mui/lab/TreeView";
import {
  AppBar,
  Avatar,
  Box,
  CardHeader,
  Checkbox,
  DialogActions,
  Divider,
  Fade,
  FormControlLabel,
  FormGroup,
  IconButton,
  Paper,
  Popper,
  Slide,
  Stack,
  Tab,
  Tooltip,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import CancelIcon from "@mui/icons-material/Cancel";
import { styled, useTheme } from "@mui/material/styles";
import { TransitionProps } from "@mui/material/transitions";

import { deepPurple } from "@mui/material/colors";
import {
  ExchangeOrderType,
  IUserSecurityInfo,
} from "src/Components/Dashboard/@State/types";

import SwipeableViews from "react-swipeable-views";
import Tabs from "@mui/material/Tabs";
import SecurityIcon from "@mui/icons-material/Security";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import Balances from "./Balances";
import { AppContext } from "src/Components/Dashboard/@State/context/AppContext";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function UserProfile(props: any) {
  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const canBeOpen = open && Boolean(anchorEl);
  const [value, setValue] = React.useState(0);
  const [expandedSchemas, setExpandedSchemas] = React.useState<string[]>([]);
  const { betaDataAuditInfo, setBetaDataAuditInfo } = React.useContext(
    AppContext
  ) as ExchangeOrderType;

  const id = canBeOpen ? "userProfile" : undefined;
  const theme = useTheme();
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setOpen((open) => !open);
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  React.useEffect(() => {
    let schemas: any = [];
    props.securityProfile.forEach((element: any) => {
      if (schemas.indexOf(element.SecurityGroupId) === -1) {
        schemas.push(element.SecurityGroupId.toString());
      }

      setExpandedSchemas(schemas);
    });
  }, [props.loggedInUserMailId, props.securityProfile]);

  const clickAwayHandler = () => {
    setOpen(false);
  };

  const handleChangeIndex = (index: number) => {
    setValue(index);
  };

  const openPassWordResetPage = () => {
    window.open(
      "https://myaccount.microsoft.com/?ref=MeControl&login_hint={loggedInUser}".replace(
        "{loggedInUser}",
        props.loggedInUserMailId
      ),
      "_blank"
    );
  };

  const RenderTree = (nodes: IUserSecurityInfo) => {
    return (
      <TreeItem
        key={nodes.SecurityGroupId}
        nodeId={nodes.SecurityGroupId.toString()}
        label={nodes.SecurityGroupName + "-  " + nodes.SecurityGroupDescription}
      >
        {Array.isArray(nodes.Operators)
          ? nodes.Operators.map((node: any) => getTreeSubItemsFromData(node))
          : null}
      </TreeItem>
    );
  };

  const getTreeSubItemsFromData = (treeItems: any) => {
    return (
      <TreeItem
        key={treeItems.OperatorId}
        nodeId={treeItems.OperatorId.toString()}
        label={treeItems.ShortName + "- " + treeItems.LegalName}
      />
    );
  };

  return (
    <div>
      <CardHeader
        sx={{ padding: "7px" }}
        avatar={
          <Avatar sx={{ bgcolor: deepPurple[500] }} onClick={handleClick} />
        }
        title={props.loggedInUser}
      />
      <Popper
        id={id}
        open={open}
        anchorEl={anchorEl}
        style={{ width: "500px", height: "350px" }}
        transition
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps}>
            <Box
              sx={{
                border: 2,
                p: 1,
                bgcolor: "background.paper",
                borderRadius: 5,
              }}
            >
              <div style={{ display: "flex", width: "100%" }}>
                <a
                  style={{ marginTop: 20, float: "right", flex: 1 }}
                  href="#"
                  onClick={openPassWordResetPage}
                >
                  Change Password{" "}
                </a>

                <DialogActions style={{ margin: 2, float: "right" }}>
                  <IconButton
                    id="cancel"
                    color="error"
                    aria-label="cancel"
                    onClick={clickAwayHandler}
                  >
                    <CancelIcon />
                  </IconButton>
                </DialogActions>
              </div>

              <Divider textAlign="left">User Info</Divider>
              <Stack direction="column">
                <label> Username : {props.loggedInUser} </label>
                <label> Email Id : {props.loggedInUserMailId} </label>
              </Stack>

              <AppBar position="static">
                <Tabs
                  className="headerTab"
                  value={value}
                  onChange={handleChange}
                  indicatorColor="secondary"
                  textColor="inherit"
                  aria-label="profile tabs"
                >
                  <Tab
                    icon={<SecurityIcon />}
                    iconPosition="start"
                    label={
                      <Tooltip title="Security Profile">
                        <span>Security Profile</span>
                      </Tooltip>
                    }
                  />
                  <Tab
                    icon={<SecurityIcon />}
                    iconPosition="start"
                    label={
                      <Tooltip title="Security Profile">
                        <span>Beta features</span>
                      </Tooltip>
                    }
                  />
                  {props.IsGlobalAdmin && (
                    <Tab
                      icon={<AccountBalanceWalletIcon />}
                      iconPosition="start"
                      label={
                        <Tooltip title="Balance">
                          <span>Balance</span>
                        </Tooltip>
                      }
                    />
                  )}
                </Tabs>
              </AppBar>
              <SwipeableViews
                className="tabPanel"
                axis={theme.direction === "rtl" ? "x-reverse" : "x"}
                index={value}
                style={{ height: "350px" }}
                onChangeIndex={handleChangeIndex}
              >
                {value === 0 && (
                  <TabPanel value={0} index={0} dir={theme.direction}>
                    <TreeView
                      defaultCollapseIcon={<ExpandMoreIcon />}
                      defaultExpandIcon={<ChevronRightIcon />}
                      expanded={expandedSchemas}
                      sx={{ overflow: "unset", bgcolor: "background.paper" }}
                    >
                      {props.securityProfile.map((data: any) => {
                        return RenderTree(data);
                      })}
                    </TreeView>
                  </TabPanel>
                )}

                <TabPanel value={1} index={1} dir={theme.direction}>
                  <FormGroup>
                    <FormControlLabel
                      onClick={() => {
                        setBetaDataAuditInfo(!betaDataAuditInfo);
                      }}
                      control={
                        <Checkbox checked={betaDataAuditInfo ? true : false} />
                      }
                      label="Data Audit Info"
                    />
                  </FormGroup>
                </TabPanel>
                {value === 2 && props.IsGlobalAdmin && (
                  <TabPanel value={2} index={2} dir={theme.direction}>
                    <Balances
                      securityProfile={props.securityProfile}
                    ></Balances>
                  </TabPanel>
                )}
              </SwipeableViews>
            </Box>
          </Fade>
        )}
      </Popper>
    </div>
  );
}

export default UserProfile;
