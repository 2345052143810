import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import MenuIcon from "@mui/icons-material/Menu";
import RefreshIcon from "@mui/icons-material/Refresh";
import { Badge, Button, LinearProgress, Paper, Tooltip } from "@mui/material";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import MuiDrawer from "@mui/material/Drawer";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Link from "@mui/material/Link";
import List from "@mui/material/List";
import { createTheme, styled, ThemeProvider } from "@mui/material/styles";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import * as React from "react";
import UserProfile from "src/Common/UserProfile";
import * as api from "src/Utility/api";
import logo from "../../Utility/Logo/oasis_logo.jpg";
import { ExchangeOrderType, IUserSecurityInfo } from "./@State/types";
import { mainListItems } from "./MenuItems/listItems";
import Routing from "./Routing/Routing";
import TableLogs from "./Tables/TableLogs/TableLogs";
import LogoutIcon from "@mui/icons-material/Logout";
import HelpVideos from "./Tables/HelpVideos/HelpVideos";
import { useEffectOnce } from "src/hooks/useEffectOnce";
import { AppContext } from "./@State/context/AppContext";

function Copyright(props: any) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://zeussolutionsinc.com">
        https://zeussolutionsinc.com
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const drawerWidth: number = 240;

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

const mdTheme = createTheme();

function stringToColor(string: string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

function stringAvatar(name: string) {
  return {
    sx: {
      bgcolor: stringToColor(name),
    },
    children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
  };
}

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

function DashboardContent(props: any) {
  const [open, setOpen] = React.useState(false);
  const toggleDrawer = () => {
    setOpen(!open);
  };

  const [showProgress, setShowProgress] = React.useState(false);
  const [loggedInUser, setLoggedInUser] = React.useState(props.loggedInUser); //UI USER
  const [loggedInUserMailId, setLoggedInUserMailId] = React.useState(
    props.loggedInUserMailId
  ); //praveen@zeussolutionsincoutlook.onmicrosoft.com
  const [userSecurityPermission, setUserSecurityPermission] =
    React.useState(null);
  const [IsRefreshClicked, setIsRefreshClicked] = React.useState(false);
  const [userSecurityProfile, setuserSecurityProfile] = React.useState<
    IUserSecurityInfo[]
  >([]);
  const [IsGlobalAdmin, setIsGlobalAdmin] = React.useState(false);
  const [pubSubClientUrl, setPubSubClientUrl] = React.useState<string[]>([]);
  const [savedBroadcastMessages, setSavedBroadcastMessages] = React.useState<
    any[]
  >([]);
  const { setAdminUser, IsAdminUser } = React.useContext(
    AppContext
  ) as ExchangeOrderType;

  const setShowProgressHandler = (show: boolean) => {
    setShowProgress(show);
  };

  const setIsRefreshClickedHandler = (isClicked: boolean) => {
    setIsRefreshClicked((isClicked) => !isClicked);
    fetchUserInfo();
  };

  const LogoutUser = () => {
    async function LogOutUser() {
      sessionStorage.removeItem("access_Token");
      sessionStorage.removeItem("userEmailId");
      sessionStorage.removeItem("userName");
      sessionStorage.removeItem("Operators");
      window.location.reload();
      api.LOG_MESSAGE(["Page : Logout", "Action : User LoggedOut"], {
        UserName: loggedInUser,
      });
      //window.location.replace(api.logout_url);
    }

    LogOutUser()
      .then(() => {})
      .catch(() => {});
  };

  useEffectOnce(() => {
  initBroadcastAndPubsub();
  });

  const initBroadcastAndPubsub = () =>{
  if (sessionStorage.getItem("Operators") !== null) {
      let _operators: any = sessionStorage.getItem("Operators");
      fetchPubSubClientUrl(_operators.split(","));
      fetchSavedBroadcastMessages(_operators.split(","));
    }
  }

  async function fetchPubSubClientUrl(operator: string) {
    await api
      .API_GET("/Messaging/GetClientURL?hubNames=" + operator)
      .then((response) => {
        setPubSubClientUrl(response.data);
      });
  }

  async function fetchSavedBroadcastMessages(operator: string) {
    await api.API_GET('/Messaging/RecieveEventHubMessage?operatorsTo=' + operator)
        .then((response) => {
            setSavedBroadcastMessages(response.data);
        });
}
  async function fetchUserInfo() {
    await api
      .API_GET(
        "/Common/GetSecurityProfile?userName=" + props.loggedInUserMailId
      )
      .then((response) => {
        if (response.data.SecurityGroups.length > 0) {
          setuserSecurityProfile(response.data.SecurityGroups);
          if (sessionStorage.getItem("Operators")) {
            sessionStorage.removeItem("Operators");
            sessionStorage.setItem("Operators", response.data.Operators);

            if(pubSubClientUrl.length == 0){
              initBroadcastAndPubsub();
            }
          } else {
            sessionStorage.setItem("Operators", response.data.Operators);

            if(pubSubClientUrl.length == 0){
              initBroadcastAndPubsub();
            }
          }
        }
      });
  }

  React.useEffect(() => {
    if (
      props.loggedInUser !== "UI USER" &&
      props.loggedInUserMailId !== "uiuser@ui.com"
    ) {
      fetchUserInfo()
        .then(() => {
          fetchPendingDetails(props.loggedInUserMailId);
        })
        .catch(() => {
          fetchPendingDetails(props.loggedInUserMailId);
        });
    }
  }, [props.loggedInUser, props.loggedInUserMailId]);

  async function fetchPendingDetails(_loggedInUserMailId: any) {
    await api
      .API_GET(
        "/Table/GetUserSecurityPermissions?loggedInUser=" + _loggedInUserMailId
      )
      .then((response) => {
        if (response.data.TableData.length > 0) {
          setUserSecurityPermission(response.data.TableData);

          const globalAdmin = response.data.TableData.filter(
            (permission: any) => {
              return (
                permission.SchemaName === null &&
                permission.TableName === null &&
                permission.UserMailId.toLowerCase() ===
                  props.loggedInUserMailId.toLowerCase()
              );
            }
          );
          if (globalAdmin.length > 0) {
            setIsGlobalAdmin(true);
            setAdminUser(true);
          }
        }
      });
  }

  return (
    <ThemeProvider theme={mdTheme}>
      <Box sx={{ display: "flex", zIndex: "100" }}>
        <CssBaseline />
        <AppBar position="absolute" open={open} style={{ zIndex: "200" }}>
          <Toolbar
            style={{ minHeight: "auto" }}
            sx={{
              pr: "24px", // keep right padding when drawer closed
            }}
          >
            <IconButton
              id="opendrawer"
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                marginRight: "36px",
                display: "none",
                ...(open && { display: "none" }),
              }}
            >
              <MenuIcon />
            </IconButton>

            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1, textAlign: "left" }}
            >
              {api.env === "OASIS"
                ? "OASIS DATA EXCHANGE"
                : api.env === "ENVOY"
                ? "ENVOY - DATA MANAGEMENT"
                : "ONYX DATA"}
            </Typography>
            <UserProfile
              loggedInUserMailId={props.loggedInUserMailId}
              loggedInUser={props.loggedInUser}
              IsGlobalAdmin={IsGlobalAdmin}
              securityProfile={userSecurityProfile}
            ></UserProfile>
            <Tooltip  title="Help">
              <IconButton
                id="help"
                size="large"
                aria-label="show 17 new notifications"
                color="inherit"
                onClick={() => {}}
              >
                <HelpVideos></HelpVideos>
              </IconButton>
            </Tooltip>

            {pubSubClientUrl?.length > 0 && (
              <TableLogs
                loggedInUser={loggedInUser}
                loggedInUserMailId={props.loggedInUserMailId}
                pubSubClientUrl={pubSubClientUrl}
                savedBroadcastMessages={savedBroadcastMessages}
              ></TableLogs>
            )}
            <Tooltip title="Refresh All">
              <IconButton
                id="refresh"
                aria-label="refresh all"
                color="inherit"
                onClick={() => {
                  setIsRefreshClickedHandler(true);
                }}
              >
                <RefreshIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Logout">
              <IconButton
                id="logout"
                aria-label="Logout"
                color="inherit"
                onClick={() => {
                  LogoutUser();
                }}
              >
                <LogoutIcon />
              </IconButton>
            </Tooltip>
          </Toolbar>
          {showProgress && <LinearProgress color="success" />}
        </AppBar>

        <Drawer
          variant="permanent"
          open={open}
          style={{ zIndex: "100", display: "none" }}
        >
          <Toolbar
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              px: [1],
            }}
          >
            <IconButton id="togglerDrawer" onClick={toggleDrawer}>
              <ChevronLeftIcon />
            </IconButton>
          </Toolbar>
          <Divider />
          <List component="nav">
            {mainListItems}
            <Divider sx={{ my: 1 }} />
          </List>
        </Drawer>
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: "100vh",
            overflow: "auto",
          }}
        >
          <Toolbar />
          <Container sx={{ mt: 4, mb: 4, maxWidth: "inherit !important" }}>
            <Grid container spacing={3}>
              {/* Main routing area */}
              <Routing
                loggedInUser={props.loggedInUser}
                loggedInUserMailId={props.loggedInUserMailId}
                IsRefreshClicked={IsRefreshClicked}
                IsAdminUser={IsGlobalAdmin}
                setShowProgress={setShowProgressHandler}
                setIsRefreshClicked={setIsRefreshClickedHandler}
                userSecurityPermission={userSecurityPermission}
              />
            </Grid>
            {/*<Copyright sx={{ pt: 4 }} />*/}
            <div>
              <img style={{ marginTop: "2%" }} src={logo}></img>
            </div>
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
}

export default function Dashboard(props: any) {
  return (
    <DashboardContent
      loggedInUserMailId={props.loggedInUserMailId}
      loggedInUser={props.loggedInUser}
    />
  );
}
