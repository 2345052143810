import Grid from "@mui/material/Grid";
import QualityReport from "./QualityReport";
import * as api from 'src/Utility/api';
import { useState } from "react";
import { useEffectOnce } from "src/hooks/useEffectOnce";

function DataQualityDashboard(props: any) {
   const [reportDefinitions, setReportDefinitions] = useState<any[]>([]);

   useEffectOnce(() => {
      loadReportDefinitions();
   });

   const loadReportDefinitions = () => {
      async function getReportDefinitions() {
         let getTableDataUrl = '/Table/GetTablesData?schema=settings&table=DATA_QUALITY_DASHBOARD';
         await api.API_GET(getTableDataUrl).then((response) => {
            if (response?.data?.Columns.length > 0) {
               let data: any[] = [];
               data = response.data.TableData;
               setReportDefinitions(data.sort((a, b) => a.ReportSequence - b.ReportSequence));
            }
         }).finally(() => { });
      }
      getReportDefinitions();
   }


   return (
      <>
         <Grid container spacing={2} sx={{ paddingLeft: "16px" }}>
            {
               reportDefinitions.map((definition, index) => {
                  return <Grid key={"Quality"+index} item xs={definition.ColumnSize}>
                     <QualityReport
                        sequenceIndex={index}
                        reportTitle={definition.ReportTitle}
                        dataSource={definition.ListValues}
                        ReportShortDesc={definition.ReportShortDesc}></QualityReport>
                  </Grid>
               })
            }
         </Grid>
      </>
   )
}

export default DataQualityDashboard;