import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import { Box, Button, DialogActions, DialogTitle, IconButton, Popper } from '@mui/material';
import { AgGridReact } from 'ag-grid-react';
import React, { useCallback } from 'react';
import { ITableGridColumnDefinition } from '../../@State/types';
import './Cart.css';

function WellDataRenderer(props: any) {
  const [open, setOpen] = React.useState(false);
  const placement = 'top-start';
  const innerGridCartRef: any = React.useRef();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const [hiddenColumns] = React.useState<string[]>(["Approver1"
    , "Comment1"
    , "ApprovedDate1"
    , "ApprovalStatus1"
    , "Approver2"
    , "Comment2"
    , "ApprovedDate2"
    , "ApprovalStatus2"
    , "Approver3"
    , "Comment3"
    , "ApprovedDate3"
    , "ApprovalStatus3"]);

  const defaultColDef = React.useMemo(() => {
    return {
      minWidth: 100,
      resizable: true,
      cellStyle: { textAlign: 'left' }
    };
  }, []);

  const [gridOptions, setGridOptions] = React.useState<any>({
    rowData: [],
    columnDefs: [],
    defaultColDef: defaultColDef,
    pagination: false
  });

  const handleClickOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
    setTimeout(() => {
      loadCartInformation();
    }, 200);
  };

  const handleClickClose = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setOpen(false);
  };

  const loadCartInformation = () => {
    let columns: any[] = ['DataAuditId', 'API', 'WellName', 'County', 'Operator'];
    let data: any[] = [];
    if (typeof (props.wellData.Data) === 'string') {
      data = JSON.parse(props.wellData.Data);
      data = data.filter(value => {
        let audit_list = props.data.AuditList.split(',');
        return (audit_list.includes(value.DataAuditId.toString()))
      });

      /* if (data.length > 0) {
        columns = Object.keys(data[0]);
      } */
      columns = columns.concat(props.wellData.SelectedReports.split(","));
    }
    else if (props.wellData.Data.length > 0) {
      if (props.wellData.Data.length > 0) {
        data = props.wellData.Data;
        columns = columns.concat(props.wellData.SelectedReports.split(","));
      }
    }
    let columnDefinition: ITableGridColumnDefinition[] = [];
    columns.forEach((row) => {
      if (!hiddenColumns.includes(row)) {
        columnDefinition.push({
          field: row,
          //type: 'editableColumn',
          width: 150,
          headerName: row,
          headerTooltip: row,
          tooltipField: row,
          cellClass: ['ag-cell-custom'],
          sortable: true,
          filter: 'agTextColumnFilter'
        });
      }
    });
    if (typeof (innerGridCartRef?.current?.api) !== 'undefined') {
      innerGridCartRef.current.api.setColumnDefs(columnDefinition);
      innerGridCartRef.current.api.setRowData(data);
    } else {
      innerGridCartRef.current.api.setColumnDefs([]);
      innerGridCartRef.current.api.setRowData([]);
    }
  }
  const autoSizeColumns = (timeoutPeriod: number) => {
    setTimeout(() => {
      innerGridCartRef?.current?.columnApi?.autoSizeAllColumns(false);
    }, timeoutPeriod);
  }

  const onFirstDataRendered = useCallback((timeoutPeriod: number) => {
    autoSizeColumns(timeoutPeriod);
  }, []);

  return (
    <div style={{ cursor: 'pointer' }}>
      {props.data &&
        <p style={{ margin: "0px", float: 'right' }}>{props.value + " Wells"}
          <IconButton id="data" color="primary" size="small" onClick={handleClickOpen} aria-label="data">
            <MoreVertOutlinedIcon />
          </IconButton>
        </p>
      }
      <Popper open={open} placement={placement} anchorEl={anchorEl} style={{ zIndex: '10000' }}>
        <Box sx={{ border: '2px solid #bfbfbf', p: 1, bgcolor: 'background.paper' }}>
          <DialogTitle>Selected Wells:</DialogTitle>
          <div style={{ height: '25vh', width: '100vh' }} className="ag-theme-balham">
            <AgGridReact
              ref={innerGridCartRef}
              rowSelection = {'multiple'}
              columnHoverHighlight={true}
              onFirstDataRendered={() => { onFirstDataRendered(10) }}
              gridOptions={gridOptions}>
            </AgGridReact>
          </div>
          <DialogActions>
            <Button id="Welldatarenderclose"
              variant="outlined"
              color="error"
              startIcon={<CancelOutlinedIcon />}
              onClick={handleClickClose}>
              CLOSE</Button>
          </DialogActions>
        </Box>
      </Popper>
    </div>
  )
}

export default WellDataRenderer;