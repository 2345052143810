import { useState } from 'react';
import DateTimePicker from 'react-datetime-picker';

function DateTimePickerEditor(props: any) {
  const [value, setDateTimeValue] = useState(new Date(props.value));
  const [rowIndex, setRowIndex] = useState(props.rowIndex);

  const valueChangeHandler = (_value: any) => {
    const selectedDate = new Date(_value).toISOString().slice(0, 19);
    props.onDateTimeValueSelected(_value, rowIndex, props.data);
    setDateTimeValue(_value);
  }

  return (
    <>
      <DateTimePicker onChange={valueChangeHandler} value={value} />
    </>
  )
}

export default DateTimePickerEditor;