import { TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import React from 'react';
import { useState, useEffect } from 'react';

function DatePickerEditor(props: any) {
  const [value, setDateTimeValue] = useState(props.value);
  const [column, setColumnName] = useState(props.column);
  const [rowIndex] = useState(props.rowIndex);
  const [isExecutedOrder] = useState(props.data?.OrderStatus === 'Executed');


  const valueChangeHandler = (_value: any) => {
    props.onDateValueSelected(_value, rowIndex, props.data);
    setDateTimeValue(_value);
  }

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          renderInput={(props) => <TextField size="small" {...props} />}
          label={column}
          disabled = {isExecutedOrder}
          value={value}
          PopperProps={{
            style: { zIndex: 1002 }
          }}
          onChange={valueChangeHandler} />
      </LocalizationProvider>
    </>
  )
}

export default DatePickerEditor;