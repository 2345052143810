import React, { useRef } from 'react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-balham.css';
import 'ag-grid-enterprise';
import './ValuationRenderer.css';
import { Box, IconButton, Paper, Popper, Typography } from '@mui/material';
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import FilePresentIcon from '@mui/icons-material/FilePresent';

function DataTransferRenderer(props: any) {
    const [open, setOpen] = React.useState(false);
    const [isOrderOwner, setIsOrderOwner] = React.useState(props.gridType === 'requested')
    const placement = 'bottom-start';
    const id = open ? 'settings-popper' : undefined;
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const handleClickClose = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(null);
        setOpen(false);
    };

    const handleClickOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
        if(isOrderOwner && (props?.data?.OrderStatus?.toLowerCase() === "executed" &&
        (props?.data?.DODataTrasferredCount > 0 && props?.data?.DODataNotTrasferredCount == 0 
            && props?.data?.OODataNotRecievedCount > 0)))
        {
            setAnchorEl(event.currentTarget);
            setOpen(true);
        }
        else if(!isOrderOwner){
            if((props?.data?.OrderStatus?.toLowerCase() === "executed" &&
            (props?.data?.DODataTrasferredCount  == 0 || props?.data?.DODataNotTrasferredCount > 0)))
            {
                setAnchorEl(event.currentTarget);
                setOpen(true);
            }
        }
    };

    const loadDataTransferByOrderHandler = () => {
        props.orderPickHandler(props);
    }

    return (
        <div style={{height:"100%"}}>
            <IconButton
                id="id"
                sx={{height:'100%',width:'100%'}}
                color="primary"
                size="small"
                onMouseOver={(event : any) => handleClickOpen(event)}
                onMouseOut={(event:any) => handleClickClose(event)} 
                disabled={props?.data != null
                    && props?.data?.OrderEvent !== "EXT_WORKFLOW_COMPLETED"}
                onClick={loadDataTransferByOrderHandler}>
                <FilePresentIcon></FilePresentIcon>
            </IconButton >

            <Popper id={id} open={open} placement={placement} anchorEl={anchorEl} style={{ zIndex: '50000' }}>
                <Box sx={{ border: '2px solid #bfbfbf', p: 1, bgcolor: 'background.paper' }}>
                        <Paper>
                            {isOrderOwner && (props?.data?.OrderStatus?.toLowerCase() === "executed" &&
                                    (props?.data?.DODataTrasferredCount > 0 && props?.data?.DODataNotTrasferredCount == 0 
                                     && props?.data?.OODataNotRecievedCount > 0)) && <div>
                                <Typography> Data Transfer : </Typography>
                                { props?.data?.OODataNotRecievedCount > 0 && <Typography>{props?.data?.OODataNotRecievedCount} Dataset's data transferred</Typography> }
                            </div>
                        }

                            {!isOrderOwner && (props?.data?.OrderStatus?.toLowerCase() === "executed" &&
                                    (props?.data?.DODataTrasferredCount  == 0 || props?.data?.DODataNotTrasferredCount > 0)) && <div>
                                <Typography> Data Transfer : </Typography>
                                { props?.data?.DODataNotTrasferredCount > 0 && <Typography>{props?.data?.DODataNotTrasferredCount} Dataset's needs data to be transferred</Typography> }
                            </div>}
                        </Paper>
                </Box>
            </Popper>
        </div>
    )
}

export default DataTransferRenderer;