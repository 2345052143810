import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import SettingsIcon from '@mui/icons-material/Settings';
import { Box, DialogActions, DialogTitle } from '@mui/material';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Popper from '@mui/material/Popper';
import { AgGridReact } from 'ag-grid-react';
import * as React from "react";
import * as api from 'src/Utility/api';
import { ITableGridColumnDefinition } from "../../@State/types";
import './Cart.css';

function SettingsViewRenderer(props: any) {
  const innerGridSettingsRef: any = React.useRef();
  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const placement = 'bottom-start';
  const id = open ? 'settings-popper' : undefined;
  const columnsToDisplay = ["SettingsId", "API", "CRS", "County", "ReportName", "Operator", "ApproverEmail1", "ApproverEmail2", "ApproverEmail3"];

  const defaultColDef = React.useMemo(() => {
    return {
      minWidth: 100,
      resizable: true,
      flex: 1,
      cellStyle: { textAlign: 'left' }
    };
  }, []);

  const [gridOptions] = React.useState<any>({
    rowData: [],
    columnDefs: [],
    defaultColDef: defaultColDef,
    pagination: false,
    rowSelection: 'single',
    getRowHeight: () => { return 25 }
  });

  const handleClickOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    getRowSettingsHandler();
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };

  const handleClickClose = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setOpen(false);
  };


  const getRowSettingsHandler = () => {
    async function retrieveSettings() {
      let getTableDataUrl = '/Table/GetTablesData?schema=orders&table=ORDER_MANAGEMENT_SETTINGS'
      await api.API_GET(getTableDataUrl).then((response) => {
        if (response?.data?.TableData.length > 0) {
          const columns = Object.keys(response?.data?.TableData[0]);
          setColumnDefinitions(columns)
          let _tableData = response?.data?.TableData.filter((item: any) => item.SettingsId === props.value);
          setRowSettingsGrid(_tableData);
        }
      });
    }
    retrieveSettings();
  }
  const setColumnDefinitions = (_columns: string[]) => {
    let columnDefinition: ITableGridColumnDefinition[] = [];
    _columns.forEach((col) => {
      if (columnsToDisplay.includes(col)) {
        columnDefinition.push({
          field: col,
          type: 'columnStyle',
          headerName: col,
          headerTooltip: col,
          tooltipField: col,
          cellClass: ['ag-cell-custom'],
          sortable: true
        })
      }
    });
    if (typeof (innerGridSettingsRef?.current?.api) !== "undefined") {
      innerGridSettingsRef.current.api.setColumnDefs(columnDefinition);
    }
  }
  const setRowSettingsGrid = (_rowSettings: any) => {
    if (_rowSettings.length > 0) {
      if (typeof (innerGridSettingsRef?.current?.api) !== 'undefined') {
        if (_rowSettings.length > 0) {
          innerGridSettingsRef.current.api.setRowData(_rowSettings);
        } else {
          innerGridSettingsRef.current.api.setColumnDefs([]);
          innerGridSettingsRef.current.api.setRowData([]);
        }
      }
    }
  }

  const autoSizeColumns = (timeoutPeriod: number) => {
    setTimeout(() => {
      innerGridSettingsRef.current.columnApi.autoSizeAllColumns(false);
    }, timeoutPeriod);
  }

  const onFirstDataRendered = React.useCallback((timeoutPeriod: number) => {
    autoSizeColumns(timeoutPeriod);
  }, []);

  return (<>
    {props.data &&
      <div>
        {props.value}
        <IconButton id="settings" color="primary" size="small" onClick={handleClickOpen} disabled={props.readOnly} aria-label="settings">
          <SettingsIcon />
        </IconButton>
        <Popper id={id} open={open} placement={placement} anchorEl={anchorEl} style={{ zIndex: '50000' }}>
          <Box sx={{ border: '2px solid #bfbfbf', p: 1, bgcolor: 'background.paper' }}>
            <DialogTitle>Order Management Settings : {props.value}</DialogTitle>
            <div style={{ height: '15vh', width: '100vh' }} className="ag-theme-balham">
              <AgGridReact
                ref={innerGridSettingsRef}
                rowSelection = {'multiple'}
                columnHoverHighlight={true}
                gridOptions={gridOptions}
                onFirstDataRendered={() => { onFirstDataRendered(10) }}
                overlayNoRowsTemplate={'<span style="padding: 10px; border: 2px solid #444; background: lightgoldenrodyellow;">No data!!</span>'}>
              </AgGridReact>
            </div>
            <DialogActions>
              <Button id="SVRclose"
                variant="outlined"
                color="error"
                startIcon={<CancelOutlinedIcon />}
                onClick={handleClickClose}>
                CLOSE</Button>
            </DialogActions>
          </Box>
        </Popper>
      </div>
    }
  </>
  )
}

export default SettingsViewRenderer;