import {
    Box, Checkbox, DialogActions, DialogTitle,
    IconButton, List, ListItem, ListItemButton, ListItemIcon,
    ListItemText, Popper, PopperPlacementType, TextField, Tooltip, Typography
} from '@mui/material';
import React from 'react';
import { useEffectOnce } from 'src/hooks/useEffectOnce';
import { ExchangeOrderType } from '../../@State/types';
import CancelIcon from '@mui/icons-material/Cancel';
import HelpCenterOutlinedIcon from '@mui/icons-material/HelpCenterOutlined';
import { AppContext } from '../../@State/context/AppContext';

function HelpVideos(props: any) {
    const [open, setOpen] = React.useState(false);
    const [placement, setPlacement] = React.useState<PopperPlacementType>('bottom-end');
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [searchText, setSearchText] = React.useState<string>("");
    const [columnSelectionFiltered, setColumnSelectionFiltered] = React.useState<any[]>([]);
    const { helpVideoFiles } = React.useContext(AppContext) as ExchangeOrderType;

    useEffectOnce(() => {

    });


    const handleClickOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
        setOpen(true);
    };


    const chooseSelection = (event: React.MouseEvent<HTMLButtonElement>) => {
        setOpen(false);
    };

    const openVideoHandler = (fileName:string) =>{
        window.open("https://kmlfiles.blob.core.windows.net/helpvideos/"+fileName);
    }

    return (
        <>
            <IconButton id="addtocart"
                color="inherit"
                aria-label="Add to cart"
                style={{ width: "fit-content" }}
                onClick={handleClickOpen}>
                <HelpCenterOutlinedIcon />
            </IconButton>
            <Popper open={open} placement={placement} anchorEl={anchorEl} style={{ zIndex: '10000' }}>
                <Box sx={{ border: '2px solid #bfbfbf', width: "300px", p: 1, bgcolor: 'background.paper' }}>
                    <DialogTitle sx={{ padding: "0px" }}>Help</DialogTitle>

                    <Box sx={{ border: '1px solid #bfbfbf', p: 1, bgcolor: 'background.paper' }} style={{ height: '25vh', width: '100%' }} className="ag-theme-material">
                        <List sx={{ width: '100%', height: "100%", maxWidth: 360, maxHeight: '25vh', overflow: 'scroll', bgcolor: 'background.paper' }}>
                            {helpVideoFiles?.map((helpVideo: any) => {
                                return (
                                    <ListItem
                                        key={decodeURI(helpVideo)}
                                        secondaryAction={
                                            <IconButton id="comments" edge="end" aria-label="comments">
                                            </IconButton>
                                        }
                                        disablePadding>
                                        <ListItemButton role={undefined} dense onClick={()=>{openVideoHandler(helpVideo)}}>
                                            <ListItemText primary={decodeURI(helpVideo)} />
                                        </ListItemButton>
                                    </ListItem>
                                );


                            })}
                        </List>
                    </Box>
                    <DialogActions sx={{ padding: "2px" }}>
                        <IconButton id="cancel" color="error" aria-label="cancel" onClick={chooseSelection}>
                            <CancelIcon />
                        </IconButton>
                    </DialogActions>
                </Box>
            </Popper>
        </>
    )
}

export default HelpVideos;