import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import { alpha, styled } from '@mui/material/styles';
import TreeView from '@mui/lab/TreeView';
import TreeItem, { TreeItemProps, treeItemClasses } from '@mui/lab/TreeItem';
import Collapse from '@mui/material/Collapse';
// web.cjs is required for IE11 support
import { useSpring, animated } from 'react-spring';
import { TransitionProps } from '@mui/material/transitions';
import TableRowsIcon from '@mui/icons-material/TableRows';
import { ExplorerContext } from '../../@State/context/ExplorerContext';
import { ExchangeOrderType, ExplorerType, IExplorerTableInfo } from '../../@State/types';
import * as api from 'src/Utility/api';
import { Box, Skeleton, Tooltip } from '@mui/material';
import { AppContext } from '../../@State/context/AppContext';
import { useContext } from 'react';

interface IExplorerProps {
  loggedInUser: string;
  loggedInUserMailId: string,
  setSelectedTableHandler: any;
  userSecurityPermission: any;
  IsUserGlobalAdmin:boolean;
}

function MinusSquare(props: SvgIconProps) {
  return (
    <SvgIcon fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
      {/* tslint:disable-next-line: max-line-length */}
      <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 11.023h-11.826q-.375 0-.669.281t-.294.682v0q0 .401.294 .682t.669.281h11.826q.375 0 .669-.281t.294-.682v0q0-.401-.294-.682t-.669-.281z" />
    </SvgIcon>
  );
}

function PlusSquare(props: SvgIconProps) {
  return (
    <SvgIcon fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
      {/* tslint:disable-next-line: max-line-length */}
      <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 12.977h-4.923v4.896q0 .401-.281.682t-.682.281v0q-.375 0-.669-.281t-.294-.682v-4.896h-4.923q-.401 0-.682-.294t-.281-.669v0q0-.401.281-.682t.682-.281h4.923v-4.896q0-.401.294-.682t.669-.281v0q.401 0 .682.281t.281.682v4.896h4.923q.401 0 .682.281t.281.682v0q0 .375-.281.669t-.682.294z" />
    </SvgIcon>
  );
}

function TransitionComponent(props: TransitionProps) {
  const style = useSpring({
    from: {
      opacity: 0,
      transform: 'translate3d(20px,0,0)',
    },
    to: {
      opacity: props.in ? 1 : 0,
      transform: `translate3d(${props.in ? 0 : 20}px,0,0)`,
    },
  });

  return (
    <animated.div style={style}>
      <Collapse {...props} />
    </animated.div>
  );
}

const StyledTreeItem = styled((props: TreeItemProps) => (
  <Tooltip title={props.label ? props.label : ''}><TreeItem {...props} TransitionComponent={TransitionComponent} /></Tooltip>
))(({ theme }) => ({
  [`& .${treeItemClasses.iconContainer}`]: {
    '& .close': {
      opacity: 0.3,
    },
  },
  [`& .${treeItemClasses.group}`]: {
    marginLeft: 15,
    paddingLeft: 18,
    borderLeft: `1px dashed ${alpha(theme.palette.text.primary, 0.4)}`,
  },
}));

const StyledTreeItemSchema = styled((props: TreeItemProps) => (
  <TreeItem {...props} TransitionComponent={TransitionComponent} />
))(({ theme }) => ({
  [`& .${treeItemClasses.iconContainer}`]: {
    '& .close': {
      opacity: 0.3,
    },
  },
  [`& .${treeItemClasses.group}`]: {
    marginLeft: 15,
    paddingLeft: 18,
    borderLeft: `1px dashed ${alpha(theme.palette.text.primary, 0.4)}`,
  },
}));

export default function Explorer(props: IExplorerProps) {
  const { tableList, saveTables } = React.useContext(ExplorerContext) as ExplorerType;
  const [schemas, setSchemas] = React.useState<string[]>([]);
  const [expandedSchemas, setExpandedSchemas] = React.useState<string[]>([]);

  React.useEffect(() => {
    if (props.loggedInUserMailId !== 'uiuser@ui.com') {
      let getTablesUrl = '/Table/GetTables?';
      getTablesUrl += 'loggedInUser=' + props.loggedInUserMailId;
      api.API_GET(getTablesUrl).then((response) => {
        const sortOnSchema = response.data;
        let schemas: any = [];
        sortOnSchema.forEach((element: any) => {
          if (schemas.indexOf(element.schema) === -1) {
            schemas.push(element.schema);
          }
        });

        if(props.IsUserGlobalAdmin) {
          schemas.push('Logs');
          sortOnSchema.push({schema : 'Logs', name: 'UserLogs', pkColumnName: ''})
        }

        setSchemas(schemas);
        setExpandedSchemas(schemas);
        saveTables(sortOnSchema);

        const sortOnName = [...response.data]?.sort((a: any, b: any) => (a.name > b.name) ? 1 : -1).filter(element => element.schema === schemas[0]);
        initialSelectionHandler(sortOnName);
      });
    }
  }, [props.loggedInUserMailId, props.IsUserGlobalAdmin]);

  const initialSelectionHandler = (explorerData: IExplorerTableInfo[]) => {
    if (explorerData.length > 0) {
      props.setSelectedTableHandler({
        name: explorerData[0].name,
        schema: explorerData[0].schema,
        pkColumnName: explorerData[0].pkColumnName
      });
    }
  }

  const nodeSelectHandler = (event: any, nodeId: any) => {
    if (tableList.length > 0 && tableList[nodeId]) {
      props.setSelectedTableHandler({
        name: tableList[nodeId].name,
        schema: tableList[nodeId].schema,
        pkColumnName: tableList[nodeId].pkColumnName
      });
    }
  }

  const setExpandedSchemaHandler = (event: React.SyntheticEvent, nodeIds: string[]) => {
    setExpandedSchemas(nodeIds);
  }

  return (
    <>
      <TreeView
        aria-label="customized"
        expanded={expandedSchemas}
        defaultCollapseIcon={<MinusSquare />}
        defaultExpandIcon={<PlusSquare />}
        defaultEndIcon={<TableRowsIcon />}
        onNodeToggle={setExpandedSchemaHandler}
        onNodeSelect={nodeSelectHandler}
        sx={{ height: 264, flexGrow: 1, maxWidth: 400, overflowY: 'auto' }}
      >
        {tableList.length === 0 && <Box sx={{ width: 300 }}>
          <Skeleton animation="wave" />
          <Skeleton animation="wave" />
          <Skeleton animation="wave" />
          <Skeleton animation="wave" />
          <Skeleton animation="wave" />
          <Skeleton animation="wave" />
          <Skeleton animation="wave" />
          <Skeleton animation="wave" />
          <Skeleton animation="wave" />
          <Skeleton animation="wave" />
          <Skeleton animation="wave" />
          <Skeleton animation="wave" />
          <Skeleton animation="wave" />
          <Skeleton animation="wave" />
          <Skeleton animation="wave" />
        </Box>
        }

        {
          schemas.map((schema, schemaIndex) => {
            return <StyledTreeItemSchema
              key={schemaIndex}
              nodeId={schema}
              label={schema}
              style={{ cursor: "pointer" }}>
              {tableList
                .sort((a: any, b: any) => (a.name > b.name) ? 1 : -1)
                .filter(table => table.schema === schema)
                .map((element, index) => {
                  return <StyledTreeItem
                    key={index + '' + element.name + '' + schema}
                    nodeId={tableList.indexOf(element) + ""}
                    label={element.name} />
                })}
            </StyledTreeItemSchema>

          })
        }
      </TreeView>
    </>
  );
}
