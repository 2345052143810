import React, { useEffect, useMemo, useRef, useState } from 'react';
import * as api from 'src/Utility/api';
import { useEffectOnce } from 'src/hooks/useEffectOnce';
import { TableContext } from "../../@State/context/TableContext";
import { IDropDownSelectList, ITableGridColumnDefinition, TableType } from '../../@State/types';
import { AgGridReact } from 'ag-grid-react';

function DropDownAgGridCustomColumnRenderer(props: any) {
    const innerGridRef: any = useRef();
    const [selectQueryDetails, setSelectQueryDetails] = useState({
        schema: "",
        table: "",
        selectQuery: ""
    });
    const [rowIndex, setRowIndex] = useState(props.rowIndex);
    const [displayColumn, setDisplayColumn] = useState("");

    const { saveDropDownSelectList, dropDownSelectListCollection } = React.useContext(TableContext) as TableType;

    useEffect(() => {
        if (dropDownSelectListCollection && dropDownSelectListCollection.length > 0) {
            setSelectQueryDetails({
                schema: props.schemaName,
                table: props.tableName,
                selectQuery: props.dropDownSelectQuery
            });
            fetchDropDownData();
        }
    }, [dropDownSelectListCollection]);

    const loadDropDownlist = (dropDownDataExists: any) => {
        if (typeof (dropDownDataExists) != "undefined") {
            if (dropDownDataExists?.data.TableData.length > 0) {
                const columns = Object.keys(dropDownDataExists.data.TableData[0]);
                if (columns.length > 1) {
                    const matchingValue: any[] = dropDownDataExists.data.TableData.filter((element: any) => { return element[props.column.colId] == props.value });
                    if (matchingValue.length > 0) {
                        setDisplayColumn(matchingValue[0][columns[1]]);
                    }
                }
            }
        }
    }

    const fetchDropDownData = async () => {
        const dropDownDataExists = dropDownSelectListCollection?.filter((dropDownSelectList: IDropDownSelectList) => {
            return dropDownSelectList.colId == props.column.colId
                && dropDownSelectList.tableName == props.tableName
                && dropDownSelectList.schema == props.schemaName;
        });

        if (typeof (dropDownDataExists) != "undefined" && dropDownDataExists?.length > 0) {
            loadDropDownlist(dropDownDataExists[0]);
        }
    }

    return (
        <div style={{ height: "40vh", width: "60vh" }}>
            {displayColumn}
        </div>
    )
}

export default DropDownAgGridCustomColumnRenderer;