import {v4 as uuidv4} from 'uuid';

export const groupByObjectArray = function (xs: any, key: any) {
    return xs.reduce(function (rv: any, x: any) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
    }, {});
};


export const currencyFormatter = (value: string) => {
    return '$' + formatNumber(parseFloat(value));
}

const formatNumber = (number: number) => {
    return Math.floor(number)
        .toString()
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
}

export const convertUTCtoCDT = (utcDate: string,isOnlyDate:boolean = false) => {
    if (utcDate !== undefined && utcDate !== "" && utcDate !== 'nullZ') {
        let localDate = new Date(utcDate);
        let localDateTimeString = localDate.toLocaleDateString() + " " + localDate.toLocaleTimeString();
        if(isOnlyDate){
            localDateTimeString = localDate.toLocaleDateString();
        }
        return localDateTimeString;
    } else {
        return "N/A";
    }
}

export const isJsonString = (str:any) => {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}

export const findDuplicates = (arr:any) => arr.filter((item:any, index:any) => arr.indexOf(item) !== index)

export function isNumeric(str:any) {
    if (typeof str != "string") return false // we only process strings!  
    return !isNaN(parseInt(str)) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
           !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
}

export const validURL = (str:string) =>{
    let pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
      '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
    return !!pattern.test(str);
  }

export function columnHeaderTemplate(columnName:string, isPropreitary? : boolean) {
    let pTagHeaderDom = "";
    let columnNameSplits = columnName.split("\\n");
    let propTag =  isPropreitary ? '<p style= "color: red; margin:0px">(proprietary)</p>' : '';
    columnNameSplits.forEach((splitText:string)=>{
        pTagHeaderDom += `<p style="margin:0px" class="ag-header-cell-text">`+splitText+`</p>`
    })

    const privateFieldTemplate = `<div class="ag-cell-label-container" role="presentation">
    <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>
    <div ref="eLabel" class="ag-header-cell-label" role="presentation">
        <div> 
            `+pTagHeaderDom+`
            `+propTag+`
        </div>
        <span ref="eSortOrder" class="ag-header-icon ag-sort-order ag-hidden"></span>
        <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon ag-hidden"></span>
        <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon ag-hidden"></span>
        <span ref="eSortMixed" class="ag-header-icon ag-sort-mixed-icon ag-hidden"></span>
        <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon ag-hidden"></span>
        <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>
     </div>
    </div>`;

    return {
        menuIcon: 'fa-bars',
        template: privateFieldTemplate
    }

}

export const generateKey = (pre:string) => {
    let myuuid = uuidv4();
    return pre+myuuid;
}

export function getMatch(a:any, b:any) {
    let matches = [];

    for ( let i = 0; i < a.length; i++ ) {
        for ( let e = 0; e < b.length; e++ ) {
            if ( a[i] === b[e] ) matches.push( a[i] );
        }
    }
    return matches;
}